import { reduxActions as CATALOG } from "_constants/catalog.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialCatalogState from "../catalog.initialState";

const put = {
  [CATALOG.PUT_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING,
    },
  }),
  
  [CATALOG.PUT_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error,
      status: FAILURE
    }
  }),
  
  [CATALOG.PUT_SUCCESS]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: {
        ...state.one.data,
        ...payload?.data?.[0],
      },
    },
    all: {
      ...state.all,
      data: [
        ...state.all.data.filter(
          ({ key }) => key !== payload?.data?.[0]?.key
        ),
        payload?.data?.[0],
      ],
    },
    put: {
      ...state.put,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS,
    }
  }),

  [CATALOG.PUT_RESET]: (state) => ({
    ...state,
    put: initialCatalogState.put
  })
};

export default put;
