import { useTranslation } from "react-i18next";
import { NubeskIcons } from "components/atoms";
import { Semaphore } from "components/molecules/index";


const Processing = ({
  current = 0,
  itemCurrent = 0,
  itemTotal = 0,
  prefix,
  total = 0
}) => {

  const { t } = useTranslation();

  return (
    <div className={`${prefix}__processing`}>
      <p>{t("module:syncfonia.updateGtin.modal.pleaseWait")}</p>
      
      <div className="--loading">
        <div>
          <NubeskIcons icon="spin" className="u-spin" size="5rem" />
        </div>
        <div>
          <p className="u-text-md">{t("module:syncfonia.updateGtin.modal.updateElements")}</p>
          <div className="--percent">
            <div>{(!!itemCurrent && !!itemTotal) && <>{((itemCurrent / itemTotal) * 100).toFixed(0)}%</> }</div>
            <div className="u-text-sm">{itemCurrent}/{itemTotal}</div>
          </div>
          <Semaphore current={current} steps={total} />
        </div>
      </div>
    </div>
  );
};

export default Processing;
