import i18next from "_config/lang/i18n";
import { toast } from "react-toastify";
import { Toast } from "components/molecules";

/**
 *
 * @param {object} feedback
 * @param {object} toastSettings
 * @param {function} onClick
 * @param {integer} autoClose
 */
const toastGo = ({
  feedback,
  toastType = 'success',
  toastSettings = {},
  buttonProps,
  autoClose = 5000,
}) => {
  if (feedback?.area) feedback.area = i18next.t(feedback.area);
  if (feedback?.info) feedback.info = i18next.t(feedback.info, feedback.infoVars);

  toast[toastType](
    <Toast autoClose={autoClose} {...feedback} buttonProps={buttonProps} />,
    {
      ...toastSettings,
    }
  );
};

export default toastGo;
