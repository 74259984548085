import { NubeskIcons } from "components/atoms/index";
import React from "react";
const prefix = "m-counter";

export const Counter = ({ value, setValue, max, min, initial, infinite, infiniteLabel }) => {
  const handleUp = () => setValue(Math.min(max, +value + 1));
  const handleDown = () => setValue(Math.max(min, +value - 1));
  const handleChange = ({ target: { value } }) => {
    let newValue = +value;
    if (!Number.isInteger(newValue) || newValue.length > 2 || newValue > max)
      return false;

    setValue(newValue);
  };

  const handleBlur = () => {
    if (!+value) setValue(initial);
  };
  
  return (
    <div className={`${prefix}`}>
      
      {infinite === value && infiniteLabel ?
        <input
          readOnly
          className={`${prefix}__input`}
          value={infiniteLabel}
        />
      :
        <input
          className={`${prefix}__input`}
          value={value}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      }
      <div className={`${prefix}__buttons`}>
        <button className={`${prefix}__button`} onClick={handleUp}>
          <NubeskIcons icon="up" />
        </button>
        <button className={`${prefix}__button`} onClick={handleDown}>
          <NubeskIcons icon="down" />
        </button>
      </div>
    </div>
  );
};
