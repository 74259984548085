import { reduxActions as LAYER } from "_constants/layer.constants";
import { API, NBSK_API } from "_constants/request.constants";

export const find = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      LAYER.ALL_STARTED,
      LAYER.ALL_SUCCESS,
      LAYER.ALL_FAIL
    ];
  }

  return dispatch({
    [API]: {
      endpoint: "/setting/layer",
      ...extras,
      params
    }
  });
};

export const post = (data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      LAYER.POST_STARTED,
      LAYER.POST_SUCCESS,
      LAYER.POST_FAIL
    ];
  }

  return dispatch({
    [API]: {
      reqType: "POST",
      endpoint: "/setting/layer",
      ...extras,
      data
    }
  });
};

export const put = (ids, data, extras = {}) => (dispatch) => {
  if (extras?.types) {
    extras.types = [
      LAYER.PUT_STARTED,
      LAYER.PUT_SUCCESS,
      LAYER.PUT_FAIL
    ];
  }

  let putData = {
    c: Array.isArray(data) ? data : [data],
    w: Array.isArray(ids) ? ids : [ids]
  };

  return dispatch(post(putData, {
    reqType: "PUT",
    ...extras
  }));
};


export default {
  find,
  post,
  put
};
