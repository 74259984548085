import { reduxActions as ATTRIBUTE } from "_constants/attribute.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialAttributeState from "../attribute.initialState";

const add = {
  [ATTRIBUTE.ADD_STARTED]: (state) => ({
    ...state,
    post: {
      ...state.post,
      status: LOADING
    }
  }),

  [ATTRIBUTE.ADD_SUCCESS]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      data: payload?.data ?? null,
      status: SUCCESS
    },
    all: {
      ...state.all,
      data: payload?.data?.length && state.all.data?.length ? 
          [...state.all.data, ...payload.data]
        :
          state.all.data
    }
  }),

  [ATTRIBUTE.ADD_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [ATTRIBUTE.ADD_RESET]: (state) => ({
    ...state,
    post: initialAttributeState.post
  })
}

export default add;
