import { reduxActions as ADAPTATION } from '_constants/adaptation.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialAdaptationState from '../adaptation.initialState';

const one = {
  [ADAPTATION.ONE_STARTED]: (state) => ({
    ...state,
    one: {
      ...state.one,
      status: LOADING
    }
  }),

  [ADAPTATION.ONE_FAIL]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [ADAPTATION.ONE_SUCCESS]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),

  [ADAPTATION.ONE_RESET]: (state) => ({
    ...state,
    one: initialAdaptationState.one
  })
};

export default one;
