import { NubeskIcons } from "components/atoms/index";
import { useTranslation } from "react-i18next";
import { Accordion, NbskEditor } from "../index";
import { useFile } from "_hooks";
import { LOADING } from "_constants/redux.constants";

const AiSection = ({ aiConfig, bgImages, canvas, onSelectBg, prefix, setBackgrounds, wasMoved }) => {
  const { aiBgGenerate, aiBgGenerateStatus } = useFile();
  const { t } = useTranslation();

  const generate = async () => {
    if (aiBgGenerateStatus === LOADING) return;

    let { folderId, fileId, adaptationLayerId, modifiers, prompts } = aiConfig;

    let config = { 
      folderId, fileId, adaptationLayerId,
      $config: {
        imageLength: 1,
        prompt: prompts[bgImages.length % prompts.length]
      }
    };
    
    if (modifiers) config.data = modifiers;
    if (wasMoved) {
      let newCanvas = {...canvas.toJSON(["nbskProperties"])};
      config.data = {
        ...config?.data ?? {},
        canvas: JSON.stringify({
          ...newCanvas ?? {},
          objects: newCanvas?.objects?.filter((o) => !o?.nbskProperties?.background )
        })
      };
    }
    
    aiBgGenerate(config, { feedback: { error: true, success: true }}).then((response) => {
      if (response?.data?.[0]?.generated?.[0]) {
        let { data } = response;
        setBackgrounds((bgs) => {
          return [...bgs, ...data[0].generated];
        });
        onSelectBg(data[0].generated[0]);
      }
    });
  };

  return (
    <Accordion
      title={t("nbsk:editor.ai.aiImages")}
      headerRight={null}
      isOpen={true}
      content={
        <div className={`${prefix}__ai-section`}>
          {bgImages && bgImages?.map(({ url, ...props }, i) => (
            <div 
              className="--obj-bg"
              key={i}
              onClick={() => onSelectBg({ url, ...props })}
              style={{backgroundImage: `url(${url})` }}
            ><div className="--obj-prev-btn">
              <NubeskIcons className="u-white" icon="eye" size="3rem" />
            </div></div>
          ))}

          {!!aiConfig?.prompts?.length &&
            <div
              className="--obj-bg"
              onClick={() => generate()}
            >
              <NubeskIcons 
                className={aiBgGenerateStatus === LOADING ? "u-spin": ""}
                icon={aiBgGenerateStatus === LOADING ? "spin" : "imagePlus" } spin size="4rem"
              />
            </div>
          }
        </div>
      } />
  );
};

export default AiSection;
