/**
 * 
 * @param {Array} array 
 * @param {Number} size 
 * @returns 
 */
const chunk = (array, size = 1) => {
  return Array.from(new Array(Math.ceil(array.length / size)), (_, i) =>
    array.slice(i * size, i * size + size)
  );
};

export default chunk;
