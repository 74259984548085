import { reduxActions as LAYOUT} from "_constants/layout.constants";
import { LOADING, SUCCESS } from "_constants/redux.constants";
import initialLayoutState from "../layout.initialState";

const post = {
  [LAYOUT.POST_STARTED]: (state) => ({
    ...state,
    post: {
      ...state.post,
      status: LOADING
    }
  }),
  
  [LAYOUT.POST_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: [...payload?.data ?? [], ...state?.all?.data ?? []] 
    },
    post: {
      ...state.post,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }, 
  }),
  
  [LAYOUT.POST_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      error: payload?.error ?? null,
      status: SUCCESS
    }
  }),

  [LAYOUT.POST_RESET]: (state) => ({
    ...state,
    post: initialLayoutState.post
  })
};

export default post;
