import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import jsonToUrl from "_utils/url/jsonToUrl";
import urlToJson from "_utils/url/urlToJson";

const STACK_SIZE = 25;

const usePagination = (initialPage, size = STACK_SIZE) => {
  const location = useLocation();
  const history = useHistory();
  const [page, setPage] = useState(initialPage);
  const [stackSize, setStackSize] = useState(size);

  const pageToA = () => {
    return { 
      page: {
        skip: stackSize,
        step: page
      }
    };
  };

  const pageToUrl = () => {
    return {
      page,
      skip: stackSize
    };
  };

  const changePage = async (newPage, newSize = stackSize, applyUrl = false) => {
    let json = await urlToJson(location.search);

    json.p = {
      step: newPage,
      skip: newSize
    };

    if (applyUrl) {
      let encode = await jsonToUrl(json);
      history.push({
        pathname: location.pathname,
        search: encode
      });
    }
    return json;
  };

  const savePage = async () => {
    let json = await urlToJson(location.search);
    let { p } = json;

    if (page?.skip && stackSize !== p.skip) {
      setStackSize(p.skip);
    } else {
      setStackSize(stackSize);
    }
    if (p?.step && p.step !== page) {
      setPage(p.step);
    }
  };
  
  useEffect(() => savePage(), [location.search])

  return {
    page,
    pageToA,
    changePage,
    stackSize
  };
};

export default usePagination;
