export const SIZE_POS_HEIGHT = "sizePos:height";
export const SIZE_POS_WIDTH = "sizePos:width";
export const SIZE_POS_POSY = "sizePos:posY";
export const SIZE_POS_POSX = "sizePos:posX";
export const SIZE_POS_ROTATE = "sizePos:rotate";
export const SIZE_POS_BORDERR1 = "sizePos:borderR1";
export const SIZE_POS_BORDERR2 = "sizePos:borderR2";
export const STYLE_AUTOCOLOR = "style:autoColor";
export const STYLE_SHAPECOLOR = "style:shapeColor";
export const STYLE_OPACITY = "style:opacity";
export const STYLE_BORDER = "style:border";
export const STYLE_BORDERCOLOR = "style:borderColor";
export const STYLE_BORDERWIDTH = "style:borderWidth";
export const STYLE_SHADOW = "style:shadow";
export const STYLE_SHADOWOFFSETX = "style:shadowOffsetX";
export const STYLE_SHADOWOFFSETY = "style:shadowOffsetY";
export const STYLE_SHADOWBLUR = "style:shadowBlur";
export const TEXT_FONTFAMILY = "text:fontFamily";
export const TEXT_FONTJUSTIFY = "text:fontJustify";
export const TEXT_FONTSIZE = "text:fontSize";
export const TEXT_FONTSTYLE = "text:fontStyle";

export const IMG_RULES = [
  SIZE_POS_HEIGHT,
  SIZE_POS_WIDTH,
  SIZE_POS_POSY,
  SIZE_POS_POSX,
  SIZE_POS_ROTATE,
  STYLE_AUTOCOLOR,
  STYLE_SHAPECOLOR,
  STYLE_OPACITY,
  STYLE_BORDER,
  STYLE_BORDERCOLOR,
  STYLE_BORDERWIDTH,
  STYLE_SHADOW,
  STYLE_SHADOWOFFSETX,
  STYLE_SHADOWOFFSETY,
  STYLE_SHADOWBLUR
];

export const LINE_RULES = [
  SIZE_POS_WIDTH,
  SIZE_POS_POSY,
  SIZE_POS_POSX,
  SIZE_POS_ROTATE,
  STYLE_OPACITY,
  STYLE_BORDERCOLOR,
  STYLE_BORDERWIDTH
];

export const RECT_RULES = [
  SIZE_POS_BORDERR1,
  SIZE_POS_BORDERR2,
  SIZE_POS_HEIGHT,
  SIZE_POS_WIDTH,
  SIZE_POS_POSY,
  SIZE_POS_POSX,
  SIZE_POS_ROTATE,
  STYLE_AUTOCOLOR,
  STYLE_SHAPECOLOR,
  STYLE_OPACITY,
  STYLE_BORDER,
  STYLE_BORDERCOLOR,
  STYLE_BORDERWIDTH,
  STYLE_SHADOW,
  STYLE_SHADOWOFFSETX,
  STYLE_SHADOWOFFSETY,
  STYLE_SHADOWBLUR
];

export const CIRCLE_RULES = [
  SIZE_POS_HEIGHT,
  SIZE_POS_WIDTH,
  SIZE_POS_POSY,
  SIZE_POS_POSX,
  SIZE_POS_ROTATE,
  STYLE_AUTOCOLOR,
  STYLE_SHAPECOLOR,
  STYLE_OPACITY,
  STYLE_BORDER,
  STYLE_BORDERCOLOR,
  STYLE_BORDERWIDTH,
  STYLE_SHADOW,
  STYLE_SHADOWOFFSETX,
  STYLE_SHADOWOFFSETY,
  STYLE_SHADOWBLUR
];

export const TEXTBOX_RULES = [
  SIZE_POS_HEIGHT,
  SIZE_POS_WIDTH,
  SIZE_POS_POSY,
  SIZE_POS_POSX,
  SIZE_POS_ROTATE,
  TEXT_FONTFAMILY,
  TEXT_FONTJUSTIFY,
  TEXT_FONTSIZE,
  TEXT_FONTSTYLE,
  STYLE_AUTOCOLOR,
  STYLE_SHAPECOLOR,
  STYLE_OPACITY,
  STYLE_SHADOW,
  STYLE_SHADOWOFFSETX,
  STYLE_SHADOWOFFSETY,
  STYLE_SHADOWBLUR
];

export const RULES = {
  image: IMG_RULES,
  line: LINE_RULES,
  rect: RECT_RULES,
  circle: CIRCLE_RULES,
  text: TEXTBOX_RULES,
  textbox: TEXTBOX_RULES
};

export const SIZE_POS_SECTION = [
  SIZE_POS_BORDERR1,
  SIZE_POS_BORDERR2,
  SIZE_POS_HEIGHT,
  SIZE_POS_WIDTH,
  SIZE_POS_POSY,
  SIZE_POS_POSX,
  SIZE_POS_ROTATE,
];

export const TEXT_SECTION = [
  TEXT_FONTFAMILY,
  TEXT_FONTSIZE,
  TEXT_FONTJUSTIFY,
  TEXT_FONTSTYLE,
];

export const STYLE_SECTION = [
  STYLE_AUTOCOLOR,
  STYLE_SHAPECOLOR,
  STYLE_OPACITY,
  STYLE_BORDER,
  STYLE_BORDERCOLOR,
  STYLE_BORDERWIDTH,
  STYLE_SHADOW,
  STYLE_SHADOWOFFSETX,
  STYLE_SHADOWOFFSETY,
  STYLE_SHADOWBLUR
];

export const DATA_TAB = "data";
export const LIB_TAB = "lib";
export const SHAPES_TAB = "shapes";
