import React from "react";
import PropTypes from "prop-types";
import Switch from "react-switch";
import { FlexComponent } from "components/templates";

const prefix = "a-switch";

const Switchs = ({
  disabled,
  height= 22,
  label,
  name,
  checked = false,
  offColor = "#DFDAE4",
  onChange = () => {},
  onColor = "#DFDAE4",
  rightLabel,
  width = 45
}) => {
  return (
    <FlexComponent gap={10}>
      <span className={`${prefix}`}>{label}</span>
      <Switch
        checked={checked}
        disabled={disabled}
        onChange={onChange}
        name={name}
        onColor={onColor}
        offColor={offColor}
        onHandleColor="#734AA0"
        handleDiameter={18}
        uncheckedIcon={false}
        checkedIcon={false}
        borderRadius={10}
        boxShadow="0px 1px 2px rgba(0, 0, 0, 0.6)"
        height={height}
        width={width}
      />
      {rightLabel && <span className={`${prefix}`}>{rightLabel}</span>}
    </FlexComponent>
  );
};

Switchs.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func
};

export default Switchs;
