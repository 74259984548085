import { createSelector } from 'reselect';

export const selectUserStore = (store) => store.user;

export const selectUserOwnStore = createSelector(
  selectUserStore,
  (user) => user.own
);

export const selectUser = createSelector(selectUserOwnStore, (userStore) => userStore.data);
export const selectUserError = createSelector(selectUserOwnStore, (userStore) => userStore.error);
export const selectUserStatus = createSelector(selectUserOwnStore, (userStore) => userStore.status);


export const selectUserAllStore = createSelector(
  selectUserStore,
  (user) => user.all
);

export const selectUserRestore = createSelector(
  selectUserStore,
  (user) => user.restore
);

export const selectUserForgot = createSelector(
  selectUserStore,
  (user) => user.forgot
);

export const selectUserSelection = createSelector(
  selectUserStore,
  (user) => user.selection
);

export const selectUserPost = createSelector(
  selectUserStore,
  (user) => user.post
);

export const selectUserPut = createSelector(
  selectUserStore,
  (user) => user.post
);

export default selectUserStore;
