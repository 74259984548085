import { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { Calendar } from "react-date-range";
import { Button } from 'components/atoms';
import * as locales from "react-date-range/dist/locale";
import i18next from '_config/lang/i18n';
 
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

const prefix = "m-date-range";

const DateRange = ({ onChange, startDefault, endDefault, ...props }) => {
  const { t } = useTranslation();

  const [date, setDate] = useState();

  const assingRange = (v) => {
    let newDates;
    
    if (date?.[0]?.startDate && 
      date?.[0]?.startDate === date?.[0]?.endDate
    ) {
      let start = date[0].startDate;
      let end = v;
      if (start > end) {
        start = v;
        end = date[0].startDate;
      }
      newDates = [{
        startDate: start,
        endDate: end,
        key: "selection"
      }];
    } else {
      newDates = [{
        startDate: v,
        endDate: v,
        key: "selection"
      }];
    }

    setDate(newDates);
  };

  /**
   * Reset
   */
  useEffect(() => {
    if (!startDefault && !endDefault) {
      setDate([]);
    } else {
      setDate([{
        startDate: startDefault,
        endDefault: endDefault,
        key: "selection"
      }]);
    }
  }, [startDefault, endDefault]);

  useEffect(() => {
    if (date !== undefined) {
      onChange({
        startDate: date?.[0]?.startDate ?? undefined,
        endDate: date?.[0]?.endDate ?? undefined
      });
    }
  }, [date])

  return (
    <div className={prefix}>
      <Calendar
        displayMode="dateRange"
        direction="horizontal"
        editableDateInputs={false}
        onChange={assingRange}
        locale={locales?.[i18next.language]}
        moveRangeOnFirstSelection={false}
        months={2}
        retainEndDateOnFirstSelection={true}
        ranges={date}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
        showSelectionPreview={true}
        showPreview={false}
        preventSnapRefocus={true}
        {...props}
      />

        <Button
          className={`${prefix}--btn-reset`}
          content={t("common:form.reset")} 
          onClick={() => setDate([])}
          sm
          type="underline"
        />
    </div>
  );
};

DateRange.propTypes = {
  endDefault: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  startDefault: PropTypes.instanceOf(Date)
};

DateRange.defaultProps = {
  onChange: () => {}
};

export default DateRange;
