import { reduxActions as ADAPTATION } from '_constants/adaptation.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialAdaptationState from '../adaptation.initialState';

const all = {
  [ADAPTATION.ALL_STARTED]: (state) => ({
    ...state,
    all: {
      ...state.all,
      status: LOADING
    }
  }),
    
  [ADAPTATION.ALL_FAIL]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [ADAPTATION.ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
    
  [ADAPTATION.ALL_RESET]: (state) => ({
    ...state,
    all: initialAdaptationState.all
  })
};
      
export default all;
