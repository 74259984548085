import { Route, Redirect, Switch } from "react-router-dom";
import {useAppSettings} from "_hooks";
import ProductRoutes from "./ProductRoutes";
import TaskRoutes from "./TaskRoutes";
import SettingsRoutes from "./SettingsRoutes";
import { ExpiredTrial, ScreenNotCompatible } from "views/App";
import { UserLayout } from "components/templates/index";

const AppRoutes = function () {
  const { appModules } = useAppSettings();

  return (
    <Switch>
      <Route path="/screenNotCompatible">
        <ScreenNotCompatible />
      </Route>
      <Route path="/expiredTrial">
        <ExpiredTrial />
      </Route>
      <Route path="/">
        <UserLayout>
          <Switch>
            <Route path={`/products`}>
              <ProductRoutes />
            </Route>
            <Route path={`/tasks`}>
              {!appModules?.["tasks-module"] && <Redirect to="/" />}
              <TaskRoutes />
            </Route>
            <Route path={`/settings`}>
              <SettingsRoutes />
            </Route>
            <Redirect to="/products" />
          </Switch> 
        </UserLayout>
      </Route>
    </Switch>
  );
};

export default AppRoutes;
