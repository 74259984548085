import { reduxActions as FILE } from "_constants/file.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const post = {
  [FILE.POST_STARTED]: (state) => ({
    ...state,
    post: {
      ...state.post,
      status: LOADING
    }
  }),

  [FILE.POST_SUCCESS]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [FILE.POST_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      error: payload?.error ?? null,
      status: FAILURE
    }
  })
};

export default post;
