import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { BulkActionsCounter } from "components/organism";
import { Button } from "components/atoms";
import { get, remove, resetRemove } from "_redux/product/product.actions";
import { useProductQuery } from "_hooks";
import { closeModal, resetSelection, toggleRefresh } from "_redux/ui/ui.actions";
import { chunk } from "_utils/array";
import { sleep } from "_utils/async";
import { REQUIRED } from "_constants/product.constants";
import useAppSettings from "_hooks/useAppSettings";

const prefix = "o-remove-product-modal";

const RemoveProductModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [completed, setCompleted] = useState(0);
  const [errors, setErrors] = useState(0);
  const [total, setTotal] = useState(0);
  const [waiting, setWaiting] = useState(false);

  const { appColumnKeyProps } = useAppSettings();
  const { itemsSelected } = useProductQuery();

  const removeHandler = async () => {
    let productsId = await itemsSelected();
    let stackSize;

    if (productsId.length > 1000){
      stackSize = 100;
    } else if (productsId.length > 100){
      stackSize = 20;
    } else {
      stackSize = 1;  
    }
    setTotal(productsId.length);
    setWaiting(true);
    let arrayChunk = chunk(productsId, stackSize);
    
    for (let i in arrayChunk) {
      let { data } = await dispatch(get({
        f: ["process"],
        w: { _id: { $in: arrayChunk[i] }}
      }));

      for (let j in data) {
        if (data[j]?.process?.reviewMaster !== REQUIRED) {
          let index = arrayChunk[i].indexOf(data[j]._id);
          if (index !== -1) {
            arrayChunk[i].splice(index, 1);
            setErrors((v) => v += 1);
          }
        }
      }
      
      if (arrayChunk[i]?.length) {
        await dispatch(remove(arrayChunk[i]));
        setCompleted((v) => v += arrayChunk[i].length)
        if (productsId.length <= 50) {
          await sleep(150).then(() => {});
        }
      }
    }
  }

  useEffect(() => {
    return () => {
      dispatch(resetRemove());
    }
  }, [])
  
  return(
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        {!waiting ?
          <p>{t("modals:removeProduct.question", { key: appColumnKeyProps?.name })}</p>
        :
          <BulkActionsCounter
            completed={completed}
            legends={{
              action: t("modals:removeProduct.bulkCounter.action"),
              complete: t("modals:removeProduct.bulkCounter.complete"),
              error: t("modals:removeProduct.bulkCounter.error"),
              finish: t("modals:removeProduct.bulkCounter.finish"),
              success: t("modals:removeProduct.bulkCounter.success"),
              total: t("modals:removeProduct.bulkCounter.total")
            }}
            onFinish={async() => {
              await dispatch(resetSelection());
              dispatch(toggleRefresh("products"));
              dispatch(closeModal());
            }}
            withError={errors}
            total={total}
          />
        }

      </div>
      {!waiting && 
        <div className={`${prefix}__footer`}>
          <Button
            content={t("common:form.cancel")}
            onClick={() => dispatch(closeModal())}
            sm
            type="underline"
          />
          <Button
            content={t("common:form.confirm")}
            onClick={removeHandler}
            sm
            type="purple"
          />
        </div>
      }
    </div>
  );
};

export default RemoveProductModal;
