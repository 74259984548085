export const reduxActions = {

  ADAPTATION_BULK_STARTED: "analysis/adaptation_bulk_started",
  ADAPTATION_BULK_FAIL: "analysis/adaptation_bulk_fail",
  ADAPTATION_BULK_SUCCESS: "analysis/adaptation_bulk_success",
  ADAPTATION_BULK_RESET: "analysis/adaptation_bulk_reset",

  LAYOUT_COPY_STARTED: "analysis/layout_copy_started",
  LAYOUT_COPY_FAIL: "analysis/layout_copy_fail",
  LAYOUT_COPY_SUCCESS: "analysis/layout_copy_success",
  LAYOUT_COPY_RESET: "analysis/layout_copy_reset",

  APPROVED_BULK_STARTED: "analysis/approved_bulk_started",
  APPROVED_BULK_FAIL: "analysis/approved_bulk_fail",
  APPROVED_BULK_SUCCESS: "analysis/approved_bulk_success",
  APPROVED_BULK_RESET: "analysis/approved_bulk_reset",

  REMOVE_BG_BULK_STARTED: "analysis/remove_bg_bulk_started",
  REMOVE_BG_BULK_FAIL: "analysis/remove_bg_bulk_fail",
  REMOVE_BG_BULK_SUCCESS: "analysis/remove_bg_bulk_success",
  REMOVE_BG_BULK_RESET: "analysis/remove_bg_bulk_reset",

  REQUEST_STARTED: "analysis/request_started",
  REQUEST_FAIL: "analysis/request_fail",
  REQUEST_SUCCESS: "analysis/request_success",
  REQUEST_RESET: "analysis/request_reset"
};
