import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Badge = ({ 
  bold,
  border,
  className,
  content,
  height,
  labelClass,
  noUp,
  medium,
  placement,
  size,
  small,
  variant,
  ...props }) => {
  const prefix = "a-badge";
  const classForComponent = classNames({
    "u-skeleton": !content,
    [`${prefix}--bold`]: bold,
    [`${prefix}--border-${border}`]: border,
    [`${prefix}--${size}`]: size,
    [`${prefix}--${variant}`]: !!variant,
    [`${prefix}--no-up`]: noUp,
    [`${prefix}--outside`]: placement === "outside",
    "u-bg-dark-gray u-white": !className && !variant && !border,
    [className]: className
  });

  return (
    <div className={prefix} {...props}>
      <div
        className={classForComponent} 
        style={{height}}
      >
        {placement !== "outside" && <span className={`${prefix}__label ${labelClass || "u-white"}`}>{content}</span>}
      </div>

      {placement === "outside" && content && content?.length &&
        <div>
          <span className={`${prefix}__label ${labelClass || "u-purple"}`}>{content}</span>
        </div>
      }
    </div>
  );
};

export default Badge;

Badge.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  placement: PropTypes.oneOf(["left", "center", "right", "outside"])
};

Badge.defaultProps = {
  placement: "center"
};
