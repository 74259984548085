import React from "react";
const prefix = "o-context-menu";
const ContextMenu = ({
  top,
  left,
  children,
  isOpen,
  setContextMenu,
  ...props
}) => {
  if (!isOpen) return false;
  return (
    <div
      onContextMenu={(e) => e.preventDefault()}
      className={prefix}
      style={{
        top: top - 5 || 50,
        left: left - 5 || 0,
      }}
      {...props}
    >
      {children}
    </div>
  );
};

export default ContextMenu;
