import { createSelector } from "reselect";

const selectAttributeStore = (state) => state.attribute;

export const selectAttributeAll = createSelector(
  selectAttributeStore,
  (attribute) => attribute.all
);

export const selectAttributeList = createSelector(
  selectAttributeStore,
  (attribute) => attribute.list
);

export const selectAttributeOne = createSelector(
  selectAttributeStore,
  (attribute) => attribute.one
);

export const selectAttributePost = createSelector(
  selectAttributeStore,
  (attribute) => attribute.post
);

export const selectAttributePut = createSelector(
  selectAttributeStore,
  (attribute) => attribute.put
);

export const selectAttributeToLayer = createSelector(
  selectAttributeStore,
  (attribute) => attribute.toLayer
);

export default selectAttributeStore;
