import { reduxActions as PROFILE } from "_constants/profile.constants";
import { API } from "_constants/request.constants";

export const find = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      PROFILE.ALL_STARTED,
      PROFILE.ALL_SUCCESS,
      PROFILE.ALL_FAIL
    ];
  }

  return dispatch({
    [API]: {
      endpoint: "/account/profile",
      reqType: "GET",
      ...extras,
      params
    }
  })
};

export default {
  find
};
