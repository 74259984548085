import initialCatalogState from "../catalog.initialState";
import { reduxActions as CATALOG} from "_constants/catalog.constants";
import { LOADING } from "_constants/redux.constants";

const add = {
  [CATALOG.ADD_ITEMS_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      loading: LOADING
    }
  }),
  [CATALOG.ADD_ITEMS_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null
    }
  }),
  [CATALOG.ADD_ITEMS_SUCCESS]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      data: payload?.data ?? null
    },
    all: {
      ...state.all,
      data: [
        ...state.all.data?.map((r) => {
          if (r._id === payload?.data?.[0]._id) {
            r = payload?.data?.[0];
          }
          return r;
        })
      ]
    }
  }),
  [CATALOG.ADD_ITEMS_RESET]: (state) => ({
    ...state,
    put: initialCatalogState
  })
};

export default add;
