import { reduxActions as FILE } from "_constants/file.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const put = {
  [FILE.PUT_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),
    
  [FILE.PUT_SUCCESS]: (state, { payload }) => ({
      ...state,
      put: {
        ...state.put,
        data: payload?.data ?? null,
        status: SUCCESS
      }
  }),
    
  [FILE.PUT_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null,
      status: FAILURE
    }
  })
};

export default put;
