import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { NubeskIcons } from "components/atoms";
import { Button } from "components/atoms";
import { addResource } from "_redux/catalog/catalog.actions";
import { selectResourceOne } from "_redux/resource/resource.selector";
import { sleep } from "_utils/async";

const prefix = "o-resource-done-process";

const ResourceDoneProcess = ({ urlManifest, urlZip }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const copyRef = useRef(null);
  const [copy, setCopy] = useState(false);

  const { data: resource } = useSelector(selectResourceOne);

  const copyLink = async() => {
    await sleep(100).then(() => {
      setCopy(true);
    });
    copyRef.current.select();
    document.execCommand("copy");
  }

  useEffect(() => {
    if (resource) {
      dispatch(addResource(resource));
    }
  }, [resource]);

  useEffect(() => {
    if (true) {
      sleep(3500).then(() => {
        setCopy(false);
      });
    }
  }, [copy])

  return (
    <div className={prefix}>
      <div onClick={() => window.open(`${urlManifest}?_=${Date.now()}`)}>
        <NubeskIcons
          icon="excel"
        />
        <Button 
          content={t("catalogs:links.downloadXls")}
          type="underline"
        />
      </div>

      <div onClick={() => window.open(urlZip)}>
        <NubeskIcons icon="downloadLine" />
        <Button 
          content={t("catalogs:links.downloadZip")}
          type="underline"
        />
      </div>

      <div onClick={copyLink}>
        {copy && <span className={`${prefix}__copy-legend`}>
          {t("catalogs:links.copied")}
        </span>}
        <input
          type="text"
          ref={copyRef}
          value={`${urlZip}?_=${Date.now()}` ?? ""}
          onChange={() => {}}
        />
        <NubeskIcons icon="linkInclined" />
        <Button
          content={t("catalogs:links.copyLink")}
          type="underline"
        />
      </div>
    </div>
  );
};

ResourceDoneProcess.propTypes = {
  urlManifest: PropTypes.string,
  urlZip: PropTypes.string
};

ResourceDoneProcess.defaultProps = {
  urlManifest: "",
  urlZip: ""
};

export default ResourceDoneProcess;
