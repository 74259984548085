import { reduxActions as PRODUCT } from "_constants/product.constants";
import { SUCCESS } from "_constants/redux.constants";

const setCounters = {
  [PRODUCT.SET_COUNTERS]: (state, { payload }) => ({
    ...state,
    count: {
      ...state.count,
      data: payload?.count ?? 0,
      error: null,
      status: SUCCESS
    },
    countPiece: {
      ...state.countPiece,
      data: payload?.countPiece === undefined ? 0 : payload.countPiece,
      error: null,
      status: SUCCESS
    }
  })
};

export default setCounters;
