import { SimpleScroller } from "components/organism/index";
import { GridLayout } from "components/templates/index";
import LayerCard from "components/templates/LayerCard/LayerCard";
import { useEffect } from "react";
import { useAppSettings, useLayer } from "_hooks";

const prefix = "v-layers";

const Layers = () => {
  const { appLayersData } = useAppSettings();
  const { canvasEditor: { visible }, closeCanvasEditor } = useLayer();

  useEffect(() => {
    return () => {
      if (visible) closeCanvasEditor();
    }
  }, []);

  return (
    <div className={prefix}>
      <SimpleScroller isHorizontal={false}>
        <GridLayout settingsLayers>
          {appLayersData?.map((l, k) => (
            <LayerCard 
              key={k}
              layer={l}
            />
          ))}
        </GridLayout>
      </SimpleScroller>
    </div>
  );
};

export default Layers;
