import React from "react";
import { CircleStatus } from "components/atoms";
import { useTranslation } from "react-i18next";

const SquareButton = ({ status, name, icon, ...props }) => {
  const prefix = "a-square-button";
  const { t } = useTranslation();

  const textStatus = {
    rejected: t("common:general.rejected"),
    approved: t("common:general.approved"),
    toReview: t("common:general.toReview"),
    required: t("common:general.required"),
  };
  return (
    <button className={prefix} {...props}>
      <CircleStatus status={status || "rejected"} />
      <div className={`${prefix}__icon`}>{icon}</div>
      <span className={`${prefix}__name`}>{name}</span>
      <span className={`${prefix}__status`}>
        {textStatus[status || "rejected"]}
      </span>
    </button>
  );
};

export default SquareButton;
