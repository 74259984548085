import React from "react";
import { NubeskIcons } from "components/atoms";
import { DropDown, FlexComponent } from "components/templates";
import classNames from "classnames";

const prefix = "m-master-row";

const RowTemplate = ({
  isActive,
  onClick,
  title,
  qty,
  children,
  options,
  ...props
}) => {
  const classForComponent = classNames(prefix, {
    "is-selected": isActive,
    "empty": !qty && props.isAdaptation
  });

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__data`} onClick={onClick} {...props}>
        <FlexComponent sm>
          <span className={`${prefix}__title`}>{title}</span>
          {qty ? <span className={`${prefix}__qty`}>({qty})</span> : null}
        </FlexComponent>
        {children}
      </div>
      <DropDown
        options={options}
        buttonProps={{
          content: <NubeskIcons icon="dots" />,
          type: "color",
        }}
      />
    </div>
  );
};

export default RowTemplate;
