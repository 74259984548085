import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { InputLabel } from "components/molecules/Inputs";
import InputTest from "components/molecules/Inputs/InputTest/InputTest";
import { FlexComponent } from "components/templates";
import { closeModal } from "_redux/ui/ui.actions";
import { addProducts, newCatalog } from "_redux/catalog/catalog.actions";
import { useAppSettings, useProductQuery } from "_hooks";
import { toastGo } from "_utils/feedback";
import { selectCatalogAllStore } from "_redux/catalog/catalog.selector";
import chunk from "_utils/array/chunk";
import { CustomProgressBar, MultipleProgress } from "components/molecules/index";
import { CircularProgress } from "components/atoms/index";

const prefix = "o-new-catalog-modal";

const NewCatalog = () => {
  const { appColumnKeyProps } = useAppSettings();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const inputRef = useRef(null);

  const { data: catalogList } = useSelector(selectCatalogAllStore);
  
  const { itemsSelected } = useProductQuery(false);
  const [count, setCount] = useState(0);
  const [form, setForm] = useState({});
  const [catalogNames, setCatalogNames] = useState([]);
  const [longRequest, setLongRequest] = useState();
  let timer;

  const handleChange = ({ target: { name, value } }) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async () => {
    let response;
    try {
      let foldersId = await itemsSelected();
      if (foldersId?.length < 500) {
        response = await dispatch(newCatalog({ ...form, foldersId }));
      } else{
        response = await dispatch(newCatalog({ ...form, foldersId: [] }));
        let { _id, key: name } = response?.data?.[0];
        let ids = chunk(foldersId, 500);
        setLongRequest({
          total: ids.length,
          completed: 0
        });
        for (let i = 0; i < ids.length; i++) {
          let extras = {};
          if (i < ids.length - 1) {
            extras = {
              feedback: {
                error: true,
                success: false
              }
            }
          }
          await dispatch(
            addProducts(_id, { foldersId: ids[i], name }, extras)
          );
          setLongRequest((s) => ({
            ...s,
            completed: s.completed + 1
          }));
        }
        setLongRequest();
      }
      if (response?.data?.[0]) {
        await dispatch(closeModal());
        toastGo({
          toastType: "info",
          feedback: {
            info: "catalogs:general.new_catalog_modal.feedback.success",
            infoVars: { catalog: response.data[0].key },
            area: "catalogs:general.new_catalog_modal.feedback.area",
          },
          buttonProps: {
            onClick: () => push(response.data[0]._id),
            content: "Go",
            type: "underline",
          },
        });
      }
    } catch (e) {
      setLongRequest();
    }
  };

  const getSelected = async () => {
    let arraySkus = await itemsSelected();
    setCount(arraySkus.length);
    return arraySkus;
  };

  const push = (id) => history.push(`/catalogs/${id}`);

  useEffect(() => {
    getSelected();
    
    inputRef.current.focus();
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (catalogList) {
      let names = catalogList.map((r) => r.key);
      setCatalogNames(names);
    }
  }, [catalogList]);

  return (
    <div className={prefix}>
      <FlexComponent isVertical>
        {longRequest ?
          <CircularProgress
            percent={longRequest.completed ? parseInt((longRequest.completed / longRequest.total) * 100) : 0}
          />
          :
          <>
            {count ? (
              <div className={`${prefix}__count`}>
                <span className={`${prefix}__count-number`}>{count}</span>
                <span className={`${prefix}__count-text`}>
                  {t("catalogs:general.new_catalog_modal.field_selected", {
                    field: appColumnKeyProps?.name,
                  })}
                </span>
              </div>
            ) : null
            }
            <InputLabel label={t("catalogs:general.new_catalog_modal.catalogName")}>
              <InputTest
                onChange={handleChange}
                name="key"
                ref={inputRef}
                error={
                  catalogNames.includes(form?.["key"]?.trim())
                    ? t("catalogs:general.new_catalog_modal.name_used")
                    : false
                }
              />
            </InputLabel>
            <InputLabel label={t("catalogs:general.new_catalog_modal.catalogDesc")}>
              <InputTest minRows={3} onChange={handleChange} name="description" />
            </InputLabel>
            <div className={`${prefix}__buttons`}>
              <Button
                content={t("common:form.cancel")}
                onClick={() => dispatch(closeModal())}
                type="underline"
                sm
              />
              <Button
                disabled={
                  !form["key"] || catalogNames.includes(form?.["key"]?.trim())
                }
                content={t("catalogs:general.toolbar.new")}
                type="purple"
                sm
                onClick={handleSubmit}
              />
            </div>
          </>
        }
      </FlexComponent>
    </div>
  );
};

export default NewCatalog;
