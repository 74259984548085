import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const copyWorkflow = {
  [APP.COPY_WORKFLOW_STARTED]: (state) => ({
    ...state,
    copyWorkflow: {
      ...state.copyWorkflow,
      status: LOADING,
    },
  }),

  [APP.COPY_WORKFLOW_SUCCESS]: (state, { payload }) => ({
    ...state,
    copyWorkflow: {
      ...state.copyWorkflow,
      data: payload?.data?.[0],
      status: SUCCESS,
    },
  }),

  [APP.COPY_WORKFLOW_FAIL]: (state, { payload }) => ({
    ...state,
    copyWorkflow: {
      ...state.copyWorkflow,
      error: payload?.error,
      status: FAILURE,
    },
  }),
};

export default copyWorkflow;
