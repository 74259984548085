export const reduxActions = {
  ADD_ITEMS_STARTED: "catalog/add_items_started",
  ADD_ITEMS_FAIL: "catalog/add_items_fail",
  ADD_ITEMS_SUCCESS: "catalog/add_items_success",
  ADD_ITEMS_RESET: "catalog/add_items_reset",

  ADD_RESOURCE: "catalog/add_resource",
  REMOVE_RESOURCE: "catalog/remove_resource",

  ALL_STARTED: "catalog/all_started",
  ALL_FAIL: "catalog/all_fail",
  ALL_SUCCESS: "catalog/all_success",
  ALL_RESET: "catalog/all_reset",

  NEW_STARTED: "catalog/new_started",
  NEW_FAIL: "catalog/new_fail",
  NEW_SUCCESS: "catalog/new_success",
  NEW_RESET: "catalog/new_reset",

  ONE_STARTED: "catalog/one_started",
  ONE_FAIL: "catalog/one_fail",
  ONE_SUCCESS: "catalog/one_success",
  ONE_RESET: "catalog/one_reset",

  ORDER_BY: "catalog/order_by",

  PUT_STARTED: "catalog/put_started",
  PUT_FAIL: "catalog/put_fail",
  PUT_SUCCESS: "catalog/put_success",
  PUT_RESET: "catalog/put_reset",

  POST_STARTED: "catalog/post_started",
  POST_FAIL: "catalog/post_fail",
  POST_SUCCESS: "catalog/post_success",
  POST_RESET: "catalog/post_reset",

  REMOVE_PRODUCTS_STARTED: "catalog/remove_products_started",
  REMOVE_PRODUCTS_FAIL: "catalog/remove_products_fail",
  REMOVE_PRODUCTS_SUCCESS: "catalog/remove_products_success",
  REMOVE_PRODUCTS_RESET: "catalog/remove_products_reset",

  SHARE_STARTED: "catalog/share_started",
  SHARE_FAIL: "catalog/share_fail",
  SHARE_SUCCESS: "catalog/share_success",
  SHARE_UPDATE: "catalog/share_update",

  TO_ARCHIVED_STARTED: "catalog/to_archived_started",
  TO_ARCHIVED_FAIL: "catalog/to_archived_fail",
  TO_ARCHIVED_SUCCESS: "catalog/to_archived_success",

  TO_TRASH_STARTED: "catalog/to_trash_started",
  TO_TRASH_FAIL: "catalog/to_trash_fail",
  TO_TRASH_SUCCESS: "catalog/to_trash_success"
  
};

export const TYPE_CATALOG = "catalogue";

export const ACTIVE = "active";
export const ARCHIVED = "archived";
export const ELIMINATED = "eliminated";
export const INACTIVE = "inactive";
export const OWNER = "owner";
export const SHARED = "shared";
