import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Accordion } from "../index";
import { Badge, Button, NubeskIcons, Paragraph, Title } from "components/atoms";
import { CustomProgressBar } from "components/molecules";
import { FlexComponent } from "components/templates";
import SimpleScroller from "../Scroller/SimpleScroller";
import DownloadRow from "./Rows/DownloadRow";
import { resetSelection } from "_redux/ui/ui.actions";
import { fetchCatalogDetails, oneReset, share } from "_redux/catalog/catalog.actions";
import {
  countByField,
  resetAllPropsCatalog,
} from "_redux/product/product.actions";
import { selectCatalogOne } from "_redux/catalog/catalog.selector";
import { selectAdaptationAll } from "_redux/adaptation/adaptation.selector";
import { dateFormat } from "_utils/format";
import { UserRow } from "components/molecules";
import { useAdaptation, useAppSettings, useChannelLayout, useModal } from "_hooks";
import { DONE, FAILED } from "_constants/resource.constants";
import { analyzeAdaptationReset } from "_redux/analysis/analysis.action";
import capitalize from "_utils/string/capitalize";
import { ACTIVE } from "_constants/catalog.constants";

const prefix = "o-catalog";

const Catalog = () => {
  const dispatch = useDispatch();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { catalogId } = useParams();
  const { appColumnKeyProps } = useAppSettings();

  const catalog = useSelector(selectCatalogOne);
  const adaptationCat = useSelector(selectAdaptationAll);
  const { adaptations, fetchListAdaptations } = useAdaptation();
  const { fetch: fetchLayouts, listData: layouts } = useChannelLayout();
  const [countMaster, setCountMaster] = useState(0);

  const init = () => {
    if (!catalogId) return;
    fetch();
    fetchListAdaptations();
  };

  const fetch = () => dispatch(fetchCatalogDetails(catalogId));

  const getProgress = async () => {
    let counter = await dispatch(
      countByField({
        query: { _id: catalog?.foldersId.map((row) => row._id) },
        countField: { by: "process.reviewMaster" },
        partial: true,
      })
    );
    
    setCountMaster(counter?.data?.[0]);
  };

  const openXLSModal = async () => openModal({
    typeModal: "downloadXls",
    header: {
      text: <>
        <NubeskIcons icon="excel" />{" "}
        {t("products:actions.download_xls")}
      </>
    },
    modalProps: {
      items: catalog?.foldersId?.map((r) => r._id)
    }
  });

  useEffect(() => {
    fetchLayouts();
    return () => {
      dispatch(resetAllPropsCatalog());
      dispatch(resetSelection());
      dispatch(analyzeAdaptationReset());
    };
  }, []);

  useEffect(() => {
    init();
    
    return () => {
      dispatch(oneReset());
      dispatch(resetAllPropsCatalog());
    };
  }, [catalogId]);

  useEffect(() => {
    if (catalog && catalog?._id === catalogId) getProgress();
  }, [catalog]);

  return (
    <div className={prefix}>
      <div className={`${prefix}__header`}>
        <Title content={catalog?.key} secondary />
        <Button content="" />
      </div>
      <div className={`${prefix}__info`}>
        <Paragraph
          content={catalog?.data?.description || t("catalogs:general:catalog.withoutDesc")}
        />
        <div className={`${prefix}__data`}>
          <FlexComponent isVertical gap={15}>
            <div className={`${prefix}__data-spec`}>
              <span className="title">
                {t("catalogs:general.catalog.created")}
              </span>
              <span className="data">{dateFormat(catalog?.createdAt)}</span>
            </div>
            <div className={`${prefix}__data-spec`}>
              <span className="title">
                {t("catalogs:general.catalog.owner")}
              </span>
              <span className="data">{catalog?.userId?.email}</span>
            </div>
            <div className={`${prefix}__data-spec`}>
              <span className="title">
                {t("catalogs:general.catalog.number", {
                  key: appColumnKeyProps?.name
                })}
                
              </span>
              <span className="data">
                <Button
                  onClick={() => openXLSModal()}
                  content={<>
                    {`${catalog?.foldersId?.length || ""}`}
                    <small>({t("catalogs:general.catalog.downloadManifest")})</small>
                  </>}
                  type="underline"
                /></span>
            </div>
            <div className={`${prefix}__data-spec`}>
              <span className="title">
                {t("catalogs:general.catalog.lastModification")}
              </span>
              <span className="data">{dateFormat(catalog?.updatedAt)}</span>
            </div>
            
            {
              <CustomProgressBar
                available={countMaster?.approved || 0}
                total={catalog?.foldersId?.length}
              />
            }
          </FlexComponent>
        </div>
      </div>

      <div className={`${prefix}__content`}>
        <SimpleScroller disableTracksWidthCompensation>
          <div className={`${prefix}__padding`}>
            <Accordion
              title={t("catalogs:general.catalog.download_links")}
              headerRight={
                <Button
                  content={<NubeskIcons icon="plus" size={20} />}
                  type={"color"}
                  onClick={(e) => {
                    e.stopPropagation();
                    return openModal({
                      closeBackdrop: false,
                      typeModal: "generateLink",
                      header: {
                        text: t("catalogs:links.generateLink"),
                        icon: { type: "downloadLine", size: 25 }
                      },
                      modalProps: {
                        adaptationList: adaptations,
                        available: countMaster?.approved || 0,
                        total: catalog?.foldersId?.length,
                        catalog,
                        notAvailable: catalog?.resourcesId?.filter(
                          (r) => ([
                            DONE,
                            FAILED
                          ].indexOf(r.step) === -1)
                        ).map((r) => r.process.adaptationId)
                      }
                    });
                  }}
                />
              }
              isOpen={true}
              content={
                <FlexComponent isVertical>
                  {adaptationCat && layouts && catalog?.resourcesId?.map((r, key) => {
                    let data = {...r};
                    if (data?.status !== ACTIVE) return null;
                    let layout;
                    let adap;

                    for (let l of layouts) {                      
                      for (let a of l.adaptationsId) {
                        if (a._id === data?.process?.adaptationId) {
                          layout = l;
                          adap = a;
                          break;
                        }
                      }

                      if (layout || adap) break;
                    }
                    
                    if (!adap)  {
                      adap = adaptationCat.filter((a) => 
                        !!a?.attributes?.isMaster && data?.process?.adaptationId === a._id
                      )?.[0];
                    }
                    return (
                      <DownloadRow
                        adaptationId={data.process.adaptationId}
                        adaptationList={adaptations}
                        catalog={catalog}
                        data={data}
                        disabled={data.step !== DONE}
                        key={key}
                        locked={data.step !== DONE}
                        name={
                          <div className="--list-resource">
                            {!adap?.attributes?.isMaster &&
                              <Badge content={layout?.key} border="purple" small/>
                            }
                            {`${capitalize(adap?.name ?? "")}`}
                          </div>
                        }
                        total={
                          (countMaster?.approved || 0) +
                          catalog?.foldersId?.length
                        }
                      />
                    );
                  })}
                </FlexComponent>
              }
            />

            <Accordion
              title={t("catalogs:general.catalog.shared_with")}
              headerRight={
                <Button
                  content={<NubeskIcons icon="plus" size={20} />}
                  type={"color"}
                  sqr
                  onClick={(e) => {
                    e.stopPropagation();
                    return openModal({
                      typeModal: "share",
                      header: {
                        text: t("catalogs:general.share.share"),
                        rightItem: catalog?.key,
                      },
                      modalProps: {
                        _id: catalog._id,
                        buttons: {
                          share: t("catalogs:general.share.share")
                        },
                        share: catalog?.share?.map((s) => s._id),
                        onShare: (selectedUsers) => 
                          dispatch(share(catalog._id, selectedUsers?.map((u) => u._id), true))
                      }
                    });
                  }}
                />
              }
              isOpen={true}
              content={
                <FlexComponent isVertical>
                  {catalog?.share?.map((user, index) => (
                    <UserRow
                      key={index}
                      email={user.email}
                      firstName={user?.account?.firstName ?? ""}
                      lastName={user?.account?.lastName ?? ""}
                      onClick={() => {}}
                      inCatalogs
                    />
                  ))}
                </FlexComponent>
              }
            />
          </div>
        </SimpleScroller>
      </div>
    </div>
  );
};

export default Catalog;
