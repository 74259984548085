import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import { Button } from "components/atoms";

const prefix = "m-button-color";

const ButtonColor = ({ disabled, onChange, placement, value }) => {
  const [active, setActive] = useState(false);
  const [marginX, setMarginX] = useState(0);
  const [marginY, setMarginY] = useState(0);

  const assignPlacement = ({ pageX, pageY }) => {
    switch (placement) {
      case "top":
        setMarginX(pageX - 100);
        if (pageY < 305) {
          setMarginY(pageY + 20);
        } else {
          setMarginY(pageY - 305);
        }
        
        break;
      case "bottom":
        setMarginX(pageX - 100);
        setMarginY(pageY + 15);
        break;
      case "left":
        setMarginX(pageX - 250);
        setMarginY(pageY - 200);
        break;
      case "right":
        setMarginX(pageX + 100);
        setMarginY(pageY - 200);
        break;
    }
  };

  useEffect(() => {
    if (disabled) setActive(false);
  }, [disabled]);

  return (
    <div className={prefix} style={{
      "--selector-top": `${marginY}px`,
      "--selector-left": `${marginX}px`
    }}>
      
      <Button 
        disabled={!!disabled}
        leftIcon={
          <div className={`${prefix}__square`} style={{ background: value }}></div>
        }
        content={value}
        onClick={(e) => {
          let { pageX, pageY } = e;
          assignPlacement({ pageX, pageY})
          setActive((a) => !a)
        }}
        type="primary"
        xs
      />
      {active && <>
        <div 
          className={`${prefix}__backdrop`} 
          onClick={() => setActive(false)}>
        </div>
        <div className={`${prefix}__selector`}>
          <SketchPicker
            color={value}
            onChange={onChange}
          />
        </div>
      </>}
    </div>
  );
};

ButtonColor.propTypes = {
  placement: PropTypes.oneOf(["top", "bottom", "left", "right"])
};

ButtonColor.defaultProps = {
  placement: "bottom"
};

export default ButtonColor;
