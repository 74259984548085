import { reduxActions as PRODUCT } from "_constants/product.constants";

const updateAll = {
  [PRODUCT.UPDATE_FILES]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: {
        ...state?.one?.data ?? {},
        filesId: [
          ...state?.one?.data?.filesId ?? [],
          ...payload?.data ?? []
        ]
      }
    }
  })
};

export default updateAll;
