import { FlexComponent } from "components/templates/index";
import React from "react";
import classNames from "classnames";

const prefix = "o-catalog-template-row";

const TemplateRow = ({ left, right, center, typeRow, className }) => {
  const ClassForComponent = classNames(prefix, {
    [`${prefix}--${typeRow}`]: typeRow,
    [className]: className,
  });

  return (
    <div className={ClassForComponent}>
      <FlexComponent>{left}</FlexComponent>
      <FlexComponent>{center}</FlexComponent>
      <FlexComponent>{right}</FlexComponent>
    </div>
  );
};

export default TemplateRow;
