import { NubeskIcons } from "components/atoms/index";
import FilterArea from "components/molecules/FilterGroup/FilterArea";

const InitialProcess = ({ bussy, onBussy, onChange, prefix }) => {
  return (
    <>
      {!bussy ?
        <>
          <span className={`${prefix}__paste-label`}>
            Copia y pega una columna de GTINs <br />
            (max 1000 por consulta).
          </span>
          <FilterArea
            disabled={bussy}
            onBussy={onBussy}
            onChange={(a) => {
              if (a) {
                onChange(a);
              }
            }}
          />
        </>
      :
        <div className={`${prefix}__paste-loading`}>
          <NubeskIcons className="u-spin" icon="spin" size="5rem" />
          <span>Buscando GTINs</span>
        </div>
      }
    </>
  );
};

export default InitialProcess;
