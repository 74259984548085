import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import AuthRoutes from './AuthRoutes';

const GuessRoutes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/auth">
          <AuthRoutes />
        </Route>
        <Redirect to="/auth/login" />
      </Switch>
    </Router>
  );
};

export default GuessRoutes;
