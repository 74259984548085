import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FlexComponent } from "components/templates";
import RowTemplate from "../RowTemplate/RowTemplate";
import { Toast } from "components/molecules";
import { Button, CircleStatus } from "components/atoms";
import { useAppSettings, useProduct, useTooltip } from "_hooks";
import { selectUser } from "_redux/user/user.selectors";
import { openManageImages } from "_redux/ui/actions/ui_manageImages.actions";
import { $$FILE, $$FOLDER, $$LINK } from "_constants/permission.constants";

const prefix = "m-master-row";

const AdaptationRow = ({
  adaptationId,
  attributeKey,
  date,
  isActive,
  isApproved,
  layoutKey,
  onClick,
  status,
  title,
  folderId,
  qty,
  sku,
  onGenerate,
  onDelete,
  onDownload,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { key } = useParams();
  const { fetchProductDetails } = useProduct();
  const user = useSelector(selectUser);
  const { saveLayoutData } = useProduct();
  const { appColumnKeyProps } = useAppSettings();
  const { injectedProps: injectedPropsSku } = useTooltip();
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Master photo missing approval",
    delay: 100,
  });

  const [newSku, setNewSku] = useState(sku);
  const [edit, setEdit] = useState(false);
  const [generating, setGenerating] = useState(false);

  let props = status === "approved" ? undefined : injectedProps;

  const handleChange = ({ target: { value } }) => setNewSku(value);

  const handleKeyDown = ({ keyCode, which }) => {
    if ((keyCode === 13, which === 13)) {
      setEdit(false);
    }
    if ((keyCode === 27, which === 27)) {
      setNewSku(sku);
      setEdit(false);
    }
  };

  const generate = async () => {
    if (generating) return;
    setGenerating(true);
    await onGenerate(adaptationId);
    await fetchProductDetails(key);
    setGenerating(false);
  };
 
  const getOptions = () => {
    let finalOpts = [];
    let opts = {
      edit: {
        onClick: () => setEdit(true),
        content: t("products:dataPhotos.edit", { key: appColumnKeyProps?.name }),
      },
      generate: {
        onClick: () => generate(adaptationId),
        content: (
          <>
            {date && qty > 0
              ? t("products:dataPhotos.regenerate")
              : t("products:dataPhotos.generate")}
          </>
        )
      },
      download: {
        onClick: () => {
          let d = new Date();
          onDownload(`_${qty}_${d.getDate()}_${d.getMonth()}_${d.getFullYear()}`);
        },
        content: t("products:dataPhotos.download"),
      },
      delete: {
        onClick: () => onDelete(adaptationId),
        content: t("products:dataPhotos.delete_channel"),
      },
      sort: {
        onClick: () => dispatch(openManageImages()),
        content: t("manage:images.orderFiles"),
      }
    };

    if (isApproved) {
      if (user.canDoIt($$FOLDER.EDIT)) finalOpts.push(opts.generate);
      if (qty > 0 && user.canDoIt($$LINK.DOWNLOAD)) finalOpts.push(opts.download);      
    }

    if (user.canDoIt($$FILE.DELETE)) finalOpts.push(opts.delete);

    if (isActive && user.canDoIt($$FOLDER.EDIT)) {
      finalOpts = [opts.sort, ...finalOpts];
    }

    return finalOpts;
  };

  useEffect(() => {
    if (!generating) return;
    toast.info(
      <Toast
        area={t("products:dataPhotos.feedback.generateAdaptation.area")}
        info={t("products:dataPhotos.feedback.generateAdaptation.started")}
      />
    );
  }, [generating]);

  return (
    <RowTemplate
      isAdaptation
      onClick={onClick}
      title={title}
      qty={qty}
      isActive={isActive}
      options={getOptions()}
    >
      <FlexComponent>
        {sku ? (
          <input
            className={`${prefix}__input`}
            value={newSku}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            readOnly={!edit}
            onClick={(e) => e.stopPropagation()}
            onDoubleClick={() => setEdit(true)}
            /* onBlur={() => setEdit(false)} */
            {...injectedPropsSku}
          />
        ) : null}
        {!edit ? (
          date && qty > 0 ? (
            <>
              <CircleStatus status={qty === 0 ? "empty" : status} />
              <span className={`${prefix}__date`}>{date}</span>
            </>
          ) : (
            isApproved && (
              <div {...props}>
                <button
                  disabled={generating || status !== "approved"}
                  onClick={(e) => {
                    e.stopPropagation();
                    generate(adaptationId);
                  }}
                  className={`${prefix}__generate-button`}
                >
                  {t("products:dataPhotos.generate")}
                </button>
              </div>
            )
          )
        ) : (
          <Button
            content={t("products:dataPhotos.save")}
            sm
            type={"blue"}
            disabled={!newSku}
            onClick={(e) => {
              e.stopPropagation();
              setEdit(false);
              saveLayoutData(layoutKey, folderId, {
                data: {
                  [attributeKey]: { value: newSku }
                }
              }, {
                reqType: "PUT",
                feedback: {
                  error: true,
                  success: {
                    area: "products:dataPhotos.feedback.changeSku.area",
                    info: "products:dataPhotos.feedback.changeSku.success"
                  }
                }
              });
            }}
            className={`${prefix}__save-button`}
          />
        )}
      </FlexComponent>
    </RowTemplate>
  );
};

export default AdaptationRow;
