import { reduxActions as UI } from "_constants/ui.constants";

const images = {
  [UI.CURRENT_ADAPTATION]: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      currentAdaptation: payload,
    },
  }),
  [UI.CURRENT_IMAGE]: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      currentImage: payload,
    },
  }),
  [UI.SET_IMAGES]: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      images: payload,
    },
  }),
  [UI.CARROUSEL]: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      carrousel: payload ?? [],
    },
  }),
};

export default images;
