import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { ChannelAdaptationsCard } from "components/organism";
import { GridLayout } from "components/templates";
import { useAdaptation, useChannelLayout } from "_hooks";
import { SimpleScroller } from "components/organism/index";

const prefix = "v-channel-adaptations";

const ChannelAdaptations = () => {
  const { layoutKey } = useParams();
  const { fetchAdaptation, onList, fetchMasters } = useAdaptation();
  const { fetchOne, layoutReset } = useChannelLayout();

  useEffect(() => {
    fetchAdaptation(layoutKey);
    fetchOne({ f: ["name", "key"], w: { key: layoutKey }});
    fetchMasters();

    return () => {
      layoutReset();
    };
  }, []);
    
  return (
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        <SimpleScroller>
          <GridLayout settingsAdaptations>
            {onList?.map((row, i) => (
              <ChannelAdaptationsCard 
                adaptation={row}
                key={i}
                layoutKey={layoutKey}
              />
            ))}
          </GridLayout>
        </SimpleScroller>
      </div>
    </div>
  )
};

export default ChannelAdaptations;
