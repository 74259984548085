import { useRef, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleTooltip } from "_redux/ui/ui.actions";

const useTooltip = ({ delay, variant, externalTip, position } = {}) => {
  const dispatch = useDispatch();

  let timer = useRef(null);

  const showTip = useCallback(() => {
    timer.current = setTimeout(() => {
      dispatch(toggleTooltip({ active: true }));
    }, delay || 500);
  });
  useEffect(() => {
    return () => dispatch(toggleTooltip({ active: false }));
  }, []);
  const closeToolTip = () => {
    clearTimeout(timer.current);
    dispatch(toggleTooltip({ active: false, variant, position }));
  };

  const onEnterToolTip = (e) => {
    const { target } = e;
    const { clientWidth, scrollWidth } = e.target;
    dispatch(toggleTooltip({ top: e.pageY, left: e.pageX }));
    if (externalTip) {
      dispatch(toggleTooltip({ tip: externalTip }));
      return showTip();
    }
    if (scrollWidth > clientWidth) {
      dispatch(toggleTooltip({ tip: target.textContent || target.value }));
      return showTip();
    }
  };
  const onMouseMove = (e) => {
    closeToolTip();
    timer.current = setTimeout(() => {
      onEnterToolTip(e);
    }, 50);
  };

  return {
    injectedProps: {
      onMouseMove,
      onMouseOut: closeToolTip,
    },
  };
};

export default useTooltip;
