import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, NubeskIcons } from "components/atoms";
import {
  Counter,
  CustomProgressBar,
  MultipleProgress,
} from "components/molecules";
import { useAppSettings, useLocalStorage } from "_hooks";
import { BulkActionsCounter } from "components/organism";
import { closeModal, toggleRefresh } from "_redux/ui/ui.actions";
import { analyzeApproved } from "_redux/analysis/analysis.action";
import {
  approvePhotos,
  resetAnalyze
} from "_redux/product/product.actions";
import { selectAnalysisApprovedBulk } from "_redux/analysis/analysis.selector";
import {
  selectAnalyzeStatus
} from "_redux/product/product.selector";
import { LOADING } from "_constants/redux.constants";

const dic = {
  minlenFilesId: "minlenFilesId",
  required: "Without photos",
  approved: "Approved",
  toReview: "To Approve",
  incomplete: "Incomplete",
};

let colors = {
  "Approved": "green",
  "To Approve": "yellow",
  "Incomplete": "red",
  "Without photos": "gray"
};

const specificOrder = ["approved", "toReview", "incomplete", "required"];
const prefix = "o-approve-photo-modal";

const ApprovePhotoModal = ({ all, items, selected, onFinished }) => {
  const { t } = useTranslation();
  const [inLocal, setLocal] = useLocalStorage();

  const dispatch = useDispatch();
  const { data: analysis } = useSelector(selectAnalysisApprovedBulk);
  const status = useSelector(selectAnalyzeStatus);

  const { appColumnKeyProps } = useAppSettings();
  const [arraySuccess, setArraySuccess] = useState();
  const [completed, setCompleted] = useState(0);
  const [counter, setCounter] = useState(inLocal("counterApprovePhoto") || 3);
  const [current, setCurrent] = useState([]);
  const [data, setData] = useState({});
  const [toApprove, setToApprove] = useState(false);
  const [total, setTotal] = useState(0);
  const [withError, setWithError] = useState(0);

  useEffect(() => {
    return () => dispatch(resetAnalyze());
  }, []);

  useEffect(() => {
    if (!counter) return false;
    if (data[counter]) return calculatePercentages();
    getData();
  }, [counter]);

  useEffect(() => {
    if (!analysis) return;
    let _data = {};
    for (let element of analysis) {
      for (let field in element) {
        if (field === "minlenFilesId") continue;
        _data = {
          ..._data,
          [element.minlenFilesId]: {
            ...(_data[element.minlenFilesId] ? _data[element.minlenFilesId] : []),
            [field]: { ...element[field], original: dic[field] },
          },
        };
      }
    }
    
    setData(_data);
  }, [analysis]);

  useEffect(() => {
    if (!Object.keys(data).length) return false;
    calculatePercentages();
  }, [data]);

  const getData = () => dispatch(analyzeApproved(items, counter));

  const calculatePercentages = () => {
    let needed = data[counter];
    let sorted = specificOrder.reduce((acc, key) => [...acc, needed[key]], []);
    let percent = 0;

    let _current = sorted.map((item) => {
      percent +=
        (item._count /
          (all
            ? items.length - selected.length
            : selected.length)) *
        100;
      
      return { ...item, percent, value: item._count, color: colors[item.original] };
    });
    setCurrent(_current);
    setTotal(data[counter]?.toReview?.folders?.length ?? 0);
  };

  const sendApprove = async () => {
    setToApprove(true);
    setLocal("counterApprovePhoto", counter);
    
    let array = data[counter]?.toReview?.folders ?? [];
    let aSuccess = [];

    for (let _id of array) {
      let response = await dispatch(approvePhotos(_id, true));
      setCompleted((p) => p + 1);

      if (response?.data?.[0]) {
        let { _id, process } =  response?.data?.[0];
        aSuccess.push({ _id, process});
      }
    }
    
    if (aSuccess.length) setArraySuccess(aSuccess);
  };

  const close = () => {
    if (arraySuccess) {
      if (typeof onFinished === "function") {
        onFinished(arraySuccess);
      } else {
        dispatch(toggleRefresh("products", arraySuccess));
      }
    }
    dispatch(closeModal());
  };

  return (
    <div className={prefix}>
      {toApprove ? (
        <BulkActionsCounter
          completed={completed}
          legends={{
            action: t("modals:approvePhotoModal.bulkCounter.action"),
            complete: t("modals:approvePhotoModal.bulkCounter.complete", { field: appColumnKeyProps?.name }),
            error: t("modals:approvePhotoModal.bulkCounter.error", { field: appColumnKeyProps?.name }),
            finish: t("modals:approvePhotoModal.bulkCounter.finish"),
            success: t("modals:approvePhotoModal.bulkCounter.success"),
            total: t("modals:approvePhotoModal.bulkCounter.total", { field: appColumnKeyProps?.name })
          }}
          onFinish={close}
          withError={withError}
          total={total}
        />
      ) : (
        <>
          <div className={`${prefix}__counter`}>
            <span>{t("modals:approvePhotoModal.min", { key: appColumnKeyProps?.name })}</span>
            <Counter
              value={counter}
              setValue={setCounter}
              max={!data[counter]?.toReview?._count ? counter : 10}
              min={
                data[counter - 1] || data[counter]?.incomplete?._count
                  ? 1
                  : counter
              }
              initial={inLocal("counterApprovePhoto") || 1}
            />
          </div>
          <CustomProgressBar
            loading={status === LOADING}
            child={<MultipleProgress counts={current || []} />}
            total={
              all
                ? items.length - selected?.length ?? 0
                : selected?.length
            }
            available={data?.[counter]?.approved?._count}
          />
          <div className={`${prefix}__warning`}>
            <NubeskIcons icon="warning" />
            <span>{t("modals:approvePhotoModal.warning", { key: appColumnKeyProps?.name })}</span>
          </div>
          <div className={`${prefix}__buttons`}>
            <Button
              content={t("common:form.cancel")}
              onClick={() => close()}
              type="underline"
              sm
            />
            <Button
              content={t("modals:approvePhotoModal.start")}
              onClick={sendApprove}
              type="purple"
              sm
              disabled={!data[counter]?.toReview?._count}
            />
          </div>
        </>
      )}
    </div>
  );
};

ApprovePhotoModal.propTypes = {
  all: PropTypes.bool,
  selected: PropTypes.array
};

ApprovePhotoModal.defaultProps = {
  selected: []
};

export default ApprovePhotoModal;
