import { reduxActions as UI } from "_constants/ui.constants";

const modal = {
  [UI.OPEN_MODAL]: (state, { payload: { typeModal, modalProps, header, ...props } }) => ({
    ...state,
    modal: {
      typeModal,
      isOpen: true,
      modalProps,
      header,
      ...props
    },
  }),
  [UI.CLOSE_MODAL]: (state) => ({
    ...state,
    modal: {
      ...state.modal,
      isOpen: false
    },
  }),
};

export default modal;
