import updateCatalogProvider from "./reducer/updateCatalogProvider";
import updateCatalogRetail from "./reducer/updateCatalogRetail";
import updateGtinReducer from "./reducer/updateGtin";
import updateGtinProviderReducer from "./reducer/updateGtinProvider";
import initialSyncfoniaState from "./syncfonia.initialState";


const syncfoniaReducer = (state = initialSyncfoniaState, action) => {
  const reducers = {
    ...updateCatalogProvider,
    ...updateCatalogRetail,
    ...updateGtinReducer,
    ...updateGtinProviderReducer
  };
  
  let { type } = action;
  if (reducers[type] === undefined) return state;
  
  return reducers[type](state, action);
};

export default syncfoniaReducer;
