import { useTranslation } from "react-i18next";
import { Accordion } from "components/organism";
import { Button, NubeskIcons } from "components/atoms";
import LibGrid from "./LibGrid";
import { useMediaLib, useModal } from "_hooks";
import { useEffect } from "react";
import { CLUSTER_ACCOUNT, CLUSTER_USER } from "_constants/mediaLib.constants";

const LibContent = ({ canvas, prefix }) => {
  const { t } = useTranslation();
  const { fetchOwn, fetchShared, sharedData, ownData } = useMediaLib();
  const { openModal } = useModal();

  const openAddMedia = (e, clusterType = CLUSTER_USER) => {
    e.stopPropagation();
    openModal({
      typeModal: "uploadMediaLib",
      header: {
        text: t("modals:uploadMediaLib.title"),
        icon: {
          type: "upload",
          size: "1.8rem"
        }
      },
      modalProps: {
        clusterType
      }
    })
  };

  useEffect(() => {
    fetchOwn();
    fetchShared();
  }, []);

  return (
    <>
      <Accordion
        content={
          <LibGrid canvas={canvas} prefix={prefix} data={ownData} />
        }
        title={t("nbsk:editor.lib.myLib")}
        headerRight={
          <Button
            className="a-accordion-button"
            content={<NubeskIcons icon={"plus"} />}
            onClick={(e) => openAddMedia(e, CLUSTER_USER)}
          />
        }
        isOpen={true}
      />

      <Accordion
        content={
          <LibGrid canvas={canvas} prefix={prefix} data={sharedData} />
        }
        title={t("nbsk:editor.lib.shared")}
        headerRight={
          <Button
            className="a-accordion-button"
            content={<NubeskIcons icon={"plus"} />}
            onClick={(e) => openAddMedia(e, CLUSTER_ACCOUNT)}
          />
        }
        isOpen={true}
      />
    </>
  );
};

export default LibContent;
