import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FlexComponent } from "components/templates";
import { NubeskIcons } from "components/atoms";
import { useEffect } from "react";

const Accordion = ({
  title,
  headerLeft,
  headerRight,
  content,
  isColored,
  isOpen,
  className,
}) => {

  const [active, setActive] = useState(false);
  const prefix = "o-accordion";
  const classForComponent = classNames(prefix, {
    "is-active": active,
    "is-colored": isColored,
    [className]: className,
  });

  useEffect(() => {
    if (active !== isOpen) setActive(isOpen);
  }, []);

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__header`} onClick={() => setActive(!active)}>
        <FlexComponent sm gap={7}>
          {headerLeft}
          <span className={`${prefix}__header-title`}>{title}</span>
        </FlexComponent>
        <FlexComponent sm gap={0}>
          {headerRight}
          
          <NubeskIcons icon="down" className={`${prefix}__arrow`} />
        </FlexComponent>
      </div>

      <div className={`${prefix}__content`}>
        <div className={`${prefix}__content-body`}>{content}</div>
      </div>
    </div>
  );
};

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  headerLeft: PropTypes.node,
  headerRight: PropTypes.node,
  content: PropTypes.node.isRequired,
  isColored: PropTypes.bool,
};

export default Accordion;

