import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FlexTable } from "components/templates";
import { Accordion, SimpleScroller } from "components/organism";
import { Button, FlexCell, NubeskIcons, Switch } from "components/atoms";
import { InputTest, Select } from "components/molecules/Inputs";
import { useAppSettings, useAttribute, useModal, usePrevious } from "_hooks";
import { LOADING } from "_constants/redux.constants";
import { DERIVED_TYPE } from "_constants/attribute.constants";

const prefix = "o-attribute-form-modal";

const AttributeFormModal = ({ attributeId, layoutKey }) => {
  const { appCopyWorkflow } = useAppSettings();
  const {
    attributeFetch,
    createAttribute,
    dataTypeOpts,
    fetchList,
    listData,
    listDerived,
    oneData,
    oneReset,
    oneStatus,
    postReset,
    postStatus,
    putReset,
    putStatus,
    translateOpts,
    typeOpts,
    updateAttribute
  } = useAttribute();

  const { closeModal } = useModal();
  const { t } = useTranslation();

  const [attrAllowData, setAttrAllowData] = useState();
  const [attrDataType, setAttrDataType] = useState();
  const [attrDerived, setAttrDerived] = useState();
  const [attrName, setAttributeName] = useState();
  const [attrEditContent, setAttrEditContent] = useState();
  const [attrRequired, setAttrRequired] = useState();
  const [attrEditable, setAttrEditable] = useState(true);
  const [attrToExport, setAttrToExport] = useState(true);
  const [attrOverwritable, setAttrOverwritable] = useState();
  const [attrToFilter, setAttrToFilter] = useState();
  const [attrTranslate, setAttrTranslate] = useState();
  const [attrRules, setAttrRules] = useState();
  const [attrType, setAttrType] = useState();
  const [attrValidateRules, setAttrValidateRules] = useState();
  const [derivedOpts, setDerivedOpts] = useState();
  const [inited, setInited] = useState(false);

  let classForField = classNames('--form-control-vertical', {
    '--with-key': !!oneData
  });

  const prevType = usePrevious(attrType);

  const send = async () => {
    let response;
    let data = {
      attrName,
      attrAllowData,
      attrDataType,
      attrDerived,
      attrRequired,
      attrEditable,
      attrToExport,
      attrOverwritable,
      attrToFilter,
      attrRules,
      attrType,
      attrValidateRules
    };

    if (attrType?.value === DERIVED_TYPE) {
      data = {
        ...data,
        attrDerived,
        attrTranslate
      }
    }
    if (oneData) {
      response = await updateAttribute(layoutKey, attributeId, data);
    } else {
      response = await createAttribute(layoutKey, data);
    }
    if (response?.data) closeModal();
  };

  useEffect(() => {
    fetchList();
    if (attributeId) {
      attributeFetch({ w: { _id: attributeId }}, layoutKey);
    } else {
      setInited(true);
    }

    return () => {
      oneReset();
      postReset();
      putReset();
    }
  }, [])

  useEffect(() => {
    if (attrType?.value && prevType?.value === DERIVED_TYPE) {
      setAttrDerived();
    }
  }, [attrType?.value]);

  useEffect(() => {
    if (!inited && oneData && derivedOpts?.length) {

      setTimeout(() => {
        setAttributeName(oneData?.name ?? "");
        setAttrRules(oneData?.validator?.prompts);
        setAttrDataType(dataTypeOpts.filter((r) => r.value === oneData?.class?.datatype)?.[0] ?? dataTypeOpts[0]);
        setAttrRequired(oneData?.flag?.require ?? false);
        setAttrEditable(oneData?.flag?.editable ?? false);
        setAttrOverwritable(oneData?.flag?.overwritable ?? false);
        setAttrToFilter(false);
        setAttrToExport(oneData?.flag?.toExport ?? false);
        setAttrType(oneData?.type ? typeOpts.filter((t) => t.value === oneData.type)?.[0] : typeOpts[0]);

        
        if (oneData?.type !== DERIVED_TYPE) {
          setInited(true);
        } else {
          setAttrTranslate([...translateOpts, ...appCopyWorkflow?.data?.forTranslation?.languages?.map((t) => ({
            label: t.title,
            value: t["ietf-code"]
          }))].filter((r) => r.value === oneData?.validator?.translate)?.[0]);
        }
        
      }, [100]);
    }
  }, [oneData, derivedOpts]);

  useEffect(() => {
    if (inited && attrDerived?.value) {
      let att = listData.filter((a) => a.key === attrDerived.value).pop();

      setAttrRules(att?.validator?.prompts);
      setAttrDataType(dataTypeOpts.filter((r) => r.value === att?.class?.datatype)?.[0] ?? dataTypeOpts[0]);
      setAttrRequired(att?.flag?.require ?? false);
      setAttrEditable(att?.flag?.editable ?? false);
      setAttrOverwritable(att?.flag?.overwritable ?? false);
      setAttrToFilter(false);
      setAttrToExport(att?.flag?.toExport ?? false);
      
    }
  }, [attrDerived]);

  useEffect(() => {
    if (listData) {  
      setDerivedOpts(listDerived(listData, {
        className: "--alias-select-option",
      }));
    }
  }, [listData]);

  useEffect(() => {
    if (!inited && derivedOpts?.length && oneData?.type === DERIVED_TYPE) {
      let derivedSelected = derivedOpts.filter((d) => d?.value === oneData?.data?.reference)?.[0];
      setAttrDerived(derivedSelected);
      setInited(true)
    }
  }, [derivedOpts, oneData]);

  return (
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        <SimpleScroller isHorizontal={false}>
          <div className={`${prefix}__basic-settings`}>
            <div className={`${prefix}__basics`} >
              {oneData &&
                <div className="--form-control-vertical --key">
                  <div className="--form-label-vertical">{t("settings:layoutAttributes.modal.fields.key")}</div>
                  <InputTest
                    name="key"
                    readOnly={true}
                    value={oneData?.key}
                  />
                </div>
              }

              <div className={classForField}>
                <div className="--form-label-vertical">{t("settings:layoutAttributes.modal.fields.name.label")}</div>
                <InputTest
                  name="name"
                  onChange={(e) => setAttributeName(e.target.value)}
                  readOnly={false}
                  placeholder="Escribe el nombre del nuevo campo"
                  value={attrName}
                />
              </div>

              {attrType?.value === DERIVED_TYPE ? (
                <div className="--form-control-vertical">
                  <div className="--form-label-vertical">
                    {t("settings:layoutAttributes.modal.fields.attributeDerived.label")}
                  </div>
                  
                  <Select
                    name="attrDerived"
                    emptyLabel={t("settings:layoutAttributes.modal.fields.attributeDerived.placeholder")}
                    onChange={(v) => {
                      if (inited) setAttrDerived(v);
                    }}
                    key="attrDerived"
                    options={derivedOpts}
                    value={attrDerived}
                  />
                </div>
              ): (
                <div className="--form-control-vertical">
                  <div className="--form-label-vertical">
                    {t("settings:layoutAttributes.modal.fields.allowDataType.label")}
                  </div>
                  <Select
                    disabled
                    key="allowData"
                    name="allowDataType"
                    onChange={(e) => setAttrAllowData(e?.target.value)}
                    options={[
                      "Cualquier valor ingresado por el usuario",
                      "Restringido a valores de la lista"
                    ]}
                    value={attrAllowData}
                  />
                </div>
              )}

              {attrType?.value === DERIVED_TYPE && 
                <div className="--form-control-vertical">
                  <div className="--form-label-vertical">
                    {t("settings:layoutAttributes.modal.fields.translate.label")}
                  </div>
                  <Select
                    name="attTranslate"
                    onChange={setAttrTranslate}
                    key="attTranslate"
                    options={[...translateOpts, ...appCopyWorkflow?.data?.forTranslation?.languages?.map((t) => ({
                      label: t.title,
                      value: t["ietf-code"]
                    })) ?? []]}
                    value={attrTranslate}
                  />
                </div>
              }
              
              <div className="--form-control-vertical">
                <div className="--form-label-vertical">
                  {t("settings:layoutAttributes.modal.fields.rules.label")}
                </div>
                <InputTest
                  name="rules"
                  onChange={(e) => setAttrRules(e.target.value)}
                  value={attrRules}
                  placeholder={t("settings:layoutAttributes.modal.fields.rules.placeholder")}
                  rows={3}
                />
              </div>

              
              <div className="--form-control">
                <div>
                  <NubeskIcons className="u-purple" icon="copy" size="19px" /> {t("settings:layoutAttributes.modal.fields.validateRules.label")}
                </div>
                <Switch
                  disabled
                  checked={attrValidateRules}
                  onChange={setAttrValidateRules}
                />
              </div>
              <div className="--form-control">
                <div>
                  <NubeskIcons className="u-purple" icon="copy" size="19px" /> {t("settings:layoutAttributes.modal.fields.editContent.label")}
                </div>
                <Switch
                  disabled
                  checked={attrEditContent}
                  onChange={setAttrEditContent}
                />
              </div>
            </div>
            <div className={`${prefix}__type-n-rules`} >
              <div className="--form-control-vertical">
                <div className="--form-label-vertical">{t("settings:layoutAttributes.modal.fields.type.label")}</div>
                <Select
                  name="type"
                  onChange={setAttrType}
                  options={typeOpts}
                  value={attrType}
                />
              </div>

              <div className="--form-control-vertical">
                <div className="--form-label-vertical">{t("settings:layoutAttributes.modal.fields.dataType.label")}</div>
                <Select     
                  name="dataType"
                  onChange={setAttrDataType}
                  options={dataTypeOpts}
                  value={attrDataType}
                />
              </div>

              <div className="--form-label">
                <NubeskIcons className="u-purple" icon="copy" size="19px" /> {t("settings:layoutAttributes.modal.attributeRules")}
              </div>

              <div className="--form-control">
                {t("settings:layoutAttributes.modal.fields.require.label")}
                <Switch
                  onChange={setAttrRequired}
                  checked={attrRequired}
                />
              </div>

              <div className="--form-control">
                {t("settings:layoutAttributes.modal.fields.editable.label")}
                <Switch
                  onChange={setAttrEditable}
                  checked={attrEditable}
                />
              </div>

              <div className="--form-control">
                {t("settings:layoutAttributes.modal.fields.overwritable.label")}
                <Switch
                  onChange={setAttrOverwritable}
                  checked={attrOverwritable}
                />
              </div>
              
              <div className="--form-control">
                {t("settings:layoutAttributes.modal.fields.toExport.label")}
                <Switch
                  onChange={setAttrToExport}
                  checked={attrToExport}
                />
              </div>
            </div>
          </div>
          <div className={`${prefix}__file-headers`} >
            <Accordion
              title={t("settings:layoutAttributes.modal.headersTitle")}
              isOpen={false}
              content={<FlexTable 
                scrollable={false}
                gridTemplateColumns="80px repeat(2, 1fr)"
                headers={<>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.row")}</FlexCell>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.tagId")}</FlexCell>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.valueToExport")}</FlexCell>
                </>}
                rows={[<>
                  <FlexCell>1</FlexCell>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.nubeskId")}</FlexCell>
                  <FlexCell>
                    <Select
                      disabled
                      options={[t("settings:layoutAttributes.modal.table.nubeskId")]}
                      value={t("settings:layoutAttributes.modal.table.nubeskId")}
                      xs
                    />
                  </FlexCell>
                </>, <>
                  <FlexCell>2</FlexCell>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.attributeName")}</FlexCell>
                  <FlexCell>
                    <Select
                      disabled
                      options={[t("settings:layoutAttributes.modal.table.attributeName")]}
                      value={t("settings:layoutAttributes.modal.table.attributeName")}
                      xs
                    />
                  </FlexCell>
                </>]}
              />}
            />
          </div>
        </SimpleScroller>
      </div>
      <div className={`${prefix}__buttons`}>
        <Button
          content={t("common:form.cancel")}
          type="underline"
          onClick={() => closeModal()}
          sm
        />
        <Button
          disabled={oneStatus === LOADING || postStatus === LOADING || putStatus === LOADING || (attrType?.value === DERIVED_TYPE && !attrDerived?.value)}
          content={t("common:form.save")}
          type="purple"
          onClick={send}
          sm
        />
      </div>
    </div>
  );
};

export default AttributeFormModal;
