import { IDLE } from "_constants/redux.constants";

const initialAnalysisState = {
  adaptationBulk: {
    data: null,
    error: null,
    status: IDLE
  },
  approvedBulk: {
    data: null,
    error: null,
    status: IDLE
  },
  layoutCopy: {
    data: null,
    error: null,
    status: IDLE
  },
  removeBgBulk: {
    data: null,
    error: null,
    status: IDLE
  },
  request: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialAnalysisState;
