import { reduxActions as UI } from "_constants/ui.constants";

const shortcuts = {
  [UI.KEYBOARD_SHORTCUTS]: (state, { payload }) => ({
    ...state,
    shortcuts: {
      [payload.type]: payload.action,
    },
  }),
  [UI.CHANGE_DETAILS_PAGE]: (state, { payload }) => ({
    ...state,
    details: {
      ...state.details,
      page: payload,
    },
  }),
};

export default shortcuts;
