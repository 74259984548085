import PropTypes from "prop-types";
import { NubeskIcons } from "components/atoms";

const prefix = "m-icon-drawer";

const IconDrawer = ({ bg, bgProps, icon, iconProps  }) => {

  return (
    <div className={prefix}>
      <NubeskIcons
        icon={icon}
        {...{
          ...iconProps,
          className: `${prefix}__icon ${iconProps?.className ?? ""}`
        }}
      />
      <NubeskIcons
        icon={bg}
        {...{
          ...bgProps,
          className: `${prefix}__background ${bgProps?.className ?? ""}`
        }}
      />
    </div>
  );
};

IconDrawer.propTypes = {
  bg: PropTypes.string,
  bgProps: PropTypes.object,
  icon: PropTypes.string,
  iconProps: PropTypes.object
};

IconDrawer.defaultProps = {
  bg: "doc2",
  bgProps: {},
  icon: "lens",
  iconProps: {}
};

export default IconDrawer;
