import { applyMiddleware, createStore } from 'redux';
import { initialState } from '_redux';
import thunk from 'redux-thunk';
import { api, web, toast } from '_middlewares';
import { reducer as rootReducer } from '_redux';

const configureStore = () => {
  const store = createStore(
    rootReducer,
    initialState,
    applyMiddleware(thunk, web, api, toast)
  );

  return store;
};

export default configureStore;
