import { BorderInputs } from "components/templates/index";
import React, { forwardRef, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import useTooltip from "_hooks/useTooltip";

const prefix = "m-input-text-r";

const InputTest = (
  {
    onDoubleClick,
    readOnly,
    disabled,
    value,
    name,
    error,
    onChange,
    type,
    onClick,
    ...props
  },
  ref
) => {
  const [blur, setBlur] = useState(false);
  const { injectedProps } = useTooltip({
    externalTip: error,
    delay: 150,
  });

  return (
    <BorderInputs
      autoHeight
      disabled={readOnly}
      onClick={onClick}
      type={type}
      error={error && blur}
    >
      <TextareaAutosize
        ref={ref}
        className={prefix}
        onDoubleClick={onDoubleClick}
        readOnly={readOnly}
        disabled={disabled}
        onFocus={() => setBlur(false)}
        onBlur={() => setBlur(true)}
        value={value}
        name={name}
        onChange={onChange}
        {...props}
      />
      {error && blur ? (
        <span
          {...injectedProps}
          style={{
            background: "rgba(255, 0, 0, 0.5)",
            width: 20,
            height: 20,
            position: "absolute",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            cursor: "pointer",
            right: -10,
            top: -10,
            color: "var(--color-white)",
          }}
        >
          i
        </span>
      ) : null}
    </BorderInputs>
  );
};

export default forwardRef(InputTest);
