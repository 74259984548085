import { createSelector } from "reselect";
import initialMediaLibState from "./mediaLib.initialState";

const selectLibraryStore = (state = initialMediaLibState) => state.mediaLib;

export const selectMediaLibOwn = createSelector(
  selectLibraryStore,
  (mediaLib) => mediaLib.own
);

export const selectMediaLibPost = createSelector(
  selectLibraryStore,
  (mediaLib) => mediaLib.post
);

export const selectMediaLibShared = createSelector(
  selectLibraryStore,
  (mediaLib) => mediaLib.shared
);

export default selectLibraryStore;
