import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { find, post, put } from "_redux/layer/layer.actions";
import { selectLayerCanvasEditor, selectLayerGenerate, selectLayerList, selectLayerOne, selectLayerPreview } from "_redux/layer/layer.selector";
import { selectAppExternalNubai, selectAppLayers } from "_redux/app/app.selector";
import { ACTIVE, AI_BACKGROUND, AI_INTEGRATED, AI_PHOTOGRAPHIC, HERO, AI, ISOMETRIC, reduxActions as LAYER } from "_constants/layer.constants";
import { reduxActions as APP } from "_constants/app.constants";
import { NBSK_API } from "_constants/request.constants";

const useLayer = () => {
  const dispatch = useDispatch();
  const { data: appExternalNubai } = useSelector(selectAppExternalNubai);
  const { t } = useTranslation();

  const { data: appLayersData, error: appLayersError, status: appLayersStatus } = useSelector(selectAppLayers);
  const { data: generateData, error: generateError, status: generateStatus } = useSelector(selectLayerGenerate);
  const { data: previewData, error: previewError, status: previewStatus } = useSelector(selectLayerPreview);
  const { data: listData, error: listError, status: listStatus } = useSelector(selectLayerList);
  const { data: oneData, error: oneError, status: oneStatus } = useSelector(selectLayerOne);
  const canvasEditor = useSelector(selectLayerCanvasEditor);

  const aiImageStyleOpts = appExternalNubai?.prompts?.style?.map((s) => ({
    label: t(`settings:layers.aiImageStyles.${s}`),
    value: s
  })) ?? [];

  const aiImageTypeOpts = [{
    label: t("settings:layers.aiImageTypes.background"),
    value: AI_BACKGROUND
  }, {
    label: t("settings:layers.aiImageTypes.integrated"),
    value: AI_INTEGRATED
  }];


  /**
   * 
   * @param {Object} data 
   * @param {Object} extras 
   * @returns 
   */
   const approvePreviewLayer = async  (data, extras = {}) => {
    if (!extras?.types) {
      extras.types = [
        LAYER.GENERATE_STARTED,
        LAYER.GENERATE_SUCCESS,
        LAYER.GENERATE_FAIL
      ];
    }
    
    return dispatch({
      [NBSK_API]: {
        endpoint: "/file/image/layer",
        reqType: "POST",
        ...extras,
        data
      }
    });
  };

  /**
   * 
   */
  const closeCanvasEditor = () => {
    dispatch({ type: LAYER.CANVAS_EDITOR_TOGGLE, payload: false });
    setTimeout(() => 
      dispatch({ type: LAYER.CANVAS_EDITOR_DATA, payload: null })
    , 100);
  };

  /**
   * 
   * @param {Object} data 
   * @param {Object} extras 
   * @returns 
   */
   const createLayer = (data, extras = {}) => {
    data = Array.isArray(data) ? data : [data];
    
    return dispatch(post({
      c: data?.map((m) => ({
        type: HERO,
        builder: { attributes: [] },
        template: {
          portrait: null,
          landscape: null,
          square: null
        },
        property: {
          canvas: {
            height: 1500,
            width: 1500
          }
        },
        ...m
      }))
    }, {
      ...extras,
      feedback: {
        error: true,
        success: true
      }
    }));
  };

  /**
   * 
   * @param {Object} params 
   * @param {Object} extras 
   * @returns 
   */
  const fetchList = (params, extras = {}) => {
    if (!extras?.types) {
      extras.types = [
        LAYER.LIST_STARTED,
        LAYER.LIST_SUCCESS,
        LAYER.LIST_FAIL
      ];
    }
    return dispatch(find({
      a: {
        sort: { by: { createdAt: -1 }},
        ...params?.a ?? {}
      },
      ...params,
      w: {
        status: ACTIVE,
        type: [HERO, ISOMETRIC, AI],
        ...params?.w ?? {}
      }
    }, extras));
  };

  /**
   * 
   * @param {*} params 
   * @param {*} extras 
   */
  const fetchOne = (params, extras = {}) => {
    if (!extras?.types) {
      extras.types = [
        LAYER.ONE_STARTED,
        LAYER.ONE_SUCCESS,
        LAYER.ONE_FAIL
      ];
    }

    return dispatch(find({
      ...params,
      w: {
        status: ACTIVE,
        ...params?.w ?? {}
      }
    }, extras));
  };

  /**
   * 
   * @param { Object } json 
   * @returns 
   */
   const hasDynamicColor = (fbjs) => {
    let output = false;
    
    for (let o of fbjs.objects) {
      if (o?.nbskProperties?.color?.dynamic === true) {
        output = true;
        break;
      }
    }

    return output;
  };

  /**
   * 
   */
  const openCanvasEditor = () => dispatch({ type: LAYER.CANVAS_EDITOR_TOGGLE, payload: true });

  /**
   * 
   * @param {Object} params 
   * @param {Object} extras 
   * @returns 
   */
  const previewLayer = (params, extras = {}) => {
    if (!extras?.types) {
      extras.types = [
        LAYER.PREVIEW_STARTED,
        LAYER.PREVIEW_SUCCESS,
        LAYER.PREVIEW_FAIL
      ];
    }

    return dispatch({
      [NBSK_API]: {
        endpoint: "/file/image/layer/preview",
        reqType: "post",
        ...extras,
        data: params
      }
    })
  };

  /**
   * 
   * @param {String} id 
   * @param {Object} data 
   * @param {Object} extras 
   * @returns 
   */
  const updateLayer = async (id, data, extras = {}) => {
    let layer = appLayersData?.filter((l) => l._id === id)?.pop();
    if (!layer) return;

    if (data?.property) {
      data.property = {
        ...data.property,
        canvas: {
          ...layer.property?.canvas ?? {},
          ...data?.property?.canvas ?? {}
        },
        image: {
          ...layer.property?.image ?? {},
          ...data.property?.image ?? {},
        },
        colorSchema: {
          ...layer.property?.colorSchema ?? {},
          background: {
            ...layer.property?.colorSchema?.background ?? {},
            ...data?.property?.colorSchema?.background
          },
          object: {
            ...layer.property?.colorSchema?.object ?? {},
            ...data?.property?.colorSchema?.object
          }
        },
        file: {
          ...layer?.property?.file ?? {},
          name: {
            ...layer?.property?.file?.name ?? {},
            ...data?.property?.file?.name ?? {}
          },
          size: {
            ...layer?.property?.file?.size ?? {},
            ...data?.property?.file?.size ?? {}
          }
        }
      }
    }
    
    let response = await dispatch(put(id, data, {
      feedback: {
        error: true,
        success: {
          area: "settings:layers.feedback.area",
          info: "settings:layers.feedback.save.success"
        }
      },
      ...extras
    }));
    
    if (response?.data?.[0]) {
      dispatch({
        type: APP.UPDATE_PROP,
        payload: {
          key: "layers",
          data: {
            data: appLayersData?.map(
              (l) => id === l._id ? response?.data?.[0] : l
            ),
            error: appLayersError,
            status: appLayersStatus
          }
        }
      });
    }
    return response;
  };

  const setCanvasEditor = (data) => dispatch({ type: LAYER.CANVAS_EDITOR_DATA, payload: data })

  const generateReset = () => dispatch({ type: LAYER.GENERATE_RESET });

  const listReset = () => dispatch({ type: LAYER.LIST_RESET });

  const previewReset = () => dispatch({ type: LAYER.PREVIEW_RESET });

  return {
    aiImageStyleOpts,
    aiImageTypeOpts,
    approvePreviewLayer,
    canvasEditor,
    closeCanvasEditor,
    createLayer,
    fetchList,
    fetchOne,
    hasDynamicColor,
    generateData,
    generateError,
    generateReset,
    generateStatus,
    listData,
    listError,
    listReset,
    listStatus,
    openCanvasEditor,
    oneData,
    oneError,
    oneStatus,
    previewData,
    previewError,
    previewLayer,
    previewReset,
    previewStatus,
    setCanvasEditor,
    updateLayer
  };
};

export default useLayer;
