import { NubeskIcons } from "components/atoms";

const NubeskLoader = ({ 
  visible = false,
}) => {
  return (
    <div
      className={`m-nubesk-loader ${visible ? "show" : "--hide" }`} 
    >
      <NubeskIcons
        icon="loading" 
        className="u-purple u-spin"
        style={{
          fontSize: "12rem",
          fontWeight: "700"
        }}
      />
    </div>
  );
};

export default NubeskLoader;
