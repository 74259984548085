import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const putPropertyColumns = {
  [APP.PUT_PROPERTY_COLUMNS_STARTED]: (state) => ({
    ...state,
    putPropertyColumns: {
      ...state.putPropertyColumns,
      status: LOADING
    }
  }),
  [APP.PUT_PROPERTY_COLUMNS_FAIL]: (state, { payload } ) => ({
    ...state,
    putPropertyColumns: {
      ...state.putPropertyColumns,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [APP.PUT_PROPERTY_COLUMNS_SUCCESS]: (state, { payload } ) => ({
    ...state,
    putPropertyColumns: {
      ...state.putPropertyColumns,
      data: payload?.success ?? null,
      status: SUCCESS
    },
    propertyColumns: {
      ...state.propertyColumns,
      data: payload?.data?.[0]?.data
    }
  })
};

export default putPropertyColumns;
