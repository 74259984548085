import { createSelector } from "reselect";

export const selectBillingStore = (state) => state.billing;

export const selectAiImages = createSelector(
  selectBillingStore,
  (billing) => billing.aiImages
);

export const selectBillingPricing = createSelector(
  selectBillingStore,
  (billing) => billing.pricing
);

export const selectBillingAccounts = createSelector(
  selectBillingStore,
  (billing) => billing.accounts
);

export const selectBillingAdaptations = createSelector(
  selectBillingStore,
  (billing) => billing.adaptations
);

export const selectBillingCurrentPlan = createSelector(
  selectBillingStore,
  (billing) => billing.currentPlan
);

export const selectBillingDateFilter = createSelector(
  selectBillingStore,
  (billing) => billing.dateFilter
);

export const selectBillingBackRemovals = createSelector(
  selectBillingStore,
  (billing) => billing.backRemovals
);

export const selectBillingChannels = createSelector(
  selectBillingStore,
  (billing) => billing.channels
);

export const selectBillingItems = createSelector(
  selectBillingStore,
  (billing) => billing.items
);

export const selectBillingHeros = createSelector(
  selectBillingStore,
  (billing) => billing.heros
);

export const selectBillingIsometrics = createSelector(
  selectBillingStore,
  (billing) => billing.isometrics
);

export const selectBillingWatermarks = createSelector(
  selectBillingStore,
  (billing) => billing.watermarks
);

export default selectBillingStore;
