import { useTranslation } from "react-i18next";
import { IconDrawer } from "components/molecules";

const prefix = "o-backremoval-modal";

const BackRemovalModal = () => {
  const { t } = useTranslation();

  return (
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        <div>
          <IconDrawer
            icon="cut"
            iconProps={{fontSize: "3rem"}}
          />
        </div>
        <div className={`${prefix}__data`}>{t("modals:backRemoval.started")}</div>
      </div>
    </div>
  );
};

export default BackRemovalModal;
