import { reduxActions as CATALOG } from "_constants/catalog.constants";

const shareUpdate = {
  [CATALOG.SHARE_UPDATE]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: {
        ...state.one.data,
        share: payload ?? []
      }
    }
  })
};

export default shareUpdate;
