import { useEffect, useState } from "react";
import { matchPath, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BulkActionsCounter } from "components/organism";
import { Button } from "components/atoms";
import { fetchCatalogDetails, removeFromCatalog, removeProductsReset } from "_redux/catalog/catalog.actions";
import useProductQuery from "_hooks/useProductQuery";
import { closeModal, resetSelection, toggleRefresh } from "_redux/ui/ui.actions";
import { chunk } from "_utils/array";
import { sleep } from "_utils/async";
import useAppSettings from "_hooks/useAppSettings";

const prefix = "o-remove-from-catalog-modal";

const RemoveFromCatalogModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { t } = useTranslation();

  const [catalogId] = useState((() => {
    let { params } = matchPath(location.pathname, {
      path: "/products/catalog/:catalogId",
      exact: true
    });

    return params?.catalogId
  })());

  const [completed, setCompleted] = useState(0);
  const [total, setTotal] = useState(0);
  const [waiting, setWaiting] = useState(false);

  const { appColumnKeyProps } = useAppSettings();
  const { itemsSelected } = useProductQuery();

  const remove = async () => {
    let productsId = await itemsSelected();
    let stackSize;
  
    if (!catalogId) return;

    if (productsId.length > 1000){
      stackSize = 100;
    } else if (productsId.length > 100){
      stackSize = 20;
    } else {
      stackSize = 1;  
    }
    setTotal(productsId.length);
    setWaiting(true);
    let arrayChunk = chunk(productsId, stackSize);
    
    for (let i = 0; i < arrayChunk.length; i++) {
      await dispatch(removeFromCatalog(catalogId, arrayChunk[i]));
      setCompleted((v) => v += arrayChunk[i].length)
      if (productsId.length <= 50) {
        await sleep(150).then(() => {});
      }
    }
  }

  useEffect(() => {
  
    return () => {
      dispatch(removeProductsReset());
    }
  }, [])
  
  return(
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        {!waiting ?
          <p>{t("modals:removeFromCatalog.question", { key: appColumnKeyProps?.name })}</p>
        :
          <BulkActionsCounter
            completed={completed}
            legends={{
              action: t("modals:editFieldBulk.bulkCounter.action"),
              complete: t("modals:editFieldBulk.bulkCounter.complete"),
              error: t("modals:editFieldBulk.bulkCounter.error"),
              finish: t("modals:editFieldBulk.bulkCounter.finish"),
              success: t("modals:editFieldBulk.bulkCounter.success"),
              total: t("modals:editFieldBulk.bulkCounter.total")
            }}
            onFinish={async() => {
              await dispatch(resetSelection());
              await dispatch(fetchCatalogDetails(catalogId));
              dispatch(toggleRefresh("products"));
              dispatch(closeModal());
            }}
            withError={0}
            total={total}
          />
        }

      </div>
      {!waiting && 
        <div className={`${prefix}__footer`}>
          <Button
            content={t("common:form.cancel")}
            onClick={() => dispatch(closeModal())}
            sm
            type="underline"
          />
          <Button
            content={t("common:form.confirm")}
            onClick={remove}
            sm
            type="purple"
          />
        </div>
      }
    </div>
  );
};

export default RemoveFromCatalogModal;
