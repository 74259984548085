import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { FlexComponent } from "components/templates";
import { Button, NubeskIcons } from "components/atoms";
import { AddToTaskForm, TaskForm } from "components/organism";
import useProductQuery from "_hooks/useProductQuery";
import { closeModal } from "_redux/ui/ui.actions";
import { Hr } from "components/atoms";

const prefix = "o-new-task-modal";

const AddToTaskModal = ({ auto, onFinished, type }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { itemsSelected } = useProductQuery(false);
  const [loading, setLoading] = useState(false);
  const [selectedForm, setSelectedForm] = useState(type || undefined);
  const [selectedIds, setSelectedIds] = useState([]);

  const getElements = async () => {
    if (auto) {
      let ids = await itemsSelected();
      setSelectedIds(ids);
      setLoading(false);
    }
  };

  useEffect(() => {
    getElements();
  }, []);

  return (
    <div className={prefix}>
      <FlexComponent isVertical>
        {!selectedForm && 
          <div className={`${prefix}__form-type`}>
            <FlexComponent>
              <Button 
                content={t("modals:addToTask.addOnNew")}
                disabled={loading}
                leftIcon={<NubeskIcons className="u-purple" icon="edit" size="2rem" />}
                onClick={() => setSelectedForm("new")}
                type="underline"
              />
            </FlexComponent>

            <Hr content={t("common:form.or")} />

            <FlexComponent>
              &nbsp;
              <Button
                content={t("modals:addToTask.addOn")}
                disabled={loading}
                leftIcon={<NubeskIcons className="u-purple" icon="plus" size="2rem"/>}
                onClick={() => setSelectedForm("exists")}
                type="underline"
              />
            </FlexComponent>
          </div>
        }

        {selectedForm === "new" && 
          <TaskForm
            elements={selectedIds}
            onCancel={() => {
              if (type) dispatch(closeModal())
              else setSelectedForm();
            }}
            onFinished={onFinished}
          />
        }

        {selectedForm === "exists" && 
          <AddToTaskForm
            elements={selectedIds}
            onCancel={() => {
              if (type) dispatch(closeModal())
              else setSelectedForm();
            }}
            onFinished={onFinished}
          />
        }

      </FlexComponent>
    </div>
  );
};

AddToTaskModal.propTypes = {
  auto: PropTypes.bool,
  onFinished: PropTypes.func,
  type: PropTypes.oneOf(["new", "exists"])
};

AddToTaskModal.defaultProps = {
  auto: true
};

export default AddToTaskModal;
