import { useState, useEffect } from "react";

const useContextMenu = ({ children }) => {
  const [contextProps, setContextProps] = useState({});
  const onContextMenu = async (e) => {
    e.preventDefault();
    let {
      pageX,
      pageY,
      view: { innerHeight, innerWidth },
    } = e;
    let menuHeight = (children || 1) * 60;

    setContextProps({
      left: pageX + 280 > innerWidth ? pageX - 240 : pageX,
      top:
        pageY + menuHeight > innerHeight
          ? pageY - ((children || 1) - 1) * 60
          : pageY,
      isOpen: true,
    });
  };
  const closeContext = () =>
    setContextProps({ ...contextProps, isOpen: false });

  return { contextProps, onContextMenu, closeContext };
};

export default useContextMenu;
