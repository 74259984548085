import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const getImageryWorkflow = {
  [APP.GET_IMAGERY_WORKFLOW_STARTED]: (state) => ({
    ...state,
    imageryWorkflow: {
      ...state.imageryWorkflow,
      status: LOADING
    }
  }),

  [APP.GET_IMAGERY_WORKFLOW_FAIL]: (state, { payload }) => ({
    ...state,
    imageryWorkflow: {
      ...state.imageryWorkflow,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [APP.GET_IMAGERY_WORKFLOW_SUCCESS]: (state, { payload }) => ({
    ...state,
    imageryWorkflow: {
      ...state.imageryWorkflow,
      data: payload?.data?.[0]?.data ?? null,
      status: SUCCESS
    }
  })
};

export default getImageryWorkflow;
