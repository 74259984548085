import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { put } from '_redux/product/product.actions';

const useFolder = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [error] = useState(null);
  const [loading, setLoading] = useState();

  const cleanData = () => setData(null);
  
  const getData = () => {
    setData();
  };

  const addAdaptation = (id, data) => 
    dispatch(put(id, null, data));

  useEffect(() => setLoading(false), [data]);
  
  return {
    addAdaptation,
    cleanData,
    data,
    error,
    getData,
    loading
  };
};

export default useFolder;
