import React from "react";
import { FlexComponent } from "components/templates/index";
import cn from "classnames";

const prefix = "m-multiple-progress";
export const MultipleProgress = ({ counts }) => {
  return (
    <div className={cn(prefix)}>
      <div className="m-multiple-progress__labels">
        {counts.map(({ original, value, color }) => (
          <FlexComponent gap={2} key={original}>
            <div
              className={`m-multiple-progress__labels-label-sqr --${color}`}
            ></div>
            <span className="m-multiple-progress__labels-label-number ">
              ({value})
            </span>
            <span
              className={`m-multiple-progress__labels-label-title --${color} `}
            >
              {original}
            </span>
          </FlexComponent>
        ))}
      </div>
      <div className="m-multiple-progress__bar">
        {counts.map(({ original, percent, value, color }, index) => (
          <div
            key={original}
            className={`m-multiple-progress__bar__item m-multiple-progress__bar__item--${color}`}
            style={{
              zIndex: counts.length - index,
              width: `${percent}%`,
              display: value ? "block" : "none"
            }}
          ></div>
        ))}
      </div>
    </div>
  );
};
