import { createSelector } from "reselect";

export const selectLayoutStore = (state) => state.layout;

export const selectLayoutAll = createSelector(
  selectLayoutStore,
  (layout) => layout.all
);

export const selectLayoutOne = createSelector(
  selectLayoutStore,
  (layout) => layout.one
);

export const selectLayoutPost = createSelector(
  selectLayoutStore,
  (layout) => layout.post
);

export default selectLayoutStore;
