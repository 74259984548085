import { NbskEditor } from "components/organism";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { NubeskIcons } from "components/atoms";
import { useLayer } from "_hooks";
import { AI, HERO, ISOMETRIC, TEMPLATE_TYPES } from "_constants/layer.constants";

const prefix = "t-layer-canvas-editor";

const allowed = {
  [AI]: [],
  [ISOMETRIC]: ["rect", "circle", "text", "line"],
  [HERO]: ["rect", "circle", "text"]
};

const LayerCanvasEditor = () => {
  const { canvasEditor: { data }, closeCanvasEditor, hasDynamicColor, updateLayer } = useLayer();
  const { t } = useTranslation();

  const [currentJson, setCurrentJson] = useState();
  const [localCanvas, setLocalCanvas] = useState();

  const close = () => {
    closeCanvasEditor();
  };

  const save = async () => {
    let myjson = localCanvas.toJSON(["nbskProperties"]);
    await updateLayer(data?.layerId, {
      template: {
        [TEMPLATE_TYPES[data?.layerType]]: JSON.stringify(myjson)
      },
      property: {
        ...data?.layer?.property ?? {},
        colorSchema: {
          ...data?.layer?.property?.colorSchema ?? {},
          object: {
            ...data?.layer?.property?.colorSchema?.object ?? {},
            format: hasDynamicColor(myjson) ? "dynamic" : "static"
          }
        }
      }
    });
    closeCanvasEditor();

    if (typeof data?.onSave === "function") data.onSave();
  }

  useEffect(() => {
    setCurrentJson(data?.layer?.template ? JSON.parse(data?.layer?.template): null);
  }, []);

  return (
    <div className={prefix}>
      <div className={`${prefix}__header`} >
        <div>
          <div className="--data">
            <Button
              leftIcon={<NubeskIcons icon="arrowLeft" />}
              onClick={() => close()}
              style={{ width: "max-content" }}
              type="color"
            />
            <div className="--layer-type">{data?.layerType || ""}</div>
            <div className="--layer-name">{data?.layer?.name || ""}</div>
          </div>
        </div>

        <div>
          <Button
            content={t("common:form.cancel")}
            onClick={(e) => {
              e.preventDefault();
              close();
            }}
            sm
            type="primary"
          />
          <Button
            content={t("settings:layers.save")}
            onClick={ (e) => {
              e.preventDefault();
              save();
            }}
            sm
            type="success"
          />
        </div>
      </div>
      
      <NbskEditor
        canvasWidth={data?.layer.property?.canvas?.width}
        canvasHeight={data?.layer.property?.canvas?.height}
        json={currentJson ? currentJson : undefined}
        onInit={({canvas}) => setLocalCanvas(canvas)}
        allowedShapes={[(function() {
          switch (data?.layer?.type) {
            case AI:
              return "squareImg";
            case HERO:
              switch (data?.layerType) {
                case 1: return "portraitImg";
                case 2: return "landscapeImg";
                case 3: return "squareImg";
              }
              break;
            case ISOMETRIC:
              switch (data?.layerType) {
                case 1: return "isomPortraitImg";
                case 2: return "isomLandscapeImg";
                case 3: return "isomSquareImg";
              }
              break;
          }
        })(), ...allowed[data.layer.type]]}
      />

    </div>
  );
};

export default LayerCanvasEditor;

