import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { FlexComponent } from "components/templates";
import { FilterGroup } from "components/molecules";
import { SimpleScroller } from "components/organism";
import { Button } from "components/atoms";
import { selectFilter } from "_redux/ui/ui.selector";
import useProductFilter from "_hooks/useProductFilter";
import { LOADING } from "_constants/redux.constants";

const prefix = "o-filter";

const Filter = ({ dropPaste }) => {
  const { t } = useTranslation();

  const currentFilter = useSelector(selectFilter);
  const { applyNewFilter, fetchStatus, filters, filterList, changeFilters } = useProductFilter();

  const classForComponent = classNames(prefix, {
    "is-open": currentFilter.isOpen,
    "has-drop-paste": dropPaste
  });

  const resetFilters = () => {
    if (fetchStatus === LOADING) return;
    changeFilters({}, true);
  };

  return (
    <div className={classForComponent}>
      <div>
        <SimpleScroller isHorizontal>
          <FlexComponent>
            {filterList ? (
              filterList?.map(({ collection, empty, field, label, options }, index) => {
                if (options?.length > 1000) {
                  options = options.slice(0, 200);
                };
                return (
                  <FilterGroup
                    key={index}
                    collection={collection}
                    empty={empty}
                    field={field}
                    label={label}
                    onChange={(value) => {
                      if (fetchStatus !== LOADING) {
                        applyNewFilter(value, collection, field)
                      }
                    }}
                    options={options}
                    values={filters?.[collection]?.[field] ?? []}
                  />
                );
              })
            ) : (
              <p>{t("common:words.loading")}</p>
            )}
          </FlexComponent>
          
        </SimpleScroller>
      </div>
      {dropPaste &&
        <div className={`${prefix}__drop-paste`}>
          <FilterGroup
            label={dropPaste.label}
            onChange={(value) => 
              applyNewFilter(value, dropPaste.collection, dropPaste.field, dropPaste.label)
            }
            collection={dropPaste.collection}
            field={dropPaste.field}
            type="dropPaste"
            values={filters?.[dropPaste.collection]?.[dropPaste.field] ?? []}
            isClean={!filters?.[dropPaste.collection]?.[dropPaste.field]}
          />
        </div>
      }
      <div className={`${prefix}__buttons`}>
        <FlexComponent sm>
            {filters && Object.keys(filters).length ? (
              <Button type={"filter"} content={t("products:filters.reset")} onClick={resetFilters}/>
            ) : null}
          </FlexComponent>
        </div>
    </div>
  );
};

Filter.propTypes = {
  dropPaste: PropTypes.object
};

Filter.defaultProps = {
  dropPaste: null
};

export default Filter;
