import React, { Suspense, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Switch, Route, useRouteMatch, Redirect, matchPath, useLocation } from "react-router-dom";
import { Tasks, TaskItems} from "views/Tasks";
import { changeToolbar } from "_redux/ui/ui.actions";

const TaskRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let { path } = useRouteMatch();

  useEffect(() => {
    if (matchPath(location.pathname, {
      path: `${path}/:taskKey`,
      strict: true
    })) {
      dispatch(changeToolbar("taskItems"));
    } else {
      dispatch(changeToolbar("tasks"));
    }
  }, [location]);

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path={`${path}/:taskKey`}>
          <TaskItems />
        </Route>
        
        <Route path={`${path}`}>
          <Tasks />
        </Route>

        <Redirect to={`${path}`} />
      </Switch>
    </Suspense>
  );
};

export default TaskRoutes;
