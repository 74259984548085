import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import classNames from "classnames";
import { DONE } from "_constants/task.constants";

const prefix = "a-task-eta";

const TaskEta = ({ date, status}) => {
  const { t } = useTranslation();

  const [state, setState] = useState();
  const [diff, setDiff] = useState();

  const classForComponent = classNames(prefix, {
    [`--${state}`]: state
  })

  useEffect(() => {
    if (!date) return;
    setDiff(moment(date).diff(moment().startOf('day'), "days"));
  }, [date]);

  useEffect(() => {
    if (status === DONE) {
      setState(status);
    } else if (diff < 0) {
      setState("defeated");
    } else if (diff < 2) {
      setState("near");
    } else if (diff >= 2) {
      setState("valid"); 
    } else {
      setState("undefined");
    }
    
  }, [diff]);

  return (
    <div className={classForComponent}>
      {status === DONE ?
        t("tasks:table.status.done")
      :
        (!date ?
          t("tasks:table.status.undefined")
        :
          <>{moment(date).format("DD/MM")} <b> · </b><strong>{diff}d</strong></>
        )
      }
    </div>
  );
};

export default TaskEta;
