import initialAnalysisState from "../analysis.initialState";
import { reduxActions as ANALYSIS } from "_constants/analysis.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const adaptationBulk = {
  [ANALYSIS.ADAPTATION_BULK_STARTED]: (state) => ({
    ...state,
    adaptationBulk: {
      ...state.adaptationBulk,
      status: LOADING
    }
  }),
  
  [ANALYSIS.ADAPTATION_BULK_FAIL]: (state, { payload }) => ({
    ...state,
    adaptationBulk: {
      ...state.adaptationBulk,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  
  [ANALYSIS.ADAPTATION_BULK_SUCCESS]: (state, { payload }) => ({
    ...state,
    adaptationBulk: {
      ...state.adaptationBulk,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),

  [ANALYSIS.ADAPTATION_BULK_RESET]: (state) => ({
    ...state,
    adaptationBulk: initialAnalysisState.adaptationBulk
  }),
};

export default adaptationBulk;
