import { reduxActions as UI } from "_constants/ui.constants";

export const setCurrentAdaptation = (id) => (dispatch) =>
  dispatch({ type: UI.CURRENT_ADAPTATION, payload: id });

export const setCurrentImage = (index) => (dispatch) =>
  dispatch({ type: UI.CURRENT_IMAGE, payload: index });

export const setCarrousel = (images) => (dispatch) =>
  dispatch({
    type: UI.CARROUSEL,
    payload: images,
  });

export const updateCurrentAdaptation = () => (dispatch, getState) => {
  let newValue = getState()?.file?.post?.data?.[0]?.adaptationId ?? null;

  if (!newValue) newValue = getState()?.ui?.details?.currentAdaptation;
  return dispatch({
    type: UI.CURRENT_ADAPTATION,
    payload: newValue,
  });
};

export const setImagesGallery = (images) => (dispatch) =>
  dispatch({
    type: UI.SET_IMAGES,
    payload: images,
  });
