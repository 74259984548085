import initialAnalysisState from "../analysis.initialState";
import { reduxActions as ANALYSIS } from "_constants/analysis.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const request = {
  [ANALYSIS.REQUEST_STARTED]: (state) => ({
    ...state,
    request: {
      ...state.request,
      status: LOADING
    }
  }),
  
  [ANALYSIS.REQUEST_FAIL]: (state, { payload }) => ({
    ...state,
    request: {
      ...state.request,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  
  [ANALYSIS.REQUEST_SUCCESS]: (state, { payload }) => ({
    ...state,
    request: {
      ...state.request,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [ANALYSIS.REQUEST_SUCCESS]: (state) => ({
    ...state,
    request: initialAnalysisState.request
  }),
};

export default request;
