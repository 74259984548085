import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const fonts = {
  [APP.FONTS_STARTED]: (state) => ({
    ...state,
    fonts: {
      ...state.fonts,
      status: LOADING
    }
  }),
  
  [APP.FONTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    fonts: {
      ...state.fonts,
      data: payload?.data?.[0]?.data?.src ?? null,
      error: null,
      status: SUCCESS
    }
  }),

  [APP.FONTS_FAIL]: (state, { payload }) => ({
    ...state,
    fonts: {
      ...state.fonts,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [APP.FONTS_SET]: (state, { payload }) => ({
    ...state,
    fonts: {
      ...state.fonts,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),
};

export default fonts;
