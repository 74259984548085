import { reduxActions as UI } from "_constants/ui.constants";

export const openLoader = (payload) => (dispatch) =>
  dispatch({
    type: UI.OPEN_LOADER,
    payload,
  });

export const closeLoader = () => (dispatch) =>
  dispatch({
    type: UI.CLOSE_LOADER,
  });
