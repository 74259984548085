import { FlexComponent } from "components/templates";
import { Button } from "components/atoms";
import { useTranslation } from "react-i18next";
import { NubeskIcons } from "components/atoms/index";
import { openModal } from "_redux/ui/ui.actions";
import { useDispatch } from "react-redux";

const UsersPanelToolbar = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <>
      <FlexComponent>
        <Button
          content={t("settings:usersPanel.actions.newUser")}
          type="primary"
          leftIcon={<NubeskIcons icon="plus"/>}
          onClick={() => dispatch(openModal({
            closeBackdrop: false,
            header: {
              text: <>
                <NubeskIcons icon="plus" /> {t("settings:usersPanel.actions.newUser")}
              </>
            },
            typeModal: "userForm"
          }))}
        />
      </FlexComponent>
      
      <FlexComponent>
        
      </FlexComponent>

    </>
  )
};

export default UsersPanelToolbar;
