import {
  reduxActions as ADAPTATION,
  ACTIVE,
} from "_constants/adaptation.constants";
import { API, NBSK_API } from "_constants/request.constants";

export const find =
  (params, extras = {}) =>
  (dispatch) => {
    if (!extras?.types) {
      extras.types = [
        ADAPTATION.ALL_STARTED,
        ADAPTATION.ALL_SUCCESS,
        ADAPTATION.ALL_FAIL,
      ];
    }

    return dispatch({
      [NBSK_API]: {
        endpoint: "/adaptation",
        reqType: "GET",
        params: {
          a: params?.a ?? {},
          f: params?.f ?? [],
          w: {
            status: ACTIVE,
            ...(params?.w ?? {}),
          },
          opt: { ...(params?.opt ?? undefined) },
        },
        feedback: {
          success: false,
        },
        ...extras,
      },
    });
  };

export const post =
  (data, extras = {}) =>
  (dispatch) => {
    if (!extras?.types) {
      extras.types = [
        ADAPTATION.POST_STARTED,
        ADAPTATION.POST_SUCCESS,
        ADAPTATION.POST_FAIL,
      ];
    }

    return dispatch({
      [NBSK_API]: {
        endpoint: "/adaptation",
        reqType: "POST",
        ...extras,
        data,
      },
    });
  };

export const put =
  (keys, data, types = null, extras = {}) =>
  (dispatch) => {
    if (!types) {
      types = [
        ADAPTATION.POST_STARTED,
        ADAPTATION.POST_SUCCESS,
        ADAPTATION.POST_FAIL,
      ];
    }

    if (!Array.isArray(keys)) keys = [keys];

    return dispatch({
      NBSK: {
        endpoint: "/adaptations",
        types,
        reqType: "POST",
        ...extras,
        data: {
          o: keys,
          c: data,
        },
      },
    });
  };

//TODO confirmar que existe y que no para modificar lo necesario
export const putAdaptation =
  (adaptationId, properties, extras = {}, derivedFrom) =>
  (dispatch) =>
    dispatch({
      NBSK: {
        endpoint: "/adaptation",
        reqType: "PUT",
        types: [
          ADAPTATION.PUT_STARTED,
          ADAPTATION.PUT_SUCCESS,
          ADAPTATION.PUT_FAIL,
        ],
        data: {
          w: [adaptationId],
          c: [{ properties, ...(derivedFrom && { derivedFrom }) }],
        },
        extras,
      },
    });

export const addAdaptationCatalog = (data) => (dispatch) =>
  dispatch(
    post(data, {
      types: [
        ADAPTATION.ADD_STARTED,
        ADAPTATION.ADD_SUCCESS,
        ADAPTATION.ADD_FAIL,
      ],
      extras: {
        feedback: {
          success: false,
          error: "settings.salesChannels.newModal.feedback.error",
        },
      },
    })
  );

export const allReset = () => (dispatch) =>
  dispatch({
    type: ADAPTATION.ALL_RESET,
  });

export const oneReset = () => (dispatch) =>
  dispatch({
    type: ADAPTATION.ONE_RESET,
  });
