import { reduxActions as BILLING } from "_constants/billing.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialBillingState from "../billing.initialState";

const items = {
  [BILLING.ITEMS_STARTED]: (state) => ({
    ...state,
    items: {
      ...state.items,
      status: LOADING
    }
  }),

  [BILLING.ITEMS_FAIL]: (state, { payload }) => ({
    ...state,
    items: {
      ...state.items,
      error: payload?.error,
      status: FAILURE
    }
  }),

  [BILLING.ITEMS_SUCCESS]: (state, { payload }) => ({
    ...state,
    items: {
      ...state.items,
      data: payload?.data?.[0],
      status: SUCCESS
    }
  }),

  [BILLING.ITEMS_RESET]: (state) => ({
    ...state,
    items: initialBillingState.items
  })
};

export default items;
