import { Button, NubeskIcons } from "components/atoms";
import { useModal } from "_hooks";
import { useTranslation } from "react-i18next";
import useUser from "_hooks/useUser";
import { $LAYER_CREATE } from "_constants/permission.constants";

const prefix = "o-layers-toolbar";

const LayersToolbar = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();
  const { user } = useUser();

  return (
    <div className={prefix}>
      <div>
        {user.canDoIt($LAYER_CREATE) &&
          <Button
            content={t("settings:layers.addModal.title")}
            leftIcon={<NubeskIcons icon="plus" />}
            type="primary"
            style={{ width: "max-content" }}
            onClick={() => openModal({
              typeModal: "addLayer",
              header: {
                text: t("settings:layers.addModal.title"),
                icon: {
                  type: "plus",
                  size: "1.5rem"
                }
              }
            })}
          />
        }
      </div>
      <div>
        
      </div>
    </div>
  );
};

export default LayersToolbar;
