import React, { Suspense, useLayoutEffect } from "react";
import { Switch, Route, useLocation, useRouteMatch } from "react-router-dom";
import Settings from "views/Settings";
import Billing from "views/Settings/Billing";
import DataFields from "views/Settings/DataFields";
import Layers from "views/Settings/Layers/Layers";
import SalesChannel from "views/Settings/SalesChannel";
import ChannelAdaptations from "views/Settings/SalesChannel/ChannelAdaptations";
import ChannelAttributes from "views/Settings/SalesChannel/ChannelAttributes";
import UsersPanel from "views/Settings/UsersPanel";

import { useSettings } from "_hooks";

const SettingsRoutes = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { toolbarSwitch } = useSettings();

  useLayoutEffect(() => {
    toolbarSwitch(pathname, path);
  }, [pathname]);

  return (
    <Suspense fallback={null}>
      <Switch>
        <Settings>
          <Route exact path={`${path}/data-fields`}>
            <DataFields />
          </Route>

          <Route exact path={`${path}/sales-channels`}>
            <SalesChannel />
          </Route>

          <Route exact path={`${path}/layers`}>
            <Layers />
          </Route>

          <Route exact path={`${path}/sales-channels/:layoutKey/adaptations`}>
            <ChannelAdaptations />
          </Route>

          <Route exact path={`${path}/sales-channels/:layoutKey/attributes`}>
            <ChannelAttributes />
          </Route>

          <Route exact path={`${path}/billing`}>
            <Billing />
          </Route>

          <Route exact path={`${path}/users`}>
            <UsersPanel />
          </Route>

        </Settings>
      </Switch>
    </Suspense>
  );
};

export default SettingsRoutes;
