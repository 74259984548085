import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const ColumnsTemplate = ({
  leftComponent,
  rightComponent,
  menu,
  leftWidth,
  isHorizontal,
  transition,
  className
}) => {
  const prefix = "t-columns";
  const classForComponent = classNames(prefix, {
    "is-auto": leftWidth === "auto",
    "is-horizontal": isHorizontal,
    [className]: className,
  });
  return (
    <div
      className={classForComponent}
      style={{ "--porcent": `${leftWidth ?? "50%"}` }}
    >
      <div className={`${prefix}__left`} style={{transition: transition}}>{leftComponent}</div>
      <div className={`${prefix}__right`} style={{transition: transition}}>
        {rightComponent}
        {menu && <div className={`${prefix}__right-menu`}>{menu}</div>}
      </div>
    </div>
  );
};

export default ColumnsTemplate;

ColumnsTemplate.propTypes = {
  leftWidth: PropTypes.string,
  leftComponent: PropTypes.node.isRequired,
  rightComponent: PropTypes.node.isRequired,
  menu: PropTypes.node,
};
