import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const countPiece = {
  [PRODUCT.COUNTPIECE_STARTED]: (state) => ({
    ...state,
    countPiece: {
      ...initialProductState.countPiece,
      status: LOADING,
    }
  }),

  [PRODUCT.COUNTPIECE_FAIL]: (state, { payload }) => ({
    ...state,
    countPiece: {
      ...initialProductState.countPiece,
      error: payload?.error ?? null,
      status: FAILURE,
    }
  }),

  [PRODUCT.COUNTPIECE_SUCCESS]: (state, { payload }) => ({
    ...state,
    countPiece: {
      ...initialProductState.countPiece,
      data: payload?.data?.[0]?.count ?? payload?.data?.[0] ?? null,
      status: SUCCESS,
    }
  }),

  [PRODUCT.COUNTPIECE_PAIR]: (state) => ({
    ...state,
    countPiece: {
      ...state.countPiece,
      data: state.count.data
    }
  })
};

export default countPiece;
