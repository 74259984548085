import { Switch } from "components/atoms"
import { TYPE_PROVIDER, TYPE_RETAILER } from "module/Syncfonia/syncfonia.constants";
import { useTranslation } from "react-i18next";

const Controls = ({ opt1, opt2, prefix, setOpt1, setOpt2, type }) => {
  const { t } = useTranslation();

  return (
    <div className={`${prefix}__controls`}>
      <div className="--option">
        <p onClick={() => setOpt1(!opt1)}>
          {type === TYPE_PROVIDER && t("module:syncfonia.updateCatalog.modal.fetchElements")}
          {type === TYPE_RETAILER && t("module:syncfonia.updateCatalog.modal.updateImg")}
        </p>
        <Switch
          checked={opt1}
          onChange={() => setOpt1(!opt1)}
        />
      </div>

      <div className="--option">
        <p onClick={() => setOpt2(!opt2)}>
          {type === TYPE_PROVIDER && t("module:syncfonia.updateCatalog.modal.updateImg")}
          {type === TYPE_RETAILER && t("module:syncfonia.updateCatalog.modal.updateInfo")}
        </p>
        <Switch
          checked={opt2}
          onChange={() => setOpt2(!opt2)}
        />
      </div>
    </div>
  );
};

export default Controls;
