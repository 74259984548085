import { createSelector } from "reselect";

export const selectLayerStore = (store) => store.layer;

export const selectLayerCanvasEditor = createSelector(
  selectLayerStore,
  (layer) => layer.canvasEditor
);

export const selectLayerList = createSelector(
  selectLayerStore,
  (layer) => layer.list
);

export const selectLayerGenerate = createSelector(
  selectLayerStore,
  (layer) => layer.generate
);

export const selectLayerOne = createSelector(
  selectLayerStore,
  (layer) => layer.one
);

export const selectLayerPreview = createSelector(
  selectLayerStore,
  (layer) => layer.preview
);

export default selectLayerStore;
