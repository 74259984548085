import { reduxActions as BILLING } from "_constants/billing.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialBillingState from "../billing.initialState";

const isometrics = {
  [BILLING.ISOMETRICS_STARTED]: (state) => ({
    ...state,
    isometrics: {
      ...state.isometrics,
      status: LOADING
    }
  }),

  [BILLING.ISOMETRICS_FAIL]: (state, { payload }) => ({
    ...state,
    isometrics: {
      ...state.isometrics,
      error: payload?.error,
      status: FAILURE
    }
  }),

  [BILLING.ISOMETRICS_SUCCESS]: (state, { payload }) => ({
    ...state,
    isometrics: {
      ...state.isometrics,
      data: payload?.data?.[0],
      status: SUCCESS
    }
  }),

  [BILLING.ISOMETRICS_RESET]: (state) => ({
    ...state,
    isometrics: initialBillingState.isometrics
  })
};

export default isometrics;
