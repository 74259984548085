import { IDLE } from "_constants/redux.constants";

const initialCatalogState = {
  all: {
    data: null,
    error: null,
    status: IDLE,
  },
  one: {
    data: null,
    error: null,
    status: IDLE,
  },
  put: {
    data: null,
    error: null,
    status: IDLE,
  },
  post: { 
    data: null,
    error: null,
    status: IDLE
  },
  removeProducts: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialCatalogState;
