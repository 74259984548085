import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { CircleStatus } from "components/atoms/index";
import useModal from "_hooks/useModal";

const Results = ({
  onClose = () => {},
  prefix,
  results: { _with = 0, _without = 0}
}) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();

  return (
    <>
      <div className={`${prefix}__results`}>
        <div>
          <div>
            <CircleStatus status="approved" /> GTINs con imágenes
          </div>
          <div>
            {_with}
          </div>
        </div>

        <div>
          <div>
            <CircleStatus status="required" /> 
            GTINs sin imágenes
          </div>
          <div>
            {_without}
          </div>
        </div>
      </div>
      <div className={`${prefix}__buttons`}>
        <span></span>
        <Button
          content={t("common:words.close")}
          onClick={() => {
            onClose();
            closeModal();
          }}
          sm
          type="purple"
        />
      </div>
    </>
  )
};

export default Results;
