import React, { useEffect, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { EmptyResults } from "components/molecules";
import { SkuCard, Scroller } from "components/organism";
import { ColumnsTemplate, GridLayout, PanelLeft } from "components/templates";

import { productStats } from "_redux/statistics/statistics.actions";
import { resetAllProps } from "_redux/product/product.actions";
import { changeToolbar } from "_redux/ui/ui.actions";
import { selectCatalogOneStore } from "_redux/catalog/catalog.selector";
import { selectLeftPanel, selectSkuSelected } from "_redux/ui/ui.selector";
import { useProductQuery } from "_hooks";

const prefix = "v-products";

const Products = ({ className, isVisible }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const leftPanel = useSelector(selectLeftPanel);
  const selections = useSelector(selectSkuSelected);
  const { data: catalog } = useSelector(selectCatalogOneStore)
  const classForComponent = classNames(prefix, {
    "has-left-panel": leftPanel.isOpen,
    [className]: className
  });

  const { inited, products, productsCount, loading, nextPage,
    initialize: initProducts } = useProductQuery(true, isVisible);

  const skeletonArray = () =>
    Array.from({ length: products.length ? 5 : 15 }, () => ({
      skeleton: true,
    }));

  useLayoutEffect(() => {
    dispatch(changeToolbar("products"));
    dispatch(productStats());
    return () => dispatch(resetAllProps());
  }, []);

  useEffect(() => {
    if (isVisible) initProducts();
  }, [isVisible]);

  return (
    <div className={classForComponent}>
      <ColumnsTemplate
        leftWidth={leftPanel.isOpen ? "32%" : "0%"}
        leftComponent={<PanelLeft />}
        rightComponent={
          <Scroller
            dataLength={catalog ? (catalog?.foldersId?.length ?? 0) : productsCount}
            next={nextPage}
            loading={loading || !products.length}
            hasMore={catalog ? (catalog?.foldersId?.length ) >= products.length : productsCount > products.length}
            isVertical
          >
            <GridLayout>
              {products
                ? products?.map(
                    ({ key, process, filesId, skeleton, _id }, index) => (
                      <SkuCard
                        key={index}
                        sku={`${key ?? undefined}`}
                        status={process}
                        skeleton={skeleton}
                        src={
                          filesId
                            ?.filter((row) => row.fileMasterId === null)
                            .sort((a, b) => {
                              if (a?.index > b?.index) return 1;
                              else if (a?.index < b?.index) return -1;
                              return 0;
                            })
                            ?.map((file) => file.baseKeyS3Carousel) ?? null
                        }
                        id={_id}
                        selected={
                          selections.all
                            ? !selections.selected?.[_id]
                            : selections.selected?.[_id]
                        }
                      />
                    )
                  ) ?? null
                : null}

              {loading
                ? skeletonArray().map((_, i) => (
                    <div
                      key={i}
                      className="u-skeleton"
                      style={{ order: products.length + i }}
                    ></div>
                  ))
                : null}
            </GridLayout>
            {inited && !loading && !products.length &&
              <EmptyResults content={t("products:filters.emptyResults")} />
            }
          </Scroller>
        }
      />
    </div>
  );
};

export default Products;
