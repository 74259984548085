import initialUserState from './user.initialState';
import all from './reducers/all';
import forgot from './reducers/forgot';
import invitation from './reducers/invitation';
import loggedin from './reducers/loggedin';
import login from './reducers/login';
import logout from './reducers/logout';
import post from './reducers/post';
import put from './reducers/put';
import restore from './reducers/restore';

const userReducer = (state = initialUserState, action) => {
  const reducers = {
    ...all,
    ...forgot,
    ...invitation,
    ...loggedin,
    ...login,
    ...logout,
    ...post,
    ...put,
    ...restore
  };

  let { type } = action;
  if (reducers[type] === undefined) return state;

  return reducers[type](state, action);
};

export default userReducer;
