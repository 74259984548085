import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Button, NubeskIcons } from "components/atoms";
import { FlexComponent } from "components/templates";
import useTooltip from "_hooks/useTooltip";
import { Toast } from "components/molecules";
import { getTemplate } from "_redux/app/app.actions";
import { openModal } from "_redux/ui/ui.actions";
import { selectUser } from "_redux/user/user.selectors";
import { ADMIN } from "_constants/user.constants";
import { $$SETTING } from "_constants/permission.constants";

const PropertyToolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });
  const user = useSelector(selectUser);

  const downloadTemplate = async () => {
    toast.info(<Toast 
      area={t("products:newSku.feedback.download_template.area")} 
      info={t("products:newSku.feedback.download_template.started")}
    />);
    
    let response = await dispatch(getTemplate());
    
    if (response?.data?.[0]?.url) {
      window.open(response?.data?.[0]?.url);
    }
  };

  return (
    <>
      <FlexComponent>
        {user.canDoIt($$SETTING.PROP_COL_UPDATE) &&
          <Button
            leftIcon={<NubeskIcons icon="plus" />}
            content={t("settings:actions.newProperty")}
            type={"primary"}
            onClick={() =>
              dispatch(
                openModal({
                  header: {
                    text: t("settings:actions.newProperty"),
                    icon: { type: "plus", size: 20 },
                  },
                  typeModal: "newProperty",
                })
              )
            }
          />
        }
      </FlexComponent>
      <FlexComponent>
        <Button
          type="gray"
          content={"Download template"}
          onClick={downloadTemplate}
          style={{ marginRight: 10 }}
        />
        {user.canDoIt($$SETTING.PROP_COL_UPDATE) &&
          <div {...injectedProps} style={{ display: "flex" }}>
            <Button
              type="gray"
              content={"Import from file"}
              onClick={() => {}}
              disabled
            />{" "}
          </div>
        }
      </FlexComponent>
    </>
  );
};

export default PropertyToolbar;
