import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DropDown, FlexTable } from "components/templates";
import { TaskItemsActions } from "components/organism";
import { AvatarName } from "components/molecules";
import { Badge, Button, CheckBox, FlexCell, NubeskIcons } from "components/atoms";
import { useTask } from "_hooks";
import { resetItems, resetOne } from "_redux/task/task.actions";
import { previousPage } from "_redux/ui/ui.actions";
import { selectAppComponentDrawing, selectAppPropertyColumns } from "_redux/app/app.selector";
import { selectTaskItems, selectTaskOne } from "_redux/task/task.selector";
import nbskReducer from "_reducers";
import { capitalize } from "_utils/string";
import { FAILURE, SUCCESS } from "_constants/redux.constants";
import { TASK_ITEMS } from "_constants/reducers.constants";
import { ITEM_DECLINED, ITEM_DONE, ITEM_INPROGRESS, ITEM_PENDING, ITEM_TOREVIEW } from "_constants/task.constants";
import { APPROVED, REQUIRED, TOREVIEW } from "_constants/product.constants";
import PrioritySelector from "components/molecules/PrioritySelector/PrioritySelector";
import { Avatar } from "components/atoms/index";
import { nestedProps } from "_utils/drawing";
import useAppSettings from "_hooks/useAppSettings";

const prefix = "v-task-items";

const statusColors = {
  [ITEM_DONE]: {
    label: "tasks:items.status.done",
    className: "u-bg-light-green",
    labelClass: "u-white"
  },
  [ITEM_DECLINED]: {
    label: "tasks:items.status.declined",
    className: "u-bg-red",
    labelClass: "u-white"
  },
  [ITEM_INPROGRESS]: {
    label: "tasks:items.status.inProgress",
    className: "u-bg-blue",
    labelClass: "u-white"
  },
  [ITEM_PENDING]: {
    label: "tasks:items.status.pending",
    className: "u-bg-gray",
    labelClass: "u-purple"
  },
  [ITEM_TOREVIEW]: {
    label: "tasks:items.status.toReview",
    className: "u-bg-yellow",
    labelClass: "u-dark-gray"
  }
};

const statusProduct = {
  [APPROVED]: "u-bg-green",
  [TOREVIEW]: "u-bg-yellow",
  [REQUIRED]: "u-bg-dark-gray"
};

const TaskItems = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { taskKey } = useParams();
  const initialized = useRef();
  
  const { appColumnKeyProps } = useAppSettings();
  const { data: task, status: taskStatus } = useSelector(selectTaskOne);
  const { data: products, status: productsStatus } = useSelector(selectTaskItems);
  const { fetch, fetchElements, selected, selectAll, setPriority, resetSelectionItems, toggleSelect } = useTask();
  const cDrawing = useSelector(selectAppComponentDrawing);
  const propertyColumns = useSelector(selectAppPropertyColumns);

  const init = useCallback(() => {    
    fetch({
      w: { key: taskKey }
    });
  }, [fetch, taskKey]);

  useEffect(() => {
    return () => {
      dispatch(resetOne());
      dispatch(resetItems());
      resetSelectionItems();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!initialized?.current) {
      initialized.current = true;
      init();
    }
  }, [init]);

  useEffect(() => {
    if (!task) return;
    fetchElements(task._id, task.foldersId?.map(r => r._id))
  }, [task]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={prefix}>
      <div className={`${prefix}__task-description`}>
        <Badge
          className="u-bg-purple"
          content={task?.type ? t(`tasks:table.types.${task?.type?.toUpperCase()}`, { key: appColumnKeyProps?.name}) : ""} 
          labelClass="u-white"
        />
        {task?.data?.description} 
      </div>
      <div className={`${prefix}__actions-bar`} >
        <TaskItemsActions 
          filteredItems={task?.foldersId?.length}
          total={task?.foldersId?.length}
        />
      </div>
      <FlexTable 
        className={`${prefix}__table`}
        bodyClass={`${prefix}__table-body`}
        emptyData={t("tasks:table.itemsTable.emptyData", { key: appColumnKeyProps?.name })}
        gridTemplateColumns="40px 50px minmax(200px, 1fr) repeat(2, 1fr) 80px 110px 140px 150px 120px 40px"
        headerCol={11}
        rowCol={11}
        showEmpty={[SUCCESS, FAILURE].indexOf(taskStatus) !== -1 && [SUCCESS, FAILURE].indexOf(productsStatus) !== -1}
        headers={
          <>
            <FlexCell></FlexCell>
            <FlexCell className="u-uppercase" justify="center">
              <NubeskIcons className icon="photos" size="2.1rem" />
            </FlexCell>
            <FlexCell className="u-uppercase">{capitalize(appColumnKeyProps?.name)}</FlexCell>
            <FlexCell className="u-uppercase">
              {cDrawing?.tasks?.itemsTable?.fields?.[0] ?
                propertyColumns?.filter((p) => p.key === cDrawing.tasks.itemsTable.fields[0]?.replace("data.", ""))?.[0]?.name
              :
                t("tasks:table.itemsTable.emptyCol1")
              }
            </FlexCell>
            <FlexCell className="u-uppercase">
              {cDrawing?.tasks?.itemsTable?.fields?.[1] ?
                propertyColumns?.filter((p) => p.key === cDrawing.tasks.itemsTable.fields[1]?.replace("data.", ""))?.[0]?.name
              :
                t("tasks:table.itemsTable.emptyCol2")
              }
            </FlexCell>
            <FlexCell className="u-uppercase">{t("tasks:table.itemsTable.photo")}</FlexCell>
            <FlexCell className="u-uppercase" justify="center">{t("tasks:table.itemsTable.info")}</FlexCell>
            <FlexCell className="u-uppercase">{t("tasks:table.itemsTable.manager")}</FlexCell>
            <FlexCell className="u-uppercase">{t("tasks:table.itemsTable.priority")}</FlexCell>
            <FlexCell className="u-uppercase">{t("tasks:table.itemsTable.status")}</FlexCell>
            <FlexCell></FlexCell>
          </>
        }
        rows= {products?.map((r) => <>
          <FlexCell>
            <CheckBox 
              checked={selectAll || selected?.indexOf(r._id) !== -1} 
              onChange={async () => {
                if (selectAll) {
                  let ids = products?.map((p) => p._id).filter((p) => p !== r._id);
                  toggleSelect(ids);
                } else {
                  toggleSelect(r._id);
                }
              }}
            />
          </FlexCell>
          <FlexCell>
            {r.filesId?.[0]?.baseKeyS3Carousel ? 
              <img src={r.filesId?.[0]?.baseKeyS3Carousel} className="--thumbnail" alt="" />
            : <NubeskIcons className="u-purple --thumbnail" icon="photos" size="2.1rem" />}
          </FlexCell>
          <FlexCell className="u-purple">
            <Button 
              content={r.key} 
              type="link"
              onClick={(e) => nbskReducer(TASK_ITEMS.TO_DETAILS, {
                action: () => {
                  e.preventDefault()
                  dispatch(previousPage({ location, params: { taskKey }}))
                },
                history,
                key: r.key,
                location,
                params: { taskKey }
              })}
            />
          </FlexCell>
          <FlexCell>{nestedProps(r, cDrawing?.tasks?.itemsTable?.fields?.[0])}</FlexCell>
          <FlexCell>{nestedProps(r, cDrawing?.tasks?.itemsTable?.fields?.[1])}</FlexCell>
          <FlexCell>
            <Badge
              content={`(${r?.filesId?.length || 0})`}
              className={statusProduct?.[r?.process?.reviewMaster]}
              placement="outside"
            />
          </FlexCell>
          <FlexCell justify="center">
            <Badge
              content={r?.total ? `(${r?.copy?.length}/${r?.total})`: ""}
              className={statusProduct?.[r?.process?.reviewCopy]}
              placement="outside"
            />
          </FlexCell>
          <FlexCell>
            {r?.tasksId?.[0]?.data?.assignedTo ?
              <Avatar
                colorRange={[107, 112, 213]}
                firstName={[...task?.share, ...[task?.userId]]?.filter(
                  (u) => u._id === r?.tasksId?.[0]?.data?.assignedTo
                ).pop()?.account?.firstName}
                lastName={[...task?.share, ...[task?.userId]]?.filter(
                  (u) => u._id === r?.tasksId?.[0]?.data?.assignedTo
                ).pop()?.account?.lastName}
              />
            :
              <AvatarName 
                colorRange={[107, 112, 213]}
                letters={" "}
                emptyLabel={t("tasks:table.nobody")}
              />
            }
          </FlexCell>
          <FlexCell>
            <PrioritySelector 
              value={r?.tasksId?.[0]?.data?.priority || undefined}
              onChange={(priority) => setPriority(taskKey, r?._id, priority, "taskItems")}
            />
          </FlexCell>
          <FlexCell>
            <Badge
              className={statusColors?.[r.tasksId?.[0]?.data?.status]?.className}
              height="30px"
              labelClass={statusColors?.[r.tasksId?.[0]?.data?.status]?.labelClass}
              content={t(statusColors?.[r.tasksId?.[0]?.data?.status]?.label)}
            />
          </FlexCell>
          <FlexCell justify="center">
            <DropDown
              buttonProps={{
                content: <NubeskIcons icon="dots" />,
                type: "color",
              }}
              options={[]}
            />
          </FlexCell>
        </>)}
      />
    </div>
  )
};

export default TaskItems;
