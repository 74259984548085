import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const one = {
  [PRODUCT.ONE_STARTED]: (state) => ({
    ...state,
    one: {
      ...state.one,
      status: LOADING
    }
  }),

  [PRODUCT.ONE_FAIL]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [PRODUCT.ONE_SUCCESS]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),

  [PRODUCT.ONE_REFRESH]: (state, { payload }) => {
    let newState = {...state.one.data};
    if (!newState) return state;
    
    if (newState?._id === payload?._id) {
      newState = { ...newState, ...payload.c };
    }

    return {
      ...state,
      one: {
        ...state.one,
        data: newState
      }
    };
  },

  [PRODUCT.ONE_RESET]: (state) => ({
    ...state,
    one: initialProductState.one
  })
};

export default one;
