// For each args passed will be taken as expresion OR
// if pass to same array arg, will be taken as expresion AND all elements of array. 

const isA = function (...args) {
  let output = true;

  if (args.length === 0) return false;
    
  all:
  for (let a of args) {
    let auxArray = a;
    if (!Array.isArray(a)) auxArray = [a];

    for (let profile of auxArray) {
      if (this.profile.indexOf(profile) === -1) {
        output = false;
        break all;
      }
    }
  }
  
  return output;
};

export default isA;
