import { NbskEditor } from "components/organism";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { NubeskIcons } from "components/atoms";
import { useLayer, useProduct } from "_hooks";
import { AI } from "_constants/layer.constants";

const prefix = "t-product-canvas-editor";

const ProductCanvasEditor = () => {
  const { approvePreviewLayer } = useLayer();
  const { canvasEditor: { data }, closeCanvasEditor } = useProduct();
  const { t } = useTranslation();

  const [index, setIndex] = useState(0);
  const [canvasLocal, setCanvasLocal] = useState();
  const [currentJson, setCurrentJson] = useState();

  const close = () => {
    closeCanvasEditor();
  };
  
  useEffect(() => {
    if (data?.previews && index !== 0) setIndex(0);
  }, [data?.previews]);

  useEffect(() => {
    if (index === undefined) return;
    setCurrentJson(data?.previews?.[0]?.renderObject);
  }, [index]);

  return (
    <div className={prefix}>
      <div className={`${prefix}__header`} >
        <div>
          <div className="--data">
            <Button
              leftIcon={<NubeskIcons icon="arrowLeft" />}
              onClick={() => close()}
              style={{ width: "max-content" }}
              type="color"
            />
            <div className="--product-key">{data?.sku}</div>
          </div>

          <div className="--index">{`(${index + 1} de ${data?.previews?.length})`}</div>
        </div>

        <div>
          <Button
            content={t("common:form.cancel")}
            onClick={(e) => {
              e.preventDefault();

              if (data?.previews?.[index + 1]) {
                setIndex((i) => (i + 1));
              } else {
                close();
              }
            }}
            sm
            type="primary"
          />
          <Button
            content="Aprobar imagen"
            onClick={async (e) => {
              e.preventDefault();
              let { adaptationLayerId, fileId, folderId } = data?.previews?.[index];
              
              let response = await approvePreviewLayer({
                adaptationLayerId,
                folderId,
                fileId,
                renderObject: JSON.stringify(canvasLocal.toJSON(['nbskProperties'])),
                $config: {}
              });

              if (typeof data?.onAfterSave === "function") {
                data.onAfterSave(response?.data?.[0]);
              }
              close();
            }}
            sm
            type="success"
          />
        </div>
      </div>
      
      {data?.previews?.[index] && currentJson &&
        <NbskEditor
          aiConfig={{
            folderId: data?.previews?.[index]?.folderId,
            fileId: data?.previews?.[index]?.fileId,
            adaptationLayerId: data?.previews?.[index]?.adaptationLayerId,
            prompts: data?.prompts,
            modifiers: data?.modifiers
          }}
          allowedSection={[...['actions', 'associates', 'size', 'text', 'style'], ...(data?.layer?.type === AI) ? ["ai"] : []]}
          bgImages={data?.bgImages ?? null}
          canvasWidth={data?.layer.property?.canvas?.width}
          canvasHeight={data?.layer.property?.canvas?.height}
          json={data?.previews?.[index]?.renderObject ? JSON.parse(data?.previews?.[index]?.renderObject) : {}}
          onInit={({ canvas }) => setCanvasLocal(canvas)}
        />
      }
    </div>
  );
};

export default ProductCanvasEditor;
