import initialSyncfoniaState from "../syncfonia.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as SYNCFONIA } from '../../syncfonia.constants';

const updateCatalogProvider = {
  [SYNCFONIA.UPDATE_CATALOG_PROVIDER_STARTED]: (state) => ({
    ...state,
    updateCatalogProvider: {
      ...state.updateCatalogProvider,
      status: LOADING
    }
  }),
    
  [SYNCFONIA.UPDATE_CATALOG_PROVIDER_FAIL]: (state, { payload }) => ({
    ...state,
    updateCatalogProvider: {
      ...state.updateCatalogProvider,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [SYNCFONIA.UPDATE_CATALOG_PROVIDER_SUCCESS]: (state, { payload }) => ({
    ...state,
    updateCatalogProvider: {
      ...state.updateCatalogProvider,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [SYNCFONIA.UPDATE_CATALOG_PROVIDER_RESET]: (state) => ({
    ...state,
    updateCatalogProvider: initialSyncfoniaState.updateCatalogProvider
  })
};

export default updateCatalogProvider;
