import { reduxActions as ADAPTATION } from '_constants/adaptation.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const add = {
  [ADAPTATION.ADD_STARTED]: (state) => ({
    ...state,
    post: {
      ...state.post,
      status: LOADING
    }
  }),

  [ADAPTATION.ADD_SUCCESS]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    },
    all: {
      ...state.all,
      data: [...state.all.data, ...payload?.data]
    }
  }),

  [ADAPTATION.ADD_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      error: payload?.error ?? null,
      status: FAILURE
    }
  })
};

export default add;
