import React, { useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { DropDown } from "components/templates";
import { Button, NubeskIcons } from "components/atoms";
import { useProductFilter, useTooltip, useUser } from "_hooks";
import { addProducts, toArchived, toTrash, share as shareCatalog } from "_redux/catalog/catalog.actions";
import { fetchAllList } from "_redux/product/product.actions";
import { openModal } from "_redux/ui/ui.actions";
import { selectSkuSelected } from "_redux/ui/ui.selector";
import { ageFormat } from "_utils/format";
import toastUndo from "_utils/feedback/toastUndo";
import { ARCHIVED, OWNER, SHARED } from "_constants/catalog.constants";
import chunk from "_utils/array/chunk";
import { $CATALOG_UPDATE } from "_constants/permission.constants";

const Cell = ({ _id, name, share, date, qty, onClick, type, withSelection }) => {
  const dispatch = useDispatch();
  const { injectedProps } = useTooltip();

  const { filterToW } = useProductFilter();
  const { t } = useTranslation();
  const { all, selected } = useSelector(selectSkuSelected);
  const [hover, setHover] = useState(false);
  const { user } = useUser();

  const getOptions = () => {
    let finalOpts = [];
    let opts = {
      dowload: {
        content: t("catalogs:actions.download"),
        onClick: () => {}
      },
      share: {
        content: t("catalogs:actions.share"),
        onClick: () =>
          dispatch(
            openModal({
              typeModal: "share",
              header: { text:  t("catalogs:general.share.share"), rightItem: name },
              modalProps: {
                _id,
                share,
                onShare: (users) => dispatch(shareCatalog(_id, users?.map((u) => u._id), false)),
                buttons: {
                  share: t("catalogs:general.share.share")
                }
              },
            })
          ),
      },
      archive: {
        content: t("catalogs:actions.archive"),
        onClick: () => dispatch(toArchived(_id)),
      },
      delete: {
        content: t("catalogs:actions.delete"),
        onClick: () => dispatch(toTrash(_id)),
      }
    }

    switch (type) {
      case OWNER:
        finalOpts = [opts.share, opts.archive];
        break;
      case SHARED:
        finalOpts = [opts.share];
        break;
      case ARCHIVED:
        finalOpts = [opts.delete];
        break;
      default:
        break;
    }

    return finalOpts;
  };

  const handlePut = async () => {
    let productsId;

    if (all) {
      let filters = await filterToW();
      let response = await dispatch(fetchAllList({ w: filters || {} }));
      productsId = response?.data?.map((p) => p._id).filter((item) => !selected[item]);
    } else if (Object.keys(selected).length) {
      productsId = Object.keys(selected);
    }
    let length = productsId.length;

    if (!length) return;

    productsId = chunk(productsId, 400);

    toastUndo({
      area: "catalogs:table.add_to_catalog.feedback.area",
      info: `${t("catalogs:table.add_to_catalog.feedback.future", {
        catalog: name,
        qty: length,
      })}`,
    },
    {},
    async () => {
      for (let i = 0; i < productsId.length; i++) {
        let extras = {};
        if (i < productsId.length - 1) {
          extras = {
            feedback: {
              error: true,
              success: false
            }
          }
        }
        await dispatch(
          addProducts(_id, { foldersId: productsId[i], name }, extras)
        );
      }
    });
  };

  return (
    <div
      className={`m-table-catalogs__cell${hover ? " drag-hover" : ""}`}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div
        onClick={(e) => {
          e.stopPropagation();
          onClick?.();
        }}
      >
        <span className="m-table-catalogs__cell-item name" {...injectedProps}>
          {name}
        </span>
        <span className="m-table-catalogs__cell-item">{qty}</span>
        <span className="m-table-catalogs__cell-item">{ageFormat(date)}d</span>
      </div>
      {withSelection ? (
        <Button
          disabled={!user?.canDoIt($CATALOG_UPDATE)}
          content={<NubeskIcons icon="plus" size={18} onClick={handlePut} />}
          type="color"
        />
      ) : (
        <DropDown
          options={getOptions()}
          buttonProps={{
            content: <NubeskIcons icon="dots" />,
            type: "color",
          }}
        />
      )}
    </div>
  );
};

export default Cell;

Cell.propTypes = {
  name: PropTypes.string,
  date: PropTypes.string,
  qty: PropTypes.number,
};
