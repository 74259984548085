import initialAnalysisState from "../analysis.initialState";
import { reduxActions as ANALYSIS } from "_constants/analysis.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const layoutCopy = {
  [ANALYSIS.LAYOUT_COPY_STARTED]: (state) => ({
    ...state,
    layoutCopy: {
      ...state.layoutCopy,
      status: LOADING
    }
  }),
  
  [ANALYSIS.LAYOUT_COPY_FAIL]: (state, { payload }) => ({
    ...state,
    layoutCopy: {
      ...state.layoutCopy,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  
  [ANALYSIS.LAYOUT_COPY_SUCCESS]: (state, { payload }) => ({
    ...state,
    layoutCopy: {
      ...state.layoutCopy,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [ANALYSIS.LAYOUT_COPY_RESET]: (state) => ({
    ...state,
    layoutCopy: initialAnalysisState.layoutCopy
  }),
};

export default layoutCopy;
