import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  find, post, put,
  updateItemProps,
  refreshTask,
  share,
  toggleSelectAllItems,
  toggleSelectItem
} from "_redux/task/task.actions";
import { find as findProducts, refreshProduct } from "_redux/product/product.actions";
import { selectTaskOne, selectTaskSelection } from "_redux/task/task.selector";
import { reduxActions as TASK } from "_constants/task.constants";
import useAppSettings from "./useAppSettings";

const useTask = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { allItems: selectAll, items: selected } = useSelector(selectTaskSelection);
  const { data: task } = useSelector(selectTaskOne);

  const { appColumnKeyProps } = useAppSettings();

  /**
   * 
   * @param { Object } params 
   * @returns 
   */
  const fetch = (params) =>
    dispatch(find({
      f: params?.f ?? [
        "_id",
        "data",
        "key",
        "description",
        "foldersId",
        "name",
        "process",
        "status",
        "userId",
        "share"
      ],
      w: params.w,
      a: {
        extended: {
          f: {
            foldersId: ["_id"],
            userId: ["account"],
            share: ["account"]
          }
        }
      }
    }, {
      types: [
        TASK.ONE_STARTED,
        TASK.ONE_SUCCESS,
        TASK.ONE_FAIL
      ]
    }));

  /**
   * 
   * @param { String } taskId 
   * @param { Array } elements 
   * @returns 
   */
  const fetchElements = (taskId, elements) => dispatch(findProducts({
    a: {
      extended: {
        f: {
          tasksId: ["_id", "data", "priority"],
          filesId: ["_id", "baseKeyS3Carousel"]
        },
        w: {
          tasksId: {
            taskId: taskId
          },
          filesId: {
            fileMasterId: null
          }
        }
      },
      sort: {
        by: {
          createdAt: -1
        }
      }
    },
    f: ["key", "filesId", "process", "tasksId", "data"],
    w: {
      _id: { $in: elements }
    }
  }, {
    types: [
      TASK.ITEMS_STARTED,
      TASK.ITEMS_SUCCESS,
      TASK.ITEMS_FAIL
    ]
  }));

  /**
   * 
   * @param { String } id
   * @param { Array } elements
   * @returns 
   */
  const addElements = (id, elements) => dispatch(put(id, {
    foldersId: elements
  }, null, {
    feedback: {
      error: true,
      success: {
        area: "modals:addToTask.feedback.assign.area",
        info: t("modals:addToTask.feedback.assign.info", { key: appColumnKeyProps?.name?.toUpperCase() })
      }
    }
  }));

  const create = (data) => dispatch(post(data, {
    feedback: {
      error: true,
      success: {
        area: "modals:addToTask.feedback.create.area",
        info: "modals:addToTask.feedback.create.info"
      }
    }
  }));

  const removeFromTask = (taskId, foldersId) => dispatch(put(taskId, {
    foldersId: Array.isArray(foldersId) ? foldersId : [foldersId]
  }, {
    $action: "remove"
  }, {
    types: [
      TASK.REMOVE_FROM_STARTED,
      TASK.REMOVE_FROM_SUCCESS,
      TASK.REMOVE_FROM_FAIL
    ],
    feedback: {
      success: false,
      error: false
    }
  }));

  const resetSelectionItems = () => dispatch({ type: TASK.SELECTION_ITEMS_RESET })

  const selectionClean = () => dispatch(resetSelectionItems());

  const setPriority = async (key, foldersId, priority, refresh) => {
    let response = await dispatch(updateItemProps(key, foldersId, { data: { priority } }));

    try {
      dispatch(refreshTask(foldersId, {
        tasksId: response.data
      }, refresh));
    } catch (e) { }
    return response;
  };

  const setProcessStatus = async (key, foldersId, status, refresh) => {
    let response = await dispatch(updateItemProps(key, foldersId, { data: { status } }));

    try {
      dispatch(refreshProduct(foldersId, {
        tasksId: response.data
      }, refresh));
    } catch (e) { }
    return response;
  }

  const shareTask = async (id, newShare, refresh) => dispatch(share(id, newShare, refresh));

  const toggleSelect = (id) => dispatch(toggleSelectItem(id));

  const toggleSelectAll = () => dispatch(toggleSelectAllItems());

  const update = async (id, data, refresh) => {
    let response;
    try {
      response = await dispatch(put(id, data, null, {
        feedback: {
          error: true,
          success: {
            area: "tasks:newTask.feedback.update.area",
            info: "tasks:newTask.feedback.update.info"
          }
        }}));
      await dispatch(refreshTask(id, {
        data: response?.data?.[0]?.data,
        name: response?.data?.[0]?.name,
        process: response?.data?.[0]?.process,
        type: response?.data?.[0]?.type
      }, refresh));
    } catch (e) { }
    return response;
  }

  return {
    addElements,
    create,
    fetch,
    fetchElements,
    removeFromTask,
    resetSelectionItems,
    selectAll,
    selectionClean,
    selected,
    setPriority,
    setProcessStatus,
    shareTask,
    task,
    toggleSelect,
    toggleSelectAll,
    update
  };
};

export default useTask;
