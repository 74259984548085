import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { InputLabel, Select } from "components/molecules/Inputs";
import { useTask, useTasks } from "_hooks";
import { closeModal } from "_redux/ui/ui.actions";
import { resetPut } from "_redux/task/task.actions";

const prefix = "o-add-to-task-form";

const AddToTaskForm = ({ elements, onCancel, onFinished }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState();
  const [selected, setSelected] = useState();

  const { addElements } = useTask();
  const { getTaskList } = useTasks();

  const submitHandler = async () => {
    setLoading(true);
    let response = await addElements(selected.value, elements);
    setLoading(false);
    
    if (typeof onFinished === "function") onFinished(response?.data?.[0]);
    
    dispatch(closeModal());
  };

  const fetch = async () => {
    setLoading(true);
    let response = await getTaskList();
    let output = [];
    for (let element of response?.data ?? []) {
      output.push({
        label: element.name,
        value: element._id
      });
    }
    setOptions(output);
    setLoading(false);
  };

  useEffect(() => {
    fetch();

    return () => {
      dispatch(resetPut());
    }
  }, []);

  return (
    <div className={prefix}>
      <InputLabel label="Tarea en curso">
        <Select
          emptyLabel={t("common:form.select")}
          onChange={(o) => setSelected(o)}
          options={options}
        />
      </InputLabel>
      <div className={`${prefix}__buttons`}>
        <Button 
          content={t("common:form.cancel")}
          onClick={onCancel}
          sm
          type="underline"
        />
        <Button 
          content="Asignar"
          disabled={!selected || loading}
          sm
          onClick={submitHandler}
          type="purple"
        />
      </div>

    </div>
  );
};

AddToTaskForm.propTypes = {
  onCancel: PropTypes.func
};

AddToTaskForm.defaultProps = {
  onClose: () => {}
};

export default AddToTaskForm;
