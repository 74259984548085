import React, { useEffect } from "react";
import classNames from "classnames";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai"; //TODO remplazar por nubesk-icons llegado a esta vista
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { selectShortcuts } from "_redux/ui/ui.selector";
import { useLocation } from "react-router-dom";

const ImageDots = ({ className, length, index, setIndex }) => {
  const { pathname } = useLocation();
  const shortcuts = useSelector(selectShortcuts);
  useEffect(() => {
    if (pathname === "/products") return false;
    if (shortcuts?.photo === 1) {
      return setIndex((previousState) =>
        previousState === length - 1 ? 0 : previousState + 1
      );
    }
    if (shortcuts?.photo === 0) {
      setIndex((previousState) =>
        !previousState ? length - 1 : previousState - 1
      );
    }
  }, [shortcuts]);
  if (!length || length <= 1) return false;
  const prefix = "m-image-dots";
  const classForComponent = classNames(prefix, {
    [className]: className,
  });
  let dots = Array.from({ length }, (_, i) => i + 1);

  return (
    <div className={classForComponent} data-testid="q-image-dots">
      <AiOutlineLeft
        title="Arrow left"
        onClick={(e) => {
          e.stopPropagation();
          setIndex((previousState) =>
            !previousState ? length - 1 : previousState - 1
          );
        }}
      />
      {dots.map((i) => (
        <div
          key={i}
          className={`${prefix}__dots ${i === index + 1 ? "is-active" : ""}`}
        ></div>
      ))}
      <AiOutlineRight
        title="Arrow Right"
        onClick={(e) => {
          e.stopPropagation();
          setIndex((previousState) =>
            previousState === length - 1 ? 0 : previousState + 1
          );
        }}
      />
    </div>
  );
};

export default ImageDots;

ImageDots.propTypes = {
  className: PropTypes.string,
  length: PropTypes.number,
  index: PropTypes.number,
  setIndex: PropTypes.func,
};
