import Avatar from "components/atoms/Avatar/Avatar";
import React from "react";
import classNames from "classnames";
import { DropDown, FlexComponent } from "components/templates/index";
import { NubeskIcons } from "components/atoms/index";
import { useTranslation } from "react-i18next";

const prefix = "m-user-row";

const UserRow = ({
  firstName,
  lastName,
  email,
  active,
  onClick,
  inCatalogs,
}) => {
  const { t } = useTranslation("catalogs");
  const classForComponent = classNames(prefix, {
    "is-active": active,
    "is-catalogs": inCatalogs,
  });
  return (
    <div className={classForComponent} onClick={onClick}>
      <div className={`${prefix}__user`}>
        <FlexComponent gap={12}>
          <Avatar
            firstName={firstName}
            lastName={lastName}
            email={email}
            noTooltip
          />
          <span>{email}</span>
        </FlexComponent>
      </div>{" "}
      {inCatalogs ? (
        <DropDown
          options={[
            { content: t("general.share.remove"), onClick: () => {} },
            { content: t("general.share.notify"), onClick: () => {} },
          ]}
          buttonProps={{
            content: <NubeskIcons icon="dots" />,
            type: "color",
          }}
        />
      ) : (
        <input
          type="checkbox"
          name="user"
          checked={active}
          onChange={() => {}}
        />
      )}
    </div>
  );
};

export default UserRow;
