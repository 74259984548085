import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FlexComponent } from "components/templates";
import { selectUser } from "_redux/user/user.selectors";
import RowTemplate from "../RowTemplate/RowTemplate";
import { $$FILE, $$FOLDER, $LINK_DOWNLOAD } from "_constants/permission.constants";

const prefix = "m-master-row";

const LayerRow = ({
  bussy,
  date,
  id,
  isActive,
  onClick,
  onDelete,
  title,
  qty,
}) => {
  const { t } = useTranslation();
  const user = useSelector(selectUser);

  const getOptions = () => {
    let finalOpts = [];
    let opts = {
      edit: {
        onClick: () => {},
        content: t("products:dataPhotos.edit", {key: ""}),
        disabled: true
      },

      delete: {
        onClick: () => onDelete(id),
        content: t("products:dataPhotos.delete_files"),
      }
    };

    if (user.canDoIt($$FILE.DELETE)) finalOpts.push(opts.delete);
    
    return finalOpts;
  };

  return (
    <RowTemplate
      onClick={onClick}
      title={title}
      qty={qty}
      isActive={isActive}
      options={getOptions()}
    >
      <FlexComponent>
        {bussy ? <span>Processing...</span> : null}
        {!bussy && date ? (
          <>
            <span className={`${prefix}__date`}>{date}</span>
          </>
        ) : null}
        {!bussy && !date ? (
          <button
            onClick={(e) => e.stopPropagation()}
            className={`${prefix}__generate-button`}
          >
            {t("products:dataPhotos.upload_button")}
          </button>
        ) : null}
      </FlexComponent>{" "}
    </RowTemplate>
  );
};

export default LayerRow;
