export const reduxActions = {
  ALL_STARTED: "layer/all_started",
  ALL_SUCCESS: "layer/all_success",
  ALL_FAIL: "layer/all_fail",
  ALL_RESET: "layer/all_reset",

  CANVAS_EDITOR_DATA: "layer/canvas_data",
  CANVAS_EDITOR_TOGGLE: "layer/canvas_editor_toggle",

  LIST_STARTED: "layer/list_started",
  LIST_SUCCESS: "layer/list_success",
  LIST_FAIL: "layer/list_fail",
  LIST_RESET: "layer/list_reset",

  GENERATE_STARTED: "layer/generate_started",
  GENERATE_SUCCESS: "layer/generate_success",
  GENERATE_FAIL: "layer/generate_fail",
  GENERATE_RESET: "layer/generate_reset",

  ONE_FAIL: "layer/one_fail",
  ONE_RESET: "layer/one_reset",
  ONE_STARTED: "layer/one_started",
  ONE_SUCCESS: "layer/one_success",

  POST_FAIL: "layer/post_fail",
  POST_RESET: "layer/post_reset",
  POST_STARTED: "layer/post_started",
  POST_SUCCESS: "layer/post_success",

  PUT_FAIL: "layer/put_fail",
  PUT_RESET: "layer/put_reset",
  PUT_STARTED: "layer/put_started",
  PUT_SUCCESS: "layer/put_success",

  PREVIEW_STARTED: "layer/preview_started",
  PREVIEW_SUCCESS: "layer/preview_success",
  PREVIEW_FAIL: "layer/preview_fail",
  PREVIEW_RESET: "layer/preview_reset"
};

export const TEMPLATE_TYPES = {
  "1": "portrait",
  "2": "landscape",
  "3": "square"
};

export const ACTIVE = "active";
export const HERO = "hero";
export const AI = "ai";
export const ISOMETRIC = "isometric";
export const WATERMARK = "watermark";

export const SHOW_TYPES = {
  [HERO]: "HERO",
  [AI]: "AI",
  [ISOMETRIC]: "ISOM",
  [WATERMARK]: "WM"
};

export const AI_BACKGROUND = "background";
export const AI_INTEGRATED = "integrated";

export const AI_PHOTOGRAPHIC = "photographic";
