import { Button, NubeskIcons, Title } from "components/atoms/index";
import { BorderInputs, FlexComponent } from "components/templates/index";
import React from "react";
import SimpleScroller from "../Scroller/SimpleScroller";
const prefix = "o-details-task";
const DetailsTask = () => {
  return (
    <div className={prefix}>
      <div className={`${prefix}__header`}>
        <Title content="Tasks & System log" secondary />
      </div>
      <div className={`${prefix}__wrapper`}>
        <SimpleScroller>
          <div className={`${prefix}__content`}>
            <div className={"card-task"}>
              <div className={"card-task__header"}>
                <FlexComponent sm>
                  <div className="icon">KM</div>
                  <span className={"card-task__header-name"}>Karen M.</span>
                </FlexComponent>
                <FlexComponent sm>
                  <span>06/18/21 16:20</span>
                </FlexComponent>
              </div>
              <div className={"card-task__description"}>
                <p>
                  The romance copy still empty, please notify me when is
                  complete to review it. Thx
                </p>
              </div>
              <div className={"card-task__footer"}>
                <span className={"card-task__footer-status"}>UNSOLVED</span>
                <FlexComponent sm>
                  <Button content="Reply" type={"purple"} />
                  <Button content="Solve" type={"blue"} />
                </FlexComponent>
              </div>
            </div>
            <div className={"card-task"}>
              <div className={"card-task__header"}>
                <FlexComponent sm>
                  <div className="icon">AZ</div>
                  <span className={"card-task__header-name"}>Antoine Z.</span>
                </FlexComponent>
                <FlexComponent sm>
                  <span>06/18/21 16:50</span>
                </FlexComponent>
              </div>
              <div className={"card-task__description"}>
                <p>Please, can you check if it's ok.</p>
              </div>
              <div className={"card-task__footer"}>
                <span className={"card-task__footer-status"}>
                  Reply to Karen M.
                </span>
                <FlexComponent sm>
                  <Button content="Reply" type={"purple"} />{" "}
                  <Button content="Solve" type={"blue"} />
                </FlexComponent>
              </div>
            </div>
            <div className={"card-task is-solved"}>
              <div className={"card-task__header"}>
                <FlexComponent sm>
                  <div className="icon">KM</div>
                  <span className={"card-task__header-name"}>Karen M.</span>
                </FlexComponent>
                <FlexComponent sm>
                  <span>06/18/21 17:40</span>
                </FlexComponent>
              </div>
              <div className={"card-task__description"}>
                <p>All very well. Thanks</p>
              </div>
              <div className={"card-task__footer"}>
                <span className={"card-task__footer-status"}>SOLVED</span>
              </div>
            </div>
          </div>
        </SimpleScroller>
        <div className={`${prefix}__footer`}>
          <div className={`${prefix}__footer-input`}>
            <BorderInputs>
              <input placeHolder="Write your message..."></input>
              <div className={`${prefix}__footer-input-butons`}>
                {" "}
                <FlexComponent sm>
                  <Button content={<NubeskIcons icon="clip" />} type="color" />
                  <Button content={<NubeskIcons icon="send" />} type="purple" />
                </FlexComponent>
              </div>
            </BorderInputs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsTask;
