import initialCatalogState from "../catalog.initialState";
import { reduxActions as CATALOG } from "_constants/catalog.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const post = {
  [CATALOG.POST_STARTED]: (state) => ({
    ...state,
    post: {
      data: null,
      error: null,
      status: LOADING,
    },
  }),

  [CATALOG.POST_SUCCESS]: (state, { payload }) => ({
    ...state,
    post: {
      status: SUCCESS,
      data: payload?.data?.[0] ?? null,
      error: null,
    },
    all: {
      ...state.all,
      data: payload?.data?.[0]
        ? [payload?.data?.[0], ...state.all.data]
        : [...state.all.data],
    },
  }),

  [CATALOG.POST_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      status: FAILURE,
      data: null,
      error: payload?.data?.status?.[0]?.desc,
    },
  }),

  [CATALOG.POST_RESET]: (state) => ({
    ...state,
    post: initialCatalogState.post,
  }),
};

export default post;
