import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { Button, NubeskIcons } from "components/atoms";
import { DropDown, FlexComponent, SearchPanel } from "components/templates";
import { openModal, toggleFilter } from "_redux/ui/ui.actions";
import {
  selectProductCount,
  selectProductCountPiece,
} from "_redux/product/product.selector";
import { selectSkuSelected } from "_redux/ui/ui.selector";
import useTooltip from "_hooks/useTooltip";

const CatalogsToolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { selected } = useSelector(selectSkuSelected);

  const productCount = useSelector(selectProductCount);
  const productCountPiece = useSelector(selectProductCountPiece);

  const [filterCounter] = useState(0);
  const [search] = useState();
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });

  return (
    <>
      <FlexComponent>
        <Button
          leftIcon={<NubeskIcons icon="plus" />}
          content={t("catalogs:general.toolbar.new")}
          type={"primary"}
          onClick={() => {
            dispatch(
              openModal({
                typeModal: "newCatalog",
                header: {
                  text: `New catalog ${
                    Object.keys(selected).length ? "with selection" : ""
                  }`,
                  icon: { type: "plus", size: 20 },
                },
              })
            );
          }}
        />
        <DropDown
          options={[
            {
              onClick: () => {},
              content: "Download XLS",
              onMouseMove: injectedProps.onMouseMove,
              onMouseLeave: injectedProps.onMouseLeave,
              disabled: true
            },
            {
              onClick: () => {},
              content: "Upload photos",
              onMouseMove: injectedProps.onMouseMove,
              onMouseLeave: injectedProps.onMouseLeave,
              disabled: true
            },
            {
              onClick: () => {},
              content: "Set field",
              onMouseMove: injectedProps.onMouseMove,
              onMouseLeave: injectedProps.onMouseLeave,
              disabled: true
            },
          ]}
          buttonProps={{
            content: t("catalogs:general.toolbar.dropdown"),
            type: "primary",
            rightIcon: <NubeskIcons icon="down" />,
          }}
        />
      </FlexComponent>
      <SearchPanel
        qty={productCountPiece}
        total={productCount}
        filterCounter={filterCounter}
        onToggleFilters={() => dispatch(toggleFilter())}
        value={search}
      />
    </>
  );
};

export default CatalogsToolbar;
