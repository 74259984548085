import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { Button, NubeskIcons } from "components/atoms/index";
import { StatisticsButton } from "components/molecules/index";
import { FlexComponent } from "components/templates/index";
const colors = ["#91E34C", "#FDF655", "#EA402D"];
const NewStats = ({ onClose, count, selected }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    setData([
      { name: "Group A", value: count["approved"] },
      { name: "Group B", value: count["toReview"] },
      { name: "Group C", value: count["rejected"] },
    ]);
  }, [count]);
  return (
    <div className="o-new-stats" onMouseLeave={onClose}>
      <Button
        className={`o-new-stats__close`}
        content={<NubeskIcons icon="times" />}
        type={"color"}
        sqr
        sm
        onClick={onClose}
      />
      <FlexComponent gap={10}>
        <div className="o-new-stats__item">
          {selected ? (
            <span>
              <CountUp end={selected} duration={1} /> of
            </span>
          ) : null}
          <span className="o-new-stats__item-total">13,938</span>
        </div>
        <StatisticsButton
          status={"approved"}
          qty={count["approved"]}
          onClick={() => alert("Approved")}
        />
        <StatisticsButton
          status={"toReview"}
          qty={count["toReview"]}
          onClick={() => alert("Review")}
        />
        <StatisticsButton
          status={"rejected"}
          qty={count["rejected"]}
          onClick={() => alert("Reject")}
        />
        <div style={{ height: 100, width: 100 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie datakey="value" data={data} fill="#8884d8">
                {data.map((value, index) => (
                  <Cell key={`cell-${index}`} fill={colors[index]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>
      </FlexComponent>
    </div>
  );
};

export default NewStats;
