import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const adaptationsPut = {
  [APP.PUT_ADAPTATIONS_STARTED]: (state) => ({
    ...state,
    status: LOADING
  }),

  [APP.PUT_ADAPTATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    adaptations: {
      ...state.adaptations,
      data: payload?.data?.[0] ?? null, 
      status: SUCCESS
    }
  }),
  [APP.PUT_ADAPTATIONS_FAIL]: (state, { payload }) => ({
    ...state,
    error: payload?.error ?? null,
    status: FAILURE
  })
};

export default adaptationsPut;
