import PropTypes from "prop-types";
import classNames from "classnames";
import { NubeskIcons } from "components/atoms";

const prefix = "m-sorter";

const Sorter = ({ disabledDown, disabledUp, onClickDown, onClickUp }) => {

  const classForDown = classNames(`${prefix}__down`, {
    ['--disabled']: disabledDown
  });

  const classForUp = classNames(`${prefix}__down`, {
    ['--disabled']: disabledUp
  });

  const clickDown = () => {
    if (!disabledDown) onClickDown();
  };
  const clickUp = () => {
    if (!disabledUp) onClickUp();
  };

  return (
    <div className={prefix}>
      <div
        className={classForUp}
        onClick={clickUp}
      >
        <NubeskIcons
          icon="up"
        />
      </div>
      <div 
        className={classForDown}
        onClick={clickDown}
      >
        <NubeskIcons
          icon="down"
        />
      </div>
    </div>
  );
};

Sorter.propTypes = {
  disabledDown: PropTypes.bool,
  disabledUp: PropTypes.bool,
  onClickDown: PropTypes.func,
  onClickUp: PropTypes.func
};

Sorter.defaultProps = {
  disabledDown: false,
  disabledUp: false,
  onClickUp: () => {},
  onClickUp: () => {},
};

export default Sorter;
