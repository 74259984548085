import React, { useRef, useEffect } from "react";
const prefix = "a-magnifier";

const Magnifier = ({ magnifierProps, setMagnifierProps }) => {
  const containerRef = useRef(null);
  
  useEffect(() => {
    let {
      current: { clientHeight, clientWidth },
    } = containerRef;
    setMagnifierProps({
      ...magnifierProps,
      magnifierHeight: clientHeight,
      magnifierWidth: clientWidth,
    });
  }, []);

  return (
    <div className={prefix} ref={containerRef}>
      <div
        style={{
          height: magnifierProps.sample
            ? magnifierProps.magnifierHeight
            : "100%",
          width: magnifierProps.sample ? magnifierProps.magnifierWidth : "100%",
          borderRadius: magnifierProps.sample ? "50%" : undefined,
          position: "relative",
          border: magnifierProps.sample ? "5px solid silver" : undefined,
          zIndex: "9999",
          //Lo que necesitaria pasar por props
          backgroundColor: "transparent",
          backgroundImage: `url("${magnifierProps.url}")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: `${magnifierProps.imgWidth * magnifierProps.zoom}px ${
            magnifierProps.imgHeight * magnifierProps.zoom
          }px`,
          backgroundPositionX: `${
            -magnifierProps.x * magnifierProps.zoom +
            magnifierProps.magnifierWidth / 2
          }px`,
          backgroundPositionY: `${
            -magnifierProps.y * magnifierProps.zoom +
            magnifierProps.magnifierHeight / 2
          }px`,
        }}
      ></div>
    </div>
  );
};

export default Magnifier;
