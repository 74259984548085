import { IDLE } from "_constants/redux.constants";

const initialAttributeState = {
  all: {
    data: null,
    error: null,
    status: IDLE
  },
  list: {
    data: null,
    error: null,
    status: IDLE
  },
  one: {
    data: null,
    error: null,
    status: IDLE
  },
  post: {
    data: null,
    error: null,
    status: IDLE
  },
  put: {
    data: null,
    error: null,
    status: IDLE
  },
  toLayer: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialAttributeState;
