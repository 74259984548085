import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { Avatar, Button, NubeskIcons, Paragraph, Title } from "components/atoms";
import { AvatarName, TaskProgress } from "components/molecules";
import { FlexComponent } from "components/templates";
import { openModal } from "_redux/ui/ui.actions";
import { selectTaskOne } from "_redux/task/task.selector";
import { useTask } from "_hooks";

const TaskItemsToolbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const { shareTask } = useTask();
  const { data: task } = useSelector(selectTaskOne);

  return (
    <>
      <FlexComponent>
        <Button
          content={<NubeskIcons icon="arrowLeft" />}
          type="color"
          onClick={() => history.push("/tasks")}
        />
        <Title content={task?.name} fontSize={22} className="u-purple" />

        <div className="time-section">
          <NubeskIcons icon="clock" className="clock-icon u-purple"/>
          <Paragraph 
            className="u-purple"
            content={task ?
              task?.process?.deliveryDate ? 
                `${t("tasks:table.daysLeft", {
                    n: moment(task?.process.deliveryDate).diff(moment().startOf("day"), "days")
                  })}`
                : 
                  t("tasks:items.etaUndefined")
              : null
            }
          />
          <TaskProgress
            completed={task?.process?.percentProgress ? Math.round(task?.process?.percentProgress * 100) : 0}
            height="25px"
            labelAlignment="outside"
            width="17rem"
          />
        </div>
      </FlexComponent>
      <FlexComponent>
        <div className="avatar-group">
          {task?.share?.length ? 
            <>
              {task.share?.filter((r, i) => i < 5)?.map((s) => <Avatar
                firstName={s.account.firstName}
                lastName={s.account.lastName}
                colorRange={[107, 112, 213]}
              />)}
              {task.share?.length > 5 && <Avatar
                firstName="."
                lastName="."
                colorRange={[107, 112, 213]}
              />}
            </>
            :
            <AvatarName
              colorRange={[107, 112, 213]}
              letters={" "}
              emptyLabel={t("tasks:table.nobody")}
            />
          }
        </div>
        
        <Button
          className="btn-share u-purple"
          content={<NubeskIcons icon="share"  />}
          onClick={() => dispatch(openModal({
            modalProps: {
              buttons: {
                share: t("tasks:actions.share")
              },
              share: task?.share?.map((s) => s._id) ?? [],
              onShare: (usersSelected) => 
                shareTask(task._id, usersSelected?.map((r) => r._id), "taskOne")
            },
            typeModal: "share",
            header: {
              text: (<>
                <NubeskIcons icon="share"  /> {t("tasks:actions.share")}
              </>),
              rightItem: task?.key,
            }
          }))}
        />

        <Button
          className="btn-message u-purple"
          content={<NubeskIcons icon="message" />}
          disabled
          onClick={() => {}}
        />
      </FlexComponent>
    </>
  )
};

export default TaskItemsToolbar;
