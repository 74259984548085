import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { FlexComponent } from "components/templates";
import { Toast } from "components/molecules";
import { Button, NubeskIcons } from "components/atoms";
import { remove as removeFiles } from "_redux/file/file.actions";
import { deleteManageImages } from "_redux/ui/actions/ui_manageImages.actions";
import {
  selectIdsSorted,
  selectInitialSort,
  selectManageChannel,
  selectImagesSelected,
  selectManageImagesImage,
} from "_redux/ui/ui.selector";
import {
  setInitialSort,
  manageImagesReset,
  closeManageImages,
} from "_redux/ui/ui.actions";
import { putSort } from "_redux/file/file.actions";

const prefix = "m-toolbar";

const ToolbarImages = React.memo(() => {
  const { t } = useTranslation();
  const ids = useSelector(selectIdsSorted);
  const _initialSort = useSelector(selectInitialSort);
  const _imagesSelected = useSelector(selectImagesSelected);
  const channelName = useSelector(selectManageChannel);
  const _image = useSelector(selectManageImagesImage);
  const dispatch = useDispatch();

  const handleSubmit = async (noToast = false, array = []) => {
    let idsFiltered = ids.filter((id) => !array.includes(id));

    const sort = idsFiltered.map((item, i) => ({ index: i }));
    await dispatch(putSort({ ids: idsFiltered, sort }));
    dispatch(setInitialSort(idsFiltered));

    if (!noToast) {
      toast.success(
        <Toast
          info={t("manage:images.ordered")}
          area={t("manage:images.area")}
        />
      );
    }
  };

  return (
    <div className={`${prefix}`}>
      <div className={`${prefix}-left`}>
        <span>{channelName}</span>
        <span>{_image?.naming}</span>
      </div>
      <div
        className={`${prefix}-right ${
          _imagesSelected.length ? `--delete` : ""
        }`}
      >
        {_imagesSelected.length ? (
          <span>{_imagesSelected.length} selected</span>
        ) : null}
        <FlexComponent>
          {_imagesSelected.length ? (
            <>
              <Button
                content={t("common:words.delete")}
                type={"delete"}
                onClick={async () => {
                  await dispatch(removeFiles(_imagesSelected));
                  toast.success(
                    <Toast
                      info={`${
                        _imagesSelected.length > 1
                          ? `${_imagesSelected.length} images`
                          : "Image"
                      } deleted`}
                      area={t("manage:images.area")}
                    />
                  );
                  handleSubmit(true, _imagesSelected);
                  dispatch(deleteManageImages(_imagesSelected));
                  if (ids.length === _imagesSelected.length)
                    dispatch(closeManageImages());
                }}
                sm
              />
              <Button
                content={"Delete for all channels"}
                type={"delete"}
                onClick={() => {}}
                disabled
                sm
              />
            </>
          ) : (
            <>
              <Button content={"Select"} type={"primary"} disabled sm />
              <Button
                content={t("manage:images.order")}
                type={"primary"}
                disabled
                sm
              />

              {JSON.stringify(ids) !== JSON.stringify(_initialSort) ? (
                <>
                  <Button
                    content={t("manage:images.save_order")}
                    type={"success"}
                    onClick={handleSubmit}
                    sm
                  />
                  <Button
                    content={"Save for all channels"}
                    disabled
                    type={"success"}
                    onClick={handleSubmit}
                    sm
                  />
                </>
              ) : null}
            </>
          )}
          <Button
            content={<NubeskIcons icon="times" size={40} />}
            type={"color"}
            onClick={() => {
              if (JSON.stringify(ids) !== JSON.stringify(_initialSort))
                console.log("Cerrando sin guardar");
              dispatch(manageImagesReset());
              dispatch(closeManageImages());
            }}
          />
        </FlexComponent>
      </div>
    </div>
  );
});

export default ToolbarImages;
