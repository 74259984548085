import { createSelector } from "reselect";

const selectResourceStore = (state) => state.resource;

export const selectResourceAllStore = createSelector(
  selectResourceStore,
  (resource) => resource.all
);

export const selectResourcePostStore = createSelector(
  selectResourceStore,
  (resource) => resource.post
);

export const selectResourcePutStore = createSelector(
  selectResourceStore,
  (resource) => resource.put
);

export const selectResourceOne = createSelector(
  selectResourceStore,
  (resource) => resource.one
);

export const selectResourceStatus = createSelector(
  selectResourcePostStore,
  (resource) => resource.status
);

export default selectResourceStore;
