const forceDownload = (fileUrl, filename, mimeType) => fetch(fileUrl, {
    cache: 'no-cache'
  })
  .then((response) => response.blob().then((blob) => {
    let element = document.createElement("a");
    let file = new Blob([blob], {
      type: mimeType || "image/*",
    });
    element.href = URL.createObjectURL(file);
    element.download = filename;
    element.click();
  }))
  .catch((e) => e);

export default forceDownload;
