import initialBillingState from "../billing.initialState";
import { reduxActions as BILLING } from "_constants/billing.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const adaptations = {
  [BILLING.ADAPTATIONS_STARTED]: (state) => ({
    ...state,
    adaptations: {
      ...state.adaptations,
      status: LOADING
    }
  }),

  [BILLING.ADAPTATIONS_FAIL]: (state, { payload }) => ({
    ...state,
    adaptations: {
      ...state.adaptations,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [BILLING.ADAPTATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    adaptations: {
      ...state.adaptations,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),

  [BILLING.ADAPTATIONS_RESET]: (state, { payload }) => ({
    ...state,
    adaptations: initialBillingState.adaptations
  })
};

export default adaptations;
