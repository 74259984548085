import { ACTIVE, reduxActions as APP } from "_constants/app.constants";
import { API } from "_constants/request.constants";


export const getGeneralJson = (isAdaptation = 0, isKey = 0) => ({
  f: ["data"],
  a: isAdaptation
    ? {
        type: "extended",
        f: ["name"],
      }
    : null,
  _opt: {
    isAdaptation: isAdaptation,
    key: isKey,
  },
});

export const getLookAndFeel = () => (dispatch) => dispatch({
  [API]: {
    types: [
      APP.LOOK_AND_FEEL_STARTED,
      APP.LOOK_AND_FEEL_SUCCESS,
      APP.LOOK_AND_FEEL_FAIL
    ],
    params: { w: {status: ACTIVE }},
    endpoint: "/setting/lookAndFeel",
    reqType: "GET"
  }
});

export const getImageryWorkflow = () => (dispatch) => dispatch({
  [API]: {
    types: [
      APP.GET_IMAGERY_WORKFLOW_STARTED,
      APP.GET_IMAGERY_WORKFLOW_SUCCESS,
      APP.GET_IMAGERY_WORKFLOW_FAIL
    ],
    endpoint: "/setting/imageryWorkflow",
    reqType: "GET"
  }
});

export const getColumnKey = () => (dispatch) =>
  dispatch({
    [API]: {
      types: [
        APP.COLUMN_KEY_STARTED,
        APP.COLUMN_KEY_SUCCESS,
        APP.COLUMN_KEY_FAIL,
      ],
      endpoint: "/setting/propertyColumns",
      reqType: "GET",
      params: getGeneralJson(0, 1),
    },
  });

export const getPropertyColumns = () => (dispatch) =>
  dispatch({
    [API]: {
      types: [
        APP.PROP_COLUMNS_STARTED,
        APP.PROP_COLUMNS_SUCCESS,
        APP.PROP_COLUMNS_FAIL,
      ],
      endpoint: "/setting/propertyColumns",
      reqType: "GET",
      params: getGeneralJson(0),
    },
  });

export const getAdaptations = () => (dispatch) =>
  dispatch({
    [API]: {
      types: [
        APP.ADAPTATIONS_STARTED,
        APP.ADAPTATIONS_SUCCESS,
        APP.ADAPTATIONS_FAIL,
      ],
      endpoint: "/setting/propertyColumns",
      reqType: "GET",
      params: getGeneralJson(1, 0),
    },
  });

export const getComponentDraw = () => (dispatch) =>
  dispatch({
    [API]: {
      types: [
        APP.COMPONENTDRAWING_STARTED,
        APP.COMPONENTDRAWING_SUCCESS,
        APP.COMPONENTDRAWING_FAIL,
      ],
      endpoint: "/setting/componentsDrawing",
      reqType: "GET",
      params: {
        f: ["data.nbsk"],
      },
    },
  });

export const setConfigurated =
  (configured = true) =>
  (dispatch) =>
    dispatch({
      type: APP.CONFIGURED,
      payload: configured,
    });

export const setConfigGot =
  (got = true) =>
  (dispatch) =>
    dispatch({
      type: APP.CONFIG_GOT,
      payload: got,
    });

export const postColumnKey = (value) => (dispatch) =>
  dispatch({
    API: {
      types: [
        APP.POST_COLUMN_KEY_STARTED,
        APP.POST_COLUMN_KEY_SUCCESS,
        APP.POST_COLUMN_KEY_FAIL,
      ],
      reqType: "PUT",
      endpoint: "/setting/propertyColumns",
      data: {
        c: [
          {
            data: [
              {
                original: value,
                key: true,
                require: true,
                enum: null,
                toExport: true,
                toFilter: false,
                overwritable: false,
                isAdaptation: false,
                refAdaptation: null,
              },
            ],
          },
        ],
      },
    },
  });

export const postPropertyColumns = (data) => (dispatch) =>
  dispatch({
    API: {
      types: [
        APP.POST_PROPERTY_COLUMNS_STARTED,
        APP.POST_PROPERTY_COLUMNS_SUCCESS,
        APP.POST_PROPERTY_COLUMNS_FAIL,
      ],
      endpoint: "/setting/propertyColumns",
      reqType: "PUT",
      data: {
        c: [
          {
            data: [
              {
                original: data?.original ?? "",
                desc: data?.desc ?? "",
                key: false,
                require: data?.require ?? false,
                enum: data.enum && data.enum.length ? data.enum : null,
                toExport: data?.toExport ?? false,
                toFilter: data?.toFilter ?? false,
                overwritable: data?.overwritable ?? false,
                isAdaptation: false,
                refAdaptation: null,
              },
            ],
          },
        ],
      },
      feedback: {
        success: {
          area: "settings:dataFields.area",
          info: "settings:dataFields.add.feedback.success",
        },
        error: "settings:dataFields.add.feedback.error",
      },
    },
  });

export const postAdaptations =
  (data, extras = {}) =>
  (dispatch) =>
    dispatch({
      API: {
        types: [
          APP.POST_ADAPTATIONS_STARTED,
          APP.POST_ADAPTATIONS_SUCCESS,
          APP.POST_ADAPTATIONS_FAIL,
        ],
        endpoint: "/setting/propertyColumns",
        reqType: "PUT",
        data: {
          c: [
            {
              data: [
                {
                  original: data.name,
                  isAdaptation: true,
                  refAdaptation: data.select,
                },
              ],
            },
          ],
        },
        ...extras,
      },
    });

export const putAdaptations = (data) => (dispatch) =>
  dispatch({
    API: {
      types: [
        APP.PUT_ADAPTATIONS_STARTED,
        APP.PUT_ADAPTATIONS_SUCCESS,
        APP.PUT_ADAPTATIONS_FAIL,
      ],
      endpoint: "/setting/propertyColumns",
      reqType: "PUT",
      data: {
        c: [
          {
            data: {
              [data.dbKey]: {
                original: data.name,
                key: false,
                require: false,
                enum: [],
                toExport: true,
                toFilter: false,
                overwritable: true,
                isAdaptation: true,
                refAdaptation: data.select,
              },
            },
          },
        ],
      },
    },
  });

export const putPropertyColumns = (property, config) => (dispatch) =>
  dispatch({
    API: {
      types: [
        APP.PUT_PROPERTY_COLUMNS_STARTED,
        APP.PUT_PROPERTY_COLUMNS_SUCCESS,
        APP.PUT_PROPERTY_COLUMNS_FAIL,
      ],
      endpoint: "/setting/propertyColumns",
      reqType: "PUT",
      data: {
        c: [
          {
            data: {
              [property]: {
                original: config.original,
                desc: config?.desc ?? "",
                key: false,
                require: config?.require ?? false,
                editable: config?.editable ?? false,
                enum: config?.enum && config.enum.length ? config.enum : null,
                toExport: config.toExport,
                toFilter: config.toFilter,
                overwritable: config.overwritable,
                isAdaptation: false,
                refAdaptation: null,
              },
            },
          },
        ],
      },
      feedback: {
        success: {
          area: "settings:dataFields.area",
          info: "settings:dataFields.update.feedback.success",
        },
        error: "settings:dataFields.update.feedback.error",
      },
    },
  });

export const addAdaptation = (data) => (dispatch) =>
  dispatch(
    postAdaptations(data, {
      feedback: {
        area: "settings:salesChannels.newModal.feedback.area",
        success: "settings:salesChannels.newModal.feedback.info",
        error: "settings:salesChannels.newModal.feedback.error",
      },
    })
  );

export const getTemplate = () => (dispatch) =>
  dispatch({
    API: {
      types: [
        APP.GET_TEMPLATE_STARTED,
        APP.GET_TEMPLATE_SUCCESS,
        APP.GET_TEMPLATE_FAIL,
      ],
      endpoint: "/setting/propertyColumns/template",
      params: {
        w: {
          status: "active",
        },
      },
    },
  });
