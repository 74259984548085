import { reduxActions as ADAPTATION } from "_constants/adaptation.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const put = {
  [ADAPTATION.PUT_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING,
    },
  }),

  [ADAPTATION.PUT_SUCCESS]: (state, { payload }) => {
    
    let data = state.onList.data;
    for (let a of payload?.data) {
      for (let i in data) {
        if (data[i]._id === a._id) {
          data[i] = a;
        }
      }
    }
    
    return {
      ...state,
      put: {
        ...state.put,
        data: payload.data,
        status: SUCCESS,
      },
      onList: {
        ...state.onList,
        data
      }
    };
  },

  [ADAPTATION.PUT_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null,
      status: FAILURE,
    },
  }),
};

export default put;
