import { useRef, useLayoutEffect } from 'react';

const useFocus = () =>{
  const inputRef = useRef();
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef]);
  return [inputRef];
};

export default useFocus;
