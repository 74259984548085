import { reduxActions as LAYOUT} from "_constants/layout.constants";
import { LOADING, SUCCESS } from "_constants/redux.constants";
import initialLayoutState from "../layout.initialState";

const one = {
  [LAYOUT.ONE_STARTED]: (state) => ({
    ...state,
    one: {
      ...state.one,
      status: LOADING
    }
  }),
  
  [LAYOUT.ONE_SUCCESS]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: payload?.data?.[0] ?? null,
      error: null,
      status: SUCCESS
    }
  }),
  
  [LAYOUT.ONE_FAIL]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      error: payload?.error ?? null,
      status: SUCCESS
    }
  }),

  [LAYOUT.ONE_RESET]: (state) => ({
    ...state,
    one: initialLayoutState.one
  })
};

export default one;
