import initialUserState from '../user.initialState';
import { reduxActions as USER } from '_constants/user.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const forgot = {
  [USER.FORGOT_STARTED]: (state) => ({
    ...state,
    forgot: {
      ...state.forgot,
      status: LOADING
    }
  }),
    
  [USER.FORGOT_FAIL]: (state, { payload }) => ({
    ...state,
    forgot: {
      ...state.forgot,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [USER.FORGOT_SUCCESS]: (state, { payload }) => ({
    ...state,
    forgot: {
      ...state.forgot,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [USER.FORGOT_RESET]: (state) => ({
    ...state,
    forgot: initialUserState.forgot
  })
};

export default forgot;
