import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePageDetails, keyboardShortcut } from "_redux/ui/ui.actions";
import { selectManageImagesisOpen, selectManageImagesStore } from "_redux/ui/ui.selector";

const usePlayGround = () => {
  /* Shift+o  trae al frente un area donde pueda probar los componentes sin tener que estar moviendo
  nadamas, se puede eliminardespues, Globalhotkeys nos sirve tambien para keyboard shortcuts para acciones en la
  app */
  const dispatch = useDispatch();
  const [activePlayGround, setActivePlayGround] = useState(false);

  const isOpenManageImages = useSelector(selectManageImagesisOpen);

  const validate = () => {
    if (isOpenManageImages) return false;
    else return true;
  };

  const deletePlayGround = useCallback(() => {
    if (!validate()) return;
    setActivePlayGround(false);
  }, [isOpenManageImages]);
  
  const handlePlayGround = useCallback(() => {
    if (!validate()) return;
    setActivePlayGround((previewsState) => !previewsState);
  }, [isOpenManageImages, setActivePlayGround]);
  
  const handleNextPage = useCallback(() => {
    if (!validate()) return;
    return dispatch(changePageDetails(1));
  }, [isOpenManageImages]);
  
  const handlePreviousPage = useCallback(() => { 
    if (!validate()) return;
    dispatch(changePageDetails(-1));
  }, [isOpenManageImages]);

  const handleNextPhoto = useCallback(() => {
    if (!validate()) return;
    dispatch(keyboardShortcut({ type: "photo", action: 1 }))
  }, [isOpenManageImages]);

  const handlePreviousPhoto = useCallback(() => {
    if (!validate()) return;
    dispatch(keyboardShortcut({ type: "photo", action: 0 }));
  }, [isOpenManageImages]);

  const handlers = {
    ACTIVE_PLAYGROUND: handlePlayGround,
    DELETE_PLAYGROUND: deletePlayGround,
    NEXT: handleNextPage,
    PREVIOUS: handlePreviousPage,
    NEXT_PHOTO: handleNextPhoto,
    PREVIOUS_PHOTO: handlePreviousPhoto,
  };

  const keyMap = {
    ACTIVE_PLAYGROUND: "shift+o",
    DELETE_PLAYGROUND: ["esc", "del"],
    NEXT: "ArrowRight",
    PREVIOUS: "ArrowLeft",
    NEXT_PHOTO: "ArrowDown",
    PREVIOUS_PHOTO: "ArrowUp",
  };

  return {
    handlers,
    keyMap,
    activePlayGround,
  };
};

export default usePlayGround;
