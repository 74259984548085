import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import {
  find as getProducts,
  count as countProducts,
  setCounters,
} from "_redux/product/product.actions";

import {
  selectProductAll,
  selectProductAllStatus,
  selectProductCountPiece,
} from "_redux/product/product.selector";

import {
  selectAppAdaptationsStatus,
  selectAppPropertyColumns,
  selectAppPropertyColumnsStatus,
} from "_redux/app/app.selector";
import urlToJson from "_utils/url/urlToJson";
import { usePagination, usePrevious, useProductFilter, useSort } from "./";
import { IDLE, LOADING, SUCCESS } from "_constants/redux.constants";
import selectUiStore, { selectRefresh } from "_redux/ui/ui.selector";
import { selectCatalogOneStore } from "_redux/catalog/catalog.selector";
import { toggleRefresh } from "_redux/ui/ui.actions";

const useCatalogQuery = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { catalogId } = useParams();

  const { filters, filterToW, setInit } = useProductFilter(true);
  const { page, setPage, pageToA, stackSize } = usePagination();
  const { sortToA, querySort } = useSort();

  const appPropertyColumns = useSelector(selectAppPropertyColumns);
  const appPropertyColumnsStatus = useSelector(selectAppPropertyColumnsStatus);

  const appAdaptationsStatus = useSelector(selectAppAdaptationsStatus);
  const { status: catalogStatus } = useSelector(selectCatalogOneStore);
  const productPageStatus = useSelector(selectProductAllStatus);
  const productsCount = useSelector(selectProductCountPiece);
  const { products: refreshProducts } = useSelector(selectRefresh);
  const [inited, setInited] = useState(false);
  const [loading, setLoading] = useState(catalogId ? true : false);
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);
  const [params, setParams] = useState();

  const productPage = useSelector(selectProductAll);
  const filtersPrev = usePrevious(filters);

  const getFromQuery = async () => {
    /** q = conditions, p = pagination, s = sorting */
    let { q, p, s } = await urlToJson(location.search);

    let w = await filterToW(q);
    let a = pageToA(p);
    let srt = sortToA(s);

    let query = {};
    if (w !== undefined) query.w = w;
    if (a !== undefined) query.a = a;
    if (srt !== undefined) {
      query.a = {
        ...(query?.a ?? {}),
        ...srt,
      };
    }

    return query;
  };

  const fetch = async () => {
    if (params === undefined) return;
    
    if (productPageStatus === LOADING) return;

    let queryString = await getFromQuery();
    let conditions = {
      ...queryString,
      w: {
        ...(queryString?.w ?? {}),
        ...(params?.w ?? {}),
      },
    };
    
    if (catalogId && !params?.w?._id?.length) {
      setProducts([]);
      setTotal(0);
      return dispatch(
        setCounters({
          count: 0,
          countPiece: 0,
        })
      );
    }

    await dispatch(getProducts(conditions, {}, true));

    if (page === 1) {
      let countTotal;
      let counters = {
        count: params?.w?._id?.length ?? 0,
      };
      if (!catalogId) {
        countTotal = await dispatch(countProducts({query: {...params?.w ?? {}}}, false, "_id"));
        counters.count = countTotal?.data?.[0]?.count ?? 0;
      }

      if (Object.keys(queryString?.w ?? {}).length > 0) {
        let piece = await dispatch(
          countProducts({ query: { ...(conditions?.w ?? {}) }, partial: true })
        );
        counters.countPiece = piece?.data?.[0]?.count ?? 0;
      } else if (countTotal?.data?.[0]?.count) {
        counters.count = countTotal?.data?.[0]?.count ?? 0;
      }

      setTotal(counters.count);
      dispatch(setCounters(counters));
    }
  };

  const nextPage = () => {
    if (productPageStatus === LOADING) return;

    if (productsCount > stackSize * page) {
      setPage(page + 1);
    } else {
      setLoading(false);
    }
  };

  const reset = () => {
    setPage(1);
  };

  useEffect(() => {
    if (
      [IDLE, LOADING].indexOf(appAdaptationsStatus) === -1 &&
      [IDLE, LOADING].indexOf(appPropertyColumnsStatus) === -1
    ) {
      setInit({ data: appPropertyColumns });
    }
  }, [appAdaptationsStatus, appPropertyColumnsStatus]);

  /** When filters is changed  */
  useEffect(() => {
    if (
      !filtersPrev ||
      !filters ||
      filtersPrev !== filters ||
      JSON.stringify(filtersPrev) !== JSON.stringify(filters)
    ) {
      if (page === 1) {
        setProducts([]);
      } else {
        setPage(1);
      }
    }
  }, [filters, querySort, querySort]);

  useEffect(() => {
    if (page === 1) setProducts([]);
  }, [page]);

  useEffect(() => reset(), [params]);

  /** When queryPage is changed */
  useEffect(() => {
    setLoading(true);
  }, [location.search, params]);

  /** When refresh is needed */
  useEffect(() => {
    if (refreshProducts) {
      setLoading(true);
      dispatch(toggleRefresh("products"));
    }
  }, [refreshProducts]);

  /** Generate Skeleton */
  useEffect(() => {
    if (productPage === null) return;

    setLoading(false);
  }, [productPage]);

  useEffect(() => {
    if (catalogStatus === LOADING) return;
    if (loading) fetch();
  }, [loading, catalogStatus, params]);
  

  /** Assing new values to products */
  useEffect(() => {
    if (productPage === null || loading === true) return;
    if (page === 1) return setProducts([...productPage]);
    
    setProducts([...products, ...productPage ?? []]);
    
    if (inited === false && loading === false) {
      setInited(true);
    }    
  }, [loading]);

  return {
    loading,
    nextPage,
    products,
    total,
    reset,
    setParams,
    stackSize
  };
};

export default useCatalogQuery;
