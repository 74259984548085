import callRequest from '_utils/axios/callRequest';
import { ACTIVE, APPROVED, REJECTED, REQUIRED, TOREVIEW } from '_constants/product.constants';
import { reduxActions as STATISTICS } from '_constants/statistics.constants';

export const productStats = () => (dispatch) => {
  let requests = [];
  let payload;

  dispatch({
    type: STATISTICS.PRODUCTS_STARTED
  });
  
  requests.push(new Promise((resolve, reject) => callRequest('/nbsk/folder', {
      type: 'GET',
      params: {
        a: { count: {'_': '@' }},
        w: {
          status: ACTIVE,
          'process.reviewMaster': APPROVED,
          'process.reviewInfo': APPROVED,
          'process.reviewRight': APPROVED
        }
      }
    })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.response.data))
  ));

  requests.push(new Promise((resolve, reject) => callRequest('/nbsk/folder',{
      type: 'GET',
      params: {
        a: { count: {'_': '@' }},
        w: {
          status: ACTIVE,
          'process.reviewMaster': { '$in': [REQUIRED, TOREVIEW] },
          'process.reviewInfo': { '$in': [REQUIRED, TOREVIEW] },
          'process.reviewRight': { '$in': [REQUIRED, TOREVIEW] } 
        }
      }
    })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.response.data))
  ));
      

  requests.push(new Promise((resolve, reject) => callRequest('/nbsk/folder', {
      type: 'GET',
      params: {
        a: { count: {'_': '@' }},
        w: {
          status: ACTIVE,
          'process.reviewMaster': REJECTED,
          'process.reviewInfo': REJECTED,
          'process.reviewRight': REJECTED
        }
      }
    })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.response.data))
  ));
    
  
  Promise.all(requests).then((responses) => {
      payload = {
        data: {
          complete: responses?.[0]?.data?.[0]?.count ?? 0,
          incomplete: responses?.[1]?.data?.[0]?.count ?? 0,
          broken: responses?.[2]?.data?.[0]?.count ?? 0
        }
      };

      return dispatch({ type: STATISTICS.PRODUCTS_SUCCESS, payload })
    })
    .catch((err) => dispatch({ 
      type: STATISTICS.PRODUCTS_FAIL, 
      payload: { error:  err?.status?.[0]?.code ?? 101} 
    }));
};


export const taskStats = () => (dispatch) => {
  let requests = [];
  let payload;

  dispatch({
    type: STATISTICS.TASKS_STARTED
  });
  
  requests.push(new Promise((resolve, reject) => callRequest('/nbsk/task', {
      type: 'GET',
      params: {

      }
    })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.response.data))
  ));

  requests.push(new Promise((resolve, reject) => callRequest('/nbsk/task',{
      type: 'GET',
      params: {

      }
    })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.response.data))
  ));
      

  requests.push(new Promise((resolve, reject) => callRequest('/nbsk/task', {
      type: 'GET',
      params: {

      }
    })
    .then((response) => resolve(response.data))
    .catch((error) => reject(error.response.data))
  ));
    
  
  Promise.all(requests).then((responses) => {
      payload = {
        data: {}
      };

      return dispatch({ type: STATISTICS.TASKS_SUCCESS, payload })
    })
    .catch((err) => dispatch({ 
      type: STATISTICS.TASKS_FAIL, 
      payload: { error:  err?.status?.[0]?.code ?? 101} 
    }));
};

const actions = {
  productStats,
  taskStats
};

export default actions;
