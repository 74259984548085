import { useTranslation } from "react-i18next";

const Done = ({
  data: { error = 0, success = 0 },
  prefix
 }) => {
  const { t } = useTranslation();

  return (
    <div className={`${prefix}__done`}>
      <div>
        <div className="--success">
          <div></div>
          <span>{t("module:syncfonia.updateGtin.modal.updated")}</span>
        </div>
        <div>{success}</div>
      </div>
      <div>
        <div className="--warning">
          <div></div>
          <span>{t("module:syncfonia.updateGtin.modal.withError")}</span>
        </div>
        <div>{error}</div>
      </div>
    </div>
  );
};

export default Done;
