import React from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import { FlexComponent } from "components/templates";
import { Button, NubeskIcons } from "components/atoms";
import { Toast } from "components/molecules";

import useProductFilter from "_hooks/useProductFilter";
import { openModal, toggleSelected } from "_redux/ui/ui.actions";
import { downloadXls } from "_redux/product/product.actions";
import selectUiStore, { selectSkuSelected } from "_redux/ui/ui.selector";
import { selectProductCountPiece } from "_redux/product/product.selector";
import { selectCatalogOne } from "_redux/catalog/catalog.selector";
import useTooltip from "_hooks/useTooltip";

const SelectionCatalogsToolbar = () => {
  const dispatch = useDispatch();
  const params = useParams();
  const { selected } = useSelector(selectSkuSelected);
  const { t } = useTranslation();
  const { selections } = useSelector(selectUiStore);
  const count = useSelector(selectProductCountPiece);
  const catalog = useSelector(selectCatalogOne);
  const { filterToW } = useProductFilter();
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150
  });

  const downloadSelected = async () => {
    let response;
    toast.info(
      <Toast
        area={t("products:newSku.feedback.download_template.area")}
        info={t("products:newSku.feedback.download_template.started")}
      />
    );

    if (selections?.all) {
      let filters = await filterToW();
      let foldersId = catalog?.foldersId?.map((r) => r._id) ?? null;
      response = await dispatch(downloadXls(foldersId, filters || {}));
    } else {
      response = await dispatch(downloadXls(Object.keys(selections.selected)));
    }

    if (response?.data?.[0]?.url) {
      window.open(response.data[0].url);
    }
  };

  return (
    <>
      <FlexComponent>
        <Button
          type={"primary"}
          content={`${
            selections.all
              ? t("common:form.deselect_all")
              : t("common:form.select_all")
          } (${
            selections.all
              ? count - Object.keys(selections.selected).length
              : Object.keys(selections.selected).length
          }/${count ?? ""})`}
          onClick={() => dispatch(toggleSelected())}
        />
        <Button
          leftIcon={<NubeskIcons icon="plus" />}
          content={t("products:actions.catalog")}
          type={"primary"}
          onClick={() => {
            dispatch(
              openModal({
                typeModal: "newCatalog",
                header: {
                  text: `New catalog ${
                    Object.keys(selected).length ? "with selection" : ""
                  }`,
                  icon: { type: "plus", size: 20 },
                },
              })
            );
          }}
        />
      </FlexComponent>

      <FlexComponent>
        <Button
          type="gray"
          content={t("products:actions.download_xls")}
          onClick={downloadSelected}
        />

        <div {...injectedProps}>
          <Button
            type="gray"
            content={"Set field"}
            onClick={() => {}}
            disabled
          />
        </div>
      </FlexComponent>
    </>
  );
};

export default SelectionCatalogsToolbar;
