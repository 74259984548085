import initialFiltersState from "./filters.initialState";
import { reduxActions as FILTERS } from "_constants/filters.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const reducer = (state = initialFiltersState,  { payload, type }) => {
  switch (type) {
    case FILTERS.PRODUCTS_STARTED:
      return {
        ...state,
        product: {
          ...state.product,
          status: LOADING
        }
      };
    
    case FILTERS.PRODUCTS_FAIL:
      return {
        ...state,
        product: {
          ...state.product,
          error: payload?.error ?? null,
          status: FAILURE
        }
      };

    case FILTERS.PRODUCTS_SUCCESS:
      return {
        ...state,
        product: {
          ...state.product,
          data: payload?.data ?? null,
          status: SUCCESS
        }
      };

    case FILTERS.PRODUCTS_RESET:
      return {
        ...state,
        product: initialFiltersState.product
      };

    case FILTERS.PRODUCT_FILTERS: 
      return {
        ...state,
        product: {
          ...state.product,
          filters: payload ?? []
        }
      };
    
    case FILTERS.PRODUCT_FILTER_LIST: 
      return {
        ...state,
        product: {
          ...state.product,
          filterList: payload ?? []
        }
      };

    case FILTERS.TOGGLE_PRODUCT_FILTERS:
      return {
        ...state,
        product: {
          ...state.product,
          visible: payload.visible
        }
      };

    default: return state;
  }
};

export default reducer;
