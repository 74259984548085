import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAdaptation, useChannelLayout, useDetailsPhoto, useModal, usePrevious } from "_hooks";
import classNames from "classnames";
import { toast } from "react-toastify";
import { FlexComponent } from "components/templates";
import { Accordion } from "components/organism";
import { PhotoSpecs } from "../";
import { SimpleScroller } from "../Scroller";
import { AdaptationRow, SwitchMasterRow, Toast } from "components/molecules";
import LayerRow from "components/molecules/Rows/LayerRow/";
import { Button, Paragraph, NubeskIcons } from "components/atoms";

import {
  toggleRefresh,
  updateCurrentAdaptation
} from "_redux/ui/ui.actions";
import { generate, removeBg } from "_redux/file/file.actions";
import {
  selectAppComponentDrawing,
  selectAppPropertyColumns
} from "_redux/app/app.selector";
import { setCurrentAdaptation } from "_redux/ui/ui.actions";
import {
  approvePhotos,
  disapprovePhotos,
  toReviewPhotos,
} from "_redux/product/product.actions";
import { generatePackage, resetOne } from "_redux/resource/resource.actions";
import { remove as removeFiles } from "_redux/file/file.actions";
import { setChannelName } from "_redux/ui/actions/ui_manageImages.actions";
import { selectDetails } from "_redux/ui/ui.selector";
import { selectProductDetailsStore } from "_redux/product/product.selector";
import { selectFilePost } from "_redux/file/file.selector";
import { capitalize } from "_utils/string";
import { nestedProps } from "_utils/drawing";
import { countFiles, sizeFormat } from "_utils/files";
import { dateFormat } from "_utils/format";
import { APPROVED } from "_constants/product.constants";
import { useAppSettings, useFile, useUser } from "_hooks";
import { LAYER_MODULE } from "_constants/app.constants";

const prefix = "o-details-photo";

const DetailsPhoto = () => {
  const dispatch = useDispatch();
  const { appLayersData } = useAppSettings();
  const { eliminate } = useFile();
  const { closeModal, openModal } = useModal();
  const { key } = useParams();
  const { t } = useTranslation();
  const { hasModule } = useUser();

  const { currentImage, currentAdaptation, images } =
    useSelector(selectDetails);

  const classForComponent = classNames(prefix, {
    "no-data-photo": !images.length,
  });

  const cDrawing = useSelector(selectAppComponentDrawing);
  const propertyColumns = useSelector(selectAppPropertyColumns);
  const { data: product } = useSelector(selectProductDetailsStore);
  const { adaptations, fetchAdaptations } = useAdaptation();
  const { fetch: fetchLayouts, listData: layoutList } = useChannelLayout();
  const { getAdaptationRow, getLayerImages }= useDetailsPhoto();
  const files = useSelector(selectFilePost);
  const prevProduct = usePrevious(product);

  const [adaptationsAvailable, setAdaptationsAvailable] = useState([]);
  const [allowLayerImages, setAllowLayerImages] = useState([]);
  const [skuPhotoDetails, setSkuPhotoDetails] = useState();
  const [resumeCopy, setResumeCopy] = useState([]);
  const [adaptationName, setAdaptationName] = useState("");
  const [removingBg, setRemovingBg] = useState(false);
  const [_images, setImages] = useState([]);

  const approveMaster = async () => {
    let response = await dispatch(approvePhotos(product._id));

    if (response?.data?.[0]._id) {
      dispatch(toggleRefresh("products", {
        _id: response?.data?.[0]._id,
        process: response?.data?.[0].process
      }));
    }
  };

  const toReviewMaster = async () => {
    let response = await dispatch(toReviewPhotos(product._id));
    for (let a of product?.adaptationMastersId) {
      if (!a?.attributes?.isMaster) {
        deleteMedia();
      }
    }

    if (response?.data?.[0]._id) {
      dispatch(toggleRefresh("products", {
        _id: response?.data?.[0]._id,
        process: response?.data?.[0].process
      }));
    }
  };

  const generateAdaptation = (adaptationId) =>
    dispatch(generate(product._id, adaptationId));

  const removeBackground = async (adaptationMastersId) => {
    setRemovingBg(true);
    
    openModal({
      typeModal: "backRemoval",
      header: { text: t("modals:backRemoval.title") }
    });
    await dispatch(
      removeBg(product._id, adaptationMastersId, {
        feedback: {
          error: false,
          success: {
            area: "products:dataPhotos.feedback.removeBg.area",
            info: "products:dataPhotos.feedback.removeBg.success"
          },
        }
      })
    );
    closeModal();
    setRemovingBg(false);
  };

  const deleteMedia = (adaptationId = null) => {
    let ids = [];
    let files = product?.filesId ?? [];

    if (adaptationId) {
      files = files?.filter((f) => f.adaptationId === adaptationId);
    }
    
    ids = files?.map((f) => f._id);

    ids = [...ids, ...product?.layersId?.map((l) => l._id) ?? []];

    if (ids?.length) dispatch(removeFiles(ids));

    if (adaptationId === null) {
      dispatch(disapprovePhotos(product?._id));
    }

    let clean = product?.filesId.filter((f) => ids.indexOf(f._id) === -1);
    dispatch(toggleRefresh("products", {_id: product._id, filesId: clean}));
  };

  const downloadChannel = async (id = null, filename = "") => {
    if (!id) id = currentAdaptation;

    toast.info(
      <Toast
        area={t("products:dataPhotos.feedback.downloadZip.area")}
        info={t("products:dataPhotos.feedback.downloadZip.started")}
      />
    );

    let response = await dispatch(
      generatePackage(product._id, id, `${product.key}_${filename}`)
    );
      
    if (response?.data?.[0]?.data?.url) {
      window.open(response?.data?.[0]?.data?.url);
    }
  };

  useEffect(() => {
    fetchLayouts();
    fetchAdaptations();

    return () => {
      dispatch(resetOne());
    }
  }, [])

  useEffect(() => {
    setImages(images.sort((a, b) => a.index - b.index));
  }, [images]);

  useEffect(() => {
    if (product === null) return;
    if (prevProduct !== null && prevProduct?._id === product._id) return;

    dispatch(setCurrentAdaptation(product?.adaptationMastersId?.[0]?._id));
  }, [key, product]);

  useEffect(() => {
    if (cDrawing?.skuPhotoDetails) {
      setSkuPhotoDetails(cDrawing?.skuPhotoDetails ?? undefined);
    }
  }, [cDrawing]);

  useEffect(() => {
    setAdaptationsAvailable([]);
    setAllowLayerImages([]);
    setRemovingBg(false);
  }, [key]);

  useEffect(() => {
    if (!adaptations || !layoutList|| !product) return;
    let asigment = async () => {
      let adaptationList = await getAdaptationRow({ layoutList, product });
      setAdaptationsAvailable(adaptationList);
      let layerImagesList = getLayerImages(product);
      setAllowLayerImages(layerImagesList);
    };

    asigment();
  }, [layoutList, product?.data]);

  useEffect(() => {
    if (product === undefined) return;
    if (skuPhotoDetails === undefined) return;
    if (propertyColumns === null) return;

    let newArray = (
      skuPhotoDetails?.resumeCopy.map((field) => {
        let cleanField = field.replace(/^(data.)/, "").trim();

        let prop = propertyColumns?.filter((p) => p.key === cleanField)?.[0];

        if (!prop) return null;

        return {
          content: nestedProps(product, field),
          title: prop.name,
        };
      }) ?? []
    ).filter((row) => row !== null);
    
    setResumeCopy(newArray);
  }, [skuPhotoDetails, propertyColumns, product]);

  useEffect(() => {
    if (files === null) return;

    dispatch(updateCurrentAdaptation());
  }, [files]);

  useEffect(() => {
    if (product === null && !adaptationsAvailable && !appLayersData) return;
    
    let adpt = adaptationsAvailable?.filter((a) => 
      a.adaptationId === currentAdaptation || a._id === currentAdaptation)
      .map((a) => ({
        ...a,
        name: a.title,
      }));

    if (adpt?.length === 0) {
      adpt = product?.adaptationMastersId?.filter(
        (a) => a._id === currentAdaptation
      );
    }

    if (adpt?.length === 0) {
      adpt = appLayersData?.filter(
        (a) => a._id === currentAdaptation
      );
    }

    dispatch(setChannelName(typeof adpt?.[0]?.name === "string" ? adpt?.[0]?.name : adpt?.[0]?.adaptationName));
    setAdaptationName(typeof adpt?.[0]?.name === "string" ? adpt?.[0]?.name : adpt?.[0]?.adaptationName);    
  }, [currentAdaptation, product?.adaptationMastersId, adaptationsAvailable, appLayersData]);

  return (
    <div className={classForComponent}>
      <PhotoSpecs
        product={product}
        status={product?.process?.reviewMaster}
        name={adaptationName}
        onApprove={approveMaster}
        onNotifyError={toReviewMaster}
        onDownload={downloadChannel}
        data={
          _images?.length
            ? [
              {
                title: t("products:details.imagery.uploadDate"),
                data: dateFormat(_images?.[currentImage]?.createdAt) ?? "-",
              },
              {
                title: "W x H",
                data: `
                    ${_images?.[currentImage]?.data?.width ?? "-"} x 
                    ${_images?.[currentImage]?.data?.height ?? "-"}
                  `,
              },
              {
                title: t("products:details.imagery.size"),
                data: sizeFormat(_images?.[currentImage]?.data?.size),
              },
              {
                title: "DPI",
                data: _images?.[currentImage]?.data?.dpi ?? "-",
              },
              {
                title: t("products:details.imagery.rename"),
                data: _images?.[currentImage]?.naming ?? "-",
              }
            ]
            : []
        }
      />
      <div className={`${prefix}__scrollable`}>
        <SimpleScroller disableTracksWidthCompensation>
          <div className={`${prefix}__scrollable-content`}>
            <Accordion
              title={t("products:details.imagery.resumeCopy")}
              headerLeft={<NubeskIcons icon={"catalogs"} fontSize={24} />}
              content={resumeCopy.map(({ content, title }, index) => (
                <div className="m-resume-copy" key={index}>
                  <span>{capitalize(title)}</span>
                  <Paragraph
                    content={content}
                    className="m-resume-copy__content"
                  />
                </div>
              ))}
            />
            <Accordion
              title={t("products:details.imagery.masterImages")}
              headerLeft={<NubeskIcons icon={"photos"} fontSize={25} />}
              headerRight={null}
              isOpen={true}
              content={
                <FlexComponent className="u-without-gap" isVertical>
                  {product?.adaptationMastersId?.map((row) => {
                    let hasCuts = 0;
                    if (row?.attributes?.withBackground === true) {
                      for (let aMasters of product.adaptationMastersId) {
                        if (aMasters.attributes?.withBackground === false) {
                          let cuts = countFiles(
                            product?.filesId ?? [],
                            aMasters._id
                            , true);

                          if (cuts) {
                            hasCuts = cuts;
                          }
                        }
                      }
                    }
                    let array = countFiles(
                      product?.filesId ?? [],
                      row._id,
                      false
                    );
                    if (row?.attributes?.withBackground === false && !array.length) return null;

                    return (
                      <SwitchMasterRow
                        key={row._id}
                        isMaster={row?.attributes?.isMaster}
                        withBackground={row?.attributes?.withBackground}
                        date={array[array.length - 1]?.updatedAt}
                        isActive={currentAdaptation === row._id}
                        hasCuts={hasCuts}
                        onClick={() => dispatch(setCurrentAdaptation(row._id))}
                        onDelete={deleteMedia}
                        onDownload={() => downloadChannel(row._id, row?.name)}
                        onRemoveBg={removeBackground}
                        onUpload={() => openModal({
                          header: { text: t("products:dataPhotos.upload_images") },
                          typeModal: "uploadImages",
                          modalProps: {
                            product,
                            adaptationId: row._id
                          },
                        })}
                        title={row?.name ?? "-"}
                        qty={array?.length}
                        productStatus={product?.process?.reviewMaster}
                        id={row._id}
                        bussy={removingBg}
                      />
                    );
                  })}
                </FlexComponent>
              }
            />

            {hasModule(LAYER_MODULE) &&
              <Accordion
                title={t("products:details.imagery.layerImages")}
                headerLeft={<NubeskIcons icon={"resize"} fontSize={24} />}
                isOpen={true}
                headerRight={
                  <Button
                    className="a-accordion-button"
                    content={<NubeskIcons icon={"plus"} />}
                    type={"color"}
                    onClick={(e) => {
                      e.stopPropagation();
                      let master = adaptations?.filter(
                        (f) => f.attributes.isMaster && f.attributes.withBackground
                      )?.[0];
                      
                      openModal({
                        closeBackdrop: false,
                        header: { text: "Generar imágenes con plantilla" },
                        modalProps: {
                          images: product?.filesId?.filter((f) => 
                            master?._id && f.adaptationId == master?._id
                          )?.sort((a, b) => {
                            if (a?.index > b?.index) return 1;
                            else if (a?.index < b?.index) return -1;
                            return 0;
                          }) ?? [],
                          productId: product?._id,
                          sku: product?.key
                        },
                        typeModal: "generateLayerImages",
                      })
                    }}
                  />
                }
                content={
                  <FlexComponent className="u-without-gap" isVertical>
                    {!!allowLayerImages?.length && allowLayerImages?.map((row) => (
                      <LayerRow
                        key={row._id}
                        isMaster={false}
                        withBackground={false}
                        date={dateFormat(row?.updatedAt)}
                        isActive={currentAdaptation === row._id}
                        hasCuts={false}
                        onClick={() => {
                          if (row?.images?.length) {
                            dispatch(setCurrentAdaptation(row._id));
                          }
                        }}
                        onDelete={() => { if (row?.images) eliminate(row?.images?.map((i) => i._id)) }}
                        title={row?.name ?? "-"}
                        qty={row?.images?.length}
                        productStatus={row?.process}
                        id={row._id}
                        bussy={removingBg}
                      />
                    ))}
                  </FlexComponent>
                }
              />
            }
            <Accordion
              title={t("products:details.imagery.adaptations")}
              headerLeft={<NubeskIcons icon={"resize"} fontSize={24} />}
              isOpen={true}
              headerRight={
                <Button
                  className="a-accordion-button"
                  content={<NubeskIcons icon={"plus"} />}
                  type={"color"}
                  onClick={(e) => {
                    e.stopPropagation();
                    
                    openModal({
                      header: { text: t("products:dataPhotos.add_adaptation") },
                      typeModal: "adaptations",
                      modalProps: {
                        fired: adaptationsAvailable?.map((a) => a.adaptationId),
                        productId: product?._id,
                        isApproved:
                          product?.process?.reviewMaster === APPROVED,
                        onAdd: generateAdaptation,
                      }
                    });
                  }}
                />
              }
              content={
                <FlexComponent className="u-without-gap" isVertical>
                  {!!adaptationsAvailable?.length && adaptationsAvailable?.map((row) => (
                    <AdaptationRow
                      date={dateFormat(row.date)}
                      key={row.adaptationId}
                      adaptationId={row.adaptationId}
                      attributeKey={row.attributeKey}
                      sku={row.sku}
                      folderId={product?._id}
                      status={product?.process?.reviewMaster}
                      isApproved={product?.process?.reviewMaster === APPROVED}
                      layoutKey={row.layoutKey}
                      name={row.name}
                      onGenerate={() => openModal({
                        header: { text: t("products:dataPhotos.generatingAdaptations") },
                        typeModal: "generateAdaptation",
                        modalProps: {
                          requestAction: () => generateAdaptation(row.adaptationId),
                          onFinish: () => dispatch(closeModal())
                        },
                      })}
                      onDelete={deleteMedia}
                      onDownload={(extraName) => downloadChannel(row.adaptationId, `${row.adaptationName}_${extraName}`)}
                      onClick={() => {
                        if (row.qty) {
                          dispatch(setCurrentAdaptation(row.adaptationId));
                        }
                      }}
                      title={row.title}
                      qty={row.qty}
                      isActive={currentAdaptation === row.adaptationId}
                    />
                  ))}
                </FlexComponent>
              }
            />
          </div>
        </SimpleScroller>
      </div>
    </div>
  );
};

DetailsPhoto.propTypes = {};

DetailsPhoto.defaultProps = {};

export default DetailsPhoto;
