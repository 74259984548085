import { reduxActions as CATALOG } from "_constants/catalog.constants";

const removeResource = {
  [CATALOG.REMOVE_RESOURCE]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: {
        ...state.one.data,
        resourcesId: [
          ...state.one?.data?.resourcesId?.filter(
            (r) => {
              if (Array.isArray(payload)) {
                return payload.indexOf(r._id) === -1;
              } else {
                return payload !== r._id;
              } 
            }
          )
          ?? []
        ]
      }
    }
  })
};

export default removeResource;
