import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import compileFiles from "./compileFiles";

const fallbackLng = ["es", "en"];
const fallbackNS = ["common"];

const strings = compileFiles(require.context("./locale", true, /\.json$/));

i18next.use(initReactI18next).init({
  interpolation: {
    format: function (value, format) {
      if (format === "uppercase") return value.toUpperCase();
    },
  },
  compatibilityJSON: "v2",
  lng: "es",
  fallbackLng: fallbackLng,
  resources: strings.resources,
  ns: strings.ns,
  defaultNS: "common",
  debug: process.env.NODE_ENV === "development",
  fallbackNS: fallbackNS,
  nsSeparator: ":",
  keySeparator: ".",
  whitelist: Object.keys(strings.resources),
  supportedLngs: Object.keys(strings.resources),
  initImmediate: false,
  react: {
    wait: true,
    useSuspense: false,
  },
});

export default i18next;
