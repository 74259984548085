import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, CircleStatus } from "components/atoms";
import { PhotoLabel } from "components/molecules";
import { FlexComponent } from "components/templates";
import { SimpleScroller } from "../Scroller/";
import { openModal } from "_redux/ui/ui.actions";
import { selectUser } from "_redux/user/user.selectors"
import { $$FOLDER, $LINK_DOWNLOAD } from "_constants/permission.constants";

const prefix = "o-photo-specs";

const PhotoSpecs = ({ 
  data, 
  status, 
  name, 
  product, 
  onApprove, 
  onNotifyError,
  onDownload 
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classForHeader = classNames(`${prefix}__header`, {
    "is-empty": status === "request",
    "no-data": !data.length,
  });
  const user = useSelector(selectUser);

  const openUpload = () => {
    let adaptation = product?.adaptationMastersId
      ?.filter((a) => a?.attributes?.isMaster === true)
      ?.shift();

    return dispatch(
      openModal({
        header: { text: t("products:dataPhotos.upload_images") },
        typeModal: "uploadImages",
        modalProps: {
          product: product,
          adaptationId: adaptation._id,
        },
      })
    )
  };

  const buttons = {
    toReview: (
      <>
        {user.canDoIt($$FOLDER.APPROVE_MASTER) &&
          <>
            <Button
              content={t("products:actions.notify_error")}
              type="primary"
              onClick={() => onNotifyError()}
            />
            <Button
              content={t("products:actions.approve")}
              sm
              type="success"
              onClick={() => onApprove()}
            />
          </>
        }
      </>
    ),
    approved: (
      <>
        {user.canDoIt($LINK_DOWNLOAD) && <Button
          content={t("products:actions.download")}
          type="underline"
          onClick={() => onDownload(null, name)}
        />}
        {user.canDoIt($$FOLDER.APPROVE_MASTER) && 
          <Button
            content={t("products:actions.notify_error")}
            sm
            type="primary"
            onClick={() => onNotifyError()}
          />
        }
      </>
    ),
    rejected: (
      <>
        {user.canDoIt($$FOLDER.APPROVE_MASTER) &&
          <>
            <Button
              content={t("products:actions.notify_error")}
              type="primary"
              onClick={() => onNotifyError()}
            />
            <Button
              content={t("products:actions.approve")}
              sm
              type="success"
              onClick={() => onApprove()}
            />
          </>
        }
      </>
    ),
    required: (
      <>
        {user.canDoIt($$FOLDER.UPLOAD) &&
          <Button
            content={t("products:actions.upload")}
            sm
            type="underline"
            onClick={openUpload}
          />
        }
        <Button
          content={t("products:actions.request_images")}
          type="primary"
          onClick={() => {}}
        />
      </>
    ),
  };

  return (
    <div className={prefix}>
      <div className={classForHeader}>
        <FlexComponent sm>
          <CircleStatus status={status} /> <span>{name}</span>
        </FlexComponent>

        <FlexComponent sm>{buttons[status]}</FlexComponent>
      </div>
      {data.length ? (
        <div className={`${prefix}__content`}>
          <SimpleScroller isHorizontal>
            <FlexComponent>
              {data?.map(({ title, data }, index) => {
                if (!title || !data) return false;
                return <PhotoLabel key={index} title={title} data={data} />;
              })}
            </FlexComponent>
          </SimpleScroller>
        </div>
      ) : null}
    </div>
  );
};

PhotoSpecs.propTypes = {
  data: PropTypes.array,
};

PhotoSpecs.defaultProps = {
  data: [],
};

export default PhotoSpecs;
