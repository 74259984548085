import PropTypes from "prop-types";
import classNames from "classnames";
import { Input } from "components/atoms";
import { FormControl } from "components/templates";

const prefix = "m-form-input";

const FormInput = ({
  className,
  disabled = false,
  label,
  name,
  onChange = () => {},
  reverse = false,
  size,
  value,
  inputprops = {},
  ...props
}) => {

  const classForComponent = classNames(prefix, {
    [className]: className,
    "--reverse": reverse
  });

  return (
    <FormControl
      className={classForComponent}
      labelProps={{
        content: label,
        size
      }}
      content={
        <Input
          disabled={disabled}
          name={name}
          onChange={onChange}
          size={size}
          value={value}
          {...inputprops}
        />
      }
      {...props}
    />
  );
};

FormInput.propTypes = {
  disabled: PropTypes.bool,
  inputprops: PropTypes.object,
  onChange: PropTypes.func,
  reverse: PropTypes.bool
};

export default FormInput;
