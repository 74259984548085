import { reduxActions as UI } from "_constants/ui.constants";

export const openPanelLeft =
  ({ typePanel, panelProps, isOpen }) =>
  (dispatch) =>
    dispatch({
      type: UI.OPEN_PANEL_LEFT,
      payload: { typePanel, panelProps, isOpen },
    });

export const closePanelLeft = () => (dispatch) =>
  dispatch({ type: UI.CLOSE_PANEL_LEFT });
