export const reduxActions = {
  ADD_FAIL: "attribute/add_fail",
  ADD_RESET: "attribute/add_reset",
  ADD_SUCCESS: "attribute/add_success",
  ADD_STARTED: "attribute/add_started",

  ADD_GLOBAL_FAIL: "attribute/add_global_fail",
  ADD_GLOBAL_RESET: "attribute/add_global_reset",
  ADD_GLOBAL_SUCCESS: "attribute/add_global_success",
  ADD_GLOBAL_STARTED: "attribute/add_global_started",

  ALL_FAIL: "attribute/all_fail",
  ALL_RESET: "attribute/all_reset",
  ALL_SUCCESS: "attribute/all_success",
  ALL_STARTED: "attribute/all_started",

  TO_LAYER_FAIL: "attribute/to_layer_fail",
  TO_LAYER_RESET: "attribute/to_layer_reset",
  TO_LAYER_SUCCESS: "attribute/to_layer_success",
  TO_LAYER_STARTED: "attribute/to_layer_started",

  LIST_FAIL: "attribute/list_fail",
  LIST_RESET: "attribute/list_reset",
  LIST_SUCCESS: "attribute/list_success",
  LIST_STARTED: "attribute/list_started",

  MOVEIT_FAIL: "attribute/moveit_fail",
  MOVEIT_RESET: "attribute/moveit_reset",
  MOVEIT_SUCCESS: "attribute/moveit_success",
  MOVEIT_STARTED: "attribute/moveit_started",

  ONE_FAIL: "attribute/one_fail",
  ONE_RESET: "attribute/one_reset",
  ONE_SUCCESS: "attribute/one_success",
  ONE_STARTED: "attribute/one_started",

  POST_FAIL: "attribute/post_fail",
  POST_RESET: "attribute/post_reset",
  POST_SUCCESS: "attribute/post_success",
  POST_STARTED: "attribute/post_started",

  PUT_FAIL: "attribute/put_fail",
  PUT_RESET: "attribute/put_reset",
  PUT_SUCCESS: "attribute/put_success",
  PUT_STARTED: "attribute/put_started",

  UPDATE_FAIL: "attribute/update_fail",
  UPDATE_RESET: "attribute/update_reset",
  UPDATE_SUCCESS: "attribute/update_success",
  UPDATE_STARTED: "attribute/update_started",

  UPDATE_GLOBAL_FAIL: "attribute/update_global_fail",
  UPDATE_GLOBAL_RESET: "attribute/update_global_reset",
  UPDATE_GLOBAL_SUCCESS: "attribute/update_global_success",
  UPDATE_GLOBAL_STARTED: "attribute/update_global_started"
};

export const ACTIVE = "active";

export const ALIAS_TYPE = "alias";
export const DERIVED_TYPE = "derived";
export const CHANNEL_TYPE = "channel";
export const COMPOSITE_TYPE = "composite";

export const SHORTTEXT_DATATYPE = "shortText";
export const LONGTEXT_DATATYPE = "longText";
export const NUMBER_DATATYPE = "number";
export const LIST_DATATYPE = "list"

export const EDITED = "edited";
export const AI = "ia";
export const PLUGIN = "plugin";
