import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Toast } from "components/molecules";
import { InputTest } from "components/molecules/Inputs";
import { Button } from "components/atoms";
import { useChannelLayout, useFocus, useForm, useModal } from "_hooks";

const prefix = "o-new-channel-modal";

const NewChannelModal = ({ onSave }) => {
  const dispatch = useDispatch();
  const [inputRef] = useFocus();
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const [channel, setChannel] = useState("");
  const { disable, error, form, handleChange } = useForm({
    schemaType: "newChannel",
    channel
  });
  const { createLayout } = useChannelLayout();

  useEffect(() => {
    if (form.derivedFrom) {
      setChannel(form.derivedFrom);
    }
  }, [form]);
  
  const handleSubmit = async () => {
    try {
      const config = {
        name: form.name,
      };

      const response = await createLayout(config);

      const { data } = response;

      dispatch(closeModal());
      toast.info(<Toast info={t("settings:channelLayout.feedback.configure.info")} area={data[0].name} />);
      return typeof onSave === "function" ? onSave(data?.[0]): () => {};
    } catch (e) {

    }
  };

  return (
    <div className={prefix}>
      <div className={`${prefix}__division`}>
        <span className={`${prefix}__label`}>{t("settings:salesChannels.newModal.channel")}</span>
        <InputTest
          ref={inputRef}
          name="name"
          onChange={handleChange}
          error={error.name}
        />
      </div>
      <div className={`${prefix}__buttons`}>
        <Button 
          content={t("common:form.cancel")}
          onClick={closeModal}
          type="underline"
        />
        <Button
          content={t("settings:salesChannels.newModal.create")}
          type="purple"
          sm
          disabled={disable}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default NewChannelModal;
