import initialResourceState from "../resource.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as RESOURCE } from "_constants/resource.constants";

const all = {
  [RESOURCE.ALL_STARTED]: (state) => ({
    ...state,
    all: {
      ...state.all,
      status: LOADING
    }
  }),
  [RESOURCE.ALL_FAIL]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [RESOURCE.ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [RESOURCE.RESET_ALL]: (state) => ({
    ...state,
    all: initialResourceState.all
  })
};

export default all;
