import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import { NubeskIcons } from "components/atoms";
import { DropDown, FlexComponent } from "components/templates";
import { DateTask, Toast } from "components/molecules";
import TemplateRow from "./TemplateRow";
import { openModal } from "_redux/ui/ui.actions";
import { deleteZip, generateZip } from "_redux/resource/resource.actions";
import { dateTimeFormat } from "_utils/format";
import { 
  DONE,
  FAILED,
  GENERATED_MISSING_ADAPT, 
  GENERATED_MISSING_KEYS,
  GENERATED_MISSING_NOBG,
  PACKAGING
 } from "_constants/resource.constants";

const steps = {
  proccesing: { class: "processing", icon: "clock" },
  generatedKeys: { class: "generated-keys", icon: "clock" },
  generatedMissing: { class: "generated-missing", icon: "spin" },
  packaging: { class: "packaging", icon: "clock" },
  failed: { class: "failed", icon: "warning" },
  done: { class: "done", icon: "check" },
  hidden: { class: "hidden", icon: "hidden" },
};

const prefix = "m-download-row";

const DownloadRow = ({
  adaptationId,
  adaptationList,
  name,
  locked,
  disabled,
  data,
  total,
  catalog
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const copyRef = useRef(null);
  const [copy, setCopy] = useState(false);
  const [{ itemsInZip, original, totalInZip }, setAvailable] = useState({});
  const [status] = useState(data?.step);
  const classForComponent = classNames(prefix, {
    [`${prefix}--${steps[status]?.class}`]: status,
  });

  useEffect(() => {
    let timer;
    if (copy) timer = setTimeout(() => setCopy(false), 1000);
    return () => clearTimeout(timer);
  }, [copy]);

  const copyToClipboard = () => {
    copyRef.current.select();
    document.execCommand("copy");
    setCopy(true);
  };

  const deleteZ = () => dispatch(deleteZip(data._id));

  const download = () => {
    if (data?.data?.url) {
      window.open(`${data?.data?.url}?_=${Date.now()}`);
    }
  };

  const downloadXls = () => {
    if (data?.data?.urlManifest) {
      window.open(`${data.data.urlManifest}?_=${Date.now()}`);
    }
  };
  
  const generate = () => {
    let d = new Date();
    toast.info(
      <Toast
        area={t("catalogs:links.feedback.request.area")}
        info={t("catalogs:links.feedback.request.update")}
      />
    );
    dispatch(
      generateZip(
        catalog?._id,
        adaptationId,
        `${name}_${
          data.process?.processPoolFoldersId?.length
        }_${d.getDate()}_${d.getMonth()}_${d.getFullYear()}`
      )
    );
  };

  useEffect(() => {
    if (!data) return;
    setAvailable({
      itemsInZip: data.process?.processPoolFoldersId?.length,
      original: data.process?.originalPoolFoldersId?.length,
      totalInZip:
        data.process?.processPoolFoldersId?.length +
          data.process?.originalPoolFoldersId?.length || 0,
    });
  }, [data])

  return (
    <TemplateRow
      className={classForComponent}
      left={
        <FlexComponent gap={5}>
          {[DONE, FAILED].indexOf(data?.step) === -1 ? 
            <NubeskIcons icon="spin" className="u-spin" />
          :
            <NubeskIcons
              className={data?.step === DONE ? "--done" : ""}
              icon={[totalInZip, total].indexOf(undefined) !== -1 && totalInZip !== total ? 
                "warning" 
              :
                steps[status]?.icon
              }
              size={locked ? 25 : 23}
            />
          }
          <span 
            className={`${prefix}__download-title`}
            onClick={() => dispatch(openModal({
              closeBackdrop: false,
              typeModal: "generateLink",
              header: {
                text: t("catalogs:links.generateLink"),
                icon: { type: "downloadLine", size: 25 }
              },
              modalProps: {
                adaptationList,
                catalog,
                total: catalog?.foldersId?.length,
                resourceId: data._id
              }
            }))}
          >
            {name}
          </span>
        </FlexComponent>
      }
      right={
        <FlexComponent gap={0}>
          {(!disabled || [
            GENERATED_MISSING_ADAPT,
            GENERATED_MISSING_NOBG,
            GENERATED_MISSING_KEYS,
            PACKAGING
          ].indexOf(data.step) !== -1) && (                
            <>
              {copy ? 
                t("catalogs:links.copied")
              : (
                <>
                  <input
                    className={`${prefix}__copy-input`}
                    ref={copyRef}
                    value={`${data.data.url ?? ""}?_=${Date.now()}`}
                    onChange={() => {}}
                  />
                  <DateTask
                    className={`${prefix}__date`}
                    date={{
                      left: `${([
                        GENERATED_MISSING_ADAPT, 
                        GENERATED_MISSING_NOBG
                      ].indexOf(data.step) !== -1 && itemsInZip === original) ?
                          "-"
                        :
                          itemsInZip
                      }/${original}`,
                      right: `${
                        itemsInZip === 0
                          ? "0"
                          : Math.round((itemsInZip / original) * 100)
                      } %`,
                    }}
                    hover={dateTimeFormat(data?.createdAt)}
                    bg="purple"
                  />
                </>
              )}
            </>
          )}
          <DropDown
            disabled={disabled}
            options={[{
              leftIcon: <NubeskIcons icon="excel" />,
              content: t("catalogs:links.downloadXls"),
              disabled: (disabled || !data?.data?.urlManifest),
              onClick: downloadXls
            }, {
              content: t("catalogs:links.copyLink"),
              leftIcon: <NubeskIcons icon="linkInclined" />,
              onClick: copyToClipboard,
              visible: total === totalInZip
            }, {
              content: t("catalogs:links.downloadZip"),
              leftIcon: <NubeskIcons icon="downloadLine" />,
              disabled: disabled,
              onClick: download,
              visible: !disabled
            }, {
              content: t("catalogs:links.forceUpdate"),
              disabled: disabled,
              leftIcon: <NubeskIcons icon="refresh" />,
              onClick: generate,
              visible: !disabled
            }, {
              content: t("catalogs:links.deleteZip"),
              disabled: disabled,
              leftIcon: <NubeskIcons icon="delete" />,
              onClick: deleteZ,
              visible: !disabled
            }]}
            buttonProps={{
              content: <NubeskIcons icon="dots" />,
              type: "color"
            }}
          />
        </FlexComponent>
      }
    />
  );
};

export default DownloadRow;
