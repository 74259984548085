import { reduxActions as ATTRIBUTE } from "_constants/attribute.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialAttributeState from "../attribute.initialState";

const toLayer = {
  [ATTRIBUTE.TO_LAYER_STARTED]: (state) => ({
    ...state,
    toLayer: {
      ...state.toLayer,
      status: LOADING
    }
  }),

  [ATTRIBUTE.TO_LAYER_SUCCESS]: (state, { payload }) => ({
    ...state,
    toLayer: {
      ...state.toLayer,
      data: payload?.data?.sort((a, b) => (a?.index - b?.index)) ?? null,
      status: SUCCESS
    }
  }),

  [ATTRIBUTE.TO_LAYER_FAIL]: (state, { payload }) => ({
    ...state,
    toLayer: {
      ...state.toLayer,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [ATTRIBUTE.TO_LAYER_RESET]: (state) => ({
    ...state,
    toLayer: initialAttributeState.toLayer
  })
};

export default toLayer;
