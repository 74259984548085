import { useDispatch, useSelector } from "react-redux";
import { get, post } from "_redux/mediaLib/mediaLib.actions";
import { selectMediaLibOwn, selectMediaLibPost, selectMediaLibShared } from "_redux/mediaLib/mediaLib.selector";
import { CLUSTER_ACCOUNT, CLUSTER_USER, reduxActions as MEDIA_LIB } from "_constants/mediaLib.constants";

const useMediaLib = () => {
  const dispatch = useDispatch();

  const { data: ownData, error: ownError, status: ownStatus } = useSelector(selectMediaLibOwn);
  const { data: uploadData, error: uploadError, status: uploadStatus } = useSelector(selectMediaLibPost);
  const { data: sharedData, error: sharedError, status: sharedStatus } = useSelector(selectMediaLibShared);

  const fetchOwn = (params, extras = {}) => {
    if (!extras?.types) {
      extras.types = [
        MEDIA_LIB.OWN_STARTED,
        MEDIA_LIB.OWN_SUCCESS,
        MEDIA_LIB.OWN_FAIL
      ];
    }
    dispatch(get({
      ...params,
      a: {
        sort: { by: { "createdAt": -1 }},
        ...params?.a ?? {},
      },
      w: {
        ...params?.w,
        cluster: CLUSTER_USER
      }
    }, extras));
  };

  const fetchShared = (params, extras = {}) => {
    if (!extras?.types) {
      extras.types = [
        MEDIA_LIB.SHARED_STARTED,
        MEDIA_LIB.SHARED_SUCCESS,
        MEDIA_LIB.SHARED_FAIL
      ];
    }

    dispatch(get({
      ...params,
      a: {
        sort: { by: { "createdAt": -1 }},
        ...params?.a ?? {},
      },
      w: {
        ...params?.w,
        cluster: CLUSTER_ACCOUNT
      }
    }, extras));
  };

  const upload = async (file, params, extras = {}) => {
    let data = new FormData();
    data.append("files", file);

    for (let i in params) {
      if (typeof params[i] === "object") {
        data.append(i, JSON.stringify(params[i]));
      } else {
        data.append(i, params[i]);
      }
    }

    let response = await dispatch(post(data, {
      headers: { "Content-Type": "multipart/form-data" },
      feedback: {
        error: true,
        success: true
      },
      ...extras
    }));
    
    if (response?.data?.[0]) {
      let data = response?.data?.[0];
      let type;
      let payload;

      switch (data.cluster) {
        case CLUSTER_USER:
          payload = {
            data: ([...[data], ...ownData ?? []]),
            error: ownError,
            status: ownStatus,
          }
          type = MEDIA_LIB.OWN_SET;
          break;
        case CLUSTER_ACCOUNT:
          type = MEDIA_LIB.SHARED_SET;
          payload = {
            data: ([...[data], ...sharedData ?? []]),
            error: sharedError,
            status: sharedStatus,
          }
          break;
        default:
          break;
      };

      if (type) dispatch({ type, payload });
    }

    return response;
  };

  const remove = () =>  (data, extras = {}) => dispatch(remove(data, extras));

  const ownReset = () => dispatch({ type: MEDIA_LIB.OWN_RESET });
  const sharedReset = () => dispatch({ type: MEDIA_LIB.SHARED_RESET });
  const uploadReset = () => dispatch({ type: MEDIA_LIB.POST_RESET });

  return {
    fetchOwn,
    fetchShared,
    ownData,
    ownError,
    ownReset,
    ownStatus,
    sharedData,
    sharedError,
    sharedReset,
    sharedStatus,
    upload,
    uploadData,
    uploadError,
    uploadReset,
    uploadStatus,
    remove
  };
};

export default useMediaLib;
