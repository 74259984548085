import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const analyze = {
  [PRODUCT.ANALYZE_STARTED]: (state) => ({
    ...state,
    analyze: {
      ...state.analyze,
      status: LOADING,
    },
  }),
  [PRODUCT.ANALYZE_FAIL]: (state, { payload }) => ({
    ...state,
    analyze: {
      ...state.analyze,
      status: FAILURE,
      error: payload?.error ?? null,
    },
  }),
  [PRODUCT.ANALYZE_SUCCESS]: (state, { payload }) => ({
    ...state,
    analyze: {
      ...state.analyze,
      status: SUCCESS,
      data:
        [
          ...state.analyze.data.filter((item) =>
            !payload.data
              .map((item) => item.minFilesId)
              .includes(item.minFilesId)
          ),
          ...payload?.data,
        ] ?? null,
    },
  }),
  [PRODUCT.ANALYZE_RESET]: (state) => ({
    ...state,
    analyze: initialProductState.analyze,
  }),
};

export default analyze;
