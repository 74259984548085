import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const put = {
  [PRODUCT.PUT_STARTED]: (state) =>  ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),
  [PRODUCT.PUT_FAIL]: (state, { payload }) =>  ({
    ...state,
    error: payload?.error,
    status: FAILURE
  }),
  [PRODUCT.PUT_SUCCESS]: (state, { payload }) =>  ({
    ...state,
    put: {
      ...state.put,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),
  [PRODUCT.PUT_RESET]: (state) =>  ({
    ...state,
    put: initialProductState.put
  })
};

export default put;
