import { reduxActions as UI } from "_constants/ui.constants";

const loader = {
  [UI.OPEN_LOADER]: (state, { payload: { typeLoader, loaderProps } }) => ({
    ...state,
    loader: {
      typeLoader,
      isOpen: true,
      loaderProps,
    },
  }),
  [UI.CLOSE_LOADER]: (state) => ({
    ...state,
    loader: {
      ...state.loader,
      isOpen: false,
    },
  }),
};

export default loader;
