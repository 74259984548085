import classNames from "classnames";

const prefix = "a-textarea";

const TextArea = ({
  className,
  onChange = () => {},
  value = "",
  ...props
}) => {
  const classForComponent = classNames(prefix, {
    [className]: className
  });

  return (
    <textarea
      className={classForComponent}
      onChange={(e) => onChange({ e, value: e?.target?.value})}
      value={value}
      {...props}
    />);
};

export default TextArea;
