import { createSelector } from "reselect";

export const selectProductStore = (store) => store.product;

export const selectProductOneStore = createSelector(
  selectProductStore,
  (product) => product.one
);

export const selectProductOne = createSelector(
  selectProductOneStore,
  (one) => one.data
);

export const selectProductOneError = createSelector(
  selectProductOneStore,
  (one) => one.error
);

export const selectProductOneStatus = createSelector(
  selectProductOneStore,
  (one) => one.status
);

export const selectProductCountStore = createSelector(
  selectProductStore,
  (product) => product.count
);

export const selectProductCount = createSelector(
  selectProductCountStore,
  (count) => count.data
);

export const selectProductCountError = createSelector(
  selectProductCountStore,
  (count) => count.error
);

export const selectProductCountStatus = createSelector(
  selectProductCountStore,
  (count) => count.status
);

export const selectProductDetailsStore = createSelector(
  selectProductStore,
  (product) => product.details
);
export const selectProductDetails = createSelector(
  selectProductDetailsStore,
  (product) => product.data
);

export const selectProductCountPieceStore = createSelector(
  selectProductStore,
  (product) => product.countPiece
);

export const selectProductCountPiece = createSelector(
  selectProductCountPieceStore,
  (countPiece) => countPiece.data
);

export const selectProductCountPieceError = createSelector(
  selectProductCountPieceStore,
  (countPiece) => countPiece.error
);

export const selectProductCountPieceStatus = createSelector(
  selectProductCountPieceStore,
  (countPiece) => countPiece.status
);

export const selectProductAllStore = createSelector(
  selectProductStore,
  (product) => product.all
);

export const selectProductAll = createSelector(
  selectProductAllStore,
  (all) => all.data
);

export const selectProductAllError = createSelector(
  selectProductAllStore,
  (all) => all.error
);

export const selectProductAllStatus = createSelector(
  selectProductAllStore,
  (all) => all.status
);

export const selectProductPutStore = createSelector(
  selectProductStore,
  (product) => product.put
);

export const selectProductPut = createSelector(
  selectProductPutStore,
  (put) => put.data
);

export const selectProductPutError = createSelector(
  selectProductPutStore,
  (put) => put.error
);

export const selectProductPutStatus = createSelector(
  selectProductPutStore,
  (put) => put.status
);

export const selectProductAllListStore = createSelector(
  selectProductStore,
  (product) => product.allList
);

export const selectProductDownloadStore = createSelector(
  selectProductStore,
  (product) => product.download
);

export const selectAnalyzeStore = createSelector(
  selectProductStore,
  (product) => product.analyze
);

export const selectAnalyze = createSelector(
  selectAnalyzeStore,
  (analyze) => analyze.data
);

export const selectAnalyzeStatus = createSelector(
  selectAnalyzeStore,
  (analyze) => analyze.status
);

export const selectProductLayoutData = createSelector(
  selectProductStore,
  (product) => product.layoutData
)

export const selectProductCanvasEditor = createSelector(
  selectProductStore,
  (product) => product.canvasEditor
);

export const selectProductAiAnalysis = createSelector(
  selectProductStore,
  (product) => product.aiAnalysis
);


export default selectProductStore;
