import axios from 'axios';
import baseService from './baseService';
import jsonUrl from 'json-url'

const codec = jsonUrl('lzma')

const createAxiosInterface = (timestamp = true, skipInterceptors = false) => {
  const API = axios.create(baseService);
  
  API.interceptors.request.use(async(config) => {
    if (config.method.toLowerCase() === 'get') {
      let params = { ...config?.params ?? {}};

      if (!skipInterceptors) {
        let compressed = await codec.compress(params);
        config.params = { 
          "_": compressed 
        };

        if (process.env.REACT_APP_REQUEST_DEBUG === "true") {
          config.params = {
            ...config.params,
            ...params
          }
        }
      }

      if (timestamp) {
        config.params = {
          ...config.params,
          timestamp: Date.now()
        };
      }
    }
    
    return config;
  });

  return API;
};
  

export default createAxiosInterface;
