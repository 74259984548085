import { Avatar } from "components/atoms";
import classNames from "classnames";

const prefix = "m-avatar-name";

const AvatarName = ({ emptyLabel, firstName, lastName, ...props }) => {

  const classForComponent = classNames(prefix, {
    ['--dotted --transparent']: !firstName && !lastName
  });

  return (
    <div className={prefix}>
      <Avatar
        className={classForComponent}
        isEmpty={!firstName && !lastName}
        isDashed={!firstName && !lastName}
        firstName={firstName}
        lastName={lastName}
        {...props}
      />
      <span>{
        firstName && lastName ?
          `${firstName} ${lastName?.charAt(0)}`
        :
          emptyLabel || ""
        }
      </span>
    </div>
  )
};

export default AvatarName;
