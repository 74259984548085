import { reduxActions as UI } from "_constants/ui.constants";

export const openModal = (payload) => (dispatch) =>
  dispatch({
    type: UI.OPEN_MODAL,
    payload,
  });

export const closeModal = () => (dispatch) =>
  dispatch({
    type: UI.CLOSE_MODAL,
  });
