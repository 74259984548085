import { useDispatch, useSelector } from "react-redux";
import { toggleFilter } from "_redux/ui/ui.actions";
import { selectFilter } from "_redux/ui/ui.selector";

const useFilterPanel = () => {
  const dispatch = useDispatch();

  const filterPanel = useSelector(selectFilter);

  const filterToggle = () => toggleFilter()

  return {
    filterPanel,
    filterToggle
  };
};

export default useFilterPanel;
