import { createSelector } from 'reselect';

const selectTaskStore = (store) => store.task;

/**
 * All
 */
export const selectTaskAll = createSelector(
  selectTaskStore,
  (task) => task.all
);

/**
 * Items
 */
 export const selectTaskItems = createSelector(
  selectTaskStore,
  (task) => task.items
);

/**
 * One
 */
export const selectTaskOne = createSelector(
  selectTaskStore,
  (task) => task.one
);

/**
 * Post
 */
export const selectTaskPost = createSelector(
  selectTaskStore,
  (task) => task.post
);

/**
 * Selection
 */
export const selectTaskSelection = createSelector(
  selectTaskStore,
  (task) => task.selection
);

export default selectTaskStore;
