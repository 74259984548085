import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const count = {
  [PRODUCT.COUNT_STARTED]: (state) => ({
    ...state,
    count: {
      ...state.count,
      status: LOADING,
    },
  }),

  [PRODUCT.COUNT_FAIL]: (state, { payload }) => ({
    ...state,
    count: {
      ...state.count,
      error: payload?.error ?? null,
      status: FAILURE,
    },
  }),

  [PRODUCT.COUNT_SUCCESS]: (state, { payload }) => ({
    ...state,
    count: {
      ...state.count,
      data: payload?.data?.[0]?.count ?? 0,
      status: SUCCESS,
    },
    countPiece: {
      ...state.countPiece,
      data: payload?.data?.[0]?.count ?? 0,
    },
  }),

  [PRODUCT.COUNT_RESET]: (state) => ({
    ...state,
    count: initialProductState.count,
    countByField: initialProductState.countByField,
    countPiece: initialProductState.countPiece
  })
};

export default count;
