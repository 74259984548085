import initialSyncfoniaState from "../syncfonia.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as SYNCFONIA } from '../../syncfonia.constants';

const updateCatalogRetail = {
  [SYNCFONIA.UPDATE_CATALOG_RETAIL_STARTED]: (state) => ({
    ...state,
    updateCatalogRetail: {
      ...state.updateCatalogRetail,
      status: LOADING
    }
  }),
    
  [SYNCFONIA.UPDATE_CATALOG_RETAIL_FAIL]: (state, { payload }) => ({
    ...state,
    updateCatalogRetail: {
      ...state.updateCatalogRetail,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [SYNCFONIA.UPDATE_CATALOG_RETAIL_SUCCESS]: (state, { payload }) => ({
    ...state,
    updateCatalogRetail: {
      ...state.updateCatalogRetail,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [SYNCFONIA.UPDATE_CATALOG_RETAIL_RESET]: (state) => ({
    ...state,
    updateCatalogRetail: initialSyncfoniaState.updateCatalogRetail
  })
};

export default updateCatalogRetail;
