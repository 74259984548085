import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { filterAnalizeId } from "_redux/product/product.actions";
import useAppSettings from "_hooks/useAppSettings";

const prefix = "m-filter-group__filter-area";

const FilterArea = ({
  onBussy,
  onChange,
  rows = 6,
  ...props
}) => {
  const dispatch = useDispatch();
  const { appColumnKeyProps } = useAppSettings();
  const [value, setValue] = useState();

  const ref = useRef();

  useEffect(() => {
    if (ref.current) ref.current.focus();
  }, []);

  const changeHandler = (values) => {
    if (!values) return onChange(undefined);

    let regexp = /[\s|\n|,]/;
    let { mask, maxLength, fill, separator, validate } = appColumnKeyProps?.class?.format ?? {};
    values = values.replace(regexp, "\n");
    let split = values.split("\n");
    let clean = split.filter((value) => {
      value = value.trim();
      
      if (value.length) {
        return true;
      }
      return false;
    }).map((v) => {
      let newValue = "";
      if (!validate) return v;
      v = v.replace(/[^\w]/gi, '');
      if (v.length && mask) {
        for (let i = 1; i <= mask.length; i++) {
          if (mask[mask.length - i] === "X") {
            if (v[v.length-i]) {
              newValue = `${v[v.length-i]}${newValue}`; 
            } else if (fill){
              newValue = `${fill}${newValue}`; 
            }
          } else if (separator) {
            newValue = `${separator}${newValue}`;
          }
        }
        v = newValue;
      }
      if (v.length && fill && maxLength && v.length < maxLength) {
        v = v.padStart(maxLength, fill);
      }

      return v;
    });
    
    fetch(clean);
  };

  const fetch = async (array) => {
    if (typeof onBussy === "function") {
      onBussy();
    }
    
    let response = await dispatch(
      filterAnalizeId({
        f: ["key"],
        w: {
          key: array,
        },
      })
    );

    if (response?.data) {
      let { data } = response;
      let notFound = [];
      data = data.map((row) => row.key);

      if (array.length !== data.length) {
        notFound = array.filter((row) => data.indexOf(row) === -1);
      }

      onChange({
        found: data,
        notFound,
      });
    }
  };

  useEffect(() => {
    changeHandler(value);
  }, [value]);

  return (
    <div className={prefix}>
      <textarea
        ref={ref}
        rows={rows}
        value={value}
        style={{height: "100%"}}
        onChange={(e) => setValue(e.target.value)}
        {...props}
      />
    </div>
  );
};

export default FilterArea;
