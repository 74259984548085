import classNames from "classnames";

const prefix = "a-checkbox";

const CheckBox = ({ classLabel, classInput, checked, label, onChange, ...props }) => {
  const classForInput = classNames([`${prefix}__input`], {
    [classInput]: classInput
  });

  const classForLabel = classNames([`${prefix}__label`], {
    [classLabel]: classLabel
  });  

  return(
    <div {...props}>
      <input 
        className={classForInput} 
        checked={checked}
        {...props}
        readOnly
        type="checkbox"
      />
      <label
        className={classForLabel}
        onClick={()=> {
          if (typeof onChange === "function") onChange(!checked);
        }}
      >
        {label || " "}
      </label>
    </div>
  );
};

export default CheckBox;
