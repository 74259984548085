import React from "react";
import classNames from "classnames";
import { Scrollbar } from "react-scrollbars-custom";

const prefix = "o-scroller";

const SimpleScroller = ({ children, isHorizontal, ...props }) => {
  const classForComponent = classNames(prefix, {
    "is-horizontal": isHorizontal,
  });
  
  return (
    <Scrollbar
      noScrollX={!isHorizontal}
      noScrollY={isHorizontal}
      className={classForComponent}
      {...props} 
    >
      {children}
    </Scrollbar>
  );
};

export default SimpleScroller;
