const sizes = {
  conventional: {
    length: 1000,
    abb: ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  },
  computational: {
    length: 1024,
    abb: ['B', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  }
};

const sizeFormat = (size, decimals = 2, to = "conventional", from = "computational") => {
  if (!size) return '0 Bytes';
  let identify = size.replace(/[^a-zA-Z]/gi, "");
  size = size.replace(/[^0-9.]/gi, "");
  if (!+size) return '0 Bytes';

  let indexIdentify = sizes[from].abb.indexOf(identify);
  if (identify) {
    size = size * Math.pow(sizes[from].length, indexIdentify);
  }
  const k = sizes[to].length;
  const dm = decimals < 0 ? 0 : decimals;
  
  const i = Math.floor(Math.log(size) / Math.log(k));
  return `${parseFloat((size / Math.pow(k, i)).toFixed(dm))} ${sizes[to].abb[i]}`;
};

export default sizeFormat;
