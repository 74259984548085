import initialLayerState from "./layer.initialState";
import all from "./reducers/all";
import canvasEditor from "./reducers/canvasEditor";
import generate from "./reducers/generate";
import list from "./reducers/list";
import one from "./reducers/one";
import preview from "./reducers/preview";

const reducer = (state = initialLayerState, action) => {
  const reducers = {
    ...all,
    ...canvasEditor,
    ...generate,
    ...list,
    ...one,
    ...preview
  };

  let { type } = action;
  if (reducers[type] === undefined) return state;

  return reducers[type](state, action);
};

export default reducer;
