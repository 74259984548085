import Routes from '_config/routes/Routes';

export { default as AppRoutes } from "./AppRoutes";
export { default as AuthRoutes } from "./AuthRoutes"
export { default as CatalogRoutes } from "./CatalogRoutes";
export { default as GuessRoutes } from "./GuessRoutes";
export { default as ProductRoutes } from "./ProductRoutes";
export { default as SettingsRoutes } from "./SettingsRoutes";
export { default as TaskRoutes } from "./TaskRoutes";

export default Routes;
