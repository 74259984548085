import { reduxActions as ATTRIBUTE, ACTIVE } from "_constants/attribute.constants";
import { API } from "_constants/request.constants";

/**
 * 
 * @param { Object } params 
 * @param { Object } extras 
 * @returns 
 */
export const find = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      ATTRIBUTE.ALL_STARTED,
      ATTRIBUTE.ALL_SUCCESS,
      ATTRIBUTE.ALL_FAIL
    ];
  }

  return dispatch({
    [API]: {
      endpoint: "/setting/attribute",
      reqType: "GET",
      ...extras,
      params: {
        ...params ?? {},
        w: {
          status: ACTIVE,
          ...params?.w ?? {}
        }
      }
    }
  });
};

/**
 * 
 * @param { Object } data 
 * @param { Object } extras 
 * @returns 
 */
export const post = (data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      ATTRIBUTE.POST_STARTED,
      ATTRIBUTE.POST_SUCCESS,
      ATTRIBUTE.POST_FAIL
    ];
  }

  return dispatch({
    [API]: {
      endpoint: "/setting/attribute",
      reqType: "POST",
      ...extras,
      data
    }
  });
};

/**
 * 
 * @param { Object } data 
 * @param { Object } extras 
 * @returns 
 */
export const put = (_id, data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      ATTRIBUTE.PUT_STARTED,
      ATTRIBUTE.PUT_SUCCESS,
      ATTRIBUTE.PUT_FAIL
    ];
  }

  return dispatch({
    [API]: {
      endpoint: "/setting/attribute",
      reqType: "PUT",
      ...extras,
      data: {
        c: Array.isArray(data) ? data : [data],
        w: Array.isArray(_id) ? _id : [_id],
      }
    }
  });
};

