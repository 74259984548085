import { combineReducers } from 'redux';
import adaptationReducer from './adaptation/adaptation.reducer';
import analysisReducer from './analysis/analysis.reducer';
import appReducer from './app/app.reducer';
import attributeReducer from './attribute/attribute.reducer';
import billingReducer from './billing/billing.reducer';
import catalogReducer from './catalog/catalog.reducer';
import fileReducer from './file/file.reducer';
import filtersReducer from './filters/filters.reducer';
import { reducer as layerReducer } from './layer';
import { reducer as layoutReducer } from './layout';
import { reducer as mediaLibReducer } from './mediaLib';
import productReducer from './product/product.reducer';
import { reducer as profileReducer } from './profile';
import readerReducer from './reader/reader.reducer';
import resourceReducer from './resource/resource.reducer';
import statisticsReducer from './statistics/statistics.reducer';
import taskReducer from './task/task.reducer';
import uiReducer from './ui/ui.reducer';
import userReducer from './user/user.reducer';

import syncfoniaReducer from "module/Syncfonia/store/syncfonia.reducer";

export default combineReducers({
  adaptation: adaptationReducer,
  analysis: analysisReducer,
  app: appReducer,
  attribute: attributeReducer,
  billing: billingReducer,
  catalog: catalogReducer,
  file: fileReducer, 
  filters: filtersReducer,
  layer: layerReducer,
  layout: layoutReducer,
  mediaLib: mediaLibReducer,
  module: combineReducers({
    syncfonia: syncfoniaReducer
  }),
  product: productReducer,
  profile: profileReducer,
  reader: readerReducer,
  resource: resourceReducer,
  statistics: statisticsReducer,
  task: taskReducer,
  ui: uiReducer,
  user: userReducer,
});
