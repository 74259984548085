//Unidades de medida
const sizes = {
  B: 1e6,
  Bi: 1e6,
  KB: 1e3,
  KiB: 1e3,
  MB: 1,
  MiB: 1,
};

export const convertToMb = (data) => {
  if (Number.isNaN) return "-";
  //Se separa el string entre numeros y letras
  const splitData = data.replace(",", ".").match(/[a-zA-Z]+|[0-9.]+/g);
  //Tamaño en X
  if (!splitData?.[0]) return undefined;
  
  const size = splitData[0];
  //Unidad del tamaño
  const unit = splitData[splitData.length - 1];
  //Se convierte a megas dividiendo entre el valor y la cantidad del objeto de tamanos
  let result = size / sizes[unit];
  //Se redondea a 2 decimales
  return result.toFixed(2);
};
