import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as RESOURCE } from "_constants/resource.constants";
import initialResourceState from "../resource.initialState";

const put = {
  [RESOURCE.PUT_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),
  [RESOURCE.PUT_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [RESOURCE.PUT_SUCCESS]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [RESOURCE.PUT_RESET]: (state) => ({
    ...state,
    put: initialResourceState.put
  })
};

export default put;
