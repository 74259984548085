import React from "react";
import PropTypes from "prop-types";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const CircularProgress = ({ percent }) => {
  return (
    <div className="a-circular-progress" style={{ width: 100, height: 100 }}>
      <CircularProgressbarWithChildren value={percent} text={`${percent}%`} />
    </div>
  );
};

export default CircularProgress;

CircularProgress.propTypes = {
  percent: PropTypes.number.isRequired,
}
