export const reduxActions = {
  ALL_STARTED: 'task/all_started',
  ALL_FAIL: 'task/all_fail',
  ALL_SUCCESS: 'task/all_success',
  ALL_REFRESH: 'task/all_refresh',
  ALL_RESET: 'task/all_reset',

  ITEMS_STARTED: 'task/items_started',
  ITEMS_FAIL: 'task/items_fail',
  ITEMS_SUCCESS: 'task/items_success',
  ITEMS_REFRESH: 'task/items_refresh',
  ITEMS_RESET: 'task/items_reset',

  LIST_STARTED: 'task/list_started',
  LIST_FAIL: 'task/list_fail',
  LIST_SUCCESS: 'task/list_success',
  LIST_RESET: 'task/list_reset',

  ONE_STARTED: 'task/one_started',
  ONE_FAIL: 'task/one_fail',
  ONE_SUCCESS: 'task/one_success',
  ONE_REFRESH: 'task/one_refresh',
  ONE_RESET: 'task/one_reset',

  COMPLETE_STARTED: 'task/complete_started',
  COMPLETE_FAIL: 'task/complete_fail',
  COMPLETE_SUCCESS: 'task/complete_success',
  COMPLETE_RESET: 'task/complete_reset',

  INCOMPLETE_STARTED: 'task/incomplete_started',
  INCOMPLETE_FAIL: 'task/incomplete_fail',
  INCOMPLETE_SUCCESS: 'task/incomplete_success',
  INCOMPLETE_RESET: 'task/incomplete_reset',

  BROKEN_STARTED: 'task/broken_started',
  BROKEN_FAIL: 'task/broken_fail',
  BROKEN_SUCCESS: 'task/broken_success',
  BROKEN_RESET: 'task/broken_reset',

  POST_FAIL: 'task/post_fail',
  POST_RESET: 'task/post_reset',
  POST_STARTED: 'task/post_started',
  POST_SUCCESS: 'task/post_success',

  PUT_FAIL: 'task/put_fail',
  PUT_RESET: 'task/put_reset',
  PUT_STARTED: 'task/put_started',
  PUT_SUCCESS: 'task/put_success',

  REMOVE_FROM_FAIL: 'task/remove_from_fail',
  REMOVE_FROM_RESET: 'task/remove_from_reset',
  REMOVE_FROM_STARTED: 'task/remove_from_started',
  REMOVE_FROM_SUCCESS: 'task/remove_from_success',

  SELECTION_ALL_ITEMS: 'task/selection_all_items',
  SELECTION_ALL_TASKS: 'task/selection_all_tasks',
  SELECTION_ITEM_TOGGLE: 'task/selection_item_toggle',
  SELECTION_TASK_TOGGLE: 'task/selection_task_toggle',
  SELECTION_ITEMS_RESET: 'task/selection_items_reset'
};

export const ACTIVE = 'active';
export const ARCHIVED = 'archived';
export const INACTIVE = 'inactive';

export const DONE = 'done';

export const ITEM_DONE = 'done';
export const ITEM_DECLINED = 'declined';
export const ITEM_INPROGRESS = 'inProgress';
export const ITEM_PENDING = 'pending';
export const ITEM_TOREVIEW = 'toReview';

export const TYPE_GENERIC = 'generic_task';
export const TYPE_SHOOTING_SKU = 'shooting_sku';
export const TYPE_TEXT_REVIEW = 'text_review';
export const TYPE_IMAGERY_REVIEW = 'imagery_review';

export const ITEM_PRIORITY_HIGH = "high";
export const ITEM_PRIORITY_MEDIUM = "medium";
export const ITEM_PRIORITY_NORMAL = null;


