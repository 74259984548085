import React from "react";
import { useSelector } from "react-redux";
import { selectIdsSorted, selectImagesSelected, selectManageImages } from "_redux/ui/ui.selector";
import classNames from "classnames";
import ImageCard from "./ImageCard";

const ImagesWrapper = ({ orientation }) => {
  const _arrayIds = useSelector(selectIdsSorted);
  const _images = useSelector(selectManageImages);
  const _imagesSelected = useSelector(selectImagesSelected)
  const classForComponent = classNames("t-images-wrapper", {
    [`t-images-wrapper--${orientation}`]: orientation,
  });
  return (
    <div className={classForComponent}>
      {_arrayIds?.map((id, i) => (
        <ImageCard
          key={id}
          i={i}
          active={_imagesSelected.includes(id)}
          image={_images.find(({ id: _id }) => _id === id)}
          /*  onClick={() => setIndex(id)} */ id={id}
        />
      ))}
    </div>
  );
};

export default ImagesWrapper;
