import React, {  useState } from "react";
import classNames from "classnames";

import { FlexComponent } from "components/templates";

const DateTask = ({ className, date, bg, hover }) => {
  const classForComponent = classNames("m-date-task", {
    "is-purple": bg === "purple",
    [className]: className
  });

  const [isHovered, setHovered] = useState(false);
  
  return (
    <div
      className={classForComponent}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <FlexComponent sm>
        {isHovered && hover ? (
          <span>{hover}</span>
        ) : (
          <>
            <span>{date?.left}</span> <div className="m-date-task__point"></div>{" "}
            <span>{date?.right}</span>
          </>
        )}
      </FlexComponent>
    </div>
  );
};

export default DateTask;
