import React from "react";
import PropTypes from "prop-types";

import { FlexComponent } from "components/templates/index";

const User = ({ name, email }) => {
  const prefix = "m-user";
  return (
    <div className={prefix}>
      <FlexComponent isVertical>
        <span className={`${prefix}__name`}> {name}</span>
        <span className={`${prefix}__email`}>{email}</span>
      </FlexComponent>
    </div>
  );
};

export default User;

User.propTypes = {
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
};
