import PropTypes from "prop-types";

const prefix = "o-list";

const List = ({ list, limit, ...props }) => {
  return(
    
    <div className={prefix} {...props}>
      {list && Array.isArray(list) ?
        list.map((r, i) => (
          <div className={`${prefix}__row`} {...r} key={i}>
            {r.content}
          </div>    
        ))
      :
        Object.keys(list).map((c) => {
          let { content } = list[c];
          if (limit && content.length > limit) content = content.slice(0, limit);
          return (
            <>
              <div className={`${prefix}__separator`}>
                {c}
              </div>
              {content?.map((r, i) => {
                let { content } = r;
                return (
                  <div className={`${prefix}__row`} {...list[c]} {...r} key={i} >
                    {content}
                  </div>
                )
              })}
            </>
          );
        })
      }
    </div>
  );
};

List.propTypes = {
  limit: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

List.defaultProps = {
  limit: false,
  list: []
};

export default List;
