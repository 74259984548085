import initialLibraryState from "./mediaLib.initialState";
import all from "./reducers/all";
import own from "./reducers/own";
import post from "./reducers/post";
import shared from "./reducers/shared";

const reducer = (state = initialLibraryState, action) => {
  const reducers = {
    ...all,
    ...own,
    ...post,
    ...shared
  };

  let { type } = action;
  if (reducers[type] === undefined) return state;

  return reducers[type](state, action);
};

export default reducer;
