import { API } from '_constants/request.constants';
import { ACTIVE } from '_constants/task.constants';
import { reduxActions as TASK } from '_constants/task.constants';

export const find = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      TASK.ALL_STARTED,
      TASK.ALL_SUCCESS,
      TASK.ALL_FAIL
    ];
  }
  return dispatch({
    [API]: {
      endpoint: '/task',
      params: {
        ...params,
        a: params?.a ?? {},
        f: params?.f ?? {},
        w: {
          status: ACTIVE,
          ...params?.w ?? {},
        }
      },
      ...extras
    }
  });
};

export const findOne = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      TASK.ONE_STARTED,
      TASK.ONE_SUCCESS,
      TASK.ONE_FAIL
    ];
  }

  return dispatch({
    [API]: {
      endpoint: '/task',
      ...extras,
      params: {
        ...params,
        a: params?.a ?? {},
        f: params?.f ?? {},
        w: {
          status: ACTIVE,
          ...params?.w ?? {},
        }
      }
    }
  });
};

export const post = (data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      TASK.POST_STARTED,
      TASK.POST_SUCCESS,
      TASK.POST_FAIL
    ];
  }

  return dispatch({
    [API]: {
      endpoint: '/task',
      reqType: "POST",
      ...extras,
      data: {
        c: (Array.isArray(data) ? data : [data])
      }
    }
  });
};

export const put = (id, data, $config = {}, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      TASK.PUT_STARTED,
      TASK.PUT_SUCCESS,
      TASK.PUT_FAIL
    ];
  }

  let params = {
    c : Array.isArray(data) ? data : [data],
    w: Array.isArray(id) ? id : [id],
  }

  if ($config) params.$config = $config;

  return dispatch({
    [API]: {
      endpoint: '/task',
      reqType: "PUT",
      ...extras,
      data: params
    }
  });
};

/**
 * 
 * @param { String } id 
 * @param { Array } userArray 
 * @param { String } refresh 
 * @returns 
 */
export const share = (id, usersArray, refresh = "", extras = {}) => async (dispatch, getState) => {
  let removed;
  let shareRefresh;
  let newShare;
  let row;

  switch(refresh) {
    case "taskAll":
      row = getState()?.task.all.data?.filter((r) => {
        if (r._id === id) return true;
        return false;
      })?.pop();
      removed = row?.share.filter((s) => usersArray.indexOf(s._id) === -1);
      newShare = usersArray?.filter((s) => {
        let found = false;
        for (let u of row?.share) {
          if (u._id === s) {
            found = true;
            break;
          }
        }
        return !found;
      });

      shareRefresh = usersArray?.map((user) => {
        for (let u of getState()?.user.all.data) {
          if (u._id === user) return u;
        }
      }) ?? [];
      break;

    case "taskOne":
      row = getState()?.task.one.data;
      removed = row?.share?.filter((s) => usersArray.indexOf(s._id) === -1);
      newShare = usersArray?.filter((s) => {
        let found = false;
        for (let u of row?.share) {
          if (u._id === s) {
            found = true;
            break;
          }
        }
        return !found;
      });

      shareRefresh = usersArray?.map((user) => {
        for (let u of getState()?.user.all.data) {
          if (u._id === user) return u;
        }
      }) ?? [];
      break;

    default: break;
  }

  
  let response = await dispatch(put(id, {
    share: newShare
  }, null, {
    feedback: {
      error: true,
      success: {
        area: "tasks:share.feedback.area",
        info: "tasks:share.feedback.info"
      }
    }
  }));
  
  if (removed?.length) {
    await dispatch(put(id, {
      share: removed?.map((u) => u._id)
    }, {
      $action: "remove"
    }, {
      feedback: {
        error: true,
        success: false
      }
    }));
  }

  dispatch(refreshTask(id, {
    share: shareRefresh
  }, refresh));

  return response;
};

/**
 * 
 * @param { String } id 
 * @param { Object } data 
 * @param { String} refresh 
 * @returns 
 */
export const refreshTask = (id, data, refresh) => (dispatch) => {
  switch (refresh) {
    case "taskAll":
      return dispatch({ type: TASK.ALL_REFRESH, payload: [{
        _id: id,
        c: data
      }]});
      
    case "taskOne":
      return dispatch({ type: TASK.ONE_REFRESH, payload: {
        _id: id,
        c: data
      }});

    case "taskItems":
      return dispatch({ type: TASK.ITEMS_REFRESH, payload: {
        _id: id,
        c: data
      }});

    default: 
      return;
  }
};

/**
 * 
 * @param { String } key 
 * @param { Array } foldersId 
 * @param { Object } data 
 * @returns 
 */
export const updateItemProps = (key, foldersId, data, $config = null, extras = {}) => 
  (dispatch) => dispatch(put(foldersId, data, $config, {
    endpoint: `/task/${key}/process`,
    ...extras
  }));

/**
 * 
 * @param { String } id 
 * @returns 
 */
export const toggleSelectItem = (id) => (dispatch) => dispatch({
  type: TASK.SELECTION_ITEM_TOGGLE,
  payload: id
});

/**
 * 
 * @returns 
 */
export const toggleSelectAllItems = () => (dispatch) => dispatch({
  type: TASK.SELECTION_ALL_ITEMS
});

/**
 * 
 * @returns 
 */
export const resetSelectionItems = () => (dispatch) => dispatch({
  type: TASK.SELECTION_ITEMS_RESET
});

export const resetAll = () => (dispatch) => 
  dispatch({ type: TASK.ALL_RESET });

export const resetItems = () => (dispatch) => 
  dispatch({ type: TASK.ITEMS_RESET });

export const resetOne = () => (dispatch) => 
  dispatch({ type: TASK.ONE_RESET });
  
export const resetPost = () => (dispatch) => 
  dispatch({ type: TASK.POST_RESET });

export const resetPut = () => (dispatch) => 
  dispatch({ type: TASK.PUT_RESET });

export const resetRemoveFrom = () => (dispatch) => 
  dispatch({ type: TASK.REMOVE_FROM_RESET });


