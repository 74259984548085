import { reduxActions as APP } from "_constants/app.constants";

const updateProp = {
  [APP.UPDATE_PROP]: (state, {payload: { key, data }} ) => ({
    ...state,
    [key]: {
      ...data
    }
  })
};

export default updateProp;
