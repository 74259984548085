import { IDLE } from '_constants/redux.constants';

const initialUserState = {
  all: {
    data: null,
    error: null,
    status: IDLE
  },
  forgot: {
    data: null,
    error: null,
    status: IDLE
  },
  invitation: {
    data: null,
    error: null,
    status: IDLE
  },
  own: {
    data: null,
    error: null,
    status: IDLE
  },
  restore: {
    data: null,
    error: null,
    status: IDLE
  },
  selection: {
    all: false,
    users: []
  },
  post: {
    data: null,
    error: null,
    status: IDLE
  },
  put: {
    data: null,
    error: null,
    status: IDLE
  },
};

export default initialUserState;
