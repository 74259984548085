import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import SimpleScroller from "components/organism/Scroller/SimpleScroller";
import { GridLayout } from "components/templates";
import { SalesChannelCard } from "components/organism";
import { selectUser } from "_redux/user/user.selectors";
import { useChannelLayout } from "_hooks";
import { $$ADAPTATION } from "_constants/permission.constants";

const prefix = "v-sales-channel";

const SalesChannel = () => {
  const user = useSelector(selectUser);
  const { fetch, listData } = useChannelLayout();

  useEffect(() => {
    fetch();
  }, []);

  return (
    <div className={prefix}>
      <SimpleScroller>
        <div className={`${prefix}__content`}>
          <GridLayout settingCards>
            {listData?.map(
              ({
                adaptationsId,
                attributesId,
                key,
                name,
                refAdaptation,
                original,
                derivedFrom,
                updatedAt
              }) => (
                <SalesChannelCard
                  adaptationAttr={adaptationsId?.[0]?.attributes ?? {}}
                  attributes={attributesId ?? []}
                  derivedFrom={derivedFrom}
                  editable={user.canDoIt($$ADAPTATION.EDIT)}
                  key={key}
                  channelKey={key}
                  name={name}
                  originalName={original}
                  properties={adaptationsId?.[0]?.properties ?? {}}
                  refAdaptation={refAdaptation}
                  updated={updatedAt}
                />
              )
            )} 
          </GridLayout>
        </div>
      </SimpleScroller>
    </div>
  );
};

export default SalesChannel;
