import { IDLE } from "_constants/redux.constants";

const initialBillingState = {
  accounts: {
    data: null,
    error: null,
    status: IDLE
  },
  adaptations: {
    data: null,
    error: null,
    status: IDLE
  },
  aiImages: {
    data: null,
    error: null,
    status: IDLE
  },
  backRemovals: {
    data: null,
    error: null,
    status: IDLE
  },
  channels: {
    data: null,
    error: null,
    status: IDLE
  },
  currentPlan: {
    data: null,
    error: null,
    status: IDLE
  },
  dateFilter: null,
  heros: {
    data: null,
    error: null,
    status: IDLE
  },
  isometrics: {
    data: null,
    error: null,
    status: IDLE
  },
  items: {
    data: null,
    error: null,
    status: IDLE
  },
  pricing: {
    data: null,
    error: null,
    status: IDLE
  },
  watermarks: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialBillingState;
