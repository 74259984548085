import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const CircleStatus = ({ status }) => {
  const classForComponent = classNames("a-circle-status", {
    "is-complete": status === "approved",
    "is-incomplete": status === "toReview",
    "is-with-error": status === "rejected",
    "is-empty": status === "required",
  });
  return <div className={classForComponent}></div>;
};

export default CircleStatus;

CircleStatus.propTypes = {
  status: PropTypes.oneOf(['approved', 'toReview', 'rejected', 'required'])
}
