import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { Switch } from "components/atoms";
import { UploadDrop } from "components/organism";
import { useMediaLib, useModal, useUser } from "_hooks";
import { IconDrawer } from "components/molecules/index";
import { NubeskIcons } from "components/atoms/index";
import { LOADING, SUCCESS } from "_constants/redux.constants";
import { useEffect } from "react";

const prefix = "o-upload-media-lib-modal";

const UploadMediaLibModal = ({ clusterType }) => {
  const { t } = useTranslation();
  const { closeModal } = useModal();
  const { upload, uploadReset, uploadStatus } = useMediaLib();
  const { user } = useUser();

  const [file, setFile] = useState([]);
  const [rmBg, setRmBg] = useState(false);

  useEffect(() => {
    return () => {
      uploadReset();
    };
  }, [])

  return(
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        {!file?.[0] && uploadStatus !== LOADING && <UploadDrop
          file={file}
          setFile={setFile}
          label={"modals:uploadMediaLib.uploadLabel"}
          dropProps={{
            accept: "image/png, image/jpeg",
            maxFiles: 1,
            noClick: false,
            disabled: false
          }}
        />}

        {file?.[0] && ![LOADING, SUCCESS].includes(uploadStatus) && <>
          <div className={`${prefix}__image-content`}>
            <img src={URL.createObjectURL(file[0])} />
            <div
              className="--cancel-btn"
              onClick={() => setFile([])}
            >
              {t("common:form.cancel")}
            </div>
          </div>
          <div className={`${prefix}__options-content`}>
            <Switch
              label={t("modals:uploadMediaLib.removeBg")}
              checked={rmBg}
              onChange={(a) => setRmBg(a)}
            />
          </div>
        </>}

        {uploadStatus === LOADING &&
          <div className={`${prefix}__loading`} >
            <IconDrawer 
              bg="photos"
              bgProps={{size: "12rem", className: "u-gray"}}
              icon="upload"
              iconProps={{size: "5rem", className: "u-purple"}}
            />
            <div>{t("modals:uploadMediaLib.uploading")}</div>
          </div>
        }

        {uploadStatus === SUCCESS &&
          <div className={`${prefix}__load-success`} >
            <div><NubeskIcons className="u-purple" icon="upload" size="12rem" /></div>
            <div>{t("modals:uploadMediaLib.uploadSuccess")}</div>
          </div>
        }
      </div>

      <div className={`${prefix}__buttons`}>
        {uploadStatus !== SUCCESS && <>
          <Button 
            content={t("common:form.cancel")}
            disabled={uploadStatus === LOADING}
            onClick={closeModal}
            type="underline"
          />

          <Button 
            content={t("common:form.upload")}
            disabled={!file?.[0] || uploadStatus === LOADING}
            onClick={() => upload(
              file[0],
              {
                $config: {
                  nobg: rmBg, resize: true
                },
                c: { cluster: clusterType },
                userId: user._id
              }
            )}
            sm
            type="purple"
          />
        </>}

        {uploadStatus === SUCCESS && <>
          <div></div>
          <Button 
            content={t("common:words.done")}
            disabled={!file?.[0] || uploadStatus === LOADING}
            onClick={closeModal}
            sm
            type="purple"
          />
        </>}
      </div>
    </div>
  )
};

export default UploadMediaLibModal;
