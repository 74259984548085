import React from "react";
const prefix = 'm-bullets'
const Bullets = ({ lines }) => {
  return (
    <div className={prefix}>
      {new Array(lines).fill("-").map((line, index) => (
        <div key={index} className={`${prefix}__dots-area`}>
          <div></div>
        </div>
      ))}
    </div>
  );
};

export default Bullets;
