import { useDispatch } from "react-redux";
import { Badge } from "components/atoms";
import useAppSettings from "./useAppSettings";
import { setCurrentAdaptation } from "_redux/ui/ui.actions";
import { countFiles } from "_utils/files";
import capitalize from "_utils/string/capitalize";

const useDetailsPhoto = () => {
  const dispatch = useDispatch();

  const { appLayersData } = useAppSettings();

  const getAdaptationRow = async({ layoutList, product }) => {
    let list = [];

    for (let layout of layoutList) {
      let keyAttribute = layout?.attributesId?.filter((a) => !!a?.flag.key)?.pop();
      
      for (let a of layout.adaptationsId) {
        
        let adaptationImages = countFiles(product.filesId, a._id, false);
        
        if (adaptationImages.length || !!product?.data?.[layout.key]) {
          
          let sku = product?.data?.[layout.key];

          list.push({
            adaptationId: a._id,
            adaptationName: a.name,
            attributeKey: keyAttribute.key,
            code: product?.key,
            date: adaptationImages[adaptationImages.length - 1]?.updatedAt,
            layoutKey: layout.key,
            name: a.name,
            qty: adaptationImages.length,
            sku,
            title: <div className="--row-badge">
              <Badge content={layout.key} border="purple" title={layout.name}/>
              {capitalize(a.name)}
              {/* {`${a.properties?.width}x${a.properties?.height} · ${a.properties?.mimetype?.replace("image/", "")}`} */}
            </div>
          });
        }
      }
    }

    return list;
  };

  const getLayerImages = (data) => {
    let output = [];
    let indexes = [];
    if (!appLayersData || !appLayersData.length) return output;
    
    try {
      for (let file of data.layersId) {
        let layer = appLayersData.filter((l) => l._id === file.adaptationLayerId).pop();
        if (!layer) continue;

        let found = indexes.indexOf(layer._id);
        if (found !== -1) {
          output[found].images.push(file);
        } else {
          output.push({
            ...layer,
            images: [
              file
            ],
            updatedAt: file.updatedAt
          });

          indexes.push(layer._id);
        }
      }
    } catch (e) {
      return output;
    }

    return output;
  };

  const setAdaptationSelected = (adaptationId) => dispatch(setCurrentAdaptation(adaptationId));

  return {
    getAdaptationRow,
    getLayerImages,
    setAdaptationSelected
  };
};

export default useDetailsPhoto;
