import React from "react";
import { CountNumber, CountText } from "components/atoms";
const CountUpModal = ({ simple, count, text, variant }) => {
  return (
    <>
      <CountNumber variant={variant} count={count} animation={!simple} />
      <CountText text={text} />
    </>
  );
};

export default CountUpModal;
