import initialLayerState from "../layer.initialState";
import { reduxActions as LAYER} from "_constants/layer.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const preview = {
  [LAYER.PREVIEW_STARTED]: (state) => ({
    ...state,
    preview: {
      ...state.preview,
      status: LOADING
    }
  }),
  
  [LAYER.PREVIEW_SUCCESS]: (state, { payload }) => ({
    ...state,
    preview: {
      ...state.preview,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),
  
  [LAYER.PREVIEW_FAIL]: (state, { payload }) => ({
    ...state,
    preview: {
      ...state.preview,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [LAYER.PREVIEW_RESET]: (state) => ({
    ...state,
    preview: initialLayerState.preview
  })
};

export default preview;
