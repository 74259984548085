import { reduxActions as CATALOG } from "_constants/catalog.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const toArchive = {
  [CATALOG.TO_ARCHIVED_STARTED]: (state) => ({
    ...state,
    one: {
      ...state.one,
      status: LOADING
    }
  }),

  [CATALOG.TO_ARCHIVED_FAIL]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: {
        ...state.one.data,
        error: payload?.error ?? null
      },
      status: FAILURE
    }
  }),

  [CATALOG.TO_ARCHIVED_SUCCESS]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: payload?.data?.[0]?.status ?? null,
      status: SUCCESS
    },
    all: {
      ...state.all,
      data: [
        ...state.all.data?.filter((row) => row._id !== payload?.data?.[0]?._id)
      ]
    }
  })
};

export default toArchive;
