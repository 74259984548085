const useLocalStorage = () => {

  const inLocalStorage = (key) => {
    if (!key) return undefined;
    if (localStorage.getItem(key) === "undefined") {
      return undefined;
    } else {
      return JSON.parse(localStorage.getItem(key)) || null;
    }
  }

  const setLocalStorage = (key, data) =>
    localStorage.setItem(key, JSON.stringify(data));
    
  return [inLocalStorage, setLocalStorage ];
};

export default useLocalStorage;
