import { reduxActions as USER } from '_constants/user.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialUserState from '../user.initialState';

const post = {
  [USER.POST_STARTED]: (state) => ({
    ...state,
    post: {
      ...state.post,
      status: LOADING
    }
  }),
    
  [USER.POST_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [USER.POST_SUCCESS]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      data: payload?.data ?? null,
      status: SUCCESS
    }, 
    all: {
      ...state.all,
      data: state?.all?.data ? [
        ...payload?.data ?? [], 
        ...state.all.data ?? []
      ] : payload?.data ?? []
    }
  }),
  
  [USER.POST_RESET]: (state, { payload }) => ({
    ...state,
    post: initialUserState.post
  })
};

export default post;
