import { reduxActions as READER } from "_constants/reader.constants";

export const post = (data, extras = {}) =>
  (dispatch) => {
    if (!extras?.types) {
      extras.types = [
        READER.UPLOAD_ONE_STARTED,
        READER.UPLOAD_ONE_SUCCESS,
        READER.UPLOAD_ONE_FAIL,
      ];
    }
    return dispatch({
      NBSK: {
        endpoint: "/folder/upload",
        reqType: "POST",
        ...extras,
        data,
      },
    });
  };

export const upload = (files, extras = {}) => (dispatch) => {
  let formData = new FormData();
  if (!Array.isArray(files)) files = [files];
  
  for (let file of files) {
    formData.append('files', file)
  }

  return dispatch(post(formData, {
    endpoint: '/folder/filter/upload',
    ...extras
  }));
};

export const filterKeys = (files) => (dispatch) => 
  dispatch(upload(files, {
    types: [ 
      READER.FILTER_KEYS_STARTED,
      READER.FILTER_KEYS_SUCCESS,
      READER.FILTER_KEYS_FAIL
    ]
  }));

export const searchFilter = (data) => (dispatch) => {
  return dispatch({
    NBSK: {
      types: [
        READER.SEARCH_KEYS_STARTED,
        READER.SEARCH_KEYS_SUCCESS,
        READER.SEARCH_KEYS_FAIL,
      ],
      params: { w: { key: [...data] } },
      endpoint: "/folder/filter",
      reqType: "GET",
    },
  });
};

export const uploadOne = (file) => (dispatch) =>
  dispatch(
    upload([file], {
      types: [
        READER.UPLOAD_ONE_STARTED,
        READER.UPLOAD_ONE_SUCCESS,
        READER.UPLOAD_ONE_FAIL,
      ],
    })
  );

export const resetOne = () => (dispatch) => 
  dispatch({type: READER.UPLOAD_ONE_RESET});

export const resetFiltersKey = () => (dispatch) => 
  dispatch({type: READER.FILTER_KEYS_RESET});

export const resetDownload = () => (dispatch) => 
  dispatch({ type: READER.DOWNLOAD_XLS_RESET });
