import initialBillingState from "../billing.initialState";
import { reduxActions as BILLING } from "_constants/billing.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const pricing = {
  [BILLING.PRICING_STARTED]: (state) => ({
    ...state,
    pricing: {
      ...state.pricing,
      status: LOADING
    }
  }),

  [BILLING.PRICING_SUCCESS]: (state, { payload }) => ({
    ...state,
    pricing: {
      ...state.pricing,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [BILLING.PRICING_FAIL]: (state, { payload }) => ({
    ...state,
    pricing: {
      ...state.pricing,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [BILLING.PRICING_RESET]: (state) => ({
    ...state,
    pricing: initialBillingState.pricing
  })
};

export default pricing;
