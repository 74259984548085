import Avatar from "components/atoms/Avatar/Avatar";
import { DropDown } from "components/templates";
import React from "react";
import { useSelector } from "react-redux";
import useTooltip from "_hooks/useTooltip";
import { selectUser } from "_redux/user/user.selectors";

const UserButton = () => {
  const user = useSelector(selectUser);
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });

  return (
    <div {...injectedProps}>
      <DropDown
        className="m-user-button"
        disabled
        /* dropComponent={
        <div style={{ padding: 10, minWidth: 200 }}>
          SOON
        </div>
      } */
        buttonProps={{
          content: <Avatar {...user.account} noTooltip />,
          onMouseMove: injectedProps.onMouseMove,
          onMouseLeave: injectedProps.onMouseLeave,
          type: null,
        }}
      />
    </div>
  );
};

export default UserButton;
