import { createSelector } from "reselect";

const selectFileStore = (state) => state.file;

export const selectFileAllStore = createSelector(
  selectFileStore,
  (file) => file.all
);

export const selectFileAll = createSelector(
  selectFileAllStore,
  (all) => all.data
);

export const selectFileAllError = createSelector(
  selectFileAllStore,
  (all) => all.error
);

export const selectFileAllStatus = createSelector(
  selectFileAllStore,
  (all) => all.status
);

export const selectFileOneStore = createSelector(
  selectFileStore,
  (file) => file.one
);

export const selectFileOne = createSelector(
  selectFileOneStore,
  (one) => one.data
);

export const selectFileOneError = createSelector(
  selectFileOneStore,
  (one) => one.error
);

export const selectFileOneStatus = createSelector(
  selectFileOneStore,
  (one) => one.status
);

export const selectFilePostStore = createSelector(
  selectFileStore,
  (file) => file.post
);

export const selectFilePost = createSelector(
  selectFilePostStore,
  (post) => post.data
);

export const selectFilePostError = createSelector(
  selectFilePostStore,
  (post) => post.error
);

export const selectFilePostStatus = createSelector(
  selectFilePostStore,
  (post) => post.status
);

export const selectFilePutStatus = createSelector(
  selectFileStore,
  (store) => store.put.status
);
export const selectFileRemoveBgStore = createSelector(
  selectFileStore,
  (file) => file.removeBg
);

export const selectFileAiBgGenerate = createSelector(
  selectFileStore,
  (file) => file.aiBgGenerate
);

export default selectFileStore;
