import { reduxActions as ATTRIBUTE } from "_constants/attribute.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialAttributeState from "../attribute.initialState";

const list = {
  [ATTRIBUTE.LIST_STARTED]: (state) => ({
    ...state,
    list: {
      ...state.list,
      status: LOADING
    }
  }),

  [ATTRIBUTE.LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: {
      ...state.list,
      data: payload?.data?.sort((a, b) => {
        if (a > b) return 1;
        else if (a > b) return -1;

        return 0;
      }) ?? null,
      status: SUCCESS
    }
  }),

  [ATTRIBUTE.LIST_FAIL]: (state, { payload }) => ({
    ...state,
    list: {
      ...state.list,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [ATTRIBUTE.LIST_RESET]: (state) => ({
    ...state,
    list: initialAttributeState.list
  })
}

export default list;
