/**
 * 
 * @param {String} cname 
 * @param {Mixed} cvalue 
 * @param {Int} expires 
 */
const setCookie = (cname, cvalue, expires) => {
  const d = new Date();
  d.setTime(d.getTime() + expires);
  expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export default setCookie;
