import React from 'react'
import { useSelector } from 'react-redux';
import { FlexComponent } from "components/templates";
import { StatisticsButton } from "components/molecules";

import { 
  selectProductComplete, 
  selectProductIncomplete, 
  selectProductBroken 
} from '_redux/statistics/statistics.selector';

import { selectProductCount } from '_redux/product/product.selector';
import formatNumber from '_utils/number/formatNumber';

const ProductStatistics = () => {
  const prefix = "o-product-statistics";

  const total = useSelector(selectProductCount);
  const complete = useSelector(selectProductComplete);
  const incomplete = useSelector(selectProductIncomplete);
  const broken = useSelector(selectProductBroken);
  
  return (
    <div className={prefix}>
      <div className={`${prefix}__total`}>
        <span className={`${prefix}__total-name`}>Total SKU'S</span>
        <span className={`${prefix}__total-qty`}>{formatNumber(total)}</span>
      </div>
      <FlexComponent isVertical>
        <StatisticsButton status="complete" qty={complete}/>
        <StatisticsButton status="incomplete" qty={incomplete}/>
        <StatisticsButton status="error" qty={broken}/>
      </FlexComponent>
    </div>
  )
}

export default ProductStatistics
