import { reduxActions as UI } from "_constants/ui.constants";

const tooltip = {
  [UI.TOGGLE_TOOLTIP]: (state, { payload }) => ({
    ...state,
    tooltip: {
      ...state.tooltip,
      ...payload
    }
  })
};


export default tooltip;
