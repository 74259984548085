import { reduxActions as BILLING } from "_constants/billing.constants";
import { API, NBSK_API, WEB } from "_constants/request.constants";
import dateFormatFilter from "_utils/format/dateFormatFilter";

export const request = (req = {}, extras = {}, type = NBSK_API) => 
  (dispatch) =>  {
    if (!(typeof req === "object" && !Array.isArray(req))) req = {};
    if (!extras?.types) {
      extras.types = [
        BILLING.FIND_STARTED,
        BILLING.FIND_SUCCESS,
        BILLING.FIND_FAIL
      ];
    }
    let { data, params } = req;
    
    const reqConfig = {
      [type]: {
        endpoint: "/billing",
        reqType: "GET",
        feedback: {
          success: false,
          error: false
        },
        ...extras
      }
    };
    
    if (params) reqConfig[type].params = params;
    if (data) reqConfig[type].data = data;
    
    return dispatch(reqConfig);
  };


export const post = (data = {}, extras = {}, type = NBSK_API) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      BILLING.POST_STARTED,
      BILLING.POST_SUCCESS,
      BILLING.POST_FAIL
    ];
  }

  extras = {
    ...extras,
    reqType: "POST"
  };

  return dispatch(request({ data }, extras, type));
};

export const getPricing = () => (dispatch) => {
  return dispatch(request({}, {
    endpoint: "/billing/plan",
    types: [
      BILLING.PRICING_STARTED,
      BILLING.PRICING_SUCCESS,
      BILLING.PRICING_FAIL
    ],
    feedback: {
      success: false,
      error: true
    }
  }, WEB));
};

export const getAccounts = () => (dispatch) => 
  dispatch(post({ w: { _: "@" }}, {
    endpoint: "/analytic/billing/users",
    types: [
      BILLING.ACCOUNTS_STARTED,
      BILLING.ACCOUNTS_SUCCESS,
      BILLING.ACCOUNTS_FAIL
    ],
    feedback: {
      success: false,
      error: true
    }
  }, API));


export const getItems = () => (dispatch) => 
  dispatch(post({ w: { _: "@" }}, {
    endpoint: "/analytic/billing/items",
    types: [
      BILLING.ITEMS_STARTED,
      BILLING.ITEMS_SUCCESS,
      BILLING.ITEMS_FAIL
    ],
    feedback: {
      success: false,
      error: true
    }
  }, API));

export const getAdaptations = (day = null, filter = null) => (dispatch) =>  {
  let data = {};
  let formated;
  if (day && filter) {
    formated = dateFormatFilter(day, filter.month, filter.year);
  } else {
    formated = dateFormatFilter(day, filter.month, filter.year);
  }

  data.w = {
    createdAt: formated
  };

  return dispatch(post(data, {
    endpoint: "/analytic/billing/adaptations",
    types: [
      BILLING.ADAPTATIONS_STARTED,
      BILLING.ADAPTATIONS_SUCCESS,
      BILLING.ADAPTATIONS_FAIL
    ],
    feedback: {
      success: false,
      error: true
    }
  }, API));
};

export const getBackRemovals = (day = null, filter = null) => (dispatch) => {
  let data = {};
  let formated;
  if (day && filter) {
    formated = dateFormatFilter(day, filter.month, filter.year);
  } else {
    formated = dateFormatFilter(day, filter.month, filter.year);
  }

  data.w = {
    createdAt: formated
  };
  
  return dispatch(post(data, {
    endpoint: "/analytic/billing/backremovals",
    types: [
      BILLING.BACK_REMOVALS_STARTED,
      BILLING.BACK_REMOVALS_SUCCESS,
      BILLING.BACK_REMOVALS_RESET
    ],
    feedback: {
      success: false,
      error: true
    }
  }, API));
};

export const getCurrentPlan = () => (dispatch) => 
  dispatch(request({}, {
    endpoint: "/setting/billing",
    types: [
      BILLING.CURRENT_PLAN_STARTED,
      BILLING.CURRENT_PLAN_SUCCESS,
      BILLING.CURRENT_PLAN_FAIL
    ],
    feedback: {
      success: false,
      error: true
    }
  }, API));

export const getChannels = () => (dispatch) => 
  dispatch(post({ w: { _: "@" }}, {
    endpoint: "/analytic/billing/channels",
    types: [
      BILLING.CHANNELS_STARTED,
      BILLING.CHANNELS_SUCCESS,
      BILLING.CHANNELS_FAIL
    ],
    feedback: {
      success: false,
      error: true
    }
  }, API));

export const setDateFilter = (date) => (dispatch) =>
  dispatch({ type: BILLING.DATE_FILTER, payload: date })
