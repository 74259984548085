import { IDLE } from "_constants/redux.constants";

const initialReaderState = {
  filterKeys: {
    data: null,
    error: null,
    status: IDLE
  },
  download: {
    data: null,
    error: null,
    status: IDLE
  },
  one: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialReaderState;
