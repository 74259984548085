import { createSelector } from "reselect";

export const selectUiStore = (store) => store.ui;

export const selectToolbar = createSelector(
  selectUiStore,
  (uiStore) => uiStore.toolbar
);
export const selectCurrentToolbar = createSelector(
  selectToolbar,
  (toolbar) => toolbar.current
);

export const selectDetails = createSelector(
  selectUiStore,
  (uiStore) => uiStore.details
);

export const selectPrevious = createSelector(
  selectUiStore,
  (uiStore) => uiStore.previous
);

export const selectLeftPanel = createSelector(
  selectUiStore,
  (uiStore) => uiStore.leftPanel
);

export const selectCurrent = createSelector(
  selectDetails,
  (details) => details.currentAdaptation
);

export const selectCurrentImageDetails = createSelector(
  selectDetails,
  (details) => details.currentImage
);

export const selectImages = createSelector(
  selectDetails,
  (details) => details.images
);

export const selectPage = createSelector(
  selectDetails,
  (details) => details.page
);

export const selectSkuSelected = createSelector(
  selectUiStore,
  (uiStore) => uiStore.selections
);

export const selectShortcuts = createSelector(
  selectUiStore,
  (uiStore) => uiStore.shortcuts
);

export const selectStatistics = createSelector(
  selectUiStore,
  (uiStore) => uiStore.statistics
);

export const selectModal = createSelector(
  selectUiStore,
  (uiStore) => uiStore.modal
);

export const selectFilter = createSelector(
  selectUiStore,
  (uiStore) => uiStore.filter
);

export const selectFilterIsOpen = createSelector(
  selectFilter,
  (filter) => filter.isOpen
);

export const selectFilterHeight = createSelector(
  selectFilter,
  (filter) => filter.height
);

export const selectCurrentImage = createSelector(
  selectUiStore,
  (uiStore) => uiStore.details
);

export const selectUiSmartEditorStore = createSelector(
  selectUiStore,
  (uiStore) => uiStore.smartEditor
);

export const selectUiSmartEditorVisible = createSelector(
  selectUiSmartEditorStore,
  (smartEditor) => smartEditor.visible
);

export const selectLoader = createSelector(
  selectUiStore,
  (uiStore) => uiStore.loader
);

export const selectManageImagesStore = createSelector(
  selectUiStore,
  (uiStore) => uiStore.manageImages
);

export const selectImagesSelected = createSelector(
  selectManageImagesStore,
  (store) => store.selection
);

export const selectManageImages = createSelector(
  selectManageImagesStore,
  (store) => store.images
);

export const selectDragItem = createSelector(
  selectManageImagesStore,
  (store) => store.drag.itemId
);

export const selectIdsSorted = createSelector(
  selectManageImagesStore,
  (store) => store.idsSorted
);

export const selectInitialSort = createSelector(
  selectManageImagesStore,
  (store) => store.initialSort
);

export const selectManageImagesImage = createSelector(
  selectManageImagesStore,
  (store) => store.selectedImage
);

export const selectManageImagesisOpen = createSelector(
  selectManageImagesStore,
  (store) => store.isOpen
);

export const selectManageChannel = createSelector(
  selectManageImagesStore,
  (store) => store.channelName
);

export const selectTooltip = createSelector(
  selectUiStore,
  (uiStore) => uiStore.tooltip
);

export const selectRefresh = createSelector(
  selectUiStore,
  (uiStore) => uiStore.refresh
);

export default selectUiStore;
