import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const putCopy = {
  [PRODUCT.PUT_COPY_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),
  [PRODUCT.PUT_COPY_SUCCESS]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: {
        ...state.one.data,
        data: { ...payload?.data?.[0]?.data },
      },
    },
    put: {
      ...state.put,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),
  [PRODUCT.PUT_COPY_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error,
      status: FAILURE
    }
  })
};

export default putCopy;
