import { PLUGIN } from "_constants/request.constants";
import { reduxActions as SYNCFONIA } from "../syncfonia.constants";

export const updateCatalogProvider = (data, extras = {}) => (dispatch) => dispatch({
  [PLUGIN]: {
    types: [SYNCFONIA.UPDATE_CATALOG_PROVIDER_STARTED, SYNCFONIA.UPDATE_CATALOG_PROVIDER_SUCCESS, SYNCFONIA.UPDATE_CATALOG_PROVIDER_FAIL],
    endpoint: '/plugin/syncfonia/provider/update/gtin/getAssets',
    reqType: 'POST',
    data,
    ...extras
  }
});

export const updateCatalogRetail = (data, extras = {}) => (dispatch) => dispatch({
  [PLUGIN]: {
    types: [SYNCFONIA.UPDATE_CATALOG_RETAIL_STARTED, SYNCFONIA.UPDATE_CATALOG_RETAIL_SUCCESS, SYNCFONIA.UPDATE_CATALOG_RETAIL_FAIL],
    endpoint: '/plugin/syncfonia/retailer/update/gtin/getAssets',
    reqType: 'POST',
    data,
    ...extras
  }
});

export const updateGtin = (data, extras = {}) => (dispatch) => dispatch({
  [PLUGIN]: {
    types: [SYNCFONIA.UPDATE_GTIN_STARTED, SYNCFONIA.UPDATE_GTIN_SUCCESS, SYNCFONIA.UPDATE_GTIN_FAIL],
    endpoint: '/plugin/syncfonia/retailer/update/gtin',
    reqType: 'POST',
    data,
    ...extras
  }
});

export const updateGtinProvider = (data, extras = {}) => (dispatch) => dispatch({
  [PLUGIN]: {
    types: [SYNCFONIA.UPDATE_GTIN_STARTED, SYNCFONIA.UPDATE_GTIN_SUCCESS, SYNCFONIA.UPDATE_GTIN_FAIL],
    endpoint: '/plugin/syncfonia/provider/update/gtin',
    reqType: 'POST',
    data,
    ...extras
  }
});
