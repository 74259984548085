import initialCatalogState from "../catalog.initialState";
import { reduxActions as CATALOG } from "_constants/catalog.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const one = {
  [CATALOG.ONE_STARTED]: (state) => ({
    ...state,
    one: {
      ...state.one,
      status: LOADING,
    },
  }),

  [CATALOG.ONE_FAIL]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      error: payload?.error ?? null,
      status: FAILURE,
    },
  }),

  [CATALOG.ONE_SUCCESS]: (state, { payload }) => {
    let data = payload?.data?.[0] ?? null;
    
    if (data) {
      data = {
        ...data,
        resourcesId: data.resourcesId?.sort((a, b) => {
          if (a.createdAt > b.createdAt) return -1;

          if (a.createdAt < b.createdAt) return 1;

          return 0;
        })
      };
    }
    return ({
      ...state,
      one: {
        ...state.one,
        data: data,
        status: SUCCESS,
      }
    })
  },

  [CATALOG.ONE_RESET]: (state) => ({
    ...state,
    one: initialCatalogState.one,
  })
};

export default one;
