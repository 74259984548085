import initialAppState from './app.initialState';
import adaptations from './reducers/adaptations';
import adaptationsPost from './reducers/adaptationsPost';
import adaptationsPut from './reducers/adaptationsPut';
import columnKey from './reducers/columnKey';
import componentDrawing from './reducers/componentDrawing';
import configGot from './reducers/configGot';
import configurated from './reducers/configurated';
import copyWorkflow from './reducers/copyWorkflow';
import externalNubai from './reducers/externalNubai';
import fonts from './reducers/fonts';
import getImageryWorkflow from './reducers/getImageryWorkflow';
import getTemplate from './reducers/getTemplate';
import getLookAndFeel from './reducers/getLookAndFeel';
import layers from './reducers/layers';
import modules from './reducers/modules';
import propertyColumns from './reducers/propertyColumns';
import postPropertyColumns from './reducers/postPropertyColumns';
import putPropertyColumns from './reducers/putPropertyColumns';
import updateProp from './reducers/updateProp';
import mediaHelp from './reducers/mediaHelp';

const appReducer = (state = initialAppState, action) => {
  const reducers = {
    ...adaptations,
    ...adaptationsPost,
    ...adaptationsPut,
    ...columnKey,
    ...componentDrawing,
    ...configGot,
    ...configurated,
    ...copyWorkflow,
    ...externalNubai,
    ...fonts,
    ...getImageryWorkflow,
    ...getLookAndFeel,
    ...getTemplate,
    ...layers,
    ...mediaHelp,
    ...modules,
    ...propertyColumns,
    ...postPropertyColumns,
    ...putPropertyColumns,
    ...updateProp
  };
  
  let { type } = action;
  if (reducers[type] === undefined) return state;
  
  return reducers[type](state, action);
};

export default appReducer;
