import classNames from "classnames";

const prefix = "a-hr";

const Hr = ({ className, content }) => {
  let classForComponent = classNames(prefix, {
    [className]: className
  });

  return(
    <div className={classForComponent}>
      <span>{content}</span>
    </div>
  )
};

export default Hr;
