import { useTranslation } from "react-i18next";
import useModal from "_hooks/useModal";
import { Button, NubeskIcons } from "components/atoms";
import { Semaphore } from "components/molecules";

const Processing = ({
  prefix,
  current,
  total,
  itemCurrent,
  itemTotal,
  onCancel,
  step
}) => {
  const { closeModal } = useModal();
  const { t } = useTranslation();

  return(
    <>
      <div className={`${prefix}__processing`}>
        <p>Por favor, espera aquí hasta que el proceso haya finalizado</p>
        
        <div className="--loading">
          <div>
            <NubeskIcons icon="spin" className="u-spin" size="5rem" />
          </div>
          <div>
            <p className="u-text-md">Agregando GTINs ({step} de 2)</p>
            <div className="--percent">
              <div>{(!!itemCurrent && !!itemTotal) && <>{((itemCurrent / itemTotal) * 100).toFixed(0)}%</> }</div>
              <div className="u-text-sm">{itemCurrent}/{itemTotal}</div>
            </div>
            <Semaphore current={current} steps={total} />
          </div>
        </div>
      </div>
      <div className={`${prefix}__buttons`}>
        <Button
          content={t("common:form.cancel")}
          onClick={() => {
            onCancel();
            closeModal();
          }}
          type="underline"
        />
        <span></span>
      </div>
    </>
  );
};

export default Processing;
