import { reduxActions as PRODUCT } from "_constants/product.constants";

const canvasEditor = {
  [PRODUCT.CANVAS_EDITOR_TOGGLE]: (state, { payload }) => ({
    ...state,
    canvasEditor: {
      ...state.canvasEditor,
      visible: payload
    }
  }),

  [PRODUCT.CANVAS_EDITOR_DATA]: (state, { payload }) => ({
    ...state,
    canvasEditor: {
      ...state.canvasEditor,
      data: payload
    }
  })
};


export default canvasEditor;
