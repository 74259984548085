/**
 * 
 * @param {Array} files 
 * @param {String} pattern 
 * @returns 
 */
const sortImageFiles = (files, pattern = '-') => {
  files.sort((a, b) => {
    let splitA = a.name.split('.');
    let splitB = b.name.split('.');
    let output = 0;

    if (splitA.length < 2) return 0;
    if (splitB.length < 2) return 0;
    splitA.pop();
    splitB.pop();
    
    let elementA = splitA.join('.')
      .replace(/[^a-zA-Z0-9À-ž]/g, pattern)
      .split(pattern)
      .map((row) => row.toLowerCase());

    let elementB = splitB.join('.')
      .replace(/[^a-zA-Z0-9À-ž]/g, pattern)
      .split(pattern)
      .map((row) => row.toLowerCase());

    let maxLength = Math.max(elementA.length, elementB.length)

    for (let i = 0; i < maxLength; i++) {
      if (elementA[i] === undefined && elementB[i] === undefined) {
       output = 0;
       break;
      }

      if (elementA[i] === undefined) {
        output = 1;
        break;
      } 

      if (elementB[i] === undefined) {
        output = -1;
        break;
      }

      if (!isNaN(elementA[i])) elementA[i] = parseInt(elementA[i]);
      if (!isNaN(elementB[i])) elementB[i] = parseInt(elementB[i]);

      if (elementA[i] < elementB[i]) {
        output = -1;
        break;
      }

      if (elementA[i] > elementB[i]) {
        output = 1;
        break;
      }

      output = 0;
    }

    return output;
  });
  
  return files;
};

export default sortImageFiles;
