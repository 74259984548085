import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import moment from "moment";
import { Header, Sidebar } from "components/organism";
import { Toolbar, Modal } from "components/templates";
import { selectFilterHeight, selectFilterIsOpen } from "_redux/ui/ui.selector";
import { useProductFilter, useToolbar,useUser } from "_hooks";
import { selectReaderOneStore } from "_redux/reader/reader.selector";
import { closeLoader, resetSelection } from "_redux/ui/ui.actions";

const prefix = "t-user-layout";

const UserLayout = ({ children }) => {
  const readInfo = useSelector(selectReaderOneStore);
  const { filters, getTags } = useProductFilter();
  const dispatch = useDispatch();
  const { toolbar } = useToolbar();
  const [menuFixed, setMenuFixed] = useState(false);
  const [array, setArray] = useState([]);
  const isOpen = useSelector(selectFilterIsOpen);
  const height = useSelector(selectFilterHeight);
  const { user } = useUser();

  const [currentView, setCurrentView] = useState("");
  const classForContent = classNames(`${prefix}__content`, {
    "filter-open": isOpen,
    "has-filter":
      array.length ||
      readInfo?.data?.found?.length ||
      Object.keys(filters?.data || {}).length ||
      Object.keys(filters?.folderId || {}).length,
    "menu-fixed": menuFixed,
    [`${prefix}__content--${currentView}`]: currentView,
  });

  useEffect(() => {
    setCurrentView(toolbar);
    dispatch(resetSelection());
  }, [toolbar]);

  useEffect(() => {
    const assingTags = async () => {
      let tags = await getTags();
      setArray(tags);
    };

    assingTags();
  }, [filters]);

  useEffect(() => {
    dispatch(closeLoader());

    if (user?.email && user?.createdAt) {
      window.Intercom("boot", {
        api_base: "https://api-iam.intercom.io",
        app_id: "fptaenb9",
        name: `${user?.account?.firstName} ${user?.account?.lastName}`,
        email: user.email,
        created_at: moment(user?.createdAt).valueOf(),
      });
      window.Intercom("update");
    }
  }, []);

  return (
    <div className={prefix} style={{ "--tabs-height": `${height}px` }}>
      <Header />
      
      <Sidebar 
        menuFixed={menuFixed}
        setMenuFixed={setMenuFixed}
      />
      
      <div className={classForContent}>
        <Modal />
        <Toolbar />
        {children}
      </div>
    </div>
  );
};

export default UserLayout;

UserLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
