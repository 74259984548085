import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { convertToMb } from "_utils/string/convertToMb";
const prefix = "m-photo-label";
const PhotoLabel = ({ title, data }) => {
  const classForComponent = classNames(prefix, {
    "is-color": title.toLowerCase().includes("color"),
  });
  const [_data, setData] = useState(data);

  useEffect(() => {
    setData(data);
    if (title === "Size") {
      const mb = convertToMb(data);
      setData(`${mb} MB`);
    }
  }, [data]);

  return (
    <div className={classForComponent}>
      {title.toLowerCase().includes("color") ? null : (
        <>
          {" "}
          <span className={`${prefix}__title`}>{title}</span>
          <span className={`${prefix}__data`}>{_data}</span>
        </>
      )}
    </div>
  );
};

export default PhotoLabel;
