import React from "react";
import { ReactComponent as NLogo } from "./Logo.svg";

const Logo = ({color}) => <NLogo 
    className="a-logo"
    style={{
      color: (color ? color : "var(--color-purple)")
    }} 
  />;

export default Logo;
