import initialProductState from "./product.initialState";
import aiAnalysis from "./reducers/aiAnalysis";
import all from "./reducers/all";
import allList from "./reducers/allList";
import analyze from "./reducers/analyze";
import analizeId from "./reducers/analizeId";
import count from "./reducers/count";
import countByField from "./reducers/countByField";
import countPiece from "./reducers/countPiece";
import details from "./reducers/details";
import downloadXls from "./reducers/downloadXls";
import canvasEditor from "./reducers/canvasEditor";
import get from "./reducers/get";
import one from "./reducers/one";
import post from "./reducers/post";
import put from "./reducers/put";
import putCopy from "./reducers/putCopy";
import saveLayoutData from "./reducers/saveLayoutData";
import setCounters from "./reducers/setCounters";
import updateAll from "./reducers/updateAll";

const reducer = (state = initialProductState, action) => {
  const reducers = {
    ...aiAnalysis,
    ...all,
    ...allList,
    ...analyze,
    ...analizeId,
    ...canvasEditor,
    ...count,
    ...countByField,
    ...countPiece,
    ...details,
    ...downloadXls,
    ...get,
    ...one,
    ...post,
    ...put,
    ...putCopy,
    ...saveLayoutData,
    ...setCounters,
    ...updateAll
  };
  
  let { type } = action;
  if (reducers[type] === undefined) return state;

  return reducers[type](state, action);
};

export default reducer;
