import { reduxActions as BILLING } from "_constants/billing.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialBillingState from "../billing.initialState";

const watermarks = {
  [BILLING.WATERMARKS_STARTED]: (state) => ({
    ...state,
    watermarks: {
      ...state.watermarks,
      status: LOADING
    }
  }),

  [BILLING.WATERMARKS_FAIL]: (state, { payload }) => ({
    ...state,
    watermarks: {
      ...state.watermarks,
      error: payload?.error,
      status: FAILURE
    }
  }),

  [BILLING.WATERMARKS_SUCCESS]: (state, { payload }) => ({
    ...state,
    watermarks: {
      ...state.watermarks,
      data: payload?.data?.[0],
      status: SUCCESS
    }
  }),

  [BILLING.WATERMARKS_RESET]: (state) => ({
    ...state,
    watermarks: initialBillingState.watermarks
  })
};

export default watermarks;
