import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Paragraph = ({ content, className }) => {
  const classForComponent = classNames("a-paragraph", {
    [className]: className,
  });
  return <p className={classForComponent}>{content}</p>;
};

export default Paragraph;

Paragraph.propTypes = {
  className: PropTypes.string,
};
