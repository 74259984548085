import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useHistory, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchAllList,
  find as getProducts,
  count as countProducts
} from "_redux/product/product.actions";
import { toggleRefresh } from "_redux/ui/ui.actions";
import { findOne as findTask } from "_redux/task/task.actions";
import {
  selectProductAll,
  selectProductAllStatus,
  selectProductCountPiece,
} from "_redux/product/product.selector";
import {
  selectAppAdaptationsStatus,
  selectAppPropertyColumns,
  selectAppPropertyColumnsStatus,
} from "_redux/app/app.selector";
import selectUiStore, { selectRefresh } from "_redux/ui/ui.selector";
import { selectCatalogOneStore } from "_redux/catalog/catalog.selector";
import { usePagination, useProductFilter, useSort } from "./";
import urlToJson from "_utils/url/urlToJson";
import jsonToUrl from "_utils/url/jsonToUrl";
import { FAILURE, IDLE, LOADING } from "_constants/redux.constants";

const useProductQuery = (primary = false, initStatus = true) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const { catalogId, key } = useParams();

  const { filters, filterFromUrl, filterToW, setInit } = useProductFilter(true);
  const { changePage, page, pageToA, stackSize } = usePagination();
  const { changeSort, sortToA, sortBy, order } = useSort();

  const appPropertyColumns = useSelector(selectAppPropertyColumns);
  const appPropertyColumnsStatus = useSelector(selectAppPropertyColumnsStatus);

  const appAdaptationsStatus = useSelector(selectAppAdaptationsStatus);
  const productPage = useSelector(selectProductAll);
  const productPageStatus = useSelector(selectProductAllStatus);
  const productsCount = useSelector(selectProductCountPiece);
  const { data: catalog } = useSelector(selectCatalogOneStore);
  const { 
    products: refreshProducts, 
    productsData: refreshProductsData 
  } = useSelector(selectRefresh);
  const { selections } = useSelector(selectUiStore);

  const [inited, setInited] = useState(false);
  const [loading, setLoading] = useState();
  const [products, setProducts] = useState([]);

  const getFromQuery = async () => {
    /** q = conditions, p = pagination, s = sorting */
    let lFilters = await filterFromUrl();
    if (!lFilters || !Object.keys(lFilters).length) lFilters = undefined;
    
    let w =  filterToW(lFilters);
    let a = pageToA();
    let sort = sortToA();
    
    let query = {};
    if (w !== undefined) query.w = w;
    if (a !== undefined) {
      query.a = {
        page: {
          ...a.page,
          step: a?.page?.step ?? 1
        }
      };
    }
    
    if (sort !== undefined) {
      query.a = {
        ...(query?.a ?? {}),
        ...sort
      };
    } else {
      query.a = {
        ...(query?.a ?? {}),
        sort: {
          by:{
            createdAt: -1
          }
        }
      };
    }
    let match = matchPath(location.pathname, {
      path: "/products/catalog/:catalogId",
      strict: true
    });

    if (match?.params?.catalogId && !query?.w?._id?.length && catalog?.foldersId) {
      query = {
        ...query ?? {},
        w: {
          ...query.w ?? {},
          _id: { $in: catalog?.foldersId?.map((r) => r._id)}
        }
      }
    } else {
      let taskFound = await urlToJson(location.search, true);
      
      if (taskFound?.from?.taskId) {
        let response = await dispatch(findTask({
          f: ["foldersId", "key", "type"],
          w: { key: taskFound?.from?.taskId }
        }));
        
        if (response?.data?.[0]?.foldersId?.length) {
          query = {
            w: {
              _id: { $in: response.data[0].foldersId }
            }
          };
          console.log(query);
        }
      }
    }
    
    return query;
  };

  const initialize = () => initStatus = true;

  const fetch = async () => {
    if (initStatus !== true || productPageStatus === LOADING) return;

    let queryString = await getFromQuery();
    let longRequest = false;
    
    if (!products.length && queryString?.a?.page?.step > 1) {
      queryString.a.page = {
        skip: stackSize * page,
        step: 1
      };
    }

    queryString.a = {
      ...queryString?.a ?? {},
      extended: {
        ...queryString?.a?.extended ?? {},
        f: {
          ...queryString?.a?.extended?.f ?? {},
          filesId: [
            ...queryString?.a?.extended?.f?.filesId ?? [],
            ...[
              "adaptationId",
              "fileMasterId",
              "baseKeyS3Carousel",
              "baseKeyS3Thumbnail",
            ]
          ],
        },
      }
    }
    let queryStringGrid = {...queryString};
    
    if (queryString?.w?._id?.$in && queryString.w._id.$in?.length > 500) {
      longRequest = true;
      queryStringGrid.w._id.$in = queryStringGrid.w._id.$in?.slice(0, 500);
    }
    dispatch(getProducts(queryString, {
      feedback: {
        error: {
          area: t("products:filters.feedback.fetch.area"),
          info: t("products:filters.feedback.fetch.error")
        },
        success: false
      }
    }));
    
    if (queryString.a.page.step === 1) {
      await dispatch(countProducts({}));
      if (Object.keys(queryString?.w ?? {}).length > 0 && !longRequest) {
        dispatch(countProducts({ query: queryString?.w ?? {}, partial: true }));
      }
    }
  };

  const nextPage = () => {
    if (productPageStatus === LOADING) return;

    if (productsCount > stackSize * page) {
      changePage(page + 1, stackSize, true);
    }
  };

  const itemsSelected = async () => {
    let arraySkus = Object.keys(selections.selected);
    if (selections.all) {
      let { w } = await getFromQuery(true);
      let skus = await dispatch(fetchAllList({ w: w ?? {}}));
      arraySkus = skus.data
        .map((item) => item._id)
        .filter((item) => !selections.selected[item]);
    }
    return arraySkus;
  };

  const updateRows = (data) => {
    let newProducts = [...products];
    if (!Array.isArray(data)) data = [data];

    for (let r of data ) {
      for (let i in newProducts) {
        if (newProducts[i]._id === r._id) {
          newProducts[i] = {
            ...newProducts[i],
            ...r
          }
        }
      }
    }

    setProducts(newProducts);
  };

  useEffect(() => {
    if (!primary) return;
    let assignParams = async () => {

      let replace = false;
      let json = await urlToJson(location.search);
      
      if (!json?.p?.step) {
        replace = true;
        let { p } = await changePage(1, stackSize, false);
        json.p = p;
      } else if (!json?.p.step === 1 && products.length) {
        setProducts([]);
      }

      if (!json?.s?.sortBy) {
        let { s } = await changeSort("createdAt", -1, false);
        json.s = s;
      }

      let encode = await jsonToUrl(json);

      if (replace) {
        history.replace({
          pathname: location.pathname,
          search: encode
        });
      }
    }
    
    if (key || catalogId) return;
    assignParams();
  }, [location.search, location.pathname]);

  useEffect(() => {
    if (!primary) return;
    if (page === undefined) return;
    if (page === 1 && products?.length) setProducts([]);
    if ((catalogId && !catalog) || key) return;
    
    setLoading(true);
  }, [page]);

  useEffect(() => {
    if (!primary) return;
    if ((catalogId && !catalog) || key) return;
    if (sortBy === undefined || order === undefined) return;
    if (!Number.isInteger(page)) return;
    
    if (page === 1) {
      if (products?.length) setProducts([]);
      setLoading(true);
    } else {
      changePage(1, stackSize, true);
    }    
    
  }, [sortBy, order]);

  useEffect(() => {
    if (!primary) return;
    if ((catalogId && !catalog) || key) return;
    if (filters === undefined) return;
    if (!Number.isInteger(page)) return;
    
    if (page === 1) {
      if (products?.length) setProducts([]);
      setLoading(true);
    } else {
      changePage(1, stackSize, true);
    }    
    
  }, [filters]);
  
  /* On change catalogId at pathname */
  useEffect(() => {
    if (!primary) return;
    if (key) return;
    if (products?.length && !catalog) {
      setProducts([]);
    }
  }, [catalogId]);

  /* On change catalog */
  useEffect(() => {
    if (!primary) return;
    if (loading) return;
    if (key && !catalog?._id) return;
    // if (!Number.isInteger(page)) return;
    
    if (page !== 1) {
      changePage(1, stackSize, true);
    } else if (!loading) {
      setLoading(true);
    }
  }, [catalog?._id]);

  useEffect(() => {
    if (!primary) return;
    if (productPage !== null || productPageStatus === FAILURE) {
      setTimeout(() => {
        setLoading(false);
        if (!inited) setInited(true);
      }, 500);
    }
  }, [productPage]);

  useEffect(() => {
    if (!primary) return;
    if (productPageStatus === FAILURE) return setLoading(false);
  }, [productPageStatus])

  useEffect(() => {
    if (!primary) return;
    if (key) {
      if (loading === true) setLoading(false)
      return;
    }

    if (loading === true) {
      fetch();
    } else if (loading === false && Array.isArray(productPage)) {
      setProducts([...products, ...productPage]);
    }
  }, [loading]);

  useEffect(() => {
    if (!primary) return;
    if (
      [IDLE, LOADING].indexOf(appAdaptationsStatus) === -1 &&
      [IDLE, LOADING].indexOf(appPropertyColumnsStatus) === -1
    ) {
      setInit({ data: appPropertyColumns });
    }
  }, [appAdaptationsStatus, appPropertyColumnsStatus]);

  /** When refresh is needed */
  useEffect(() => {
    if (!primary) return;
    if (refreshProducts) {
      if (!loading) {
        if (refreshProductsData) {
          updateRows(refreshProductsData);
        } else {
          setProducts([]);
          setLoading(true);
        }
      }
      dispatch(toggleRefresh("products"));
    }
  }, [refreshProducts]);

  return {
    getFromQuery,
    inited,
    initialize,
    itemsSelected,
    loading,
    nextPage,
    products,
    productsCount,
    stackSize,
    updateRows
  };
};

export default useProductQuery;
