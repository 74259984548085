import { canDoIt, isA } from '_utils/policies';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as USER } from "_constants/user.constants";

const loggedin = {
  [USER.IS_LOGGED_IN_STARTED]: (state, { payload }) => ({
    ...state,
    own: {
      ...state.own,
      status: LOADING,
      data: payload?.data ?? null
    }
  }),
    
  [USER.IS_LOGGED_IN_SUCCESS]: (state, { payload }) => {
    let user = payload?.data?.[0] ?? null;
    if (user !== null) {
      Object.defineProperties(user.__proto__, {
        'canDoIt': {
          value: canDoIt
        },
        'isA': {
          value: isA
        }
      });
    }

    return {
      ...state,
      own: {
        ...state.own,
        status: SUCCESS,
        data: user ?? null
      }
    };
  },

  [USER.IS_LOGGED_IN_FAIL]: (state, { payload }) => ({
    ...state,
    own: {
      ...state.own,
      error: payload?.error ?? null,
      status: FAILURE
    }
  })
};

export default loggedin;
