import initialAdaptationState from '../adaptation.initialState';
import { reduxActions as ADAPTATION } from '_constants/adaptation.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const create = {
  [ADAPTATION.CREATE_STARTED]: (state) => ({
    ...state,
    create: {
      ...state.create,
      status: LOADING
    }
  }),

  [ADAPTATION.CREATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    create: {
      ...state.create,
      data: payload.data,
      status: SUCCESS
    },
    onList: {
      ...state.onList,
      data: [...state?.onList?.data ?? [], ...payload?.data ?? []]
    }
  }),

  [ADAPTATION.CREATE_FAIL]: (state, { payload }) => ({
    ...state,
    create: {
      ...state.create,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [ADAPTATION.CREATE_RESET]: (state, ) => ({
    ...state,
    create: initialAdaptationState
  })
};

export default create;
