import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button } from "components/atoms";
import { UploadDrop } from "components/organism";
import { sortImageFiles } from "_utils/upload";
import { NubeskIcons } from "components/atoms/index";
import Uploading from "../UploadBulkImages/Uploading";
import { toggleRefresh } from "_redux/ui/ui.actions";
import { useDispatch } from "react-redux";

const prefix = "o-upload-images";

const UploadImagesModal = ({ product, adaptationId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [file, setFile] = useState([]);
  const [showingFiles, setShowingFiles] = useState([]);
  const [stack, setStack] = useState(false);
  const [showDropArea, setShowDropArea] = useState(true);
  const [upload, setUpload] = useState(false);
  const [step, setStep] = useState({ page: 0, space: 0 });
  const classForComponent = classNames(prefix, { uploading: upload });

 
  const onChangeFilesHandler = (files) => {
    files = sortImageFiles(files);
    setFile(files);
  };

  useEffect(() => {
    if (!showingFiles.length && file.length) {
      setStep({ ...step, page: step.page - 1 });
    }
  }, [showingFiles]);

  useEffect(() => {
    setShowingFiles(
      file.slice(step.page * step.space, (step.page + 1) * step.space)
    );
  }, [step]);

  useEffect(() => {
    if (file.length) {
      setStep((previous) => ({ ...previous, space: 12 }));
    }
  }, [file]);

  useEffect(() => {
    if (file?.length) {
      return setShowDropArea(false);
    }
    setShowingFiles([]);
    return setShowDropArea(true);
  }, [file]);

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__header`}></div>
      <div className={`${prefix}__content`}>
        {showDropArea && (
          <UploadDrop
            file={file}
            setFile={onChangeFilesHandler}
            label={"modals:uploadBulk.hintFiles"}
            dropProps={{ accept: "image/png, image/jpeg, image/tiff, .jpg, .jpeg, .png,.tif, .tiff" }}
          />
        )}

        {!stack && showingFiles.length ? (
          <div className={`${prefix}__thumb-container`}>
            {showingFiles.map((_file, index) => {
              return (
                <div className={`${prefix}__thumb`}>
                  <img
                    src={URL.createObjectURL(_file)}
                    onLoad={() => {
                      URL.revokeObjectURL(_file);
                    }}
                  />
                  {/* <span>{_file.path}</span> */}
                  <Button
                    content={<NubeskIcons icon="times" />}
                    className={`${prefix}__thumb-btn`}
                    type={"color"}
                    onClick={() =>
                      setFile([
                        ...file.filter(
                          (item, i) => i !== step.page * step.space + index
                        ),
                      ])
                    }
                  />
                </div>
              );
            })}
          </div>
        ) : null}
        {stack ? (
          <Uploading
            data={file}
            imageQty={file.length}
            foreignOptions={{ folderId: product._id, adaptationId }}
            onFinish={(data) => dispatch(toggleRefresh("products", ({
              _id: product._id,
              filesId: [
                ...product.filesId,
                ...data
              ]
            })))}
          />
        ) : null}
      </div>
      {file.length && !stack ? (
        <div className={`${prefix}__footer`}>
          <Button
            style={stack ? { visibility: "hidden" } : null}
            content={t("common:form.cancel")}
            type="underline"
            sm
            onClick={() => {
              setFile([]);
              setStack(false);
            }}
          />
          <div
            className={`${prefix}__next-previous`}
            style={upload ? { visibility: "hidden" } : null}
          >
            <Button
              style={!step.page ? { visibility: "hidden" } : null}
              content={"<"}
              type={"color"}
              onClick={() => setStep({ ...step, page: step.page - 1 })}
            />
            <Button
              style={
                file.length > step.space &&
                file.length / step.space > step.page + 1
                  ? null
                  : { visibility: "hidden" }
              }
              content={">"}
              type={"color"}
              onClick={() => setStep({ ...step, page: step.page + 1 })}
            />
          </div>
          <Button
            content={t("common:form.continue")}
            type="purple"
            sm
            onClick={() => {
              setStack(true); 
            }}
          />
        </div>
      ) : null}
    </div>
  );
};

UploadImagesModal.defaultProps = {
  onFinish: () => {},
};

export default UploadImagesModal;
