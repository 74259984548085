import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const FlexComponent = ({
  children,
  className,
  isSidebar,
  isVertical,
  sm,
  xs,
  gap,
  ...props
}, ref) => {
  const classForComponent = classNames("t-flex-component", {
    "is-vertical": isVertical,
    "is-sidebar": isSidebar,
    "is-sm": sm,
    "is-xs": xs,
    [className]: className,
  });

  return <div 
    className={classForComponent}
    ref={ref}
    style={{
      gap: gap,
      ...props.style ?? {}
    }}
    {...props}
    >
      {children}
    </div>;
};

FlexComponent.propTypes = {
  className: PropTypes.string,
  isSidebar: PropTypes.bool,
  isVertical: PropTypes.bool,
  sm: PropTypes.bool,
  xs: PropTypes.bool
};

export default React.forwardRef(FlexComponent);

