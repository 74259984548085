import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const modules = {
  [APP.MODULES_STARTED]: (state) => ({
    ...state,
    modules: {
      ...state.modules,
      status: LOADING
    }
  }),
  
  [APP.MODULES_SUCCESS]: (state, { payload }) => ({
    ...state,
    modules: {
      ...state.modules,
      data: payload?.data?.[0]?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),

  [APP.MODULES_FAIL]: (state, { payload }) => ({
    ...state,
    modules: {
      ...state.modules,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [APP.MODULES_SET]: (state, { payload }) => ({
    ...state,
    modules: {
      ...state.modules,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),
};

export default modules;
