import React from "react";
import capitalize from "_utils/string/capitalize";
const prefix = "m-input-label";

const InputLabel = ({ label, children, required, ...props }) => {
  return (
    <div className={prefix} {...props}>
      <span className={`${prefix}__label`}>
        {capitalize(label)}{" "}
        {required ? (
          <span className={`${prefix}__require`}>({required})</span>
        ) : null}
      </span>
      {children}
    </div>
  );
};

export default InputLabel;
