import { reduxActions as LAYOUT } from "_constants/layout.constants";
import { API } from "_constants/request.constants";

/**
 * 
 * @param { Object } params 
 * @param { Object } extras 
 * @returns 
 */
export const find = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      LAYOUT.ALL_STARTED,
      LAYOUT.ALL_SUCCESS,
      LAYOUT.ALL_FAIL
    ];
  }
  
  return dispatch({
    [API]: {
      endpoint: "/setting/layout",
      reqType: "GET",
      ...extras,
      params
    }
  });
};

/**
 * 
 * @param { Object } data 
 * @param { Object } extras 
 * @returns 
 */
export const post = (data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      LAYOUT.POST_STARTED,
      LAYOUT.POST_SUCCESS,
      LAYOUT.POST_FAIL
    ];
  }

  return dispatch({
    [API]: {
      endpoint: "/setting/layout",
      reqType: "POST",
      ...extras,
      data
    }
  });
};

export default {
  find,
  post
};
