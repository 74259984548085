import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as RESOURCE } from "_constants/resource.constants";
import initialResourceState from "../resource.initialState";

const post = {
  [RESOURCE.POST_STARTED]: (state) => ({
    ...state,
    post: {
      ...state.post,
      status: LOADING
    }
  }),
  [RESOURCE.POST_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [RESOURCE.POST_SUCCESS]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [RESOURCE.POST_RESET]: (state) => ({
    ...state,
    post: initialResourceState.post
  })
};

export default post;
