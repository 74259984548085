import { createSelector } from 'reselect';

export const selectAdaptationStore = (state) => state.adaptation;

export const selectAdaptationAllStore = createSelector(
  selectAdaptationStore,
  (adaptation) => adaptation.all
);

export const selectAdaptationAll = createSelector(
  selectAdaptationAllStore,
  (all) => all.data
);

export const selectAdaptationAllError = createSelector(
  selectAdaptationAllStore,
  (all) => all.error
);

export const selectAdaptationAllStatus = createSelector(
  selectAdaptationAllStore,
  (all) => all.status
);

export const selectAdaptationOneStore = createSelector(
  selectAdaptationStore,
  (adaptation) => adaptation.one
);

export const selectAdaptationOne = createSelector(
  selectAdaptationOneStore,
  (one) => one.data
);

export const selectAdaptationOneError = createSelector(
  selectAdaptationOneStore,
  (one) => one.error
);

export const selectAdaptationOneStatus = createSelector(
  selectAdaptationOneStore,
  (one) => one.status
);

export const selectAdaptationPostStore = createSelector(
  selectAdaptationStore,
  (adaptation) => adaptation.post
);

export const selectAdaptationOnList = createSelector(
  selectAdaptationStore,
  (adaptation) => adaptation.onList
);

export const selectAdaptationCreate = createSelector(
  selectAdaptationStore,
  (adaptation) => adaptation.create
);

export const selectAdaptationMasters = createSelector(
  selectAdaptationStore,
  (adaptation) => adaptation.masters
);

export const selectAdaptationWmCanvasEditor = createSelector(
  selectAdaptationStore,
  (adaptation) => adaptation.wmCanvasEditor
);

export default selectAdaptationStore;
