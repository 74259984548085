import { IDLE } from "_constants/redux.constants";

const initialAdaptationState = {
  all: {
    data: null,
    error: null,
    status: IDLE
  },
  wmCanvasEditor: {
    visible: false,
    data: null
  },
  create: {
    data: null,
    error: null,
    status: IDLE
  },
  masters: {
    data: null,
    error: null,
    status: IDLE
  },
  onList: {
    data: null,
    error: null,
    status: IDLE
  },
  one: {
    data: null,
    error: null,
    status: IDLE
  },
  put: {
    data: null,
    error: null,
    status: IDLE
  },
  post: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialAdaptationState;
