import { FlexComponent } from "components/templates";
import React from "react";
const prefix = "m-property-channel";
const Property = ({ content, title, ...props }) => {
  return (
    <div className={prefix} {...props}>
      <FlexComponent>
        <span className={`${prefix}__title`}>{title}</span>
      </FlexComponent>
      {content}
    </div>
  );
};

export default Property;
