import { reduxActions as UI } from "_constants/ui.constants";
import { closePanelLeft } from "./ui_left-panel.actions";
import { returnPreviousToolbar, toolbarSelection } from "./ui_toolbar.actions";

export const addSelection = (id) => async (dispatch, getState) => {
  const {
    ui: { leftPanel }
  } = getState();
  
  /* if (leftPanel?.isOpen) {
    dispatch(closePanelLeft());
  } */

  return dispatch({
    type: UI.ADD_SELECTION,
    payload: id,
  });;
};

export const toggleSelected = () => async (dispatch) => 
  dispatch({ type: UI.TOGGLE_SELECTION });


export const resetSelection = () => (dispatch) =>
  dispatch({ type: UI.RESET_SELECTION });
