/**
 * IMPORTANT!
 * 
 * Definition of permission constant must start with "$" char.
 * Constants set must start with "$$" char (double).
 */
export const $ADAPTATION_CREATE = "adaptation:create";
export const $ADAPTATION_READ = "adaptation:read";
export const $ADAPTATION_EDIT = "adaptation:edit";
export const $ADAPTATION_DELETE = "adaptation:delete";
export const $$ADAPTATION = {
  CREATE: $ADAPTATION_CREATE,
  READ: $ADAPTATION_READ,
  EDIT: $ADAPTATION_EDIT,
  DELETE: $ADAPTATION_DELETE
};

export const $ADMIN_LOGIN_AS = "admin:loginAs";
export const $ADMIN_BILLING = "admin:billing";
export const $$ADMIN = {
  LOGIN_AS: $ADMIN_LOGIN_AS,
  BILLING: $ADMIN_BILLING
};

export const $CONTAINER_READ = "container:read";
export const $CONTAINER_CREATE = "container:create";
export const $CONTAINER_DELETE = "container:delete";
export const $CONTAINER_UPDATE = "container:update";

export const $CSV_CREATE = "csv:create";
export const $CSV_READ = "csv:read";
export const $CSV_DELETE = "csv:delete";
export const $$CSV = {
  CREATE: $CSV_CREATE,
  READ: $CSV_READ,
  DELETE: $CSV_DELETE
};

export const $DEPARTMENT_CREATE = "department:create";
export const $DEPARTMENT_READ = "department:read";
export const $DEPARTMENT_EDIT = "department:edit";
export const $DEPARTMENT_DELETE = "department:delete";

export const $ENTITY_CREATE = "entity:create";
export const $ENTITY_READ = "entity:read";
export const $ENTITY_EDIT = "entity:edit";
export const $ENTITY_DELETE = "entity:delete";

export const $FILE_CREATE = "file:create";
export const $FILE_READ = "file:read";
export const $FILE_EDIT = "file:edit";
export const $FILE_DELETE = "file:delete";
export const $FILE_BACKREMOVAL = "file:backremoval";
export const $FILE_UP = "file:up";
export const $$FILE = {
  CREATE: $FILE_CREATE,
  READ: $FILE_READ,
  EDIT: $FILE_EDIT,
  DELETE: $FILE_DELETE,
  BACKREMOVAL: $FILE_BACKREMOVAL,
  UP: $FILE_UP
};

export const $FOLDER_CREATE = "folder:create";
export const $FOLDER_READ = "folder:read";
export const $FOLDER_EDIT = "folder:edit";
export const $FOLDER_DELETE = "folder:delete";
export const $FOLDER_APPROVE_INFO = "folder:approveInfo";
export const $FOLDER_APPROVE_MASTER = "folder:approveMaster";
export const $FOLDER_UPLOAD = "folder:upload";
export const $$FOLDER = {
  CREATE: $FOLDER_CREATE,
  READ: $FOLDER_READ,
  EDIT: $FOLDER_EDIT,
  DELETE: $FOLDER_DELETE,
  APPROVE_INFO: $FOLDER_APPROVE_INFO,
  APPROVE_MASTER: $FOLDER_APPROVE_MASTER,
  UPLOAD: $FOLDER_UPLOAD
};

export const $CATALOG_CREATE = "catalog:create";
export const $CATALOG_UPDATE = "catalog:update";
export const $CATALOG_DELETE = "catalog:delete";
export const $$CATALOG = {
  CREATE: $CATALOG_CREATE,
  DELETE: $CATALOG_DELETE,
  UPDATE: $CATALOG_UPDATE
};

export const $XLS_CREATE = "xlsUpload:create";
export const $XLS_UPDATE = "xlsUpload:update";

export const $IMAGE_ADAPTATION = "image:adaptation";

export const $INVENTORY_CREATE = "inventory:create";
export const $INVENTORY_READ = "inventory:read";
export const $INVENTORY_EDIT = "inventory:edit";
export const $INVENTORY_DELETE = "inventory:delete";

export const $LAYER_CREATE = "layer:create";
export const $LAYER_READ = "layer:read";
export const $LAYER_EDIT = "layer:edit";
export const $LAYER_DELETE = "layer:delete";

export const $LINK_CREATE = "link:create";
export const $LINK_READ = "link:read";
export const $LINK_DELETE = "link:delete";
export const $LINK_DOWNLOAD = "link:download";
export const $$LINK = {
  CREATE: $LINK_CREATE,
  READ: $LINK_READ,
  DELETE: $LINK_DELETE,
  DOWNLOAD: $LINK_DOWNLOAD
};

export const $MIGRATE_TO_NBSK = "migrate:toNbsk";

export const $REGION_CREATE = "region:create";
export const $REGION_READ = "region:read";
export const $REGION_EDIT = "region:edit";
export const $REGION_DELETE = "region:delete";

export const $REQUEST_LOAN_READ = "requestLoan:read";
export const $REQUEST_LOAN_CREATE = "requestLoan:create";
export const $REQUEST_LOAN_UPDATE = "requestLoan:update";
export const $REQUEST_LOAN_DELETE = "requestLoan:delete";

export const $REQUEST_GIFT_READ = "requestGift:read";
export const $REQUEST_GIFT_CREATE = "requestGift:create";
export const $REQUEST_GIFT_UPDATE = "requestGift:update";
export const $REQUEST_GIFT_DELETE = "requestGift:delete";

export const $SESSION_LOGIN = "session:login";
export const $SESSION_API = "session:api";
export const $SESSION_LOGOUT = "session:logout";
export const $$SESION = {
  LOGIN: $SESSION_LOGIN,
  API: $SESSION_API,
  LOGOUT: $SESSION_LOGOUT
};

export const $SETTING_READ = "setting:read";
export const $SETTING_PROP_COL_READ = "setting:propColRead";
export const $SETTING_PROP_COL_UPDATE = "setting:propColUpdate";
export const $$SETTING = {
  READ: $SETTING_READ,
  PROP_COL_READ: $SETTING_PROP_COL_READ,
  PROP_COL_UPDATE: $SETTING_PROP_COL_UPDATE
};

export const $TAG_READ = "tag:read";
export const $TAG_DELETE = "tag:delete";
export const $TAG_A_RECEIVE = "tag:aReceive";
export const $TAG_A_DELIVER = "tag:aDeliver";
export const $TAG_A_MOVE = "tag:aMove";
export const $TAG_A_REMOVE = "tag:aRemove";
export const $TAG_A_LOAN = "tag:aLoan";
export const $TAG_A_GIFT = "tag:aGift";

export const $TRACKLOG_READ = "tracklog:read";

export const $USER_CREATE = "user:create";
export const $USER_READ = "user:read";
export const $USER_EDIT = "user:edit";
export const $USER_DELETE = "user:delete";
export const $USER_EDIT_PASS = "user:editPass";
export const $USER_READ_ONE = "user:readOne";
export const $USER_EDIT_ONE = "user:editOne";
export const $USER_DELETE_ONE = "user:deleteOne";
export const $USER_EDIT_PASS_ONE = "user:editPassOne";
export const $$USER = {
  CREATE: $USER_CREATE,
  READ: $USER_READ,
  EDIT: $USER_EDIT,
  DELETE: $USER_DELETE,
  EDIT_PASS: $USER_EDIT_PASS,
  READ_ONE: $USER_READ_ONE,
  EDIT_ONE: $USER_EDIT_ONE,
  DELETE_ONE: $USER_DELETE_ONE,
  EDIT_PASS_ONE: $USER_EDIT_PASS_ONE
};

export const $VARIANT_CREATE = "variant:create";
export const $VARIANT_READ = "variant:read";
export const $VARIANT_EDIT = "variant:edit";
export const $VARIANT_DELETE = "variant:delete";
export const $VARIANT_UPLOAD = "variant:upload";



