import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import classNames from "classnames";
import { Button } from "components/atoms";
import { UploadDrop } from "components/organism";
import CompleteUpload from "components/organism/Upload/CompleteUpload";
import { Toast } from "components/molecules";
import {
  resetPost,
  upload as uploadFile,
} from "_redux/product/product.actions";
import selectProductStore from "_redux/product/product.selector";
import UploadingFile from "components/organism/Upload/UploadingFile";
import {
  closeModal,
  closePanelLeft,
  toggleRefresh
} from "_redux/ui/ui.actions";
import { useProductFilter } from "_hooks";
import useAttribute from "_hooks/useAttribute";

const prefix = "o-upload-file";

const UploadFileModal = ({ onFinished }) => {
  const { downloadTemplate } = useAttribute();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { formatFills } = useProductFilter();
  const classForComponent = classNames(prefix);
  const { post: postData } = useSelector(selectProductStore);
  const [type, setType] = useState("upload");
  const [selection, setSelection] = useState(null);
  const [file, setFile] = useState([]);
  
  const downloadTemplateFile = async () => {
    toast.info(<Toast 
      area={t("products:newSku.feedback.download_template.area")} 
      info={t("products:newSku.feedback.download_template.started")}
    />);
    
    downloadTemplate(null, { group: ["@all", "channels"] })
  };

  const handleSubmit = async () => {
    const params = { $action: "process", flags: selection };
    setType("updated");
    let response = await dispatch(
      uploadFile({ file: file[0] }, params)
    );
    
    if (response?.data?.[0]?.countRow?.toCreate || 
      response?.data?.[0]?.countRow?.toUpdate
    ) {
      if (typeof onFinished === "function") {
        onFinished();
      } else {
        dispatch(toggleRefresh("products"));
        formatFills(true);
      }
    }
  };
    

  useEffect(() => {
    if (file?.length && !file[0]?.errors) {
      const params = { $action: "validate" };
      dispatch(uploadFile({ file: file[0] }, params));
      setType("loading");
    }
  }, [file]);

 
  return (
    <div className={classForComponent}>
      <div className={`${prefix}__header`}></div>
      <div className={`${prefix}__content`}>
        {type === "upload" ? (
          <UploadDrop 
            file={file} 
            setFile={setFile}
            label={"products:newSku.hintFile"}
            dropProps={{
              accept: "text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv",
              maxFiles: 1
            }}
          />
        ) : null}

        {type === "loading" ? (
          <UploadingFile file={file[0]} setType={setType} />
        ) : null}

        {type === "complete" ? (
          <CompleteUpload
            file={file[0]}
            setFile={setFile}
            selection={selection}
            setSelection={setSelection}
          />
        ) : null}

        {type === "updated" ? (
          <UploadingFile
            file={file[0]}
            setType={setType}
            update
            selections={selection}
          />
        ) : null}
      </div>

      <div className={`${prefix}__footer`}>
        {type === "upload" ? (
          <Button
            content={t("products:newSku.download_template")}
            type="underline"
            onClick={downloadTemplateFile}
          />
        ) : (
          <>
            {type !== "updated" ? (
              <Button
                content={`< ${t("products:newSku.upload_file")}`}
                type="color"
                onClick={() => {
                  setType("upload");
                  dispatch(resetPost());
                  setFile({ data: {} });
                }}
              />
            ) : null}
            {type === "complete" ? (
              <Button
                content={t("products:newSku.continue")}
                type="purple"
                onClick={handleSubmit}
                disabled={
                  !selection || (!selection?.update && !selection?.create)
                }
              />
            ) : null}
            
            {type === "updated" && postData.status === "success" ? (
              <>
                <div></div>
                <Button
                  content={t("products:newSku.close")}
                  type="purple"
                  onClick={() => {
                    dispatch(closeModal());
                    dispatch(closePanelLeft());
                  }}
                />
              </>
            ) : null}
          </>
        )}
      </div>
    </div>
  );
};

export default UploadFileModal;
