export const reduxActions = {

  ALL_FAIL: "mediaLib/all_fail",
  ALL_RESET: "mediaLib/all_reset",
  ALL_STARTED: "mediaLib/all_started",
  ALL_SUCCESS: "mediaLib/all_success",

  DELETE_FAIL: "mediaLib/delete_fail",
  DELETE_RESET: "mediaLib/delete_reset",
  DELETE_STARTED: "mediaLib/delete_started",
  DELETE_SUCCESS: "mediaLib/delete_success",

  OWN_FAIL: "mediaLib/own_fail",
  OWN_RESET: "mediaLib/own_reset",
  OWN_SET: "mediaLib/own_set",
  OWN_STARTED: "mediaLib/own_started",
  OWN_SUCCESS: "mediaLib/own_success",

  POST_FAIL: "mediaLib/post_fail",
  POST_RESET: "mediaLib/post_reset",
  POST_STARTED: "mediaLib/post_started",
  POST_SUCCESS: "mediaLib/post_success",

  SHARED_FAIL: "mediaLib/shared_fail",
  SHARED_RESET: "mediaLib/shared_reset",
  SHARED_SET: "mediaLib/shared_set",
  SHARED_STARTED: "mediaLib/shared_started",
  SHARED_SUCCESS: "mediaLib/shared_success",

  UPLOAD_FAIL: "mediaLib/upload_fail",
  UPLOAD_RESET: "mediaLib/upload_reset",
  UPLOAD_STARTED: "mediaLib/upload_started",
  UPLOAD_SUCCESS: "mediaLib/upload_success",
};

export const ACTIVE = "active";
export const INACTIVE = "inactive";

export const CLUSTER_ACCOUNT = "account";
export const CLUSTER_USER = "user";
export const CLUSTER_GLOBAL = "global";
