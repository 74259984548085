import { useEffect, useState } from "react";
import { Logo } from "components/atoms/index";
import { FlexComponent } from "components/templates/index";
import { ReactComponent as Abrigo } from "./assets/abrigo.svg";
import { ReactComponent as Chamarra } from "./assets/chamarra.svg";
import { ReactComponent as Falda } from "./assets/falda.svg";
import { ReactComponent as Playera } from "./assets/playera.svg";
import { ReactComponent as Short } from "./assets/short.svg";
import { ReactComponent as Vestido } from "./assets/vestido.svg";
import { ReactComponent as Vestido2 } from "./assets/vestido2.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";


const prefix = "o-details-photo-loader";


const clothes = [
  Abrigo,
  Chamarra,
  Falda,
  Playera,
  Short,
  Vestido,
  Vestido2,
  Abrigo,
  Chamarra,
  Falda,
  Playera,
  Short,
];

const DetailsPhotoLoader = ({ variant }) => {
  const { t } = useTranslation();
  const classForComponent = classNames(prefix, {
    [`${prefix}--${variant}`]: variant,
  });
  const [random, setRandom] = useState([]);
  useEffect(() => {
    let randomize = clothes
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);
    setRandom(randomize);
  }, []);
  return (
    <div className={classForComponent}>
      <div className={`${prefix}__logo`}>
        <Logo color={variant === "detailsPhoto"}/>
        <div className={`${prefix}__loading`}>
          <span className={`${prefix}__loading-text`}>{t("common:words.loading")}</span>
          <div className="dot-flashing"></div>
        </div>
      </div>
      <div className={`${prefix}__carrousel`}>
        <FlexComponent gap={120}>
          {random.map((Clothe, index) => (
            <div className={`${prefix}__carrousel-clothes`} key={index}>
              <Clothe  />
            </div>
          ))}
        </FlexComponent>
      </div>
    </div>
  );
};

export default DetailsPhotoLoader;
