import { previousPage } from "_redux/ui/ui.actions";
import jsonToUrl from "_utils/url/jsonToUrl";
import urlToJson from "_utils/url/urlToJson";

const toPhotos = async ({ action, history, location, params, sku }) => {
  let { pathname, search: queryString } = location;

  if (params?.catalogId) {
    queryString = await urlToJson(queryString);
    queryString.from = { catalogId: params?.catalogId };
    queryString = await jsonToUrl(queryString);
  }
  
  action(pathname);
  
  history.push({
    pathname: `/products/${sku}/photos`,
    search: queryString
  });
};

export default toPhotos;
