import PropTypes from "prop-types";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const prefix = "m-square-edit";

const SquareEdit = ({ bgImage, createLegend, emptyLegend, enableEdition, height, isEmpty, onEdit, width }) => {
  const { t } = useTranslation();

  const classForComponent = classNames(prefix, {
    "--focused": enableEdition
  });

  return (
    <div className={classForComponent} 
      style={{backgroundImage: `url(${bgImage || ""})`}}
      onClick={() => { if (typeof onEdit === "function") onEdit(); }}
    >
      <span className="--preview-width">{width} px</span>
      <span className="--preview-height">{height} px</span>
      {isEmpty && <>
        <div className="--empty">
          {!!emptyLegend && <div className="--empty-legend">{emptyLegend}</div>}
          
          {!!createLegend && 
            <div className="--create-legend">
              {createLegend}
            </div>
          }
        </div>
        </>
      }

      {enableEdition && 
        <div
          className={`--edit-btn${!isEmpty ? "-filled" : ""}`}
        >{t("common:form.edit")}</div>
      }
    </div>
  )
};

SquareEdit.propTypes= {
  bgImage: PropTypes.string,
  emptyLegend: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  enableEdition: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isEmpty: PropTypes.bool,
  onEdit: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SquareEdit.defaultProps = {
  isEmpty: true
};

export default SquareEdit;
