import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import ActiveCard from "views/Settings/DataFields/PropertyCard/ActiveCard";
import { postPropertyColumns } from "_redux/app/app.actions";
import { closeModal } from "_redux/ui/ui.actions";
import { selectUser } from "_redux/user/user.selectors";
import { $SETTING_PROP_COL_UPDATE } from "_constants/permission.constants";

const prefix = "o-new-property-modal";

const NewPropertyModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [_type, setType] = useState("Text");
  const [values, setValues] = useState({});
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);

  const save = async () => {
    setLoading(true);
    await dispatch(postPropertyColumns(values));
    dispatch(closeModal());
    setLoading(false);
  };
  
  return (
    <div className={prefix}>
      <ActiveCard 
        allDisabled={!(user.canDoIt($SETTING_PROP_COL_UPDATE))}
        data={{}} 
        isModal _type={_type} 
        onChange={(val) => setValues(val)}
        onSave={() => {}}
        setType={setType}
      />
      <div className={`${prefix}__buttons`}>
        {user.canDoIt($SETTING_PROP_COL_UPDATE) &&
          <>
            <Button 
              content={t("common:form.cancel")} 
              type={"underline"} 
              sm
              onClick={() => dispatch(closeModal())}
            />
          
            <Button 
              content={t("common:form.save")} 
              type={"purple"} 
              sm
              disabled={loading || !Object.keys(values).length || !values?.original?.length}
              onClick={() => save()}
            />
          </>
        }
      </div>
    </div>
  );
};

export default NewPropertyModal;
