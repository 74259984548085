import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import ReactDOM from "react-dom";
import scriptjs from "scriptjs";
import classNames from "classnames";
import { Logo } from "components/atoms";
import { Toast } from "components/molecules";
import { useTranslation } from "react-i18next";


const SCRIPT_URL = "https://clippingmagic.com/api/v1/ClippingMagic.js";
const prefix = "u-smart-editor"; // clipping-magic.scss

const SmartEditor = ({ data, filename, onClose, onSave }) => {
  const { t } = useTranslation();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
 /*  const [fullScreen, setFullScreen] = useState(true); */
  let ClippingMagic;

  const classForComponent = classNames(prefix, {
    /* [`${prefix}--full-screen`]: fullScreen, */
  });

  useEffect(() => {
    scriptjs(SCRIPT_URL, () => setLoaded(true));

    return () => close();
  }, []);

  useEffect(() => {
    let timer = setTimeout(() => {
      /* handleResize(); */
      setLoading(false);
    }, 6000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (loaded === true && data !== null) init();
  }, [loaded]);

  const init = () => {
    ClippingMagic = window["ClippingMagic"];
    var errorsArray = ClippingMagic.initialize({ apiId: 9376 });
    if (errorsArray.length > 0) {
      toast.warn(
        <Toast
          area="Edition"
          info="Sorry, your browser is missing some required features"
        />
      );
    }
    edit();
  };

  const edit = async () => {
    if (!data?.image) return;

    ClippingMagic.edit(
      {
        image: {
          id: data.image.id,
          secret: data.image.secret,
          test: process.env.NODE_ENV === "development",
        },
        useStickySettings: true,
        locale: "es-MX",
      },
      callback
    );

    
  };

  const callback = (opts) => {
    // TODO: Replace this with your own functionality
    switch (opts.event) {
      case "error":
        toast.error(<Toast area={t("manage:smartEditor.toast.process")} info={opts.error.message} />);
        break;
      case "result-generated":
        toast.info(<Toast area={t("manage:smartEditor.toast.process")} info={t("manage:smartEditor.toast.workInProgress")} />);
        if (typeof onSave === "function") {
          let config = {image: {
            ...opts.image,
            originalFilename: data.image.originalFilename
          }};
          
          onSave(config);
        }
        close();
        break;

      case "editor-exit":
        close();
        break;
      default:
        break;
    }
  };

  const close = () => {
    if (typeof onClose === "function") onClose();
    window["ClippingMagic"].close();
  };
/* 
  const handleResize = () => {
    const clippingContainer = document.querySelector(
      "#clipping-magic-container"
    );
    clippingContainer.classList.add("clip-full-screen");
    setFullScreen(!fullScreen);
  }; */

  return ReactDOM.createPortal(
    <div className={classForComponent}>
      {loading && <div className={`${prefix}__loader`}>
        <Logo color="var(--color-white)"/>
        <span>{t("common:words.loading")}</span>
      </div>
      }
      
    </div>,
    document.body
  );
};

export default SmartEditor;
