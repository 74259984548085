import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, NubeskIcons } from "components/atoms";
import { FlexComponent, SearchPanel } from "components/templates";
import { toggleFilter } from "_redux/ui/ui.actions";
import {
  selectProductCount,
  selectProductCountPiece,
} from "_redux/product/product.selector";
import useTooltip from "_hooks/useTooltip";

const CatalogIdToolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });
  const productCount = useSelector(selectProductCount);
  const productCountPiece = useSelector(selectProductCountPiece);

  const [filterCounter] = useState(0);
  const [search] = useState();
  
  return (
    <>
      <FlexComponent>
        <div {...injectedProps}>
          <Button
            leftIcon={<NubeskIcons icon="share" />}
            content={"Share"}
            type={"primary"}
            disabled
            onClick={() => {}/* 
              dispatch(
                openModal({
                  typeModal: "share",
                  header: {
                    text: "Share catalog",
                    rightItem: catalog?.key,
                  },
                  modalProps: {
                    _id: catalog?._id,
                    share: catalog?.share?.map((s) => s._id),
                  },
                })
              ) */
            }
          />
        </div>
        <div {...injectedProps}>
          <Button
            leftIcon={<NubeskIcons icon="plus" />}
            content={"Task"}
            type={"primary"}
            disabled
            onClick={() => {
              /*  dispatch(
              openModal({
                typeModal: "newCatalog",
                header: {
                  text: "New catalog with selection",
                  icon: { type: "plus", size: 20 },
                },
              })
            ); */
            }}
          />
        </div>
      </FlexComponent>
      <SearchPanel
        qty={productCountPiece}
        total={productCount}
        filterCounter={filterCounter}
        onToggleFilters={() => dispatch(toggleFilter())}
        value={search}
      />
    </>
  );
};

export default CatalogIdToolbar;
