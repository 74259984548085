import { useDispatch, useSelector } from "react-redux";
import { post } from "_redux/analysis/analysis.action";
import { selectAnalysisLayoutCopy } from "_redux/analysis/analysis.selector";
import { reduxActions as ANALYSIS } from "_constants/analysis.constants";

const useAnalyticProgress = () => {
  const dispatch = useDispatch();

  const { data: layoutCopyData, error: layoutCopyError, status: layoutCopyStatus } = useSelector(selectAnalysisLayoutCopy);

  const analyzeLayoutCopy = (resourceId, layoutId) => {
    let params = { w: { resourceId } };
    let $config = {
      layoutId: layoutId
    };

    return dispatch(post({ ...params, $config}, {
      endpoint: `/analytic/matchup/toLayout`,
      types: [
        ANALYSIS.LAYOUT_COPY_STARTED,
        ANALYSIS.LAYOUT_COPY_SUCCESS,
        ANALYSIS.LAYOUT_COPY_FAIL
      ]
    }));
  };

  const layoutCopyReset = () => dispatch({ type: ANALYSIS.LAYOUT_COPY_RESET });

  return {
    analyzeLayoutCopy,
    layoutCopyData,
    layoutCopyError,
    layoutCopyReset,
    layoutCopyStatus
  };
};

export default useAnalyticProgress;
