import initialStatisticsState from "./statistics.initialState";
import { reduxActions as STATISTICS } from "_constants/statistics.constants";
import { FAILURE, LOADING } from "_constants/redux.constants";

const reducer = (state = initialStatisticsState, { type, payload }) => {
  switch (type) {

    // Product stats
    case STATISTICS.PRODUCTS_STARTED:
      return {
        ...state,
        products: {
          ...state.products,
          status: LOADING
        }
      };

    case STATISTICS.PRODUCTS_FAIL:
      return {
        ...state,
        products: {
          ...state.products,
          error: payload?.error ?? null,
          status: FAILURE
        }
      };

    case STATISTICS.PRODUCTS_SUCCESS:
      return {
        ...state,
        products: {
          ...state.products,
          error: null,
          data: payload?.data ?? initialStatisticsState.products.data
        }
      };
    
    case STATISTICS.PRODUCTS_RESET:
      return {
        ...state,
        products: initialStatisticsState.products
      };


    // Task stats
    case STATISTICS.TASKS_STARTED:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          status: LOADING
        }
      };

    case STATISTICS.TASKS_FAIL:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: payload?.error ?? null,
          status: FAILURE
        }
      };
      
    case STATISTICS.TASKS_SUCCESS:
      return {
        ...state,
        tasks: {
          ...state.tasks,
          error: null,
          data: payload?.data ?? initialStatisticsState.tasks.data
        }
      };
    case STATISTICS.TASKS_RESET:
      return {
        ...state,
        tasks: initialStatisticsState.tasks
      };

    default: return state;
  }
};

export default reducer;
