export const reduxActions = {
  PRODUCTS_STARTED: 'filters/products_started',
  PRODUCTS_FAIL: 'filters/products_fail',
  PRODUCTS_SUCCESS: 'filters/products_success',
  PRODUCTS_RESET: 'filters/products_reset',

  PRODUCT_FILTERS: 'filters/product_filters',
  PRODUCT_FILTER_LIST: 'filters/product_filter_list',
  TOGGLE_PRODUCT_FILTERS: 'filters/toggle_product_filters'
};
