import { reduxActions as BILLING } from "_constants/billing.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialBillingState from "../billing.initialState";

const heros = {
  [BILLING.HEROS_STARTED]: (state) => ({
    ...state,
    heros: {
      ...state.heros,
      status: LOADING
    }
  }),

  [BILLING.HEROS_FAIL]: (state, { payload }) => ({
    ...state,
    heros: {
      ...state.heros,
      error: payload?.error,
      status: FAILURE
    }
  }),

  [BILLING.HEROS_SUCCESS]: (state, { payload }) => ({
    ...state,
    heros: {
      ...state.heros,
      data: payload?.data?.[0],
      status: SUCCESS
    }
  }),

  [BILLING.HEROS_RESET]: (state) => ({
    ...state,
    heros: initialBillingState.heros
  })
};

export default heros;
