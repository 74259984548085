import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import { Badge, Button, NubeskIcons, Title } from "components/atoms";
import { DropDown, FlexComponent } from "components/templates";
import { selectBillingCurrentPlan, selectBillingDateFilter } from "_redux/billing/billing.selector";
import useBilling from "_hooks/useBilling";
import { setDateFilter } from "_redux/billing/billing.actions";

const BillingToolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: currentPlan } = useSelector(selectBillingCurrentPlan);
  const dateFilter = useSelector(selectBillingDateFilter);
  const { getNextRenewal, getOptionsFilter } = useBilling();

  const [nextRenewal, setNextRenewal] = useState("");
  const [months, setMonths] = useState([]);

  useEffect(() => {
    if (currentPlan?.billingDay) {
      setNextRenewal(getNextRenewal(currentPlan?.billingDay));
      setMonths(getOptionsFilter(currentPlan?.billingDay))
    }
  }, [currentPlan]);

  useEffect(() => {
    if (months.length && !dateFilter) {
      dispatch(setDateFilter(months[0]));
    }
  }, [months]);

  return (
    <>
      <FlexComponent variant="column">
        <Button 
          content={t("settings:billing.manageSuscription")}
          type="primary"
          disabled
        />

        <DropDown 
          scrollOptions={{ translateContentSizeXToHolder: true }}
          options={months?.map((o) => ({
            content: `${t(`common:datetime.month.${o.month}`)} ${o.year}`,
            onClick: () => dispatch(setDateFilter(o)),
            style: { width: "max-content", minWidth: "100%" },
          })) ?? []}
          buttonProps={{
            content: (i18next.exists(`common:datetime.month.${dateFilter?.month}`) ?
              `${t(`common:datetime.month.${dateFilter?.month}`)} ${dateFilter?.year}`
              : ""
            ),
            type: "primary",
            rightIcon: <NubeskIcons icon="down" />
          }}
        />
      </FlexComponent>
      <FlexComponent variant="column">
        <Title 
          content={t("settings:billing.nextRenewal")}
          secondary
          small
        />
        <Badge
          content={nextRenewal}
          variant="purple"
          noUp
          size="large"
        />
      </FlexComponent>
    </>
  );
};

export default BillingToolbar;
