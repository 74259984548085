import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import classNames from "classnames";

import { useSelector } from "react-redux";
import { selectLoader } from "_redux/ui/ui.selector";
import DetailsPhotoLoader from "./DetailsPhotoLoader";

const prefix = "o-loader";

const loaderManager = {
  detailsPhoto: DetailsPhotoLoader,
  homeInit: DetailsPhotoLoader,
};

const LoaderSelector = () => {
  const [show, setShow] = useState(false);
  const { typeLoader, isOpen, loaderProps } = useSelector(selectLoader);
  const classForComponent = classNames(prefix, {
    [`${prefix}--${typeLoader}`]: typeLoader,
  });

  let renderedLoader;
  if (typeof loaderManager[typeLoader] !== "undefined") {
    const LoaderComponent = loaderManager[typeLoader];
    renderedLoader = <LoaderComponent {...loaderProps} variant={typeLoader} />;
  }

  useEffect(() => {
    if (isOpen) return setShow(true);

    return setShow(false);
  }, [isOpen]);

  if (!show) return null;
  return ReactDOM.createPortal(
    <div className={classForComponent}>{renderedLoader}</div>,
    document.body
  );
};

export default LoaderSelector;
