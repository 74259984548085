import React from "react";
import classNames from "classnames";
import { FlexComponent } from "components/templates/index";

const prefix = "a-property";

const Property = ({
  name,
  description,
  content,
  extraChild,
  variant,
  headerRight,
}) => {
  const classForComponent = classNames(prefix, {
    [`${prefix}--${variant}`]: variant,
  });
  return (
    <div className={`${classForComponent}`}>
      <div className={`${prefix}__content`}>
        <div className={`${prefix}__child`}>
          <div className={`${prefix}__header`}>
            <FlexComponent gap={12}>
              <span className={`${prefix}__name`}>{name}</span>
              <p className={`${prefix}__description`}>{description}</p>
            </FlexComponent>
            {headerRight}
          </div>
          {content}
        </div>
        {extraChild}
      </div>
    </div>
  );
};

export default Property;
