import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { approveCopy as approve, putCopy, putCopyStatus } from "_redux/product/product.actions";

const useCopyData = () => {
  const dispatch = useDispatch();

  const [fullObject, setFullObject] = useState({});
  const [snapShot, setSnapShot] = useState({});
  const [progress, setProgress] = useState(0);

  const approveCopy = (productId) => dispatch(approve(productId));
  const changeCopyStatus = (productId, status) => dispatch(putCopyStatus(productId, status));

  const dataStructure = (product, appPropertyColumns) => {
    let _fullObject = {};
    let incompleteSnap = {};
    
    if (product?.data) {
      for (let field of appPropertyColumns) {
        if (!product?.data[field?.key]) {
          incompleteSnap = {
            ...incompleteSnap,
            [field?.key]: "-",
          };
        }

        _fullObject[field?.key] = {
          value: product?.data?.[field?.key] || "-",
          array: field?.class?.enum || null,
          readOnly: !field?.flag?.editable || false,
          original: field?.name || field,
        };
      }
    }
    
    setFullObject(_fullObject);
    setSnapShot(_fullObject);
  };

  const dataReset = () => {
    setFullObject({});
    setSnapShot({});
  };

  const discardChanges = () => setFullObject(snapShot);

  const estimateProgress = () => {
    let _progress = 0;
    let keys = Object.keys(fullObject);
    let output = 0;

    if (keys.length) {
      keys.forEach((a) => {
        
        if (fullObject?.[a]?.value && fullObject[a].value !== "-") {
          _progress += 1;
        }
      });
    }

    if (_progress) {
      output = ((_progress / keys.length) * 100).toFixed(0);
    }
  
    setProgress(output);
  };

  const changeHandler = ({ value, name }) => {
    if (snapShot?.[name]?.includes?.("•")) {
      value = value
        .split("\n")
        .map((data) => `•${data}`)
        .join("\n");
    }
    setFullObject((s) => ({
      ...s,
      [name]: {
        ...s?.[name],
        value
      }
    }));
  };

  const saveCopy = (productId, data) => {
    let object = {};
    let array = Object.keys(data)?.filter((f) => data[f]?.value !== "-")

    for (let a of array) {
      object = {
        ...object,
        [a]: data[a].value
      }
    }
    
    return dispatch(putCopy(productId, object));
  };

  useEffect(() => {
    estimateProgress();
  }, [fullObject]);

  return {
    approveCopy,
    changeCopyStatus,
    dataReset,
    dataStructure,
    discardChanges,
    changeHandler,
    fullObject,
    progress,
    saveCopy,
    snapShot
  };
};

export default useCopyData;
