import { createSelector } from 'reselect';

export const selectStatisticsStore = (store) => store.statistics;

// Product selectors
export const selectProductStore = createSelector(
  selectStatisticsStore,
  (statistics) => statistics.products
);

export const selectProductData = createSelector(
  selectProductStore,
  (productsStore) => productsStore.data
);

export const selectProductComplete = createSelector(
  selectProductData,
  (data) => data.complete
);

export const selectProductIncomplete = createSelector(
  selectProductData,
  (data) => data.incomplete
);

export const selectProductBroken = createSelector(
  selectProductData,
  (data) => data.broken
);

export const selectProductError = createSelector(
  selectProductStore,
  (products) => products.error
);

export const selectProductStatus = createSelector(
  selectProductStore,
  (products) => products.status
);

// Task selectors
export const selectTaskStore = createSelector(
  selectStatisticsStore,
  (statistics) => statistics.tasks
);

export const selectTaskData = createSelector(
  selectTaskStore,
  (taskStore) => taskStore.data
);

export const selectTaskCopy = createSelector(
  selectTaskData,
  (data) => data.copy
);

export const selectTaskPhoto = createSelector(
  selectTaskData,
  (data) => data.photos
);

export const selectTaskRights = createSelector(
  selectTaskData,
  (data) => data.rights
);

export const selectTaskError = createSelector(
  selectTaskStore,
  (taskStore) => taskStore.error
);

export const selectTaskStatus = createSelector(
  selectTaskStore,
  (taskStore) => taskStore.status
);


export default selectStatisticsStore;
