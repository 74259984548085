import useAppSettings from "./useAppSettings";
import useModal from "./useModal";
import useUser from "./useUser";

const useMediaHelp = () => {
  const { appMediaHelp } = useAppSettings();
  const { openModal } = useModal();
  const { user } = useUser();

  const launchVideo = () => {
    if (user?.__counters?.currentLogins === 1) {
      openModal({ 
        typeModal: "faq",
        isClosable: false,
        closeBackdrop: false,
        modalProps: {
          url: !user?.fork ? appMediaHelp?.core?.videoFirstTime?.url : appMediaHelp?.[user.fork]?.videoFirstTime?.url
        }
      });
      setTimeout(() => {
        openModal({
          typeModal: "faq",
          isClosable: true,
          closeBackdrop: false,
          modalProps: {
            url: !user?.fork ? appMediaHelp?.core?.videoFirstTime?.url : appMediaHelp?.[user.fork]?.videoFirstTime?.url
          }
        })
      }, 5000);
    }
  }
  return {
    launchVideo
  };
};

export default useMediaHelp;
