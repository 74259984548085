import { Switch } from "components/atoms"
import { useTranslation } from "react-i18next";

const Controls = ({ opt1, opt2, prefix, setOpt1, setOpt2 }) => {
  const { t } = useTranslation();

  return (
    <div className={`${prefix}__controls`}>
      <div className="--option">
        <p onClick={() => setOpt1((s) => !s)}>
          {t("module:syncfonia.updateGtin.modal.updateImg")}
        </p>
        <Switch
          checked={opt1}
          onChange={() => setOpt1((s) => !s)}
        />
      </div>

      <div className="--option">
        <p onClick={() => setOpt2((s) => !s)}>
          {t("module:syncfonia.updateGtin.modal.updateInfo")}
        </p>
        <Switch
          checked={opt2}
          onChange={() => setOpt2((s) => !s)}
        />
      </div>
    </div>
  );
};

export default Controls;
