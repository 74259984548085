import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Badge, Button, DimensionPreview } from "components/atoms";
import { IconDrawer, PhotoLabel } from "components/molecules";
import { Select } from "components/molecules/Inputs";
import InputTest from "components/molecules/Inputs/InputTest/InputTest";
import { useAppSettings, useAttribute, useChannelLayout, useModal, useProduct, useUser } from "_hooks";
import {selectProductDetails } from "_redux/product/product.selector";
import backgroundColor from "_utils/background/backgroundColor";
import { WATERMARK_MODULE } from "_constants/app.constants";

const prefix = "o-add-adaptation-modal";

const AddAdaptationModal = ({ fired, productId, onAdd, isApproved }) => {
  const classForComponent = classNames(prefix);
  const { closeModal } = useModal();
  const { t } = useTranslation();
  const { listData: layouts, fetch } = useChannelLayout();
  const { appColumnKeyProps } = useAppSettings();
  const { attributeFetch, dataOne: attribute } = useAttribute();
  const { saveLayoutData } = useProduct();
  const { hasModule } = useUser();

  const product = useSelector(selectProductDetails);
  const [options, setOptions] = useState([]);
  const [selected, setSelected] = useState();
  const [sku, setSku] = useState("");
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      setLoading(true);
      const { _id, layoutKey } = selected?.value;
      
      const attributeKey = await attributeFetch({
        "flag.key": true
      }, layoutKey);

      
      await saveLayoutData(layoutKey, productId, {
        data: {
          [attributeKey.key]: sku
        }
      });

      if (isApproved) await onAdd(_id)

      closeModal();
      
    } catch (e) {
      setLoading(false);
    }
  };

  const getAvailables = () => {
    if (!layouts) return;

    let list = [];
    
    for (let l of layouts) {
      for (let a of l.adaptationsId) {
        if (!a || fired.indexOf(a?._id) !== -1) continue;

        list.push({
          content: a?.original,
          label: (
            <div className="--channel-option">
              <Badge
                border="purple"
                content={l?.name}
              />
              <div className="u-purple" title={a?.name}>
                {a?.name}
              </div>
            </div>
          ),
          labelInfo: <>
            {a?.properties?.width ? `${a?.properties?.width} x ${a?.properties?.height} · ${a?.properties?.mimetype?.replace("image/", "")}`: ""}
          </>,
          value: {
            properties: a.properties,
            updatedAt: a.updatedAt,
            layoutKey: l.key,
            _id: a._id
          }
        });
      }
      
      setOptions(list);
    }
  };

  useEffect(() => {
    setSku(product?.key ?? "");
    fetch();
  }, []);

  useEffect(() => {
    getAvailables();
  }, [fired, layouts, attribute]);

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__header`}>
        { !loading && (<>
          <Select
            emptyLabel={t("products:dataPhotos.selectAvailable")}
            onChange={setSelected}
            value={t("products:dataPhotos.selectAvailable")}
            name={"adaptation-modal"}
            options={options?.map((a) => ({ label: a.label, labelInfo: a.labelInfo, value: a.value }))}
          />

        </>)}
      </div>

      {selected && !loading ? (
        <div className={`${prefix}__info-photo`}>
          <DimensionPreview
            className="--preview"
            h={selected?.value?.properties?.height}
            w={selected?.value?.properties?.width}
            margin={[selected?.value?.properties?.margin, selected?.value?.properties?.margin, selected?.value?.properties?.margin, selected?.value?.properties?.margin]}
            background={backgroundColor(selected?.value)}
            fadeType="radial"
            overlayerImg={hasModule(WATERMARK_MODULE) ? selected?.value?.properties?.layerUrl : undefined}
          />
          <div className="--data">
            {selected &&
              <div className={`${prefix}__sku-input`}>
                <div>{appColumnKeyProps?.name?.toUpperCase()}</div>
                <InputTest
                  value={sku}
                  onChange={(e) => setSku(e.target.value)}
                />
              </div>
            }
            <PhotoLabel title="Width (Ancho)" data={`${selected?.value?.properties?.width}px`} />
            <PhotoLabel title="Height (Largo)" data={`${selected?.value?.properties?.height}px`} />
            <PhotoLabel title="Extensión" data={selected?.value?.properties?.mimetype?.replace("image/", "")} />
            <PhotoLabel title="DPI" data={`${selected?.value?.properties?.density}DPI`} />
            <PhotoLabel title="Max size" data={`${selected?.value?.properties?.maxSize}KB`} />
          </div>
        </div>
      ) : null}

      {loading && (
        <div className={`${prefix}__animation`}>
          <IconDrawer icon="cut" />
          <div>
            <span>{t("products:dataPhotos.generatingAdaptations")}...</span>
          </div>
        </div>
      )}
      <div className={`${prefix}__footer`}>
        <Button
          content={t("common:form.cancel")}
          type="underline"
          onClick={() => closeModal()}
        />
        <Button
          content={t("products:actions.add_adaptation")}
          disabled={!selected || !sku.length || loading}
          sm
          type={"purple"}
          onClick={submit}
        />
      </div>
    </div>
  );
};

AddAdaptationModal.propTypes = {
  fired: PropTypes.array,
  productId: PropTypes.string.isRequired,
};

AddAdaptationModal.defaultProps = {
  fired: [],
};

export default AddAdaptationModal;
