import { FlexComponent } from "components/templates";
import { Card } from "components/organism";
import { NubeskIcons } from "components/atoms";
import ProgressBar from "@ramonak/react-progress-bar";

const prefix = "o-progress-card";

const ProgressCard = ({ icon, label, available, total }) => {
  return (
    <Card
      className={prefix}
      header={
        <div className={`${prefix}__label`}>
          <FlexComponent>
            {icon && <NubeskIcons icon={icon} />}
            {label}
          </FlexComponent>
          <div>
            <span className={`${prefix}__progress`}>
              {available}
            </span> / <span className={`${prefix}__total`}>
              {total}
            </span>
          </div>
        </div>
      }
    >
      <div className={`${prefix}__content`}>
        <ProgressBar 
          completed={available ? Math.round((available / total) * 100) : 0}
          isLabelVisible={false}
          bgColor="#6B6DD2"
          height="20px"
        />
      </div>
    </Card>
  );
};

export default ProgressCard;
