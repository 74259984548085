import { useAnalyticProgress } from "_hooks";

const useDownloadXls = () => {
  const { analyzeLayoutCopy, layoutData } = useAnalyticProgress();

  const getXlsProgress = (resourceId, layout) => {
    analyzeLayoutCopy({
      _id: resourceId
    }, layout);
  };

  return {
    getXlsProgress
  };
};

export default useDownloadXls;
