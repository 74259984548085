import { useEffect, useRef, useState } from "react";
import { Button } from "components/atoms/index";
import { Select } from "components/molecules/Inputs";
import { useDispatch, useSelector } from "react-redux";
import { put, resetPut } from "_redux/product/product.actions";
import { useTranslation } from "react-i18next/";
import { InputSelector } from "components/molecules/Inputs/index";
import { selectAppPropertyColumns } from "_redux/app/app.selector";
import { closeModal } from "_redux/ui/ui.actions";
import { chunk } from "_utils/array";
import { BulkActionsCounter } from "components/organism/index";
import { sleep } from "_utils/async";

const prefix = "o-edit-field-bulk-modal";

const EditFieldBulkModal = ({ items, onFinished }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [completed, setCompleted] = useState(0);
  const [selected, setSelected] = useState();
  const [arraySuccess, setArraySuccess] = useState([]);
  const [total, setTotal] = useState(0);
  const [value, setValue] = useState();
  const [waiting, setWaiting] = useState(false);
  
  const inputRef = useRef(null);

  const propColumns = useSelector(selectAppPropertyColumns);

  const close = () => {
    if (typeof onFinished === "function") onFinished(arraySuccess);
    dispatch(closeModal());
  };

  const sendEdition = async () => {
    let field = selected.value.key;
    let stackSize = 1;
    let responses = [];
    delete selected.name;
    
    let _items = items.map((p) => {
      if (p?._id) return p._id;

      return p;
    });

    if (_items.length > 1000){
      stackSize = 100;
    } else if (_items.length > 100){
      stackSize = 20;
    } else if (_items.length > 20){
      stackSize = 10;
    }
    
    setTotal(_items.length);
    setWaiting(true);
    let arrayChunk = chunk(_items, stackSize);
    
    for (let i = 0; i < arrayChunk.length; i++) {
      let response = await dispatch(put(arrayChunk[i], {
        data: {
          [field]: value
        }
      }));
      responses = [...responses, ...response?.data]
      setCompleted((v) => v += arrayChunk[i].length)
      if (_items.length <= 50) {
        await sleep(150).then(() => {});
      }
    }

    setArraySuccess(responses);
  };

  useEffect(() => {
    return () => {
      dispatch(resetPut());
    }
  }, []);

  useEffect(() => {
    if (selected) inputRef.current.focus();
  }, [selected]);

  return(
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        {!waiting && (<>
          <Select
          options={propColumns?.filter((p) => !!p?.flag?.editable)
            ?.map((p) => {
              let { name, key, layoutId, index } = p;
              return ({
                label: p?.name,
                name: p?.data?.propcolKeyReference ?? "",
                value: { index, key, layoutId, name }
            })}) ?? []}
            onChange={(v) => setSelected(v)}
            value={selected}
            emptyLabel={t("common:form.select")}
          />
          
          <InputSelector
            buttons={false}
            closeEdit={() => {}}
            edit={true}
            globalEdit={false}
            label={selected?.original}
            options={selected?.enum || null}
            onApprove={() => {}}
            name={selected?.name}
            onChange={({ target: { value } }) => setValue(value)}
            originalValue={""}
            readOnly={selected === undefined}
            ref={inputRef}
            value={value}
          />
          <div className={`${prefix}__footer`}>
            <Button
              content={t("common:form.apply")}
              disabled={!selected || (value === undefined || (typeof value === "string" && !value.length))}
              onClick={sendEdition}
              sm
              type="purple"
            />
          </div>
        </>)}
        
        {waiting && (
          <BulkActionsCounter
            completed={completed}
            legends={{
              action: t("modals:editFieldBulk.bulkCounter.action"),
              complete: t("modals:editFieldBulk.bulkCounter.complete"),
              error: t("modals:editFieldBulk.bulkCounter.error"),
              finish: t("modals:editFieldBulk.bulkCounter.finish"),
              success: t("modals:editFieldBulk.bulkCounter.success"),
              total: t("modals:editFieldBulk.bulkCounter.total")
            }}
            onFinish={close}
            withError={0}
            total={total}
          />
        )}
      </div>
      
    </div>
  );
};

export default EditFieldBulkModal;
