import initialProductState from "../../file/file.initialState";
import { reduxActions as PRODUCT } from "_constants/file.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const aiBgGenerate = {
  [PRODUCT.AI_BG_GENERATE_STARTED]: (state) => ({
    ...state,
    aiBgGenerate: {
      data: null,
      error: null,
      status: LOADING
    }
  }),
  [PRODUCT.AI_BG_GENERATE_SUCCESS]: (state, { payload }) =>  ({
    ...state,
    aiBgGenerate: {
      status: SUCCESS,
      data: payload?.data?.[0] ?? null,
      error: null
    }
  }),
  [PRODUCT.AI_BG_GENERATE_FAIL]: (state, { payload }) => ({
    ...state,
    aiBgGenerate: {
      status: FAILURE,
      data: null,
      error: payload?.error
    }
  }),
  [PRODUCT.AI_BG_GENERATE_RESET]: (state) =>  ({
    ...state,
    aiBgGenerate: initialProductState.aiBgGenerate
  })
};

export default aiBgGenerate;
