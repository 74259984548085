import initialAppState from "_redux/app/app.initialState"
import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const getTemplate = {
  [APP.GET_TEMPLATE_STARTED]: (state) => ({
    ...state,
    template: {
      ...state.template,
      status: LOADING
    }
  }),
  
  [APP.GET_TEMPLATE_FAIL]: (state, { payload }) => ({
    ...state,
    template: {
      ...state.template,
      error: payload?.error,
      status: FAILURE
    }
  }),

  [APP.GET_TEMPLATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    template: {
      ...state.template,
      data: payload?.data,
      status: SUCCESS
    }
  }),

  [APP.GET_TEMPLATE_RESET]: (state) => ({
    ...state,
    template: initialAppState.template
  })
};

export default getTemplate;
