import { reduxActions as USER } from '_constants/user.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialUserState from '../user.initialState';

const put = {
  [USER.PUT_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),
    
  [USER.PUT_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [USER.PUT_SUCCESS]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      data: payload?.data ?? null,
      status: SUCCESS
    },
    all: {
      ...state.all,
      data: state?.all?.data?.map ? state.all.data.map((row) => {
        if (!payload?.data?.[0]) return row;
        let { account, email, profile, status} = payload.data[0];
        let output = {...row};
        if (row._id === payload.data[0]._id) {
          output = {...row, ...{
            account,
            email, 
            profile,
            status
          }};
        }

        return output;
      }): state.all
    }
  }),
  
  [USER.PUT_RESET]: (state) => ({
    ...state,
    put: initialUserState.put
  })
};

export default put;
