
const prefix = "v-auth";

const AuthLayout = function ({ children }) {
  return (
    <div className={prefix}>
      <div className={`${prefix}__container`}>
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
