import { createRef, useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import { Scrollbar } from "react-scrollbars-custom";
import classNames from "../../../../node_modules/classnames/index";

const prefix = "o-scroller";
const contentPrefix = "o-scroller__content";

const Scroller = ({
  dataLength,
  children,
  hasMore,
  isVertical,
  isHorizontal,
  loading,
  next,
  noAnimated,
  onScrollStop,
  ...props
}) => {
  const ref = createRef();
  const [invisible, setInvisible] = useState(false);

  const classForComponent = classNames(prefix, {
    [`${prefix}__invisible`]: invisible,
  });

  const classForContent = classNames(contentPrefix, {
    [`${contentPrefix}-horizontal`]: isHorizontal,
    [`${contentPrefix}-vertical`]: isVertical,
  });

  const scrollStopHandler = (e) => {
    if (onScrollStop) onScrollStop(e);

    if (ref?.current) {
      let {
        clientHeight,
        clientWidth,
        contentScrollHeight,
        contentScrollWidth,
        scrollTop,
      } = ref?.current?.scrollValues;
      if (!loading && hasMore && next) {
        if (
          isVertical &&
          scrollTop >= contentScrollHeight - clientHeight - 320
        ) {
          next();
        }
        if (isHorizontal && scrollTop >= contentScrollWidth - clientWidth) {
          next();
        }
      }
    }
  };

  return (
    <Scrollbar
      {...props}
      noScrollX={isVertical}
      noScrollY={isHorizontal}
      className={classForComponent}
      onScrollStart={() => {
        setInvisible(false);
      }}
      onScrollStop={scrollStopHandler}
      ref={ref}
    >
      <div className={classForContent}>{children}</div>
    </Scrollbar>
  );
};

Scroller.propTypes = {
  dataLength: PropTypes.number,
  hasMore: PropTypes.bool,
  isHorizontal: PropTypes.bool,
  isVertical: PropTypes.bool,
  loading: PropTypes.bool,
  next: PropTypes.func,
};

Scroller.defaultProps = {
  dataLength: 0,
  hasMore: false,
  isHorizontal: false,
  isVertical: true,
  loading: false,
  next: null,
};

export default Scroller;
