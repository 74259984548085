import { useDispatch } from "react-redux";
import { openModal as open, closeModal as close } from "_redux/ui/ui.actions";

const useModal = () => {
  const dispatch = useDispatch();

  const openModal = (params) => dispatch(open(params));

  const closeModal = () => dispatch(close());
  
  return {
    openModal,
    closeModal
  }
};

export default useModal;
