export const reduxActions = {
  ACCOUNTS_STARTED: "billing/accounts_started",
  ACCOUNTS_SUCCESS: "billing/accounts_success",
  ACCOUNTS_FAIL: "billing/accounts_fail",
  ACCOUNTS_RESET: "billing/accounts_reset",

  ADAPTATIONS_STARTED: "billing/adaptations_started",
  ADAPTATIONS_FAIL: "billing/adaptations_fail",
  ADAPTATIONS_SUCCESS: "billing/adaptations_success",
  ADAPTATIONS_RESET: "billing/adaptations_reset",

  AI_IMAGES_STARTED: "billing/ai_images_started",
  AI_IMAGES_FAIL: "billing/ai_images_fail",
  AI_IMAGES_SUCCESS: "billing/ai_images_success",
  AI_IMAGES_RESET: "billing/ai_images_reset",

  BACK_REMOVALS_STARTED: "billing/back_removals_started",
  BACK_REMOVALS_FAIL: "billing/back_removals_fail",
  BACK_REMOVALS_SUCCESS: "billing/back_removals_success",
  BACK_REMOVALS_RESET: "billing/back_removals_reset",

  CHANNELS_STARTED: "billing/channels_started",
  CHANNELS_SUCCESS: "billing/channels_success",
  CHANNELS_FAIL: "billing/channels_fail",
  CHANNELS_RESET: "billing/channels_reset",

  CURRENT_PLAN_STARTED: "billing/current_plan_started",
  CURRENT_PLAN_FAIL: "billing/current_plan_fail",
  CURRENT_PLAN_SUCCESS: "billing/current_plan_success",

  DATE_FILTER: "billing/date_filter",

  FIND_STARTED: "billing/find_started",
  FIND_SUCCESS: "billing/find_success",
  FIND_FAIL: "billing/find_fail",
  FIND_RESET: "billing/find_reset",

  HEROS_STARTED: "billing/heros_started",
  HEROS_SUCCESS: "billing/heros_success",
  HEROS_FAIL: "billing/heros_fail",
  HEROS_RESET: "billing/heros_reset",

  ITEMS_STARTED: "billing/items_started",
  ITEMS_FAIL: "billing/items_fail",
  ITEMS_SUCCESS: "billing/items_success",
  ITEMS_RESET: "billing/items_reset",

  ISOMETRICS_STARTED: "billing/isometrics_started",
  ISOMETRICS_SUCCESS: "billing/isometrics_success",
  ISOMETRICS_FAIL: "billing/isometrics_fail",
  ISOMETRICS_RESET: "billing/isometrics_reset",

  POST_STARTED: "billing/post_started",
  POST_SUCCESS: "billing/post_success",
  POST_FAIL: "billing/post_fail",
  POST_RESET: "billing/post_reset",

  PRICING_STARTED: "billing/pricing_started",
  PRICING_SUCCESS: "billing/pricing_success",
  PRICING_FAIL: "billing/pricing_fail",
  PRICING_RESET: "billing/pricing_reset",

  WATERMARKS_STARTED: "billing/watermark_started",
  WATERMARKS_SUCCESS: "billing/watermark_success",
  WATERMARKS_FAIL: "billing/watermark_fail",
  WATERMARKS_RESET: "billing/watermark_reset"
};

export const ACTIVE = "active";
