import React from "react";
import { useTranslation } from "react-i18next";
import ProgressBar from "@ramonak/react-progress-bar";

const prefix = "m-custom-progress-bar";

const CustomProgressBar = ({ total, available, child, loading }) => {
  const { t } = useTranslation();
  return (
    <div className={prefix}>
      {child ? (
        child
      ) : (
        <ProgressBar
          completed={available ? Math.round((available / total) * 100) : 0}
          isLabelVisible={false}
          bgColor="#6B6DD2"
          height="30px"
        />
      )}
      <div className={`${prefix}__header`}>
        <span> {t("catalogs:general.catalog.photo_available")}</span>

        <div
          className={`${prefix}__header-qty ${
            loading ? `u-skeleton` : ""
          }`}
        >
          <div className={`${prefix}__header-total`}>
            (<span>{available}</span> /<span>{total}</span>)
          </div>
          <span className={`${prefix}__header-percent`}>
            {total ? Math.round((available / total) * 100) : "0"}%
          </span>
        </div>
      </div>
    </div>
  );
};

export default CustomProgressBar;
