export const reduxActions = {
  AI_BG_GENERATE_STARTED: "file/ai_bg_generate_started",
  AI_BG_GENERATE_FAIL: "file/ai_bg_generate_fail",
  AI_BG_GENERATE_SUCCESS: "file/ai_bg_generate_success",
  AI_BG_GENERATE_RESET: "file/ai_bg_generate_reset",

  ALL_STARTED: "file/all_started",
  ALL_FAIL: "file/all_fail",
  ALL_RESET: "file/all_reset",
  ALL_SUCCESS: "file/all_success",

  ONE_STARTED: "file/one_started",
  ONE_FAIL: "file/one_fail",
  ONE_RESET: "file/one_reset",
  ONE_SUCCESS: "file/one_success",

  POST_STARTED: "file/post_started",
  POST_FAIL: "file/post_fail",
  POST_RESET: "file/post_reset",
  POST_SUCCESS: "file/post_success",

  PUT_STARTED: "file/put_started",
  PUT_FAIL: "file/put_fail",
  PUT_RESET: "file/put_reset",
  PUT_SUCCESS: "file/put_success",

  REMOVE_STARTED: "file/remove_started",
  REMOVE_FAIL: "file/remove_fail",
  REMOVE_SUCCESS: "file/remove_success",
  REMOVE_RESET: "file/remove_reset",

  REMOVEBG_STARTED: "file/removebg_started",
  REMOVEBG_FAIL: "file/removebg_fail",
  REMOVEBG_SUCCESS: "file/removebg_success",
  REMOVEBG_RESET: "file/removebg_reset",

  TRIMM_STARTED: "file/trimm_started",
  TRIMM_FAIL: "file/trimm_fail",
  TRIMM_SUCCESS: "file/trim_success",
  TRIMM_RESET: "file/trimm_reset",

  UPLOAD_STARTED: "file/upload_started",
  UPLOAD_FAIL: "file/upload_fail",
  UPLOAD_SUCCESS: "file/upload_success",
  UPLOAD_RESET: "file/upload_reset",

  TO_SMART_EDITOR_STARTED: "file/to_smart_editor_started",
  TO_SMART_EDITOR_FAIL: "file/to_smart_editor_fail",
  TO_SMART_EDITOR_SUCCESS: "file/to_smart_editor_success",
  TO_SMART_EDITOR_RESET: "file/to_smart_editor_reset",

  SAVE_EDITION_STARTED: "file/to_save_edition_started",
  SAVE_EDITION_FAIL: "file/to_save_edition_fail",
  SAVE_EDITION_SUCCESS: "file/to_save_edition_success",
  SAVE_EDITION_RESET: "file/to_save_edition_reset",
  
  SWATCH_STARTED: "file/swatch_started",
  SWATCH_FAIL: "file/swatch_fail",
  SWATCH_SUCCESS: "file/swatch_success",
  SWATCH_RESET: "file/swatch_reset"
};
