const SquareColor = ({ color }) => {
  return (
    <svg 
      xmlns='http://www.w3.org/2000/svg' 
      viewBox="0 0 12 12"
      width="12" 
      height="12">
        <rect 
          fill={color || "White"} 
          x="0" y="0" width="12" height="12" 
          rx="12" ry="2"
          stroke="Black"
        />
    </svg>
  )
};

export default SquareColor;
