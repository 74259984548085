import React from "react";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import { Button } from "components/atoms";
import { useAppSettings } from "_hooks";

const prefix = "upload-bulk-images__found";

const Found = ({ foundQty, notFoundQty, imageQty, setStep }) => {
  const { appColumnKeyProps } = useAppSettings();
  const { t } = useTranslation();

  return (
    <div className={`${prefix}`}>
      <div className={`${prefix}__content`}>
        <div className={`${prefix}__content-item`}>
          <CountUp
            className={`${prefix}__content-qty`}
            end={imageQty}
            duration={1}
          />{" "}
          <span className={`${prefix}__content-txt`}>{t("modals:uploadBulk.found.images")}</span>
        </div>
        <div className={`${prefix}__content-item`}>
          <CountUp
            className={`${prefix}__content-qty`}
            end={foundQty}
            duration={1}
          />{" "}
          <span className={`${prefix}__content-txt`}>{t("modals:uploadBulk.found.receive", { "key": appColumnKeyProps?.name })}
          </span>
        </div>
          <div className={`${prefix}__content-item`}>
            <CountUp
              className={`${prefix}__content-qty --red`}
              end={notFoundQty}
              duration={1}
            />{" "}
            <span className={`${prefix}__content-txt`}>{t("modals:uploadBulk.found.not_found", { "key": appColumnKeyProps?.name })}</span>
          </div>
      </div>
      <div className={`${prefix}__buttons`}>
        <Button
          content={t("modals:uploadBulk.cancel")}
          onClick={() => {
            setStep(1);
          }}
          type="underline"
          sm
        />
        <Button
          content={t("modals:uploadBulk.startUpload")}
          onClick={() => {
            setStep((step) => step + 1);
          }}
          disabled={!foundQty}
          type="purple"
          sm
        />
      </div>
    </div>
  );
};

export default Found;
