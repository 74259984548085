import { reduxActions as FILE } from "_constants/file.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialFileState from "../file.initialState";

const remove = {
  [FILE.REMOVEBG_STARTED]: (state) => ({
    ...state,
    removeBg: {
      ...state.remove,
      status: LOADING
    }
  }),
  [FILE.REMOVEBG_FAIL]: (state, { payload }) => ({
    ...state,
    removeBg: {
      ...state.remove,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [FILE.REMOVEBG_SUCCESS]: (state, { payload }) => ({
    ...state,
    removeBg: {
      ...state.remove,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [FILE.REMOVEBG_RESET]: (state) => ({
    ...state,
    remove: initialFileState.remove
  }),
};

export default remove;
