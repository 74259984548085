import { IconDrawer, Semaphore } from "components/molecules/index"

const prefix = "o-semaphore-progress";

const SemaphoreProgress = ({ completed, label, total }) => {

  return (
    <div className={prefix}>
      <div className={`${prefix}__info`}>
        <div className={`${prefix}__animation`}>
          <IconDrawer
            icon="pencil"
            iconProps={{fontSize: "3rem"}}
          />
        </div>
        <div className={`${prefix}__data`}>
          <div className={`${prefix}__state`}>
            {label}...
          </div>
          <div className={`${prefix}__counter`}>
            <div>
              {total && `${Math.trunc(completed / total * 100)}%` }
            </div>
            <div>
              {completed && total && (
                <>
                  {completed || "-"}/{total || "-"}
                </>
              )}
            </div>
          </div>
          <Semaphore current={total ? Math.trunc(completed / total * 5) : 0} steps={5} />
        </div>
      </div>
    </div>
  )
};

export default SemaphoreProgress;
