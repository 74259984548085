import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as USER } from "_constants/user.constants";

const logout = { 
  [USER.LOGOUT_STARTED]: (state, { payload }) => ({
    ...state,
    own: {
      ...state.own,
      status: LOADING,
      data: payload?.data ?? null
    }
  }),
  
  [USER.LOGOUT_SUCCESS]: (state) => ({
    ...state,
    own: {
      ...state.own,
      status: SUCCESS,
      data: null
    }
  }),

  [USER.LOGOUT_FAIL]: (state, { payload }) => ({
    ...state,
    own: {
      ...state.own,
      error: payload?.error ?? null,
      status: FAILURE
    }
  })
};

export default logout;
