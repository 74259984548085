import { useEffect } from "react"
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import classNames from "classnames";
import { Title, Badge, SquareButton, NubeskIcons } from "components/atoms";
import { FlexComponent } from "components/templates";
import { useTranslation } from "react-i18next";

import { useProduct } from "_hooks";
import { selectAppComponentDrawing } from "_redux/app/app.selector";
import { nestedProps } from "_utils/drawing";
import { IDLE, LOADING } from "_constants/redux.constants";
import { resetOne } from "_redux/product/product.actions";
import { previousPage } from "_redux/ui/ui.actions";
import useTooltip from "_hooks/useTooltip";
import nbskReducer from "_reducers/nbskReducer";
import { SKUCARD } from "_constants/reducers.constants";

const prefix = "m-sku-card-content";
 
const SkuCardContent = ({ active, id, sku, process }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const params = useParams();
  const { getProduct, product, status, reset } = useProduct();

  const { injectedProps } = useTooltip();

  const { skuCard: skuCardDrawing } = useSelector(selectAppComponentDrawing);

  const classForComponent = classNames(prefix);
  const classSkeleton = classNames({
    "u-skeleton": [IDLE, LOADING].indexOf(status) !== -1,
  });

  const fetch = () => getProduct(id, "_id", {}, "skuCard");

  useEffect(() => {
    if (active) fetch();
    else reset();
  }, [active]);

  return (
    <div className={classForComponent}>
      <FlexComponent isVertical sm>
        <div className={`${prefix}__title`}>
          <Title content={product?.key} secondary medium />
        </div>
        <span
          className={`${prefix}__product-name ${classSkeleton}`}
          {...injectedProps}
        >
          {nestedProps(product, skuCardDrawing?.name ?? false)}
        </span>
        <FlexComponent sm>
          {status === LOADING ? (
            <>
              <Badge />
              <Badge />
            </>
          ) : (
            <>
              {nestedProps(product, skuCardDrawing?.tags ?? false)?.map(
                (tag, index) => (
                  tag ? <Badge content={tag} key={index} variant="purple" /> : null
                )
              )}
            </>
          )}
        </FlexComponent>
        <p className={`${prefix}__description ${classSkeleton}`}>
          {nestedProps(product, skuCardDrawing?.content ?? false)}
        </p>
        <div className={`${prefix}__buttons`}>
          <SquareButton
            onClick={(e) => nbskReducer(SKUCARD.TO_PHOTOS, {
              action: () => {
                e.stopPropagation();
                dispatch(previousPage({location, params}));
              },
              history,
              location,
              params,
              sku
            })}
            status={process?.reviewMaster}
            name={t("products:grid.skuCardPhotos")}
            icon={<NubeskIcons icon="photos" />}
          />{" "}

          {/* <Link
            to={`/products/${sku}/photos${queryString}`}
            onClick={(e) => {
              e.stopPropagation();
              // dispatch(previousPage(pathname));
            }}
          >
            <SquareButton
              status={process?.reviewMaster}
              name="Photos"
              icon={<NubeskIcons icon="photos" />}
            />{" "}
          </Link> */}
          <Link
            onClick={(e) => {
              e.stopPropagation();
              dispatch(resetOne());
            }}
            to={`/products/${sku}/copy${location?.search}`}
          >
            <SquareButton
              status={process?.reviewCopy}
              name={t("products:grid.skuCardInfo")}
              icon={<NubeskIcons icon="copy" />}
            />{" "}
          </Link>
          {/* <SquareButton
            status="complete"
            name="Other"
            icon={<NubeskIcons icon="rights" />}
            disabled={true}
          /> */}
        </div>
      </FlexComponent>
    </div>
  );
};

SkuCardContent.propTypes = {
  to: PropTypes.array
};

SkuCardContent.defaultProps = {
  to: []
};

export default SkuCardContent;
