import { reduxActions as ADAPTATION } from '_constants/adaptation.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const post = {
  [ADAPTATION.POST_STARTED]: (state) => ({
    ...state.post,
    status: LOADING
  }),

  [ADAPTATION.POST_SUCCESS]: (state, { payload }) => ({
    ...state.post,
    data: payload.data,
    status: SUCCESS
  }),

  [ADAPTATION.POST_FAIL]: (state, { payload }) => ({
    ...state.post,
    status: FAILURE
  })
};

export default post;
