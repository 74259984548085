import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import SimpleScroller from "components/organism/Scroller/SimpleScroller";
import { FlexComponent } from "components/templates";
import PropertyCard from "./PropertyCard/PropertyCard";
import { selectUser } from "_redux/user/user.selectors";
import {
  selectAppColumnKeyStore,
  selectAppPropertyColumns
} from "_redux/app/app.selector";
import { $$SETTING } from "_constants/permission.constants";

const prefix = "v-data-fields";

const DataFields = () => {
  const [fields, setFields] = useState([]);
  //TODO Configuración arrastrable para más adelante
  /*   const [dragItem, setDragItem] = useState(null); */

  const primaryKey = useSelector(selectAppColumnKeyStore);
  const propertyColumn = useSelector(selectAppPropertyColumns);
  const user = useSelector(selectUser);

  useEffect(() => {
    if (propertyColumn) {
      let array = [];

      for (let key in propertyColumn) {
        if (key === primaryKey.data) continue;
        array = [...array, { ...propertyColumn[key], name: key }];
      }
      array = [
        { ...primaryKey.properties[primaryKey.data], name: primaryKey.data },
        ...array,
      ].filter(item => !item.isAdaptation);

      setFields(array);
    }
  }, [propertyColumn]);
  /*   const onDragStart = (e, index) => {
    const _dragItem = fields[index];
    setDragItem(_dragItem);
    e.dataTransfer.effectAllowed = "move";
    e.dataTransfer.setData("text/html", e.target.parentNode);
    e.dataTransfer.setDragImage(e.target.parentNode, 10, 10);
  };
  const onDragOver = (index) => {
    const dragOverItem = fields[index];
    if (dragItem === dragOverItem) return;
    let items = fields.filter((item) => item !== dragItem);
    items.splice(index, 0, dragItem);
    setFields(items);
  }; */

  return (
    <div className={prefix}>
      <SimpleScroller>
        <div className={`${prefix}__content`}>
          <FlexComponent isVertical gap={10}>
            {fields.map(({name, ...data}, index) => (
              <PropertyCard
                editable={user.canDoIt($$SETTING.PROP_COL_UPDATE)}
                data={data}
                name={name}
                pKey={data.key}
                key={name}
                index={index}
                setFields={setFields}
              />
            ))}
          </FlexComponent>
        </div>
      </SimpleScroller>
    </div>
  );
};

export default DataFields;
