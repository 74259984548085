import initialTaskState from "../task.initialState";
import { reduxActions as TASK } from "_constants/task.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const post = {
  [TASK.POST_STARTED]: (state) => ({
    ...state,
    post: {
      ...state.post,
      status: LOADING
    }
  }),

  [TASK.POST_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [TASK.POST_SUCCESS]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      data: payload?.data ?? null,
      status: SUCCESS
    },
    all: {
      ...state.all,
      data: (
        Array.isArray(state.all.data) ? [
          ...payload?.data,
          ...state.all.data
        ]
      : null)
    }
  }),

  [TASK.POST_RESET]: (state) => ({
    ...state,
    post: initialTaskState.post
  })
};

export default post;
