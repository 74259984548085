import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as RESOURCE } from "_constants/resource.constants";

const createLink = {
  [RESOURCE.CREATE_LINK_STARTED]: (state) => ({
    ...state,
    post: {
      ...state.post,
      status: LOADING
    }
  }),
  [RESOURCE.CREATE_LINK_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [RESOURCE.CREATE_LINK_SUCCESS]: (state, { payload }) => ({
    ...state,
    post: {
      ...state.post,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    },
    one: {
      ...state.one,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  })
};

export default createLink;
