import React from 'react'
import classNames from "classnames";
import PropTypes from 'prop-types';


const PlayGround = ({component, active, purple}) => {
  const classForComponent = classNames("t-playground", {"is-purple": purple});
  if(!active) return false;
  return (
    <div className={classForComponent}>
      {component}
    </div>
  )
}

export default PlayGround

PlayGround.propTypes = {
  active: PropTypes.bool,
  component: PropTypes.node.isRequired,
  purple: PropTypes.bool,
}
