import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import emptyImg from "_utils/images/emptyImg.png";
import classNames from "classnames";
import { ContextButton, ContextMenu } from "components/organism/index";
import useContextMenu from "_hooks/useContextMenu";
import { toggleSmartEditor } from "_redux/ui/ui.actions";
import { toSmartEditor } from "_redux/file/file.actions";
import { selectUser } from "_redux/user/user.selectors";
import { selectUiSmartEditorVisible } from "_redux/ui/ui.selector";
import { $$FOLDER } from "_constants/permission.constants";
import { useTranslation } from "react-i18next";
import { NubeskIcons } from "../index";


const prefix = "a-magnifier-image";

const MagnifierImage = ({
  id,
  isNoBg,
  magnifierProps,
  onRequestSwatch,
  orientation,
  orientationAll,
  setMagnifierProps,
  src
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    contextProps,
    onContextMenu,
    closeContext
  } = useContextMenu({ children: 6 });
  const seVisible = useSelector(selectUiSmartEditorVisible);
  const user = useSelector(selectUser);

  let timer = useRef(null);
  const [loaded, setLoaded] = useState(false);
  const [source, setSource] = useState("");
  const [withoutImage, setWithOutImage] = useState(false);
  const [propImg, setPropImg] = useState();

  const classForComponent = classNames(prefix, {
    [`${prefix}__all--${orientationAll}`]: orientationAll,
    [`${prefix}--${orientation}`]: orientation,
    "without-image": withoutImage,
    "is-hovered": magnifierProps.isOpen
  });
  
  const handleZoom = (n) => {
    if (magnifierProps.zoom + n < 2 || magnifierProps.zoom + n > 6)
      return false;
    setMagnifierProps({
      ...magnifierProps,
      zoom: (magnifierProps.zoom || 2) + n
    });
  };

  const toEdition = async () => {
    let response = await dispatch(toSmartEditor(id));
    dispatch(toggleSmartEditor(response?.data?.[0]));
    closeContext();
  };

  const configSwatch = (e, props) => {
    let { clientX, clientY } = e;
    let { isOpen, imgHeight, imgWidth, sample, zoom } = props;

    if (!isOpen) return;
    
    let output = {
      fileId: id,
      $config: {
        xPct: clientX ? (clientX / imgWidth).toFixed(4) : 0,
        yPct: clientY ? (clientY / imgHeight).toFixed(4) : 0,
        zoomFactor: zoom,
        height: imgHeight
      }
    };

    onRequestSwatch(output);
  };

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  useEffect(() => {
    if (!magnifierProps?.isOpen && magnifierProps?.onClick) {
      let newMagnifier = {...magnifierProps};
      delete newMagnifier.onClick;
    }
  }, [magnifierProps?.isOpen]);

  useEffect(() => {
    setLoaded(false);
    setSource((p) => null);
    setSource(src ? src : emptyImg);
    if (!src) {
      setWithOutImage(true);
      return;
    }
    setWithOutImage(false);
    setMagnifierProps({ ...magnifierProps, url: src });
  }, [src]);
  
  return (
    <div className={classForComponent}>
      <img 
        className="u-is-hidden"
        src={source}
        onLoad={({target}) => {
          setPropImg({
            height: target?.height,
            width: target?.width
          });
          setLoaded(true);
        }}
      />
      {loaded ? (
        <img
          className={`${prefix}__image ${emptyImg === source ? "--is-empty" : ""}`}
          style={{
            cursor: magnifierProps.isOpen ? "none" : undefined,
            height: propImg?.width < propImg?.height ? "100%" : "",
            width: propImg?.width > propImg?.height ? "100%" : ""
          }}
          src={source}
          onContextMenu={onContextMenu}
          onClick={(e) => {
            if (magnifierProps.isOpen) {
              const { ctrlKey, altKey } = e;
              let negative = -1;
              if (ctrlKey && altKey) return handleZoom(negative);
              if (ctrlKey) return handleZoom(1);
              return;
            }
            setMagnifierProps({
              ...magnifierProps,
              isOpen: true,
            });
          }}
          onWheel={({ deltaY }) => {
            let wheel = Math.sign(deltaY);
            handleZoom(wheel);
          }}
          alt="featured_image"
          onMouseEnter={(e) => {
            if (!src) return;
            const elem = e.currentTarget;
            const { width, height } = elem.getBoundingClientRect();
            setMagnifierProps({
              ...magnifierProps,
              imgWidth: width,
              imgHeight: height,
            });
          }}
          onMouseMove={(e) => {
            const { currentTarget: elem } = e;
            const { top, left } = elem.getBoundingClientRect();
            const x = e.pageX - left;
            const y = e.pageY - top;
            setMagnifierProps({ ...magnifierProps, x, y });
          }}
          onMouseLeave={() => {
            if (!contextProps.isOpen) {
              setMagnifierProps({
                ...magnifierProps,
                isOpen: false,
                sample: false,
                zoom: 2
              });
            }
          }}
          onError={() => setSource(emptyImg)}
          onClick={magnifierProps?.onClick ?? (() => {})}
        />
      ) : (
        <NubeskIcons className="u-spin u-purple" icon="loading" />
      )}
      {magnifierProps?.isOpen ? (
        <>
          <div
            style={{
              transition: contextProps.isOpen ? ".3s" : "0s",
              pointerEvents: "none",
              position: "absolute",
              top: `${
                magnifierProps.y -
                (magnifierProps?.magnifierHeight / magnifierProps.zoom + 1) / 2
              }px`,
              left: `${
                magnifierProps.x -
                (magnifierProps?.magnifierWidth / magnifierProps.zoom + 1) / 2
              }px`,
              height: magnifierProps?.magnifierHeight / magnifierProps.zoom + 1,
              width: magnifierProps?.magnifierWidth / magnifierProps.zoom + 1,
              background:
                "linear-gradient(45deg,var(--color-purple) 0%,var(--color-blue) 100%",
              borderRadius: magnifierProps.sample ? "50%" : 10,
              opacity: "0.5",
            }}
          ></div>
        </>
      ) : null}
      <ContextMenu
        onMouseLeave={() => {
          setMagnifierProps({ ...magnifierProps, isOpen: false });
          closeContext();
        }}
        onMouseOver={() => {
          setMagnifierProps({ ...magnifierProps, isOpen: true });
        }}
        {...contextProps}
      >

        <ContextButton
          name={t("products:contextualMenu.zoomIn")}
          icon="zoomIn"
          shortcut="Ctrl + Click"
          disabled={magnifierProps.zoom === 6}
          onClick={() => handleZoom(1)}
        />
        <ContextButton
          name={t("products:contextualMenu.zoomOut")}
          icon="zoomOut"
          shortcut="Ctrl + Alt + Click"
          onClick={() => handleZoom(-1)}
          disabled={magnifierProps.zoom === 2}
        />
        <div
          style={{
            margin: "10px 0",
            height: 1,
            background: "var(--color-purple)",
            width: "100%",
          }}
        />{" "}
        <ContextButton
          name={t("products:contextualMenu.takeSwatch")}
          icon="dropper"
          onClick={() => {
            setMagnifierProps({
              ...magnifierProps,
              sample: true,
              magnifierWidth: 500,
              magnifierHeight: 500,
              zoom: magnifierProps.zoom,
              onClick: (e) => configSwatch(e, magnifierProps)
            });
            closeContext();
          }}
        />
        {isNoBg && !seVisible && user.canDoIt($$FOLDER.EDIT) && (
          <ContextButton
            name={t("products:contextualMenu.smartEditor")}
            icon="edit"
            iconSize={20}
            onClick={toEdition}
          />
        )}

      </ContextMenu>
    </div>
  );
};

export default MagnifierImage;
