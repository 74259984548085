import qs from "qs";

const baseService = {
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json"
  },
  paramsSerializer: function (params) {
    return qs.stringify(params, {
      arrayFormat: "index",
      encode: false,
    });
  },
};

export default baseService;
