import React from "react";
import classNames from "classnames";
import { FlexComponent } from "components/templates";
import { Title, Logo } from "components/atoms";
import { NotificationButton, UserButton } from "components/molecules";
import { useAppSettings } from "_hooks";

const Header = () => {
  const classForComponent = classNames("o-header", {});
  const { appLookAndFeel } = useAppSettings();

  return (
    <header className={classForComponent}>
      <FlexComponent>
        {appLookAndFeel?.customerLogo ?
          <img alt="logo" src={appLookAndFeel.customerLogo}/>
          :
          <Logo color="var(--color-white)" />
        }

        <Title content={appLookAndFeel?.account?.name ?? ""} />
      </FlexComponent>
      <FlexComponent>
        <NotificationButton />
        <UserButton />
      </FlexComponent>
    </header>
  );
};

export default Header;
