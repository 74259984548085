import ObjectActions from './ShapeContent/ObjectActions';
import SizePosSection from './ShapeContent/SizePosSection';
import StyleSection from './ShapeContent/StyleSection';
import TextSection from './ShapeContent/TextSection';
import { SIZE_POS_SECTION, STYLE_SECTION, TEXT_SECTION } from '_constants/nbskEditor.constants';
import useNbskEditor from '_hooks/useNbskEditor';
import AssociatedSections from './ShapeContent/AssociatesSection';
import AiSection from './AiSection';

const ShapeTabContent = ({
  aiConfig,
  setBackgrounds,
  allowedSection,
  bgImages,
  canvas,
  changeHandler,
  objectProps,
  onChangeNbskProps,
  prefix,
  selection,
  wasMoved
}) => {
  
  const { addImage, isVisible, removeObjects, sendToBack } = useNbskEditor(canvas);

  return (
    <>
      {!!selection && 
        <ObjectActions
          canvas={canvas}
          prefix={prefix}
        />
      }

      <AssociatedSections 
        canvas={canvas}
        prefix={prefix}
        selection={selection}
        onAssociate={(props) => onChangeNbskProps(selection, props)}
      />

      {allowedSection.includes("ai") &&
        <AiSection 
          aiConfig={aiConfig}
          bgImages={bgImages}
          canvas={canvas}
          onSelectBg={({ url }) => {
            let objs = canvas.getObjects().filter((o) => o?.nbskProperties?.background);
            removeObjects(objs);
      
            addImage(url, {
              callback: (obj) => sendToBack(obj, canvas),
              selectable: false,
              evented: false,
              hoverCursor: 'pointer',
              left: 0,
              lockMovementX: true,
              lockMovementY: true,
              nbskProperties: {
                background: true,
                lock: true
              },
              top: 0,
            });
          }}
          prefix={prefix}
          setBackgrounds={setBackgrounds}
          wasMoved={wasMoved}
        />
      }
      
      {selection?.length === 1 && isVisible(selection, SIZE_POS_SECTION) &&
        <SizePosSection
          canvas={canvas}
          onChange={({ name, value }) => changeHandler(selection, name, value)}
          prefix={prefix}
          selection={selection}
          values={objectProps}
        />
      }
        
      {selection?.length === 1 && isVisible(selection, TEXT_SECTION) &&
        <TextSection
          canvas={canvas}
          prefix={prefix}
          onChange={({ name, value }) => changeHandler(selection, name, value )}
          selection={selection}
          values={objectProps}
        />
      }

      {selection?.length === 1 && isVisible(selection, STYLE_SECTION) &&
        <StyleSection
          canvas={canvas} 
          prefix={prefix}
          onChange={({ name, value }) => changeHandler(selection, name, value )}
          selection={selection}
          values={objectProps}
        />
      }
        
    </>
  )
};

export default ShapeTabContent;
