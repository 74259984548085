import { IDLE } from "_constants/redux.constants";

const initialMediaLibState = {
  all: {
    data: null,
    error: null,
    status: IDLE
  },
  remove: {
    data: null,
    error: null,
    status: IDLE
  },
  own: {
    data: null,
    error: null,
    status: IDLE
  },
  post: {
    data: null,
    error: null,
    status: IDLE
  },
  shared: {
    data: null,
    error: null,
    status: IDLE
  },
};

export default initialMediaLibState;
