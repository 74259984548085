/**
 * 
 * @param { integer } num 
 * @returns 
 */
const alphabetColumn = (num) => {
  const length = Math.ceil(num / 26);
  let output = [];
  for (let i = 0; i < length; i++) {
    if ((i + 1) === length) {
      if (num % 26) {
        output.push(String.fromCharCode((num % 26) + 64));
      } else {
        console.log("char code 90");
        output.push(String.fromCharCode(90));
      }
    } else {
      output.push(String.fromCharCode((i) + 65))
    }
  }
 return output.join("");
};

export default alphabetColumn;
