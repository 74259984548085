import { reduxActions as UI } from "_constants/ui.constants";

const misc = {
  [UI.TOGGLE_FILTER]: (state) => ({
    ...state,
    filter: {
      ...state.filter,
      isOpen: !state.filter.isOpen,
    },
  }),
  [UI.TABS_HEIGHT]: (state, { payload }) => ({
    ...state,
    filter: {
      ...state.filter,
      height: payload,
    },
  }),
  [UI.PREVIOUS_PAGE]: (state, { payload }) => ({
    ...state,
    previous: payload,
  }),
  [UI.TOGGLE_REFRESH]: (state, { payload }) => ({
    ...state,
    refresh: {
      ...state.refresh,
      [payload.view]: !state.refresh[payload.view],
      [`${payload.view}Data`]: payload.data
    },
  })
};

export default misc;
