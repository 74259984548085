import { SKUCARD } from "_constants/reducers.constants";
import toInfo from "./toInfo";
import toPhotos from "./toPhotos";

const skuCard =  {
  [SKUCARD.TO_INFO]: toInfo,
  [SKUCARD.TO_PHOTOS]: toPhotos
};

export default skuCard;
