import { useTranslation } from "react-i18next";
import { FlexTable } from "components/templates";
import { Sorter } from "components/molecules";
import { Badge, Button, FlexCell, NubeskIcons } from "components/atoms";
import { useAttribute, useModal } from "_hooks";
import { FAILURE, SUCCESS } from "_constants/redux.constants";
import { alphabetColumn } from "_utils/array";

const GlobalAttributes = ({ prefix, attributes }) => {
  const { moveIt } = useAttribute();
  const { t } = useTranslation();
  const { openModal } = useModal();

  return (
    <FlexTable
      className={`${prefix}__table`}
      bodyClass={`${prefix}__table-body`}
      headerCol={9}
      rowCol={9}
      showEmpty={[SUCCESS, FAILURE].indexOf([]) !== -1}
      gridTemplateColumns="60px minmax(100px,150px) minmax(200px, 1fr) minmax(130px, 190px) minmax(140px, 200px) minmax(110px, 170px) minmax(150px, 210px) minmax(110px, 170px)"
      emptyData={t("settings:layoutAttributes.table.emptyData")}
      headers={<>
        <FlexCell>{t("settings:layoutAttributes.table.column")}</FlexCell>
        <FlexCell>{t("settings:layoutAttributes.table.id")}</FlexCell>
        <FlexCell>{t("settings:layoutAttributes.table.name")}</FlexCell>
        <FlexCell justify="center">{t("settings:layoutAttributes.table.required")}</FlexCell>
        <FlexCell justify="center">{t("settings:layoutAttributes.table.type")}</FlexCell>
        <FlexCell justify="center">{t("settings:layoutAttributes.table.isFilter")}</FlexCell>
        <FlexCell justify="center">{t("settings:layoutAttributes.table.editable")}</FlexCell>
        <FlexCell justify="center"><NubeskIcons icon="settings" size="20px" /></FlexCell>
      </>}
      rows={Array.isArray(attributes) && attributes.map((a, i) => {
        return (
          <>
            <FlexCell className="u-purple" align="center">
              {alphabetColumn(i + 1)}
              <Sorter 
                disabledDown={attributes.length === (i + 1)}
                disabledUp={i === 0}
                onClickDown={() => {
                  moveIt(a, attributes?.filter((r) => r.index === a.index + 1).pop());
                }}
                onClickUp={() => {
                  moveIt(a, attributes?.filter((r) => r.index === a.index - 1).pop())
                }}
              />
            </FlexCell>
            <FlexCell>
              <Badge content={a.key} border="purple"/>
            </FlexCell>
            <FlexCell>{a.name}</FlexCell>
            <FlexCell className="u-dark-gray" justify="center">
              {a?.flag?.require ? t("common:words.yes") : t("common:words.no")}
            </FlexCell>
            <FlexCell className="u-dark-gray" justify="center">{a?.class?.datatype ? t(`settings:layoutAttributes.dataType.${a.class.datatype}`): ""}</FlexCell>
            <FlexCell className="u-dark-gray" justify="center">
              {a?.flag?.toFilter ? t("common:words.yes") : t("common:words.no")}
            </FlexCell>
            <FlexCell className="u-dark-gray" justify="center">
              {`
                ${a?.flag?.editable ? t("common:words.yes") : t("common:words.no")} |
                ${a?.flag?.overwritable ? t("common:words.yes") : t("common:words.no")}
              `}              
            </FlexCell>
            <FlexCell className="u-purple" justify="center">
              {!a?.flag?.key &&
                <Button
                  content="Configurar"
                  leftIcon={<NubeskIcons icon="settings" />}
                  onClick={() => openModal({
                    header: {
                      icon: {
                        size: "22px",
                        type: "settings"
                      },
                      text: t("settings:layoutAttributes.modal.globalTitle")
                    },
                    modalProps: {
                      attributeId: a._id
                    },
                    typeModal: "globalAttribute"
                  })}
                  type="underline"
                  xs
                />
              }
            </FlexCell>
          </>
        );
      })}
    />
  );
};

export default GlobalAttributes;
