import products from "./products";

export const getDrawFields = (cDrawing, component) => {
  switch (component) {
    case 'skuCard':
      return products.skuCard(cDrawing[component]);

    case 'skuPhotoDetails':
      return products.skuPhotoDetails(cDrawing[component]);
    default:
      return [];
  }
};

export default getDrawFields;
