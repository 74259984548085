import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { closeModal } from "_redux/ui/ui.actions";

const prefix = "o-confirm-modal";

const ConfirmModal = ({ question, onConfirm }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        {question}
      </div>

      <div className={`${prefix}__buttons`}>
        <Button
          content={t("common:form.cancel")}
          onClick={() => dispatch(closeModal())}
          sm
          type="underline"
        />
        <Button
          content={t("common:form.confirm")}
          onClick={onConfirm}
          sm
          type="purple"
        />
      </div>
    </div>
  );
};

ConfirmModal.propTypes = {
  question: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onConfirm: PropTypes.func
};

ConfirmModal.defaultProps = {
  onConfirm: () => {}
};

export default ConfirmModal;
