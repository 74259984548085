import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import moment from "moment";
import { useProductFilter } from "_hooks";
import { DateRange } from "components/molecules";
import { Button, NubeskIcons } from "components/atoms";
import {
  selectProductAllStore,
  selectProductCountPieceStore,
  selectProductCountStore
} from "_redux/product/product.selector";
import { LOADING } from "_constants/redux.constants";
import { Select } from "../Inputs";

const prefix = "m-filter-date-range";

const FilterDateRange = () => { 
  const  { t } = useTranslation();
  const { changeFilters, datesHandler } = useProductFilter();

  const [active, setActive] = useState(false);
  const [keyFilter, setKeyFilter] = useState({
    label: t("products:filters.createdAtLabel"),
    value: "createdAt"
  });
  const [from, setFrom] = useState();
  const [to, setTo] = useState();

  const { status: productStatus } = useSelector(selectProductAllStore);
  const { status: countStatus } = useSelector(selectProductCountStore)
  const { status: countPieceStatus } = useSelector(selectProductCountPieceStore);

  const classForBackdrop = classNames(`${prefix}__backdrop`, {
    [`is-open`]: active
  });

  const assingRange = ({ startDate, endDate }) => {
    setFrom(startDate);
    setTo(endDate);
  };

  const rangeToParams = async () => {
    let newFilters;
    if (keyFilter?.value && from && to && from !== to) {
      newFilters = await datesHandler({
        [keyFilter.value]: {
          f: moment(from).format("YYYY-MM-DD"),
          t: moment(to).add('days', 1).format("YYYY-MM-DD")
        }
      });
    } else {
      newFilters = await datesHandler();
    }

    changeFilters(newFilters, true);
  };

  useEffect(() => {
    rangeToParams();
  }, [keyFilter, from, to])

  return (
    <div className={prefix}>
      <Button
        disabled={
          productStatus === LOADING ||
          countPieceStatus === LOADING ||
          countStatus === LOADING
        }
        content={<NubeskIcons icon="calendar" />}
        onClick={() => setActive(!active)}
        type="primary"
        isActive={active}
      />
      <div className={classForBackdrop}>
        <div className={`${prefix}__container`}>
          <Select
            options={[
              {
                label: t("products:filters.createdAtLabel"),
                value: "createdAt"
              },
              {
                label: t("products:filters.updatedAtLabel"),
                value: "updatedAt"
              }
            ]}
            orientation="left"
            onChange={(a) => setKeyFilter(a)}
            value={keyFilter}
          />
          <DateRange
            onChange={assingRange}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterDateRange;
