import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { PreviousNext } from "components/molecules";
import { Button, NubeskIcons } from "components/atoms";
import { TaskItemsStatusManager } from "components/organism";
import { selectPage, selectPrevious } from "_redux/ui/ui.selector";
import { changePageDetails, changeToolbar, closeLoader } from "_redux/ui/ui.actions";

const prefix = "o-bottom-menu";

const BottomMenu = ({ current, total, onClickNav, task, itemData, productId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();
  const history = useHistory();

  const page = useSelector(selectPage);

  useEffect(() => {
    if (page) {
      onClickNav(page);
      dispatch(changePageDetails(null));
    }
  }, [page]);

  return (
    <div className={prefix}>
      <Button
        leftIcon={<NubeskIcons icon="arrowLeft" />}
        content={t("common:general.navigation.returnTo", {
          to: "products"
        })}
        type={"color"}
        className={`${prefix}__return`}
        onClick={() => {
          dispatch(closeLoader());
          dispatch(changeToolbar("products"))
          history.push({
            pathname: "/products",
            search: search || ""
          });
        }}
      />
      {task && itemData &&
        <TaskItemsStatusManager
          {...{task, itemData, productId}}
        />
      }

      <PreviousNext onClickNav={onClickNav} current={current} total={total} />
    </div>
  );
};

export default BottomMenu;
