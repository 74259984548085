import initialSyncfoniaState from "../syncfonia.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { reduxActions as SYNCFONIA } from '../../syncfonia.constants';

const updateGtin = {
  [SYNCFONIA.UPDATE_GTIN_STARTED]: (state) => ({
    ...state,
    updateGtin: {
      ...state.updateGtin,
      status: LOADING
    }
  }),
    
  [SYNCFONIA.UPDATE_GTIN_FAIL]: (state, { payload }) => ({
    ...state,
    updateGtin: {
      ...state.updateGtin,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [SYNCFONIA.UPDATE_GTIN_SUCCESS]: (state, { payload }) => ({
    ...state,
    updateGtin: {
      ...state.updateGtin,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [SYNCFONIA.UPDATE_GTIN_RESET]: (state) => ({
    ...state,
    updateGtin: initialSyncfoniaState.updateGtin
  })
};

export default updateGtin;
