import React, { useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import { selectTooltip } from "_redux/ui/ui.selector";
import { useSelector } from "react-redux";
const prefix = "a-tooltip";

const Tooltip = () => {
  const tooltip = useSelector(selectTooltip);
  let { active, tip = "", top, left, variant } = tooltip;
  const classForComponent = classNames(prefix, {
    [`${prefix}--${variant}`]: variant,
  });
  const [spanWidth, setSpanWidth] = useState(null);
  const spanRef = useRef(null);
  useLayoutEffect(() => {
    if (active && spanRef.current) {
      setSpanWidth(spanRef.current.offsetWidth);
    }
  }, [active]);
  if (!active) return false;
  return (
    <>
      {active ? (
        <span
          ref={spanRef}
          className={classForComponent}
          style={
            left + spanWidth > window.innerWidth
              ? { top: top + 18, right: 0 }
              : {
                  top: top + 18,
                  left: left + 5,
                }
          }
        >
          {tip}
        </span>
      ) : null}
    </>
  );
};

Tooltip.propTypes = {
  active: PropTypes.bool,
  tip: PropTypes.string,
};
export default Tooltip;
