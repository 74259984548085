import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useAppSettings } from "_hooks";
import { SimpleScroller } from "components/organism";
import Cell from "./Cell";
import { Button, Hr, NubeskIcons } from "components/atoms";
import { openModal, previousPage } from "_redux/ui/ui.actions";
import { selectCatalogAllStore } from "_redux/catalog/catalog.selector";
import { selectUser } from "_redux/user/user.selectors";
import { ARCHIVED, OWNER, SHARED } from "_constants/catalog.constants";
import { find as fetchCatalogs } from "_redux/catalog/catalog.actions";
import { selectProductAll, selectProductAllStore, selectProductCountStore } from "_redux/product/product.selector";
import { LOADING } from "_constants/redux.constants";

const prefix = "m-table-catalogs";

const TableCatalogs = ({ area, withSelection, type = OWNER }) => {
  const location = useLocation();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: catalogList } = useSelector(selectCatalogAllStore);
  const { status: productStatus } = useSelector(selectProductAllStore);
  const { status: countStatus } = useSelector(selectProductCountStore)
  const user = useSelector(selectUser);
  const classForComponent = classNames(prefix, {
    "has-selection": withSelection,
  });

  const { appColumnKeyProps } = useAppSettings();

  const fetch = (params = {}) => {
    switch (type) {
      case OWNER:
        params.w = {
          ...params.w,
          userId: user._id,
        };
        break;
      case SHARED:
        params.w = {
          ...params.w,
          share: user._id,
        };
        break;
      case ARCHIVED:
        params.w = {
          ...params.w,
          status: ARCHIVED,
        };
        break;
      default:
        return;
    }
    dispatch(fetchCatalogs(params));
  };

  useEffect(() => fetch(), [type]);

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__title`}>
        <span>{area}</span>
        {!withSelection ? 
        <Button
            leftIcon={
              <NubeskIcons
                icon="plus"
                style={{ marginRight: 10, fontSize: 18 }}
              />
            }
            content={t("catalogs:general.toolbar.new")}
            type="underline"
            style={{ fontSize: "1.8rem", padding: "1rem 3rem" }}
            onDragOver={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            
            onClick={() =>
              dispatch(
                openModal({
                  typeModal: "newCatalog",
                  header: {
                    text: t("catalogs:general.new_catalog_modal.createWithSelection"),
                    icon: { type: "plus", size: 18 },
                  },
                })
              )
            }
          /> : null}
      </div>{" "}
      {withSelection ? (
        <div className={`${prefix}__add-new`}>
          {" "}
          <Button
            leftIcon={
              <NubeskIcons
                icon="plus"
                style={{ marginRight: 10, fontSize: 18 }}
              />
            }
            content={t("catalogs:general.new_catalog_modal.createWithSelection")}
            type="underline"
            style={{ fontSize: "1.8rem", padding: "1rem 3rem" }}
            onDragOver={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
            
            // onDrop={(e) => {
            //   e.preventDefault();
            //   dispatch(
            //     openModal({
            //       typeModal: "newCatalog",
            //       header: {
            //         text: t("catalogs:general.new_catalog_modal.createWithSelection"),
            //         icon: { type: "plus", size: 18 },
            //       },
            //     })
            //   );
            // }}
            onClick={() =>
              dispatch(
                openModal({
                  closeBackdrop: false,
                  typeModal: "newCatalog",
                  header: {
                    text: t("catalogs:general.new_catalog_modal.createWithSelection"),
                    icon: { type: "plus", size: 18 },
                  },
                })
              )
            }
          />

          {catalogList?.length ? (
            <Hr className={`${prefix}__or`} content={t("common:form.or")} />
            // <span></span>
          ) : null}
        </div>
      ) : null}
      {
        catalogList?.length ? (
          <>
            <div className={`${prefix}__header`}>
              <span className={`${prefix}__header-field`}>
                {t("catalogs:table.header.name")}
              </span>
              <span className={`${prefix}__header-field`}>
                {t("catalogs:table.header.skus", {
                  key: appColumnKeyProps?.name?.toUpperCase(),
                })}
              </span>
              <span className={`${prefix}__header-field`}>
                {t("catalogs:table.header.created")}
              </span>
            </div>
            <div className={`${prefix}__cells`}>
              <SimpleScroller disableTracksWidthCompensation>
                <div className={`${prefix}__cells-content`}>
                  {catalogList?.map(({ _id, key, share, foldersId, createdAt }) => (
                    <Cell
                      _id={_id}
                      date={createdAt}
                      key={_id}
                      name={key}
                      onClick={async () => {
                        if (productStatus === LOADING || countStatus === LOADING) return;
                        let tmpLocation = Object.assign({}, location);;
                        dispatch(previousPage({ location: tmpLocation }));
                        history.push(`/products/catalog/${_id}`);
                      }}
                      qty={foldersId?.length ?? 0}
                      share={share}
                      type={type}
                      views={null}
                      withSelection={withSelection}
                    />
                  ))}
                </div>
              </SimpleScroller>
            </div>
          </>
        ) : null
      }
    </div>
  );
};

export default TableCatalogs;

TableCatalogs.propTypes = {
  list: PropTypes.array,
};
