import qs from 'qs';
import jsonUrl from "json-url";

const codec = jsonUrl("lzma");

const urlToJson = async (string, extras = false) => {
  if (string === "" || string === false || typeof string !== 'string') return {};

  string = string.replace(/^[?]/, '');

  let search = qs.parse(string, {
    arrayFormat: 'indices'
  });
  
  let output = await codec.decompress(search?._);
  
  if (extras) {
    return {
      ...search ?? {},
      _: output, 
    };
  } else {
    return output;
  }
};

export default urlToJson;
