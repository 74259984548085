import { reduxActions as ATTRIBUTE } from "_constants/attribute.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialAttributeState from "../attribute.initialState";

const moveIt = {
  [ATTRIBUTE.MOVEIT_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),

  [ATTRIBUTE.MOVEIT_SUCCESS]: (state, { payload }) =>  {
    return ({
      ...state,
      all: {
        ...state.all,
        data: (state.all.data?.map((r) => {
          for (let d of payload?.data) {
            if (d._id === r._id) {
              r.index = d.index;
              break;
            }
          }
          return r;
        }).sort((a, b) => (a?.index - b?.index)))
      },
      put: {
        ...state.put,
        data: payload?.data,
        status: SUCCESS,
      }
    })
  },

  [ATTRIBUTE.MOVEIT_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [ATTRIBUTE.MOVEIT_RESET]: (state) => ({
    ...state,
    all: initialAttributeState.all
  })
}

export default moveIt;
