import initialTaskState from "../task.initialState";
import { reduxActions as TASK } from "_constants/task.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const removeFrom = {
  [TASK.REMOVE_FROM_STARTED]: (state) => ({
    ...state,
    removeFrom: {
      ...state.removeFrom,
      status: LOADING
    }
  }),

  [TASK.REMOVE_FROM_FAIL]: (state, { payload }) => ({
    ...state,
    removeFrom: {
      ...state.removeFrom,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [TASK.REMOVE_FROM_SUCCESS]: (state, { payload }) => ({
    ...state,
    removeFrom: {
      ...state.removeFrom,
      data: payload?.data ?? null,
      status: SUCCESS
    },
    items: {
      ...state.items,
      data: (() => {
        let output = [...state.items.data];
        let ids = payload?.data?.[0]?.foldersId ?? [];
        output = output.filter((r) => {
          if (ids.indexOf(r._id) !== -1) {
            return true;
          }
          return false;
        });

        return output;
      })()
    }, 
    one: {
      ...state.one,
      data: (() => {
        if (!state?.one?.data) return state?.one?.data;
        let output = {...state?.one?.data};
        output.foldersId = payload?.data?.[0]?.foldersId;
        output.process = payload?.data?.[0]?.process;
        
        return output;
      })()
    }
  }),

  [TASK.REMOVE_FROM_RESET]: (state) => ({
    ...state,
    removeFrom: initialTaskState.removeFrom
  })
};

export default removeFrom;
