import { API, WEB } from '_constants/request.constants';
import { reduxActions as USER, ACTIVE } from '_constants/user.constants';
import setCookie from '_utils/cookie/setCookie';

export const isLoggedIn = () => (dispatch) => dispatch({
  [WEB]: {
    types: [USER.IS_LOGGED_IN_STARTED, USER.IS_LOGGED_IN_SUCCESS, USER.IS_LOGGED_IN_FAIL],
    endpoint: '/auth/loggedin',
    reqType: 'GET',
    feedback: {
      error: false,
      success: false
    }
  }
});


export const login = ({ email, password }) => (dispatch) => dispatch({
  [WEB]: {
    types: [USER.LOGIN_STARTED, USER.LOGIN_SUCCESS, USER.LOGIN_FAIL],
    endpoint: '/auth/login',
    reqType: 'POST',
    data: {
      email,
      password
    },
    feedback: {
      error: true,
      success: {
        area: "auth:login.feedback.success.area",
        info: "auth:login.feedback.success.info"
      }
    }
  }
});


export const logout = () => (dispatch) => {
  let now = Date.now();
  setCookie("Nubesk_v2.0_session", now, 0);
  localStorage.clear();
  return dispatch({
    [WEB]: {
      types: [USER.LOGOUT_STARTED, USER.LOGOUT_SUCCESS, USER.LOGOUT_FAIL],
      endpoint: '/auth/logout',
      reqType: 'POST'
    }
  });
};

export const find = (params = {}, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [USER.ALL_STARTED, USER.ALL_SUCCESS, USER.ALL_FAIL];
  }

  return dispatch({
    [API]: {
      endpoint: "/user",
      feedback: {
        error: true
      },
      ...extras,
      params: {
        ...params,
        w: {
          status: ACTIVE,
          ...params.w ?? {}
        }
      }
    }
  });
};

export const post = (data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      USER.POST_STARTED,
      USER.POST_SUCCESS,
      USER.POST_FAIL
    ];
  }

  return dispatch({
    [API]: {
      endpoint: "/user",
      reqType: 'POST',
      feedback: {
        error: true
      },
      ...extras,
      data
    }
  })
};

/**
 * 
 * @param { Array | String } ids 
 * @param { Array | Object } data 
 * @param { Object } extras 
 * @returns 
 */
export const put = (ids, data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      USER.PUT_STARTED,
      USER.PUT_SUCCESS,
      USER.PUT_FAIL
    ];
  }

  if (!Array.isArray(ids)) ids = [ids];
  if (!Array.isArray(data)) data = [data];

  return dispatch({
    [API]: {
      endpoint: "/user",
      reqType: 'PUT',
      feedback: {
        error: true
      },
      ...extras,
      data: {
        c: data,
        w: ids
      }
    }
  })
};


export const forgot = (data, extras = {}) => (dispatch) => 
  dispatch({
    [WEB]: {
      endpoint: '/auth/forgot',
      reqType: 'POST',
      data: {
        ...data,
        reset: "password"
      },
      types: [USER.FORGOT_STARTED, USER.FORGOT_SUCCESS, USER.FORGOT_FAIL],
      feedback: {
        success: {
          area: "auth:forgot.feedback.area",
          info: "auth:forgot.feedback.info"
        },
        error: true
      },
      ...extras
    }
  });

export const restore = (token, data, extras = {}) => (dispatch) => 
  dispatch({
    [WEB]: {
      endpoint: `/auth/reset/access/${token}`,
      reqType: 'POST',
      data,
      types: [USER.RESTORE_STARTED, USER.RESTORE_SUCCESS, USER.RESTORE_FAIL],
      feedback: {
        success: {
          area: "auth:restore.feedback.area",
          info: "auth:restore.feedback.info"
        },
        error: true
      },
      ...extras
    }
  });

export const forgotReset = () => (dispatch) => 
  dispatch({ type: USER.FORGOT_RESET});

export const restoreReset = () => (dispatch) => 
  dispatch({ type: USER.RESTORE_RESET});
