import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { FlexComponent } from "components/templates";
import { SimpleScroller } from "components/organism";
import { useSort } from "_hooks";
import { NubeskIcons } from "components/atoms";
import FilterOption from "./FilterOptions";
import FilterSwitch from "./FilterSwitch";

const prefix = "m-filter-group";

const FilterGroup = ({
  collection,
  empty,
  field,
  isClean,
  label,
  onChange,
  options,
  values,
  type
}) => {
  const { changeSort, sortBy, order } = useSort();
  const sortedClass = classNames({
    [`is-up`]: order === 1
  });

  const changeHandler = (value) => {
    if (values === undefined) return onChange([value]);
    let selected = [...values ?? []];

    let index = selected.indexOf(value);
    let array = [...selected];
    if (index !== -1) {
      array.splice(index, 1);
    } else {
      array.push(value);
    }

    onChange(array);
  };

  const changeSwitchHandler = (values) => onChange(values);

  if (type === options && 
    (!options?.[0] || Object.keys(options[0]).length === 0))
    return null;

  return (
    <div className={prefix}>
      <span
        className={`${prefix}__title ${sortedClass}`}
        onClick={() => changeSort(`${collection}.${field}`, order === -1 ? 1 : -1, true)}
      >
        {label}
        {type === "options" && sortBy === `${collection}.${field}` ? (
          <NubeskIcons icon="down" />
        ) : null}
      </span>

      <div className={`${prefix}__content`}>
        {type === "dropPaste" && 
          <FilterSwitch
            collection={collection}
            field={field}
            isClean={isClean}
            prefix={prefix}
            onChange={changeSwitchHandler}
            order={order}
            values={values}
          />
        }

        {type === "options" &&
          <SimpleScroller translateContentSizeXToHolder>
            <FlexComponent isVertical sm>
              <FilterOption 
                empty={empty}
                onChange={changeHandler}
                collection={collection}
                field={field}
                prefix={prefix}
                options={options}
                order={order}
                values={values}
              />
            </FlexComponent>
          </SimpleScroller>
        }
      </div>
    </div>
  );
};

FilterGroup.propTypes = {
  type: PropTypes.oneOf(["options", "dropPaste"])
};

FilterGroup.defaultProps = {
  type: "options"
};

export default FilterGroup;
