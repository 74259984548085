import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

const GridLayout = ({ children, isTasks, settingCards, settingsAdaptations, settingsLayers }) => {
  const prefix = "t-grid-layout";
  const classForComponent = classNames(prefix, {
    "is-tasks": isTasks,
    "is-setting-cards": settingCards,
    "is-settings-adaptations-cards": settingsAdaptations,
    "is-settings-layer-cards": settingsLayers
  });

  return (
    <div draggable={false} className={classForComponent}>
      {children}
    </div>
  );
};

export default GridLayout;

GridLayout.propTypes = {
  children: PropTypes.node,
  isTasks: PropTypes.bool,
};
