import { TaskForm } from "components/organism/index";
import { useDispatch } from "react-redux";
import { closeModal } from "_redux/ui/ui.actions";

const prefix = "o-task-edition-modal";

const TaskEditionModal = ({ task }) => {
  const dispatch = useDispatch();

  return (
    <div className={prefix}>
      <TaskForm
        task={task}
        onCancel={() => dispatch(closeModal())}
      />
    </div>
  );
};

export default TaskEditionModal;
