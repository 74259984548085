import { useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { useEffect } from "react";

const prefix = "a-dimension-preview";

const DimensionPreview = ({ 
  background,
  className,
  derivedFrom,
  fadeType,
  h,
  margin,
  overlayer,
  overlayerImg,
  w
}) => {
  const squareRef = useRef();

  const [colors, setColors] = useState({});
  const [scale, setScale] = useState(1);
  const [square, setSquare] = useState({});

  const classForComponent = classNames(prefix, {
    [className]: className
  });

  const classForSquare = classNames(`${prefix}__square`, {
    [`--${fadeType}`]: fadeType
  });

  const calculateDimensions = () => {
    let { width, height } = squareRef.current.getBoundingClientRect();
    const heightAvailable = height - 45;
    const widthAvailable = width - 35;

    let _scale = Math.min(
      widthAvailable / w,
      heightAvailable / h
    );
    setScale(_scale ? _scale : 1);
    setSquare({
      width: w * _scale,
      height: h * _scale,
    });
  };

  useEffect(() => {
    if (squareRef.current) {
      calculateDimensions();
    }
  }, [derivedFrom, h, margin, w]);

  useEffect(() => {
    let output = {};
    if (Array.isArray(background)) {
      for (let i in background) {
        output[`--color${i}`] = background[i];
      }
    } else {
      output["--color0"] = background;
    }

    setColors(output);
  }, [background]);

  return (
    <div className={classForComponent} ref={squareRef} style={{...{
      "--width": `${square.width}px`,
      "--height": `${square.height}px`,
      }, ...colors}}>
      <div className="--transparent">
        <div className={classForSquare}>
          {/[\w\d\W]*(-nobgtrim)$/gi.test(derivedFrom) && (
            <div className={`${prefix}__content-margin`} style={{
              "--width": square.width ? `${square.width}px` : "100%",
              "--height": square.height ? `${square.height}px` : "100%",
              "--l0": margin[0] ? `${margin[0] * scale}px`: "0px",
              "--l1": margin[1] ? `${margin[1] * scale}px`: "0px",
              "--l2": margin[2] ? `${margin[2] * scale}px`: "0px",
              "--l3": margin[3] ? `${margin[3] * scale}px`: "0px",
            }}>

            </div>
          )}
          <span className={`${prefix}__square-width`}>{w} px</span>
          <span className={`${prefix}__square-height`}>{h} px</span>
        </div>
        {(overlayerImg || overlayer )&&
          <div className="--overlayer" style={{backgroundImage: `url(${overlayerImg})`}}>
            {overlayer}
          </div>
        }
      </div>
    </div>
  );
};

DimensionPreview.propTypes = {
  background: PropTypes.array,
  fadeType: PropTypes.oneOf(["solid", "radial", "linear"]),
  onScale: PropTypes.func,
  transparent: PropTypes.bool
};

DimensionPreview.defaultProps = {
  background: null,
  fadeType: "solid",
  transparent: true
};

export default DimensionPreview;
