import React, { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import classNames from "classnames";

import { CircleStatus, Title } from "components/atoms";
import { SkuCardContent } from "components/molecules";
import { ImageSlider } from "components/organism";
import { addSelection } from "_redux/ui/ui.actions";

import { useGridTimer } from "_hooks";
import useTooltip from "_hooks/useTooltip";
import { useTranslation } from "react-i18next";

const prefix = "o-sku-card";
const SkuCard = ({ src = [], id, sku, skeleton, selected, status }) => {
  const { t } = useTranslation("products");
  const dispatch = useDispatch();
  const { active, showLoader, actions, dimensions, lastRight, cardRef } =
    useGridTimer();
  const [originalWidth, setOriginalWidth] = useState(undefined);
  const [index, setIndex] = useState(0);
  const classForComponent = classNames(prefix, {
    "is-active": active && !selected,
    "is-selected": selected,
    "is-last": lastRight,
    "u-skeleton": skeleton,
  });

  const {
    injectedProps: { onMouseMove, onMouseOut },
  } = useTooltip({
    variant: "gradient",
    externalTip: `${src?.length} ${t("dataPhotos.images_in")}`,
    delay: 150,
  });

  const handleSelected = () => dispatch(addSelection(id));

  useLayoutEffect(() => {
    setOriginalWidth(cardRef.current.getBoundingClientRect().width);
  }, []);

  return (
    <>
      {active && !selected ? <div></div> : null}
      <div
        {...actions}
        onMouseEnter={!selected ? actions.onMouseEnter : null}
        className={classForComponent}
        onClick={handleSelected}
        ref={cardRef}
        draggable={false}
        style={
          active && !lastRight && !selected
            ? {
                left: dimensions?.left,
                width: dimensions?.width * 2 + 20,
                top: dimensions?.top,
              }
            : active && lastRight && !selected
            ? {
                width: dimensions?.width * 2 + 20,
                top: dimensions?.top,
                right: window.innerWidth - dimensions?.right,
              }
            : null
        }
        data-testid="q-sku-card"
      >
        {showLoader && !selected ? (
          <div className="content">
            <div className="loading">
              <span></span>
            </div>
          </div>
        ) : null}
        {src?.length ? (
          <span
            className={`${prefix}__items`}
            onMouseMove={active ? (e) => onMouseMove(e) : null}
            onMouseOut={active ? (e) => onMouseOut(e) : null}
          >
            ({src?.length})
          </span>
        ) : null}
        <div className={`${prefix}__image`}>
          {!active || selected ? (
            <>
              <div className={`${prefix}__image-status`}>
                <CircleStatus status={status?.reviewMaster} />
                <CircleStatus status={status?.reviewCopy} />
              </div>

              <div className={`${prefix}__image-title`}>
                <Title content={sku} secondary medium />
              </div>
            </>
          ) : null}
          <ImageSlider
            src={src?.[index]}
            setIndex={setIndex}
            index={index}
            isHome
            length={active ? src?.length ?? 0 : undefined}
            draggable={false}
          />
        </div>
        <div className={`${prefix}__content`}>
          <SkuCardContent sku={sku} id={id} active={active} process={status} />
        </div>
      </div>
    </>
  );
};

export default SkuCard;

SkuCard.propTypes = {
  order: PropTypes.number,
};
