import { reduxActions as BILLING } from "_constants/billing.constants";

const dateFilter = {
  [BILLING.DATE_FILTER]: (state, { payload }) => ({
    ...state,
    dateFilter: payload
  })
};

export default dateFilter;
