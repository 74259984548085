import { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IconDrawer } from "components/molecules";

const prefix = "o-generate-adaptation-modal";

const GenerateAdaptationModal = ({ requestAction, onFinish }) => {
  const  { t } = useTranslation();

  const request = useCallback(async() => {
    if (typeof requestAction === "function") {
      await requestAction();
    }
    if (typeof onFinish === "function") {
      onFinish();
    }
  }, [requestAction, onFinish]);

  useEffect(() => {
    request();
  }, [request]);

  return (
    <div className={prefix}>
      <div className={`${prefix}__animation`}>
        <IconDrawer icon="cut" />
        <div>
          <span>{t("products:dataPhotos.generatingAdaptations")}...</span>
        </div>
      </div>    
    </div>
  );
};

export default GenerateAdaptationModal;
