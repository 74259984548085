import { useDispatch, useSelector } from "react-redux";
import {
  updateCatalogProvider as updateCatalogProviderAction,
  updateCatalogRetail as updateCatalogRetailAction,
  updateGtin as updateGtinAction,
  updateGtinProvider as updateGtinProviderAction,
} from "./store/syncfonia.actions";
import { reduxActions as SYNCFONIA } from "./syncfonia.constants";

const useSyncfonia = () => {
  const dispatch = useDispatch();

  const {
    data: gtinData,
    error: gtinError,
    status: gtinStatus 
  } = useSelector((s) => s?.module?.syncfonia?.updateGtin);

  const {
    data: gtinProviderData,
    error: gtinProviderError,
    status: gtinProviderStatus 
  } = useSelector((s) => s?.module?.syncfonia?.updateGtinProvider);

  const {
    data: catalogProviderData,
    error: catalogProviderError,
    status: catalogProviderStatus
  } = useSelector((s) => s?.module?.syncfonia?.updateCatalogProvider);

  const {
    data: catalogRetailData,
    error: catalogRetailError,
    status: catalogRetailStatus
  } = useSelector((s) => s?.module?.syncfonia?.updateCatalogRetail);

  const catalogProviderReset = () => dispatch({ type: SYNCFONIA.UPDATE_CATALOG_PROVIDER_RESET });

  const catalogRetailReset = () => dispatch({ type: SYNCFONIA.UPDATE_CATALOG_RETAIL_RESET });

  const gtinReset = () => dispatch({ type: SYNCFONIA.UPDATE_GTIN_RESET });

  const gtinProviderReset = () => dispatch({ type: SYNCFONIA.UPDATE_GTIN_PROVIDER_RESET });

  /**
   * 
   * @param {Object} data 
   * @param {Object} extras 
   * @returns 
   */
  const updateGtin = (data, extras = {}) => dispatch(updateGtinAction(data, {
    feedback: {
      error: true,
      success: false
    },
    ...extras
  }));

  const updateGtinProvider = (data, extras = {}) => dispatch(updateGtinProviderAction(data, {
    feedback: {
      error: true,
      success: false
    },
    ...extras
  }));

  const updateCatalogProvider = (data, extras = {}) => dispatch(updateCatalogProviderAction(data, {
    feedback: {
      error: true,
      success: true
    },
    ...extras
  }));

  const updateCatalogRetail = (data, extras = {}) => dispatch(updateCatalogRetailAction(data, {
    feedback: {
      error: true,
      success: true
    },
    ...extras
  }));

  return {
    catalogProviderData,
    catalogProviderError,
    catalogProviderReset,
    catalogProviderStatus,
    catalogRetailData,
    catalogRetailError,
    catalogRetailReset,
    catalogRetailStatus,
    gtinData,
    gtinError,
    gtinReset,
    gtinStatus,
    gtinProviderData,
    gtinProviderError,
    gtinProviderReset,
    gtinProviderStatus,
    updateCatalogProvider,
    updateCatalogRetail,
    updateGtin,
    updateGtinProvider
  };
};

export default useSyncfonia;
