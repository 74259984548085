import React from "react";
import CountUp from "react-countup";
import classNames from "classnames";

const CountNumber = ({ count, variant, animation }) => {
  const prefix = "a-count-number";
  const classForComponent = classNames(prefix, {
    [`${prefix}--${variant}`]: variant,
  });
  return animation ? (
    <CountUp className={classForComponent} end={count} duration={1} />
  ) : (
    <span className={classForComponent}>{count}</span>
  );
};

export default CountNumber;
