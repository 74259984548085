import useNbskEditor from "_hooks/useNbskEditor";

const LibGrid = ({ canvas, prefix, data }) => {
  const { addImage } = useNbskEditor(canvas);
  
  const clickHandler = (e) => {
    e.preventDefault();
    let element = e.target;
    if (!e.target.classList.contains(".--media")) {
      element = e.target.closest(".--media");
    }
    
    addImage(element.dataset.resource, {
      nbskProperties: { lock: true }
    });
  };

  return (
    <div className={`${prefix}__lib-grid`} onClick={clickHandler}>
      {data?.map(({ resource, thumbnail }, i) => (
        <div
          className="--media"
          data-resource={resource}
          style={{backgroundImage: `url(${thumbnail})`}}
          key={i}
        >
          <div className="--btn-add"></div>
        </div>
      ))}
    </div>
  )
};

export default LibGrid;
