import initialTaskState from "../task.initialState"
import { ACTIVE, reduxActions as TASK } from "_constants/task.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const all = {
  [TASK.ALL_STARTED]: (state) => ({
    ...state,
    all: {
      ...state.all,
      status: LOADING
    }
  }),

  [TASK.ALL_FAIL]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [TASK.ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [TASK.ALL_REFRESH]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: state.all.data?.map((task) => {
        for (let p of payload) {
          if (task?._id === p?._id) {
            return { ...task, ...p.c };
          }
        }
        
        return task;
      }).filter((r) => r.status === ACTIVE) ?? null
    }
  }),

  [TASK.ALL_RESET]: (state) => ({
    ...state,
    all: initialTaskState.all
  })
};

export default all;
