import { useEffect, useRef, useState } from 'react';
import PropTypes from "prop-types";
import { useTranslation } from 'react-i18next';
import { fabric } from 'fabric';
import { NubeskIcons } from 'components/atoms';
import { useNbskEditor } from '_hooks';
import { SimpleScroller } from '../index';
import ShapeTabContent from './ShapeTabContent';
import LibTabContent from './LibContent/LibContent';
import ShapesSection from './ShapesSection';
import DataSection from './DataSection';
import { DATA_TAB, LIB_TAB, SHAPES_TAB } from '_constants/nbskEditor.constants';

const prefix = "o-nbsk-editor";

let canvas;

const NbskEditor = ({
  aiConfig,
  allowedSection,
  allowedShapes,
  bgImages,
  canvasHeight,
  canvasWidth,
  canvasProps,
  json,
  onInit,
  ...props 
}) => {
  const {
    addImage,
    changeHandler,
    modificationHandler,
    objectProps,
    selection,
    selectionHandler,
    sendToBack,
    setNbskProps,
    setWasMoved,
    wasMoved
  } = useNbskEditor(canvas);
  
  const  { t } = useTranslation();
  const divRef = useRef(null);

  const [isDrawed, setIsDrawed] = useState(false);
  const [scale, setScale] = useState();
  const [tab, setTab] = useState(SHAPES_TAB);
  const [backgrounds, setBackgrounds] = useState();

  const calculateScale = () => {
    let _scale;
    let divHeight = divRef.current.clientHeight;
    let divWidth = divRef.current.clientWidth;
    
    let sHeight = Math.floor((divHeight / canvasHeight) * 100);
    let sWidth = Math.floor((divWidth / canvasWidth) * 100);
    
    _scale = Math.min(sWidth, sHeight)/100;
    _scale = _scale.toFixed(2);
    setScale(_scale < 1 ? _scale : 1);
  }

  const init = () => {
	  canvas = new fabric.Canvas('fbjs');
  
    canvas.setZoom(scale);
    canvas.loadFromJSON(json, () => {
      if (canvas) {
        canvas.fire('object:modified', (e) => console.log(e));
        canvas.on({
          'object:modified': (e) => modificationHandler(e, { canvas }),
          'object:added': (e) => modificationHandler(e, { canvas }),
          'object:removed': (e) => modificationHandler(e, { canvas }),
          // 'object:rotating': selectHandler,
          // 'object:scaling': selectHandler,
          'object:moving': () => {
            if (!wasMoved) setWasMoved(true);
          },
          // 'object:skewing': selectHandler,
          'selection:cleared': selectionHandler,
          'selection:created': selectionHandler,
          'selection:updated': selectionHandler,
          'after:render': () => {}
        });

        canvas.renderAll();
        
        if (bgImages && bgImages?.length) {
          addImage(bgImages[0].url, {
            callback: function (obj) {sendToBack(obj, canvas)},
            selectable: false,
            evented: false,
            hoverCursor: 'pointer',
            left: 0,
            lockMovementX: true,
            lockMovementY: true,
            nbskProperties: {
              background: true,
              lock: true
            },
            top: 0,
          }, canvas);
        }
      }
    });
    if (typeof onInit === "function") {
      onInit({ 
        canvas, 
        json: canvas.toJSON(["nbskProperties"])
      });
      
    }
  };

  useEffect(() => {
    calculateScale();
    
    return () => {
      canvas = undefined;
    };
  }, []);

  useEffect(() => {
    if (scale) init();
  }, [scale]);

  useEffect(() => {
    if (bgImages) setBackgrounds(bgImages);
  }, [bgImages]);

  return (
    <div className={prefix} {...props}>
      <div className={`${prefix}__container`}>
        <div className={`${prefix}__canvas-panel`}>
          <div className={`${prefix}__canvas`} ref={divRef} onClick={(e) => {
            if (e.target.classList.contains("o-nbsk-editor__canvas")) canvas.discardActiveObject().renderAll();
          }}>
            {!!scale && <canvas
              id="fbjs"
              height={canvasHeight * scale}
              style={{ 
                ...canvasProps?.style ?? {}
              }}
              width={canvasWidth * scale}
            ></canvas>}
          </div>
        </div>
        
        <div className={`${prefix}__tools-panel`}>
          <div className="--tabs">
            <div
              className={`--tab ${tab === SHAPES_TAB ? "selected" : ""}`}
              onClick={() => setTab(SHAPES_TAB)}
            > 
              <NubeskIcons icon="photos" />
              {t("nbsk:editor.shapes.tab")}
            </div>

            <div
              className={`--tab ${tab === LIB_TAB ? "selected" : ""}`}
              onClick={() => setTab(LIB_TAB)}
            >
              <NubeskIcons icon="photos" />
              {t("nbsk:editor.lib.tab")}
            </div>

            <div
              className={`--tab ${tab === DATA_TAB ? "selected" : ""}`}
              onClick={() => setTab(DATA_TAB)}
            >
              <NubeskIcons icon="doc" />
              {t("nbsk:editor.data.tab")}
            </div>
          </div>
          
          <div className={`${prefix}__controls`}>
            <div className={`${prefix}__tab-container`}>
              <SimpleScroller isHorizontal={false}>
                {tab === SHAPES_TAB && <ShapeTabContent 
                  aiConfig={aiConfig}
                  allowedSection={allowedSection}
                  bgImages={backgrounds}
                  canvas={canvas}
                  changeHandler={changeHandler}
                  prefix={prefix}
                  selection={selection}
                  setBackgrounds={setBackgrounds}
                  objectProps={objectProps}
                  onChangeNbskProps={setNbskProps}
                  wasMoved={wasMoved}
                />}

                {tab === LIB_TAB && <LibTabContent
                  canvas={canvas}
                  prefix={prefix}
                />}

                {tab === DATA_TAB && <DataSection
                  canvas={canvas}
                  prefix={prefix}
                />}
                
                <ShapesSection
                  allowedShapes={allowedShapes}
                  canvas={canvas}
                  isDrawed={isDrawed}
                  onChange={() => {}}
                  onDrawToggle={setIsDrawed}
                  prefix={prefix}
                  selection={selection}
                />
              </SimpleScroller>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

NbskEditor.propTypes = {
  aiConfig: PropTypes.object,
  allowedSection: PropTypes.array,
  allowedShapes: PropTypes.array,
  bgImages: PropTypes.array,
  canvas: PropTypes.object,
  canvasHeight: PropTypes.number,
  canvasHeight: PropTypes.number,
  json: PropTypes.object,
  onChange: PropTypes.func
};

NbskEditor.defaultProps = {
  aiConfig: null,
  allowedSection: ['actions','associates', 'size', 'text', 'style'],
  allowedShapes: ['rect', 'circle', 'text'],
  bgImages: null,
  canvas: undefined,
  canvasHeight: 200,
  canvasWidth: 200,
  json: { "version": "5.3.0", "objects":[] },

};

export default NbskEditor;
