import React, { useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import classNames from "classnames";

const prefix = "a-avatar";

const Avatar = ({
  isDashed,
  firstName, 
  isTransparent,
  isEmpty,
  lastName, 
  email,
  letters,
  noTooltip, 
  onClick, 
  colorRange
}) => {
  const [initials, setInitials] = useState("");
  const classForComponent = classNames(prefix, {
    '--dashed': isDashed,
    '--empty': isEmpty || (!firstName && !lastName),
    '--transparent': isTransparent
  });

  useEffect(() => {
    setInitials(firstName?.charAt(0) + lastName?.charAt(0));
  }, [firstName, lastName]);

  const randomColor = () => {
    if (isEmpty) return '';
    if (isTransparent) return 'transparent';
    
    if (colorRange && Array.isArray(colorRange)) return getRange();
    const firstAlphabet = firstName.charAt(0).toLowerCase();
    const asciiCode = firstAlphabet.charCodeAt(0);
    const colorNum =
      asciiCode.toString() + asciiCode.toString() + asciiCode.toString();
    const num = Math.round(0xffffff * parseInt(colorNum));
    const r = (num >> 80) & 170;
    const g = (num >> 70) & 170;
    const b = num & 255;
    return `rgb(${r},${g},${b})`;
  };

  const getRange = () => {
    let c = [];
    for (let color of colorRange) {
      if (Array.isArray(color)) {
        let random = Math.trunc(Math.random() * color[1] + color[0]);
        c.push(random);
      } else {
        c.push(color)
      }

      if (c.length === 3) break;
    }
    return `rgb(${c[0]},${c[1]},${c[2]})`
  };

  return (
    <>
      <div
        title={`${firstName || ""} ${lastName || ""} ${email ? `[${email}]` : ''}`}
        onClick={() => onClick?.()}
        className={classForComponent}
        data-for={email}
        style={{
          background: `${randomColor()}`,
        }}
      >
        <span>{initials || letters || "NB"}</span>
      </div>
      {!noTooltip && email ? (
        <ReactTooltip
          /*  clickable={true} */
          /*   delayHide={1000} */
          id={email}
          arrowColor={'transparent'}
          place="top"
          effect="solid"
          backgroundColor={"var(--color-purple)"}
        >
          <span style={{ cursor: "default" }}>
            {email}
          </span>
        </ReactTooltip>
      ) : null}
    </>
  );
};

export default Avatar;
