import { IDLE } from "_constants/redux.constants";

const initialLayerState = {
  canvasEditor: {
    visible: false,
    data: null
  },
  list: {
    data: null,
    error: null,
    status: IDLE
  },
  generate: {
    data: null,
    error: null,
    status: IDLE
  },
  one: {
    data: null,
    error: null,
    status: IDLE
  },
  post: {
    data: null,
    error: null,
    status: IDLE
  },
  preview: {
    data: null,
    error: null,
    status: IDLE
  },
};


export default initialLayerState;
