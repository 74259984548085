import initialMediaLibState from "../mediaLib.initialState";
import { reduxActions as MEDIA_LIB } from "_constants/mediaLib.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const shared = {
  [MEDIA_LIB.SHARED_STARTED]: (state) => ({
    ...state,
    shared: {
      ...state.shared,
      status: LOADING
    }
  }),

  [MEDIA_LIB.SHARED_SUCCESS]: (state, { payload }) => ({
    ...state,
    shared: {
      ...state.shared,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),

  [MEDIA_LIB.SHARED_FAIL]: (state, { payload }) => ({
    ...state,
    shared: {
      ...state.shared,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [MEDIA_LIB.SHARED_RESET]: (state, { payload }) => ({
    ...state,
    shared: initialMediaLibState
  }),

  [MEDIA_LIB.SHARED_SET]: (state, { payload }) => ({
    ...state,
    shared: payload
  })
};

export default shared;
