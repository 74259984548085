import { NBSK_API, PLUGIN_API } from "_constants/request.constants";
import { ACTIVE, reduxActions as RESOURCE } from "_constants/resource.constants";
import { addResource, removeResource } from "_redux/catalog/catalog.actions";

export const find = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [RESOURCE.ALL_STARTED, RESOURCE.ALL_SUCCESS, RESOURCE.ALL_FAIL];
  }

  return dispatch ({
    [NBSK_API]: {
      endpoint: "/resource/zip",
      reqType: "GET",
      ...extras,
      params: {
        ...params,
        w: {
          status: ACTIVE, 
          ...params.w,
        }
      }
    }
  })
};

/**
 *
 * @param {Object} params
 * @returns
 */
 export const findOne = (params, extras = {}) => {
  if (!extras?.types) {
    extras.types = [RESOURCE.ONE_STARTED, RESOURCE.ONE_SUCCESS, RESOURCE.ONE_FAIL];
  }
  return find(
    params,
    extras
  );
};


/**
 * 
 * @param {Object} data 
 * @param {Object} extras 
 * @returns 
 */
export const post = (data, extras = {}) => async (dispatch) => {
  if (!extras?.types) {
    extras.types = [RESOURCE.POST_STARTED, RESOURCE.POST_SUCCESS, RESOURCE.POST_FAIL];
  }
  
  let response = await dispatch({
    [NBSK_API]: {
      endpoint: "/resource",
      reqType: "POST",
      ...extras,
      data
    }
  });

  return response;
};

/**
 * 
 * @param { String | Array } ids 
 * @param { Object | Array } data 
 * @param { Object } extras 
 * @returns 
 */
export const put = (ids, data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      RESOURCE.PUT_STARTED,
      RESOURCE.PUT_SUCCESS,
      RESOURCE.PUT_FAIL
    ];
  }

  return dispatch(post({
    w: Array.isArray(ids) ? ids : [ids],
    c: Array.isArray(data) ? data : [data]
  }, {
    reqType: "PUT",
    ...extras
  }))
};

/**
 * 
 * @param {String} folderId 
 * @param {String} adaptationId 
 * @param {String} fileName 
 * @param {Boolean} regenerate 
 * @param {Int} index 
 * @param {Object} extras
 * @returns 
 */
export const generateZip =  (folderId, adaptationId, fileName, $config, index = false, extras = {}) => 
  async (dispatch, getState) => {
    let data = {
      folderId,
      adaptationId,
      $config,
      $setters: { fileName }
    };
    
    if (index) {
      data.$config = {
        ...data?.$config ?? {},
        index: {
          type: index === 1 ? "values": "range",
          value: index === 1 ? [0]: `0-${index}`
        }
      };
    }

    let response = await dispatch({
      [PLUGIN_API]: {
        endpoint: "/resource/zip",
        reqType: "POST",
        types: [
          RESOURCE.CREATE_LINK_STARTED,
          RESOURCE.CREATE_LINK_SUCCESS,
          RESOURCE.CREATE_LINK_FAIL
        ],
        ...extras,
        data
      }
    });
    
    if (getState()?.catalog?.one?.data?._id && response?.data?.[0]) {
      dispatch(addResource(response?.data?.[0]));
    }

    return response;
  };

/**
 * 
 * @param {String} folderId 
 * @param {String} adaptationId 
 * @param {String} fileName 
 * @param {Boolean} regenerate 
 * @param {Int} index 
 * @returns 
 */
export const generatePackage = (folderId, adaptationId, fileName, regenerate = false, index = false) => 
  generateZip(folderId, adaptationId, fileName, regenerate = false, index = false, {
    types: [
      RESOURCE.POST_STARTED,
      RESOURCE.POST_SUCCESS,
      RESOURCE.POST_FAIL
    ]
  });


export const fetchResources = (productId) => (dispatch) => 
  dispatch(find({
    f: ["_id", "type", "step", "status", "data", "createdAt","expirationDate", "process"],
    w: { 
      type: "zip",
      "process.folderId": productId 
    }
  }, {
    endpoint: "/resource/zip"
  }));

export const resetAllProps = () => (dispatch) => {
  dispatch(resetAll());
  dispatch(resetOne());
  dispatch(resetPost());
};

export const deleteZip = (ids) => async(dispatch, getState) => {
  if (!Array.isArray(ids)) ids = [ids];

  let response = await dispatch(post({
    w: ids
  }, {
    endpoint: "/resource/zip",
    reqType: "DELETE",
    types: [
      RESOURCE.DELETE_ZIP_STARTED,
      RESOURCE.DELETE_ZIP_SUCCESS,
      RESOURCE.DELETE_ZIP_FAIL
    ]

  }));

  if (getState()?.catalog?.one?.data?._id && response?.data?.[0]) {
    dispatch(removeResource(ids));
  }

  return response;
};

export const resetAll = () => (dispatch) =>
  dispatch({ type: RESOURCE.ALL_RESET});

export const resetOne = () => (dispatch) =>
  dispatch({ type: RESOURCE.ONE_RESET});

export const resetPost = () => (dispatch) =>
  dispatch({ type: RESOURCE.POST_RESET});
