import { Button, NubeskIcons } from "components/atoms/index";
import SimpleScroller from "components/organism/Scroller/SimpleScroller";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { useDispatch, useSelector } from "react-redux";

import { selectManageImagesImage } from "_redux/ui/ui.selector";
import "./styles/index.scss";

import { ToolbarImages, ImagesWrapper } from "./components";
import { closeLoader } from "_redux/ui/ui.actions";
const prefix = "v-manage-images";

const ManageImages = ({ images }) => {
  const image = useSelector(selectManageImagesImage);
  const dispatch = useDispatch();
  
  useEffect(() => {
    dispatch(closeLoader());
  }, []);

  return ReactDOM.createPortal(
    <div className="v-manage-images">
      <ToolbarImages />
      <div
        className={`${prefix}__image ${prefix}__image--${images?.orientation}`}
      >
        <img src={image?.original} />
      </div>
      <div className={`${prefix}__grid`}>
        <SimpleScroller>
          <ImagesWrapper orientation={images?.orientation} />
        </SimpleScroller>
        {/* <div className={`${prefix}__grid-upload ${grew ? "grew" : ""}`}>
          {grew ? (
            <div style={{ width: "90%", height: "75%" }}>
              <Button
                className={"close-button"}
                content={<NubeskIcons icon={"down"} size={30} />}
                type={"color"}
                onClick={() => setGrew(false)}
              />
              <UploadDrop file={[]} setFile={() => {}} />
            </div>
          ) : (
            <>
              {" "}
              <Button
                content="Upload New Images"
                type={"color"}
                onClick={() => setGrew(true)}
              />
              <span>^</span>
            </>
          )}
        </div> */}
      </div>
    </div>,
    document.body
  );
};

export default ManageImages;
