import { API, CORE_API, NBSK_API, PLUGIN, PLUGIN_API } from '_constants/request.constants';
import { callRequest } from '_utils/axios';

const CALL_API = API;
const CALL_API_NBSK = NBSK_API;
const CALL_PLUGIN = PLUGIN;
const CALL_PLUGIN_API = PLUGIN_API;
const CALL_CORE_API = CORE_API;

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
const apiMiddleware = (store) => (next) => async(action) => {
  let KEY = CALL_API;
  let callAPI = action[CALL_API];
  let callNBSK = action[CALL_API_NBSK];
  let callPlugin = action[CALL_PLUGIN];
  let callPluginAPI = action[CALL_PLUGIN_API];
  let callCoreAPI = action[CALL_CORE_API];
  let base = CALL_API;
  
  if (callAPI === undefined && callNBSK === undefined && callPlugin === undefined && 
    callPluginAPI === undefined && callCoreAPI === undefined
  ) {
    return next(action)
  }
  
  if (callNBSK !== undefined) {
    KEY = CALL_API_NBSK;
    callAPI = callNBSK;
  }
  
  if (callPlugin !== undefined) {
    KEY = CALL_PLUGIN;
    callAPI = callPlugin;
    base = CALL_PLUGIN;
  }

  if (callPluginAPI !== undefined) {
    KEY = CALL_PLUGIN_API;
    callAPI = callPluginAPI;
    base = CALL_PLUGIN_API;
  }
  
  if (callCoreAPI !== undefined) {
    KEY = CALL_CORE_API;
    callAPI = callCoreAPI;
    base = CALL_CORE_API;
  }
  
  let { after, data, endpoint, feedback, headers, params, reqType, signal, types, ...extras } = callAPI;

  if ((callNBSK !== undefined || callPluginAPI !== undefined) && typeof endpoint === 'string') {
    endpoint = '/nbsk' + endpoint;
  }

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[KEY];
    return finalAction;
  };

  const [ requestType, successType, failureType ] = types;
  next(actionWith({ type: requestType }))
  
  return callRequest(endpoint, { base, data, headers, params, signal, type: reqType, ...extras }, false).then(
    (response) => {
      next(actionWith({
        feedback,
        payload: {
          ...response?.data,
          ...after ? { after } : {}
        },
        type: successType,
      }));
      return response?.data;
    },
    (error) => {
      next(actionWith({
        feedback: {
          ...feedback, 
          code: error?.response?.data?.status?.[0]?.code ?? null,
          error: feedback?.error === true ? feedback?.error ?? error.message : feedback?.error
        },
        payload: error.response,
        type: failureType,
      }));
      return error;
    }
  );
};

export default apiMiddleware;

