import { reduxActions as LAYER } from "_constants/layer.constants";

const canvasEditor = {
  [LAYER.CANVAS_EDITOR_DATA]: (state, { payload }) => ({
    ...state,
    canvasEditor: {
      ...state.canvasEditor,
      data: payload
    }
  }),

  [LAYER.CANVAS_EDITOR_TOGGLE]: (state, { payload }) => ({
    ...state,
    canvasEditor: {
      ...state.canvasEditor,
      visible: payload
    }
  })
};


export default canvasEditor;
