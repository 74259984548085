import { createSelector } from "reselect";

export const selectCatalogStore = (store) => store.catalog;

export const selectCatalogAllStore = createSelector(
  selectCatalogStore,
  (catalog) => catalog.all
);

export const selectCatalogAll = createSelector(
  selectCatalogAllStore,
  (all) => all.data
);

export const selectCatalogOneStore = createSelector(
  selectCatalogStore,
  (catalog) => catalog.one
);

export const selectCatalogOne = createSelector(
  selectCatalogOneStore,
  (one) => one.data
);

