import initialProfileState from "../profile.initialState";
import { reduxActions as PROFILE } from "_constants/profile.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const all = ({
  [PROFILE.ALL_STARTED]: (state) => ({
    ...state,
    all: {
      ...state.all,
      status: LOADING
    }
  }),

  [PROFILE.ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [PROFILE.ALL_FAIL]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [PROFILE.ALL_RESET]: (state) => ({
    ...state,
    all: initialProfileState
  })
});

export default all;
