import { matchPath } from "react-router-dom";
import { openPanelLeft } from "_redux/ui/ui.actions";
import { OWNER } from "_constants/catalog.constants";

const open = ({
  dispatch,
  location
}) => {
  let type;
  if (matchPath(location.pathname, {
    path: "/products/catalog/:key"
  })) {
    type = "catalog";
  } else {
    type = "catalogs";
  }
  
  return dispatch(
    openPanelLeft({
      typePanel: type,
      panelProps: {
        area: "My catalogs",
        type: OWNER
      }
    })
  );
};

export default open;
