import initialResourceState from "./resource.initialState";
import all from "./reducers/all";
import createLink from "./reducers/createLink";
import one from "./reducers/one";
import post from "./reducers/post";
import put from "./reducers/put";

const reducer = (state = initialResourceState, action) => {
  const reducers = {
    ...all,
    ...createLink,
    ...one,
    ...post,
    ...put
  };

  let { type } = action;
  if (reducers[type] === undefined) return state;

  return reducers[type](state, action);
};

export default reducer;
