import initialAdaptationState from './adaptation.initialState';
import add from './reducers/add';
import all from './reducers/all';
import wmCanvasEditor from './reducers/wmCanvasEditor';
import create from './reducers/create';
import masters from './reducers/masters';
import one from './reducers/one';
import onList from './reducers/onList';
import post from './reducers/post';
import put from './reducers/put';

const adaptationsReducer = (state = initialAdaptationState, action) => {
    const reducers = {
      ...add,
      ...all,
      ...wmCanvasEditor,
      ...create,
      ...masters,
      ...one,
      ...onList,
      ...post,
      ...put
    };
    
    let { type } = action;
    if (reducers[type] === undefined) return state;
    
    return reducers[type](state, action);
};

export default adaptationsReducer;
