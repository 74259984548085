import { matchPath } from "react-router-dom";
import { openPanelLeft, toggleRefresh } from "_redux/ui/ui.actions";
import { OWNER } from "_constants/catalog.constants"
import { pairCount } from "_redux/product/product.actions";

const close = ({
  action,
  dispatch,
  history,
  location,
  previous
}) => {
  let type;

  if (!type && matchPath(location.pathname, {
    path: "/products/catalog/:key",
    strict: true
  })) {
    type = "catalogId";
    history.push({
      pathname: `${previous?.location?.pathname ?? "/products"}`,
      search: `${previous?.location?.search ?? ""}`
    });
    
    dispatch(pairCount());
    dispatch(toggleRefresh("products"));
    
    return dispatch(
      openPanelLeft({
        typePanel: "catalogs",
        panelProps: {
          area: "My catalogs",
          withSelection: true,
          type: OWNER
        }
      })
    );
  }
  
  if (!type && matchPath(location.pathname, {
    path: "/products",
    strict: true
  })) {
    return action ? action() : null;
  }
};

export default close;
