import { reduxActions as FILTERS } from '_constants/filters.constants';
import { ACTIVE } from '_constants/product.constants';

export const fetchFolderFilters = (field = '_id') => (dispatch) => dispatch({
  'NBSK': {
    endpoint: '/folder',
    types: [FILTERS.PRODUCTS_STARTED, FILTERS.PRODUCTS_SUCCESS, FILTERS.PRODUCTS_FAIL],
    feedback: {
      error: true,
      success: false
    },
    params: {
      a: {
        unique: {
          by: field
        }
      },
      w: {
        status: ACTIVE
      }
    }
  }
});

export const productFiltersToggle = (visible) => (dispatch) => dispatch({
  type: FILTERS.TOGGLE_PRODUCT_FILTERS,
  payload: { visible }
});

export const setProductFilters = (payload) => (dispatch) => dispatch({
  type: FILTERS.PRODUCT_FILTERS,
  payload
});

export const setProductFilterList = (payload) => (dispatch) => dispatch({
  type: FILTERS.PRODUCT_FILTER_LIST,
  payload
});

const actions = {
  fetchFolderFilters,
  productFiltersToggle,
  setProductFilterList,
  setProductFilters
};

export default actions;
