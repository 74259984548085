import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import useTooltip from "_hooks/useTooltip";

const FilterOption = ({ collection, empty, field, onChange, options, prefix, values }) => {
  const { t } = useTranslation();
  const {  injectedProps } = useTooltip();

  return (
    <>
      {empty && 
        <Button
          className={`${prefix}__content-button`}
          content={
            <span
              {...injectedProps}
              className={`${prefix}__content-button--span`}
              style={{transform: "skew(-17deg)"}}
            >{`<${t("common:words.empty")}>`}
            </span>
          }
          type={"dropdown"}
          onClick={() => onChange("[@empty]", collection, field, "Empty")}
          isActive={values.indexOf("") !== -1 ? true : false}
        />
      }
      
      {options?.map(({ label, value }, index) => (
        <Button
          className={`${prefix}__content-button`}
          key={index}
          content={
            <span
              {...injectedProps}
              className={`${prefix}__content-button--span`}
            >
              {label}
            </span>
          }
          type="dropdown"
          onClick={() => onChange(value, collection, field, label)}
          isActive={values.indexOf(value) !== -1 ? true : false}
        />
      ))}
    </>
  );
};

export default FilterOption;
