import { GlobalHotKeys } from "react-hotkeys";
import { BrowserRouter as Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import { PlayGround, UserLayout } from "components/templates";
import { usePlayGround } from "_hooks";
import Routes from "_config/routes";
import "normalize.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/index.scss";
import { Tooltip } from "components/atoms/index";
import { LoaderSelector } from "components/templates/index";

function App() { 
  const { activePlayGround, handlers, keyMap } = usePlayGround();
  return (
    <> 
      <Router>
        <div className="App">
          <GlobalHotKeys 
            handlers={handlers}
            keyMap={keyMap}
            allowChanges={true}
          />
          <PlayGround
            active={activePlayGround}
            component={<UserLayout children={<span></span>} />}
          /> 
          <Routes />
        </div>
      </Router>
      <Tooltip />
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        closeOnClick={false}
        closeButton={false}
        limit={3}
      />
      <LoaderSelector />
    </>
  );
}

export default App;
 