import { newProperty } from "../schema.generator";

const schema = [
  { ...newProperty("width", "number", true, "Width") },
  { ...newProperty("height", "number", true, "Height") },
  { ...newProperty("resolution", "number", true, "Resolution") },
  {
    ...newProperty("color", "string", true, "Color", {
      matches: [/^#[0-9A-F]{6}$/i, "must be hexadecimal"],
    }),
  },
  { ...newProperty("maxSize", "number", false, "Max size") },
];

export default schema;
