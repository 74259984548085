import initialBillingState from "./billing.initialState";
import accounts from "./reducers/accounts";
import adaptations from "./reducers/adaptations";
import aiImages from "./reducers/aiImages";
import backRemovals from "./reducers/backremovals";
import channels from "./reducers/channels";
import currentPlan from "./reducers/currentPlan";
import dateFilter from "./reducers/dateFilter";
import heros from "./reducers/heros";
import isometrics from "./reducers/isometrics";
import items from "./reducers/items";
import pricing from "./reducers/pricing";
import watermarks from "./reducers/watermarks";

const reducer = (state = initialBillingState, action) => {
  const reducers = {
    ...accounts,
    ...adaptations,
    ...aiImages,
    ...backRemovals,
    ...channels,
    ...currentPlan,
    ...dateFilter,
    ...heros,
    ...isometrics,
    ...items,
    ...pricing,
    ...watermarks
  };

  let { type } = action;
  if (reducers[type] === undefined) return state;

  return reducers[type](state, action);
};

export default reducer;
