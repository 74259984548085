import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { FlexComponent } from "components/templates";
import { Button, NubeskIcons } from "components/atoms";
import { useChannelLayout, useTooltip, useUser } from "_hooks";
import { selectUser } from "_redux/user/user.selectors";
import { $$ADMIN, $$USER } from "_constants/permission.constants";
import { GLOBAL } from "_constants/layout.constants";
import { LAYER_MODULE } from "_constants/app.constants";

const prefix = "o-settings-sidebar";

const SettingsSidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = useLocation();
  const { t } = useTranslation("settings");
  const { layoutData, layoutReset } = useChannelLayout();
  const { hasModule } = useUser();

  const user = useSelector(selectUser);
  
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });

  const [isGlobal, setIsGlobal] = useState(false);

  useEffect(() => {
    let match = matchPath(pathname, {
      path: "/settings/sales-channels/:layoutKey/"
    });

    if (match?.params?.layoutKey && match.params.layoutKey === GLOBAL) setIsGlobal(true);
    else setIsGlobal(false);

  }, [location]);

  return (
    <div className={prefix}>
      <FlexComponent isVertical>
        <div {...injectedProps}>
          <Button
            content={t("settings:sidebar.account")}
            type="color"
            isActive={pathname.includes("my-account")}
            onClick={() => {
              history.push("/settings/my-account");
            }}
            disabled
            style={{ justifyContent: "flex-start" }}
          />
        </div>
        <Button
          content={t("settings:sidebar.sales-channel")}
          type="color"
          isActive={!pathname.includes("$global") && !!matchPath(pathname, {
            path: `/settings/sales-channels`,
            exact: true,
            strict: true
          })}
          onClick={() => {
            layoutReset();
            history.push("/settings/sales-channels");
          }}
          style={{ justifyContent: "flex-start" }}
        />

        {layoutData &&
          <>
            <Button
              className="--submenu"
              content={t("settings:sidebar.adaptations")}
              type="color"
              isActive={!isGlobal && !!matchPath(pathname, {
                path: `/settings/sales-channels/:layoutKey/adaptations`
              })}
              onClick={() => {
                history.push(`/settings/sales-channels/${layoutData.key}/adaptations`);
              }}
              rightIcon={!isGlobal && matchPath(pathname, {
                path: `/settings/sales-channels/:layoutKey/adaptations`
              }) && <NubeskIcons icon="arrowRight" />}
              style={{ justifyContent: "flex-start" }}
            />
            <Button
              className="--submenu"
              content={t("settings:sidebar.attributes")}
              type="color"
              isActive={!isGlobal && !!matchPath(pathname, {
                path: `/settings/sales-channels/:layoutKey/attributes`
              })}
              onClick={() => {
                history.push(`/settings/sales-channels/${layoutData.key}/attributes`);
              }}
              rightIcon={!isGlobal && matchPath(pathname, {
                path: `/settings/sales-channels/:layoutKey/attributes`
              }) && <NubeskIcons icon="arrowRight" />}
              style={{ justifyContent: "flex-start" }}
            />
          </>
        }
        
        {hasModule(LAYER_MODULE) &&
          <Button
            content={t("settings:sidebar.layers")}
            type="color"
            isActive={pathname.includes("/settings/layers")}
            onClick={() => {
              history.push(`/settings/layers`);
            }}
            style={{ justifyContent: "flex-start" }}
          />
        }

        <Button
          content={t("settings:sidebar.globalAttributes")}
          type="color"
          isActive={pathname.includes("/$global/")}
          onClick={() => {
            history.push(`/settings/sales-channels/$global/attributes`);
          }}
          style={{ justifyContent: "flex-start" }}
        />

        {user.canDoIt([$$ADMIN.BILLING]) &&
          <Button
            content={t("settings:sidebar.billing")}
            type="color"
            isActive={pathname.includes("billing")}
            onClick={() => {
              history.push("/settings/billing");
            }}
            style={{ justifyContent: "flex-start" }}
          />
        }

        {user.canDoIt([$$USER.CREATE], [$$USER.READ], [$$USER.EDIT], [$$USER.DELETE]) &&
          <Button
            content={t("settings:sidebar.users")}
            type="color"
            isActive={pathname.includes("users")}
            onClick={() => {
              history.push("/settings/users");
            }}
            style={{ justifyContent: "space-between" }}
          />
        }
      </FlexComponent>

      <FlexComponent>
        <div {...injectedProps}>
          <Button
            content={
              <span style={{ fontSize: "12px" }}>{t("sidebar.feedback")}</span>
            }
            disabled
            type={"underline"}
          />
        </div>
      </FlexComponent>
    </div>
  );
};

export default SettingsSidebar;
