import initialReaderState from "../reader.initialState";
import { reduxActions as READER } from "_constants/reader.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const filterKeys = {
  [READER.FILTER_KEYS_STARTED]: (state) => ({
    ...state,
    filterKeys: {
      ...state.filterKeys,
      status: LOADING
    }
  }),

  [READER.FILTER_KEYS_FAIL]: (state, { payload }) => ({
    ...state,
    filterKeys: {
      ...state.filterKeys,
      data : payload.data[0],
      status: FAILURE
    }
  }),

  [READER.FILTER_KEYS_SUCCESS]: (state, { payload }) => ({
    ...state,
    filterKeys: {
      ...state.filterKeys,
      data: payload.data[0],
      status: SUCCESS
    }
  }),

  [READER.FILTER_KEYS_RESET]: (state) => ({
    ...state,
    filterKeys: initialReaderState.filterKeys
  })
};

export default filterKeys;
