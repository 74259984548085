import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from "moment";
import useAppSettings from './useAppSettings';
import useChannelLayout from './useChannelLayout';
import useLocalStorage from './useLocalStorage';
import { fetchFolderFilters, setProductFilterList } from '_redux/filters/filters.actions';
import { selectAppAdaptations } from '_redux/app/app.selector';
import { selectProductAllStatus } from '_redux/product/product.selector';
import { selectProductFilterList } from '_redux/filters/filters.selector';
import { APPROVED, REQUIRED, TOREVIEW } from '_constants/product.constants';
import { LOADING } from '_constants/redux.constants';
import { capitalize } from '_utils/string';
import { jsonToUrl, urlToJson } from '_utils/url';

const useProductFilter = (primary = false) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();
  const [getLS, setLS] = useLocalStorage();

  const adaptations = useSelector(selectAppAdaptations);
  const filterList = useSelector(selectProductFilterList);
  const fetchStatus = useSelector(selectProductAllStatus);
  const { appPropertyColumns, appColumnKeyProps } = useAppSettings();
  const { listData: layoutList } = useChannelLayout();

  const [loading, setLoading] = useState(false);
  const [objectList, setObjectList] = useState();
  const [filters, setFilters] = useState();

  const applyNewFilter = (value, collection, field) => {
    if (fetchStatus === LOADING) return;
    let newArray = filters?.[collection]?.[field] ?? [];
    
    if (newArray.indexOf(value) === -1) {  
      let newFilters = {
        ...filters ?? {},
        [collection]: {
          ...filters?.[collection] ?? {},
          [field] : value
        }
      };
      
      changeFilters(newFilters, true);
    }
  };

  const filterToW = (lFilters = null) => {
    let output = {};
    if (lFilters === null) {
      lFilters = filters ?? false;
    }
    
    if (!lFilters) return false;

    for (let collection in lFilters) {
      for (let field in lFilters[collection]) {
        if (lFilters[collection][field].length < 1) continue;
        
        let values = lFilters[collection][field];
        
        switch (collection) {
          case '$dates': 
            let dates = lFilters[collection];
            let keyDate = Object.keys(dates).pop();
            
            output = {
              ...output,
              [keyDate]: {
                $gte: dates[keyDate].f,
                $lt: dates[keyDate].t
              }
            };
            break;
          case '$search': 
            values = lFilters[collection];
            if (values.length) {
              values = values.replace(/[^a-zA-Z0-9]/g, "");
              let regex = values.split('').join('[^a-zA-Z0-9]*');
              
              let conditionSearch = {
                "$regex": regex,
                "$options": 'i'
              };
              let arraySearch = [{key: conditionSearch}];
              
              for (let ad in adaptations) {
                arraySearch.push({
                  [`data.${ad}`]: conditionSearch
                });
              }
              output = {
                ...output,
                $or: arraySearch
              };
              
            } else if (output?.key) {
              delete output.key;
            }
            
            break;
          case 'folderId':
            let condition = {
              $in: values
            };

            output = {
              ...output || {},
              [`${field}`]: condition
            };
            break
          default:
            let search = values.indexOf("[@empty]");
            let regexp = /^(\$\$)+(exists)/;
            let match = regexp.test(field);

            if (match) {
              output = Object.assign({}, output);
              for (let v in Object.keys(adaptations)) {
                if (output[v]) delete output[v];
              }
              
              output["$or"] = values.map((v) => ({[`${collection}.${v}`]: { $exists: 1}}));
              
            } else {
              if (search !== -1) {
                values.splice(search, 1, "", "-", null);
                output = {
                  ...output || {},
                  $or: [
                    {[`${collection}.${field}`]:  {
                      $in: values
                    }},
                    {[`${collection}.${field}`]:  {
                      $exists: false
                    }}
                  ]
                };
              } else { 
                if (!Array.isArray(values) && typeof values === "object") {
                  values = Object.keys(values).map((v) => values[v]);
                }
                output = {
                  ...output || {},
                  [`${collection}.${field}`]: { $in: values}
                };
              }
            }
            break;
        }
      }
    }
    
    return output;
  };

  /**
   * 
   * @param { Boolean } force 
   * @returns 
   */
  const formatFills = async(force = false) => {
    if (!primary && !force) return;
    let _objectList;
    let filterList = getLS("filterList");

    if (filterList && !force)  {
      dispatch(setProductFilterList(filterList));
      return;
    }

    if (force) {
      _objectList = { data: appPropertyColumns };
    } else {
      _objectList = { ...objectList };
    }
    if (!_objectList || !Object.keys(_objectList).length) return;

    if ([undefined, null].indexOf(_objectList) !== -1 && loading === false) return;
    
    let mergeObjectList = {
      ...{
        folderId: {
          "process.reviewMaster": {
            original: t("products:filters.photoStatus"),
            enum: [{
              label: t("products:filters.status.approved"),
              value: APPROVED
            }, {
              label: t("products:filters.status.toReview"),
              value:TOREVIEW
            }, {
              label: t("products:filters.status.required"),
              value: REQUIRED
            }],
            toFilter: true
          },
          "process.reviewCopy": {
            original: t("products:filters.copyStatus"),
            enum: [{
              label: t("products:filters.status.approved"),
              value: APPROVED
            }, {
              label: t("products:filters.status.toReview"),
              value: TOREVIEW
            }],
            toFilter: true
          }
        }
      },
      ...{
        ..._objectList ?? {},
        data: {
          "$$exists": {
            original: t("products:filters.salesChannel"),
            enum: layoutList.map((l) => ({
              label: l?.name,
              value: l?.key
            })),
            toFilter: true,
            empty: false
          },
          ...(function() {
            let newObjt = {};
            for (let a of objectList.data) {
              newObjt[a.key] = {
                original: a.name,
                enum: a?.class?.enum ?? null,
                toFilter: !!a?.flag?.toFilter
              }
            }
            return newObjt;
          })()
        }
      }
    };

    setLoading(true);
    let keys = Object.keys(mergeObjectList);
    let promises = [];
    let newFiltersList = [];
    let filterables = [];

    for (let k of keys) {
      if (mergeObjectList[k]) {
        let keysNested = Object.keys(mergeObjectList[k]);

        for (let kn of keysNested) {
          let { enum: enm, empty, key, original, toFilter } = mergeObjectList[k][kn];

          if (toFilter && !(key)) {
            filterables.push(kn);

            let enumList = enm?.map((row) => { 
              if (!!row?.value && !!row?.label) {
                return row;
              } else {
                return { 
                  label: row,
                  value: row
                };
              }
            }) ?? [];

            newFiltersList.push({
              empty: empty !== false && (k === "data"),
              collection: k,
              isKey: false,
              field: kn,
              label: capitalize(original),
              options: enumList.sort((a, b) => {
                if (a.label > b.label) return 1;
                else if (a.label < b.label) return -1;
                return 0;
              })
            });

            if (!(enumList?.length)) {
              promises.push(new Promise((resolve) => {
                dispatch(fetchFolderFilters( "data." + kn)).then((response) => {
                  enumList = response?.data?.[0]?.unique?.map((row) => ({
                    label: row,
                    value: row
                  })) ?? [];
                  let index = filterables.indexOf(kn);
                  newFiltersList[index] = ({
                    empty: empty !== false && (k === "data"),
                    collection: k,
                    isKey: false,
                    field: kn,
                    label: capitalize(original),
                    options: enumList.sort((a, b) => {
                      if (a.label > b.label) return 1;
                      else if (a.label < b.label) return -1;
                      return 0;
                    })
                  });
                  dispatch(setProductFilterList(newFiltersList));
                  resolve(newFiltersList);
                  return newFiltersList;
                });
              }))
            }
          }
        }
      }
    }
    Promise.all(promises).then(() => {
      setLS("filterList", newFiltersList);
    });
    dispatch(setProductFilterList(newFiltersList));
  };

  /**
   * 
   */
  const getTags = useCallback(async () => {
    let group = [];
    let specials = [];
    let tags = [];
    let json = await urlToJson(location.search);    
    let filtersLocal = json?.w ?? [];
    
    for (let collection in filtersLocal) {
      
      for (let field in filtersLocal[collection]) {
        if (Array.isArray(filtersLocal[collection][field])) {
          let value = filtersLocal[collection][field];

          if (collection === "folderId" && field === "key") {
            group.push({
              label: t("products:filters.filteredTag", {
                n: value.length, columnKey: appColumnKeyProps?.name
              }),
              collection,
              field,
              value: "[@fieldGroup]"
            });
          } else {
            let nameFiltered = appPropertyColumns?.filter((c) => c.key === field)?.[0];
            
            for (let val of value) {
              let label = val;
              for (let f of filterList) {
                if (f?.options?.length && f.field === field) {
                  for (let o of f?.options) {
                    if (o?.label && o?.value === val) {
                      label = o.label;
                      break;
                    }
                  }
                }
              }
              
              if (field === "$$exists") {
                label = layoutList.filter((l) => l.key === val)?.[0]?.name;
              }

              let element = {
                label: label || val,
                collection,
                field,
                value: val
              };

              if (val === "[@empty]" && nameFiltered) {
                element.name = nameFiltered?.name ?? element.label;
              }

              tags.push(element);
            }
          }
        }
      }
    }
    
    if (filtersLocal.$dates && Object.keys(filtersLocal.$dates).length) {
      let keyDate = Object.keys(filtersLocal.$dates).pop();
      let { f: from, t: to } = filtersLocal.$dates?.[keyDate];
      
      specials.push({
        label: t("products:filters.dateRange", { 
          from, 
          to: moment(to).subtract(1, 'd').format("YYYY-MM-DD")
        }),
        field: "$dates",
        type: "dateRange"
      });
    }

    return {
      group,
      specials,
      tags
    };
  }, [location.search]);

  const removeSpecial = async (field) => {
    if (fetchStatus === LOADING) return;
    let { w } = await urlToJson(location.search);

    if (w?.[field]) {
      delete w[field];
    }

    changeFilters(w, true);
  };

  const removeValue = async (val, collection, field) => {
    if (fetchStatus === LOADING) return;
    let { w } = await urlToJson(location.search);
    let value = w?.[collection]?.[field] ?? [];

    if (val === "[@fieldGroup]") {
      delete w[collection][field];
    } else if (Array.isArray(value) && value.length) {
      
      let index = value.indexOf(val);
      if (index !== -1) {
        w[collection][field].splice(index, 1);
        if (w[collection][field].length === 0) {
          delete w[collection][field];
        }
      }
    }
    
    changeFilters(w, true);
  }

  const changeFilters = async (newFilters, applyUrl = false) => {
    let json = await urlToJson(location.search);
    if (newFilters) json.w = newFilters;
    
    let encode = await jsonToUrl(json);

    if (applyUrl) {
      history.replace({
        pathname: location.pathname,
        search: encode
      });
    }
    return json;
  };

  const setInit = (values) => setObjectList(values);

  const filterFromUrl = async () => {
    let params = await urlToJson(location.search);
    return params?.w ?? {};
  };

  const filterToUrl = () => filters;

  const saveFilters = async () => {
    let json = await urlToJson(location.search);
    
    if (JSON.stringify(filters) !== JSON.stringify(json?.w ?? undefined)) {
      if (filters !== json.w) setFilters(json?.w);
    }
  };

  const searchHandler = async (value) => {
    let prevKey = filters?.$search?.length ?? 0;

    let { w: newFilters } = await urlToJson(location.search);

    if (value.length === 0 && prevKey !== 0) {
      delete newFilters["$search"];
    } else {
      if (!newFilters) newFilters = {};
      newFilters.$search = value;
    }

    return newFilters;
  };

  const datesHandler = async (value) => {
    let { w: newFilters } = await urlToJson(location.search);

    if (value) {
      if (!newFilters) newFilters = {};
      newFilters.$dates = value;
    } else {
      if (newFilters?.["$dates"]) delete newFilters["$dates"];
    }

    return newFilters;
  };

  
  const getSuggestions = (value) => {
    let newSuggestions = [];

    if (value && value.length) {
      let word = value.replace(/[^a-zA-Z0-9]/gi).split();
      let regexp = new RegExp(`${word.join("[^a-zA-Z0-9]")}`, "gi");
      
      for (let fl of filterList) {
        let opts = fl.options.filter((o) => (
          regexp.test(o.value) || regexp.test(o.label)
        ));
        
        if (opts.length) {
          newSuggestions.push({
            ...fl,
            content: opts.map((o) => ({
              content: o.label,
              value: o.value
            }))
          });
        }
      }
    }

    return newSuggestions;
  };


  useEffect(() => {
    if (primary === false) return;
    formatFills();
  }, [objectList]);

  useEffect(() => {
    if (primary === false) return;
    if (filterList && loading === true) setLoading(false);
  }, [filterList]);
  
  useEffect(() => saveFilters(), [location.search]);
  
  return {
    applyNewFilter,
    changeFilters,
    datesHandler,
    fetchStatus,
    filterList,
    filters,
    filterFromUrl,
    filterToUrl,
    filterToW,
    formatFills,
    getTags,
    getSuggestions,
    removeSpecial,
    removeValue,
    searchHandler,
    setInit
  };
};

export default useProductFilter;
