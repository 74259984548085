import initialTaskState from "../task.initialState"
import { reduxActions as TASK } from "_constants/task.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const list = {
  [TASK.LIST_STARTED]: (state) => ({
    ...state,
    list: {
      ...state.list,
      status: LOADING
    }
  }),

  [TASK.LIST_FAIL]: (state, { payload }) => ({
    ...state,
    list: {
      ...state.list,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [TASK.LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: {
      ...state.list,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [TASK.LIST_RESET]: (state) => ({
    ...state,
    list: initialTaskState.list
  })
};

export default list;
