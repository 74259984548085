import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const all = {
  [PRODUCT.ALL_STARTED]: (state) => ({
    ...state,
    all: {
      ...state.all,
      status: LOADING,
    },
  }),

  [PRODUCT.ALL_FAIL]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      error: payload?.error ?? null,
      status: FAILURE,
    },
  }),

  [PRODUCT.ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS,
    },
  }),

  [PRODUCT.ALL_REFRESH]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: state.all.data?.map((product) => {
        for (let p of payload) {
          if (product?._id === p?._id) {
            return { ...product, ...p.c };
          }
        }
        
        return product;
      }) ?? null
    }
  }),

  [PRODUCT.ALL_RESET]: (state) => ({
    ...state,
    all: initialProductState.all,
  })
};

export default all;
