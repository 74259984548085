import { useDispatch, useSelector } from "react-redux";
import { find, post } from "_redux/layout/layout.actions";
import { selectLayoutAll, selectLayoutOne, selectLayoutPost } from "_redux/layout/layout.selector";
import { ACTIVE, reduxActions as LAYOUT } from "_constants/layout.constants";

const useChannelLayout = () => {
  const dispatch = useDispatch();

  const {
    data: listData,
    error: listError,
    status: listStatus
  } = useSelector(selectLayoutAll);
  
  const {
    data: postData,
    error: postError,
    status: postStatus
  } = useSelector(selectLayoutPost);

  const {
    data: layoutData,
    error: layoutError,
    status: layoutStatus
  } = useSelector(selectLayoutOne);

  const createLayout = (data) => dispatch(post({
    c: [{
      name: data.name
    }]
  }, {
    feedback: {
      error: {
        area: "settings:channelLayout.feedback.create.area"
      },
      success: {
        area: "settings:channelLayout.feedback.create.area",
        info: "settings:channelLayout.feedback.create.success"
      }
    }
  }));
  

  /**
   * 
   * @param { Object } conditions 
   * @returns 
   */
  const fetch = (params, extras = {}) => dispatch(find({
    f:  params?.f ?? 
      ["name", "key", "adaptationsId", "attributesId", "createdAt"],
    a: {
      extended: {
        _: "@"
      },
      ...params?.a ?? {}
    },
    w: {
      status: ACTIVE,
      ...params?.w ?? {}
    }
  }, extras));
  
  /**
   * 
   * @param { Object } params 
   * @returns 
   */
  const fetchOne = (params) => fetch({
    a: params?.a ?? {},
    f: params?.f,
    w: params?.w ?? {}
  }, {
    types: [
      LAYOUT.ONE_STARTED,
      LAYOUT.ONE_SUCCESS,
      LAYOUT.ONE_FAIL
    ]
  });

  const layoutReset = () => dispatch({ type: LAYOUT.ONE_RESET });
  const listReset = () => dispatch({ type: LAYOUT.ALL_RESET });

  return {
    createLayout,
    layoutData,
    layoutError,
    layoutReset,
    layoutStatus,
    fetch,
    fetchOne,
    listData,
    listError,
    listReset,
    listStatus,
    postData,
    postError,
    postStatus
  };
};

export default useChannelLayout;
