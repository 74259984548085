export const reduxActions = {
  ADD_STARTED: "adaptation/add_started",
  ADD_FAIL: "adaptation/add_fail",
  ADD_RESET: "adaptation/add_reset",
  ADD_SUCCESS: "adaptation/add_success",

  ALL_STARTED: "adaptation/all_started",
  ALL_FAIL: "adaptation/all_fail",
  ALL_RESET: "adaptation/all_reset",
  ALL_SUCCESS: "adaptation/all_success",


  CREATE_STARTED: "adaptation/create_started",
  CREATE_FAIL: "adaptation/create_fail",
  CREATE_RESET: "adaptation/create_reset",
  CREATE_SUCCESS: "adaptation/create_success",

  MASTERS_FAIL: "adaptations/masters_fail",
  MASTERS_RESET: "adaptations/masters_reset",
  MASTERS_SUCCESS: "adaptations/masters_success",
  MASTERS_STARTED: "adaptations/masters_started",

  ON_LIST_STARTED: "adaptation/on_list_started",
  ON_LIST_FAIL: "adaptation/on_list_fail",
  ON_LIST_RESET: "adaptation/on_list_reset",
  ON_LIST_SUCCESS: "adaptation/on_list_success",

  ONE_STARTED: "adaptation/one_started",
  ONE_FAIL: "adaptation/one_fail",
  ONE_RESET: "adaptation/one_reset",
  ONE_SUCCESS: "adaptation/one_success",

  POST_STARTED: "adaptation/post_started",
  POST_FAIL: "adaptation/post_fail",
  POST_SUCCESS: "adaptation/post_success",
  POST_RESET: "adaptation/post_reset",

  PUT_STARTED: "adaptation/put_started",
  PUT_FAIL: "adaptation/put_fail",
  PUT_SUCCESS: "adaptation/put_success",
  PUT_RESET: "adaptation/put_reset",

  WM_CANVAS_EDITOR_DATA: "adaptation/canvas_data",
  WM_CANVAS_EDITOR_TOGGLE: "adaptation/canvas_editor_toggle"
};

export const WITH_MARGINS = "marginChange";
export const REDIMENSION = "resizeChange";
export const CHANGE_BG = "backgroundChange";

export const SOLID = "solid";
export const TRANSPARENT = "transparent";
export const RADIAL = "radial";
export const IMAGE = "image";
export const INPAINT = "inpaint";

export const STANDARD = "standard";
export const CUSTOM = "custom";

export const FOLDING_BY_SKU = "PC(key)/{}";
export const FOLDING_ONLY = "{}";

export const ACTIVE = 'active';

export const DEFAULT_BG_COLOR = "#F1EDF6";

export const MASTERS_ECOMM = "adapt-master_ecomm-00";
export const ECOMM_NOBG = "adapt-master_ecommnobg-00";
export const NOBG_TRIM = "adapt-master_ecommnobgtrim-00";

export const LANDSCAPE = "landscape";
export const PORTRAIT = "portrait";
export const SQUARE = "square";
