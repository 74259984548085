import { reduxActions as BILLING } from "_constants/billing.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const currentPlan = {
  [BILLING.CURRENT_PLAN_STARTED]: (state) => ({
    ...state,
    currentPlan: {
      ...state.currentPlan,
      status: LOADING
    }
  }),

  [BILLING.CURRENT_PLAN_FAIL]: (state, { payload }) => ({
    ...state,
    currentPlan: {
      ...state.currentPlan,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [BILLING.CURRENT_PLAN_SUCCESS]: (state, { payload }) => ({
    ...state,
    currentPlan: {
      ...state.currentPlan,
      data: payload?.data?.[0]?.data ?? null,
      status: SUCCESS
    }
  })
};

export default currentPlan;
