import { API } from "_constants/request.constants";
import { reduxActions as ANALYSIS } from "_constants/analysis.constants";

export const request = ({ params, data }, extras = {}, type = API) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      ANALYSIS.REQUEST_STARTED,
      ANALYSIS.REQUEST_SUCCESS,
      ANALYSIS.REQUEST_FAIL
    ];
  }

  return dispatch({
    [type]: {
      endpoint: "/analytic/matchup",
      reqType: "GET",
      ...extras,
      params,
      data
    }
  });
}

export const put = (data, extras = {}) => (dispatch) => {
  extras.reqType = "PUT";
  
  return dispatch(request({ data }, extras));
}

export const post = (data, extras = {}) => (dispatch) => {
  extras.reqType = "POST";
  return dispatch(request({ data }, extras));
}

export const analyzeAdaptation = (params, adaptationId, index) => (dispatch) => {
  let $config = {
    adaptationId
  };

  if (index) {
    $config = {
      ...$config ?? {},
      index: {
        type: index === 1 ? "values" : "range",
        value: index === 1 ? [0]: `0-${index}`
      }
    };
  }
  return dispatch(post({ ...params, $config }, {
    endpoint: "/analytic/matchup/toAdaptation",
    types: [
      ANALYSIS.ADAPTATION_BULK_STARTED,
      ANALYSIS.ADAPTATION_BULK_SUCCESS,
      ANALYSIS.ADAPTATION_BULK_FAIL
    ]
  }))
};

export const analyzeApproved = (keys, minlenFilesId) => (dispatch) => {
  if (!Array.isArray(keys)) keys = [keys];

  return dispatch(post({
    $config: {
      minlenFilesId
    },
    w: keys
  }, {
    endpoint: "/analytic/matchup/toApproved",
    types: [
      ANALYSIS.APPROVED_BULK_STARTED,
      ANALYSIS.APPROVED_BULK_SUCCESS,
      ANALYSIS.APPROVED_BULK_FAIL
    ]
  }));
};
  

export const analyzeRemoveBg = (params, index) => (dispatch) => {
  let $config;

  if (index) {
    $config = {
      ...$config ?? {},
      index: {
        type: index === 1 ? "values" : "range",
        value: index === 1 ? [0]: `0-${index}`
      }
    };
  } 
  return dispatch(post({ ...params, $config}, {
    endpoint: "/analytic/matchup/toBackremoval",
    types: [
      ANALYSIS.REMOVE_BG_BULK_STARTED,
      ANALYSIS.REMOVE_BG_BULK_SUCCESS,
      ANALYSIS.REMOVE_BG_BULK_FAIL
    ]
  }));
};

export const analyzeRemoveBgReset = () => (dispatch) =>
  dispatch({type: ANALYSIS.REMOVE_BG_BULK_RESET});

export const analyzeAdaptationReset = () => (dispatch) =>
  dispatch({type: ANALYSIS.ADAPTATION_BULK_RESET});
