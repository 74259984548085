import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { find, post, put } from "_redux/attribute/attribute.actions";
import { Badge } from "components/atoms";
import { selectAttributeAll, selectAttributeList, selectAttributeOne, selectAttributePost, selectAttributePut, selectAttributeToLayer } from "_redux/attribute/attribute.selector";
import {
  reduxActions as ATTRIBUTE,
  ACTIVE,
  CHANNEL_TYPE,
  COMPOSITE_TYPE,
  LIST_DATATYPE,
  NUMBER_DATATYPE,
  LONGTEXT_DATATYPE,
  SHORTTEXT_DATATYPE,
  DERIVED_TYPE
} from "_constants/attribute.constants";
import { reduxActions as APP } from "_constants/app.constants";
import { NBSK_API } from "_constants/request.constants";

const useAttribute = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: allData, error: allError, status: allStatus } = useSelector(selectAttributeAll);
  const { data: oneData, error: oneError, status: oneStatus } = useSelector(selectAttributeOne);
  const { data: postData, error: postError, status: postStatus } = useSelector(selectAttributePost);
  const { data: putData, error: putError, status: putStatus } = useSelector(selectAttributePut);
  const { data: listData, error: listError, status: listStatus } = useSelector(selectAttributeList);
  const { data: toLayerData, error: toLayerError, status: toLayerStatus } = useSelector(selectAttributeToLayer);

  const dataTypeOpts = [{
    label: t("settings:layoutAttributes.dataType.shortText"),
    value: SHORTTEXT_DATATYPE
  }, {
    label: t("settings:layoutAttributes.dataType.longText"),
    value: LONGTEXT_DATATYPE
  }, {
    label: t("settings:layoutAttributes.dataType.number"),
    value: NUMBER_DATATYPE
  }, {
    disabled: true,
    label: t("settings:layoutAttributes.dataType.list"),
    value: LIST_DATATYPE
  }];

  const translateOpts = [{
    label: t("settings:layoutAttributes.modal.fields.translate.opts.noTranslate"),
    value: null
  }];

  const typeOpts = [{
    label: t("settings:layoutAttributes.type.channel"),
    value: CHANNEL_TYPE
  }, {
    label: t("settings:layoutAttributes.type.derived"),
    value: DERIVED_TYPE
  }, {
    disabled: true,
    label: t("settings:layoutAttributes.type.composite"),
    value: COMPOSITE_TYPE
  }];

  /**
   * 
   * @param { String } layoutKey 
   * @param { Object } params 
   * @returns 
   */
   const attributeFetch = async (params, layoutKey) => {
    let extras = {};
    
    try {
      if (layoutKey) {
        extras.endpoint = `/setting/layout/${layoutKey}/attribute`;
      }
      extras.types = [
        ATTRIBUTE.ONE_STARTED,
        ATTRIBUTE.ONE_SUCCESS,
        ATTRIBUTE.ONE_FAIL
      ];

      let { data } = await dispatch(find({
        f: params?.f ?? ["name", "class", "data", "flag", "outputStructure", "key", "type", "validator"],
        w: { 
          status: ACTIVE,
          ...params?.w ?? {}
        }
      }, extras));

      return data[0];
    } catch (e) { }
  };


  const downloadTemplate = async (layoutKey, $config = {}) => {
    let { data } = await dispatch(post({
      $config: {
        group: ["@all"],
        filename: `layout_${layoutKey ?? "global"}_template`,
        ...$config
      }
    }, {
      endpoint: `/setting/layout/${layoutKey ? `${layoutKey}/` : ""}getTemplate`,
      types: [
        ATTRIBUTE.POST_STARTED,
        ATTRIBUTE.POST_SUCCESS,
        ATTRIBUTE.POST_FAIL
      ],
      feedback: {
        error: true,
        success: false
      }
    }));

    if (data?.[0]?.url) window.open(data?.[0].url)    
  };

  /**
   * 
   * @param {String} layoutKey 
   * @param {Object} params 
   * @returns 
   */
  const fetch = (layoutKey, params) => dispatch(find(params, {
    endpoint: `/setting/layout/${layoutKey}/attribute`
  }));

  /**
   * 
   * @param {*} params 
   * @param {*} extras 
   * @returns 
   */
  const fetchAttributeToLayer = (params, extras = {}) => {
    if (!extras?.types) {
      extras.types = [
        ATTRIBUTE.TO_LAYER_STARTED,
        ATTRIBUTE.TO_LAYER_SUCCESS,
        ATTRIBUTE.TO_LAYER_FAIL
      ];
    }

    return dispatch({
      [NBSK_API]: {
        endpoint: "/folder/attribute",
        ...extras,
        params
      },
    });
  };

  /**
   * 
   * @returns 
   */
  const fetchColumnKey = () => fetchGlobal({}, {
    types: [
      APP.COLUMN_KEY_STARTED,
      APP.COLUMN_KEY_SUCCESS,
      APP.COLUMN_KEY_FAIL
    ]
  });

  /**
   * 
   * @returns 
   */
  const fetchList = () => fetchGlobal({
    f: ["name", "key", "flag", "validator", "class"]
  }, {
    types: [
      ATTRIBUTE.LIST_STARTED,
      ATTRIBUTE.LIST_SUCCESS,
      ATTRIBUTE.LIST_FAIL
    ]
  });

  /**
   * 
   * @param {Object} params 
   * @returns 
   */
  const fetchGlobal = (params, extras = {}) => dispatch(find({
    f: params?.f,
    a: params?.a,
    w: { 
      status: ACTIVE,
      ...params?.w
    }
  }, extras));

  /**
   * 
   * @param { String } layoutKey 
   * @param { String } _id 
   * @param { Object} data 
   * @returns 
   */
  const createAttribute = (layoutKey, data) => {
    const config = {
      c: [{
        name: data?.attrName,
        class: {
          datatype: data?.attrDataType?.value,
          enum: data?.classEnum ?? null
        },
        data: data?.attrDerived?.value ? { reference: data.attrDerived.value } : {},
        type: data?.attrType?.value,
        flag: {
          key: false,
          require: data?.attrRequired ?? false,
          toExport: data?.attrToExport ?? false,
          toFilter: data?.attrToFilter ?? false,
          editable: data?.attrEditable ?? false,
          overwritable: data?.attrOverwritable ?? false
        },
        validator: {
          prompts: data?.attrRules ?? "",
          translate: data?.attrTranslate?.value ?? null
        },
        outputStructure: {
          xlsx: {
            headers: data?.headers ?? []
          }
        }
      }]
    };
    
    return dispatch(post(config, {
      types: [
        ATTRIBUTE.ADD_STARTED,
        ATTRIBUTE.ADD_SUCCESS,
        ATTRIBUTE.ADD_FAIL
      ],
      endpoint: `/setting/layout/${layoutKey}/attribute`,
      feedback: {
        error: true,
        success: {
          area: t("settings:layoutAttributes.feedback.attributeArea"),
          info: t("settings:layoutAttributes.feedback.create")
        }
      }
    }));
  };

  /**
   * 
   * @param { String } data 
   * @returns 
   */
  const createGlobalAttribute = (data) => {
    const config = {
      c: [{
        name: data?.name,
        class: {
          datatype: data?.dataType?.value,
          enum: data?.classEnum ?? null
        },
        flag: {
          key: false,
          require: data?.require ?? false,
          toExport: data?.toExport ?? false,
          toFilter: data?.toFilter ?? false,
          editable: data?.editable ?? false,
          overwritable: data?.overwritable ?? false
        },
        validator: {
          prompt: data?.rules ?? ""
        },
        outputStructure: {
          xlsx: {
            headers: data?.headers ?? []
          }
        }
      }]
    };

    return dispatch(post(config, {
      types: [
        ATTRIBUTE.ADD_GLOBAL_STARTED,
        ATTRIBUTE.ADD_GLOBAL_SUCCESS,
        ATTRIBUTE.ADD_GLOBAL_FAIL
      ],
      feedback: {
        error: true,
        success: {
          area: "settings:layoutAttributes.feedback.globalArea",
          info: "settings:layoutAttributes.feedback.createGlobal"
        }
      }
    }));
  };

  const listDerived = (list, { className }) => {
    return list?.map((r) => ({
      label: <div className={className}>
        <Badge border="purple" content={r.key} />
        <div className="u-purple"><span>{r.name}</span></div>
      </div>,
      value: r.key
    })) ?? []
  }
  /**
   * 
   * @param { String } layoutKey 
   * @param { Object } current 
   * @param { Object } toUse 
   * @returns 
   */
  const moveIt = (current, toUse, layoutKey) => {
    if (current?.index === undefined || toUse?.index === undefined) return;
    let endpoint = "/setting/attribute";
    
    if (layoutKey) {
      endpoint = `/setting/layout/${layoutKey}/attribute`;
    }

    return dispatch(put([current._id, toUse._id], [
      { index: toUse.index },
      { index: current.index }
    ], {
      endpoint,
      types: [
        ATTRIBUTE.MOVEIT_STARTED,
        ATTRIBUTE.MOVEIT_SUCCESS,
        ATTRIBUTE.MOVEIT_FAIL
      ],
      feedback: {
        error: true,
        success: {
          area: layoutKey ? 
            t("settings:layoutAttributes.feedback.globalArea") 
          :
            t("settings:layoutAttributes.feedback.attributeArea"),
          info: t("settings:layoutAttributes.feedback.moveIt")
        }
      }
    }));
  };

  /**
   * 
   * @param { String } layoutKey 
   * @param { String } _id 
   * @param { Object } data 
   * @returns 
   */
  const updateAttribute = (layoutKey, _id, data) => {
    const config = [{
      name: data?.attrName,
      data: data?.attrDerived?.value ? { reference: data.attrDerived.value } : {},
      class: {
        datatype: data?.attrDataType?.value,
        enum: data?.classEnum ?? null
      },
      flag: {
        key: false,
        require: data?.attrRequired,
        toExport: data?.attrToExport,
        toFilter: data?.attrToFilter ?? false,
        editable: data?.attrEditable ?? false,
        overwritable: data?.atrrOverwritable ?? false
      },
      validator: {
        prompts: data?.atrrRules ?? "",
        translate: data?.attrTranslate?.value ?? null
      }
    }];
    
    return dispatch(put(_id, config, {
      endpoint: `/setting/layout/${layoutKey}/attribute`,
      feedback: {
        success: {
          area: "settings:layoutAttributes.feedback.attributeArea",
          info: "settings:layoutAttributes.feedback.update"
        },
        error: true
      },
      types: [
        ATTRIBUTE.UPDATE_STARTED,
        ATTRIBUTE.UPDATE_SUCCESS,
        ATTRIBUTE.UPDATE_FAIL
      ]
    }));
  }

  /**
   * 
   * @param { String } _id 
   * @param { Object } data 
   * @returns 
   */
  const updateGlobalAttribute = (_id, data) => {
    const config = [{
      name: data?.name,
      class: {
        datatype: data?.dataType?.value,
        enum: data?.classEnum ?? null
      },
      flag: {
        key: false,
        require: data?.require,
        toExport: data?.toExport,
        toFilter: data?.toFilter ?? false,
        editable: data?.editable ?? false,
        overwritable: data?.overwritable ?? false
      },
      validator: {
        prompt: data?.rules ?? ""
      }
    }];
    return dispatch(put(_id, config, {
      feedback: {
        error: true,
        success: {
          area: t("settings:layoutAttributes.feedback.globalArea"),
          info: t("settings:layoutAttributes.feedback.updateGlobal")
        }
      },
      types: [
        ATTRIBUTE.UPDATE_GLOBAL_STARTED,
        ATTRIBUTE.UPDATE_GLOBAL_SUCCESS,
        ATTRIBUTE.UPDATE_GLOBAL_FAIL
      ]
    }));
  };

  /**
   * Resets
   * @returns 
   */
  const allReset = () => dispatch({ type: ATTRIBUTE.ALL_RESET });
  const listReset = () => dispatch({ type: ATTRIBUTE.LIST_RESET });
  const oneReset = () => dispatch({ type: ATTRIBUTE.ONE_RESET });
  const postReset = () => dispatch({ type: ATTRIBUTE.POST_RESET });
  const putReset = () => dispatch({ type: ATTRIBUTE.PUT_RESET });
  const toLayerReset = () => dispatch({ type: ATTRIBUTE.TO_LAYER_RESET });

  return {
    allData,
    allError,
    allReset,
    allStatus,
    attributeFetch,
    createAttribute,
    createGlobalAttribute,
    dataTypeOpts,
    downloadTemplate,
    fetch,
    fetchAttributeToLayer,
    fetchColumnKey,
    fetchGlobal,
    fetchList,
    listData,
    listDerived,
    listError,
    listReset,
    listStatus,
    moveIt,
    oneData,
    oneError,
    oneReset,
    oneStatus,
    postData,
    postError,
    postReset,
    postStatus,
    putData,
    putError,
    putReset,
    putStatus,
    toLayerData,
    toLayerError,
    toLayerStatus,
    toLayerReset,
    translateOpts,
    typeOpts,
    updateAttribute,
    updateGlobalAttribute
  };
};

export default useAttribute;
