import { useDispatch, useSelector } from 'react-redux';
import WebFontLoader from "webfontloader";
import {
  selectAppAdaptations,
  selectAppAdaptationsStatus,
  selectAppColumnKey,
  selectAppColumnKeyStatus,
  selectAppColumnKeyStore,
  selectAppComponentDrawing,
  selectAppComponentDrawingStatus,
  selectAppCopyWorkflowStore,
  selectAppExternalNubai,
  selectAppFonts,
  selectAppLayers,
  selectAppLookAndFeelStore,
  selectAppMediaHelp,
  selectAppModules,
  selectAppPropertyColumns,
  selectAppPropertyColumnsStatus
}  from '_redux/app/app.selector';
import {
  getLookAndFeel as fetchLookAndFeel,
  getAdaptations,
  getComponentDraw,
  setConfigGot
} from "_redux/app/app.actions";
import { useAttribute, useLayer, useChannelLayout } from '_hooks';
import { ACTIVE, reduxActions as APP } from '_constants/app.constants';
import { API, CORE_API } from '_constants/request.constants';
import useLocalStorage from './useLocalStorage';

const useAppSettings = () => {
  const dispatch = useDispatch();

  const appAdaptations = useSelector(selectAppAdaptations);
  const appAdaptationsStatus = useSelector(selectAppAdaptationsStatus);
  const appColumnKey = useSelector(selectAppColumnKey);
  const { properties : appColumnKeyProps } = useSelector(selectAppColumnKeyStore);
  const appColumnKeyStatus = useSelector(selectAppColumnKeyStatus);
  const appComponentDrawing = useSelector(selectAppComponentDrawing);
  const appComponentDrawingStatus = useSelector(selectAppComponentDrawingStatus);
  const { data: appModules } = useSelector(selectAppModules);
  const appPropertyColumns = useSelector(selectAppPropertyColumns);
  const appPropertyColumnsStatus = useSelector(selectAppPropertyColumnsStatus);
  const {data: appCopyWorkflow, error: appCopyWorkflowError, status: appCopyWorkflowStatus} = useSelector(selectAppCopyWorkflowStore);
  const { data: appLayersData, error: appLayersError, status: appLayersStatus } = useSelector(selectAppLayers);
  const { data: appExternalNubai } = useSelector(selectAppExternalNubai);
  const { data: appFonts, error: appFontsError, status: appFontsStatus } = useSelector(selectAppFonts);
  const { data: appLookAndFeel } = useSelector(selectAppLookAndFeelStore);
  const { data: appMediaHelp, error: appMediaHelpError, status: appMediaHelpStatus } = useSelector(selectAppMediaHelp);

  const { fetchColumnKey, fetchGlobal } = useAttribute();
  const { fetchList: fetchLayers } = useLayer();
  const { fetch: fetchLayouts } = useChannelLayout();

  const [getLS, setLS] = useLocalStorage();

  const fetchAllSettings = () => {
    getCopyWorkflow();
    let promises = [
      getFonts(),
      getMediaHelp(),
      getModulesData(),
      getExternalNubai(),
      getLookAndFeel(),
      fetchColumnKey(),
      fetchLayouts(),
      fetchLayers({
        a: { sort: { by: { createdAt: -1 }}}
      }, {
        types: [
          APP.LAYERS_STARTED,
          APP.LAYERS_SUCCESS,
          APP.LAYERS_FAIL
        ]
      }),
      fetchGlobal({}, {
        types: [
          APP.PROP_COLUMNS_STARTED,
          APP.PROP_COLUMNS_SUCCESS,
          APP.PROP_COLUMNS_FAIL
        ]
      }),
      dispatch(getAdaptations()),
      getComponetDrawing(),
    ];
  
    Promise.all(promises).then((a) => {
      dispatch(setConfigGot(true));
    });
  };

  const getComponetDrawing = async () => {
    let drawing = getLS("appComponentDrawing");
    
    let data;
    if (drawing) {
      data = drawing;
      dispatch({
        type: APP.COMPONENTDRAWING_SET,
        payload: { data }
      });
    } else {
      drawing = await dispatch(getComponentDraw());
      data = drawing?.data?.[0]?.data?.nbsk;
      setLS("appComponentDrawing", data)
    }

    return drawing;
  };

  const getFonts = async () => {
    let fonts = getLS("appFonts");
    
    let data;
    if (fonts) {
      data = fonts;
      dispatch({
        type: APP.FONTS_SET,
        payload: { data }
      });
    } else {
      fonts = await dispatch({
        [API]: {
          endpoint: "/setting/library/font",
          types: [
            APP.FONTS_STARTED,
            APP.FONTS_SUCCESS,
            APP.FONTS_FAIL
          ],
          reqType: "GET",
          params: { w: { status: "active" }}
        }
      });
      data = fonts?.data?.[0]?.data?.src;
      setLS("appFonts", data)
    }
    

    WebFontLoader.load({
      google: {
        families: data?.map((f) => f.name),
      },
    });

    return fonts;
  };


  const getLookAndFeel = async () => {
    let look = getLS("appLookAndFeel");
    
    let data;
    if (look) {
      data = look;
      dispatch({
        type: APP.LOOK_AND_FEEL_SET,
        payload: { data }
      });
    } else {
      look = await dispatch(fetchLookAndFeel());
      data = look?.data?.[0]?.data;
      setLS("appLookAndFeel", data);
    }
    return look;
  };

  const getModulesData = async () => {
    let modules = getLS("appModules");
    
    let data;
    if (modules) {
      data = modules;
      dispatch({
        type: APP.MODULES_SET,
        payload: { data }
      });
    } else {
      modules = await dispatch({
        [API]: {
          endpoint: "/setting/modules",
          types: [
            APP.MODULES_STARTED,
            APP.MODULES_SUCCESS,
            APP.MODULES_FAIL
          ],
          reqType: "GET",
          params: { w: { status: ACTIVE }}
        }
      });

      data = modules?.data?.[0]?.data;
      setLS("appModules", data)
    }

    return modules;
  };

  const getCopyWorkflow = () => dispatch({
    [API]: {
      types: [
        APP.COPY_WORKFLOW_STARTED,
        APP.COPY_WORKFLOW_SUCCESS,
        APP.COLUMN_KEY_FAIL
      ],
      endpoint: "/setting/copyWorkflow",
      params: {
        w: {
          status: ACTIVE,
        },
      },
      feedback: {
        error: true,
        success: false
      }
    }
  });

  const getExternalNubai = async () => {
    let modules = getLS("external-nubai");
    
    let data;
    if (modules) {
      data = modules;
      dispatch({
        type: APP.EXTERNAL_NUBAI_SET,
        payload: { data }
      });
    } else {
      modules = await dispatch({
        [API]: {
          endpoint: "/setting/external/nubai",
          types: [
            APP.EXTERNAL_NUBAI_STARTED,
            APP.EXTERNAL_NUBAI_SUCCESS,
            APP.EXTERNAL_NUBAI_FAIL
          ],
          reqType: "GET",
          params: { w: { status: ACTIVE }}
        }
      });

      data = modules?.data?.[0]?.data;
      if (data) setLS("external-nubai", data);
    }

    return modules;
  };

  const getMediaHelp = async () => {
    let modules = getLS("media-help");
    
    let data;
    if (modules) {
      data = modules;
      dispatch({
        type: APP.MEDIA_HELP_SET,
        payload: { data }
      });
    } else {
      modules = await dispatch({
        [CORE_API]: {
          endpoint: "/getter/media-help",
          types: [
            APP.MEDIA_HELP_STARTED,
            APP.MEDIA_HELP_SUCCESS,
            APP.MEDIA_HELP_FAIL
          ],
          reqType: "GET",
          withCredentials: false
        }
      });

      data = modules?.data?.[0];
      if (data) setLS("media-help", data);
    }
    return modules;
  };

  return {
    appAdaptations,
    appAdaptationsStatus,
    appColumnKey,
    appColumnKeyProps,
    appColumnKeyStatus,
    appComponentDrawing,
    appComponentDrawingStatus,
    appCopyWorkflow,
    appCopyWorkflowError,
    appCopyWorkflowStatus,
    appExternalNubai,
    appFonts,
    appFontsError,
    appFontsStatus,
    appLayersData,
    appLayersError,
    appLayersStatus,
    appLookAndFeel,
    appMediaHelp,
    appMediaHelpError,
    appMediaHelpStatus,
    appModules,
    appPropertyColumns,
    appPropertyColumnsStatus,
    fetchAllSettings,
    getCopyWorkflow
  };
};

export default useAppSettings;
