import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomProgressBar, MultipleProgress } from "components/molecules";
import { LOADING } from "_constants/redux.constants";
import { Button, Switch } from "components/atoms";
import { BulkActionsCounter } from "components/organism";
import { Select } from "components/molecules/Inputs";
import { useAdaptation, useProductQuery } from "_hooks";
import { closeModal } from "_redux/ui/ui.actions";
import { analyzeAdaptation, analyzeAdaptationReset } from "_redux/analysis/analysis.action";
import { generate } from "_redux/file/file.actions";
import { addAdaptation, fetchAllList, findOne, resetOne } from "_redux/product/product.actions";
import { selectAnalysisaAdaptationBulk } from "_redux/analysis/analysis.selector";
import { selectModal, selectSkuSelected } from "_redux/ui/ui.selector";
import { selectProductCountPiece } from "_redux/product/product.selector"
import { selectAppAdaptations, selectAppColumnKey } from "_redux/app/app.selector";
import sleep from "_utils/async/sleep";

let cancel = false;
const colors = {
  "With adaptations": "blue",
  "Require BGR": "green",
  "To create": "yellow",
  "Incomplete": "gray"
};

let keys = {
  "With adaptations": "withAdaptation",
  "To create": "toAdapt",
  "Incomplete": "incomplete"
};

const prefix = "o-adaptations-bulk-modal";

const AdaptationsBulkModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { getKeyAdaptation } = useAdaptation();
  const { getFromQuery } = useProductQuery();
  const selections = useSelector(selectSkuSelected);
  const adaptations = useSelector(selectAppAdaptations);
  const { data: analysis, status } = useSelector(selectAnalysisaAdaptationBulk);
  const { isOpen } = useSelector(selectModal)
  const count = useSelector(selectProductCountPiece);
  const keyField = useSelector(selectAppColumnKey);

  const [adaptation, setAdaptation] = useState(
    t("modals:adaptationsBulk.selectSaleChannel")
  );
  const [adaptationId, setAdaptationId] = useState();
  const [current, setCurrent] = useState([]);
  const [executed, setExecuted] = useState(false);
  const [force, setForce] = useState(false);
  const [options, setOptions] = useState([]);
  const [processed, setProcessed] = useState(0);
  const [total, setTotal] = useState(0);
  const [withError, setWithError] = useState(0);

  const fetch = async () => {
    let selection = Object.keys(selections.selected);
    
    if (selections.all) {
      let { w } = await getFromQuery();
      let response = await dispatch(fetchAllList({ w: w || {} }));
      selection = response?.data?.map((p) => p._id);
    }
    dispatch(analyzeAdaptation({ w: selection }, adaptationId));
  };

  const processData = () => {
    let output = [];
    let percet = 0;
    let analyzeLocal = { ...analysis };
    
    if (analyzeLocal?.skipped?._count) {
      analyzeLocal.incomplete._count += analyzeLocal.skipped._count;
    }

    for (let i in keys) {
      if (analyzeLocal?.[keys[i]]._count) {
        percet = percet + (analyzeLocal[keys[i]]._count / (selections.all
          ? count - Object.keys(selections.selected).length
          : Object.keys(selections.selected).length)
        ) * 100;
      }

      output.push({
        _count: analyzeLocal[keys[i]]._count,
        color: colors[i],
        original: i,
        percent: percet,
        value: analyzeLocal[keys[i]]._count
      });
    }

    setCurrent(output);
    setTotal(analyzeLocal?.toAdapt?.folders.length);
  };
  
  const cancelProcess = () => {
    cancel = true;
    dispatch(closeModal());
  };

  const sendRequest = async () => {
    setExecuted(true);
    let list = analysis?.toAdapt?.folders ?? [];

    if (force) {
      list = [...list, ...analysis?.withAdaptation?.folders ?? []];
      setTotal(list.length);
    }
    
    for (let i in list) {
      let productId = list[i];
      await sleep(50).then(async () => {
        if (!cancel) {
          let key = getKeyAdaptation(adaptation);
          
          let product = await dispatch(findOne({ 
            w: { _id: productId },
            f: ["key", "data"]
          }));
          product = product?.data?.[0] ?? {};
          if (product?.key && !product?.data?.[key]) {
            await dispatch(
              addAdaptation(productId, {[key]: product.key})
            );
          }
          
          let response = await dispatch(
            generate(productId, adaptationId, undefined, force, {
              feedback: {
                success: false,
                error: true
              }
            }));
          await setProcessed((p) => p + 1);
          if (response?.status?.[0]?.code !== 0) {
            setWithError((w) => w + 1);
          }
        }
      });
      if (cancel) break;
    }
  };

  useEffect(() => {
    cancel = false;
    setOptions(Object.keys(adaptations)?.map(
      (a) => adaptations[a].original) 
    ?? []);

    return () => {
      cancel = true;
      dispatch(closeModal());
      dispatch(resetOne());
      dispatch(analyzeAdaptationReset());
    }
  }, []);

  useEffect(() => {
    if (isOpen) return;
    cancelProcess();
  }, [isOpen]);
  
  useEffect(() => {
    if (!adaptation 
      && adaptation !== t("modals:adaptationsBulk.selectSaleChannel")) return;
    
    for (let a of Object.keys(adaptations)) {
      if (adaptations[a]?.original === adaptation) {
        setAdaptationId(adaptations[a].refAdaptation);
        break;
      }
    }
    
  }, [adaptation]);
  
  useEffect(() => {
    if (!adaptationId) return;
    fetch();
  }, [adaptationId]);

  useEffect(() => {
    if (!analysis) return;
    processData();
  }, [analysis]);

  return (
    <div className={prefix}>
      {executed ? 
        <BulkActionsCounter
          completed={processed}
          legends={{
            action: t("modals:removeBgBulk.bulkCounter.action"),
            complete: t("modals:removeBgBulk.bulkCounter.complete", { field: keyField }),
            error: t("modals:removeBgBulk.bulkCounter.error", { field: keyField }),
            finish: t("modals:removeBgBulk.bulkCounter.finish"),
            success: t("modals:removeBgBulk.bulkCounter.success"),
            total: t("modals:removeBgBulk.bulkCounter.total", { field: keyField })
          }}
          onCancel={cancelProcess}
          total={total}
          withError={withError}
        />
      :
        <>
          <Select
            color="gray"
            onChange={(o) => setAdaptation(o.target.value)}
            options={options}
            value={adaptation}
          />
          {current?.length > 0 && <CustomProgressBar
            loading={status === LOADING}
            child={<MultipleProgress counts={current || []} />}
            total={
              selections.all
                ? count - Object.keys(selections.selected).length
                : Object.keys(selections.selected).length
            }
            available={total}
            onCancel={cancelProcess}
          />}
          
          <div className={`${prefix}__force-option`}>
            <Switch 
              checked={force}
              label={t("modals:adaptationsBulk.force")}
              name="force"
              onChange={setForce}
            />
          </div>
          
          <div className={`${prefix}__actions`}>
            <Button
              content={t("common:form.cancel")}
              disabled={executed}
              onClick={() => dispatch(closeModal())}
              type="underline"
            />

            <Button
              content={t("modals:adaptationsBulk.send")}
              disabled={
                (!adaptationId || executed || status === LOADING) || 
                (!adaptationId && !force)
              }
              onClick={sendRequest}
              type="purple"
            />
          </div>
        </>
      }
    </div>
  );
};

export default AdaptationsBulkModal;
