import { IDLE } from "_constants/redux.constants";

const initialLayoutState = {
  all: {
    data: null,
    error: null,
    status: IDLE
  },
  post: {
    data: null,
    error: null,
    status: IDLE
  },
  one: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialLayoutState;
