import initialProfileState from "./profile.initialState";
import all from "./reducers/all";

const reducer = (state = initialProfileState, action) => {
  let reducers = {
    ...all
  };

  let { type } = action;
  if (reducers[type] === undefined) {
    return state;
  }

  return reducers[type](state, action);
};

export default reducer;
