import React, { useState, useEffect, useCallback } from "react";
import { useDispatch} from "react-redux";
import { Button, CircularProgress } from "components/atoms";
import { CountUpload, CountUpModal } from "components/molecules";
import { uploadImages } from "_redux/file/file.actions";
import { findOne } from "_redux/product/product.actions";
import { closeModal } from "_redux/ui/ui.actions";
import { useTranslation } from "react-i18next";
import { sortImageFiles } from "_utils/upload";
import { useAppSettings } from "_hooks";
import "react-circular-progressbar/dist/styles.css";

const prefix = "upload-bulk-images__uploading";

const Uploading = ({ data, imageQty, foreignOptions, onFinish }) => {
  const { appColumnKeyProps } = useAppSettings();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [percent, setPercent] = useState(0);
  const [pendingImages, setPendingImages] = useState(0);
  const [notFound, setNotFound] = useState(0);
  const [toUpdate, setToUpdate] = useState(0);
  const [responses, setResponses] = useState([]);

  const request = useCallback(async () => {
    if (foreignOptions) return await _uploading(data, foreignOptions, true);
    for (let key in data) {
      await post({ key, file: data[key] });
      setToUpdate((p) => p + 1);
    }
    setPercent(100);
  });

  const post = useCallback(async ({ key, file }) => {
    let { data } = await dispatch(
      findOne({ 
        a: {
          extended: { f: { adaptationMastersId: ["attributes"] }}
        },
        f: ["adaptationMastersId", "process", "filesId"],
        w: { key },
      })
    );

    if (!data?.length || data?.[0]?.filesId?.length) {
      setNotFound((p) => p + 1);

      setPendingImages((p) => p + file.length);
      return;
    }
    let files = sortImageFiles(file);
    let masterInfo = data?.[0]?.adaptationMastersId.find(
      (master) => master.attributes.isMaster && master.attributes.withBackground
    );
    let folderId = data?.[0]?._id;

    await _uploading(files, { folderId, adaptationId: masterInfo._id }, true);

  }, []);

  const _uploading = useCallback(async (files, options, noFeedback = false) => {
    let res = [];
    setResponses(res);

    for (let _file of files) {
      let response = await dispatch(uploadImages([_file], options, noFeedback));
      setPendingImages((p) => p + 1);
      if (response?.data?.[0]) res.push(response.data[0]);
    }
    setResponses(res);
  }, []);

  useEffect(() => {
    request();
  }, [data]);

  useEffect(() => {
    if (pendingImages === imageQty) return setPercent(100);
    let _percent = (100 / imageQty) * pendingImages;
    setPercent((p) => _percent);
  }, [pendingImages]);

  return (
    <>
      <div className={prefix}>
        <CircularProgress percent={Math.floor(percent)} />
        <div
          className={`${prefix}__content`}
          style={{ ...(foreignOptions && { height: "50%" }) }}
        >
          {percent !== 100 ? (
            <>
              <span className={`${prefix}__content-title`}>UPLOADING</span>
              <CountUpload
                pending={pendingImages}
                total={imageQty}
                text={t("modals:uploadBulk.uploading.images")}
              />
              {!foreignOptions && (
                <>
                  <CountUpload
                    pending={toUpdate}
                    total={Object.keys(data).length}
                    text={t("modals:uploadBulk.uploading.receive", {
                      key: appColumnKeyProps?.name,
                    })}
                  />
                  <CountUpModal
                    simple
                    variant="red"
                    count={notFound}
                    text={t("modals:uploadBulk.uploading.skipped", {
                      key: appColumnKeyProps?.name,
                    })}
                  />
                </>
              )}
            </>
          ) : (
            <>
              <span className={`${prefix}__content-title`}>SUCCESS</span>{" "}
              <CountUpModal
                count={imageQty}
                text={t("modals:uploadBulk.uploading.images")}
              />
              {!foreignOptions && (
                <>
                  <CountUpModal
                    count={Object.keys(data).length - notFound}
                    text={t("modals:uploadBulk.uploading.receive", {
                      key: appColumnKeyProps?.name,
                    })}
                  />
                  <CountUpModal
                    count={notFound}
                    text={t("modals:uploadBulk.uploading.skipped", {
                      key: appColumnKeyProps?.name,
                    })}
                    variant={"red"}
                  />
                </>
              )}
            </>
          )}
        </div>
      </div>
      {percent === 100 ? (
        <div className={`${prefix}__buttons`}>
          <Button
            content="Done"
            type={"purple"}
            sm
            onClick={() => {
              if (onFinish && Object.keys(data).length - notFound) {
                onFinish(responses);
              }
              return dispatch(closeModal());
            }}
          />
        </div>
      ) : null}
    </>
  );
};

export default Uploading;
