import { useState } from "react";
import { useAppSettings, useProduct, useUser } from "_hooks";
import useSyncfonia from "module/Syncfonia/useSyncfonia";
import InitialProcess from "./InitialProcess";
import ShowFound from "./ShowFound";
import Processing from "./Processing";
import Results from "./Results";
import chunk from "_utils/array/chunk";
import sleep from "_utils/async/sleep";
import useProductFilter from "_hooks/useProductFilter";
import { TYPE_PROVIDER, TYPE_RETAILER } from "module/Syncfonia/syncfonia.constants";

const prefix = "syncfoniaGtinFetchModal";
let cancel = false;

const GtinFetchModal = ({ type = TYPE_RETAILER }) => {
  const { appColumnKey } = useAppSettings();
  const [bussy, setBussy] = useState(false);
  const [current, setCurrent] = useState(0);
  const [dualStep, setDualStep] = useState(0)
  const [checking, setChecking] = useState();
  const [stepProgress, setStepProgress] = useState(1);
  const [results, setResults] = useState({ _with: 0, _without: 0});
  const [itemTotal, setItemTotal] = useState(0);

  const { create: createProduct } = useProduct();
  const { changeFilters } = useProductFilter();
  const { user } = useUser();

  const { updateGtin, updateGtinProvider } = useSyncfonia();

  const process = async({ update }) => {
    let reqFunction = updateGtin;
    let toProcess = [];
    let resCreate = { data: []};
    setStepProgress(3);
    
    if (checking?.notFound?.length) {
      setDualStep(1);
      resCreate = await createProduct(checking?.notFound?.map((k) => ({
        data: { [appColumnKey]: k }
      })), { 
        fork: user?.fork,
        type: user?.type
      }, { 
        feedback: {
          error: false,
          success: false
        }
      });
    }
    
    setDualStep(2);
    toProcess = [...resCreate?.data?.map((p) => p.key) ?? []];
    if (update) {
      toProcess = [...toProcess, ...checking?.found];
    }
    
    if (type === TYPE_PROVIDER) reqFunction = updateGtinProvider;

    setItemTotal(toProcess);
    
    let arrays = chunk(toProcess, 5);
    let _current = 0;
    for (let a of arrays) {
      if (cancel) break;
      _current += a.length;
      setCurrent(_current);
      await sleep(50).then(async () => {
        let _update = await reqFunction({
          gtins: { key: a },
          opts: {
            getAssets: true,
            getInfo: true
        }});

        if (_update?.data) {
          setResults((v) => ({
            ...v,
            _with: v?._with + _update?.data?.[0]?.success?.withAssets ?? 0
          }))
        }
      });
    }

    setResults((v) => ({
      ...v,
      _without: toProcess?.length - v._with
    }));

    setStepProgress(4);
  };

  const getProgress = () => {
    switch (stepProgress)  {
      case 1:
        return <InitialProcess
          bussy={bussy}
          onBussy={() => setBussy(true)}
          onChange={(a) => {
            setBussy(false)
            setChecking(a);
            setStepProgress(2);
          }}
          prefix={prefix}
        />
      case 2:
        return <ShowFound 
          data={checking}
          prefix={prefix}
          process={process}
        />;
      case 3:
        return <Processing 
          prefix={prefix}
          current={current}
          step={dualStep}
          itemCurrent={current}
          itemTotal={itemTotal?.length ?? 0}
          onCancel={() => { cancel = true; }}
          total={4}
        />;
      case 4:
        return <Results 
          prefix={prefix}
          results={results}
          onClose={() => changeFilters({ folderId: { key: itemTotal }}, true)}
        />
      default:
        return;
    }
  };

  return (
    <div className={prefix}>
      {getProgress()}
    </div>
  );
};

export default GtinFetchModal;
