import initialMediaLibState from "../mediaLib.initialState";
import { reduxActions as MEDIA_LIB } from "_constants/mediaLib.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const all = {
  [MEDIA_LIB.ALL_STARTED]: (state) => ({
    ...state,
    all: {
      ...state.all,
      status: LOADING
    }
  }),

  [MEDIA_LIB.ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),

  [MEDIA_LIB.ALL_FAIL]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [MEDIA_LIB.ALL_RESET]: (state, { payload }) => ({
    ...state,
    all: initialMediaLibState
  })
};

export default all;
