import { reduxActions as ADAPTATION } from '_constants/adaptation.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialAdaptationState from '../adaptation.initialState';

const onList = {
  [ADAPTATION.ON_LIST_STARTED]: (state) => ({
    ...state,
    onList: {
      ...state.onList,
      status: LOADING
    }
  }),

  [ADAPTATION.ON_LIST_FAIL]: (state, { payload }) => ({
    ...state,
    onList: {
      ...state.onList,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [ADAPTATION.ON_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    onList: {
      ...state.onList,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [ADAPTATION.ON_LIST_RESET]: (state) => ({
    ...state,
    onList: initialAdaptationState.onList
  })
};

export default onList;
