import { useTranslation } from "react-i18next";
import { useNbskEditor } from "_hooks";
import { Accordion } from "components/organism";
import { NubeskIcons } from "components/atoms";

const ShapesSection = ({ allowedShapes, canvas, prefix }) => {
  const { t } = useTranslation();
  
  const { addCircle, addImage, addLine, addRect, addText } = useNbskEditor(canvas);

  return(
    <Accordion
      title={t("nbsk:editor.shapes.addShapes")}
      headerRight={null}
      isOpen={true}
      content={
        <div className={`${prefix}__shapes-section`}>
          
          {allowedShapes?.includes("squareImg") &&
            <div
              className='--obj-square-img'
              onClick={() => addImage("square", {
                nbskProperties: { lock: false }
              })}
            >
              <NubeskIcons icon="photos" className="t-purple" size="3.5rem" />
              <div className="--obj-add-btn" />
            </div>
          }

          {allowedShapes?.includes("portraitImg") &&
            <div
              className='--obj-portrait-img'
              onClick={() => addImage("portrait", {
                nbskProperties: { lock: false }
              })}
              >
                <NubeskIcons icon="photos" className="t-purple" size="3.5rem" />
                <div className="--obj-add-btn" />
              </div>
          }

          {allowedShapes?.includes("landscapeImg") &&
            <div
              className='--obj-square-img'
              onClick={() => addImage("landscape", {
                nbskProperties: { lock: false }
              })}
              >
                <NubeskIcons icon="photos" className="t-purple" size="3.5rem" />
                <div className="--obj-add-btn" />
              </div>
          }


          {allowedShapes?.includes("isomSquareImg")&&
            <div
              className='--obj-portrait-inverse'
              onClick={() => addImage("isomSquareImg", {
                nbskProperties: {
                  lock: false,
                  isometric: true
                }
              })}
              >
                <NubeskIcons icon="cube" className="t-purple" size="3.5rem" />
                <div className="--obj-add-btn" />
              </div>
          }

          {allowedShapes?.includes("isomPortraitImg") &&
            <div
              className='--obj-portrait-inverse'
              onClick={() => addImage("isomPortraitImg", {
                nbskProperties: {
                  lock: false,
                  isometric: true
                }
              })}
              >
                <NubeskIcons icon="cube" className="t-purple" size="3.5rem" />
                <div className="--obj-add-btn" />
              </div>
          }

          {allowedShapes?.includes("isomLandscapeImg") &&
            <div
              className='--obj-portrait-inverse'
              onClick={() => addImage("isomLandscapeImg", {
                nbskProperties: {
                  lock: false,
                  isometric: true
                }
              })}
            >
              <NubeskIcons icon="cube" className="t-purple" size="3.5rem" />
              <div className="--obj-add-btn" />
            </div>
          }

          {allowedShapes?.includes("rect") &&
            <div
              className='--obj-rect'
              onClick={() => addRect({ width: 200, height: 200,fill: "#ffffff" })}
            >
              <div className="--obj-add-btn" />
            </div>
          }
          
          {allowedShapes?.includes("circle") &&
            <div
              className='--obj-circle'
              onClick={() => addCircle({ fill: "#ffffff", radius: 150 })}
            >
              <div className="--obj-add-btn" />
            </div>
          }

          {allowedShapes?.includes("text") &&
            <div
              className='--obj-text'
              onClick={() => addText({
                height: 100,
                fontFamily: "Arial",
                fontSize: 40,
                nbskProperties: {
                  lock: true
                },
                text: t("settings:layers.editor.textContent"),
                textAlign: "center",
                width: 300
              })}
            >
              <NubeskIcons icon="text" />
              <div className="--obj-add-btn" />
            </div>
          }
          
          {allowedShapes?.includes("line") &&
            <div
              className='--obj-line'
              onClick={() => addLine()}
            >
              <NubeskIcons icon="slash" className="t-purple" size="3.5rem" />
            </div>
          }
        </div>
    } />
  );
};

export default ShapesSection;
