import { SimpleScroller } from "components/organism/index";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "../../../../node_modules/classnames/index";

const prefix = "t-flex-table";

const FlexTable = ({ 
  bodyClass,
  emptyData,
  gridTemplateColumns,
  headerCol,
  headers,
  rows,
  rowCol,
  scrollable,
  showEmpty,
  ...props
}) => {
  const  { t } = useTranslation();
  const classForBody = classNames(`${prefix}__body`, {
    [bodyClass]: bodyClass
  });

  return (
    <div className={`${prefix}__container`} {...props}>
      <div className={`${prefix}__header`} style={{
        gridTemplateColumns: gridTemplateColumns || `repeat(${headerCol}, 1fr)`,
      }}>
        {headers}
      </div>

      <div className={classForBody}>
        {scrollable ?
          <SimpleScroller>
            {rows && rows.length ? 
              rows?.map((r, i) => (
                  <div className={`${prefix}__row`} 
                    key={i}
                    style={{
                      gridTemplateColumns: gridTemplateColumns || `repeat(${rowCol}, 1fr) 40px`,
                    }}
                  >
                    {r}
                  </div>
              ))
            :
              showEmpty && (
                <div className={`${prefix}__row --empty`} style={{
                  gridTemplateColumns: `100%`,
                }}>
                  {emptyData ? emptyData : t("common:table.empty")}
                </div>
              )
            }
          </SimpleScroller>
        :
          <>
            {rows && rows.length ? 
              rows?.map((r, i) => (
                  <div className={`${prefix}__row`} 
                    key={i}
                    style={{
                      gridTemplateColumns: gridTemplateColumns || `repeat(${rowCol}, 1fr) 40px`,
                    }}
                  >
                    {r}
                  </div>
              ))
            :
              showEmpty && (
                <div className={`${prefix}__row --empty`} style={{
                  gridTemplateColumns: `100%`,
                }}>
                  {emptyData ? emptyData : t("common:table.empty")}
                </div>
              )
            }
          </>
        }
      </div>
    </div>
  );
};

FlexTable.propTypes = {
  emptyData: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  gridTemplateColumns: PropTypes.string,
  headers: PropTypes.node,
  headerCol: PropTypes.number,
  row: PropTypes.array,
  rowCol: PropTypes.number,
  scrollable: PropTypes.bool,
  showEmpty: PropTypes.bool
};

FlexTable.defaultProps = {
  emptyData: undefined,
  row: [],
  scrollable: true,
  showEmpty: true
};

export default FlexTable;
