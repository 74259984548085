import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

import { BorderInputs } from "components/templates";
import { List, SimpleScroller } from "components/organism";
import { Button, NubeskIcons } from "components/atoms";
import { selectFilterIsOpen } from "_redux/ui/ui.selector";
import { selectProductAllStatus } from "_redux/product/product.selector";
import sleep from "_utils/async/sleep";
import { LOADING } from "_constants/redux.constants";

const prefix = "m-input-search";

const InputSearch = ({ 
  autocomplete,
  disabled,
  filterCounter, 
  onChange, 
  onSelectSuggest, 
  onToggleFilters, 
  placeholder,
  value 
}) => {
  const isOpen = useSelector(selectFilterIsOpen);
  const fetchStatus = useSelector(selectProductAllStatus);
  const { t } = useTranslation();

  const [focus, setFocus] = useState(false);

  const inputRef = useRef(null);
  const classForComponent = classNames(prefix, { "is-focused": focus });

  return (
    <div
      className={classForComponent}
      onFocus={() => setFocus(true)}
      onBlur={() => sleep(100).then(() => setFocus(false))}
    >
      <BorderInputs>
        <NubeskIcons icon="search" className={`${prefix}__icon`} />
        <input
          className={`${prefix}__input`}
          disabled={disabled || false}
          placeholder={placeholder}
          value={value}
          onBlur={() => sleep(100).then(() => setFocus(false))}
          onChange={(e) => {onChange(e.target.value)
            setFocus(true)
          }}
          onMouseEnter={() => setFocus(true)}
          ref={inputRef}
        />
      </BorderInputs>
      <Button
        className={`${prefix}__filters${isOpen ? " is-open" : ""}`}
        content={
          <>
            <span>
              {t("products:filters.viewFilters")}
              {value?.length || !filterCounter
                ? null
                : `(${filterCounter})`}
            </span>
            <NubeskIcons icon={value?.length ? "times" : "filter"} />
          </>
        }
        onClick={
          value?.length
            ? () => onChange("")
            : () => onToggleFilters()
        }
        style={{ color: "var(--color-purple)" }}
        spin={fetchStatus === LOADING}
        rightIcon={value?.length ? null : <NubeskIcons icon={"down"} />}
      />

      {!!Object.keys(autocomplete)?.length && focus &&
        <div className={`${prefix}__autocomplete`}>
          <SimpleScroller >
            <List
              limit={20}
              list={autocomplete || []}
              onClick={onSelectSuggest}
            />
          </SimpleScroller>
        </div>
      }
    </div>
  );
};

InputSearch.propTypes = {
  autocomplete: PropTypes.oneOfType([PropTypes.object, PropTypes.array, PropTypes.bool]),
  onChange: PropTypes.func,
  onToggleFilters: PropTypes.func,
};

InputSearch.defaultProps = {
  autocomplete: false,
  onChange: () => {},
  onToggleFilters: () => {}
};

export default InputSearch;
