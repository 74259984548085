export const reduxActions = {
  FILTER_KEYS_STARTED: "reader/filters_key_started",
  FILTER_KEYS_FAIL: "reader/filters_key_fail",
  FILTER_KEYS_SUCCESS: "reader/filters_key_success",
  FILTER_KEYS_RESET: "reader/filters_key_reset",

  UPLOAD_ONE_STARTED: "reader/upload_one_started",
  UPLOAD_ONE_FAIL: "reader/upload_one_fail",
  UPLOAD_ONE_SUCCESS: "reader/upload_one_success",
  UPLOAD_ONE_RESET: "reader/upload_one_reset",

  SEARCH_KEYS_STARTED: "reader/search_keys_started",
  SEARCH_KEYS_FAIL: "reader/search_keys_fail",
  SEARCH_KEYS_SUCCESS: "reader/search_keys_success",
};
