import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const saveLayoutData = {
  [PRODUCT.LAYOUT_SAVE_STARTED]: (state) => ({
    ...state,
    layoutDataSave: {
      ...state.layoutDataSave,
      status: LOADING
    }
  }),

  [PRODUCT.LAYOUT_SAVE_SUCCESS]: (state, { payload }) => ({
    ...state,
    layoutDataSave: {
      ...state.layoutDataSave,
      status: SUCCESS
    },
    details: {
      ...state.details,
      data: {
        ...state.details.data,
        data: {
          ...state.details.data.data,
          [payload?.after?.layoutKey]: payload?.data?.[0]?.data?.[payload?.after?.attributeKey]?.value
        }
      }
    }
  }),

  [PRODUCT.LAYOUT_SAVE_FAIL]: (state) => ({
    ...state,
    layoutDataSave: {
      ...state.layoutDataSave,
      status: FAILURE
    }
  }),

  [PRODUCT.LAYOUT_SAVE_RESET]: (state) => ({
    ...state,
    layoutDataSave: initialProductState.layoutDataSave
  })
};

export default saveLayoutData;
