import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import usePrevious from "_hooks/usePrevious";
import FilterArea from "./FilterArea";
import FilterDrop from "./FilterDrop";
import { resetOne as resetOneReader } from "_redux/reader/reader.actions";
import { jsonToUrl, urlToJson } from "_utils/url";
import { NubeskIcons } from "components/atoms/index";
import CountUp from "react-countup";
import { toggleFilter } from "_redux/ui/ui.actions";

const prefix = "m-filter-group";

const FilterSwitch = ({ isClean, onChange, ...props }) => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const prevValues = usePrevious(props.values);

  const [selectSwitch, setSelectedSwitch] = useState("drop");
  const [results, setResults] = useState();

  const reset = async () => {
    setResults(undefined);
    setSelectedSwitch("drop");
    dispatch(resetOneReader());
    let json = await urlToJson(location.search);
    if (json?.w?.[props.collection]) {
      delete json.w[props.collection];
    }

    let queryString = jsonToUrl(json);
    history.push({
      pathname: location.pathname,
      search: queryString,
    });
  };

  useEffect(() => {
    if (results) onChange(results.found);
    if (results?.found?.length) dispatch(toggleFilter());
  }, [results]);

  useEffect(() => {
    if (isClean && prevValues?.length && !props.values.length) {
      setResults();
      setSelectedSwitch("drop");
    }
  }, [props?.values]);

  return (
    <div className={`${prefix}__filter-switch`}>
      {results ? (
        <div className={"drop-container__results"}>
          <div className={"drop-container__results-item"}>
            <CountUp end={results?.found?.length} duration={1} />
            <p className="results">{t("products:filters.found")}</p>
          </div>
          <div className={"drop-container__results-item"}>
            <CountUp end={results?.notFound?.length} duration={1} />
            <p className="results">{t("products:filters.notFound")}</p>
          </div>

          <Button
            className={"drop-container__button"}
            content={<NubeskIcons icon="times" />}
            onClick={reset}
            type="color"
          />
        </div>
      ) : (
        <div className={"drop-container"}>
          {selectSwitch === "drop" && (
            <FilterDrop
              {...props}
              onChange={setResults}
              setSelectedSwitch={setSelectedSwitch}
            />
          )}

          {selectSwitch === "paste" && (
            <>
              <Button
                className={"drop-container__button"}
                content={<NubeskIcons icon="times" />}
                onClick={() => setSelectedSwitch("drop")}
                type="color"
              />
              <FilterArea {...props} onChange={setResults} />
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default FilterSwitch;
