import { reduxActions as UI } from "_constants/ui.constants";

export const changeToolbar = (toolbarName) => (dispatch) =>
  dispatch({
    type: UI.TOOLBAR_CHANGE,
    payload: toolbarName,
  });

export const returnPreviousToolbar = () => (dispatch) =>
  dispatch({
    type: UI.TOOLBAR_PREVIOUS,
  });

export const toolbarSelection = () => (dispatch) =>
  dispatch({
    type: UI.TOOLBAR_SELECTION,
  });
