import initialTaskState from "../task.initialState";
import { reduxActions as TASK } from "_constants/task.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const put = {
  [TASK.PUT_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),

  [TASK.PUT_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [TASK.PUT_SUCCESS]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      data: payload?.data ?? null,
      status: SUCCESS
    },
    all: {
      ...state.all,
      data: (
        Array.isArray(state.all.data) ? 
          state.all.data.map((r) => {
            if (r._id === payload?.data?.[0]._id) {
              r = {
                ...r,
                ...payload?.data?.[0] ?? {}
              }
            }
            return r;
          })
        
        : null
      )
    }
  }),

  [TASK.PUT_RESET]: (state) => ({
    ...state,
    put: initialTaskState.put
  })
};

export default put;
