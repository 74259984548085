import { callRequest } from '_utils/axios';

const CALL_WEB = "WEB";

// A Redux middleware that interprets actions with CALL_WEB info specified.
// Performs the call and promises when such actions are dispatched.
const webMiddleware = (store) => (next) => (action) => {
  const callAPI = action[CALL_WEB];

  if (callAPI === undefined) {
    return next(action);
  }

  let { data, endpoint, feedback, headers, params, reqType, signal, types } = callAPI;

  if (typeof endpoint === 'function') {
    endpoint = endpoint(store.getState());
  }

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }

  if (!types.every(type => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  const actionWith = data => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction[CALL_WEB];
    return finalAction;
  }

  const [ requestType, successType, failureType ] = types;
  next(actionWith({ type: requestType }));

  return callRequest(endpoint, { base: CALL_WEB, data, headers, params, signal, type: reqType }, true).then(
    (response) => {
      next(actionWith({
        feedback,
        payload: response?.data,
        type: successType,
      }));
      return response?.data;
    },
    (error) => {
      next(actionWith({
        feedback: {
          ...feedback, 
          code: error?.response?.data?.status?.[0]?.code ?? null,
          error: feedback?.error ? error.message : false
        },
        payload: error.response,
        type: failureType,
      }));
      return error;
    }
  );
};

export default webMiddleware;
