import React from "react";
import { SettingsSidebar } from "components/organism";
import { ColumnsTemplate, LayerCanvasEditor,WatermarkerCanvasEditor } from "components/templates";
import { useAdaptation, useLayer } from "_hooks";

const prefix = "v-settings";

const Settings = ({ children }) => {

  const { canvasEditor } = useLayer();
  const { wmCanvasEditor  } = useAdaptation();

  return (
    <div className={prefix}>
      {canvasEditor?.visible && <LayerCanvasEditor />}
      {wmCanvasEditor?.visible && <WatermarkerCanvasEditor />}

      <ColumnsTemplate
        leftWidth={"220px"}
        leftComponent={<SettingsSidebar />}
        rightComponent={<div className={`${prefix}__content`}>{children}</div>}
      />
    </div>
  );
};

export default Settings;
