import { createSelector } from 'reselect';

export const selectAppStore = (store) => store.app;

/* Column key */
export const selectAppColumnKeyStore = createSelector(
  selectAppStore,
  (app) => app.columnKey
);

export const selectAppColumnKey = createSelector(
  selectAppColumnKeyStore,
  (columnKey) => columnKey.data
);

export const selectAppColumnKeyError = createSelector(
  selectAppColumnKeyStore,
  (columnKey) => columnKey.error
);

export const selectAppColumnKeyStatus = createSelector(
  selectAppColumnKeyStore,
  (columnKey) => columnKey.status
);

/** Component drawing */
export const selectAppComponentDrawingStore = createSelector(
  selectAppStore,
  (app) => app.componentDrawing
);

export const selectAppComponentDrawing = createSelector(
  selectAppComponentDrawingStore,
  (componentDrawing) => componentDrawing.data
);

export const selectAppComponentDrawingError = createSelector(
  selectAppComponentDrawingStore,
  (componentDrawing) => componentDrawing.error
);

export const selectAppComponentDrawingStatus = createSelector(
  selectAppComponentDrawingStore,
  (componentDrawing) => componentDrawing.status
);


/* App is configured */
export const selectAppConfigurated = createSelector(
  selectAppStore,
  (appStore) => appStore.configured
);

/* App is config got */
export const selectAppConfigGot = createSelector(
  selectAppStore,
  (appStore) => appStore.configGot
);

/* Property Columns store */
export const selectAppPropertyColumnsStore = createSelector(
  selectAppStore,
  (app) => app.propertyColumns
);

export const selectAppPropertyColumns = createSelector(
  selectAppPropertyColumnsStore, 
  (propertyColumns) => propertyColumns.data
);

export const selectAppPropertyColumnsError = createSelector(
  selectAppPropertyColumnsStore, 
  (propertyColumns) => propertyColumns.error
);

export const selectAppPropertyColumnsStatus = createSelector(
  selectAppPropertyColumnsStore, 
  (propertyColumns) => propertyColumns.status
);

/* Adaptations Store*/
export const selectAppAdaptationsStore = createSelector(
  selectAppStore,
  (app) => app.adaptations
);

export const selectAppAdaptations = createSelector(
  selectAppAdaptationsStore,
  (adaptations) => adaptations.data
);

export const selectAppAdaptationsError = createSelector(
  selectAppAdaptationsStore,
  (adaptations) => adaptations.error
);

export const selectAppAdaptationsStatus = createSelector(
  selectAppAdaptationsStore,
  (adaptations) => adaptations.status
);

export const selectImageryWorkflowStore = createSelector(
  selectAppStore,
  (app) => app.imageryWorkflow
);

export const selectAppLookAndFeelStore = createSelector(
  selectAppStore,
  (app) => app.lookAndFeel
);

/** Component drawing */
export const selectAppCopyWorkflowStore = createSelector(
  selectAppStore,
  (app) => app.copyWorkflow
);


export const selectAppLayers = createSelector(
  selectAppStore,
  (app) => app.layers
);

export const selectAppFonts = createSelector(
  selectAppStore,
  (app) => app.fonts
);

export const selectAppModules = createSelector(
  selectAppStore,
  (app) => app.modules
);

export const selectAppExternalNubai = createSelector(
  selectAppStore,
  (app) => app.externalNubai
);

export const selectAppMediaHelp = createSelector(
  selectAppStore,
  (app) => app.mediaHelp
);
