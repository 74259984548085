const useScreen = () => {
  const getSizes = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height
    };
  };

  return {
    getSizes
  };
};

export default useScreen;
