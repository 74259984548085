export const LEFT_PANEL = {
  CLOSE: "leftPanel/close",
  OPEN: "leftPanel/open"
};

export const SKUCARD = {
  TO_INFO: "skuCard/toInfo",
  TO_PHOTOS: "skuCard/toPhotos"
};

export const TASK_ITEMS = {
  TO_DETAILS: "tasksItems/toDetails"
};
