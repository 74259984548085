import { Button, Paragraph } from "components/atoms/index";
import { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ITEM_PRIORITY_HIGH,
  ITEM_PRIORITY_MEDIUM,
  ITEM_PRIORITY_NORMAL
} from "_constants/task.constants";

const prefix = "m-priority-selector";

const PrioritySelector = ({ value, onChange }) => {
  const { t } = useTranslation();

  const [opened, setOpened] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [position, setPosition] = useState({
    top: "0px",
    left: "0px",
    display: "none"
  });
  
  const clickHandler = async (priority) => {
    setWaiting(true);
    if (typeof onChange === "function") {
      await onChange(priority);
    }
    setWaiting(false);
    setOpened(false);
  }

  useEffect(() => {
    if (!opened) {
      setPosition({
        top: "0px",
        left: "0px",
        display: "none"
      });
    }
  }, [opened]);
  
  return (
    <div className={prefix}>
      <Button 
        className={`--${value || "normal"}`}
        content={t(`common:priority.${value || "normal"}`)}
        onClick={(e) => {
          setPosition({
            top: `${e.clientY - 100}px`,
            left: `${e.clientX - 80}px`,
          });
          setOpened((v) => !v)
        }}
        type="primary"
        sm
      />
      {opened && (
        <>
          <div className={`${prefix}__backdrop`} onClick={() => setOpened(false)}></div>
          <div className={`${prefix}__container`} style={{
            ...position
          }}>
            <Paragraph
              className="u-purple"
              content={t("common:priority.priority")}
            />
            <Button 
              className="--normal"
              content={t("common:priority.normal")}
              disabled={waiting}
              onClick={() => clickHandler(ITEM_PRIORITY_NORMAL)}
              sm
              type="primary"
            />
            <Button 
              className="--medium"
              content={t("common:priority.medium")}
              disabled={waiting}
              onClick={() => clickHandler(ITEM_PRIORITY_MEDIUM)}
              sm
              type="primary"
            />
            <Button 
              className="--high"
              content={t("common:priority.high")}
              disabled={waiting}
              onClick={() => clickHandler(ITEM_PRIORITY_HIGH)}
              sm
              type="primary"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PrioritySelector;
