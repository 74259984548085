import { reduxActions as FILE } from "_constants/file.constants";
import initialFileState from "../file.initialState";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import { STATEMENT_OR_BLOCK_KEYS } from "@babel/types";

const toSaveEdition = {
  [FILE.SAVE_EDITION_STARTED]: (state) => ({
    ...state,
    saveEdition: {
      ...state.saveEdition,
      status: LOADING
    }
  }),
  [FILE.SAVE_EDITION_FAIL]: (state, { payload }) => ({
    ...state,
    saveEdition: {
      ...state.saveEdition,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [FILE.SAVE_EDITION_SUCCESS]: (state, { payload }) => ({
    ...state,
    saveEdition: {
      ...state.saveEdition,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [FILE.SAVE_EDITION_RESET]: (state) => ({
    ...state,
    saveEdition: initialFileState.saveEdition
  }),
};

export default toSaveEdition;
