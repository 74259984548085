import { IDLE } from "_constants/redux.constants";

const initialSyncfoniaState = {
  updateGtin: {
    data: null,
    error: null,
    status: IDLE
  },

  updateGtinProvider: {
    data: null,
    error: null,
    status: IDLE
  },

  updateCatalogProvider: {
    data: null,
    error: null,
    status: IDLE
  },

  updateCatalogRetail: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialSyncfoniaState;
