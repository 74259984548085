const capitalize = (string) => {
  if (!string) return '';
  string = string.toLowerCase();
  
  const output = string.charAt(0).toUpperCase() + string.slice(1);

  return output;
};

export default capitalize;
