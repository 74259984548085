import React from "react";

import { FlexComponent } from "components/templates";
import { Button, NubeskIcons} from "components/atoms";
import { useTranslation } from "react-i18next";

const TaskStatistics = () => {
  const { t } = useTranslation();
  const prefix = "o-product-statistics";

  return (
    <div className={prefix}>
      <div className={`${prefix}__total`}>
        <span className={`${prefix}__total-name`}>{t("sidebar:statistics.tasks.unsolved_tasks")}</span>
        <span className={`${prefix}__total-qty`}>21</span>
      </div>
      <FlexComponent isVertical>
        <Button
          leftIcon={<NubeskIcons icon="copy" />}
          content={t("sidebar:statistics.tasks.copy")}
          qty={9}
          type="gray"
          onClick={() => {}}
        />
        <Button
          leftIcon={<NubeskIcons icon="photos" />}
          content={t("sidebar:statistics.tasks.photos")}
          type="gray"
          qty={10}
          onClick={() => {}}
        />
        <Button
          leftIcon={<NubeskIcons icon="rights" />}
          content={t("sidebar:statistics.tasks.rights")}
          type="gray"
          qty={2}
          onClick={() => {}}
        />
      </FlexComponent>
    </div>
  );
};

export default TaskStatistics;
