import dateFormat from "./dateFormat";

const dateTimeFormat = (date) => {
  if (date === undefined) return date;
  let localDate = new Date(date);
  let dateString = dateFormat(date);
  let hours = ("0" + localDate.getHours()).slice(-2);
  let minutes = ("0" + localDate.getMinutes()).slice(-2);
  return `${dateString} ${hours}:${minutes}`;
};

export default dateTimeFormat;
