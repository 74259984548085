import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const aiAnalysis = {
  [PRODUCT.AI_ANALYSIS_STARTED]: (state) => ({
    ...state,
    aiAnalysis: {
      data: null,
      error: null,
      status: LOADING
    }
  }),
  [PRODUCT.AI_ANALYSIS_SUCCESS]: (state, { payload }) =>  ({
    ...state,
    aiAnalysis: {
      status: SUCCESS,
      data: payload?.data?.[0] ?? null,
      error: null
    }
  }),
  [PRODUCT.AI_ANALYSIS_FAIL]: (state, { payload }) => ({
    ...state,
    aiAnalysis: {
      status: FAILURE,
      data: null,
      error: payload?.error
    }
  }),
  [PRODUCT.AI_ANALYSIS_RESET]: (state) =>  ({
    ...state,
    aiAnalysis: initialProductState.aiAnalysis
  })
};

export default aiAnalysis;
