const validations = {
  numer: "must be a number",
};

export const newProperty = (name, type, required, label, otherParams) => ({
  name,
  validationType: type,
  ...(validations[type] && {validationTypeError: validations[type]}),
  validations: {
    ...(required && {required : ["is required"]}),
    label: [label],
    ...otherParams
  },
});
