import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import CountUp from "react-countup";
import classNames from "classnames";
import { useAppSettings } from "_hooks";
import { ReactComponent as Doc } from "_utils/icons/doc.svg";
import { ReactComponent as Times } from "_utils/icons/cross.svg";
import { ReactComponent as Ready } from "_utils/icons/ready.svg";
import { ReactComponent as Lupa } from "_utils/icons/lens.svg";
import { ReactComponent as Lapiz } from "_utils/icons/pencil.svg";
import selectProductStore from "_redux/product/product.selector";

const prefix = "o-uploading-file";

const status = {
  loading: "Analyzing",
  success: "Success",
  failure: "Failure",
};

const UploadingFile = ({
  file,
  setType,
  update,
  selections,
  externalStatus,
  noPath,
}) => {
  const { t } = useTranslation();
  const { post: postData } = useSelector(selectProductStore);
  const classForComponent = classNames(prefix, {
    [`${prefix}--${postData.status}`]: postData.status,
    [`${prefix}--${externalStatus}`]: externalStatus,
    update: update,
  });

  const { appColumnKeyProps } = useAppSettings();

  useEffect(() => {
    let timeout;
    if (postData.status === "success") {
      timeout = setTimeout(() => {
        if (!update && typeof setType === "function")
          return setType("complete");
      }, 600);
    }
    return () => clearTimeout(timeout);
  }, [postData.status]);

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__icons`}>
        {!update ? (
          <Lupa className={`${prefix}__icons-lupa`} />
        ) : (
          <Lapiz className={`${prefix}__icons-lupa lapiz`} />
        )}
        <Times className={`${prefix}__icons-times`} />
        <Ready className={`${prefix}__icons-ready`} />
        <Doc className={`${prefix}__icons-doc`} />
      </div>
      <div className={`${prefix}__info`}>
        <div className={`${prefix}__info-header`}>
          <span className={`${prefix}__info-status ${postData.status}`}>
            {postData.status === "loading" || (externalStatus && update)
              ? t("products:newSku.loading")
              : status[externalStatus] || status[postData.status]}
          </span>
          {postData.status === "loading" || externalStatus === "loading" ? (
            <div className="dot-flashing"></div>
          ) : null}
        </div>
        {status === "loading" || !update ? (
          <span className={`${prefix}__info-file-name`}>
            {!noPath && (file.path?.length > 20 ? 
              file.path.substring(0, 20) + "..."
            :
              file.path
            )}
          </span>
        ) : (
          <div className={`${prefix}__info-totals`}>
            {selections.create ? (
              <div className={`${prefix}__content-item`}>
                <span className={`${prefix}__content-text`}>
                  {appColumnKeyProps?.name?.toUpperCase()} Created
                </span>
                <CountUp
                  end={postData?.data?.countRow?.toCreate}
                  className={`${prefix}__content-qty`}
                  duration={1}
                />
              </div>
            ) : null}{" "}
            {selections.update && postData.status === "success" ? (
              <div className={`${prefix}__content-item`}>
                <span className={`${prefix}__content-text`}>
                  {appColumnKeyProps?.name?.toUpperCase()} Updated
                </span>
                <CountUp
                  end={postData?.data?.countRow?.toUpdate}
                  className={`${prefix}__content-qty`}
                  duration={1}
                />
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadingFile;
