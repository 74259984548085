import { API, CORE_API, PLUGIN, PLUGIN_API, WEB } from '_constants/request.constants';
import createAxiosInterface from './interfaceAxios';

const callRequest = (endpoint, { base = 'API', cancelToken, data, headers, params, signal, type = 'get', ...extras }, enconde = false) => {
  const axios = createAxiosInterface(true, enconde);
  let baseURL = process.env.REACT_APP_BASE_URL;
  let url = '';
  
  switch (base) {
    case API:
      url = process.env.REACT_APP_API_PATH + endpoint;
      break;
    case PLUGIN:
      baseURL = process.env.REACT_APP_PLUGIN_URL;
      url = endpoint;
      break;
    case PLUGIN_API:
      baseURL = process.env.REACT_APP_PLUGIN_URL;
      url = process.env.REACT_APP_API_PATH + endpoint;
      break;
    case CORE_API:
      baseURL = process.env.REACT_APP_CORE_API;
      url = endpoint;
      break;
    case WEB:
    default:
      url += endpoint;
  }

  let config = {
    baseURL,
    cache: 'no-cache',
    cancelToken,
    data,
    headers,
    method: type.toLowerCase(),
    params,
    signal,
    url,
    ...extras
  };
  
  return axios(config)
    .then(response => response)
    .catch((err) => {
      if (err?.code !== "ERR_CANCELED") {
        return Promise.reject(err);
      }
    });
};
  

export default callRequest;
