import initialMediaLibState from "../mediaLib.initialState";
import { reduxActions as MEDIA_LIB } from "_constants/mediaLib.constants";
import { LOADING, SUCCESS } from "_constants/redux.constants";

const own = {
  [MEDIA_LIB.OWN_STARTED]: (state) => ({
    ...state,
    own: {
      ...state.own,
      status: LOADING
    }
  }),

  [MEDIA_LIB.OWN_SUCCESS]: (state, { payload }) => ({
    ...state,
    own: {
      ...state.own,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),

  [MEDIA_LIB.OWN_FAIL]: (state, { payload }) => ({
    ...state,
    own: {
      ...state.own,
      error: payload?.error ?? null,
      status: SUCCESS
    }
  }),

  [MEDIA_LIB.OWN_RESET]: (state) => ({
    ...state,
    own: initialMediaLibState
  }),

  [MEDIA_LIB.OWN_SET]: (state, { payload }) => ({
    ...state,
    own: payload
  })
};

export default own;
