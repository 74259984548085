import React, { useState, useEffect } from "react";
import { Filter, TabsPanel } from "components/organism";
import useProductFilter from "_hooks/useProductFilter";
import { useSelector } from "react-redux";
import selectUiStore, { selectFilter } from "_redux/ui/ui.selector";
import { selectReaderOneStore } from "_redux/reader/reader.selector";

const FilterLayout = ({ children, ...props }) => {
  const { filters, getTags } = useProductFilter();

  const readInfo = useSelector(selectReaderOneStore);
  const { isOpen } = useSelector(selectFilter);
  const { toolbar } = useSelector(selectUiStore);
  const [array, setArray] = useState({});

  useEffect(() => {
    const assingTags = async () => {
      let tags = await getTags();
      setArray(tags);
    };
    
    assingTags();
  }, [filters]);

  useEffect(() => {
    if (readInfo?.data?.found?.length) {
      setArray({
        ...array ?? {},
        tags: [
          ...array.tags,
          {
            label: `${readInfo?.data?.found?.length} PC9 filtered from file`,
            value: ``
          }
        ]
      });
    }
  }, [readInfo]);

  return (
    <>
      <Filter {...props} />
    
      {(
        (toolbar.current === "products" && filters && Object.keys(filters)) || 
        readInfo?.data?.found?.length) && 
      (
        <TabsPanel
          data={array}
          height={() => {}} 
          filterOpen={isOpen && toolbar.current === "products"}
          setArray={setArray}
        />
      )}
      <div style={{height:"100%"}}>{children}</div>
    </>
  );
};

export default FilterLayout;
