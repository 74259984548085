import { reduxActions as UI } from "_constants/ui.constants";
import initialUIState from "../ui.initialState";

const smartEditor = {
  [UI.SMART_EDITOR_OPEN]: (state, { payload }) => ({
    ...state,
    smartEditor: {
      data: payload,
      visible: true
    }
  }),

  [UI.SMART_EDITOR_CLOSE]: (state) => ({
    ...state,
    smartEditor: initialUIState.smartEditor
  })
};

export default smartEditor;
