import React from "react";
import { ProductStatistics, TaskStatistics } from "components/organism";
import { useSelector } from "react-redux";
import { selectStatistics } from "_redux/ui/ui.selector";

const Statistics = () => {
  const current = useSelector(selectStatistics);
  const statisticsManager = {
    products: ProductStatistics,
    tasks: TaskStatistics,
  };
  let renderedStatistics;
  if (typeof statisticsManager[current] !== "undefined") {
    const StatisticsComponent = statisticsManager[current];
    renderedStatistics = <StatisticsComponent />;
  }
  return <div className="t-statistics">{renderedStatistics}</div>;
};

export default Statistics;
