import { useDispatch, useSelector } from "react-redux";
import { findOne, put } from "_redux/resource/resource.actions";
import { selectResourceOne, selectResourcePutStore } from "_redux/resource/resource.selector";
import { ELIMINATED, reduxActions as RESOURCE } from "_constants/resource.constants";

const useResource = () => {
  const dispatch = useDispatch();

  const { data: oneData, error: oneError, status: oneStatus } = useSelector(selectResourceOne);
  const { data: putData, error: putError, status: putStatus } = useSelector(selectResourcePutStore);

  const fetchOne = ({ w }, extras = {}) => dispatch(findOne({ w }, {
    feedback: {
      error: true,
      success: false
    },
    ...extras
  }));

  const updateResource = (ids, changes = {}, extras = {}) => dispatch(put(ids, changes, extras));

  const cancelResource = (ids) => updateResource(ids, { status: ELIMINATED }, {
    endpoint: "/resource/zip",
    reqType: "DELETE"
  });

  const oneReset = () => dispatch({ type: RESOURCE.ONE_RESET });
  const putReset = () => dispatch({ type: RESOURCE.PUT_RESET });

  return {
    cancelResource,
    fetchOne,
    oneData,
    oneError,
    oneReset,
    oneStatus,
    putData,
    putError,
    putReset,
    putStatus,
    updateResource
  };
};

export default useResource;
