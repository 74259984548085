import { Button } from "components/atoms/index";

const ShowFound = ({ data: { found = [], notFound = [] }, process, prefix }) => {
  return (
    <>
      <div className={`${prefix}__showfound`}>
        <div>
          <div>{notFound?.length ?? 0 }</div>
          <div>GTINs serán agregados a la cuenta</div>
        </div>
        <div>
          <div>{found?.length ?? 0 }</div>
          <div>GTINs ya existen ¿Actualizar?</div>
        </div>
      </div>
      <div className={`${prefix}__buttons`}>
        <span></span>
        <div>
          <Button
            content={`Agregar (${notFound?.length ?? 0}) y actualizar (${found?.length ?? 0})`}
            onClick={() => process({ add: false, update: true})}
            sm
            type="purple"
          />
          <Button
            content={`Sólo agregar (${notFound?.length ?? 0})`}
            disabled={!notFound?.length}
            onClick={() => process({ add: true, update: false})}
            sm
            type="purple"
          />
        </div>
      </div>
    </>
  );
};

export default ShowFound;
