import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const allList = {
  [PRODUCT.ALL_LIST_STARTED]: (state) => ({
    ...state,
    allList: {
      ...state.allList,
      status: LOADING
    }
  }),
  [PRODUCT.ALL_LIST_FAIL]: (state, { payload }) => ({
    ...state,
    allList:{  
      ...state.allList,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [PRODUCT.ALL_LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    allList: {
      ...state.allList,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [PRODUCT.ALL_LIST_RESET]: (state) => ({
    ...state,
    allList: initialProductState.allList
  })
};

export default allList;
