import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FlexComponent } from "components/templates";
import { BillingCard, Metrics, ProgressCard, SimpleScroller } from "components/organism";
import { Paragraph, Title } from "components/atoms";

import capitalize from "_utils/string/capitalize";
import useBilling from "_hooks/useBilling";
import useUser from "_hooks/useUser";
import { NUBAI_MODULE } from "_constants/app.constants";

const prefix = "v-billing";

const Billing = () => {
  const { t } = useTranslation();
  const {
    accountsData: accounts,
    adaptationsData: adaptations,
    aiImagesData: aiImages,
    backRemovalsData: backRemovals,
    channelsData: channels,
    currentPlanData: currentPlan,
    dateFilter,
    fetchAccounts,
    fetchAdaptations,
    fetchAiImages,
    fetchBackRemovals,
    fetchPricing,
    fetchChannels,
    fetchCurrentPlan,
    fetchHeros,
    fetchIsometrics,
    fetchItems,
    fetchWatermarks,
    herosData: heros,
    isometricsData: isometrics,
    itemsData: items,
    watermarkData: watermarks
  } = useBilling();
  const { hasModule } = useUser();

  useEffect(() => {
    fetchAccounts();
    fetchPricing();
    fetchChannels();
    fetchCurrentPlan();
    fetchItems();
  }, []); 

  useEffect(() => {
    if (!dateFilter || !currentPlan?.billingDay) return;
    fetchAdaptations(currentPlan.billingDay, dateFilter);
    if (hasModule(NUBAI_MODULE)) fetchAiImages(currentPlan.billingDay, dateFilter);
    fetchBackRemovals(currentPlan.billingDay, dateFilter);
    fetchHeros(currentPlan.billingDay, dateFilter);
    fetchIsometrics(currentPlan.billingDay, dateFilter);
    fetchWatermarks(currentPlan.billingDay, dateFilter);
  }, [dateFilter, currentPlan]);

  return (
    <div className={prefix}>
      <SimpleScroller>
        <div className={`${prefix}__content`}>
          <div className={`${prefix}__cards`}>
            <div>
              <BillingCard 
                title={<Title content={<>Nubesk <b>{currentPlan?.billingPlanName || ''}</b></>} large secondary />}
                icon="settings"
                highlight="bottom"
                content={
                  <FlexComponent>
                    <div>
                      <Title
                        content={
                          currentPlan?.billingPlanId?.pricing?.[currentPlan?.billingType] || ''
                        } 
                        black xlarge
                      />
                    </div>
                    <div>
                      <Title
                        content={currentPlan?.billingPlanId?.pricing?.currency || ''} 
                        medium bold black
                      />
                      <Paragraph 
                        content={`${capitalize(currentPlan?.billingType)} payment`}
                        className="u-purple"
                      />
                    </div>
                  </FlexComponent>
                }
                bottom={<>
                  <FlexComponent variant="column">
                    <Paragraph content="Background removal credits included" className="u-purple"/>
                  </FlexComponent>

                  <FlexComponent variant="column">
                    <Title
                      content={currentPlan?.billingPlanId?.rule?.monthly?.creditsBackremoval || ''}
                      bold secondary
                    />
                  </FlexComponent>
                </>}
              />
              <div className={`${prefix}__activity`}>
                <ProgressCard
                  icon="users"
                  label="Active users"
                  available={accounts?.byAccount?.count}
                  total={accounts?.billingPlan?.limit}
                />
                <ProgressCard
                  icon="store"
                  label="Sales channels"
                  available={channels?.byAccount?.count}
                  total={channels?.billingPlan?.limit}
                />
                <ProgressCard
                  icon="products2"
                  label="SKU's"
                  available={items?.byAccount?.count}
                  total={items?.billingPlan?.limit}
                />
              </div>
            </div>


            <BillingCard 
              title={
                <Title content={t("settings:billing.monthlyTransformation")}
                secondary
                medium
              />}
              icon="warning"
              highlight="top"
              content={
                <>
                  <Metrics 
                    content={t("settings:billing.backgroundRemoval")}
                    icon="layer"
                    total={backRemovals?.billingPlan?.limit}
                    used={backRemovals?.byAccount?.count}
                  />
                  <Metrics
                    content={t("settings:billing.singleAdaptations")}
                    icon="resize"
                    used={adaptations?.byAccount?.count}
                    total={adaptations?.billingPlan?.limit}
                  />
                  <Metrics
                    content={t("settings:billing.watermark")}
                    icon="watermark"
                    used={watermarks?.byAccount?.count}
                    total={watermarks?.billingPlan?.limit}
                  />
                  <Metrics
                    content={t("settings:billing.heroImages")}
                    icon="hero"
                    used={heros?.byAccount?.count}
                    total={heros?.billingPlan?.limit}
                  />
                  <Metrics
                    content={t("settings:billing.isometrics")}
                    icon="measure"
                    used={isometrics?.byAccount?.count}
                    total={isometrics?.billingPlan?.limit}
                  />

                  {hasModule(NUBAI_MODULE) &&
                    <Metrics
                      content={t("settings:billing.aiImages")}
                      icon="robot"
                      used={aiImages?.byAccount?.count ?? 0}
                      total={aiImages?.billingPlan?.limit}
                    />
                  }
                </> 
              }
            />
          </div>


          {/* <div class={`${prefix}__history`}>
            <Title content="Billing history" secondary />
          </div> */}
        </div>
      </SimpleScroller>
    </div>
  );
};

export default Billing;
