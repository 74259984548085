import { useTranslation } from "react-i18next";
import computer from "_utils/images/computer.png";

const prefix = "v-screen-not-compatible";

const ScreenNotCompatible = () => {
  const { t } = useTranslation();

  return (
    <div className={prefix}>
      <img 
        alt="Screen no compatible"
        className={`${prefix}__image`}
        src={computer}
      />
      <div className={`${prefix}__title`}>
        {t("app:screenNotCompatible.title")}
      </div>
      <div className={`${prefix}__desc`}>
        {t("app:screenNotCompatible.desc")}
      </div>
    </div>
  )
};

export default ScreenNotCompatible;
