import { createSelector } from "reselect";

const selectReaderStore = (state) => state.reader;

export const selectReaderOneStore = createSelector(
  selectReaderStore,
  (reader) => reader.one
);

export default selectReaderStore;
