import { DEFAULT_BG_COLOR, REDIMENSION, TRANSPARENT, WITH_MARGINS } from "_constants/adaptation.constants";

const backgroundColor = ({ attributes, bgType, properties }) => {
  if (REDIMENSION === attributes?.type) {
    return [DEFAULT_BG_COLOR, DEFAULT_BG_COLOR];
  } else if (WITH_MARGINS === attributes?.type && properties?.background?.start) {
    return [
      properties.background.start,
      properties.background.end
    ];
  } else if (REDIMENSION !== attributes?.type && bgType === TRANSPARENT) {
    return null;
  } else if (properties?.background?.start) {
    return [
      properties.background.start,
      properties.background.end
    ];
  } else {
    return null
  }
};

export default backgroundColor;
