import initialCatalogState from "./catalog.initialState";
import add from "./reducers/add";
import addResource from "./reducers/addResource";
import all from "./reducers/all";
import one from "./reducers/one";
import orderBy from "./reducers/orderBy";
import post from "./reducers/post";
import put from "./reducers/put";
import removeResource from "./reducers/removeResource";
import share from "./reducers/share";
import shareUpdate from "./reducers/shareUpdate";
import toArchived from "./reducers/toArchived";
import toTrash from "./reducers/toTrash";

const reducer = (state = initialCatalogState, action) => {
  const reducers = {
    ...add,
    ...addResource,
    ...all,
    ...one,
    ...orderBy,
    ...post,
    ...put,
    ...removeResource,
    ...share,
    ...shareUpdate,
    ...toArchived,
    ...toTrash
  };

  let { type } = action;
  if (reducers[type] === undefined) return state;
  
  return reducers[type](state, action);
};

export default reducer;
