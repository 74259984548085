import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useAttribute } from "_hooks";
import GlobalAttributes from "./GlobalAttributes";
import AttributesTable from "./AttributesTable";
import { GLOBAL } from "_constants/layout.constants";
import { useChannelLayout } from "_hooks";

const prefix = "v-channel-attributes";

const ChannelAttributes = () => {
  const { layoutKey } = useParams();
  const location = useLocation();
  const { allData: attributes, fetch, fetchGlobal } = useAttribute();
  const { fetchOne, layoutReset } = useChannelLayout();

  useEffect(() => {
    return () => {
      layoutReset()
    };
  }, []);

  useEffect(() => {
    fetchOne({ f: ["name", "key"], w: { key: layoutKey }});
    

    if (layoutKey === GLOBAL) {
      let f = ["name", "class", "key", "flag", "index"];
      fetchGlobal({ f });
    } else {
      let f = ["name", "class", "key", "flag", "type", "index"];
      fetch(layoutKey, { f });
    }
  }, [location]);

  return (
    <div className={prefix}>
      {layoutKey === GLOBAL ? 
        <GlobalAttributes
          attributes={attributes}
          prefix={prefix}
        />
      : 
        <AttributesTable
          attributes={attributes}
          prefix={prefix}
        />
      }
    </div>
  );
};

export default ChannelAttributes;
