import { IDLE } from "_constants/redux.constants";

const initialTaskState = {
  all: {
    data: null,
    error: null,
    status: IDLE
  },
  items: {
    data: null,
    error: null,
    status: IDLE
  },
  list: {
    data: null,
    error: null,
    status: IDLE
  },
  one: {
    data: null,
    error: null,
    status: IDLE
  },
  post: {
    data: null,
    error: null,
    status: IDLE
  },
  put: {
    data: null,
    error: null,
    status: IDLE
  },
  removeFrom: {
    data: null,
    error: null,
    status: IDLE
  },
  selection: {
    allItems: false,
    allTasks: false,
    items: [],
    tasks: []
  }
};

export default initialTaskState;
