import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { InputTest } from "components/molecules/Inputs";
import { useFocus, useForm } from "_hooks";
import { closeModal } from "_redux/ui/ui.actions";
import { useAdaptation } from "_hooks";

const prefix = "o-new-adaptation-modal";

const NewAdaptationModal = ({ onSave, layoutKey }) => {
  console.log("🚀 ~ file: NewAdaptationModal.js:13 ~ NewAdaptationModal ~ layoutKey", layoutKey)
  const dispatch = useDispatch();
  const [inputRef] = useFocus();
  const { t } = useTranslation();
  const [adaptation, setAdaptation] = useState("");
  const { disable, error, form, handleChange } = useForm({
    schemaType: "newAdaptation",
    adaptation
  });
  const { createAdaptation } = useAdaptation();

  useEffect(() => {
    if (form.derivedFrom) {
      setAdaptation(form.derivedFrom);
    }
  }, [form]);
  
  const handleSubmit = async () => {
    const config = {
      name: form.name
    };

    await createAdaptation(layoutKey, config);
    return dispatch(closeModal());
  };

  return (
    <div className={prefix}>
      <div className={`${prefix}__division`}>
        <span className={`${prefix}__label`}>Nueva adaptación</span>
        <InputTest
          ref={inputRef}
          name="name"
          onChange={handleChange}
          error={error.name}
        />
      </div>
      <div className={`${prefix}__buttons`}>
        <Button content="Cancel" type="underline" onClick={() => dispatch(closeModal())} />
        <Button
          content={t("common:form.create")}
          type="purple"
          sm
          disabled={disable}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
};

export default NewAdaptationModal;
