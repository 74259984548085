import { reduxActions as ATTRIBUTE } from "_constants/attribute.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialAttributeState from "../attribute.initialState";

const update = {
  [ATTRIBUTE.UPDATE_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),

  [ATTRIBUTE.UPDATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      data: payload?.data ?? null,
      status: SUCCESS
    },
    all: {
      ...state.all,
      data: state.all.data.map((r) => {
        for (let d of payload?.data) {
          if (r?._id && r._id === d?._id) {
            r = {...r, ...d};
          }
        }
        return r;
      })
    }
  }),

  [ATTRIBUTE.UPDATE_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [ATTRIBUTE.UPDATE_RESET]: (state) => ({
    ...state,
    put: initialAttributeState.put
  })
}

export default update;
