import React from "react";
import { CountNumber, CountText } from "components/atoms";

const CountUpload = ({ pending, total, text }) => {
  return (
    <>
      <div className={"count"}>
        {" "}
        <CountNumber count={pending} />
        /
        <CountNumber variant={"gray"} count={total} />
      </div>
      <CountText text={text} />
    </>
  );
};

export default CountUpload;
