import { reduxActions as APP } from "_constants/app.constants";

const configurated = {
  [APP.CONFIGURED]: (state, { payload }) => ({      
    ...state,
    configured: payload
  })
};

export default configurated;
