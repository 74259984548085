import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FlexComponent } from "components/templates";
import RowTemplate from "../RowTemplate/RowTemplate";
import { CircleStatus } from "components/atoms";
import { openModal } from "_redux/ui/ui.actions";
import { selectProductDetails } from "_redux/product/product.selector";
import { uploadImages } from "_redux/file/file.actions";
import { APPROVED, REQUIRED, TOREVIEW } from "_constants/product.constants";
import { openManageImages } from "_redux/ui/actions/ui_manageImages.actions";
import { selectUser } from "_redux/user/user.selectors";
import { $$FOLDER, $LINK_DOWNLOAD } from "_constants/permission.constants";

const prefix = "m-master-row";

const EMPTY = "empty";

const MasterRow = ({
  date,
  id,
  isActive,
  hasCuts,
  onClick,
  onDelete,
  onDownload,
  onRemoveBg,
  onUpload,
  status,
  title,
  qty
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const product = useSelector(selectProductDetails);
  const user = useSelector(selectUser);


  const getOptions = () => {
    let finalOpts = [];
    let opts = {
      order: {
        onClick: () => dispatch(openManageImages()),
        content: t("manage:images.orderFiles")
      },
      download: {
        onClick: () => onDownload(id),
        content: t("products:dataPhotos.download"),
      },
      notify: {
        onClick: () => {},
        content: t("products:dataPhotos.notify"),
      },
      delete: {
        onClick: () => onDelete(),
        content: t("products:dataPhotos.delete_files"),
      },
      upload: {
        onClick: () => onUpload(),
        content: t("products:dataPhotos.upload_images"),
      },
      removeBg: {
        onClick: () => onRemoveBg(id),
        content: t("products:dataPhotos.remove_bg")
      },
      request: {
        onClick: () => {},
        content: t("products:dataPhotos.request_images")
      }
    };
    if (isActive && user.canDoIt($$FOLDER.APPROVE_MASTER)) finalOpts = [opts.order];

    switch (status) {
      case APPROVED:
        if (user.canDoIt($LINK_DOWNLOAD)) finalOpts.push(opts.download);
        // if (user.canDoIt($$FOLDER.APPROVE_MASTER)) finalOpts.push(opts.notify);
        if (user.canDoIt($$FOLDER.EDIT) && (!hasCuts || hasCuts !== qty))
          finalOpts.push(opts.removeBg);
        if (user.canDoIt([$$FOLDER.DELETE, $$FOLDER.APPROVE_MASTER])) 
          finalOpts.push(opts.delete);
        break;
      case TOREVIEW:
        if (user.canDoIt($$FOLDER.UPLOAD)) finalOpts.push(opts.upload)
        if (user.canDoIt($$FOLDER.APPROVE_MASTER)) finalOpts.push(opts.request);
        if (user.canDoIt([$$FOLDER.DELETE, $$FOLDER.APPROVE_MASTER]))
          finalOpts.push(opts.delete);
        break;
      case EMPTY:
      case REQUIRED:
        if (user.canDoIt($$FOLDER.UPLOAD)) finalOpts.push(opts.upload);
        if (user.canDoIt($$FOLDER.APPROVE_MASTER)) finalOpts.push(opts.request);
      default:
    }
    
    return finalOpts;
  };

  return (
    <RowTemplate
      onClick={onClick}
      title={title}
      qty={qty}
      isActive={isActive}
      options={getOptions()}
    >
      <FlexComponent>
        <CircleStatus status={status} />
        {status === APPROVED ? (
          <>
            <span className={`${prefix}__date`}>{date}</span>
          </>
        ) : (
          <button
            onClick={(e) => {
              e.stopPropagation();
              onUpload()
            }}
            className={`${prefix}__generate-button`}
          >
            {t("products:dataPhotos.upload_button")}
          </button>
        )}
      </FlexComponent>{" "}
    </RowTemplate>
  );
};

export default MasterRow;
