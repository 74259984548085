import React from "react";
import { Button, NubeskIcons } from "components/atoms/index";

const ContextButton = ({
  onClick,
  name,
  shortcut,
  icon,
  disabled,
  iconSize,
  ...props
}) => {
  return (
    <Button
      content={
        <>
          <div>
            <NubeskIcons icon={icon} size={iconSize || 15} />
            <span>{name}</span>
          </div>{" "}
          <span className="shortcut">{shortcut}</span>
        </>
      }
      type={"context"}
      onClick={onClick}
      disabled={disabled}
      {...props}
    />
  );
};

export default ContextButton;
