import initialTaskState from "../task.initialState";
import { reduxActions as TASK } from "_constants/task.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const one = {
  [TASK.ONE_STARTED]: (state) => ({
    ...state,
    one: {
      ...state.one,
      status: LOADING
    }
  }),

  [TASK.ONE_FAIL]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [TASK.ONE_SUCCESS]: (state, { payload }) => ({
    ...state,
    one: {
      ...state.one,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),

  [TASK.ONE_REFRESH]: (state, { payload }) => {
    let newState = {...state.one.data};

    if (newState?._id === payload?._id) {
      newState = { ...newState, ...payload.c };
    }

    return {
      ...state,
      one: {
        ...state.one,
        data: newState
      }
    };
  },

  [TASK.ONE_RESET]: (state) => ({
    ...state,
    one: initialTaskState.one
  })
};

export default one;
