import { IDLE } from '_constants/redux.constants';

const initialState = {
  adaptations: {
    data: null,
    error: null,
    status: IDLE
  },
  columnKey: {
    data: null,
    error: null,
    status: IDLE
  },
  configured: null,
  configGot: false,
  componentDrawing: {
    data: null,
    error: null,
    status: IDLE
  },
  copyWorkflow: {
    data: null,
    error: null,
    status: IDLE
  },
  externalNubai: {
    data: null,
    error: null,
    status: IDLE
  },
  fonts: {
    data: null,
    error: null,
    status: IDLE
  },
  imageryWorkflow: {
    data: null,
    error: null,
    status: IDLE
  },
  layers: {
    data: null,
    error: null,
    status: IDLE
  },
  mediaHelp: {
    data: null,
    error: null,
    status: IDLE
  },
  modules: {
    data: null,
    error: null,
    status: IDLE
  },
  lookAndFeel: {
    data: null,
    error: null,
    status: IDLE
  },
  propertyColumns: {
    data: null,
    error: null,
    status: IDLE
  },
  putPropertyColumns: {
    data: null,
    error: null,
    status: IDLE
  },
  template: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialState;
