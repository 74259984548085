import PropTypes from 'prop-types';
import classNames from 'classnames';

const prefix = 't-drawer';

const Drawer = ({ children, closable, header, onClose, open, placement, width }) => {
  const classForComponent = classNames(prefix, {
    [`${prefix}__right`]: placement === 'right' ,
    [`${prefix}__left`]: placement === 'left',
    [`${prefix}__open`]: open,
  });
  
  const close = (e) => {
    if (closable) onClose();
  };

  return (
    <div tabIndex="-1" className={classForComponent} >
      <div className={`${prefix}__mask`} onClick={close}></div>
      <div className={`${prefix}__content-wrapper`} style={{ width: `${width}px`, transform: open ? '': 'translateX(100%)'}} >
        <div className={`${prefix}__content`} >
          <div className={`${prefix}__wrapper-body`}>
            <div className={`${prefix}__header`} >
              {header}
            </div>
            <div className={`${prefix}__body`}>
              {children}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Drawer.propTypes = {
  closable: PropTypes.bool,
  header: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
  placement: PropTypes.oneOf(['right', 'left']).isRequired,
  width: PropTypes.number
};

Drawer.defaultProps = {
  closable: true,
  header: null,
  onClose: null,
  open: false,
  placement: 'right',
  width: 350
};

export default Drawer;
