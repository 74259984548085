import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const columnKey = {
  [APP.COLUMN_KEY_STARTED]: (state) => ({
    ...state,
    columnKey: {
      ...state.columnKey,
      status: LOADING,
    },
  }),

  [APP.COLUMN_KEY_SUCCESS]: (state, { payload }) => {
    let key = payload?.data?.[0]?.key ?? null;
    return {
      ...state,
      columnKey: {
        ...state.columnKey,
        data: key,
        properties: payload?.data?.[0] ?? null,
        status: SUCCESS,
      },
    };
  },

  [APP.COLUMN_KEY_FAIL]: (state, { payload }) => ({
    ...state,
    columnKey: {
      ...state.columnKey,
      error: payload?.error,
      status: FAILURE,
    },
  }),
};

export default columnKey;
