import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import useTooltip from "_hooks/useTooltip";
import useProductFilter from "_hooks/useProductFilter";

import { FlexComponent } from "components/templates";
import { Button, NubeskIcons } from "components/atoms";
import { DropDown } from "components/templates/index";

import { OWNER } from "_constants/catalog.constants";
import { openModal, openPanelLeft, toggleSelected } from "_redux/ui/ui.actions";
import { fetchAllList } from "_redux/product/product.actions";
import { selectProductCountPiece } from "_redux/product/product.selector";
import { selectSkuSelected } from "_redux/ui/ui.selector";

const SelectionToolbar = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selections = useSelector(selectSkuSelected);
  const count = useSelector(selectProductCountPiece);
  const { filterToW } = useProductFilter();
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });

  const itemsSelected = async () => {
    let arraySkus = Object.keys(selections.selected);
    if (selections.all) {
      let filters = await filterToW();
      let skus = await dispatch(fetchAllList({ w: filters || {} }));
      arraySkus = skus.data.map((item) => item._id);
    }
    return arraySkus;
  };
  const downloadSelected = async () => {
    let arraySkus = await itemsSelected();
    dispatch(
      openModal({
        typeModal: "downloadXls",
        header: {
          text: "Download XLS",
          rightItem: `(${
            arraySkus.length - Object.keys(selections.selected).length ||
            Object.keys(selections.selected).length
          }) Items`,
        },
      })
    );
  };
  const openBulkModal = async ({ typeModal, text }) => {
    let arraySkus = await itemsSelected();
    dispatch(
      openModal({
        typeModal,
        header: {
          text,
          rightItem: `(${
            arraySkus.length - Object.keys(selections.selected).length ||
            Object.keys(selections.selected).length
          }) Items`,
        },
      })
    );
  };

  return (
    <Fragment>
      <FlexComponent>
        <Button
          type={"primary"}
          content={`${
            selections.all
              ? t("common:form.deselect_all")
              : t("common:form.select_all")
          } (${
            selections.all
              ? count - Object.keys(selections.selected).length
              : Object.keys(selections.selected).length
          }/${count ?? ""})`}
          onClick={() => dispatch(toggleSelected())}
        />
        <Button
          leftIcon={<NubeskIcons icon="plus" />}
          content={t("products:actions.catalog")}
          type={"primary"}
          onClick={() =>
            dispatch(
              openPanelLeft({
                typePanel: "catalog",
                panelProps: {
                  area: "My catalogs",
                  withSelection: true,
                  type: OWNER,
                },
              })
            )
          }
        />
        <DropDown
          scrollOptions={{ translateContentSizeXToHolder: true }}
          options={[
            {
              onClick: () => {
                dispatch(
                  openModal({
                    typeModal: "upload",
                    header: { text: "Upload bulk file" },
                  })
                );
              },
              content: t("products:newSku.upload_bulk"),
              leftIcon: <NubeskIcons icon="catalogs" />,
              style: { width: "max-content" },
            },
            {
              onClick: () => {},
              content: "Upload bulk photos",
              onClick: () => {
                dispatch(
                  openModal({
                    typeModal: "uploadBulkImages",
                    header: { text: "Upload bulk images" },
                  })
                );
              },
              leftIcon: <NubeskIcons icon="photos" />,
              style: { width: "max-content" },
            },
            {
              onClick: () => {},
              content: "Approve photos",
              onClick: () => {
                dispatch(
                  openModal({
                    typeModal: "approveBulkPhoto",
                    header: {
                      text: "Approve imagery",
                      rightItem: `(${
                        Object.keys(selections.selected).length
                      }) Items`,
                    },
                  })
                );
              },
              leftIcon: <NubeskIcons icon="photos" />,
              style: { width: "max-content" },
            },
          ]}
          buttonProps={{
            content: t("products:actions.more_actions"),
            type: "primary",
            rightIcon: <NubeskIcons icon="down" />,
          }}
        />
      </FlexComponent>

      <FlexComponent>
        <Button
          type="gray"
          content={t("products:actions.download_xls")}
          onClick={downloadSelected}
        />

        <div {...injectedProps}>
          <Button
            type="gray"
            content={"Set field"}
            onClick={() => {}}
            disabled
          />
        </div>
      </FlexComponent>
    </Fragment>
  );
};

export default SelectionToolbar;
