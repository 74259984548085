import classNames from "classnames";

const prefix = "o-card";

const Card = ({ header, children, className, ...props}) => {

  const classForComponent = classNames(prefix, {
    [className]: className
  });

  return (
    <div className={classForComponent} {...props}>
      <div className={`${prefix}__header`}>
        {header}
      </div>
      <div className={`${prefix}__content`}>
        {children}
      </div>
    </div>
  );
};

export default Card;
