import jsonToUrl from "_utils/url/jsonToUrl";

const toDetails = async ({ action, history, location, params, key }) => {
  let { pathname, search: queryString } = location;

  if (params?.taskKey) {
    queryString = await jsonToUrl({}, {from: { taskKey: params?.taskKey }});
  }
  
  action(pathname);
  history.push({
    pathname: `/products/${key}/photos`,
    search: queryString
  });
};

export default toDetails;
