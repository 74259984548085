import { reduxActions as CATALOG } from "_constants/catalog.constants";

const orderBy = {
  [CATALOG.ORDER_BY]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: [
        ...state?.all?.data?.slice()?.sort((a, b) => {
          if (typeof a[payload] === "string")
            return a[payload].localeCompare(b[payload]);
          if (Array.isArray(a[payload])) return a[payload] > b[payload];
        }) ?? []
      ],
    },
  })
};

export default orderBy
