import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import useForm from "_hooks/useForm";
import { InputLabel, InputTest, Select } from "components/molecules/Inputs";
import { Button } from "components/atoms";
import { closeModal } from "_redux/ui/ui.actions";
import { useUsersPanel, useProfile } from "_hooks";
import { LOADING } from "_constants/redux.constants";
import { ADMIN, COMMERCIAL } from "_constants/user.constants";

const prefix = "o-user-form-modal";

const UserFormModal = ({ user }) => {
  const dispatch = useDispatch();
  const { fetchProfiles, profileList } = useProfile();
  const { t } = useTranslation();
  const { postStatus, addUser } = useUsersPanel();

  const inputRef = useRef(null);
  
  const [userForm] = useState({});

  const { 
    changeHandler,
    disable: disabledForm, 
    error: formError, 
    form
  } = useForm({
    schemaType: "newUser",
    userForm
  });

  const submitHadler = async () => {
    await addUser(form);
    dispatch(closeModal());
  };

  useEffect(() => {
    inputRef.current.focus();
    if (!profileList) fetchProfiles();
  }, []);  

  return (
    <div className={prefix}>
      <div className={`${prefix}__flex-columns`}>
        <InputLabel label={"Nombre"}>
          <InputTest 
            errors={formError?.firstName}
            name="firstName" 
            onChange={changeHandler}
            ref={inputRef}
            value={form?.firstName}
          />
        </InputLabel>

        <InputLabel label={"Apellido"}>
          <InputTest 
            error={formError?.lastName}
            name="lastName"
            onChange={changeHandler}
            value={form?.lastName}
          />
        </InputLabel>
      </div>

      <InputLabel label={"Correo electrónico"}>
        <InputTest
          onChange={changeHandler}
          name="email"
          error={formError?.email}
          value={form?.email}
        />
      </InputLabel>

      <InputLabel label={"Perfil"}>
        <Select
          emptyLabel={t("common:form.select")}
          onChange={(d) => changeHandler({
            target: { name: "profile", value: d }
          })}
          options={profileList.map((p) => ({
            label: p.name,
            value: {
              _id: p._id,
              code: p.code
            }
          }))}
          value={form?.profile}
        />
      </InputLabel>

      <div className={`${prefix}__buttons`}>
        <Button 
          content={t("common:form.cancel")}
          onClick={() => dispatch(closeModal())}
          sm
          type="underline"
        />
        <Button
          content={t(`common:form.${user?._id ? "save" : "create"}`)}
          disabled={disabledForm || postStatus === LOADING || !form?.profile}
          onClick={submitHadler}
          sm
          type="purple"
        />
      </div>
    </div>
  )
};

export default UserFormModal;
