import { reduxActions as FILE } from "_constants/file.constants";

/**
 *
 * @param {Object} params
 * @param {Array} types
 * @param {Object} extras
 * @returns
 */
export const get = (params, types = null, extras = {}) => (dispatch) => {
  if (!types) {
    types = [FILE.GET_STARTED, FILE.GET_SUCCESS, FILE.GET_FAIL];
  }

  return dispatch({
    'NBSK': {
      endpoint: '/file',
      types,
      reqType: 'POST',
      ...extras,
      params
    }
  });
};

//Sort images
export const putSort = (data) => dispatch => {
  return dispatch({
    'NBSK': {
      endpoint: '/file',
      types: [FILE.PUT_STARTED, FILE.PUT_SUCCESS, FILE.PUT_FAIL],
      reqType: 'PUT',
      data : {
        w: data.ids,
        c: data.sort
      }
    }
  });
}
/**
 *
 * @param {Object} params
 * @param {Array} types
 * @param {Object} extras
 * @returns
 */
export const post = (data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [FILE.POST_STARTED, FILE.POST_SUCCESS, FILE.POST_FAIL];
  }

  return dispatch({
    'NBSK': {
      endpoint: '/file',
      reqType: 'POST',
      ...extras,
      data
    }
  });
};

/**
 *
 * @param {String | Array} keys
 * @param {Object} data
 * @param {Array} types
 * @param {Object} extras
 * @returns
 */
export const put = (keys, data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [FILE.POST_STARTED, FILE.POST_SUCCESS, FILE.POST_FAIL];
  }

  if (!Array.isArray(keys)) keys = [ keys ];

  return dispatch({
    'NBSK': {
      endpoint: '/file',
      reqType: 'POST',
      ...extras,
      data: {
        o: keys,
        c:data
      }
    }
  });
};

export const remove = (ids, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [FILE.POST_STARTED, FILE.POST_SUCCESS, FILE.POST_FAIL];
  }

  return dispatch({
    'NBSK': {
      endpoint: '/file',
      reqType: 'DELETE',
      ...extras,
      data: {
        w: ids
      }
    }
  });
};

/**
 *
 * @param {array} files
 * @param {Object} params
 * @returns
 */
export const uploadImages =
  (files, params = {}, noFeedback = false) =>
  (dispatch) => {
    let data = new FormData();
    for (let i in files) {
      data.append("files", files[i]);
    }

    for (let i in params) {
      data.append(i, params[i]);
    }
    let feedback = noFeedback
      ? null
      : {
          success: {
            area: "products:dataPhotos.feedback.upload.area",
            info: "products:dataPhotos.feedback.upload.success",
          },
          error: true,
        };
    return dispatch(
      post(data, {
        endpoint: "/file/image/upload",
        headers: { "Content-Type": "multipart/form-data" },
        feedback,
      })
    );
  };

export const removeBg =
  (folderId, adaptationMasterId = undefined, extras = {}) =>
  (dispatch) => {
    return dispatch(
      post(
        {
          folderId,
          adaptationMasterId
        },
        {
          endpoint: "/file/image/backremoval",
          types: [
            FILE.REMOVEBG_STARTED,
            FILE.REMOVEBG_SUCCESS,
            FILE.REMOVEBG_FAIL
          ],
          feedback: {
            success: {
              area: "products:dataPhotos.feedback.removeBg.area",
              info: "products:dataPhotos.feedback.removeBg.success",
            },
            error: true,
          },
          ...extras
        }
      )
    );
  };

export const generate =
  (folderId, adaptationId, adaptationMasterId = undefined, overwrite = false, extras = {}) =>
  (dispatch) => {
    let config = {
      folderId,
      adaptationId,
      adaptationMasterId,
      $config: {
        overwrite: {
          adaptation: overwrite
        }
      }
    };

    return dispatch(
      post(config,
        {
          endpoint: "/file/image/adaptation",
          feedback: {
            success: {
              area: "products:dataPhotos.feedback.generateAdaptation.area",
              info: "products:dataPhotos.feedback.generateAdaptation.success",
            },
            error: true,
          },
          ...extras
        }
      )
    );
  };

export const trimm = (folderId, adaptationMasterId) => (dispatch) => 
  dispatch(
    post({
      folderId,
      adaptationMasterId,
    }, {
      endpoint: "/file/image/trimm",
      feedback: {
        success: {
          area: "products:dataPhotos.feedback.trimm.area",
          info: "products:dataPhotos.feedback.trimm.success",
        },
        error: {
          area: "products:dataPhotos.feedback.trimm.area",
          error: "products:dataPhotos.feedback.trimm.error",
        }
      },
      types: [
        FILE.TRIM_STARTED, FILE.TRIM_SUCCESS, FILE.TRIM_FAIL
      ]
    })
  );

export const download = (files) => (dispatch) =>
  dispatch(
    get({ files }, null, {
      feedback: {
        success: true,
        error: true,
      },
    })
  );

export const notifyError = (keys) => (dispatch) =>
  dispatch(
    put(
      keys,
      {
        process: "error",
      },
      {
        feedback: {
          success: true,
          error: true,
        },
      }
    )
  );

export const toSmartEditor = (ids) => (dispatch) => 
  dispatch(post({
    filesId: ids
  }, {
    types: [
      FILE.TO_SMART_EDITOR_STARTED,
      FILE.TO_SMART_EDITOR_SUCCESS,
      FILE.TO_SMART_EDITOR_FAIL
    ],
    endpoint: "/file/image/editor"
  }));

/**
 * 
 * @param {String | Array} id 
 * @param {Object} $config 
 * @param {Object} extras 
 * @returns 
 */
export const saveEdition = (id, $config, extras = {}) => (dispatch) => 
  dispatch(post({
    fileId: id,
    $config
  }, {
    types: [FILE.SAVE_EDITION_STARTED, FILE.SAVE_EDITION_SUCCESS, FILE.SAVE_EDITION_FAIL],
    feedback: {
      success: false,
      error: {
        area: "",
        info: "" 
      }
    },
    ...extras,
    endpoint: "/file/image/editor/done"
  }));

export const swatch = (data) => (dispatch) => dispatch(
  post(data, {
    types: [
      FILE.SWATCH_STARTED,
      FILE.SWATCH_SUCCESS,
      FILE.SWATCH_FAIL
    ],
    endpoint: "/file/image/swatch"
  })
);

export const clearAll = () => (dispatch) => dispatch({ type: FILE.ALL_RESET });
export const oneAll = () => (dispatch) => dispatch({ type: FILE.ONE_RESET });
export const postAll = () => (dispatch) => dispatch({ type: FILE.POST_RESET });
export const putAll = () => (dispatch) => dispatch({ type: FILE.PUT_RESET });
export const smartEditorReset = () => (dispatch) => dispatch({ type: FILE.TO_SMART_EDITOR_RESET });
export const swatchReset = () => (dispatch) => dispatch({ type: FILE.SWATCH_RESET });

export const resetAllProps = () => (dispatch) => {
  dispatch(clearAll());
  dispatch(oneAll());
  dispatch(postAll());
  dispatch(putAll());
  dispatch(smartEditorReset())
};
