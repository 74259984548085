import { useDispatch, useSelector } from "react-redux";
import { post, remove } from "_redux/file/file.actions";
import { selectFileAiBgGenerate } from "_redux/file/file.selector";
import { reduxActions as FILE } from "_constants/file.constants";

const useFile = () => {
  const dispatch = useDispatch();

  const {
    data: aiBgGenerateData,
    error: aiBgGenerateError,
    status: aiBgGenerateStatus
  } = useSelector(selectFileAiBgGenerate);
  
  /**
   * 
   * @param {Array | String} ids 
   * @param {*} extras 
   * @returns 
   */
  const eliminate = (ids, extras = {}) => dispatch(
    remove(Array.isArray(ids) ? ids : [ids], extras)
  );
  
  const aiBgGenerate = ({ data, folderId, fileId, adaptationLayerId, $config }, extras = {}) => {
    return dispatch(post({
      c: [{ folderId, fileId, adaptationLayerId, data}],
      $config 
    }, {
      types: [
        FILE.AI_BG_GENERATE_STARTED,
        FILE.AI_BG_GENERATE_SUCCESS,
        FILE.AI_BG_GENERATE_FAIL
      ],
      endpoint: "/file/image/generated",
      ...extras
    }))
  };

  /* RESETS */
  const aiBgGenerateReset = () => dispatch({ type: FILE.AI_BG_GENERATE_RESET });

  return {
    aiBgGenerate,
    aiBgGenerateData,
    aiBgGenerateError,
    aiBgGenerateReset,
    aiBgGenerateStatus,
    eliminate
  };
};

export default useFile;
