import { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import i18next from "_config/lang/i18n";
import { Calendar } from "react-date-range";
import * as locales from 'react-date-range/dist/locale';
import { InputLabel, InputTest, Select } from "components/molecules/Inputs";
import { Button } from "components/atoms";
import { useForm, useTask } from "_hooks";
import { closeModal } from "_redux/ui/ui.actions";
import { post, resetPost } from "_redux/task/task.actions";
import { selectUser } from "_redux/user/user.selectors";
import { selectAppColumnKey } from "_redux/app/app.selector";
import { selectTaskPost } from "_redux/task/task.selector";
import { sleep } from "_utils/async";
import { TYPE_GENERIC, TYPE_IMAGERY_REVIEW, TYPE_SHOOTING_SKU, TYPE_TEXT_REVIEW } from "_constants/task.constants";
import { LOADING } from "_constants/redux.constants";

const prefix = "o-new-task-form";

const NewTaskForm = ({ elements, onCancel }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showCalendar, setShowCalendar] = useState(false);
  const [taskForm] = useState({});

  const inputRef = useRef(null);
  const { create } = useTask();

  const { 
    changeHandler,
    disable: disabledForm, 
    error: errorForm, 
    form
  } = useForm({
    schemaType: "newTask",
    taskForm
  });

  const user = useSelector(selectUser);
  const columnKey  = useSelector(selectAppColumnKey);
  const { status: reqStatus } = useSelector(selectTaskPost);

  const submitHadler = async () => {
    let { date, description,  name, type } = form;

    await create({
      foldersId: elements,
      name,
      type,
      data: {
        description
      },
      process: {
        deliveryDate: date
      },
      userId: user._id,
      share: [],
      tasksId: []
    });

    dispatch(closeModal());
  };

  useEffect(() => {
    inputRef.current.focus();

    return () => {
      dispatch(resetPost());
    };
  }, []);

  return (
    <div className={prefix}>
      <InputLabel label={t("tasks:newTask.fields.name.label")}>
        <InputTest
          onChange={changeHandler}
          name="name"
          ref={inputRef}
          error={errorForm?.name}
        />
      </InputLabel>

      <InputLabel label={t("tasks:newTask.fields.description.label")}>
        <InputTest 
          minRows={3}
          name="description"
          onChange={changeHandler}
          error={errorForm?.name}
        />
      </InputLabel>
      
      <div className={`${prefix}__flex-columns`}>
        <InputLabel label={t("tasks:newTask.fields.date.label")}>
          <InputTest 
            errors={errorForm?.date}
            name="date" 
            value={form?.date ? moment(form?.date).format("YYYY-MM-DD") : undefined}
            onFocus={() => setShowCalendar((v => !v))}
            onChange={(e) => {
              changeHandler(e);
              if (showCalendar) sleep(200).then(() => setShowCalendar(false))
            }}
          />
          {showCalendar && 
            <div className={`${prefix}__calendar-container`}>
              <Calendar
                direction="horizontal"
                onChange={(d) => {
                  setShowCalendar(false);
                  changeHandler({
                    target: { name: "date", value: d}
                  })
                }}
                date={form.date}
                dateDisplayFormat="YYYY-MM-DD"
                locale={locales?.[i18next.language]}
              />
            </div>
          }
        </InputLabel>

        <InputLabel label={t("tasks:newTask.fields.type.label")}>
          <Select
            emptyLabel={t("tasks:newTask.fields.type.placeholder")}
            error={errorForm?.type}
            onChange={(v) => changeHandler({
              target: { name: "type", value: v.value }
            })}
            options={[{
              label: t("tasks:newTask.types.GENERIC_TASK"),
              value: TYPE_GENERIC
            }, {
              label: t("tasks:newTask.types.SHOOTING_SKU", {key: columnKey }),
              value: TYPE_SHOOTING_SKU
            }, {
              label: t("tasks:newTask.types.TEXT_REVIEW"),
              value: TYPE_TEXT_REVIEW
            }, {
              label: t("tasks:newTask.types.IMAGERY_REVIEW"),
              value: TYPE_IMAGERY_REVIEW
            }]}
          />
        </InputLabel>
      </div>

      <div className={`${prefix}__buttons`}>
        <Button 
          content={t("common:form.cancel")}
          onClick={onCancel}
          sm
          type="underline"
        />
        <Button 
          content={t("tasks:newTask.createBtn")}
          disabled={disabledForm || reqStatus === LOADING}
          onClick={submitHadler}
          sm
          type="purple"
        />
      </div>
    </div>
  );
};

NewTaskForm.propTypes = {
  onCancel: PropTypes.func
};

NewTaskForm.defaultProps = {
  onCancel: () => {}
};

export default NewTaskForm;
