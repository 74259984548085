import moment from "moment";

const dateFormatFilter = (billingDay, month, year) => {
  
  let output = {
    start: "",
    end: ""
  };

  let start = moment(`${year}-${month}-${billingDay}`, "YYYY-MM-DD")
    .format("YYYY-MM-DD").toString();
  let end = moment(`${year}-${month}-${billingDay}`, "YYYY-MM-DD")
    .add(1, "M").format("YYYY-MM-DD").toString();

  output = {
    start,
    end
  };

  return output;
};

export default dateFormatFilter;
