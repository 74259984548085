import React from "react";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs"; //TODO remplazar por nubesk-icons llegado a esta vista

import { Button } from "components/atoms";
import { FlexComponent } from "components/templates";

const prefix = "o-previous-next";

const PreviousNext = ({ current, total, onClickNav}) => {
  
  return (
    <div className={prefix}>
      <FlexComponent sm>
        <Button
          content={<BsArrowLeft />}
          isActive
          className={`${prefix}__button`}
          onClick={() => onClickNav(-1)}
        />
        <div className={`${prefix}__info`}>
          <span className={`${prefix}__info-current`}>{current || '-'}</span> / <span>{total || '-'}</span>
        </div>
        <Button
          content={<BsArrowRight />}
          isActive
          className={`${prefix}__button`}
          onClick={() => onClickNav(1)}
        />
      </FlexComponent>
    </div>
  );
};

export default PreviousNext;
