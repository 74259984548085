import { useEffect, useState, useRef } from "react";
import * as yup from "yup";

import attributeFormSchema from "_config/formSchemas/attributes/attributeForm.schema";
import newChannelSchema from "_config/formSchemas/salesChannel/newSalesChannel.schema";
import channelSchema from "_config/formSchemas/salesChannel/salesChannel.schema";
import channelMasterSchema from "_config/formSchemas/salesChannel/salesChannelMaster.schema";
import newAdaptationSchema from "_config/formSchemas/adaptation/newAdaptation.schema";
import newGlobalAttributeSchema from "_config/formSchemas/attributes/newAttributeGlobal.schema";
import newLayerSchema from "_config/formSchemas/layers/newLayer.schema";
import newTaskSchema from "_config/formSchemas/tasks/newTask.schema";
import newUserSchema from "_config/formSchemas/users/userForm.schema";
import putLayerSchema from "_config/formSchemas/layers/putLayer.schema";
import saveAdaptationSchema from "_config/formSchemas/adaptation/saveAdaptation.schema";

const schemas = {
  attributeForm: attributeFormSchema,
  newChannel: newChannelSchema,
  channel: channelSchema,
  channelMaster: channelMasterSchema,
  newAdaptation: newAdaptationSchema,
  newGlobalAttribute: newGlobalAttributeSchema,
  newLayer: newLayerSchema,
  newTask: newTaskSchema,
  newUser: newUserSchema,
  putLayer: putLayerSchema,
  saveAdaptation: saveAdaptationSchema
};

const useForm = ({ schemaType, formInitialState, channel }) => {
  const schema = useRef(null);
  const [disable, setDisable] = useState(true);
  const [form, setForm] = useState(formInitialState || {});
  const [error, setError] = useState({});

  useEffect(() => {
    if (schemas[schemaType]) {
      schema.current = getValidationSchema(schemas[schemaType]);
    }
  }, [schemaType]);

  useEffect(() => {
    if (form) {
      validateForm(schema.current);
    }
  }, [form]);

  useEffect(() => {
    if (channel) {
      validateForm(schema.current);
    }
  }, [channel]);

  const changeHandler = ({ target: { name, value } }) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const validateForm = (schema) => {
    schema
      .validate({ ...form }, { abortEarly: false })
      .then((value) => {
        setError({});
        setDisable(!value);
      })
      .catch((error) => {
        const _error = {};
        error.inner.forEach((e) => {
          _error[e.path] = `${e.params.label} ${e.message}`;
        });
        setError(_error);
        setDisable(true);
      });
  };

  const getValidationSchema = (fields) => {
    //Desglosa el objeto para retornar el schema de yup
    //Va elemento por elemento
    const schema = fields.reduce((schema, field) => {
      const {
        name,
        validationType,
        validationTypeError,
        validations = [],
      } = field;
      //Se pueden usar objetos anidados separandolos con punto
      const isObject = name.indexOf(".") >= 0;

      if (!yup[validationType]) {
        return schema;
      }
      let validator = yup[validationType]().typeError(
        validationTypeError || ""
      );
      //Se buscan en las validaciones extra
      for (let type in validations) {
        if (!validator[type]) {
          return;
        }
        //termina con la estructura .type(...params)
        validator = validator[type](...validations[type]);
      }

      if (!isObject) {
        return schema.concat(yup.object().shape({ [name]: validator }));
      }

      const reversePath = name.split(".").reverse();
      const currNestedObject = reversePath.slice(1).reduce(
        (yupObj, path, index, source) => {
          if (!isNaN(path)) {
            return { array: yup.array().of(yup.object().shape(yupObj)) };
          }
          if (yupObj.array) {
            return { [path]: yupObj.array };
          }
          return { [path]: yup.object().shape(yupObj) };
        },
        { [reversePath[0]]: validator }
      );

      const newSchema = yup.object().shape(currNestedObject);
      return schema.concat(newSchema);
    }, yup.object().shape({}));

    return schema;
  };

  return {
    changeHandler,
    disable,
    error,
    form,
    handleChange: changeHandler,
    setForm
  };
};

export default useForm;
