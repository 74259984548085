export const reduxActions = {
  ALL_STARTED: "resource/all_started",
  ALL_FAIL: "resource/all_fail",
  ALL_RESET: "resource/all_reset",
  ALL_SUCCESS: "resource/all_success",

  CREATE_LINK_STARTED: "resource/create_link_started",
  CREATE_LINK_FAIL: "resource/create_link_fail",
  CREATE_LINK_SUCCESS: "resource/create_link_success",

  DELETE_ZIP_FAIL: "resource/delete_zip_fail",
  DELETE_ZIP_RESET: "resource/delete_zip_reset",
  DELETE_ZIP_STARTED: "resource/delete_zip_started",
  DELETE_ZIP_SUCCESS: "resource/delete_zip_success",

  ONE_STARTED: "resource/one_started",
  ONE_FAIL: "resource/one_fail",
  ONE_RESET: "resource/one_reset",
  ONE_SUCCESS: "resource/one_success",

  POST_STARTED: "resource/post_started",
  POST_FAIL: "resource/post_fail",
  POST_RESET: "resource/post_reset",
  POST_SUCCESS: "resource/post_success",

  PUT_STARTED: "resource/put_started",
  PUT_FAIL: "resource/put_fail",
  PUT_SUCCESS: "resource/put_success",
  PUT_RESET: "resource/put_reset",
};

export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const ELIMINATED = "eliminated";
export const HIDDEN = "hidden";

export const PROCESSING = "processing";
export const GENERATED_MISSING_KEYS = "generatingMissingKeys";
export const GENERATED_MISSING_ADAPT = "generatingMissingAdapt";
export const GENERATED_MISSING_NOBG = "generatingMissingNobg";
export const GENERATING_MISSING_LAYOUT = "generatingMissingLayout";

export const PACKAGING = "packaging";
export const FAILED = "failed";
export const DONE = "done";

export const XLSX_TYPE = "xlsx";
export const ZIP_TYPE = "zip";
