import initialLayerState from "../layer.initialState";
import { reduxActions as LAYER} from "_constants/layer.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const list = {
  [LAYER.LIST_STARTED]: (state) => ({
    ...state,
    list: {
      ...state.list,
      status: LOADING
    }
  }),
  
  [LAYER.LIST_SUCCESS]: (state, { payload }) => ({
    ...state,
    list: {
      ...state.list,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),
  
  [LAYER.LIST_FAIL]: (state, { payload }) => ({
    ...state,
    list: {
      ...state.list,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [LAYER.LIST_RESET]: (state) => ({
    ...state,
    list: initialLayerState.list
  })
};

export default list;
