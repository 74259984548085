import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialProductState from "../product.initialState";


const countByField = {
  [PRODUCT.COUNT_BY_FIELD_STARTED]: (state) => ({
    ...state,
    countByField: {
      ...state.countByField,
      status: LOADING
    }
  }),
  [PRODUCT.COUNT_BY_FIELD_FAIL]: (state, { payload }) => ({
    ...state,
    countByField: {
      ...state.countByField,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [PRODUCT.COUNT_BY_FIELD_SUCCESS]: (state, { payload }) => ({
    ...state,
    countByField: {
      ...state.countByField,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [PRODUCT.COUNT_BY_FIELD_RESET]: (state) => ({
    ...state,
    countByField: initialProductState.countByField
  })
};

export default countByField;
