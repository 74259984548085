import React, { forwardRef, useEffect, useState, useRef } from "react";
import { ImageDots } from "components/molecules";
import PropTypes from "prop-types";
import emptyImg from "_utils/images/emptyImg.png";
import classNames from "classnames";

const prefix = "o-image-slider";

const ImageSlider = ({ src, isHome, index, setIndex, length, ...props }) => {
  const [source, setSource] = useState("");

  const classForComponent = classNames(prefix, {
    "is-home": isHome,
    "no-image": !src || source === emptyImg,
  });

  useEffect(() => {
    setSource(src ? src : emptyImg);
  }, [src]);

  return (
    <div className={classForComponent}>
      <img
        src={source}
        alt="featured_image"
        onError={() => {
          setSource(emptyImg);
        }}
        className={`${prefix}__image`}
        {...props}
      />
        {source !== emptyImg ? (
        <ImageDots
          className={`${prefix}__dots`}
          index={index}
          length={length}
          setIndex={setIndex}
        />
      ) : null}
    </div>
  );
};
ImageSlider.propTypes = {
  src: PropTypes.string,
  index: PropTypes.number,
  length: PropTypes.number,
  setIndex: PropTypes.func,
  isHome: PropTypes.bool,
};

ImageSlider.defaultProps = {
  src: null,
  index: null,
  length: null,
  setIndex: null,
  isHome: null,
};

export default ImageSlider;
