import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { jsonToUrl, urlToJson} from "_utils/url";
import { selectProductFilterList } from "_redux/filters/filters.selector";

const useSort = () => {
  const history = useHistory();
  const location = useLocation();

  const productFilterList = useSelector(selectProductFilterList);
  
  const [sortBy, setSortBy] = useState();
  const [order, setOrder] = useState();

  const changeSort = async (newSortBy, newOrder, applyUrl = false) => {
    let json = await urlToJson(location.search);
    
    if (newOrder === undefined) newOrder = -1;
    
    json.s = {
      sortBy: newSortBy,
      order: newOrder
    }

    if (applyUrl) {
      let encode = await jsonToUrl(json);
      history.push({
        pathname: location.pathname,
        search: encode
      });
    }
    sortOptions();
    
    return json;
  };

  const sortToA = () =>  {
    if (sortBy === undefined) return undefined;
    if (order === undefined) return undefined;
    
    let output = {
      sort: {
        by:{
          [sortBy]: order
        }
      }
    }

    return output;
  };
  
  const sortToUrl = () => {
    if (sortBy === undefined) return undefined;

    return {
      sortBy,
      order: order ?? 1
    };
  };

  const saveSort = async () => {
    let queryString = await urlToJson(location.search);
    let { s } = queryString;

    if (s?.sortBy && s.order !== undefined) {
      if (sortBy !== s.sortBy) {
        setSortBy(s.sortBy);
      }
      if (order !== s.order) {
        setOrder(s.order);
      }
    }

    return s;
  };
  
  const sortOptions = () => {
    if (sortBy) {
      let newList = [...productFilterList];
      let split = sortBy.split('.');
      let collection = split.shift();
      let field = split.join('.');
      for (let i in newList) {
        if (newList[i].collection === collection && newList[i].field === field) {
          newList?.[i].options?.sort((a, b) => {
            if (a.label > b.label) {
              return order === 1 ? 1 : -1;
            } else if (a.label < b.label) {
              return order === 1 ? -1 : 1;
            }

            return 0;
          });
        }
      }
    }
  };

  useEffect(() => saveSort(), [location.search]);

  return {
    changeSort,
    order,
    sortBy,
    sortToA,
    sortToUrl
  };
};

export default useSort;
