import { useDispatch, useSelector } from "react-redux";
import { find, forgot, post, put } from "_redux/user/user.actions";
import { selectUserAllStore, selectUserPost, selectUserSelection } from "_redux/user/user.selectors";
import { ACTIVE, INACTIVE, SUSPENDED, reduxActions as USER } from "_constants/user.constants";

const useUsersPanel = () => {
  const dispatch = useDispatch();

  const selection = useSelector(selectUserSelection);
  const { status: postStatus } = useSelector(selectUserPost);
  const { data: users, status: usersStatus } = useSelector(selectUserAllStore);

  const addUser = (data) => {
    let { email, firstName, lastName, profile } = data;
    
    return dispatch(post({
      c: [{
          account: {
          firstName,
          lastName
        },
        email,
        profile: profile.value.code,
        profileId: profile.value._id,
        password: "Hola123."
      }]
    }, {
      endpoint: "/user/new",
      feedback: {
        error: true,
        success: {
          area: "settings:usersPanel.feedback.add.area",
          info: "settings:usersPanel.feedback.add.info"
        }
      }
    }));
  };

  const enableUser = (id) => dispatch(put(id, {
    status: ACTIVE
  }, {
    feedback: {
      error: true,
      success: true
    }
  }));

  const disableUser = (id) => dispatch(put(id, {
    status: SUSPENDED
  }, {
    feedback: {
      error: true,
      success: true
    }
  }));

  const fetchUsers = (params = null) => {
    if (!params) {
      params = {
        f: ["_id", "email", "profile", "profileId", "status", "account", "$lastLogin"],
        w: {
          status: { $in: [ACTIVE, INACTIVE, SUSPENDED] }
        }
      };
    }

    return dispatch(find(params));
  };

  const resetPassword = (email) => dispatch(forgot({ account: email }, {
    feedback: {
      error: true,
      success: {
        area: "settings:usersPanel.feedback.resetPassword.area",
        info: "settings:usersPanel.feedback.resetPassword.info"
      }
    }
  }));

  const sendWelcome = (ids) => dispatch(post({
    w: Array.isArray(ids) ? ids : [ids]
  }, {
    endpoint: "/user/invite",
    feedback: {
      error: true,
      success: {
        area: "settings:usersPanel.feedback.resetPassword.area",
        info: "settings:usersPanel.feedback.resetPassword.info"
      }
    },
    types: [
      USER.INVITATION_STARTED,
      USER.INVITATION_SUCCESS,
      USER.INVITATION_FAIL
    ]
  }));

  return  {
    addUser,
    disableUser,
    enableUser,
    fetchUsers,
    postStatus,
    resetPassword,
    selection,
    sendWelcome,
    users,
    usersStatus
  };
};

export default useUsersPanel;
