import React, { useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { formatNumber } from "_utils/number";
import { NewStats } from "components/organism/index";

const ItemCounter = ({ counterName, qty, total }) => {
  const prefix = "m-item-counter";
  const [active, setActive] = useState(false);
  const classForComponent = classNames(prefix, { });

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__qtys`}>
        {!isNaN(qty) && (Number.isInteger(qty) && qty !== total) ? (
          <>
            <span className={`${prefix}__qtys-filtered`}>
              {formatNumber(qty)}
            </span>
            <span>/</span>
          </>
        ) : null}

        {typeof total !== "number" ? (
          <div
            className="u-skeleton"
            style={{
              height: "25px",
              width: "60px",
              background: "var(--color-purple)",
            }}
          ></div>
        ) : (
          <>
            <span className={`${prefix}__qtys-total`}>
              {formatNumber(total)}
            </span>
            <span className={`${prefix}__title`}>
              {!!counterName && `${counterName}`}
            </span>
          </>
        )}
      </div>
      {active ? (
        <NewStats
          selected={1796}
          onClose={() => setActive(false)}
          count={{ approved: 540, toReview: 233, rejected: 1023 }}
        />
      ) : null}
    </div>
  );
};

ItemCounter.propTypes = {
  qty: PropTypes.number,
  total: PropTypes.number,
};

export default ItemCounter;
