import { useTranslation } from "react-i18next";
import { Button, NubeskIcons } from "components/atoms";
import { DropDown, FlexComponent, SearchPanel } from "components/templates";
import { useDispatch, useSelector } from "react-redux";
import { useAppSettings, useTask } from "_hooks";
import { selectUser } from "_redux/user/user.selectors";
import { openModal } from "_redux/ui/ui.actions";
import { $$FOLDER } from "_constants/permission.constants";
import { refreshTask } from "_redux/task/task.actions";

const TaskItemsActions = ({
  filteredItems,
  total
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { appColumnKeyProps } = useAppSettings();
  const { selectAll, selected, task, toggleSelectAll, resetSelectionItems, fetchElements } = useTask();

  const user = useSelector(selectUser);

  const openBulkModal = ({ text, typeModal, ...extras }) => {
    let elements = selectAll ? task?.foldersId : selected;
    dispatch(
      openModal({
        typeModal,
        header: {
          text,
          rightItem: `(${
            elements.length - selected.length ||
            selected.length
          }) Items`,
        },
        ...extras,
        modalProps: {
          ...extras?.modalProps ?? {},
          items: elements
        }
      })
    );
  }

  return (
    <>
      <FlexComponent>
        <Button
          type="primary"
          content={selectAll ? t("tasks:actions.deselectAll") : t("tasks:actions.selectAll")}
          onClick={toggleSelectAll}
        />
        <DropDown
          scrollOptions={{ translateContentSizeXToHolder: false }}
          options={[
            {
              onClick: () => {
                dispatch(
                  openModal({
                    typeModal: "upload",
                    header: { text: t("tasks:actions.uploadBulk") },
                    modalProps: {
                      onFinished: () => fetchElements(task._id, task.foldersId)
                    }
                  })
                );
              },
              content: t("tasks:actions.uploadBulk"),
              leftIcon: <NubeskIcons icon="excel" />,
              visible: user.canDoIt([$$FOLDER.CREATE], [$$FOLDER.EDIT])
            }
          ]}
          buttonProps={{
            content: t("tasks:actions.moreActions"),
            type: "primary",
            rightIcon: <NubeskIcons icon="down" />,
          }}
        />
      </FlexComponent>
      <FlexComponent>
        {selectAll || selected?.length ?
          <DropDown
          scrollOptions={{ translateContentSizeXToHolder: false }}
          options={[
            {
              content: t("tasks:actions.downloadXls"),
              leftIcon: <NubeskIcons icon="excel" />,
              onClick: () => openBulkModal({
                typeModal: "downloadXls",
                text: <>
                  <NubeskIcons icon="excel" />{" "}
                  {t("tasks:actions.downloadXls")}
                </>
              })
            }, {
              content: t("tasks:actions.approveImages"),
              leftIcon: <NubeskIcons icon="photos" />,
              onClick: () => openBulkModal({
                typeModal: "approveBulkPhoto",
                text: <>
                  <NubeskIcons icon="photos" />{" "}
                  {t("tasks:actions.approveImages")}
                </>,
                modalProps: {
                  all: selectAll,
                  selected: selected,
                  onFinished: (array) => {
                    for (let p of array) {
                      dispatch(refreshTask(p._id, { process: p.process }, "taskItems"));
                    }
                  }
                }
              })
            }, {
              content: t("tasks:actions.editField"),
              leftIcon: <NubeskIcons icon="edit" />,
              onClick: () => openBulkModal({
                typeModal: "editFieldBulk",
                text: <>
                  <NubeskIcons icon="edit" /> {t("tasks:actions.editField")}
                </>,
                modalProps: {
                  all: selectAll,
                  selected: selected,
                  onFinished: (array) => {
                    for (let p of array) {
                      dispatch(refreshTask(p._id, { data: p.data }, "taskItems"));
                    }
                  }
                }
              })
            }, {
              content: t("tasks:actions.removeFromTask"),
              leftIcon: <NubeskIcons icon="delete" className="u-red"  />,
              onClick: () => openBulkModal({
                typeModal: "removeFromTask",
                text: <>
                  <NubeskIcons icon="delete" className="u-red" /> {t("tasks:actions.removeFromTask")}
                </>,
                modalProps: {
                  taskId: task?._id,
                  onFinished: () => resetSelectionItems()
                }
              })
            }

          ]}
          buttonProps={{
            content: t("tasks:actions.bulkActions", {
              key: appColumnKeyProps?.name, 
              n: (selectAll ? task?.foldersId?.length : selected?.length)
            }),
            type: "primary",
            rightIcon: <NubeskIcons icon="down" />,
          }}
        />
        :
          <SearchPanel
            counterName={`${appColumnKeyProps?.name}'s`}
            disabled={true}
            qty={filteredItems}
            total={total}
            placeholder={t("tasks:actions.searchBy")}
            withDates={false}
          />
        }
      </FlexComponent>
    </>
  )
};

export default TaskItemsActions;
