import PropTypes from "prop-types";
import classNames from "classnames";

const prefix = "m-semaphore";

const Semaphore = ({ current, steps }) => {

  return(
    <div className={prefix}>
      {Array.apply(null, Array(steps))?.map((e, i) => {
        let classForStep = classNames(`${prefix}__step`, {
          "--current": i === (current - 1),
          "--completed": i < (current - 1),
          "--queue": i > (current - 1)
        });
        
        return (
          <div className={classForStep} key={i}></div>
        );
      })}
    </div>
  );
};

Semaphore.propTypes = {
  current: PropTypes.number,
  steps: PropTypes.number
};

Semaphore.defaultProps = {
  current: 0,
  steps: 1
};

export default Semaphore;
