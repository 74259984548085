import ProgressBar from "@ramonak/react-progress-bar";
import classNames from "classnames";

const prefix = "m-task-progress";

const TaskProgress = ({ className, ...props}) => {
  const classForComponent = classNames(prefix, {
    [className]: className
  })

  return (
    <div className={classForComponent}>
      <ProgressBar 
        className={`${prefix}__wrapper`}
        heigth="30px"
        labelAlignment="outside"
        {...props}
      />
    </div>
  )
};

export default TaskProgress;
