import { IDLE } from "_constants/redux.constants";

const initialFiltersState = {
  product: {
    data: null,
    error: null,
    status: IDLE,
    visible: false,
    filters: [],
    filterList: [],
    sortBy: undefined,
    order: 1
  }  
};

export default initialFiltersState;
