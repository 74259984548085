import React from "react";
import PropTypes from "prop-types";
import { FlexComponent } from "components/templates";
import { FilterDateRange, ItemCounter, InputSearch } from "components/molecules";

const SearchPanel = ({
  autocomplete,
  counterName,
  disabled,
  filterCounter,
  qty,
  onChange,
  onToggleFilters,
  onSelectSuggest,
  placeholder,
  total,
  value,
  withDates
}) => {
  return (
    <FlexComponent>
      <ItemCounter 
        qty={qty}
        total={total}
        counterName={counterName}
      />
      <InputSearch 
        autocomplete={autocomplete}
        disabled={disabled}
        filterCounter={filterCounter}
        onChange={onChange}
        onSelectSuggest={onSelectSuggest}
        onToggleFilters={onToggleFilters}
        placeholder={placeholder}
        value={value}
      />
      {withDates && <FilterDateRange /> }
    </FlexComponent>
  );
};

SearchPanel.propTypes = {
  counterName: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  withDates: PropTypes.bool
};

SearchPanel.defaultProps = {
  counterName: "",
  value: "",
  withDates: true
};

export default SearchPanel;
