import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { FlexComponent } from "components/templates";
import SimpleScroller from "components/organism/Scroller/SimpleScroller";
import { UserRow } from "components/molecules";
import { InputLabel, InputTest } from "components/molecules/Inputs";
import { Avatar, Button } from "components/atoms";
import { closeModal } from "_redux/ui/ui.actions";
import { find as fetchUsers } from "_redux/user/user.actions";
import { selectUser, selectUserAllStore } from "_redux/user/user.selectors";

const prefix = "o-share-modal";

const SharedModal = ({ buttons, share, onShare }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data: userList } = useSelector(selectUserAllStore);
  const [availables, setAvailables] = useState([]);
  const [scrollTop, setScrollTop] = useState(0);
  const [selectedUsers, setSelectedUsers] = useState(share);
  const [searchedArray, setSearchedArray] = useState([]);
  
  const user = useSelector(selectUser);

  const onCheckToggle = (id) => {
    for (let user of selectedUsers) {
      if (id === user._id) {
        return setSelectedUsers(selectedUsers.filter((u) => u._id !== id));
      }
    }
    
    let newElement = availables.filter((u) => u._id === id).pop();
    setSelectedUsers((previousState) => [...previousState, ...[newElement]]);
  };

  const handleSearch = ({ target: { value } }) => {
    if (!value.trim().length) return setSearchedArray(availables);
    setSearchedArray(availables.filter(({ email }) => email.startsWith(value)));
  };

  const findIndex = (id) => {
    setSearchedArray(availables);
    const index = availables.findIndex((user) => user.id === id);
    setScrollTop(index > 1 ? index * 60 : 0);
  };

  const fetch = () => dispatch(fetchUsers({
    f: ["_id", "email", "account"]
  }));

  const submit = async () => {
    if (typeof onShare === "function")  {
      await onShare(selectedUsers);
    }
    
    dispatch(closeModal());
  };

  useEffect(() => fetch(), []);

  useEffect(() => {
    if (!Array.isArray(userList)) return;
    let availableList = [];
    let selectedList = [];

    for (let person of userList) {
      if (person._id === user._id) continue;

      for (let shareId of share) {
        if (person._id === shareId) {
          selectedList.push({
            _id: person?._id,
            email: person?.email,
            lastName: person?.account?.lastName,
            firstName: person?.account?.firstName 
          });
          break;
        }
      }

      let object = {
        _id: person?._id,
        email: person?.email,
        lastName: person?.account?.lastName,
        firstName: person?.account?.firstName 
      };
      
      availableList.push(object);
    }

    setSelectedUsers(selectedList);
    setAvailables(availableList);
    setSearchedArray(availableList);
  }, [userList, share]);

  return (
    <div className={prefix}>
        <InputLabel label={t("catalogs:general.share.filterUsers")}>
          <InputTest
            onChange={handleSearch}
            name="search"
            placeholder={t("catalogs:general.share.searchByEmail")}
          />
        </InputLabel>
      <span className={`${prefix}__title`}>{t("catalogs:general.share.selectUsers")}</span>
      <div
        className={`${prefix}__list`}
        style={{
          height: searchedArray.length > 4 ? 63 * 4 : (searchedArray.length || 1) * 65,
        }}
      >
        <SimpleScroller
          noScrollY={searchedArray.length < 4}
          disableTracksWidthCompensation
          scrollTop={scrollTop}
          onScroll={({ scrollTop }) => setScrollTop(scrollTop)}
        >
          <div className={`${prefix}__list-content`}>
            <FlexComponent isVertical>
              {searchedArray.map((row, index) => (
                <UserRow
                  key={index}
                  {...row}
                  active={selectedUsers?.map((r) => r._id)?.indexOf(row._id) !== -1}
                  onClick={() => onCheckToggle(row._id)}
                />
              ))}
            </FlexComponent>
          </div>
        </SimpleScroller>
      </div>
      <span className={`${prefix}__title`}>{t("catalogs:general.share.sharedWith")}</span>
      <div className={`${prefix}__shared`}>
        <SimpleScroller isHorizontal disableTracksWidthCompensation>
          <div className={`${prefix}__shared-content`}>
            {selectedUsers.map((user, index) => {
              if (!availables?.length) return null;
              let row = availables.filter((a) => a._id === user._id).pop();
              if (!row) return null;
              return (
                <Avatar
                  key={index}
                  email={row.email}
                  firstName={row?.firstName ?? " "}
                  lastName={row?.lastName ?? " "}
                  onClick={() => findIndex(index)}
                />
              );
            })}
          </div>
        </SimpleScroller>
      </div>
      <div
        className={`${prefix}__buttons`}
        style={{
          height: 40,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          content={buttons?.cancel || t("common:form.cancel")}
          type="underline"
          sm
          onClick={() => dispatch(closeModal())}
        />
        
        <Button
          content={buttons?.share}
          type="purple"
          sm
          onClick={() => submit()}
        />
      </div>
    </div>
  );
};

export default SharedModal;
