import {
  LIST_DATATYPE,
  LONGTEXT_DATATYPE,
  NUMBER_DATATYPE,
  SHORTTEXT_DATATYPE
} from "_constants/attribute.constants";
import { FormInput, FormSelect, FormTextarea } from "components/molecules/Form";

const FieldSelector = ({
  datatype,
  keyValue,
  value = "",
  name,
  type,
  ...props
}) => {

  const renderSelection = () => {
    switch(datatype) {
      case SHORTTEXT_DATATYPE:
        return <FormInput
          value={value}
          inputprops={props}
          label={name}
          name={keyValue}
          orientation="vertical"
          size="sm"
        />
      case LONGTEXT_DATATYPE:
        return <FormTextarea
          label={name}
          name={keyValue}
          orientation="vertical"
          size="sm"
          rows="4"
          value={value}
          {...props}
        />;
      case NUMBER_DATATYPE:
        return <></>;
      case LIST_DATATYPE:
        return <FormSelect
          name={keyValue}
          label={name}
          orientation="vertical"
          size="sm"
          value={value}
          {...props}
        />;
      default:
        return null;
    }
  };

  return (
    renderSelection()
  )
};

export default FieldSelector;
