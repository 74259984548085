import { Button, NubeskIcons } from "components/atoms/index";
import { BorderInputs, DropDown } from "components/templates/index";
import { Select } from "components/molecules/Inputs";
import React, { useState, useEffect } from "react";
import classNames from "classnames";
import capitalize from "_utils/string/capitalize";
import { InputsApproveButton } from "components/molecules/Inputs/index";

const prefix = "o-copy-dropdown";
/* TODO Si todo sigue funcionando correctamente eliminar este archivo y el InputText. Renombrar InputTest a InputText y utilzar ese para los camposd e texto comunes . Falta del input text los puntos para cuando sea componente con bullets*/
const CopyDropdown = ({
  value,
  options,
  label,
  edit,
  name,
  onChange,
  globalEdit,
  closeEdit,
  openEdit,
  readOnly,
  onApprove,
  onDiscard,
  originalValue,
}) => {
  const [_value, setValue] = useState(value);
  const classForComponent = classNames(prefix, {
    "is-disabled": !(edit || globalEdit) && !readOnly,
    "read-only": readOnly,
  });
  const handleClick = () => {
    onApprove?.();
    closeEdit?.();
  };
  const handleDiscard = () => {
    onDiscard?.();
    closeEdit?.();
  };
  useEffect(() => {
    setValue(value);
  }, [value]);
  useEffect(() => {
    onChange({ target: { value: _value, name } });
  }, [_value]);
  return (
    <div className={classForComponent} onDoubleClick={openEdit}>
      <span className={`${prefix}__label`}>{capitalize(label)}</span>

      <Select
        options={options}
        disabled={!(edit || globalEdit) && !readOnly}
        value={value}
        onChange={(e) => {
          setValue(e);
        }}
      />
      {!globalEdit && edit && value !== originalValue ? (
        <InputsApproveButton
          onClick={{
            approve: () => handleClick(),
            discard: () => handleDiscard(),
          }}
        />
      ) : null}
    </div>
  );
};

export default CopyDropdown;
