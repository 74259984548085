import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Button, NubeskIcons } from "components/atoms";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useProductFilter } from "_hooks";
import { useDispatch } from "react-redux";
import { setHeightTabs } from "_redux/ui/ui.actions";

const prefix = "o-tabs-panel";

const TabsPanel = ({ data, className, setArray }) => {
  const dispatch = useDispatch();
  const  { t } = useTranslation();
  const { removeSpecial, removeValue } = useProductFilter();
  let panelRef = useRef(null);

  const classForComponent = classNames(prefix, {
    [className]: className,
    [`${prefix}__visible`]: (data?.tags?.length || data?.group?.length || data?.specials?.length),
  });

  useEffect(() => {
    if (panelRef.current && panelRef.current !== null) {
      dispatch(setHeightTabs(panelRef.current.clientHeight));
    }
  }, [panelRef.current?.clientHeight]);

  useEffect(()=>{return () => setArray([])},[]);

  return (
    <div className={classForComponent} ref={panelRef}>
      <div className={"o-tabs-panel__tabs"}>
        {data?.tags?.map(({ label, name, value, collection, field }, i) => (
          <Button
            key={i}
            type="tab"
            content={label === "[@empty]" ? `${name} [${t("common:words.empty")}]`: label}
            onClick={() => removeValue(value, collection, field)}
            rightIcon={<NubeskIcons icon="times" />}
          />
        ))}
        {data?.group?.map(({ label, name, value, collection, field }, i) => (
          <Button
            key={i}
            type="tab"
            content={label === "[@empty]" ? `${name} [${t("common:words.empty")}]`: label}
            onClick={() => removeValue(value, collection, field)}
            rightIcon={<NubeskIcons icon="times" />}
          />
        ))}
        {data?.specials?.map(({ label, field }, i) => (
          <Button
            key={i}
            type="tab"
            content={label}
            onClick={() => removeSpecial(field)}
            rightIcon={<NubeskIcons icon="times" />}
          />
        ))}
      </div>
    </div>
  );
};

TabsPanel.propTypes = {
  data: PropTypes.object,
};

TabsPanel.defaultProps = {
  data: {},
};

export default TabsPanel;
