import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BulkActionsCounter } from "components/organism";
import { Button } from "components/atoms";
import { selectAppColumnKey } from "_redux/app/app.selector";
import { closeModal } from "_redux/ui/ui.actions";
import { chunk } from "_utils/array";
import { sleep } from "_utils/async";
import { useAppSettings, useTask } from "_hooks";
import { resetRemoveFrom } from "_redux/task/task.actions";

const prefix = "o-remove-from-task-modal";

const RemoveFromTaskModal = ({ items, taskId, onFinished }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [completed, setCompleted] = useState(0);
  const [total, setTotal] = useState(0);
  const [waiting, setWaiting] = useState(false);
  const [arraySuccess, setArraySuccess] = useState([]);

  const { appColumnKeyProps } = useAppSettings();
  const { removeFromTask } = useTask();

  const close = () => {
      console.log("🚀 ~ file: RemoveFromTaskModal.js ~ line 30 ~ close ~ onFinished", onFinished)
    if (typeof onFinished === "function") {
      onFinished(arraySuccess);
      console.log("finished");
    }
    dispatch(closeModal());
  };

  const remove = async () => {
    let stackSize;
    let aSucces = [];
    let _items = items.map((r) => {
      if (r?._id) return r._id;

      return r;
    });

    if (!taskId) return;

    if (_items.length > 1000){
      stackSize = 100;
    } else if (_items.length > 100){
      stackSize = 20;
    } else {
      stackSize = 1;  
    }
    setTotal(_items.length);
    setWaiting(true);
    
    let arrayChunk = chunk(_items, stackSize);
    
    for (let i = 0; i < arrayChunk.length; i++) {
      let response = await removeFromTask(taskId, arrayChunk[i]);

      aSucces = [...aSucces, ...response?.data];
      setCompleted((v) => v += arrayChunk[i].length)
      if (_items.length <= 50) {
        await sleep(150).then(() => {});
      }
    }

    setArraySuccess(aSucces);
  }

  useEffect(() => {
  
    return () => {
      dispatch(resetRemoveFrom());
    }
  }, [])
  
  return(
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        {!waiting ?
          <p>{t("modals:removeFromTask.question", { key: appColumnKeyProps?.name })}</p>
        :
          <BulkActionsCounter
            completed={completed}
            legends={{
              action: t("modals:removeFromTask.bulkCounter.action"),
              complete: t("modals:removeFromTask.bulkCounter.complete", { key: appColumnKeyProps?.name }),
              finish: t("modals:removeFromTask.bulkCounter.finish"),
              success: t("modals:removeFromTask.bulkCounter.success"),
              total: t("modals:removeFromTask.bulkCounter.total"),
            }}
            onFinish={close}
            withError={0}
            total={total}
          />
        }

      </div>
      {!waiting && 
        <div className={`${prefix}__footer`}>
          <Button
            content={t("common:form.cancel")}
            onClick={close}
            sm
            type="underline"
          />
          <Button
            content={t("common:form.confirm")}
            onClick={remove}
            sm
            type="purple"
          />
        </div>
      }
    </div>
  );
};

export default RemoveFromTaskModal;
