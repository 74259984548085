import { Button } from "components/atoms/index";
import React from "react";

const Tabs = ({ panels }) => {
  return (
    <div>
      {/*  {panels.map(({ content, onClick }) => (
        <Button content={}/>
      ))} */}
    </div>
  );
};

export default Tabs;
