import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const analizeId = {
  [PRODUCT.ANALYZE_ID_STARTED]: (state) => ({
    ...state,
    analizeId: {
      ...state.analizeId,
      status: LOADING,
    },
  }),

  [PRODUCT.ANALYZE_ID_FAIL]: (state, { payload }) => ({
    ...state,
    analizeId: {
      ...state.analizeId,
      error: payload?.error ?? null,
      status: FAILURE,
    },
  }),

  [PRODUCT.ANALYZE_ID_SUCCESS]: (state, { payload }) => ({
    ...state,
    analizeId: {
      ...state.analizeId,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS,
    },
  }),

  [PRODUCT.ANALYZE_ID_RESET]: (state) => ({
    ...state,
    analizeId: initialProductState.analizeId,
  })
};

export default analizeId;
