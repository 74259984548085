import { Button } from "components/atoms/index";
import React from "react";

const prefix = "o-error-upload";

const ErrorUpload = () => {
  return (
    <div className={prefix}>
      El archivo ingresado no tiene el formato adecuado.
      <Button content="Download template" type="underline" />
    </div>
  );
};

export default ErrorUpload;
