const nestedPropOnly = (object, property) => {
  if (!property) return "";
  let output = object;
  let props = property.split(".");
  if (!output?.[props[0]]?.[props[1]]) return false;
  for (let prop of props) {
    if (!output?.[prop]) break;
    output = output[prop];
  }
  return output;
};

const nestedProps = (object, properties) => {
  if (typeof object !== "object") return undefined;
  if (properties === false) return undefined;

  let output = undefined;

  if (Array.isArray(properties)) {
    output = properties.map((prop) => nestedPropOnly(object, prop));
  } else {
    output = nestedPropOnly(object, properties);
  }

  return output;
};

export default nestedProps;
