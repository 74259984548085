import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useDropzone } from "react-dropzone";
import { NubeskIcons } from "components/atoms/index";

const prefix = "o-upload-drop";

const Uploaddrop = ({ file, setFile, directory, dropProps, isFilter, label }) => {
  const { t } = useTranslation();
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject
  } = useDropzone({
    ...dropProps,
  });

  const classForComponent = classNames(prefix, {
    "is-drag-active": isDragActive,
    "is-drag-accept": isDragAccept,
    "is-drag-reject": isDragReject,
  });
  let timer = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);
  
  useEffect(() => {
    if (fileRejections.length) {
      return setFile(fileRejections);
    }

    if (acceptedFiles.length) {
      return setFile(acceptedFiles);
    }
  }, [acceptedFiles, fileRejections]);

  useEffect(() => {
    if (file[0]?.errors) {
      timer = setTimeout(() => {
        setFile([]);
      }, 2000);
    }
  }, [file]);

  return (
    <>
      <div {...getRootProps({ className: classForComponent })}>
        {directory ?
          <input 
            {...getInputProps()}
            webkitdirectory="false"
            directory="false"
          />
        :
          <input {...getInputProps()} />
        }

        {!isFilter &&  <NubeskIcons icon="upload" size={80} className={`${prefix}__button`} />}
        <span className={`${prefix}__text`}>{t(label)}</span>
      </div>
      {file?.[0]?.errors ? (
        <span className={`${prefix}__error`}>
          {file[0]?.file?.path} {t("common:form.invalid_file")}
        </span>
      ) : null}
    </>
  );
};

Uploaddrop.propTypes = {
  directory: PropTypes.bool,
  label: PropTypes.string
};

Uploaddrop.defaultProps = {
  directory: false,
  label: "modals:uploadBulk.hintFolder"
};


export default Uploaddrop;
