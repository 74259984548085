import { LEFT_PANEL } from "_constants/reducers.constants";
import close from "./close";
import open from "./open";

const leftPanel =  {
  [LEFT_PANEL.CLOSE]: close,
  [LEFT_PANEL.OPEN]: open
};

export default leftPanel;
