import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const Title = ({ 
  bold, 
  className,
  content, 
  secondary, 
  black, 
  medium, 
  fontSize, 
  small, 
  large, 
  xlarge,
  xmedium
}) => {
  const classForComponent = classNames("a-title", {
    "is-bold": bold,
    "is-secondary": secondary,
    "is-black": black,
    "is-medium": medium,
    "is-xmedium": xmedium,
    "is-small": small,
    "is-large": large,
    "is-xlarge": xlarge,
    [className]: className
  });
  return <h2 style={{fontSize: `${fontSize}px`}} className={classForComponent}>{content}</h2>;
};

export default Title;

const validationProp = (props, propName, component, compare) => {
  if (props.secondary && typeof props.secondary !== 'boolean' ) {
    return new Error(`Invalid prop ${propName} expected boolean`);
  }

  if (props[propName] && props[compare]) {
    return new Error(`Only one of secondary or black in '${component}'`);
  }
}

Title.propTypes = {
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  secondary: (props, propName, component) => validationProp(props,propName,component, "black"  ),
  black: PropTypes.bool,
  fontSize: PropTypes.number,
};
