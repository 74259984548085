const prefix = "o-faq-modal";

const FaqModal = ({ url }) => {

  return (
    <div className={prefix}>
      <p className={`${prefix}__title`}>Toma el tour de bienvenida</p>
      <div style={{position: "relative", paddingBottom: "56.25%", height: "0"}}>
        <iframe 
          src={url}
          frameBorder="0"
          webkitallowfullscreen="true" 
          mozallowfullscreen="true" 
          allowFullScreen={true} 
          style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%" }}
          title=""
        ></iframe>
      </div>
    </div>
  )
};

export default FaqModal;
