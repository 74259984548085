import React, { Fragment, useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Button } from "components/atoms";
import FlexComponent from "../FlexComponent/FlexComponent";
import SimpleScroller from "components/organism/Scroller/SimpleScroller";
import { useRef } from "react";
import { useEffect } from "react";

const prefix = "t-dropdown";

const DropDown = ({ 
  buttonProps,  
  className, 
  copy, 
  disabled, 
  divisors,
  dropComponent,
  maxVisible,
  options, 
  scrollOptions,
  sm,
  xs
}) => {
  const [active, setActive] = useState(false);
  const [inBorder, setInBorder] = useState(false);
  const [position, setPosition] = useState({});
  const [itemsLength, setItemsLength] = useState(0);
  const btnRef = useRef(null);

  const classForComponent = classNames(prefix, {
    "is-active": active,
    [`${prefix}--button`]: options,
    [`${prefix}--header`]: dropComponent,
    "is-copy": copy,
    "is-xs": xs,
    "is-sm": sm,
    [className]: className,
  });

  useLayoutEffect(() => {
    if (
      window.innerHeight - position.bottom <
      options?.length * (copy ? 40 : 48) + 10
    ) return setInBorder(true); 
      
    setInBorder(false);
  }, [position]);

  useEffect(() => {
    let items = options?.filter((r) => r.visible !== false)?.length;
    if (maxVisible && maxVisible < items) {
        setItemsLength(maxVisible);
    } else {
      setItemsLength(options?.filter((r) => r.visible !== false)?.length ?? 0);
    }
    
  }, [options]);

  return (
    <div className={classForComponent}>
      <div
        className={`${prefix}__content`}
        ref={btnRef}
      >
        <Button
          content=""
          disabled={disabled || !options.length || !options?.filter((o) => o?.visible !== false).length}
          {...buttonProps}
          onClick={({ currentTarget }) => {
            setPosition(currentTarget.getBoundingClientRect());
            setActive(!active);
          }}
          xs={xs}
          sm={sm}
        />
        <div
          className={`${prefix}__content-dropdown`}
          onMouseLeave={!copy ? () => setActive(false) : null}
          style={{
            height: `${itemsLength * (copy ? 40 : 47) || 48}px`,
            minHeight: `${itemsLength * (copy ? 40 : 47) || 48}px`,
            "--top": `${inBorder ? position.top - 160 : position.bottom}px`,
            "--right": `${window?.innerWidth - position.right}px`,
            "--min-width": `${inBorder ? position.width : (
              btnRef?.current?.offsetWidth < "250" ? "250" : btnRef?.current?.offsetWidth
            )}px`,
          }}
        >
          <SimpleScroller {...scrollOptions}>
            {options ? (
              <FlexComponent isVertical sm>
                {options?.map(({ content, labelInfo, onClick, visible, ...props }, index) => {                  
                  if (visible === false) return null;
                  
                  return (
                    <Fragment key={index}>
                      {divisors?.indexOf(index) !== -1 && <hr></hr>}
                      <Button
                        content={content}
                        type={"dropdown"}
                        rightIcon={labelInfo || null}
                        sm={!!sm}
                        {...props}
                        onClick={() => {
                          onClick();
                          setActive(false);
                        }}
                      />
                    </Fragment>
                  );
                })}
              </FlexComponent>
            ) : (
              dropComponent
            )}
          </SimpleScroller>
        </div>
      </div>
      <div className={`${prefix}__mask`} onClick={() => setActive(false)}></div>
    </div>
  );
};

DropDown.propTypes = {
  buttonProps: PropTypes.object,
  divisors: PropTypes.array,
  dropdown: PropTypes.node,
  maxVisible: PropTypes.number,
  options: PropTypes.array,
  sm: PropTypes.bool,
  xs: PropTypes.bool
};

DropDown.defaultProps = {
  divisors: [],
  maxVisible: 5,
  sm: false,
  xs: false
};

export default DropDown;
