import initialBillingState from "../billing.initialState";
import { reduxActions as BILLING } from "_constants/billing.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const accounts = {
  [BILLING.ACCOUNTS_STARTED]: (state) => ({
    ...state,
    accounts: {
      ...state.accounts,
      status: LOADING
    }
  }),

  [BILLING.ACCOUNTS_SUCCESS]: (state, { payload }) => ({
    ...state,
    accounts: {
      ...state.accounts,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),

  [BILLING.ACCOUNTS_FAIL]: (state, { payload }) => ({
    ...state,
    accounts: {
      ...state.accounts,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [BILLING.ACCOUNTS_RESET]: (state) => ({
    ...state,
    accounts: initialBillingState.accounts
  })
};

export default accounts;
