import initialLayoutState from "./layout.initialState";
import all from "./reducers/all";
import one from "./reducers/one";
import post from "./reducers/post";

const reducer = (state = initialLayoutState, action) => {
  const reducers = {
    ...all,
    ...one,
    ...post
  };
  
  let { type } = action;
  if (reducers[type] === undefined) return state;

  return reducers[type](state, action);
};

export default reducer;
