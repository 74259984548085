const initialState = {
  details: {
    currentAdaptation: null,
    currentImage: false,
    page: null,
    images: [],
  },
  filter: {
    isOpen: false,
    height: 0,
  },
  leftPanel: {
    isOpen: false,
    typePanel: "",
    panelProps: {},
  },
  loader: {
    typeLoader: undefined,
    isOpen: false,
    loaderProps: undefined,
  },
  manageImages: {
    isOpen: false,
    images: [],
    channelName: "",
    selectedImage: {},
    drag: {
      itemId: "",
    },
    toolbar: {},
    idsSorted: [],
    initialSort: [],
    selection: [],
  },
  modal: {
    typeModal: "",
    isOpen: false,
    modalProps: {},
    header: {
      icon: "",
      text: "",
      leftItem: "",
      rightItem: "",
    },
  },
  refresh: {
    products: false,
    productsData: null
  },
  selections: {
    all: false,
    selected: {},
  },
  shortcuts: {},
  smartEditor: {
    visible: false,
    data: null,
  },
  toolbar: { previous: "", current: "" },
  tooltip: {
    tip: "",
    active: false,
    top: 0,
    left: 0,
  }
};

export default initialState;
