import { createSelector } from 'reselect';

const selectFiltersStore = (store) => store.filters;

export const selectProductFiltersStore = createSelector(
  selectFiltersStore,
  (filters) => filters.product
);

export const selectProductFilter = createSelector(
  selectProductFiltersStore,
  (product) => product.data
);

export const selectProductFilterError = createSelector(
  selectProductFiltersStore,
  (product) => product.Error
);

export const selectProductFilterStatus = createSelector(
  selectProductFiltersStore,
  (product) => product.status
);

export const selectProductFiltersVisible = createSelector(
  selectProductFiltersStore,
  (product) => product.visible
);

export const selectProductFilters = createSelector(
  selectProductFiltersStore,
  (product) => product.filters
);

export const selectProductFilterList = createSelector(
  selectProductFiltersStore,
  (product) => product.filterList
);

export default selectFiltersStore;
