import { NubeskIcons } from "components/atoms/index";
import { FlexComponent } from "components/templates/index";
import classNames from "classnames";

const prefix =  "o-metrics";

const Metrics = ({ content, icon, total, used}) => {
  const classForUsed = classNames(`${prefix}__used`, {
    "u-red": total && (used / total) >= 0.9,
    "u-yellow": total && ((used / total) >= 0.7 && (used / total) < 0.9),
    "u-green": !total || (total && (used / total) < 0.7),
  });

  const classForTotal = classNames(`${prefix}__total`, "u-dark-gray", {
    infinite: !total
  });

  return (
    <div className={prefix}>
      <FlexComponent >
        {icon && <div><NubeskIcons icon={icon} /></div>}
        {content}
      </FlexComponent>
      <FlexComponent>
        <div className={`${prefix}__values`}>
          <div>
            {used !== undefined && <span className={classForUsed} >{used}</span>}
            
            {used !== undefined && <>/</>}
            
            <span className={classForTotal}>
              {total || <NubeskIcons icon="infinite" />}
            </span>
          </div>
        </div>
      </FlexComponent>
    </div>
  )
};

export default Metrics;
