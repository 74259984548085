import { useDispatch, useSelector } from "react-redux";
import { find } from "_redux/profile/profile.actions";
import { selectProfileAll } from "_redux/profile/profile.selector";

const useProfile = () => {
  const dispatch = useDispatch();
  const { data: profileList } = useSelector(selectProfileAll);
  
  const fetchProfiles = (params) => dispatch(find({
    ...params,
    w: {
      ...params?.w ?? {},
      code: {
        ...params?.w?.code ?? {},
        $exists: 1
      }
    }
  }));


  return {
    fetchProfiles,
    profileList
  };
};

export default useProfile;
