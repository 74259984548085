import initialAnalysisState from "../analysis.initialState";
import { reduxActions as ANALYSIS } from "_constants/analysis.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const approvedBulk = {
  [ANALYSIS.APPROVED_BULK_STARTED]: (state) => ({
    ...state,
    approvedBulk: {
      ...state.approvedBulk,
      status: LOADING
    }
  }),
  
  [ANALYSIS.APPROVED_BULK_FAIL]: (state, { payload }) => ({
    ...state,
    approvedBulk: {
      ...state.approvedBulk,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  
  [ANALYSIS.APPROVED_BULK_SUCCESS]: (state, { payload }) => ({
    ...state,
    approvedBulk: {
      ...state.approvedBulk,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [ANALYSIS.APPROVED_BULK_RESET]: (state) => ({
    ...state,
    approvedBulk: initialAnalysisState.approvedBulk
  }),
};

export default approvedBulk;
