import { reduxActions as USER } from '_constants/user.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialUserState from '../user.initialState';

const invitation = {
  [USER.INVITATION_STARTED]: (state) => ({
    ...state,
    invitation: {
      ...state.invitation,
      status: LOADING
    }
  }),
    
  [USER.INVITATION_FAIL]: (state, { payload }) => ({
    ...state,
    invitation: {
      ...state.invitation,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [USER.INVITATION_SUCCESS]: (state, { payload }) => ({
    ...state,
    invitation: {
      ...state.invitation,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  
  [USER.INVITATION_RESET]: (state, { payload }) => ({
    ...state,
    invitation: initialUserState.invitation
  })
};

export default invitation;
