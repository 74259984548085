import { reduxActions as ADAPTATION } from "_constants/adaptation.constants";

const wmCanvasEditor = {
  [ADAPTATION.WM_CANVAS_EDITOR_DATA]: (state, { payload }) => ({
    ...state,
    wmCanvasEditor: {
      ...state.wmCanvasEditor,
      data: payload
    }
  }),

  [ADAPTATION.WM_CANVAS_EDITOR_TOGGLE]: (state, { payload }) => ({
    ...state,
    wmCanvasEditor: {
      ...state.wmCanvasEditor,
      visible: payload
    }
  })
};


export default wmCanvasEditor;
