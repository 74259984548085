import React, { useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ColumnsTemplate } from "components/templates";
import { BottomMenu, Gallery, SmartEditor } from "components/organism";
import { Magnifier } from "components/atoms";
import { useLayer, useProduct, useProductQuery } from "_hooks";
import { 
  setCurrentImage, 
  setImagesGallery, 
  toggleSmartEditor, 
  setImages as reduxImages,
  toggleFilter
} from "_redux/ui/ui.actions";
import {
  count as countProducts,
  resetAllPropsDetails
} from "_redux/product/product.actions";
import {
  remove,
  resetAllProps as resetAllPropsFile,
  saveEdition,
  swatch
} from "_redux/file/file.actions";
import {
  selectProductCountPiece,
  selectProductDetails,
  selectProductPutStatus
} from "_redux/product/product.selector";
import { findOne as fetchCatalog } from "_redux/catalog/catalog.actions";
import { findOne as findTask } from "_redux/task/task.actions";
import { selectAppComponentDrawing } from "_redux/app/app.selector";
import {
  selectDetails,
  selectFilter,
  selectInitialSort,
  selectManageImagesisOpen,
  selectRefresh,
  selectUiSmartEditorStore
} from "_redux/ui/ui.selector";
import {
  selectFilePostStatus,
  selectFilePutStatus,
  selectFileRemoveBgStore
} from "_redux/file/file.selector";
import { selectTaskOne } from "_redux/task/task.selector";
import { LOADING, SUCCESS } from "_constants/redux.constants";
import ManageImages from "components/_features/ManageImages/ManageImages";
import urlToJson from "_utils/url/urlToJson";
import { ECOMM_NOBG, MASTERS_ECOMM } from "_constants/adaptation.constants";

const prefix = "t-details";

const DetailsTemplate = ({ children }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const { key } = useParams();
  const [magnifierProps, setMagnifierProps] = useState({
    isOpen: false,
    zoom: 2
  });

  const [imagesForGallery, setImagesForGallery] = useState({});

  const currentFilter = useSelector(selectFilter);
  const cDrawing = useSelector(selectAppComponentDrawing);
  const productPutStatus = useSelector(selectProductPutStatus);
  const filePostStatus = useSelector(selectFilePostStatus);
  const filePutStatus = useSelector(selectFilePutStatus);
  const { status: fileRemoveBgStatus } = useSelector(selectFileRemoveBgStore);
  const { currentAdaptation, currentImage } = useSelector(selectDetails);
  const _arrayIds = useSelector(selectInitialSort);
  const { visible: seVisible, data: seData } = useSelector(
    selectUiSmartEditorStore
  );
  const { details: refreshDetails } = useSelector(selectRefresh);
  const isOpenManageImages = useSelector(selectManageImagesisOpen);
  const countPiece = useSelector(selectProductCountPiece);
  const { data: task } = useSelector(selectTaskOne);

  const { fetchProductDetails, getRail, reset } = useProduct();
  const { getFromQuery } = useProductQuery();
  const { generateStatus: generateLayerStatus } = useLayer();

  const [current, setCurrent] = useState();
  const [total, setTotal] = useState({});
  const [isNoBg, setIsNoBg] = useState(false);
  const [stackBg, setStackBg] = useState([]);

  const product = useSelector(selectProductDetails);

  const fetch = () => {
    if (cDrawing === null || !key) return;

    return fetchProductDetails(key);
  };

  const fetchCount = async () => {
    if (!key) return;
    let { search } = location;
    let queryString = await urlToJson(search);
    let { a, w } = await getFromQuery();

    let conditions = {
      a: {
        sort: a?.sort ?? {
          by: { createdAt: -1 }
        }
      },
      f: ["_id", "key"],
      w
    };

    if (queryString?.from?.catalogId) {
      let { catalogId } = queryString?.from;
      let response = await dispatch(fetchCatalog(catalogId, "_id", {
        f: ["foldersId"],
        a: {
          extended: {
            f: { foldersId: ["key"] }
          },
          ...conditions?.a
        },
        w: conditions?.w ?? {}
      }));
      let data = response?.data?.[0]?.foldersId ?? [];
      conditions.w = {
        ...conditions.w ?? {},
        _id: {
          $in: data
        }
      };
    } else {
      let { from } = await urlToJson(location.search, true);
      if (from?.taskKey) {
        let { data } = await dispatch(findTask({
          f: ["key", "foldersId"],
          w: { key : from.taskKey}
        }));
        
        conditions = {
          ...conditions ?? {},
          w: {
            ...conditions?.w ?? {},
            _id: { $in: data?.[0]?.foldersId }
          }
        }
      }
    }
    
    if (countPiece === null) {
      dispatch(countProducts({ query: conditions.w ?? {}, partial: true }));
    }
    
    return await getBlock({
      a: {
        page: a?.page,
        sort: conditions.a.sort
      },
      f: conditions.f,
      w: conditions.w
    })
  };
    

  const checkIsNoBg = () => {
    if (product?.adaptationMastersId && currentAdaptation) {
      for (let a of product.adaptationMastersId) {
        if (
          a.attributes.withBackground === false &&
          a._id === currentAdaptation
        ) {
          return setIsNoBg(true);
        }
      }
    }

    return setIsNoBg(false);
  };

  const getBlock = async (object) => {
    if (!object) {
      let { w } = await getFromQuery();
      object = {
        ...object ?? {},
        w: w
      };
    }

    let conf = {
      a: {
        page: {
          ...object?.a?.page ?? {
            step: 1
          },
          skip: 500
        },
        sort: object.a.sort
      },
      f: ["_id", "key"],
      w: object.w
    };
    let { data } = await getRail(conf);
    let a = {...total};
    let { skip, step } = conf.a.page;

    for (let i in data) {
      a[parseInt(i) + (step - 1) * skip] = data[i].key;
    }
    await setTotal(a);
    return;
  };

  const navSku = async(direction) => {
    if (total === undefined || !total[current + direction]) return;
    if (current === 0 && direction === -1) return;
    if (current === countPiece - 1 && direction === 1) return;
    
    let path = location.pathname.split("/");
    path[2] = total[current + direction];

    let pathname = path.join("/");

    history.push({
      pathname,
      search: location.search
    });
  };

  const onSaveEdition = async (data) => {
    let masterId;
    let noBg;
    let save = await dispatch(saveEdition(_arrayIds[currentImage], data));
    
    if (!save?.data?.[0]) return;
    
    product?.adaptationMastersId.forEach((a) => {
      if (a.key === MASTERS_ECOMM) masterId = a._id;
      if (a.key === ECOMM_NOBG) noBg = a._id;
    });

    let ids = product?.filesId.filter(
        (f) => (![masterId, noBg].includes(f.adaptationId))
      ).map((f) => f._id);
  
    await dispatch(
      remove(ids, {
        feedback: {
          success: {
            area: "Edition",
            info: "Success",
          },
          error: true,
        },
      })
    );
    fetch();
  }; 

  const getImagesForGallery = () => {
    let count = { landscape: 0, portrait: 0, square: 0 };
    let newImages = [...product?.filesId ?? []].filter(
      (i) => (i.adaptationId === currentAdaptation)
    );

    if (!newImages?.length) {
      newImages = [...product?.layersId ?? []].filter(
        (i) => (i.adaptationLayerId === currentAdaptation)
      );
    }
    
    newImages = newImages?.map(
      ({
        baseKeyS3Carousel,
        baseKeyS3Thumbnail,
        baseKeyS3Original,
        data,
        index,
        naming,
        _id,
      }) => {
        count = { ...count, [data?.orientation]: count[data?.orientation] + 1 };
        return {
          index,
          id: _id,
          naming,
          carousel: baseKeyS3Carousel,
          thumbnail: baseKeyS3Thumbnail,
          orientation: data?.orientation,
          original: baseKeyS3Original,
        };
      }
    ) ?? [];

    let higher = Object.keys(count).reduce((a, b) =>
      count[a] > count[b] ? a : b
    );
    
    newImages.sort((a, b) => a.index - b.index);
    dispatch(reduxImages(newImages));
    setImagesForGallery({ images: newImages, orientation: higher });
  };

  const requestSwatch = (data) => {
    dispatch(swatch({
    ...data,
    folderId: product?._id
  }))};

  useEffect(()=> {
    if (Object.keys(total).length) {
      let index = -1;
      for (let i in total) {
        if (total[i] === key) {
          index = parseInt(i);
          break;
        }
      }
      setCurrent(index);
    }
  }, [total, key]);

  useEffect(() => {
    const railInspector = () => {
      if (!current || current < 0) return;
      if (current + 1 < countPiece && !total[current + 1]) {
        let step = Math.ceil((current + 2) / 100);
        getBlock({
          a: { page: { skip: 100, step } }
        });
      }

      if (current - 1 > 0 && total[current - 1] === undefined) {
        let step = Math.ceil((current + (-1) + 1) / 100);
        getBlock({
          a: { page: { skip: 100, step } }
        });
      }
    }

    railInspector();
  }, [current])
  
  useEffect(() => {
    fetchCount();
    if (currentFilter?.isOpen) {
      dispatch(toggleFilter());
    }
    return () => {
      dispatch(resetAllPropsFile());
      dispatch(resetAllPropsDetails());
      
      urlToJson(location.search, true).then(({ from }) => {
        if (from?.taskKey) {
          reset();
          dispatch(reduxImages());
          setImagesForGallery({ images: [] });
        }
      });
    };
  }, []);

  useEffect(() => {
    if (!key) return;

    fetch();
  }, [key]);

  useEffect(() => {
    if (!key || productPutStatus !== SUCCESS) return;
    fetch();
  }, [productPutStatus]);

  useEffect(() => {
    if (!key || filePostStatus !== SUCCESS) return;
    fetch();
  }, [filePostStatus]);

  useEffect(() => {
    if (!key || filePutStatus !== SUCCESS) return;
    fetch();
  }, [filePutStatus]);

  useEffect(() => {
    if (!key || generateLayerStatus !== SUCCESS) return;
    fetch();
  }, [generateLayerStatus]);

  useEffect(() => {
    if (fileRemoveBgStatus === LOADING) {
      setStackBg(stackBg.concat([key]));
      return;
    }

    if (fileRemoveBgStatus === SUCCESS && stackBg[stackBg.length - 1] === key) {
      fetch();
    }
  }, [fileRemoveBgStatus]);

  useEffect(() => {
    if (total === undefined) return;
    if (!product) return;
    if (currentAdaptation === null) return;

    checkIsNoBg();
  }, [product?.filesId, total, currentAdaptation]);
  
  useEffect(() => {
    if (currentAdaptation === undefined || (!product?.filesId && !product?.layersId )) return;

    let images =[...product?.filesId ?? [], ...product?.layersId ?? []]
      ?.filter((f) => 
        f.adaptationId === currentAdaptation ||
        (f?.adaptationLayerId && f.adaptationLayerId === currentAdaptation)
      );
    dispatch(setImagesGallery(images));
  }, [product?.filesId, product?.layersId, currentAdaptation]);

  useEffect(async () => {
    if (product?.filesId || product?.layersId) getImagesForGallery();
  }, [product?.filesId, product?.layersId, currentAdaptation]);

  useEffect(async () => {
    if (refreshDetails) getImagesForGallery();
   }, [refreshDetails]);
  
  return (
    <div className={prefix}>
      <>
        {seVisible && seData && (
          <SmartEditor
            data={seData}
            onSave={onSaveEdition}
            onClose={() => dispatch(toggleSmartEditor())}
          />
        )}
        {isOpenManageImages && (
          <ManageImages
            images={imagesForGallery ?? {}}
            channel={"master-ecomm"}
          />
        )}
        <ColumnsTemplate
          leftWidth="48%"
          leftComponent={
            <Gallery
              productKey={product?.key}
              setMagnifierProps={setMagnifierProps}
              magnifierProps={magnifierProps}
              isNoBg={isNoBg}
              images={imagesForGallery ?? []}
              onChangeIndex={(index) => dispatch(setCurrentImage(index))}
              onRequestSwatch={requestSwatch}
            />
          }
          rightComponent={
            <div className={`${prefix}__children`}>
              {magnifierProps.isOpen ? (
                <Magnifier
                  setMagnifierProps={setMagnifierProps}
                  magnifierProps={magnifierProps}
                />
              ) : null}
              {children}
            </div>
          }
        />
        <BottomMenu
          onClickNav={navSku}
          current={current !== undefined ? current + 1 : undefined}
          total={countPiece}
          task={task}
          itemData={product?.tasksId?.[0]}
          productId={product?._id}
        />
      </>
    </div>
  );
};

export default DetailsTemplate;
