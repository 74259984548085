import leftPanel from "./leftPanel";
import skuCard from "./skuCard";
import taskItems from "./taskItems";

const nbskReducer = (type, payload) =>  {
  'use strict';
  const reducers = {
    ...leftPanel,
    ...skuCard,
    ...taskItems
  };

  if (typeof reducers[type] === "undefined") {
    throw Error("Reducer is not defined");
  }

  let reducer = reducers[type];

  return reducer(payload);
};

export default nbskReducer;
