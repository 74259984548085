import { useTranslation } from "react-i18next";
import { FlexTable } from "components/templates";
import { Sorter } from "components/molecules";
import { Badge, Button, FlexCell, NubeskIcons } from "components/atoms";
import { FAILURE, SUCCESS } from "_constants/redux.constants";
import { alphabetColumn } from "_utils/array";
import { useAttribute, useChannelLayout, useModal } from "_hooks";

const LayoutAttributes = ({ prefix, attributes }) => {
  const { moveIt } = useAttribute();
  const { layoutData } = useChannelLayout();
  const { openModal } = useModal();
  const { t } = useTranslation();

  return (
    <FlexTable
      className={`${prefix}__table`}
      bodyClass={`${prefix}__table-body`}
      headerCol={9}
      rowCol={9}
      showEmpty={[SUCCESS, FAILURE].indexOf([]) !== -1}
      gridTemplateColumns="60px minmax(100px,150px) minmax(100px, 1fr) minmax(130px, 190px) minmax(130px, 190px) minmax(130px, 190px) minmax(150px, 210px) minmax(110px, 170px)"
      emptyData={t("settings:layoutAttributes.table.emptyData")}
      headers={<>
        <FlexCell>{t("settings:layoutAttributes.table.column")}</FlexCell>
        <FlexCell>{t("settings:layoutAttributes.table.id")}</FlexCell>
        <FlexCell>{t("settings:layoutAttributes.table.name")}</FlexCell>
        <FlexCell justify="center">{t("settings:layoutAttributes.table.type")}</FlexCell>
        <FlexCell justify="center">{t("settings:layoutAttributes.table.required")}</FlexCell>
        <FlexCell justify="center">{t("settings:layoutAttributes.table.dataType")}</FlexCell>
        <FlexCell justify="center">{t("settings:layoutAttributes.table.editable")}</FlexCell>
        <FlexCell justify="center"><NubeskIcons icon="settings" size="20px"/></FlexCell>
      </>}
      rows={Array.isArray(attributes) && attributes.map((a, i) => (
        <>
          <FlexCell className="u-purple" align="center">
            {alphabetColumn(i + 1)}
            <Sorter 
              disabledDown={attributes.length === (i + 1)}
              disabledUp={i === 0}
              onClickDown={() => 
                moveIt(a, attributes?.filter((r) => r.index === a.index + 1).pop(), layoutData.key)
              }
              onClickUp={() => 
                moveIt(a, attributes?.filter((r) => r.index === a.index - 1).pop(), layoutData.key)
              }
            />
          </FlexCell>
          <FlexCell>
            <Badge content={a.key} border="purple"/>
          </FlexCell>
          <FlexCell>{a.name}</FlexCell>
          <FlexCell className="u-dark-gray" justify="center">{a?.type ? t(`settings:layoutAttributes.type.${a.type}`) : ""}</FlexCell>
          <FlexCell className="u-dark-gray" justify="center">{a?.flag?.require ? "Sí" : "No"}</FlexCell>
          <FlexCell className="u-dark-gray" justify="center">{a?.class?.datatype ? t(`settings:layoutAttributes.dataType.${a.class.datatype}`): ""}</FlexCell>
          <FlexCell className="u-dark-gray" justify="center">
            {`
            ${a?.flag?.editable ? t("common:words:yes") : t("common:words:no")} | 
            ${a?.flag?.overwritable ? t("common:words:yes") : t("common:words:no")}
            `}
          </FlexCell>
          <FlexCell className="u-purple" justify="center">
            {!a?.flag?.key && 
              <Button
                content="Configurar"
                leftIcon={<NubeskIcons icon="settings" />}
                onClick={() => openModal({
                  header: {
                    icon: {
                      size: "22px",
                      type: "settings"
                    },
                    text: t("settings:layoutAttributes.modal.title", { name: layoutData?.name})
                  },
                  modalProps: {
                    attributeId: a._id,
                    layoutKey: layoutData?.key,
                    layoutName: layoutData?.name,
                    colLetter: alphabetColumn(i + 1)
                  },
                  typeModal: "attributeForm"
                })}
                type="underline"
                xs
              />
            }
          </FlexCell>
        </>
        )
      )}
    />
  );
};

export default LayoutAttributes;
