import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { CustomProgressBar, MultipleProgress } from "components/molecules";
import { useProductQuery } from "_hooks";
import { Button, Paragraph } from "components/atoms";
import { BulkActionsCounter } from "components/organism";
import { closeModal } from "_redux/ui/ui.actions";
import { analyzeRemoveBg, analyzeRemoveBgReset } from "_redux/analysis/analysis.action";
import { removeBg } from "_redux/file/file.actions";
import { fetchAllList } from "_redux/product/product.actions";
import { selectAnalysisRemoveBgBulk } from "_redux/analysis/analysis.selector";
import { selectSkuSelected } from "_redux/ui/ui.selector";
import { selectProductCountPiece } from "_redux/product/product.selector";
import { selectAppColumnKey } from "_redux/app/app.selector";
import sleep from "_utils/async/sleep";
import { LOADING, SUCCESS } from "_constants/redux.constants";

const prefix = "o-remove-bg-bulk-modal";
const colors = {
  "Bg removed": "blue",
  "To remove BG": "yellow",
  "Incomplete": "gray"
};

let keys = {
  "Bg removed": "withNobg",
  "To remove BG": "toBackremoval",
  "Incomplete": "incomplete"
};

let cancel = false;

const RemoveBgBulkModal = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const selections = useSelector(selectSkuSelected);
  const keyField = useSelector(selectAppColumnKey);
  const { data: analyze, status} = useSelector(selectAnalysisRemoveBgBulk);
  const count = useSelector(selectProductCountPiece);

  const { getFromQuery } = useProductQuery();
  
  const [current, setCurrent] = useState([]);
  const [confirm, setConfirm] = useState(false);
  const [executed, setExecuted] = useState(false);
  const [processed, setProcessed] = useState(0);
  const [total, setTotal] = useState(0);
  const [widthError, setWithError] = useState(0);

  const fetch = async () => {
    let selection = Object.keys(selections.selected);
    if (selections.all) {
      let { w } = await getFromQuery();
      let response = await dispatch(fetchAllList({ w: w || {} }));
      selection = response?.data?.map((p) => p._id);
    }
    dispatch(analyzeRemoveBg({ w: selection }));
  };

  const processData = () => {
    let output = [];
    let percet = 0;
    let analyzeLocal = { ...analyze };
    
    if (analyzeLocal?.skipped?._count) {
      analyzeLocal.incomplete._count += analyzeLocal.skipped._count;
    }

    for (let i in keys) {
      if (analyzeLocal[keys[i]]._count) {
        percet = percet + (analyzeLocal[keys[i]]._count / (selections.all
          ? count - Object.keys(selections.selected).length
          : Object.keys(selections.selected).length)
        ) * 100;
      }

      output.push({
        _count: analyzeLocal[keys[i]]._count,
        color: colors[i],
        original: i,
        percent: percet,
        value: analyzeLocal[keys[i]]._count
      });
    }

    setCurrent(output);
    setTotal(analyzeLocal?.toBackremoval?.folders.length);
  };

  const sendRemoveBg = useCallback(async () => {
    setExecuted(true);
    let list = analyze?.toBackremoval?.folders;
    
    for (let i in list) {
      let folderId = list[i];
      await sleep(50).then(async () => {
        if (!cancel) {
          let response = await dispatch(removeBg(folderId, undefined, {
            feedback: {
              success: false,
              error: true
            }
          }));
          await setProcessed((p) => p + 1);
          if (response?.status?.[0]?.code !== 0) {
            setWithError((w) => w + 1);
          }
        }
      });
      if (cancel) break;
    }
  });

  const cancelProcess = () => {
    cancel = true;
    dispatch(closeModal());
  };

  useEffect(() => {
    fetch();
    cancel = false;

    return () => {
      cancel = true;
      dispatch(analyzeRemoveBgReset());
    }
  }, []);

  useEffect(() => {
    if (!analyze) return;

    processData();
  }, [analyze]);

  return (
    <div className={prefix}>
      {executed ? 
        <BulkActionsCounter
          completed={processed}
          legends={{
            action: t("modals:removeBgBulk.bulkCounter.action"),
            complete: t("modals:removeBgBulk.bulkCounter.complete", { field: keyField }),
            error: t("modals:removeBgBulk.bulkCounter.error", { field: keyField }),
            finish: t("modals:removeBgBulk.bulkCounter.finish"),
            success: t("modals:removeBgBulk.bulkCounter.success"),
            total: t("modals:removeBgBulk.bulkCounter.total", { field: keyField })
          }}
          onCancel={cancelProcess}
          total={total}
          widthError={widthError}
        />
      :
        <>
          <CustomProgressBar
            loading={status === LOADING}
            child={<MultipleProgress counts={current || []} />}
            total={
              selections.all
                ? count - Object.keys(selections.selected).length
                : Object.keys(selections.selected).length
            }
            available={total}
          />
          <div className={`${prefix}__check-confirm`}>
            <input 
              disabled={status !== SUCCESS}
              onChange={(e) => setConfirm(!confirm)}
              type="checkbox"
              checked={confirm}
            />
            <Paragraph
              content={t("modals:removeBgBulk.confirmLegend")}
              className="u-purple"
            />
          </div>
          <div className={`${prefix}__actions`}>
            <Button
              content={t("common:form.cancel")}
              onClick={cancelProcess}
              type="underline"
            />

            <Button
              content={t("modals:removeBgBulk.removeBg")}
              disabled={!confirm || executed || !analyze?.toBackremoval?._count}
              onClick={sendRemoveBg}
              type="purple"
            />
          </div>
        </>
      }
    </div>
  );
};

export default RemoveBgBulkModal;
