import { IDLE } from "_constants/redux.constants";

const initialProfileState = {
  all: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialProfileState;
