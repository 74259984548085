import { Button } from "components/atoms/index";
import useNbskEditor from "_hooks/useNbskEditor";

const ObjectActions = ({ canvas, prefix }) => {
  const { sendToBack, bringToFront, removeObjects } = useNbskEditor(canvas);

  return (
    <div className={`${prefix}__actions`}>
      <Button 
        content="Traer al frente"
        onClick={() => bringToFront()}
        sm
        type="primary"
      />

      <Button 
        content="Enviar atrás"
        onClick={() => sendToBack()}
        sm
        type="primary"
      />

      <Button 
        content="Eliminar"
        onClick={ () => removeObjects()}
        sm
        type="delete"
      />
    </div>
  );
};

export default ObjectActions;
