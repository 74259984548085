import i18next from "_config/lang/i18n";
import { toast } from "react-toastify";
import { Toast } from "components/molecules";

/**
 * 
 * @param {object} props 
 * @param {object} settings
 * @param {function} cb 
 * @param {integer} time 
 */
const toastUndo = (props, settings = {}, cb = () => {}, autoClose = 5000) => {
  let schedule = true;
  if (props?.area) props.area = i18next.t(props.area);
  if (props?.info) props.info = i18next.t(props.info);

  toast.info(
    <Toast 
      autoClose={autoClose}
      {...props} 
      buttonProps={{
        onClick: () => {schedule = false}
      }}
    />, {
    ...settings, 
    ...{ 
      onClose: () => {
        if (schedule) cb();
      }
    }
  });
};

export default toastUndo;
