import { useTranslation } from "react-i18next";
import { Button, NubeskIcons } from "components/atoms";
import { useModal } from "_hooks";

const prefix = "o-removal-quota-modal";

const RemovalQuotaModal = () => {
  const { closeModal } = useModal();
  const { t } = useTranslation();
  
  return (
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        <NubeskIcons icon="alert" className="u-purple" size="4rem" />
        <div><h2>{t("quotas:removal.modal.subtitle")}</h2></div>
        <div>{t("quotas:removal.modal.description")}</div>
      </div>
      <div className="buttons">
        <Button
          type="purple"
          content={t("common:words.close")}
          onClick={closeModal}
          sm
        />
      </div>
    </div>
  );
};

export default RemovalQuotaModal;
