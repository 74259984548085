import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { CircleStatus } from "components/atoms";
import CountUp from "react-countup";

const StatisticsButton = ({ status, qty, onClick }) => {
  const { t } = useTranslation();

  const prefix = "m-statistics-button";
  const titles = {
    approved: t("sidebar:statistics.products.complete"),
    toReview: t("sidebar:statistics.products.incomplete"),
    rejected: t("sidebar:statistics.products.broken"),
  };

  return (
    <button className={prefix} onClick={onClick}>
      <span className={`${prefix}__content-title`}>{titles[status]}</span>{" "}
      <div>
        <CircleStatus status={status} />
        <span className={`${prefix}__content-qty`}>
          <CountUp end={qty} duration={1} />
        </span>
      </div>
    </button>
  );
};

StatisticsButton.propTypes = {
  status: PropTypes.oneOf(["complete", "incomplete", "error"]).isRequired,
  qty: PropTypes.number,
};

export default StatisticsButton;
