import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { toast } from "react-toastify";
import { Select } from "components/molecules/Inputs";
import { Counter, CustomProgressBar, PhotoLabel, Toast } from "components/molecules";
import { Badge, Button, DimensionPreview, NubeskIcons, SquareColor, Switch } from "components/atoms";
import ResourceProgress from "./ResourceProgress";
import ResourceDoneProcess from "./ResourceDoneProcess";
import { findOne, generateZip, resetOne } from "_redux/resource/resource.actions";
import {
  selectResourceOne,
  selectResourceStatus,
} from "_redux/resource/resource.selector";

import capitalize from "_utils/string/capitalize";
import { LOADING } from "_constants/redux.constants";
import { DONE } from "_constants/resource.constants";
import { useAppSettings, useChannelLayout, useUser } from "_hooks";
import { backgroundColor } from "_utils/background";
import { WATERMARK_MODULE } from "_constants/app.constants";

const prefix = "o-generate-link-modal";

const GenerateLinkModal = ({
  adaptationList,
  available,
  catalog,
  notAvailable,
  resourceId,
  total
}) => {
  const classForComponent = classNames(prefix);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [selected, setSelected] = useState(null);
  const [allowRegenerate, setAllowRegenerate] = useState(false);
  const [checkRegenerate, setCheckRegenerate] = useState(true);
  const [xlsType, setXlsType] = useState();
  const [disabledXls, setDisabledXls] = useState(false);
  const [divisors, setDivisors] = useState([]);
  const [imagesNumb, setImagesNumb] = useState(0);
  const [options, setOptions] = useState([]);

  const { appColumnKeyProps } = useAppSettings();
  const { data: resource } = useSelector(selectResourceOne);
  const postStatus = useSelector(selectResourceStatus);
  const { fetch: fetchLayouts, listData: layouts} = useChannelLayout();
  const { hasModule } = useUser();       

  const xlsOptions = [
    {
      label: t("catalogs:links.xlsOptions.global"),
      value: "global"
    }, {
      label: t("catalogs:links.xlsOptions.channel"),
      value: "channel",
      disabled: disabledXls
    }
  ];

  const generate = async () => {
    let d = new Date();
    toast.info(
      <Toast
        area={t("catalogs:links.feedback.request.area")}
        info={t("catalogs:links.feedback.request.info")}
      />
    );

    await dispatch(
      generateZip(
        catalog._id,
        selected?.value?._id,
        `${selected?.value?.name}_${available}_${d.getDate()}_${d.getMonth()}_${d.getFullYear()}`,
        {
          manifest: xlsType?.value,
          overwrite : { adaptation: checkRegenerate }
        },
        imagesNumb
      )
    );
  };

  const getDropdownList = () => {
    let arrayMasters = [];
    let array = [];

    for (let layout of layouts) {
      for (let a of layout.adaptationsId) {
        array.push({
          label: <div className="--select-option">
            <Badge content={layout.name} border="purple"/>
            {capitalize(a.name)}
          </div>,
          labelInfo: 
          <>
            {a?.properties?.background?.start ? 
              <><SquareColor color={a?.properties?.background?.start} /> · </>
            : null}
            {`${a?.properties?.width}x${a?.properties?.height} ·
              ${a?.properties?.mimetype ? ` ${a.properties.mimetype.replace("image/", "")}`: null}
            `}
          </>,
          value: a
        })
      }
    }

    for (let al of adaptationList) {
      if (notAvailable.indexOf(al._id) !== -1) continue;
      if (al?.attributes?.isMaster) {
        arrayMasters.push({
          label: capitalize(al.name),
          value: al,
        });
        continue;
      }
    }

    if (arrayMasters.length) setDivisors([array.length]);

    setOptions(array.concat(arrayMasters));
  };

  useEffect(() => {
    fetchLayouts();
    return () => {
      dispatch(resetOne());
    }
  }, []);

  useEffect(() => {
    if (resourceId) {
      dispatch(findOne({ w: { _id: resourceId }}));
    }
  }, [resourceId])

  useEffect(() => {
    if (resourceId && !selected) {
      for (let a of adaptationList) {
        if (resource?.process?.adaptationId === a._id) {
          setSelected({
            value: a._id,
            label: capitalize(a.name)
          });
          break;
        }
      }
    }
  }, [resource])

  useEffect(() => {
    if (!layouts || !adaptationList) return;
    
    getDropdownList();
  }, [adaptationList, layouts]);

  useEffect(() => {
    let allow = true;
    
    for (let al of adaptationList) {
      if (selected?._id === al._id && al?.attributes?.isMaster) {
        allow = false;
        break;
      }
    }
    setAllowRegenerate(allow);
    if (selected && !!selected?.value?.attributes?.isMaster) {
      setDisabledXls(true);
      setXlsType(xlsOptions[0]);
    } else if (selected) {
      setDisabledXls(false);
    }

    if (selected?.value?.attributes?.isMaster) {
      setCheckRegenerate(false);
    } else {
      setCheckRegenerate(true);
    }
  }, [selected]);

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__names`}>
        <div>{catalog?.key}</div>
        <div>{selected?.label || selected}</div>
      </div>
      {!resource && !resourceId &&
        <>
          <Select
            divisors={divisors}
            onChange={(data) => setSelected(data)}
            value={selected?.content}
            emptyLabel={t("catalogs:links.select_adaptation")}
            color="gray"
            name="adaptation-modal"
            options={options ?? []}
          />
          {selected ? (
            <>
              {selected?.value?.attributes?.isMaster !== true && (
                <div className={`${prefix}__info-photo`}>
                  <DimensionPreview
                    className="--preview"
                    h={selected?.value?.properties?.height}
                    w={selected?.value?.properties?.width}
                    margin={[
                      selected?.value?.properties?.margin,
                      selected?.value?.properties?.margin,
                      selected?.value?.properties?.margin,
                      selected?.value?.properties?.margin
                    ]}
                    background={backgroundColor(selected?.value)}
                    fadeType="radial"
                    overlayerImg={hasModule(WATERMARK_MODULE) ? selected?.value?.properties?.layerUrl : undefined}
                  />
                  <div>
                    <PhotoLabel
                      title={t("catalogs:links.width")}
                      data={selected?.value?.properties?.width ?? "-"}
                    />
                    <PhotoLabel
                      title={t("catalogs:links.height")}
                      data={selected?.value?.properties?.height ?? "-"}
                    />
                    <PhotoLabel
                      title={t("catalogs:links.extension")}
                      data={selected?.value?.properties?.mimetype?.replace("image/", "")}
                    />
                    <PhotoLabel
                      title={t("catalogs:links.density")}
                      data={selected?.value?.properties?.density}
                    />
                    <PhotoLabel
                      title={t("catalogs:links.maxSize")}
                      data={`${selected?.value?.properties?.maxSize}`}
                    />
                  </div>
                </div>
              )}
              <div className={`${prefix}__progress-bar`}>
                <CustomProgressBar available={available} total={total} onlyLabel />
              </div>
             
              {allowRegenerate && (
                <div className={`${prefix}__fields`}>
                  <div className="--form-control">
                    <div>{t("catalogs:links.includeXls")}</div>
                    <Select
                      options={xlsOptions}
                      onChange={setXlsType}
                      value={xlsType}
                    />
                  </div>
                  
                  {!selected?.value?.attributes?.isMaster &&
                    <div className="--form-control">
                      <div>{t("catalogs:links.regenerate")}</div>
                      
                      <Switch 
                        checked={checkRegenerate}
                        label={""}
                        onChange={setCheckRegenerate}
                      />
                    </div>
                  }

                  <div className="--images-by-item">
                    <div>
                      {t("catalogs:links.imagesByItem", { key: appColumnKeyProps?.name })}
                    </div>
                    <Counter 
                      value={imagesNumb}
                      setValue={setImagesNumb}
                      max={50}
                      min={0}
                      initial={0}
                      infinite={0}
                      infiniteLabel={t("common:form.all")}
                    />
                  </div>
                </div>
              )} 
              
              <div className={`${prefix}__warning`}>
                <NubeskIcons icon="warning" size={30} />
                <span>
                  {t("catalogs:general.catalog.generate_link_modal.warning", { key: appColumnKeyProps?.name })}
                </span>
              </div>
              
              <div className={`${prefix}__button`}>
                <Button
                  disabled={postStatus === LOADING || !available}
                  content={t("catalogs:links.generate")}
                  onClick={generate}
                  sm
                  type={"blue"}
                />
              </div>
            </>
          ) : null}
        </>
      }
      {resource && resource.step !== DONE && selected && 
        <ResourceProgress 
          catalog={catalog}
          adaptationId={selected?.value?._id}
          resource={resource}
          imagesNumb={resource?.process?.setters?.index || imagesNumb}
        />
      }
      
      {resource && resource.step === DONE &&
        <ResourceDoneProcess 
          urlManifest={resource?.data?.urlManifest}
          urlZip={resource?.data?.url}
        />
      }
    </div>
  )
};

GenerateLinkModal.propTypes = {
  notAvailable: PropTypes.array,
  resourceId: PropTypes.string
};

GenerateLinkModal.defaultProps = {
  notAvailable: []
};

export default GenerateLinkModal;
