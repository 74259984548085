import i18next from "_config/lang/i18n";
import { toast } from 'react-toastify'
import { Toast } from "components/molecules";

const errorToast = ({ code, error }) => {
  if (!code) return;
  
  if (typeof error === 'object') {
    let area = error?.area ?? "common:words.error";
    let info = error?.info ?? "common:words.error";
    return toast.error(<Toast info={i18next.t(info)} area={i18next.t(area)} />)
  } 
  
  if (error === true && code !== false) {
    let info = i18next.exists('common:errors.' + code) ? i18next.t('common:errors.' + code) : code;
    return toast.error(
      <Toast info={info} area={i18next.t("common:words.error")} />
    );
  } 

  if (typeof error === 'string') {
    let info = i18next.exists(error) ? i18next.t(error) : error;
    return toast.error(
      <Toast info={info} area={i18next.t("common:words.error")} />
    );
  }
};

const successToast = ({ code, success }) => {
  if (code !== undefined && code !== false) return;

  if (typeof success === 'object') {
    let area = success?.area ?? "common:words.success"
    let info = success?.info ?? "common:error." + code;
    let infoString = info;

    if (i18next.exists(info)) {
      infoString = i18next.t(info);
    }
    return toast.success(<Toast info={infoString} area={i18next.t(area)} />)
  } 

  if (typeof success === 'string' ) {
    return toast.success(
      <Toast info={i18next.t(success)} area={i18next.t("common:words.success")} />
    );
  }
};

const toastMiddleware = (store) => (next) => (action) => {
  let { feedback } = action;


  if (feedback === undefined) {
    return next(action)
  }
  const actionWith = (data) => {
    const finalAction = Object.assign({}, action, data);
    delete finalAction.feedback;
    return finalAction;
  };

  next(actionWith(action));
  
  if (typeof feedback === 'object') {
    let error = feedback?.error ?? false;
    let success = feedback?.success ?? false;
    let code = feedback?.code ?? false;

    errorToast({ code, error });    
    successToast({ success, code });
  }
};

export default toastMiddleware;
