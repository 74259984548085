import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { UploadDrop } from "components/organism";
import UploadingFile from "components/organism/Upload/UploadingFile";
import Found from "./Found";
import Uploading from "./Uploading";
import { useDispatch } from "react-redux";
import { searchFilter } from "_redux/reader/reader.actions";
import { toggleRefresh } from "_redux/ui/ui.actions";

const prefix = "upload-bulk-images";

const UploadBulkImages = () => {
  const dispatch = useDispatch();
  const [files, setFiles] = useState([]);
  const [data, setData] = useState({found: {}, notFound: [], qty: 0});
  const [step, setStep] = useState(1);
  const classForComponent = classNames(prefix, {});

  useEffect(() => {
    return setFiles([]);
  }, []);

  useEffect(() => {
    if (files.length) {
      setStep(2);
      handleSearch();
    }
  }, [files]);

  const searchName = (string, compare) => string.substr(0, string.lastIndexOf(compare));

  const handleSearch = async () => {
    let _data = {};
    files.forEach((file) => {
      let name = searchName(file.name, "-");
      if( name.length < 5) name = searchName(file.name, ".");
      _data = {
        ..._data,
        [name]: _data[name] ? [..._data[name], file] : [file],
      };
    });

    let results = await dispatch(searchFilter(Object.keys(_data)));
    let final = {};

    final = {...final, notFound: results?.data?.[0].notFound}
  
    await results?.data?.[0].found.forEach((item) => {
      let _qty = _data[item].length || 0;
      final = {
        ...final,
        found: { ...final.found, [item]: _data[item] },
        qty: (final.qty || 0) + _qty,
      };
    });
    setData(final);
    
    setStep(3)
  };

  return (
    <div className={classForComponent}>
      {step === 1 && (
        <UploadDrop
          file={files}
          setFile={setFiles}
          label={"modals:uploadBulk.hintFolder"}
          dropProps={{}}
          directory
        />
      )}
      {step === 2 && <UploadingFile externalStatus="loading" noPath />}
      {step === 3 && (
        <Found
          foundQty={data?.found ? Object.keys(data?.found)?.length : 0}
          notFoundQty={data?.notFound?.length}
          imageQty={data?.qty}
          setStep={setStep}
        />
      )}
      {step === 4 && (
        <Uploading 
          data={data.found}
          imageQty={data.qty}
          onFinish={() => dispatch(toggleRefresh("products"))}
        />
      )}
      {step === 5 && <div>Success</div>}
    </div>
  );
};

export default UploadBulkImages;
