import { CircleStatus } from "components/atoms";
import { FlexComponent } from "components/templates";
import React from "react";

import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { $$FILE, $$FOLDER, $LINK_DOWNLOAD } from "_constants/permission.constants";
import { openManageImages } from "_redux/ui/actions/ui_manageImages.actions";
import { selectUser } from "_redux/user/user.selectors";
import RowTemplate from "../RowTemplate/RowTemplate";

const prefix = "m-master-row";

const CutRow = ({
  bussy,
  date,
  id,
  isActive,
  onClick,
  onDelete,
  onDownload,
  status,
  title,
  qty,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);

  const getOptions = () => {
    let finalOpts = [];
    let opts = {
      order: {
        onClick: () => dispatch(openManageImages()),
        content: t("manage:images.orderFiles"),
      },
      download: {
        onClick: () => onDownload(),
        content: t("products:dataPhotos.download"),
      },
      trimm: {
        onClick: () => {},
        content: t("products:dataPhotos.trimm"),
      },
      upload: {
        onClick: () => {},
        content: t("products:dataPhotos.upload_png"),
      },
      notify: {
        onClick: () => {},
        content: t("products:dataPhotos.notify"),
      },
      delete: {
        onClick: () => onDelete(id),
        content: t("products:dataPhotos.delete_files"),
      }
    };

    if (isActive && user.canDoIt($$FOLDER.EDIT)) finalOpts = [opts.order];
    
    if (user.canDoIt($LINK_DOWNLOAD)) finalOpts.push(opts.download);
    // if (user.canDoIt($$FOLDER.UPLOAD)) finalOpts.push(opts.upload);
    // if (user.canDoIt($$FOLDER.APPROVE_MASTER)) finalOpts.push(opts.notify);
    if (user.canDoIt($$FILE.DELETE)) finalOpts.push(opts.delete);
    
    return finalOpts;
  };

  return (
    <RowTemplate
      onClick={onClick}
      title={title}
      qty={qty}
      isActive={isActive}
      options={getOptions()}
    >
      <FlexComponent>
        <CircleStatus status={status} />
        {bussy ? <span>Processing...</span> : null}
        {!bussy && date ? (
          <>
            <span className={`${prefix}__date`}>{date}</span>
          </>
        ) : null}
        {!bussy && !date ? (
          <button
            onClick={(e) => e.stopPropagation()}
            className={`${prefix}__generate-button`}
          >
            {t("products:dataPhotos.upload_button")}
          </button>
        ) : null}
      </FlexComponent>{" "}
    </RowTemplate>
  );
};

export default CutRow;
