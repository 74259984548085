import { reduxActions as UI } from "_constants/ui.constants";

export const toggleFilter = () => (dispatch) =>
  dispatch({ type: UI.TOGGLE_FILTER });

export const setHeightTabs = (height) => (dispatch) =>
  dispatch({ type: UI.TABS_HEIGHT, payload: height });

export const previousPage = (payload) => (dispatch) =>
  dispatch({ type: UI.PREVIOUS_PAGE, payload });

export const toggleRefresh = (view, data = null) => (dispatch) =>
  dispatch({ type: UI.TOGGLE_REFRESH, payload: {data, view} });
