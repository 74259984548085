export const reduxActions = {
  PRODUCTS_STARTED: 'statistics/products_started',
  PRODUCTS_FAIL: 'statistics/products_fail',
  PRODUCTS_SUCCESS: 'statistics/products_success',
  PRODUCTS_RESET: 'statistics/products_reset',

  TASKS_STARTED: 'statistics/tasks_started',
  TASKS_FAIL: 'statistics/tasks_fail',
  TASKS_SUCCESS: 'statistics/tasks_success',
  TASKS_RESET: 'statistics/tasks_reset',
};
