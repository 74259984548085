import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Switch } from "components/atoms";
import { InputTest, Select } from "components/molecules/Inputs";
import { FlexComponent } from "components/templates";
import Property from "./Property";
import MultipleOptions from "./MultipleOptions";
import useFocus from "_hooks/useFocus";
import { useTranslation } from "react-i18next";

const prefix = "m-active-card";

const panelSettings = {
  Select: MultipleOptions
};

const ActiveCard = ({
  _type,
  allDisabled,
  data,
  editable,
  isModal,
  onChange,
  onSave,
  options,
  propertyOptions,
  type,
  setActive,
  setType
}) => {
  const { t } = useTranslation();
  const [checkeds, setCheckeds] = useState(propertyOptions || {});
  const [newData, setNewData] = useState({});
  const [inputRef] = useFocus();
  const [changed, setChanged] = useState(false);

  const onChangeHandler = ({ target: { name, value } }) => {
    setNewData({ ...newData, [name]: value });
  };

  const save = async () => {
    await onSave({ ...newData, ...checkeds });
    setChanged(false);
    setActive(false);
  };

  useEffect(() => {
    if (
      newData?.original &&
      (newData.original === data.original || !newData?.original?.length)
    ) {
      return setChanged(false);
    }
    setChanged(true);
  }, [newData]);

  useEffect(() => {
    if (propertyOptions !== checkeds) return setChanged(true);
    setChanged(false);
  }, [checkeds]);

  useEffect(() => {
    if (_type === "Text") return setNewData({ ...newData, enum: null });
  }, [_type]);

  useEffect(() => {
    if (typeof onChange === "function") {
      onChange({ ...newData, ...checkeds });
    }
  }, [newData, checkeds]);

  let renderComponent = undefined;

  if (typeof panelSettings[_type] !== "undefined") {
    let Component = panelSettings[_type];
    renderComponent = (
      <Component options={options || []} setNewData={setNewData} />
    );
  }

  return (
    <>
      <Property
        name={t("settings:dataFields.propertyName.name")}
        description={t("settings:dataFields.propertyName.description")}
        variant={"header"}
        content={
          <InputTest
            placeholder={"Property name"}
            name={"original"}
            onChange={onChangeHandler}
            ref={isModal ? inputRef : null}
            value={newData.original ?? (data.original || "")}
          />
        }
        extraChild={
          isModal ? null : (
            <FlexComponent gap={15}>
              {editable && <>
                <Button
                  content="Cancel"
                  sm
                  type={"underline"}
                  onClick={() => {
                    setActive(false);
                    setType(type);
                  }}
                />
                <Button
                  content="Save"
                  sm
                  type={"success"}
                  disabled={!changed}
                  onClick={() => {save(); setType(type)}}
                />
              </>}
            </FlexComponent>
          )
        }
      />
      <Property
        name={t("settings:dataFields.propertyDescription.name")}
        description={t("settings:dataFields.propertyDescription.description")}
        variant={"desc"}
        content={
          <InputTest
            placeholder={"Property description"}
            value={newData.desc ?? (data.desc || "")}
            name={"desc"}
            onChange={onChangeHandler}
            disabled={allDisabled}
          />
        }
      />
      <Property
        name={t("settings:dataFields.propertyOptions.name")}
        description={t("settings:dataFields.propertyOptions.description")}
        variant={"description"}
        content={
          <FlexComponent gap={15}>
            <Switch
              disabled={allDisabled}
              label={t("settings:dataFields.labels.require")}
              name={"require"}
              checked={checkeds.require}
              onChange={() =>
                setCheckeds({
                  ...checkeds,
                  require: !checkeds.require,
                })
              }
            />
            <span className={`${prefix}__division`}>|</span>
            <Switch
              disabled={allDisabled}
              label={t("settings:dataFields.labels.editable")}
              name={"editable"}
              checked={checkeds.editable}
              onChange={() => {
                let newChecks = {
                  ...checkeds,
                  editable: !checkeds.editable,
                };
                if (newChecks.editable) newChecks.overwritable = true;
                setCheckeds(newChecks);
              }}
            />
            <span className={`${prefix}__division`}>|</span>
            <Switch
              disabled={allDisabled}
              label={t("settings:dataFields.labels.overwritable")}
              name={"overwritable"}
              checked={checkeds.overwritable}
              onChange={() => {
                let newChecks = {
                  ...checkeds,
                  overwritable: !checkeds.overwritable,
                };
                if (!newChecks.overwritable) newChecks.editable = false;
                setCheckeds(newChecks);
              }}
            />
            <span className={`${prefix}__division`}>|</span>
            <Switch
              disabled={allDisabled}
              label={t("settings:dataFields.labels.toFilter")}
              name={"toFilter"}
              checked={checkeds.toFilter}
              onChange={() =>
                setCheckeds({
                  ...checkeds,
                  toFilter: !checkeds.toFilter,
                })
              }
            />
            <span className={`${prefix}__division`}>|</span>
            <Switch
              disabled={allDisabled}
              label={t("settings:dataFields.labels.toExport")}
              name={"toExport"}
              checked={checkeds.toExport}
              onChange={() =>
                setCheckeds({
                  ...checkeds,
                  toExport: !checkeds.toExport,
                })
              }
            />
            <span className={`${prefix}__division`}>|</span>
            <Switch
              disabled={allDisabled}
              label={t("settings:dataFields.labels.hidden")}
              name={"hidden"}
              checked={checkeds.hidden}
              onChange={() =>
                setCheckeds({
                  ...checkeds,
                  hidden: !checkeds.hidden,
                })
              }
            />
          </FlexComponent>
        }
      />
      <Property
        name={t("settings:dataFields.propertyField.name")}
        description={t("settings:dataFields.propertyField.description")}
        variant={"space"}
        headerRight={
          <Select
            color="gray"
            readOnly={allDisabled}
            name={"type"}
            value={_type}
            options={["Text", "Bullets", "Select"]}
            onChange={({ target: { name, value }, target }) => {
              setType(value);
              onChangeHandler({ target: { name, value } });
            }}
          />
        }
        content={renderComponent}
      />
    </>
  );
};
ActiveCard.propTypes = {
  allDisabled: PropTypes.bool
};

ActiveCard.defaultProps = {
  allDisabled: false
};

export default ActiveCard;
