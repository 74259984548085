import initialAnalysisState from "./analysis.initialState";
import adaptationBulk from "./reducers/adaptationBulk";
import approvedBulk from "./reducers/approvedBulk";
import layoutCopy from "./reducers/layoutCopy";
import removeBgBulk from "./reducers/removeBgBulk";
import request from "./reducers/request";

const reducer = (state = initialAnalysisState, action) => {
  const reducers = {
    ...adaptationBulk,
    ...approvedBulk,
    ...layoutCopy,
    ...removeBgBulk,
    ...request
  };

  let { type } = action;
  if (!reducers?.[type]) return state;

  return reducers[type](state, action);
};

export default reducer;
