import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const adaptations = {
  [APP.ADAPTATIONS_STARTED]: (state) => ({
    ...state,
    adaptations: {
      ...state.adaptations,
      status: LOADING
    }
  }),
  
  [APP.ADAPTATIONS_SUCCESS]: (state, { payload }) => ({
    ...state,
    adaptations: {
      ...state.adaptations,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),

  [APP.ADAPTATIONS_FAIL]: (state, { payload }) => ({
    ...state,
    adaptations: {
      ...state.adaptations,
      error: payload?.error ?? null,
      status: FAILURE
    }
  })
};

export default adaptations;
