import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FlexComponent, SearchPanel, DropDown } from "components/templates";
import { Button, NubeskIcons } from "components/atoms";
import {  useAppSettings, useModal, useProductQuery, useProductFilter } from "_hooks";
import nbskReducer from "_reducers/nbskReducer";
import {
  closePanelLeft,
  openPanelLeft,
  toggleFilter,
  toggleSelected
} from "_redux/ui/ui.actions";
import { selectUser } from "_redux/user/user.selectors";
import { selectUiStore, selectLeftPanel } from "_redux/ui/ui.selector";
import {
  selectProductAllStore,
  selectProductCount,
  selectProductCountPiece
} from "_redux/product/product.selector";
import { selectCatalogOneStore } from "_redux/catalog/catalog.selector";
import { LEFT_PANEL } from "_constants/reducers.constants";
import { $$ADAPTATION, $$FILE, $$FOLDER, $CATALOG_CREATE, $CATALOG_UPDATE, $CSV_CREATE } from "_constants/permission.constants";
import { LOADING } from "_constants/redux.constants";
import { $SYNCFONIA_PROVIDER_CATALOG, $SYNCFONIA_PROVIDER_GTIN_DATA, $SYNCFONIA_RETAIL_GTIN_ASSETS, $SYNCFONIA_RETAIL_GTIN_CATALOG, $SYNCFONIA_RETAIL_GTIN_DATA, FORK_GS1 } from "module/Syncfonia/syncfonia.constants";

let timeout;

const HomeToolbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { openModal } = useModal();
  const { t } = useTranslation();
  const {
    changeFilters,
    fetchStatus,
    filterFromUrl,
    filters,
    getSuggestions,
    searchHandler
  } = useProductFilter();
  const { itemsSelected } = useProductQuery();
  const { status: productStatus } = useSelector(selectProductAllStore);
  const { status: catalogStatus } = useSelector(selectCatalogOneStore)
  const { appColumnKeyProps, appModules } = useAppSettings();
  const productCount = useSelector(selectProductCount);
  const productCountPiece = useSelector(selectProductCountPiece);
  const { selections } = useSelector(selectUiStore);
  const leftPanel = useSelector(selectLeftPanel);
  const user = useSelector(selectUser);
  
  const [filterCounter, setFilterCounter] = useState(0);
  const [search, setSearch] = useState();
  const [suggestions, setSuggestions] = useState([]);

  const onSearch = (value) => {
    if (timeout) clearTimeout(timeout);
    
    let suggestList = getSuggestions(value);
    let newSuggestions = {};
    
    suggestList.forEach((s) => {
      let { label } = s;
      newSuggestions[label] = s;
    });
    
    setSuggestions(newSuggestions);

    timeout = setTimeout(async () => {      
      if (!suggestList.length) {
        let newFilters = await searchHandler(value);
        changeFilters(newFilters, true);
      }
      clearTimeout(timeout);
    }, 1500);
  };

  const selectSuggest = async ({target: {attributes}}) => {
    if (fetchStatus === LOADING) return;
    
    let collection = attributes.getNamedItem("collection").value;
    let field = attributes.getNamedItem("field")?.value;
    let value = attributes.getNamedItem("value")?.value;
    
    if (!collection || !field || !value) return;

    let newFilters = await filterFromUrl();
    if (newFilters?.$search !== undefined) {
      delete newFilters.$search;
    }

    let newArray = newFilters?.[collection]?.[field] ?? [];
    if (!Array.isArray(newArray)) newArray = [newArray];

    if (newArray.indexOf(value) === -1) {  
      newArray.push(value);

      newFilters = {
        [collection]: {
          ...filters?.[collection] ?? {},
          [field] : newArray
        }
      };
      
      changeFilters(newFilters, true);
    }
  };

  const toggleCatalogs = () => {
    if (leftPanel.isOpen) {
      nbskReducer(LEFT_PANEL.CLOSE, {
        action: () => dispatch(closePanelLeft()),
        dispatch,
        history,
        location,
      });
    } else {
      nbskReducer(LEFT_PANEL.OPEN, {
        dispatch,
        history,
        location,
      });
    }
  };

  const openBulkModal = async ({ typeModal, text }, extras = {}) => {
    let arraySkus = await itemsSelected();
      openModal({
        typeModal,
        header: {
          text,
          rightItem: `(${
            arraySkus.length - Object.keys(selections.selected).length ||
            Object.keys(selections.selected).length
          }) Items`,
        },
        ...extras,
        modalProps: {
          ...extras?.modalProps ?? {},
          items: arraySkus
        }
      })
  };

  useEffect(() => {
    if (typeof filters?.$search === "string" && filters.$search.length) {
      setSearch(filters.$search);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    }
  }, []);

  useEffect(() => {
    let counter;
    let lFilters = filters ? {...filters} : {};
    if (lFilters) {
      if (lFilters?.$search) delete lFilters.$search;

      counter = Object.keys(lFilters)
        .map((collection) =>
          Object.keys(lFilters[collection]).filter(
            (field) => lFilters[collection][field].length > 0
          )
        )
        .flat(1).length;
    } else {
      counter = 0;
    }

    setFilterCounter(counter);
  }, [filters]);

  useEffect(() => {
    if (typeof search === "string") onSearch(search);
  }, [search]);

  return (
    <>
      <FlexComponent>
        <Button
          disabled={catalogStatus === LOADING || productStatus === LOADING}
          type={"primary"}
          content={`
            ${
              !selections.all && !Object.keys(selections.selected).length
                ? t("common:form.select_all")
                : `${
                    selections.all
                      ? t("common:form.deselect_all")
                      : t("common:form.select_all")
                  } (${
                    selections.all
                      ? productCountPiece -
                        Object.keys(selections.selected).length
                      : Object.keys(selections.selected).length
                  }/${productCountPiece ?? ""})
            `
            }`}
          onClick={() => dispatch(toggleSelected())}
        />

        <Button
          disabled={catalogStatus === LOADING || productStatus === LOADING}
          content={
            ["catalog", "catalogs"].indexOf(leftPanel.typePanel) !== -1 &&
            leftPanel.isOpen
              ? leftPanel.typePanel === "catalog"
                ? t("products:actions.exit_catalog")
                : t("products:actions.hide_catalog")
              : t("products:actions.catalog")
          }
          type="primary"
          onClick={toggleCatalogs}
        />

        {user.canDoIt($$FOLDER.CREATE, $$FOLDER.EDIT, $$FOLDER.UPLOAD) && (
          <DropDown
            scrollOptions={{ translateContentSizeXToHolder: false }}
            options={[
              {
                content: t("module:syncfonia.gtinFetch.modal.title"),
                onClick: () => openModal({
                  typeModal: "syncfonia:gtinFetch",
                  header: { text: t("module:syncfonia.gtinFetch.modal.title") },
                  closeBackdrop: false,
                  modalProps: { type: user?.type }
                }),
                leftIcon: <NubeskIcons icon="plus" />,
                visible: user?.fork === FORK_GS1 && (
                  user.canDoIt(
                    [$$FOLDER.READ, $$FOLDER.CREATE, $SYNCFONIA_RETAIL_GTIN_ASSETS],
                    [$$FOLDER.READ, $$FOLDER.CREATE, $SYNCFONIA_PROVIDER_GTIN_DATA]
                  )
                )
              }, {
                content: t("products:actions.new", { key: appColumnKeyProps?.name ?? "" }),
                leftIcon: <NubeskIcons icon="plus" />,
                visible: user.canDoIt([$$FOLDER.CREATE]),
                onClick: () =>
                  dispatch(openPanelLeft({ typePanel: "new-sku" })),
              }, {
                content: t("catalogs:general.toolbar.new"),
                leftIcon: <NubeskIcons icon="plus" />,
                onClick: () => {
                  openModal({
                    typeModal: "newCatalog",
                    header: {
                      text: t("catalogs:general.toolbar.new"),
                      icon: { type: "plus", size: 18 },
                    },
                  });
                
                  nbskReducer(LEFT_PANEL.OPEN, {
                    dispatch,
                    history,
                    location,
                  });
                },
                visible: user.canDoIt([$CATALOG_CREATE])
              }, {
                onClick: () => openModal({
                  typeModal: "upload",
                  header: { text: t("products:newSku.upload_bulk") },
                }),
                content: t("products:newSku.upload_bulk"),
                leftIcon: <NubeskIcons icon="catalogs" />,
                visible: user.canDoIt([$$FOLDER.CREATE], [$$FOLDER.EDIT])
              }, {
                content: t("modals:uploadBulk.actionName"),
                onClick: () => openModal({
                  typeModal: "uploadBulkImages",
                  header: { text: t("modals:uploadBulk.actionNameLong") },
                }),
                leftIcon: <NubeskIcons icon="photos" />,
                visible: user.canDoIt([$$FILE.UP])
              }, {
                content: t("module:syncfonia.updateCatalog.modal.title"),
                onClick: () => openModal({
                  typeModal: "syncfonia:updateCatalog",
                  header: { text: t("module:syncfonia.updateCatalog.modal.title") },
                  modalProps: { type: user?.type }, 
                  closeBackdrop: false
                }),
                leftIcon: <NubeskIcons icon="refreshDot" />,
                visible: user?.fork === FORK_GS1 && 
                  user.canDoIt($SYNCFONIA_RETAIL_GTIN_CATALOG, $SYNCFONIA_PROVIDER_CATALOG)
              }
            ]}
            buttonProps={{
              content: t("products:actions.more_actions"),
              type: "primary",
              rightIcon: <NubeskIcons icon="down" />,
            }}
          />
        )}
      </FlexComponent>

      {(selections.all || Object.keys(selections.selected).length)  ? (
        <FlexComponent>
          {user.canDoIt(
            $CSV_CREATE,
            $$FOLDER.APPROVE_MASTER,
            $$ADAPTATION.CREATE,
            $$ADAPTATION.EDIT,
            $$FILE.BACKREMOVAL
          ) &&
            <DropDown
              maxVisible={0}
              scrollOptions={{ translateContentSizeXToHolder: false }}
              options={[{
                content: t("module:syncfonia.updateGtin.modal.title"),
                onClick: () => openModal({
                  typeModal: "syncfonia:updateGtin",
                  header: { text: t("module:syncfonia.updateGtin.modal.title") },
                  modalProps: { type: user?.type }
                }),
                leftIcon: <NubeskIcons icon="refresh" />,
                visible: user?.fork === FORK_GS1 && user.canDoIt($SYNCFONIA_RETAIL_GTIN_DATA, $SYNCFONIA_PROVIDER_GTIN_DATA) 
              }, {
                content: t("products:actions.download_xls"),
                leftIcon: <NubeskIcons icon="excel" />,
                onClick: () =>
                  openBulkModal({
                    typeModal: "downloadXls",
                    text: <>
                      <NubeskIcons icon="excel" />{" "}
                      {t("products:actions.download_xls")}
                    </>
                  }),
                style: { width: "max-content", minWidth: "100%" },
                visible: user.canDoIt([$CSV_CREATE])
              }, {
                content: t("products:actions.approve_photos"),
                leftIcon: <NubeskIcons icon="photos" />,
                onClick: () =>
                  openBulkModal({
                    typeModal: "approveBulkPhoto",
                    text: <>
                      <NubeskIcons icon="photos" />{" "}
                      {t("products:actions.approve_photos")}
                    </>
                  }, {
                    modalProps: {
                      all: selections.all,
                      selected: Object.keys(selections.selected)
                    }
                  }),
                style: { maxWidth: "100%" },
                visible: user.canDoIt([$$FOLDER.APPROVE_MASTER])
              },
              
              // {
              //   content: t("products:actions.removeBackground"),
              //   leftIcon: <NubeskIcons icon="cut" />,
              //   onClick: () => openBulkModal({
              //     typeModal: "removeBgBulk",
              //     text: <>
              //       <NubeskIcons icon="cut" />{" "}
              //       {t("products:actions.removeBackground")}
              //     </>
              //   }),
              //   style: { maxWidth: "100%" },
              //   visible: user.canDoIt([$$FILE.BACKREMOVAL])
              // // }, {
              //   content: t("products:actions.createAdaptations"),
              //   leftIcon: <NubeskIcons icon="resize" />,
              //   onClick: () => openBulkModal({
              //     typeModal: "adaptationsBulk",
              //     text: <>
              //       <NubeskIcons icon="resize" />&nbsp;
              //       {t("products:actions.createAdaptations")}
              //     </>
              //   }),
              //   style: { maxWidth: "100%" },
              //   visible: user.canDoIt([$$ADAPTATION.CREATE, $$ADAPTATION.EDIT])
              // }, 
              {
                content: t("products:actions.editField"),
                leftIcon: <NubeskIcons icon="edit" />,
                onClick: () => openBulkModal({
                  typeModal: "editFieldBulk",
                  text: <>
                    <NubeskIcons icon="resize" />&nbsp;
                    {t("products:actions.editField")}
                  </>
                }),
                style: { maxWidth: "100%" },
                visible: user.canDoIt([$$FOLDER.EDIT])
              }, {
                content: t("products:actions.addToTask"),
                leftIcon: <NubeskIcons icon="plus" />,
                onClick: () => openBulkModal({
                  text: <>
                    <NubeskIcons icon="resize" />&nbsp;
                    {t("modals:addToTask.title")}
                  </>,
                  typeModal: "addToTask"
                }, {
                  closeBackdrop: false,
                  modalProps: {
                    onFinished: (task) => window.open(`/tasks/${task.key}`, "_blank")
                  }
                }),
                style: { maxWidth: "100%" },
                visible: appModules?.["tasks-module"] && user.canDoIt([$$FOLDER.EDIT])
              }, {
                content: t("modals:removeFromCatalog.title"),
                leftIcon: <NubeskIcons icon="minus" className="u-red" />,
                onClick: () => openBulkModal({
                  typeModal: "removeFromCatalog",
                  text: <>
                    <NubeskIcons icon="minus"  className="u-red" />&nbsp;
                    {t("modals:removeFromCatalog.title")}
                  </>
                }, {
                  closeBackdrop: false
                }),
                style: { maxWidth: "100%" },
                visible: (matchPath(location.pathname, {
                  path: "/products/catalog/:catalogId",
                  exact: true
                })?.params?.catalogId !== undefined) && user.canDoIt([$CATALOG_UPDATE])
              }, {
                content: t("products:actions:removeProducts", { key: appColumnKeyProps?.name ?? "" }),
                leftIcon: <NubeskIcons icon="minus" className="u-red" />,
                onClick: () => openBulkModal({
                  typeModal: "removeProduct",
                  text: <>
                    <NubeskIcons icon="minus"  className="u-red" />&nbsp;
                    {t("modals:removeProduct.title", { key: appColumnKeyProps?.name ?? "" })}
                  </>
                }, {
                  closeBackdrop: false
                }),
                style: { maxWidth: "100%" },
                visible: (matchPath(location.pathname, {
                  path: "/products",
                  exact: true,
                  strict: true
                })?.params?.catalogId === undefined) && user.canDoIt([$$FOLDER.DELETE])
              }]}
              buttonProps={{
                content: t("products:actions.bulkActions", {
                  n: `${
                    selections.all
                      ? productCountPiece -
                        Object.keys(selections.selected).length
                      : Object.keys(selections.selected).length
                  }`,
                  key: `${appColumnKeyProps?.name}`,
                }),
                type: "primary",
                rightIcon: <NubeskIcons icon="down" />
              }}
            />
          }
        </FlexComponent>
      ) : (
        <SearchPanel
          autocomplete={suggestions}
          counterName={`${appColumnKeyProps?.name?.toUpperCase()}'s`}
          qty={productCountPiece}
          total={productCount}
          onChange={setSearch}
          filterCounter={filterCounter}
          onToggleFilters={() => dispatch(toggleFilter())}
          onSelectSuggest={selectSuggest}
          placeholder={t("products:filters.searchBy", { key: appColumnKeyProps?.name ?? "" })}
          value={search}
        />
      )}
    </>
  );
};

export default HomeToolbar;
