import { newProperty } from "../schema.generator";

const newTaskSchema = [
  { ...newProperty("firstName", "string", true, "Name") },
  { ...newProperty("lastName", "string", true, "Last Name") },
  { ...newProperty("email", "string", true, "Email", {
    matches: [/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g, "must be email"]
  })}
];

export default newTaskSchema;
