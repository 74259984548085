import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import { NubeskIcons } from "components/atoms";
import { DropDown } from "components/templates";
import useTooltip from "_hooks/useTooltip";

const NotificationButton = ({ hasNotification }) => {
  const prefix = "m-notification-button";
  const classForComponent = classNames(prefix, {
    "has-notification": hasNotification,
  });
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });
  return (
    <div className={classForComponent} {...injectedProps}>
      {hasNotification ? (
        <span className={`${prefix}__count`}>{hasNotification}</span>
      ) : null}
      <DropDown
      disabled
        dropComponent={
          <div style={{padding: 10, minWidth: 250}}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla quod
            omnis facere quaerat cumque porro eligendi laborum suscipit harum
            quam dolorum, quos molestiae repellat nihil similique, vitae fugit
            odio vel magnam perferendis! Quam suscipit quidem minima modi
          </div>
        }
        buttonProps={{
          content: <NubeskIcons icon="notification" />,
          type: null,
        }}
      />
    </div>
  );
};

NotificationButton.propTypes = {
  hasNotification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NotificationButton;

