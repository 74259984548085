import { useState } from "react";
import { Select } from "components/molecules/Inputs";
import { useTranslation } from "react-i18next";
import { Accordion } from "../index";
import { useAppSettings, useChannelLayout, useNbskEditor } from "_hooks";


const DataSection = ({ canvas, prefix }) => {
  const { appPropertyColumns } = useAppSettings();
  const { addText } = useNbskEditor(canvas);
  const { listData } = useChannelLayout();
  const { t } = useTranslation();

  const [selected, setSelected] = useState({
    label: t("nbsk:editor.data.global"),
    value: "global"
  });

  return (
    <Accordion
      title={t("nbsk:editor.data.productAttr")}
      isOpen={true}
      content={
        <div className={`${prefix}__product-attr`}>
          <Select
            options={[{
              label: t("nbsk:editor.data.global"),
              value: "global"
            }, ...listData.map((c) => ({
              label: c.name,
              value: c
            }))]}
            onChange={(v) => setSelected(v)}
            value={selected}
          />

          <div className="--allowed-attr" onClick={(e) => {
            let attr = e.target;
            if (!e.target.classList.contains(".--attribute")) {
              attr = e.target.closest('.--attribute');
            }

            if (attr) {
              addText({
                editable: false,
                fontFamily: "Arial",
                left: 100,
                nbskProperties: {
                  lock: false
                },
                text: `${attr.dataset.layout}/${attr.dataset.key}`,
                textAlign: "center",
                top: 100,
              });
            }
          }}>
            {typeof selected?.value === "string" ? 
              appPropertyColumns?.map((a, i) => 
                <div className="--attribute" key={i} data-key={a.key} data-layout="L-GLOBAL001">
                  <div className="--label">
                    <div className="--key">{a.key}</div> 
                    {a.name}
                  </div>
                  <div className="--btn-add"></div>
                </div>
              )
            :
              selected?.value?.attributesId?.map((a, i) => 
                <div className="--attribute" key={i} data-key={a.key} data-layout={selected?.value?.key} >
                  <div className="--label">
                    <div className="--key">{a.key}</div> {a.name}
                  </div>
                  <div className="--btn-add"></div>
                </div>
              )
            }
          </div>
        </div>
      }
    />
  )
};

export default DataSection;
