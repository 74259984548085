import React, { forwardRef, useState, useEffect } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { InputsApproveButton, InputLabel, Select } from "../";
import InputTest from "../InputTest/InputTest";
import Bullets from "components/molecules/Bullets/Bullets";

const prefix = "m-input-selector";

const InputSelector = ({
  buttons,
  value,
  options,
  label,
  edit,
  name,
  onChange,
  globalEdit,
  closeEdit,
  openEdit,
  readOnly,
  onApprove,
  onDiscard,
  originalValue,
  ...props
}, ref) => {
  const orders = {
    pc9: 1,
    name: 1,
    simple_description: 1,
    marketing_description: 1,
    bullets: 3,
    /*  "country-of-origin": 2 */
  };

  const componentManager = {
    text: InputTest,
    select: Select,
  };
  const [component, setComponent] = useState("skeleton");
  const [hasBullet, setHasBullet] = useState(false);
  const [lines, setLines] = useState(0);
  const [phraseLength, setPhraseLength] = useState(0);

  const classForComponent = classNames(prefix, {
    "max-width":
      (value?.replace?.(",", " ").split?.(" ").length > 9) & !hasBullet,
    "mid-width":
      (value?.replace?.(",", " ").split?.(" ").length >= 6) & !hasBullet,
    "read-only": readOnly,
    "has-bullets": hasBullet,
  });

  useEffect(() => {
    setComponent("");
    if (options) return setComponent("select");
    setComponent("text");
  }, [options]);

  useEffect(() => {
    if (!value || typeof value !== "string") return;
    let bullets = false;
    for (let line of value?.split("\n") ?? []) {
      if (line?.split(" ").length > 12) {
        return false;
      }
      bullets = true;
    }
    if (
      (value?.split("\n")?.length >= 3 && bullets) ||
      value.includes("•") ||
      hasBullet
    ) {
      setHasBullet(true);
      let split = value.replaceAll("•", "").split("\n");
      setLines(split.length);
      let maxWords = 0;
      split.forEach((line) => {
        if (line.split(" ").length > maxWords) {
          maxWords = line.split(" ").length;
        }
      });
      return setPhraseLength(maxWords);
    }
  }, [value]);

  const handleClick = () => {
    onApprove?.();
    closeEdit?.();
  };

  const handleDiscard = () => {
    onDiscard?.();
    closeEdit?.();
  };

  const onKeyPress = (e) => {
    if (e.keyCode === 27 || e.which === 27) {
      handleDiscard();
    }

    if (e.nativeEvent.ctrlKey === true && e.which === 13 && !globalEdit) {
      if (value !== originalValue) {
        return handleClick();
      }
      handleDiscard();
    }

    if (e.nativeEvent.ctrlKey === true && e.which === 68 && !globalEdit) {
      e.preventDefault();
      setHasBullet(false);
    }
  };

  let renderedInput;

  if (typeof componentManager[component] !== "undefined") {
    const InputComponent = componentManager[component];
    renderedInput = (
      <InputComponent
        readOnly={!globalEdit ? !edit : !globalEdit}
        disabled={readOnly}
        value={value?.replaceAll?.("•", "").replace?.(/^ +/gm, "") || value}
        name={name}
        onChange={onChange}
        onKeyDown={onKeyPress}
        onClick={component === "text" ? handleClick : null}
        options={options}
        ref={ref}
        {...props}
      />
    );
  }
  return (
    <div
      className={classForComponent}
      onDoubleClick={openEdit}
      style={{
        order: orders[name],
        gridRow: lines ? `span ${Math.ceil(lines / 3)}` : undefined,
        gridColumn: phraseLength
          ? `span ${phraseLength >= 10 ? 3 : phraseLength > 4 ? 2 : 1}`
          : undefined,
      }}
      onBlur={
        (String(value ?? "").trim() === originalValue) & (component === "text")
          ? closeEdit
          : null
      }
    >
      <InputLabel label={label}>
        {hasBullet ? <Bullets lines={lines} /> : null}
        {renderedInput ? (
          renderedInput
        ) : (
          <div className="u-skeleton" style={{ height: 45 }}></div>
        )}
      </InputLabel>
      {buttons && !globalEdit && edit && value !== originalValue ? (
        <InputsApproveButton
          onClick={{
            approve: () => handleClick(),
            discard: () => handleDiscard(),
          }}
        />
      ) : null}
    </div>
  );
};
InputSelector.propTypes = {
  buttons: PropTypes.bool
};

InputSelector.defaultProps = {
  buttons: true
};

export default forwardRef(InputSelector);
