import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, DimensionPreview, NubeskIcons } from "components/atoms";
import { DERIVED_TYPE } from "_constants/attribute.constants";
import { useAttribute, useUser } from "_hooks";
import { backgroundColor } from "_utils/background";
import { WATERMARK_MODULE } from "_constants/app.constants";

const prefix = "o-sales-channel-card";

const SalesChannelCard = ({ adaptationAttr, attributes, name, channelKey, properties }) => {
  const { downloadTemplate } = useAttribute();
  const history = useHistory();
  const { t } = useTranslation();
  const { hasModule } = useUser();

  return (
    <div className={prefix}>
      <div className={`${prefix}__dimensions`}>
        <div className="--name">{name}</div>
        <div className="--category">{t("settings:channelLayout.imagesTitle")}</div>
        <DimensionPreview
          className="--preview"
          derivedFrom={""}
          h={properties?.height}
          w={properties?.width}
          margin={[0,0,0,0]}
          background={backgroundColor({ attributes: adaptationAttr, properties })}
          fadeType="radial"
          transparent={true}
          overlayerImg={hasModule(WATERMARK_MODULE) ? properties?.layerUrl : undefined}
        />
        <div className="--width">
          <p className="--label">{t("settings:channelLayout.width")}</p>
          <p className="--data"><b>{properties?.width}</b>px</p>
        </div>
        <div className="--height">
          <p className="--label">{t("settings:channelLayout.height")}</p>
          <p className="--data"><b>{properties?.height}</b>px</p>
        </div>
        <div className="--resolution">
          <p className="--label">{t("settings:channelLayout.density")}</p>
          <p className="--data"><b>{properties?.density}</b>DPI</p>
        </div>
        <div className="--size">
          <p className="--label">{t("settings:channelLayout.maxSize")}</p>
          <p className="--data"><b>{["image/png", "images/webp"].includes(properties?.mimetype) ? "ND" : properties?.maxSize}</b></p>
        </div>
        <div className="--extension">
          <p className="--label">{t("settings:channelLayout.extension")}</p>
          <p className="--data"><b>{properties?.mimetype ? properties.mimetype.replace("image/", ""): ""}</b></p>
        </div>
        <div className="--config">
          <Button
            content="Configuración"
            leftIcon={<NubeskIcons icon="settings" />}
            onClick={() => history.push(`/settings/sales-channels/${channelKey}/adaptations`)}
            sm
            type="underline"
          />
        </div>
      </div>
      
      <div className={`${prefix}__attributes`}>
        <div className="--category">{t("settings:channelLayout.attributesTitle")}</div>
        {attributes ? <>
          <div className="--no-columns">
            <p className="--label">{t("settings:channelLayout.columns")}</p>
            <p className="--data">{attributes?.length ?? 0}</p>
          </div>
          <div className="--download">
            <Button
              content="XLS"
              leftIcon={<NubeskIcons icon="download" />}
              onClick={() => downloadTemplate(channelKey)}
              sm
              type="underline"
            />
          </div>
          <div className="--origin">
            <p className="--label">{t("settings:channelLayout.global")}</p>
            <p className="--data">{attributes?.filter((a) => a.type === DERIVED_TYPE)?.length ?? 0}</p>
          </div>
          <div className="--unique">
            <p className="--label">{t("settings:channelLayout.uniques")}</p>
            <p className="--data">{attributes?.filter((a) => a.type !== DERIVED_TYPE)?.length ?? 0}</p>
          </div>
          {/* <div className="--lang">
            <p className="--label">{t("settings:channelLayout.language")}</p>
            <p className="--data">ES-MX</p>
          </div> */}
        </>
        : 
          <div className="--empty-data">
            {t("settings:channelLayout.emptyAttributes")}
          </div>
        }
        <div className="--config">
          <Button
            content="Configuración"
            leftIcon={<NubeskIcons icon="settings" />}
            onClick={() => history.push(`/settings/sales-channels/${channelKey}/attributes`)}
            sm
            type="underline"
          />
        </div>
      </div>
    </div>
  );
};

export default SalesChannelCard;
