import initialUIState from "./ui.initialState";
import images from "./reducers/images";
import leftPanel from "./reducers/leftPanel";
import loader from "./reducers/loader";
import manageImages from "./reducers/manageImages";
import misc from "./reducers/misc";
import modal from "./reducers/modal";
import selection from "./reducers/selection";
import shortcuts from "./reducers/shortcuts";
import smartEditor from "./reducers/smartEditor";
import toolbar from "./reducers/toolbar";
import tooltip from "./reducers/tooltip";

const uiReducer = (state = initialUIState, { payload, type }) => {
  let reducers = {
    ...images,
    ...leftPanel,
    ...loader,
    ...manageImages,
    ...misc,
    ...modal,
    ...selection,
    ...smartEditor,
    ...shortcuts,
    ...toolbar,
    ...tooltip,
  };
  if (!reducers[type]) return state;
  return reducers[type](state, { payload });
};

export default uiReducer;
