import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const propertyColumns = {
  [APP.PROP_COLUMNS_STARTED]: (state, { payload }) => ({
    ...state,
    propertyColumns: {
      ...state.propertyColumns,
      status: LOADING
    }
  }),
    
  [APP.PROP_COLUMNS_SUCCESS]: (state, { payload }) => ({
    ...state,
    propertyColumns: {
      ...state.propertyColumns,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [APP.PROP_COLUMNS_FAIL]:  (state, { payload }) => ({
    ...state,
    propertyColumns: {
      ...state.propertyColumns,
      error: payload?.error ?? null,
      status: FAILURE
    }
  })
};

export default propertyColumns;
