import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const post = {
  [PRODUCT.POST_STARTED]: (state) => ({
    ...state,
    post: {
      data: null,
      error: null,
      status: LOADING
    }
  }),
  [PRODUCT.POST_SUCCESS]: (state, { payload }) =>  ({
    ...state,
    post: {
      status: SUCCESS,
      data: payload?.data?.[0] ?? null,
      error: null
    }
  }),
  [PRODUCT.POST_FAIL]: (state, { payload }) => ({
    ...state,
    post: {
      status: FAILURE,
      data: null,
      error: payload?.data?.status?.[0]?.desc
    }
  }),
  [PRODUCT.POST_RESET]: (state) =>  ({
    ...state,
    post: initialProductState.post
  })
};

export default post;
