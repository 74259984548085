import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { FlexTable } from "components/templates";
import { Accordion, SimpleScroller } from "components/organism";
import { Button, FlexCell, NubeskIcons, Switch } from "components/atoms";
import { InputTest, Select } from "components/molecules/Inputs";
import { useAttribute, useFocus, useForm, useModal } from "_hooks";
import { LOADING } from "_constants/redux.constants";

const prefix = "o-global-attribute-modal";

const GlobalAttributeModal = ({ attributeId }) => {
  const { closeModal } = useModal();
  const { 
    attributeFetch,
    createGlobalAttribute,
    dataTypeOpts,
    oneData,
    oneStatus,
    oneReset,
    postReset,
    postStatus,
    putReset,
    putStatus,
    updateGlobalAttribute
  } = useAttribute();
  const { t } = useTranslation();

  const [attributeForm] = useState({
    overwritable: true,
    toExport: true
  });

  const { disable, form, error, changeHandler, setForm } = useForm({
    schemaType: "newGlobalAttribute",
    formInitialState: attributeForm
  });

  const [inputRef] = useFocus();

  let classForField = classNames('--form-control-vertical', {
    '--with-key': !!oneData
  });

  useEffect(() => {
    if (attributeId) {
      attributeFetch({ w: { _id: attributeId } });
    }

    return () => {
      oneReset();
      postReset();
      putReset();
    } 
  }, []);

  useEffect(() => {
    if (oneData) {
      setForm({
        name: oneData?.name,
        rules: oneData?.validator?.prompt,
        dataType: dataTypeOpts.filter((r) => r.value === oneData?.class?.datatype)?.[0] ?? dataTypeOpts[0],
        require: oneData?.flag?.require ?? false,
        editable: oneData?.flag?.editable ?? false,
        overwritable: oneData?.flag?.overwritable ?? false,
        toFilter: oneData?.flag?.toFilter ?? false,
        toExport: oneData?.flag?.toExport ?? false
      });
    }
  }, [oneData]);

  return (
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        <SimpleScroller isHorizontal={false}>
          <div className={`${prefix}__basic-settings`}>
            <div className={`${prefix}__basics`} >
              
              {oneData &&
                <div className="--form-control-vertical --key">
                  <div className="--form-label-vertical">{t("settings:layoutAttributes.modal.fields.key")}</div>
                  <InputTest
                    name="key"
                    readOnly={true}
                    value={oneData?.key}
                  />
                </div>
              }

              <div className={classForField}>
                <div className="--form-label-vertical">{t("settings:layoutAttributes.modal.fields.name.label")}</div>
                <InputTest
                  error={error?.name}
                  name={"name"}
                  onChange={changeHandler}
                  readOnly={false}
                  ref={inputRef}
                  placeholder={t("settings:layoutAttributes.modal.fields.name.placeholder")}
                  value={form?.name}
                />
              </div>

              <div className="--form-control-vertical">
                <div className="--form-label-vertical">
                  {t("settings:layoutAttributes.modal.fields.allowDataType.label")}
                </div>
                <Select
                  disabled
                  name="allowDataType"
                  onChange={changeHandler}
                  options={[
                    "Cualquier valor ingresado por el usuario",
                    "Restringido a valores de la lista"
                  ]}
                  value={form?.allowData}
                />
              </div>

              <div className="--form-control-vertical">
                <div className="--form-label-vertical">
                  {t("settings:layoutAttributes.modal.fields.rules.label")}
                </div>
                <InputTest 
                  error={error?.rules}
                  name="rules"
                  onChange={changeHandler}
                  value={form?.rules}
                  placeholder={t("settings:layoutAttributes.modal.fields.rules.placeholder")}
                  rows={3}
                />
              </div>

              <div className="--form-control">
                <div>
                  <NubeskIcons className="u-purple" icon="copy" size="19px" /> {t("settings:layoutAttributes.modal.fields.validateRules.label")}
                </div>
                <Switch
                  disabled
                  checked={form?.validateRules}
                  onChange={(v) => changeHandler({
                    target: {
                      name: "validateRules",
                      value: v
                    }
                  })}
                />
              </div>
              <div className="--form-control">
                <div>
                  <NubeskIcons className="u-purple" icon="copy" size="19px" /> {t("settings:layoutAttributes.modal.fields.editContent.label")}
                </div>
                <Switch
                  disabled
                  checked={form?.editContent}
                  onChange={(v) => changeHandler({
                    target: {
                      name: "editContent",
                      value: v
                    }
                  })}
                />
              </div>
            </div>
            <div className={`${prefix}__type-n-rules`} >
              <div className="--form-control-vertical">
                <div className="--form-label-vertical">{t("settings:layoutAttributes.modal.fields.type.label")}</div>
                <Select
                  name="type"
                  onChange={(v) => changeHandler({
                    target: {
                      name: "type",
                      value: v
                    }
                  })}
                  options={[{
                    label: "Global",
                    value: "global"
                  }]}
                  value={form?.type}
                />
              </div>

              <div className="--form-control-vertical">
                <div className="--form-label-vertical">{t("settings:layoutAttributes.modal.fields.dataType.label")}</div>
                <Select     
                  name="dataType"
                  onChange={(v) => changeHandler({
                    target: {
                      name: "dataType",
                      value: v
                    }
                  })}
                  options={dataTypeOpts}
                  value={form?.dataType}
                />
              </div>

              <div className="--form-label">
                <NubeskIcons className="u-purple" icon="copy" size="19px" /> {t("settings:layoutAttributes.modal.attributeRules")}
              </div>

              <div className="--form-control">
                {t("settings:layoutAttributes.modal.fields.require.label")}
                <Switch
                  onChange={(v) => changeHandler({
                    target: {
                      name: "require",
                      value: v
                    }
                  })}
                  checked={form?.require}
                />
              </div>

              <div className="--form-control">
                {t("settings:layoutAttributes.modal.fields.editable.label")}
                <Switch
                  onChange={(v) => changeHandler({
                    target: {
                      name: "editable",
                      value: v
                    }
                  })}
                  checked={form?.editable}
                />
              </div>

              <div className="--form-control">
                {t("settings:layoutAttributes.modal.fields.overwritable.label")}
                <Switch
                  onChange={(v) => changeHandler({
                    target: {
                      name: "overwritable",
                      value: v
                    }
                  })}
                  checked={form?.overwritable}
                />
              </div>

              <div className="--form-control">
                {t("settings:layoutAttributes.modal.fields.toFilter.label")}
                <Switch
                   onChange={(v) => changeHandler({
                    target: {
                      name: "toFilter",
                      value: v
                    }
                  })}
                  checked={form?.toFilter}
                />
              </div>
              <div className="--form-control">
                {t("settings:layoutAttributes.modal.fields.toExport.label")}
                <Switch
                   onChange={(v) => changeHandler({
                    target: {
                      name: "toExport",
                      value: v
                    }
                  })}
                  checked={form?.toExport}
                />
              </div>
            </div>
          </div>
          <div className={`${prefix}__file-headers`} >
            <Accordion
              title={t("settings:layoutAttributes.modal.table.title")}
              content={<FlexTable 
                scrollable={false}
                gridTemplateColumns="80px repeat(2, 1fr)"
                headers={<>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.row")}</FlexCell>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.tagId")}</FlexCell>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.valueToExport")}</FlexCell>
                </>}
                rows={[<>
                  <FlexCell>1</FlexCell>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.nubeskId")}</FlexCell>
                  <FlexCell>
                    <Select
                      disabled
                      options={[t("settings:layoutAttributes.modal.table.nubeskId")]}
                      value={t("settings:layoutAttributes.modal.table.nubeskId")}
                      xs
                    />
                  </FlexCell>
                </>, <>
                  <FlexCell>2</FlexCell>
                  <FlexCell>{t("settings:layoutAttributes.modal.table.attributeName")}</FlexCell>
                  <FlexCell>
                    <Select
                      disabled
                      options={[t("settings:layoutAttributes.modal.table.attributeName")]}
                      value={t("settings:layoutAttributes.modal.table.attributeName")}
                      xs
                    />
                  </FlexCell>
                </>]}
              />}
            />
          </div>
        </SimpleScroller>
      </div>
      <div className={`${prefix}__buttons`}>
        <Button
          content={t("common:form.cancel")}
          type="underline"
          onClick={() => closeModal()}
          sm
        />
        <Button
          disabled={disable || oneStatus === LOADING || postStatus === LOADING || putStatus === LOADING}
          content={t("common:form.save")}
          type="purple"
          onClick={async () => {
            let response;
            if (oneData?._id) {
              response = await updateGlobalAttribute(attributeId, form);
            } else {
              response = await createGlobalAttribute(form);
            }
            if (response?.data) closeModal();
          }}
          sm
        />
      </div>
    </div>
  );
};

export default GlobalAttributeModal;

