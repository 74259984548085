import PropTypes from "prop-types";
import classNames from "classnames";

const prefix = "m-form-label";

const FormLabel = ({ className, content, size, ...props }) => {

  const classForComponent = classNames(prefix, {
    [className]: className,
    [`--${size}`]: size
  });

  return (
    <label
      className={classForComponent}
      {...props}
    > 
      {content}
    </label>
  );
};

FormLabel.propTypes = {
  content: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string
  ]),
  size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl"])
};

FormLabel.defaultProps = {
  content: "",
  size: "xs"
};

export default FormLabel;
