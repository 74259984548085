import { reduxActions as TASK } from "_constants/task.constants";
import initialTaskState from "../task.initialState";

const all = {
  [TASK.SELECTION_ITEM_TOGGLE]: (state, { payload }) => ({
    ...state,
    selection: {
      ...state.selection,
      allItems: (() => {
        if (state.selection.allItems) {
          return false;
        }

        return state.selection.allItems;
      })(),
      items: (() => {
        let newArray = [...state.selection.items];
        let array = Array.isArray(payload) ? payload : [payload];
        for (let i of array) {
          let index = newArray.indexOf(i);
          
          if (index !== -1) {
            newArray.splice(index, 1);
          } else {
            newArray.push(i);
          }
        }
        
        return newArray;
      })()
    }
  }),
  
  [TASK.SELECTION_ALL_ITEMS]: (state) => ({
    ...state,
    selection: {
      ...state.selection,
      allItems: !state.selection.allItems,
      items: []
    }
  }),

  [TASK.SELECTION_ITEMS_RESET]: (state) => ({
    ...state,
    selection: {
      ...state.selection,
      allItems: false,
      items: []
    }
  })
};

export default all;
