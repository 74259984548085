import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Button, NubeskIcons, Title } from "components/atoms";
import { useAdaptation, useChannelLayout, useUser } from "_hooks";
import { openModal } from "_redux/ui/ui.actions";
import { $$ADAPTATION } from "_constants/permission.constants";

const prefix = "o-channel-adaptations-toolbar";

const ChannelAdaptationsToolbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();  
  const { t } = useTranslation();

  const { layoutData } = useChannelLayout();
  const { onListReset } = useAdaptation();
  const { user } = useUser();

  return (
    <div className={prefix}>
      <div>
        <Button
          content={t("settings:layoutAdaptations.actions.toBack")}
          leftIcon={<NubeskIcons icon="arrowLeft" />}
          type={"color"}
          style={{ width: "max-content" }}
          onClick={() => {
            onListReset();
            history.push("/settings/sales-channels");
          }}
        />
        <Title
          className="u-purple"
          content={
            <>
              <NubeskIcons icon="settings" /> {layoutData?.name} | {t("settings:layoutAdaptations.title")}
            </>
          }
          fontSize={18}
        />
      </div>
      <div>
        {user.canDoIt($$ADAPTATION.CREATE) && 
          <Button
            content={t("settings:layoutAdaptations.actions.newAdaptation")}
            disabled={!layoutData}
            leftIcon={<NubeskIcons icon="plus" />}
            type="primary"
            style={{ width: "max-content" }}
            onClick={() => dispatch(openModal({
              header: { text: t("settings:layoutAdaptations.actions.newAdaptation"), icon: {type: "plus", size: 20} },
              modalProps: {
                layoutKey: layoutData?.key,
                onSave: () => {}
              },
              typeModal: "newAdaptation"
            }))}
          />
        }
      </div>
    </div>
  );
};

export default ChannelAdaptationsToolbar;
