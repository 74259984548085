import initialProductState from "../product.initialState";
import { reduxActions as PRODUCT } from "_constants/product.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const downloadXls = {
  [PRODUCT.DOWNLOAD_XLS_STARTED]: (state) => ({
    ...state,
    download: {
      ...state.download,
      status: LOADING
    }
  }),

  [PRODUCT.DOWNLOAD_XLS_FAIL]: (state, { payload }) => ({
    ...state,
    download: {
      ...state.download,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [PRODUCT.DOWNLOAD_XLS_SUCCESS]: (state, { payload }) => ({
    ...state,
    download: {
      ...state.download,
      data: payload?.data?.[0],
      status: SUCCESS
    }
  }),
  [PRODUCT.DOWNLOAD_XLS_RESET]: (state) => ({
    ...state,
    download: initialProductState.download
  })
};

export default downloadXls;
