import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { Toast } from "components/molecules";
import { find, post } from "_redux/adaptation/adaptation.actions";
import { selectAdaptationAll, selectAdaptationMasters, selectAdaptationOnList, selectAdaptationWmCanvasEditor } from "_redux/adaptation/adaptation.selector";
import useAppSettings from "./useAppSettings";
import {
  ACTIVE, 
  reduxActions as ADAPTATION,
  CHANGE_BG,
  CUSTOM,
  FOLDING_BY_SKU,
  FOLDING_ONLY,
  IMAGE,
  INPAINT,
  RADIAL,
  REDIMENSION,
  SOLID,
  STANDARD,
  TRANSPARENT,
  WITH_MARGINS
} from "_constants/adaptation.constants";
import { API } from "_constants/request.constants";

const useAdaptation = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { appAdaptations } = useAppSettings();
  const adaptationsAll = useSelector(selectAdaptationAll);
  const [adaptations, setAdaptations] = useState(null);
  const [data, setData] = useState([]);
  const { data: onList } = useSelector(selectAdaptationOnList);
  const { data: mastersData, error: mastersError, status: mastersStatus } = useSelector(selectAdaptationMasters);
  const wmCanvasEditor = useSelector(selectAdaptationWmCanvasEditor);

  const typeOpts = [{
    label: "Redimensionar",
    value: REDIMENSION
  }, {
    label: "Cambiar fondo",
    value: CHANGE_BG
  }, {
    label: "Agregar márgenes",
    value: WITH_MARGINS
  }];

  const bgTypeOpts = [{
    label: "Sólido",
    value: SOLID
  }, {
    label: "Radial",
    value: RADIAL
  }, {
    label: "Transparente",
    value: TRANSPARENT
  }, {
    label: "Imagen",
    value: IMAGE,
    disabled: true
  }, {
    label: "Fondo IA",
    value: INPAINT,
    disabled: true
  }];

  const foldingOpts = [{
    label: t("settings:layoutAdaptations.fields.folding.opts.bySku"),
    value: FOLDING_BY_SKU
  }, {
    label: t("settings:layoutAdaptations.fields.folding.opts.oneFolder"),
    value: FOLDING_ONLY
  }];

  const formatOpts = [{
    label: "0",
    value: 0,
   }, {
    label: "1",
    value: 1
  }, {
    label: "00",
    value: "00"
  }, {
    label: "01",
    value: "01"
  }];

  const mimeTypeOpts = [{
    label: "JPG",
    value: "image/jpeg"
  }, {
    label: "PNG",
    value: "image/png"
  }, {
    label: "WEBP",
    value: "image/webp"
  }];

  const renameTypeOpts = [{
    label: t("settings:layoutAdaptations.fields.renameType.opts.standard"),
    value: STANDARD
  }, {
    label: t("settings:layoutAdaptations.fields.renameType.opts.custom"),
    value: CUSTOM
  }];

  const separatorOpts = [{
    label: t("settings:layoutAdaptations.fields.split.opts.dash"),
    value: "-"
  },{
    label: t("settings:layoutAdaptations.fields.split.opts.dot"),
    value: "."
  }, {
    label: t("settings:layoutAdaptations.fields.split.opts.underscore"),
    value: "_"
  }];

  const closeWmCanvasEditor = () => {
    dispatch({ type: ADAPTATION.WM_CANVAS_EDITOR_TOGGLE, payload: false });
    setTimeout(() => 
      dispatch({ type:ADAPTATION.WM_CANVAS_EDITOR_DATA, payload: null })
    , 100);
  };

  const createAdaptation = async(layoutKey, config) => {
    try {
      let endpoint = "/setting/adaptation";

      if (layoutKey) {
        endpoint = `/setting/layout/${layoutKey}/adaptation`;
      }

      const { data } = await dispatch({
        [API]: {
          endpoint,
          reqType: "POST",
          types: [
            ADAPTATION.CREATE_STARTED,
            ADAPTATION.CREATE_SUCCESS,
            ADAPTATION.CREATE_FAIL
          ],
          data: {
            c: [config]
          },
          feedback: {
            error: true,
            success: true
          }
        }
      });
      toast.success(<Toast area={data[0].name} info={t("settings:layoutAdaptations.feedback.create.success")} />);
      return data;
    } catch (e) { }
  };

  const deleteWatermark = (layoutKey, adaptationId) => updateAdaptation(layoutKey, adaptationId, { adaptationLayerId: null });

  const fetchAdaptation = async (layoutKey) => {
    let params = {
      w: { status: ACTIVE }
    };
    let endpoint = "/setting/adaptation";

    if (layoutKey) {
      endpoint = `/setting/layout/${layoutKey}/adaptation`;
    }
    
    const { data } = await dispatch({
      [API]: {
        endpoint,
        reqType: "GET",
        types: [
          ADAPTATION.ON_LIST_STARTED,
          ADAPTATION.ON_LIST_SUCCESS,
          ADAPTATION.ON_LIST_FAIL
        ],
        params
      }
    });
    return data?.[0];
  };

  const fetchAdaptations = async () => {
    let response = await dispatch(find());
    return response?.data ?? [];
  };

  const fetchMasters = () => dispatch({
    [API]: {
      endpoint: "/setting/adaptation",
      reqType: "GET",
      params: { w: {"attributes.isMaster": true }},
      types: [
        ADAPTATION.MASTERS_STARTED,
        ADAPTATION.MASTERS_SUCCESS,
        ADAPTATION.MASTERS_FAIL
      ]
    }
  });

  const getAvailables = () => {
    if (typeof appAdaptations !== "object") return;

    let list = [];

    for (let adaptationName in appAdaptations) {
      let adaptationData = adaptationsAll.find(
        (item) => item._id === appAdaptations[adaptationName].refAdaptation
      );
      if (!adaptationData) continue;
      list = [
        ...list,
        {
          adaptationName,
          ...appAdaptations[adaptationName],
          ...adaptationData,
        },
      ];
    }

    setData(list);
  };
  
  const fetchListAdaptations = async () => {
    let response = await dispatch(
      find(
        {
          f: ["_id", "name", "attributes", "properties"],
        },
        {
          feedback: {
            success: false,
            error: true,
          },
        }
      )
    );

    setAdaptations(response?.data ?? []);    
    return response?.data ?? [];
  };

  const getKeyAdaptation = (value, field = "original") => {
    let key;
    for (let k of Object.keys(appAdaptations)) {
      if (appAdaptations[k][field] === value) {
        key = k;
        break;
      }
    }

    return key;
  };

  const openWmCanvasEditor = () => dispatch({ type: ADAPTATION.WM_CANVAS_EDITOR_TOGGLE, payload: true });

  const setWmCanvasEditor = (data) => dispatch({ type: ADAPTATION.WM_CANVAS_EDITOR_DATA, payload: data });

  const updateAdaptation = async (layoutKey, adaptationId, { 
    attributes, 
    derivedFrom,
    adaptationLayerId,
    properties,
    structureNameFile,
    structureNamePattern
  }, extras = {}) => {
    let data = {};
    try {
      if (structureNameFile) data.structureNameFile = structureNameFile;
      if (structureNamePattern) data.structureNamePattern = structureNamePattern;
      if (properties) data.properties = properties;
      if (attributes) data.attributes = attributes;
      if (derivedFrom) data.derivedFrom = derivedFrom;
      if (adaptationLayerId !== undefined) data.adaptationLayerId = adaptationLayerId;

      let response = await dispatch({
        [API]: {
          endpoint: `/setting/layout/${layoutKey}/adaptation`,
          reqType: "PUT",
          types: [
            ADAPTATION.PUT_STARTED,
            ADAPTATION.PUT_SUCCESS,
            ADAPTATION.PUT_FAIL
          ],
          ...extras,
          data: {
            c: [data],
            w: [adaptationId]
          }
        }
      });

      if (extras?.feedback?.success === undefined) {
        toast.success(<Toast area={response.data[0].name} info={t("settings:layoutAdaptations.feedback.update.success")} />);
      }
      return response;
    } catch (e) { }
  };

  useEffect(() => {
    if (adaptations) {
      getAvailables();
    }
  }, [adaptations, appAdaptations]);

  useEffect(() => {
    if (!adaptationsAll) return;
    setAdaptations(adaptationsAll ?? []);
  }, [adaptationsAll]);


  const onListReset = () => dispatch({type: ADAPTATION.ON_LIST_RESET});

  return {
    adaptations,
    bgTypeOpts,
    closeWmCanvasEditor,
    createAdaptation,
    data,
    deleteWatermark,
    fetchAdaptation,
    fetchAdaptations,
    fetchListAdaptations,
    fetchMasters,
    foldingOpts,
    formatOpts,
    getKeyAdaptation,
    mastersData,
    mastersError,
    mastersStatus,
    mimeTypeOpts,
    onList,
    onListReset,
    openWmCanvasEditor,
    renameTypeOpts,
    separatorOpts,
    setWmCanvasEditor,
    typeOpts,
    updateAdaptation,
    wmCanvasEditor
  };
};

export default useAdaptation;
