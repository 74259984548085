import { reduxActions as FILE } from "_constants/file.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialFileState from "../file.initialState";

const toSmartEditor = {
  [FILE.TO_SMART_EDITOR_STARTED]: (state) => ({
    ...state,
    smartEditor: {
      ...state.smartEditor,
      status: LOADING
    }
  }),
  [FILE.TO_SMART_EDITOR_FAIL]: (state, { payload }) => ({
    ...state,
    smartEditor: {
      ...state.smartEditor,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [FILE.TO_SMART_EDITOR_SUCCESS]: (state, { payload }) => ({
    ...state,
    smartEditor: {
      ...state.smartEditor,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
  [FILE.TO_SMART_EDITOR_RESET]: (state) => ({
    ...state,
    smartEditor: initialFileState.smartEditor
  })
};

export default toSmartEditor;
