import initialAttributeState from './attribute.initialState';
import add from './reducers/add';
import addGlobal from './reducers/addGlobal';
import all from './reducers/all';
import list from './reducers/list';
import moveIt from './reducers/moveIt';
import one from './reducers/one';
import post from './reducers/post';
import put from './reducers/put';
import toLayer from './reducers/toLayer';
import update from './reducers/update';
import updateGlobal from './reducers/updateGlobal';

const adaptationsReducer = (state = initialAttributeState, action) => {
    const reducers = {
      ...add,
      ...addGlobal,
      ...all,
      ...list,
      ...moveIt,
      ...one,
      ...post,
      ...put,
      ...toLayer,
      ...update,
      ...updateGlobal,
    };
    
    let { type } = action;
    if (reducers[type] === undefined) return state;
    
    return reducers[type](state, action);
};

export default adaptationsReducer;
