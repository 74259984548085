export const reduxActions =  {
  ALL_STARTED: "user/all_started",
  ALL_FAIL: "user/all_fail",
  ALL_SUCCESS: "user/all_success",
  ALL_RESET: "user/all_reset",

  FIND_STARTED: "user/find_started",
  FIND_FAIL: "user/find_fail",
  FIND_SUCCESS: "user/find_success",
  FIND_RESET: "user/find_reset",

  FORGOT_STARTED: "user/forgot_started",
  FORGOT_FAIL: "user/forgot_fail",
  FORGOT_SUCCESS: "user/forgot_success",
  FORGOT_RESET: "user/forgot_reset",

  INVITATION_STARTED: "user/invitation_started",
  INVITATION_FAIL: "user/invitation_fail",
  INVITATION_SUCCESS: "user/invitation_success",
  INVITATION_RESET: "user/invitation_reset",

  IS_LOGGED_IN_STARTED: "user/is_loggin_started",
  IS_LOGGED_IN_FAIL: "user/is_loggin_fail",
  IS_LOGGED_IN_SUCCESS: "user/is_loggin_success",

  LOGIN_STARTED: "user/login_started",
  LOGIN_FAIL: "user/login_fail",
  LOGIN_SUCCESS: "user/login_success",

  LOGOUT_STARTED: "user/logout_started",
  LOGOUT_FAIL: "user/logout_fail",
  LOGOUT_SUCCESS: "user/logout_success",

  POST_STARTED: "user/post_started",
  POST_FAIL: "user/post_fail",
  POST_SUCCESS: "user/post_success",
  POST_RESET: "user/post_reset",

  PUT_STARTED: "user/put_started",
  PUT_FAIL: "user/put_fail",
  PUT_SUCCESS: "user/put_success",
  PUT_RESET: "user/put_reset",

  RESTORE_STARTED: "user/restore_started",
  RESTORE_FAIL: "user/restore_fail",
  RESTORE_SUCCESS: "user/restore_success",
  RESTORE_RESET: "user/restore_reset"
};

export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const SUSPENDED = "suspended";

export const ADMIN = "admin_app";
export const COMMERCIAL = "commercial_user";
