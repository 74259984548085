import i18n from "_config/lang/i18n";
import { 
  ACTIVE, 
  ARCHIVED, 
  reduxActions as CATALOG, 
  TYPE_CATALOG
} from "_constants/catalog.constants";

export const remove = (ids, extras = {}) => (dispatch) => {
  if (!Array.isArray(ids)) ids = [ids];

  return dispatch({
    NBSK: {
      endpoint: "/folder",
      ...extras,
      reqType: "delete",
      data: {
        w: ids
      }
    }
  })
};

/**
 *
 * @param {Object} params
 * @param {Array} types
 * @param {boolean | object} extend
 * @returns
 */
export const find =
  (params, orderBy = "createdAt", extras = {}) =>
  async (dispatch) => {
    if (extras.types === undefined) {
      extras.types = [CATALOG.ALL_STARTED, CATALOG.ALL_SUCCESS, CATALOG.ALL_FAIL];
    }

    return dispatch({
      NBSK: {
        endpoint: "/folder",            
        params: {
          a: {
            ...params?.a ?? {}
          },
          f: [...(params?.f ?? [])],
          w: {
            status: ACTIVE,
            ...params?.w ?? {},
            type: TYPE_CATALOG,
          }
        },
        feedback: {
          success: false,
        },
        ...extras
      }
    });
  };

export const findOne = (value, key = "_id", params = {}) => (dispatch) => {
  params = {
    ...params,
    w: {
      ...params?.w ?? {},
      [key]: value
    }
  };

  return dispatch(find(params, "createdAt", {
    types: [CATALOG.ONE_STARTED, CATALOG.ONE_SUCCESS, CATALOG.ONE_FAIL]
  }))
};

export const fetchCatalogDetails = (id) => (dispatch) => dispatch(
  findOne(id, "_id", {
    f: [
      "createdAt",
      "data",
      "foldersId",
      "key",
      "resourcesId",
      "share",
      "status",
      "type",
      "updatedAt",
      "userId"
    ],
    w: {
      status: [ARCHIVED, ACTIVE]
    },
    a: {
      extended: {
        f: {
          userId: ["email"],
          resourcesId: ["_id", "data", "step", "createdAt", "type", "process", "progress"]
        },
      }
    }
  })
);


/**
 *
 * @param {String} key
 * @param {String} description
 * @param {Array} foldersId
 * @returns
 */
export const post = (data, extras = {}) => 
  async (dispatch) => {
    if (!extras?.types) {
      extras.types= [CATALOG.POST_STARTED, CATALOG.POST_SUCCESS, CATALOG.POST_FAIL];
    }

    let response = await dispatch({
      NBSK: {
        types: [CATALOG.POST_STARTED, CATALOG.POST_SUCCESS, CATALOG.POST_FAIL],
        endpoint: "/folder",
        reqType: "POST",
        data: {
          c: [data]
        },
        ...extras
      }
    });
    
    return response;
  };

export const newCatalog = ({ 
  key,  
  description, 
  foldersId 
}) => async (dispatch) => await dispatch(post({
    type: TYPE_CATALOG,
    key,
    data: { description },
    foldersId: foldersId || []
  }, {}));

/**
 *
 * @param {String | Array} id
 * @param {Array} data
 * @param {Array} extras
 * @returns
 */
export const put = (ids, data, extras = {}) => {
  return async (dispatch) => {
    if (!extras.types) {
      extras.types = [CATALOG.PUT_STARTED, CATALOG.PUT_SUCCESS, CATALOG.PUT_FAIL];
    }

    await dispatch({
      NBSK: {
        endpoint: `/folder`,
        reqType: "PUT",
        data: {
          c: data,
          w: Array.isArray(ids) ? ids : [ids]
        },
        ...extras
      }
    });
  };
};

/**
 * 
 * @param {String} id 
 * @param {Object} {} 
 * @param {Object} extras 
 * @returns 
 */
export const addProducts = (id, { foldersId, name }, extras = {}) => (dispatch) => {
  let data = [{ foldersId }];

  extras = {
    types: [CATALOG.ADD_ITEMS_STARTED, CATALOG.ADD_ITEMS_SUCCESS, CATALOG.ADD_ITEMS_FAIL],
    feedback: {
      success: {
        area: "catalogs:table.add_to_catalog.feedback.area",
        info: `${i18n.t("catalogs:table.add_to_catalog.feedback.success", {
          catalog: name,
        })}`,
      },
      error: true
    },
    ...extras
  };

  return dispatch(put(id, data, extras));
};

/**
 * 
 * @param { String } catalogId 
 * @param { Array | String } productId 
 * @param { Object } extras 
 * @returns 
 */
export const removeFromCatalog = (catalogId, productId, extras = {}) => (dispatch) => 
  dispatch({
    NBSK: {
      endpoint: "/folder",
      ...extras,
      reqType: "PUT",
      data: {
        c: [{
          foldersId: (Array.isArray(productId) ? productId : [productId])
        }],
        w: (Array.isArray(catalogId) ? catalogId : [catalogId]),
        $config: {
          action: "remove"
        }
      },
      types: [
        CATALOG.REMOVE_PRODUCTS_STARTED,
        CATALOG.REMOVE_PRODUCTS_SUCCESS,
        CATALOG.REMOVE_PRODUCTS_FAIL
      ]
    }
  });

export const addResource = (resource) => (dispatch) => 
  dispatch({type: CATALOG.ADD_RESOURCE, payload: resource});

export const removeResource = (resourceId) => (dispatch) => {
  dispatch({type: CATALOG.REMOVE_RESOURCE, payload: resourceId});
};

export const toArchived = (id) => (dispatch) => 
  dispatch(put(id, [{
    status: ARCHIVED
  }], {
    types: [CATALOG.TO_ARCHIVED_STARTED, CATALOG.TO_ARCHIVED_SUCCESS, CATALOG.TO_ARCHIVED_FAIL]
  }));


export const toTrash = (id) => (dispatch) => 
  dispatch(remove(id, {
    types: [CATALOG.TO_TRASH_STARTED, CATALOG.TO_TRASH_SUCCESS, CATALOG.TO_TRASH_FAIL]
  }));

export const orderCatalogs = (orderBy) => (dispatch) => 
  dispatch({ type: CATALOG.ORDER_BY, payload: orderBy });

export const share =  (id, userIds, updateUsers = false) => async(dispatch, getState) => {
  let response = await dispatch(put(id, [{ share: userIds }], {
    endpoint: "/folder/share",
    types: [CATALOG.SHARE_STARTED, CATALOG.SHARE_SUCCESS, CATALOG.SHARE_FAIL],
    feedback: {
      success: {
        area: "catalogs:table.share.feedback.area",
        info: "catalogs:table.share.feedback.success",
      },
      error: {
        area: "catalogs:table.share.feedback.area",
        info: "catalogs:table.share.feedback.error",
      }
    }
  }));

  if (updateUsers)  {
    let newUsers = userIds?.map((r) => {
      return getState()?.user.all.data?.filter((doc) => doc._id === r)?.[0] ?? false
    }) ?? [];

    dispatch(shareUpdate(newUsers));
  }
  return response;
}

export const shareUpdate = (data) => (dispatch) => 
  dispatch({ type: CATALOG.SHARE_UPDATE, payload: data });

export const allReset = () => (dispatch) =>
  dispatch({ type: CATALOG.ALL_RESET });

export const oneReset = () => (dispatch) =>
  dispatch({ type: CATALOG.ONE_RESET });

export const putReset = () => (dispatch) => 
  dispatch({type: CATALOG.PUT_RESET});

export const postReset = () => (dispatch) => 
  dispatch({type: CATALOG.POST_RESET});

export const removeProductsReset = () => (dispatch) => 
  dispatch({type: CATALOG.REMOVE_PRODUCTS_RESET});

export const catalogReset = () => (dispatch) => {
  dispatch(allReset());
  dispatch(oneReset());
  dispatch(postReset());
  dispatch(putReset());
};
