import { reduxActions as ADAPTATION } from '_constants/adaptation.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialAdaptationState from '../adaptation.initialState';

const masters = {
  [ADAPTATION.MASTERS_STARTED]: (state) => ({
    ...state,
    masters: {
      ...state.masters,
      status: LOADING
    }
  }),
    
  [ADAPTATION.MASTERS_FAIL]: (state, { payload }) => ({
    ...state,
    masters: {
      ...state.masters,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [ADAPTATION.MASTERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    masters: {
      ...state.masters,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),
    
  [ADAPTATION.MASTERS_RESET]: (state) => ({
    ...state,
    masters: initialAdaptationState.masters
  })
};
      
export default masters;
