import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { DropDown, FlexComponent } from "components/templates";
import { Badge, NubeskIcons } from "components/atoms";
import ActiveCard from "./ActiveCard";
import { postColumnKey, putPropertyColumns } from "_redux/app/app.actions";

const prefix = "m-property-card";

const PropertyCard = ({ data, editable, pKey, name, ...props }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [active, setActive] = useState(false);
  const [propertyOptions, setPropertyOptions] = useState({});
  const [_type, setType] = useState("Text");
  const classForComponent = classNames(prefix, {
    [`${prefix}--active`]: active,
  });

  const onSave = (newData) => {
    let objectData = { ...data, ...newData };

    if (pKey) {
      return dispatch(postColumnKey(name));
    } else {
      return dispatch(putPropertyColumns(name, objectData));
    }
  };

  useEffect(() => {
    if (data) {
      let { require, toFilter, overwritable, toExport } = data;

      setPropertyOptions({ require, overwritable, toFilter, toExport, editable });
      if (data.enum) {
        setType("Select");
      }
    }
  }, [data]);

  return (
    <div className={classForComponent}>
      <div
        className={`${prefix}__content`}
        onDoubleClick={!active ? () => setActive(pKey ? false : true) : null}
        draggable
        {...props}
      >
        {active ? (
          <ActiveCard
            setActive={setActive}
            data={data}
            editable={editable}
            propertyOptions={propertyOptions}
            name={name}
            options={data?.enum}
            type={data?.enum ? "Select" : "Text"}
            _type={_type}
            setType={setType}
            onSave={(newData) => onSave(newData)}
          />
        ) : (
          <>
            <div className={`${prefix}__content-header`}>
              <FlexComponent>
                <span className={`${prefix}__content-title`}>
                  {data?.original}
                </span>
                <FlexComponent gap={12}>
                  {Object.keys(propertyOptions).map((key) => {
                    if (propertyOptions[key]) {
                      return (
                        <React.Fragment key={key}>
                          <span className={`${prefix}__content-ct`}>
                            {t(`settings:dataFields.labels.${key}`)}
                          </span>
                          <span className={`${prefix}__division`}>|</span>
                        </React.Fragment>
                      );
                    }
                    return null;
                  })}
                </FlexComponent>
              </FlexComponent>
              <FlexComponent gap={4}>
                {pKey && <Badge content={"P. Key"} variant={"purple"} />}
                <DropDown
                  options={[]}
                  buttonProps={{
                    content: <NubeskIcons icon="dots" size={20} />,
                    type: "color",
                    sm: true,
                  }}
                />
              </FlexComponent>
            </div>
            <div className={`${prefix}__content-footer`}>
              <p className={`${prefix}__content-description`}>
                {t("settings:dataFields.withoutDesc")}
              </p>
              <span className={`${prefix}__content-type`}>{_type}</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default PropertyCard;
