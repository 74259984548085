import qs from 'qs';
import jsonUrl from "json-url";

const codec = jsonUrl("lzma");

const jsonToUrl = async (json, noCompress) => { 
  if (json === false) return '';
  if (typeof json !== 'object') {
    throw new Error("Is not a object variable");
  }
  
  let compress = await codec.compress(json);
  let params = { _: compress };

  if (noCompress) {
    params = {
      ...noCompress,
      ...params
    };
  }
  return qs.stringify(params, {
    arrayFormat : 'brackets',
    encode: true
  });
};

export default jsonToUrl;
