import { IDLE } from '_constants/redux.constants';

const initialFileState = {
  aiBgGenerate: {
    data: null,
    error: null,
    status: IDLE
  },
  all: {
    data: null,
    error: null,
    status: IDLE
  },
  one: {
    data: null,
    error: null,
    status: IDLE
  },
  put: {
    data: null,
    error: null,
    status: IDLE
  },
  post: {
    data: null,
    error: null,
    status: IDLE
  },
  remove: {
    data: null,
    error: null,
    status: IDLE
  },
  removeBg: {
    data: null,
    error: null,
    status: IDLE
  },
  saveEdition: {
    data: null,
    error: null,
    status: IDLE
  },
  swatch: {
    data: null,
    error: null,
    status: IDLE
  }
};

export default initialFileState;
