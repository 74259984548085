import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Button, NubeskIcons } from "components/atoms";
import { FlexComponent } from "components/templates";
import { useTooltip } from "_hooks";
import { openModal } from "_redux/ui/ui.actions";
import { selectUser } from "_redux/user/user.selectors";
import { $$ADAPTATION } from "_constants/permission.constants";

const SalesChannelToolbar = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();
  const { injectedProps } = useTooltip({
    variant: "gradient",
    externalTip: "Soon",
    delay: 150,
  });

  const user = useSelector(selectUser);

  return (
    <>
      <FlexComponent>
        {user.canDoIt($$ADAPTATION.CREATE) &&
          <Button
            leftIcon={<NubeskIcons icon="plus" />}
            content={t("settings:actions.newChannel")}
            type={"primary"}
            onClick={() =>
              dispatch(
                openModal({
                  header: { text: t("settings:actions.newChannel"), icon: {type: "plus", size: 20} },
                  typeModal: "newChannel",
                  modalProps: {
                    onSave: (d) => history.push(`/settings/sales-channels/${d.key}/adaptations`)
                  }
                })
              )
            }
          />
        }
      </FlexComponent>
      <FlexComponent>
        {user.canDoIt($$ADAPTATION.CREATE) &&
          <div {...injectedProps} style={{ display: "flex" }}>
            <Button
              type="gray"
              content={"Download template"}
              onClick={() => {}}
              disabled
              style={{ marginRight: 10 }}
            />
            <Button
              type="gray"
              content={"Import from file"}
              onClick={() => {}}
              disabled
            />{" "}
          </div>
        }
      </FlexComponent>
    </>
  );
};

export default SalesChannelToolbar;
