import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { Accordion } from "components/organism";
import { NubeskIcons } from "components/atoms/index";

const AssociatedSections = ({ onAssociate, prefix, selection }) => {
  const { t } = useTranslation();

  const [associates, setAssociates] = useState();

  const checkGroups = () => {
    let list = [];
    
    if (Array.isArray(selection)) {
      for (let o of selection) {
        if (o?.nbskProperties?.cluster && !list.includes(o?.nbskProperties?.cluster)) {
          list.push(o?.nbskProperties?.cluster);
        }
      }
    }
    setAssociates(list);
  };

  useEffect(() => {
    checkGroups();
  }, [selection]);

  return (
    <Accordion 
      content={
        <div className={`${prefix}__associates`}>
          <div>
            ({ selection?.length ?? 0 }) {t("nbsk:editor.shapes.selectedShapes")} 
          </div>

          <div>
            {!!associates?.length &&
              <Button
                content={<NubeskIcons icon="ungroup" className="u-red"/>}
                sm
                onClick={() => {
                  onAssociate({ cluster: null });
                  checkGroups();
                }}
                type="delete"
              />
            }
            
            {(associates?.length === 0 || associates?.length > 1) &&
              <Button
                leftIcon={<NubeskIcons icon="group" />}
                content={t("nbsk:editor.shapes.associate")}
                disabled={ !selection?.length || associates?.lenght >= 1 }
                onClick={() => {
                  onAssociate({ cluster: Date.now().toString(16) });
                  checkGroups();
                }}
                sm
                type="primary"
              />
            }
            
          </div>
        </div>
      }
      isOpen={false}
      headerRight={null}
      title={t("nbsk:editor.shapes.associated")}
    />
  );
};

export default AssociatedSections;
