import PropTypes from "prop-types";
import classNames from "classnames";
import { NubeskIcons } from "components/atoms";
import { Card } from "components/organism";
import { FlexComponent } from "components/templates";

const prefix = "o-billing-card";

const BillingCard = ({ bottom, content, highlight, icon, title }) => {

  const classForHeader = classNames(`${prefix}__header`, {
    [`${prefix}__highlight`]: highlight === "top"
  });

  const classForBottom = classNames(`${prefix}__bottom`, {
    [`${prefix}__highlight`]: highlight === "bottom"
  });

  return (
    <Card 
      className={prefix}
      header={
        <div className={classForHeader}>
          <FlexComponent variant="column">
            <div>{title}</div>
          </FlexComponent>
          <FlexComponent variant="column" className={`${prefix}__header-icon`}>
            <NubeskIcons icon={icon} />
          </FlexComponent>
        </div>
      }  
    >
      <div className={`${prefix}__content`}>{content}</div>
      {bottom && <div className={classForBottom}>{bottom}</div>}
    </Card>
  );
};

BillingCard.propTypes = {
  highlight: PropTypes.oneOf(["top", "bottom", null])
};

BillingCard.defaultProps = {
  highlight: null
};

export default BillingCard;
