const prefix = "m-empty-results";

const EmptyResults = ({content}) => {
  return (
    <div className={prefix}>
      <span>{content}</span>
    </div>
  );
};

export default EmptyResults;
