import initialFileState from './file.initialState';
import aiBgGenerate from './reducers/aiBgGenerate';
import post from './reducers/post';
import put from './reducers/put';
import remove from './reducers/remove';
import removeBg from './reducers/removeBg';
import saveEdition from './reducers/saveEdition';
import toSmartEditor from './reducers/toSmartEditor';

const reducer = (state = initialFileState, action) => {
  const reducers = {
    ...aiBgGenerate,
    ...post,
    ...put,
    ...remove,
    ...removeBg,
    ...saveEdition,
    ...toSmartEditor
  };
  
  let { type } = action;
  if (reducers[type] === undefined) return state;
  
  return reducers[type](state, action);
};

export default reducer;
