const dateFormat = (date, format = "") => {
  if (date === undefined) return date;
  let localDate = new Date(date);
  return localDate.toLocaleDateString('en-US',{
    year: "2-digit",
    day: "2-digit",
    month: "2-digit",
  });
};

export default dateFormat;
