import { useTranslation } from "react-i18next";
import useModal from "./useModal";
import useUser from "./useUser";

const useQuota = () => {
  const { openModal } = useModal();
  const { user } = useUser();
  const { t } = useTranslation();

  const isOkRemoval = () => {
    let output = true;
    if (user?.__analytics?.backremovals?.billingPlan?.limit !== undefined && 
      user?.__analytics?.backremovals?.byAccount?.count && 
      user.__analytics.backremovals.billingPlan.limit <= user.__analytics.backremovals.byAccount.count
    ) {
      output = false;
    }

    return output;
  };

  const showExceededRemoval = () => {
    openModal({
      typeModal: "removalQuota",
      header: { text: t("quotas:removal.modal.title") },
    })
  };

  return {
    isOkRemoval,
    showExceededRemoval
  }
};

export default useQuota;
