import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { UploadDrop } from "components/organism";
import { filterKeys } from "_redux/reader/reader.actions";

const FilterDrop = ({ onChange, setSelectedSwitch, ...props }) => {
  const dispatch = useDispatch();

  const [file, setFile] = useState([]);

  const fetchFilter = async (file) => {
    let response = await dispatch(filterKeys(file));

    if (response?.data?.[0]) {
      let { found, notFound } = response?.data?.[0];
      onChange({ found, notFound });
    }
  };

  useEffect(() => {
    if (file?.[0]) fetchFilter(file?.[0]);
  }, [file]);

  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        setSelectedSwitch("paste");
      }}
      style={{height: "100%"}}
    >
      <UploadDrop
        file={file}
        setFile={setFile}
        label={"products:filters.uploadFileLegend"}
        dropProps={{
          accept:
            "text/csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv",
          maxFiles: 1,
          noClick: true,
          disabled: true
        }}
      />
    </div>
  );
};

export default FilterDrop;
