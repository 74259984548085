import { createSelector } from "reselect";

const selectAnalysisStore = (state) => state.analysis;

export const selectAnalysisRequest = createSelector(
  selectAnalysisStore,
  (analysis) => analysis.request
);

export const selectAnalysisaAdaptationBulk = createSelector(
  selectAnalysisStore,
  (analysis) => analysis.adaptationBulk
);

export const selectAnalysisApprovedBulk = createSelector(
  selectAnalysisStore,
  (analysis) => analysis.approvedBulk
);

export const selectAnalysisLayoutCopy = createSelector(
  selectAnalysisStore,
  (analysis) => analysis.layoutCopy
);

export const selectAnalysisRemoveBgBulk = createSelector(
  selectAnalysisStore,
  (analysis) => analysis.removeBgBulk
);


export default selectAnalysisStore;
