import { ACTIVE, reduxActions as MEDIA_LIB } from "_constants/mediaLib.constants";
import { NBSK_API } from "_constants/request.constants";

export const get = (params, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      MEDIA_LIB.ALL_STARTED,
      MEDIA_LIB.ALL_SUCCESS,
      MEDIA_LIB.ALL_FAIL
    ];
  }

  return dispatch({
    [NBSK_API]: {
      endpoint: "/library/image",
      reqType: "GET",
      ...extras,
      params: {
        ...params,
        w: params?.w ?? { status: ACTIVE }
      }
    }
  });
};

export const post = (data, extras = {}) => (dispatch) => {
  if (!extras?.types) {
    extras.types = [
      MEDIA_LIB.POST_STARTED,
      MEDIA_LIB.POST_SUCCESS,
      MEDIA_LIB.POST_FAIL
    ];
  }

  return dispatch({
    [NBSK_API]: {
      endpoint: "/library/image",
      reqType: "POST",
      ...extras,
      data
    }
  });
};

export const remove = (ids, extras = {}) => {
  if (!extras?.types) {
    extras.types = [
      MEDIA_LIB.DELETE_STARTED,
      MEDIA_LIB.DELETE_SUCCESS,
      MEDIA_LIB.DELETE_FAIL
    ];
  }

  return post({
    _id: Array.isArray(ids) ? ids : [ids]
  }, extras);
};

export default {
  get,
  post,
  remove
};
