import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button } from "components/atoms";
import { IconDrawer, Semaphore } from "components/molecules";
import { closeModal } from "_redux/ui/ui.actions";
import { deleteZip, findOne } from "_redux/resource/resource.actions";
import { 
  GENERATED_MISSING_ADAPT,
  GENERATED_MISSING_KEYS,
  GENERATED_MISSING_NOBG,
  PACKAGING,
  PROCESSING
} from "_constants/resource.constants";
import { addResource } from "_redux/catalog/catalog.actions";

const prefix = "o-resource-progress";
const steps = [
  PROCESSING,
  GENERATED_MISSING_KEYS,
  GENERATED_MISSING_NOBG,
  GENERATED_MISSING_ADAPT,
  PACKAGING
];

const ResourceProgress = ({ resource }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [progress, setProgress] = useState({});

  const fetchCount = async () => { 
    switch(resource?.step) {
      case GENERATED_MISSING_NOBG:
        setProgress({
          completed: resource?.progress?.generatingMissingNobg?.completed ?? 0,
          total: resource?.progress?.generatingMissingNobg?.totals ?? 0
        });
        break;
        
      case GENERATED_MISSING_ADAPT:
        setProgress({
          completed: resource?.progress?.generatingMissingAdapt?.completed ?? 0,
          total: resource?.progress?.generatingMissingAdapt?.totals
        });
        break;

      case GENERATED_MISSING_KEYS:
      case PACKAGING:
      case PROCESSING:
      default:
        setProgress({});
        break;
    };
  };

  useEffect(() => {
    let interval = setInterval(async () => {
      await dispatch(findOne({ w: { _id: resource._id }}));
    }, 10000);

    return () => {
      clearInterval(interval);
    }
  }, []);

  useEffect(() => {
    if (resource) {
      dispatch(addResource(resource));
    
      fetchCount();
    }
  }, [resource])

  return(
    <div className={prefix}>
      <div className={`${prefix}__info`}>
        <div className={`${prefix}__animation`}>
          <IconDrawer
            icon="cut"
            iconProps={{fontSize: "3rem"}}
          />
        </div>
        <div className={`${prefix}__data`}>
          <div className={`${prefix}__state`}>
            {t(`catalogs:links.states.${resource.step}`)}...
          </div>
          <div className={`${prefix}__counter`}>
            <div>
              {progress.total && `${Math.trunc(progress.completed / progress.total * 100)}%` }
            </div>
            <div>
              {progress.completed && progress.total && (
                <>
                  {progress.completed || "-"}/{progress.total || "-"}
                </>
              )}
            </div>
          </div>
          <Semaphore current={steps.indexOf(resource.step) + 1} steps={steps.length} />
        </div>
      </div>
      <div className={`${prefix}__buttons`}>
        <Button 
          content={t("common:form.cancel")}
          onClick={() => {
            dispatch(deleteZip(resource._id));
            dispatch(closeModal());
          }}
          type="underline"
        />
        <Button 
          content={t("catalogs:links.closeAndNotify")} 
          onClick={() => dispatch(closeModal())}
          sm
          type="purple"
        />
      </div>
    </div>
  );
};

export default ResourceProgress;
