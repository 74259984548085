import initialTaskState from "./task.initialState";
import all from "./reducers/all";
import items from "./reducers/items";
import list from "./reducers/list";
import one from "./reducers/one";
import post from "./reducers/post";
import put from "./reducers/put";
import removeFrom from "./reducers/removeFrom";
import selection from "./reducers/selection";

const reducer = (state = initialTaskState, action) => {
  const reducers = {
    ...all,
    ...items,
    ...list,
    ...one,
    ...post,
    ...put,
    ...removeFrom,
    ...selection
  }
  let { type } = action;
  
  if (reducers[type] === undefined) return state;

  return reducers[type](state, action);
};

export default reducer;
