import { reduxActions as LAYOUT} from "_constants/layout.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";
import initialLayoutState from "../layout.initialState";

const all = {
  [LAYOUT.ALL_STARTED]: (state) => ({
    ...state,
    all: {
      ...state.all,
      status: LOADING
    }
  }),
  
  [LAYOUT.ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: payload?.data?.sort((a, b) => (
        a.createdAt > b.createdAt ? -1 : 1
      )) ?? null,
      error: null,
      status: SUCCESS
    }
  }),
  
  [LAYOUT.ALL_FAIL]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [LAYOUT.ALL_RESET]: (state) => ({
    ...state,
    all: initialLayoutState.all
  })
};

export default all;
