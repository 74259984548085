import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { Button, NubeskIcons } from "components/atoms";
import { Catalog, CatalogsList, NewSkuPanel } from "components/organism";
import { TableCatalogs } from "components/molecules";
import nbskReducer from "_reducers/nbskReducer";
import { closePanelLeft } from "_redux/ui/ui.actions";
import selectUiStore from "_redux/ui/ui.selector";
import { selectProductAllStore } from "_redux/product/product.selector";
import { selectCatalogOneStore } from "_redux/catalog/catalog.selector";
import { LOADING } from "_constants/redux.constants";
import { LEFT_PANEL } from "_constants/reducers.constants";

const prefix = "t-panel-left";

const panelManager = {
  "new-sku": NewSkuPanel,
  "my-catalogs": TableCatalogs,
  "catalogs": CatalogsList,
  "catalog": Catalog,
};

const PanelLeft = () => {
  const {
    leftPanel: { isOpen, typePanel: currentPanel, panelProps },
    previous
  } = useSelector(selectUiStore);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  
  const classForComponent = classNames(prefix, {
    [`${prefix}--${currentPanel}`]: currentPanel,
  });

  const { status: productStatus } = useSelector(selectProductAllStore);
  const { status: catalogStatus } = useSelector(selectCatalogOneStore)

  if (!currentPanel) return false;

  let renderedPanel;

  if (typeof panelManager[currentPanel] !== "undefined") {
    const PanelComponent = panelManager[currentPanel];
    renderedPanel = <PanelComponent {...panelProps} />;
  }

  return (
    <div className={classForComponent}>
      {isOpen ? (
        <>
          <div className={`${prefix}__content`}>
            <Button
              content={<NubeskIcons icon="times" />}
              disabled={productStatus === LOADING || catalogStatus === LOADING}
              type="color"
              className={`${prefix}__button`}
              sqr
              onClick={() => nbskReducer(LEFT_PANEL.CLOSE, {
                action: () => dispatch(closePanelLeft()),
                dispatch,
                history,
                location,
                previous
              })}
            />
            {isOpen && renderedPanel}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default PanelLeft;
//Marca genero, categoria
