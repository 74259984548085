import initialAdaptationState from './adaptation/adaptation.initialState';
import initialAnalysisState from './analysis/analysis.initialState';
import initialAppState from './app/app.initialState';
import initialAttributeState from './attribute/attribute.initialState';
import initialBillingState from './billing/billing.initialState';
import initialCatalogState from "./catalog/catalog.initialState";
import initialFileState from './file/file.initialState';
import initialFiltersState from './filters/filters.initialState';
import initialLayerState from './layer/layer.initialState';
import initialLayoutState from './layout/layout.initialState';
import initialMediaLibState from './mediaLib/mediaLib.initialState';
import initialProductState from './product/product.initialState';
import initialProfileState from './profile/profile.initialState';
import initialReaderState from './reader/reader.initialState';
import initialResourceState from './resource/resource.initialState';
import initialStatisticsState from './statistics/statistics.initialState';
import initialTaskState from './task/task.initialState';
import initialUIState from './ui/ui.initialState';
import initialUserState from './user/user.initialState';

import initialSyncfoniaState from "module/Syncfonia/store/syncfonia.initialState";

const initialStateRedux = {
  adaptation: initialAdaptationState,
  analysis: initialAnalysisState,
  app: initialAppState,
  attribute: initialAttributeState,
  billing: initialBillingState,
  catalog: initialCatalogState,
  file: initialFileState,
  filters: initialFiltersState,
  layer: initialLayerState,
  layout: initialLayoutState,
  mediaLib: initialMediaLibState,
  module: {
    syncfonia: initialSyncfoniaState,
  },
  product: initialProductState,
  profile: initialProfileState,
  reader: initialReaderState,
  resource: initialResourceState,
  statistics: initialStatisticsState,
  task: initialTaskState,
  ui: initialUIState,
  user: initialUserState
};

export default initialStateRedux;
