import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { matchPath, useHistory, useLocation } from "react-router-dom";
import { Button, NubeskIcons, Title } from "components/atoms";
import { useAttribute, useChannelLayout, useModal } from "_hooks";
import { alphabetColumn } from "_utils/array";
import { GLOBAL } from "_constants/layout.constants";

const prefix = "o-channel-attributes-toolbar";

const ChannelAttributesToolbar = () => {
  const { allData, downloadTemplate } = useAttribute();
  const history = useHistory();
  const location = useLocation();
  const { openModal } = useModal();
  const { layoutData, layoutReset } = useChannelLayout();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [isGlobal, setIsGlobal] = useState();

  useEffect(() => {
    let match = matchPath( pathname, {
      path: "/settings/sales-channels/:layoutKey/attributes",
      exact: true,
      strict: true
    })

    if (match?.params?.layoutKey === GLOBAL) setIsGlobal(true);
    else setIsGlobal(false);
  }, [location])

  return(
    <div className={prefix}>
      <div>
        {isGlobal ? 
          <Button
            content={t("settings:layoutAttributes.actions.newGlobalAttribute")}
            leftIcon={<NubeskIcons icon="plus" />}
            type="primary"
            style={{ width: "max-content" }}
            onClick={() => openModal({
              header: {
                icon: {
                  size: "22px",
                  type: "settings"
                },
                text: t("settings:layoutAttributes.modal.globalTitle")
              },
              typeModal: "globalAttribute",
              modalProps: { }
            })}
          />
        :
          <Button
            content={t("settings:layoutAttributes.actions.backTo")}
            leftIcon={<NubeskIcons icon="arrowLeft" />}
            type={"color"}
            style={{ width: "max-content" }}
            onClick={() => {
              layoutReset();
              history.push("/settings/sales-channels")
            }}
          />
        }
        <Title
          className="u-purple"
          content={
            <>
              <NubeskIcons 
                icon={isGlobal ? "globe" : "settings"}
              />  {isGlobal ? "Global" : layoutData?.name} | {t("settings:layoutAttributes.title")}
            </>
          }
          fontSize={18}
        />
      </div>
      <div>
        <Button
          content={t("settings:layoutAttributes.actions.downloadTemplate")}
          leftIcon={<NubeskIcons icon="download" />}
          type="primary"
          style={{ width: "max-content" }}
          onClick={async() => downloadTemplate(isGlobal ? null : layoutData.key)}
        />
        
        {!isGlobal &&
          <Button
            content={t("settings:layoutAttributes.actions.newAttribute")}
            disabled={!layoutData}
            leftIcon={<NubeskIcons icon="plus" />}
            type="primary"
            style={{ width: "max-content" }}
            onClick={() => openModal({
              header: {
                icon: {
                  size: "22px",
                  type: "settings"
                },
                text: t("settings:layoutAttributes.modal.title", { name: layoutData.name })
              },
              typeModal: "attributeForm",
              modalProps: {
                layoutKey: layoutData?.key,
                layoutName: layoutData?.name,
                colLetter: alphabetColumn(allData?.length + 1)
              }
            })}
          />
        }
      </div>
    </div>
  );
};

export default ChannelAttributesToolbar;
