import { reduxActions as UI } from "_constants/ui.constants";

const selection = {
  [UI.ADD_SELECTION]: (state, { payload }) => {
    if (state.selections?.selected?.[payload]) {
      delete state.selections?.selected[payload];
      return {
        ...state,
        selections: {
          ...state.selections,
          qty: (state.selections.qty || 0) - 1,
        },
      };
    }
    return {
      ...state,
      selections: {
        ...state.selections,
        selected: {
          ...state.selections.selected,
          [payload]: payload,
        },
        qty: (state.selections.qty || 0) + 1,
      },
    };
  },
  [UI.TOGGLE_SELECTION]: (state, { payload }) => ({
    ...state,
    selections: {
      all: !state.selections.all,
      selected: {},
    },
  }),
  [UI.RESET_SELECTION]: (state) => ({
    ...state,
    selections: {
      all: false,
      selected: {},
    },
  }),
};

export default selection;
