import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FlexComponent } from "components/templates";
import { SquareEdit } from "components/molecules";
import { InputTest, Select } from "components/molecules/Inputs";
import { Button, Logo, NubeskIcons, Switch } from "components/atoms";
import { useForm, useLayer, useUser } from "_hooks";
import { $LAYER_EDIT } from "_constants/permission.constants";
import { AI, AI_INTEGRATED, HERO, ISOMETRIC, SHOW_TYPES, TEMPLATE_TYPES } from "_constants/layer.constants";
import { FormControl } from "../index";
import { Input } from "components/atoms/index";

const prefix = "t-layer-card";

const LayerCard = ({ layer: { _id, key, name, thumbnail = null, property, template, type, ...layer }}) => {

  const { aiImageTypeOpts, aiImageStyleOpts, setCanvasEditor, openCanvasEditor, updateLayer } = useLayer();
  const { t } = useTranslation();
  const { user } = useUser();

  const inputRef = useRef();

  const [enableEdition, setEnableEdition] = useState(false);
  const [horizontal, setHorizontal] = useState();
  const [layerForm] = useState({
    height: property?.canvas?.height,
    width: property?.canvas?.width,
    aiImageStyle: type === AI ? aiImageStyleOpts.filter((a) => {
      if (layer?.data?.style === a.value) return true;

      return false;
    }).pop() : undefined,
    aiImageType: layer?.data?.style ? 
      aiImageTypeOpts[layer?.data?.mask ? 1: 0] : undefined,
    aiKeywords: type === AI ? layer?.data?.keywords : undefined
  });
  const [selectedPreview, setSelectedPreview] = useState(1);
  const [square, setSquare] = useState();
  const [vertical] = useState(true);

  const { form, changeHandler } = useForm({
    schemaType: "putLayer",
    formInitialState: layerForm
  });

  const save = async (feedback = true, callback = null) => {
    let { height, width, aiImageStyle, aiKeywords, aiImageType } = form;
    let data = {
      template: {
        landscape: horizontal ? template.landscape : null,
        portrait: vertical ? template.portrait : null,
        square: square ? template.square : null
      },
      property: {
        canvas: {
          height: parseInt(height),
          width: parseInt(width)
        }
      }
    };
    
    if (type === AI) {
      data.data = {
        ...layer.data ?? {},
        mask: aiImageType?.value === AI_INTEGRATED,
        style: aiImageStyle?.value,
        keywords: aiKeywords
      };
    }
  
    let extras = feedback ?  {} : {
      feedback: {
        error: false,
        succes: false
      }
    };
    
    let response = await updateLayer(_id, data, extras);

    if (typeof callback === "function") callback();

    return response;
  };

  const beforeEditTemplate = async (data) => {
    const [l] = data;
    const { key, name, property, template, thumbnail } = l;
    await setCanvasEditor({
      layerId: _id,
      layer: { key, name, property, template: template[TEMPLATE_TYPES[selectedPreview]], thumbnail, type },
      layerType: selectedPreview,
      onSave: () => setEnableEdition(false)
    });
    openCanvasEditor();
  };

  useEffect(() => {
    if (template?.landscape) setHorizontal(true);
    else setHorizontal(false);
  }, [!!template?.landscape]);

  useEffect(() => {
    if (template?.square) setSquare(true);
    else setSquare(false);
  }, [!!template?.square]);

  return (
    <div className={prefix}>
      <FlexComponent className={`${prefix}__header`}>
        <div>
          <div className="--key">{`${SHOW_TYPES[type]} ${key}`}</div>
          <div className="--name">{name}</div>
        </div>
        <div>
          {!enableEdition && user.canDoIt($LAYER_EDIT) &&
            <Button
              content={t("common:words.config")}
              onClick={() => setEnableEdition(true)}
              type="underline"
              xs
            />
          }
          {enableEdition && <>
            <Button
              content={t("common:form.save")}
              disabled={false}
              onClick={() => save(true, () => setEnableEdition(false))}
              type="success"
              xs
            />
            <Button
              content={t("common:form.cancel")}
              onClick={() => setEnableEdition(false)}
              type="underline"
              xs
            />
          </>}
        </div>
      </FlexComponent>
      <div className={`${prefix}__content`}>
        <div>
          <div className={`${prefix}__preview`}>
            <div className="--preview-type">
              {vertical && <div 
                className={`${selectedPreview === 1 ? "--selected" : ""}`}
                onClick={() => setSelectedPreview(1)}
              >1</div>}

              {horizontal && <div
                className={`${selectedPreview === 2 ? "--selected" : ""}`}
                onClick={() => setSelectedPreview(2)}
              >2</div>}

              {square && <div
                className={`${selectedPreview === 3 ? "--selected" : ""}`}
                onClick={() => setSelectedPreview(3)}
              >3</div>}
            </div>

            <SquareEdit
              bgImage={thumbnail?.[TEMPLATE_TYPES[selectedPreview]]?.src || ""}
              createLegend={<>
                <p>{t("settings:layers.createYourLayer")}</p>
                <Logo className="--logo" color="var(--color-dark-gray)" />
              </>}
              emptyLegend={t("settings:layers.emptyLayer")}
              enableEdition={enableEdition}
              height={property?.canvas?.height}
              isEmpty={!!!thumbnail?.[TEMPLATE_TYPES[selectedPreview]]?.src}
              onEdit={() => {
                if (!enableEdition) return;
                save(false).then(({ data }) => beforeEditTemplate(data)).catch(() => {});
              }}
              width={property?.canvas?.width}
            />

          </div>
          <div className={`${prefix}__config`}>
            {[HERO, ISOMETRIC].includes(type) && <>
              <div className={`${prefix}__dimensions`}>
                <div className="--form-label">
                  <label>{t("settings:layers.width")}</label>
                  <div className="--form-control">
                    <InputTest 
                      disabled={!enableEdition}
                      maxLength={4}
                      name="width"
                      onChange={changeHandler}
                      ref={inputRef}
                      value={form?.width}
                    />
                    <span>px</span>
                  </div>
                </div>

                <div className="--form-label">
                  <label>{t("settings:layers.height")}</label>
                  <div className="--form-control">
                    <InputTest 
                      disabled={!enableEdition}
                      maxLength={4}
                      name="height"
                      onChange={changeHandler}
                      value={form?.height}
                    />
                    <span>px</span>
                  </div>
                </div>
              </div>
              

              <div className={`${prefix}__switches`}>
                <span className="--title">{t("settings:layers.productVariants")}</span>
                <Switch
                  checked={vertical}
                  disabled={!enableEdition}
                  label={<>
                    <div className="--preview-type">1</div>
                    <span>{t("settings:layers.vertical")}</span>
                  </>}
                />

                <Switch
                  checked={horizontal}
                  disabled={!enableEdition}
                  label={<>
                    <div className="--preview-type">2</div>
                    <span>{t("settings:layers.horizontal")}</span>
                  </>}
                  onChange={(a) => {
                    if (!a && square) setSquare(false);
                    setHorizontal(a);
                  }}
                />

                <Switch
                  checked={square}
                  disabled={!enableEdition}
                  label={<>
                    <div className="--preview-type">3</div>
                    <span>{t("settings:layers.square")}</span>
                  </>}
                  onChange={(a) => {
                    if (!horizontal && a) setHorizontal(true);
                    setSquare(a)
                  }}
                />
              </div>
            </>}

            {type === AI &&
              <div className={`${prefix}__ai-settings`}>
                <FormControl
                  content={<Select
                    disabled={!enableEdition}
                    fullwidth
                    options={aiImageTypeOpts}
                    onChange={(value) => changeHandler({ target: {
                      name: "aiImageType",
                      value
                    }})}
                    value={form?.aiImageType}
                    xs
                  />}
                  labelProps={{content: "Tipo de imagen", size:"sm"}}
                  orientation="vertical"
                />

                <FormControl
                  content={<Select
                    disabled={!enableEdition}
                    fullwidth
                    options={aiImageStyleOpts}
                    onChange={(value) => changeHandler({ target: {
                      name: "aiImageStyle",
                      value
                    }})}
                    name="dataStyle"
                    value={form?.aiImageStyle}
                    xs
                  />}
                  labelProps={{content: "Estilo de imagen", size:"sm"}}
                  orientation="vertical"
                />

                <FormControl
                  content={<Input
                    disabled={!enableEdition}
                    name="aiKeywords"
                    onChange={changeHandler}
                    placeholder="pe. Christmas, Halloween"
                    size="sm"
                    value={form?.aiKeywords}
                  />}
                  labelProps={{content: "Palabras clave", size:"sm"}}
                  orientation="vertical"
                />
              </div>
            }
          </div>
        </div>

        <div className={`${prefix}__channel-section`}>
          <div className="--header">
            <span className="--title">{t("settings:layers.allowChannels")}</span>
            <Button
              content="Agregar más"
              disabled={!enableEdition}
              rightIcon={<NubeskIcons icon="plus" size="1.8rem" />}
              sm
              type="link"
            />
          </div>

          <div className="--tags-container">
            <div className="--tag">Todos</div>
          </div>

          <div className="--adaptations">
            <div className="--form-control">
              <label className="--form-label">{t("settings:layers.onAdaptations")}</label>
              <Select
                disabled={!enableEdition}
                options={[
                  "Agregar al inicio"
                ]}
                sm
              />
            </div>

            <div className="--help-text">
              {t("settings:layers.onAdaptationsDesc")}
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default LayerCard;

