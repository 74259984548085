import { reduxActions as UI } from "_constants/ui.constants";

export const toggleSmartEditor = (data = null) => (dispatch) => {
  if (data) {
    return dispatch({
      type: UI.SMART_EDITOR_OPEN,
      payload: data
    });
  }

  return dispatch({
    type: UI.SMART_EDITOR_CLOSE
  });
}
