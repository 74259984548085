import PropTypes from 'prop-types';
import { MasterRow, CutRow } from "components/molecules";
import { dateFormat } from '_utils/format';

const SwitchMasterRow = ({ date, isMaster, withBackground, ...props}) => {
  let Element;
  let status = 'incomplete';
  
  if (isMaster && withBackground) {
    Element = MasterRow;
  } else {
    Element = CutRow;
  }

  if (!(props?.qty)) status = 'empty';
  else if (props.productStatus) status = props.productStatus;
  else status = "empty";
  
  return (
    <Element 
      {...props}
      date={date ? dateFormat(date) : false}
      status={status}
    />
  );
};

SwitchMasterRow.propTypes = {
  isMaster: PropTypes.bool.isRequired,
  withBackground: PropTypes.bool.isRequired
};

SwitchMasterRow.defaultProps = {
  isMaster: null,
  withBackground: null
};

export default SwitchMasterRow;
