import initialAnalysisState from "../analysis.initialState";
import { reduxActions as ANALYSIS } from "_constants/analysis.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const removeBgBulk = {
  [ANALYSIS.REMOVE_BG_BULK_STARTED]: (state) => ({
    ...state,
    removeBgBulk: {
      ...state.removeBgBulk,
      status: LOADING
    }
  }),
  
  [ANALYSIS.REMOVE_BG_BULK_FAIL]: (state, { payload }) => ({
    ...state,
    removeBgBulk: {
      ...state.removeBgBulk,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  
  [ANALYSIS.REMOVE_BG_BULK_SUCCESS]: (state, { payload }) => ({
    ...state,
    removeBgBulk: {
      ...state.removeBgBulk,
      data: payload?.data?.[0] ?? null,
      status: SUCCESS
    }
  }),

  [ANALYSIS.REMOVE_BG_BULK_RESET]: (state) => ({
    ...state,
    removeBgBulk: initialAnalysisState.removeBgBulk
  }),
};

export default removeBgBulk;
