import { reduxActions as CATALOG } from "_constants/catalog.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const share = {
  [CATALOG.SHARE_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),
  [CATALOG.SHARE_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [CATALOG.SHARE_SUCCESS]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      data: payload?.data,
      status: SUCCESS
    }
  })
};

export default share;
