import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const externalNubai = {
  [APP.EXTERNAL_NUBAI_STARTED]: (state) => ({
    ...state,
    externalNubai: {
      ...state.externalNubai,
      status: LOADING
    }
  }),
  
  [APP.EXTERNAL_NUBAI_SUCCESS]: (state, { payload }) => ({
    ...state,
    externalNubai: {
      ...state.externalNubai,
      data: payload?.data?.[0]?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),

  [APP.EXTERNAL_NUBAI_FAIL]: (state, { payload }) => ({
    ...state,
    externalNubai: {
      ...state.externalNubai,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [APP.EXTERNAL_NUBAI_SET]: (state, { payload }) => ({
    ...state,
    externalNubai: {
      ...state.externalNubai,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),
};

export default externalNubai;
