import { useSelector } from "react-redux";
import { selectAppModules } from "_redux/app/app.selector";
import { selectUser } from "_redux/user/user.selectors";

const useUser = () => {
  const user = useSelector(selectUser);
  const { data: modulesData } = useSelector(selectAppModules);

  const hasModule = (modules) => {
    let output = true;
    if (!Array.isArray(modules)) modules = [modules];

    for (let m of modules) {
      if (!modulesData?.[m]) {
        output = false;
        break;
      }
    }
    return output;
  };

  return {
    user,
    hasModule
  };
};

export default useUser;
