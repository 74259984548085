import classNames from "classnames";
import { FormControl } from "components/templates";
import { TextArea } from "components/atoms";

const prefix = "m-form-textarea";

const FormTextarea = ({
  disabled = false,
  label = "",
  name = "",
  onChange = () => {},
  rows = 3,
  size,
  value,
  ...props
}) => {

  const classForComponent = classNames(prefix, {
    "---disabled": disabled
  });

  return(
    <FormControl
      className={classForComponent} {...props}
      labelProps={{
        content: label,
        size
      }}
      content={<TextArea
        disabled={disabled}
        name={name}
        onChange={onChange}
        rows={rows}
        value={value}
        {...props}
      />}
    />
  );
};

export default FormTextarea;
