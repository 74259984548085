const compileStrings = (ctx) => {
  const compiled = { resources: {}, ns: [] };
  const fileRegex = /(?<context>\w*)\/(?<locale>\w+)\/(?<namespace>\w+)\/(?<fileName>\w+)\.json$/;

  ctx.keys().forEach(async (file) => {
    const { fileName, namespace, locale } = file.match(fileRegex).groups;

    compiled.ns.push(namespace);
    compiled.resources[locale] = !!compiled.resources[locale] ? compiled.resources[locale] : {};
    compiled.resources[locale][namespace] = !!compiled.resources[locale][namespace] ? compiled.resources[locale][namespace] : {};
    compiled.resources[locale][namespace][fileName] = ctx(file);

  });

  compiled.ns = compiled.ns.filter((value, index, self) => self.indexOf(value) === index);

  return compiled;
};

export default compileStrings;
