import { Badge, Button, NubeskIcons } from "components/atoms";
import { InputTest } from "components/molecules/Inputs";
import React, { useState, useRef, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

const prefix = "m-multiple-options";

const MultipleOptions = ({ options, setNewData }) => {
  const {t} = useTranslation('settings');
  let [_options, setOptions] = useState(options);
  const inputRef = useRef(null);
  const [edit, setEdit] = useState(false);

  const setData = useCallback(() => {
    setNewData(newData =>({...newData, enum: _options}))
  }, [_options, setNewData]); 

  useEffect(() => {
    if (edit) {
      inputRef.current.focus();
    }
  }, [edit]);

  useEffect(() => {
    setData();
  }, [setData]);

  const handleChange = (value) => setOptions([..._options.filter(o => o !== value)]);
  return (
    <div className={prefix}>
      <div className={`${prefix}__badges`}>
        {_options.map((row, index) => (
          <Badge content={row} variant={"purple"} noUp key={index} onClick={()=>handleChange(row)}/>
        ))}
      </div>
      <div className={`${prefix}__button`}>
        {edit ? (
          <InputTest
            onKeyPress={(e) => {
              if (e.code === "Enter") {
                setOptions([..._options, e.target.value]);
                setEdit(false);
              }
              
            }}
            onKeyDown={(e) => {
              if (e.code === "Escape") {
                setEdit(false);
              }
            }}
            ref={inputRef}
          />
        ) : (
          <Button
            content={t('dataFields.addMore')}
            type={"color"}
            onClick={() => setEdit(true)}
            sm
            rightIcon={<NubeskIcons icon={"plus"} size={17} />}
          />
        )}
      </div>
    </div>
  );
};

export default MultipleOptions;
