import { useState } from "react";
import { fabric } from "fabric";
import { useTranslation } from "react-i18next";
import { 
  RULES as rulesObject, 
  SIZE_POS_BORDERR1, 
  SIZE_POS_BORDERR2, 
  SIZE_POS_HEIGHT, 
  SIZE_POS_POSX, 
  SIZE_POS_POSY, 
  SIZE_POS_ROTATE, 
  SIZE_POS_WIDTH,
  STYLE_AUTOCOLOR,
  STYLE_BORDER,
  STYLE_BORDERCOLOR,
  STYLE_BORDERWIDTH,
  STYLE_OPACITY,
  STYLE_SHADOW,
  STYLE_SHADOWBLUR,
  STYLE_SHADOWOFFSETX,
  STYLE_SHADOWOFFSETY,
  STYLE_SHAPECOLOR,
  TEXT_FONTFAMILY,
  TEXT_FONTJUSTIFY,
  TEXT_FONTSIZE,
  TEXT_FONTSTYLE
} from "_constants/nbskEditor.constants";
import portraitImg from '_utils/images/vertical_clean.png';
import squareImg from "_utils/images/square_clean.png";
import lanscapeImg from "_utils/images/horizontal_clean.png";
import isomPortraitImg from"_utils/images/isom_portrait.png";
import isomSquareImg from"_utils/images/isom_square.png";
import isomLandscapeImg from"_utils/images/isom_landscape.png";

const useNbskEditor = (canvas = null) => {
  const { t } = useTranslation();
  const [objectProps, setObjectProps] = useState({});
  const [selection, setSelection] = useState();
  const [modified, setModified] = useState(false);
  const [wasMoved, setWasMoved] = useState(false);

  const __anyChangeHandler = (object, key, value) => {
    switch (key) {
      case SIZE_POS_HEIGHT:
        value = parseInt(value).toFixed(0);
        object.set({ scaleY: parseFloat(value / object.height) });
        break;
      case SIZE_POS_WIDTH:
        object.set({ scaleX: parseFloat(value / object.width) });
        break;
      case SIZE_POS_POSX:
        object.set({ left: parseInt(value) });
        break;
      case SIZE_POS_POSY:
        object.set({ top: parseInt(value) });
        break;
      case SIZE_POS_ROTATE:
        object.set({ angle: parseFloat(value).toFixed(0) });
        break;
      case SIZE_POS_BORDERR1:
        object.set({ rx: value });
        break;
      case SIZE_POS_BORDERR2:
        object.set({ ry: value });
        break;
      case STYLE_AUTOCOLOR:
        object.set({
          nbskProperties: {
            ...object?.nbskProperties ?? {},
            color: {
              dynamic: value,
              format: value ? "dynamic": "static"
            }
          },
          fill: value ? "#764CA4" : object.fill
        });
        setObjectProps((o) => ({
          ...o,
          [STYLE_SHAPECOLOR]: value ? "#764CA4" : object.fill
        }));
        break;
      case STYLE_SHAPECOLOR:
        object.set({ fill: value });
        break;
      case STYLE_OPACITY:
        object.set({ opacity: parseFloat(value)?.toFixed(2)});
        break;
      case STYLE_BORDER:
        if (value) {
          object.set({
            stroke: "#000000",
            strokeWidth: 2,
            strokeDashArray: null,
            strokeLineCap: "butt",
            strokeDashOffset: 0,
            strokeLineJoin: "miter",
            strokeUniform: false,
            strokeMiterLimit: 4
          });
          setObjectProps((o) => ({
            ...o,
            [STYLE_BORDERCOLOR]: "#000000"
          }));
        } else {
          object.set({
            stroke: null,
            strokeWidth: 0
          });
        }
        break;
      case STYLE_BORDERCOLOR:
        object.set({ stroke: value });
        setObjectProps((o) => ({
          ...o,
          [STYLE_BORDERCOLOR]: value
        }));
        break;
      case STYLE_BORDERWIDTH:
        object.set({ strokeWidth: parseInt(value) });
        setObjectProps((o) => ({
          ...o,
          [STYLE_BORDERWIDTH]: parseInt(value)
        }));
        break;
      case STYLE_SHADOW:
        if (value) {
          object.set({
            shadow: {
              blur: 0,
              color: '#aaaaaa',
              offsetX: 5,
              offsetY: 5,
              affectStroke: false,
              nonScaling: false
            }
          });
          setObjectProps((o) => ({
            ...o,
            [STYLE_SHADOWBLUR]: 0,
            [STYLE_SHADOWOFFSETX]: 5,
            [STYLE_SHADOWOFFSETY]: 5
          }));
        } else {
          object.set({ shadow: null });
          setObjectProps((o) => ({
            ...o,
            [STYLE_SHADOWBLUR]: 0,
            [STYLE_SHADOWOFFSETX]: 0,
            [STYLE_SHADOWOFFSETY]: 0
          }));
        }
        break;
      case STYLE_SHADOWOFFSETX:
        object.set({
          shadow: {
            ...object.shadow,
            offsetX: value
          }
        });
        
        break;
      case STYLE_SHADOWOFFSETY:
        object.set({
          shadow: {
            ...object.shadow,
            offsetY: value
          }
        });
        break;
      case STYLE_SHADOWBLUR:
        object.set({
          shadow: {
            ...object.shadow,
            blur: value
          }
        });
        break;
    }
  };

  const __circleChangeHandler = (object, key, value) => {
    switch (key) {
      case SIZE_POS_HEIGHT:
        object.set({ scaleY: parseFloat(value / object.height).toFixed(0) });
        break;
      case SIZE_POS_WIDTH:
        object.set({ scaleX: parseFloat(value / object.width).toFixed(0) });
        break;
      case SIZE_POS_POSX:
      case SIZE_POS_POSY:
      case SIZE_POS_ROTATE:
      case STYLE_AUTOCOLOR:
      case STYLE_SHAPECOLOR:
      case STYLE_OPACITY:
      case STYLE_BORDER:
      case STYLE_BORDERCOLOR:
      case STYLE_BORDERWIDTH:
      case STYLE_SHADOW:
      case STYLE_SHADOWOFFSETX:
      case STYLE_SHADOWOFFSETY:
      case STYLE_SHADOWBLUR:
        __anyChangeHandler(object, key, value);
        break;
    }
  };

  const __lineChangeHandler = (object, key, value) => {
    switch (key) {
      case SIZE_POS_HEIGHT:
      case SIZE_POS_WIDTH:
      case SIZE_POS_POSX:
      case SIZE_POS_POSY:
      case SIZE_POS_ROTATE:
      case SIZE_POS_BORDERR1:
      case SIZE_POS_BORDERR2:
      case STYLE_AUTOCOLOR:
      case STYLE_SHAPECOLOR:
      case STYLE_OPACITY:
      case STYLE_BORDER:
      case STYLE_BORDERCOLOR:
      case STYLE_BORDERWIDTH:
      case STYLE_SHADOW:
      case STYLE_SHADOWOFFSETX:
      case STYLE_SHADOWOFFSETY:
      case STYLE_SHADOWBLUR:
        __anyChangeHandler(object, key, value);
        break;
    }
  };

  const __circleSelectHandler = (selected) => {
    const {
      angle,
      fill,
      height,
      nbskProperties,
      left,
      opacity,
      scaleX,
      scaleY,
      shadow,
      stroke,
      strokeWidth,
      top,
      width
    } = selected?.[0];

    setObjectProps({
      [SIZE_POS_HEIGHT]: parseInt(height * scaleY)?.toFixed(0) ?? 1,
      [SIZE_POS_WIDTH]: parseInt(width * scaleX)?.toFixed(0) ?? 1,
      [SIZE_POS_POSX]: typeof left === "number" ? left?.toFixed(0) : left,
      [SIZE_POS_POSY]: typeof top === "number" ? top?.toFixed(0) : top,
      [SIZE_POS_ROTATE]: angle !== undefined ? parseInt(angle)?.toFixed(1) : 0,
      [STYLE_AUTOCOLOR]: !!nbskProperties?.color?.dynamic,
      [STYLE_SHAPECOLOR]: nbskProperties?.color?.format !== "dynamic"? fill : "",
      [STYLE_OPACITY]: opacity ?? 1,
      [STYLE_BORDER]: !!stroke,
      [STYLE_BORDERCOLOR]: stroke,
      [STYLE_BORDERWIDTH]: strokeWidth,
      [STYLE_SHADOW]: !!shadow,
      [STYLE_SHADOWOFFSETX]: shadow ? shadow.offsetX : 0,
      [STYLE_SHADOWOFFSETY]: shadow ? shadow.offsetY : 0,
      [STYLE_SHADOWBLUR]: shadow ? shadow.blur : 0,
    });
  };

  const __lineSelectHandler = (selected) => {
    const {
      angle,
      height,
      left,
      opacity,
      scaleX,
      scaleY,
      shadow,
      stroke,
      strokeWidth,
      top,
      width
    } = selected?.[0];

    setObjectProps({
      [SIZE_POS_HEIGHT]: parseInt(height * scaleY)?.toFixed(0) ?? 1,
      [SIZE_POS_WIDTH]: parseInt(width * scaleX)?.toFixed(0) ?? 1,
      [SIZE_POS_POSX]: typeof left === "number" ? left?.toFixed(0) : left,
      [SIZE_POS_POSY]: typeof top === "number" ? top?.toFixed(0) : top,
      [SIZE_POS_ROTATE]: angle !== undefined? parseInt(angle)?.toFixed(0) : 0,
      [STYLE_OPACITY]: opacity ?? 1,
      [STYLE_BORDER]: true,
      [STYLE_BORDERCOLOR]: stroke,
      [STYLE_BORDERWIDTH]: strokeWidth,
      [STYLE_SHADOW]: !!shadow,
      [STYLE_SHADOWOFFSETX]: shadow ? shadow.offsetX : 0,
      [STYLE_SHADOWOFFSETY]: shadow ? shadow.offsetY : 0,
      [STYLE_SHADOWBLUR]: shadow ? shadow.blur : 0,
    });
  };

  const __rectChangeHandler = (object, key, value) => {
    switch (key) {
      case SIZE_POS_HEIGHT:
      case SIZE_POS_WIDTH:
      case SIZE_POS_POSX:
      case SIZE_POS_POSY:
      case SIZE_POS_ROTATE:
      case SIZE_POS_BORDERR1:
      case SIZE_POS_BORDERR2:
      case STYLE_AUTOCOLOR:
      case STYLE_SHAPECOLOR:
      case STYLE_OPACITY:
      case STYLE_BORDER:
      case STYLE_BORDERCOLOR:
      case STYLE_BORDERWIDTH:
      case STYLE_SHADOW:
      case STYLE_SHADOWOFFSETX:
      case STYLE_SHADOWOFFSETY:
      case STYLE_SHADOWBLUR:
        __anyChangeHandler(object, key, value);
        break;
    }
  };

  const __rectSelectHandler = (selected) => {
    const {
      angle,
      fill,
      height,
      nbskProperties,
      left,
      opacity,
      rx,
      ry,
      scaleX,
      scaleY,
      shadow,
      stroke,
      strokeWidth,
      top,
      width
    } = selected?.[0];

    setObjectProps({
      [SIZE_POS_HEIGHT]: parseInt(height * scaleY)?.toFixed(0) ?? 1,
      [SIZE_POS_WIDTH]: parseInt(width * scaleX)?.toFixed(0) ?? 1,
      [SIZE_POS_POSX]: typeof left === "number" ? left?.toFixed(0) : left,
      [SIZE_POS_POSY]: typeof top === "number" ? top?.toFixed(0) : top,
      [SIZE_POS_ROTATE]: angle ?? 0,
      [SIZE_POS_BORDERR1]: rx ?? 0,
      [SIZE_POS_BORDERR2]: ry ?? 0,
      [STYLE_AUTOCOLOR]: !!nbskProperties?.color?.dynamic,
      [STYLE_SHAPECOLOR]: !!nbskProperties?.color?.dynamic ? "#764CA4" : fill,
      [STYLE_OPACITY]: opacity ?? 1,
      [STYLE_BORDER]: !!stroke,
      [STYLE_BORDERCOLOR]: stroke,
      [STYLE_BORDERWIDTH]: strokeWidth,
      [STYLE_SHADOW]: !!shadow,
      [STYLE_SHADOWOFFSETX]: shadow ? shadow.offsetX : 0,
      [STYLE_SHADOWOFFSETY]: shadow ? shadow.offsetY : 0,
      [STYLE_SHADOWBLUR]: shadow ? shadow.blur : 0,
    });
  };

  const __imageChangeHandler = (object, key, value) => {
    switch (key) {
      case SIZE_POS_HEIGHT:
      case SIZE_POS_WIDTH:
      case SIZE_POS_POSX:
      case SIZE_POS_POSY:
      case SIZE_POS_ROTATE:
      case STYLE_AUTOCOLOR:
      case STYLE_SHAPECOLOR:
      case STYLE_OPACITY:
      case STYLE_BORDER:
      case STYLE_BORDERCOLOR:
      case STYLE_BORDERWIDTH:
      case STYLE_SHADOW:
      case STYLE_SHADOWOFFSETX:
      case STYLE_SHADOWOFFSETY:
      case STYLE_SHADOWBLUR:
        __anyChangeHandler(object, key, value);
        break;
    }
  };

  const __imageSelectHandler = (selected) => {
    const {
      angule,
      backgroundColor,
      height,
      nbskProperties,
      left,
      opacity,
      scaleX,
      scaleY,
      shadow,
      stroke,
      strokeWidth,
      top,
      width
    } = selected?.[0];

    setObjectProps({
      [SIZE_POS_HEIGHT]: parseInt(height * scaleY)?.toFixed(0) ?? 1,
      [SIZE_POS_WIDTH]: parseInt(width * scaleX)?.toFixed(0) ?? 1,
      [SIZE_POS_POSX]: typeof left === "number" ? left?.toFixed(0) : left,
      [SIZE_POS_POSY]: typeof top === "number" ? top?.toFixed(0) : top,
      [SIZE_POS_ROTATE]: angule ?? 0,
      [STYLE_AUTOCOLOR]: !!nbskProperties?.color?.dynamic,
      [STYLE_SHAPECOLOR]: !nbskProperties?.color?.format ? backgroundColor : "",
      [STYLE_OPACITY]: opacity ?? 1,
      [STYLE_BORDER]: !!stroke,
      [STYLE_BORDERCOLOR]: stroke,
      [STYLE_BORDERWIDTH]: strokeWidth,
      [STYLE_SHADOW]: !!shadow,
      [STYLE_SHADOWOFFSETX]: shadow ? shadow.offsetX : 0,
      [STYLE_SHADOWOFFSETY]: shadow ? shadow.offsetY : 0,
      [STYLE_SHADOWBLUR]: shadow ? shadow.blur : 0
    });
  };

  const __textChangeHandler = (object, key, value) => {
    switch (key) {
      case SIZE_POS_HEIGHT:
      case SIZE_POS_WIDTH:
      case SIZE_POS_POSX:
      case SIZE_POS_POSY:
      case SIZE_POS_ROTATE:
      case SIZE_POS_BORDERR1:
      case SIZE_POS_BORDERR2:
      case STYLE_AUTOCOLOR:
      case STYLE_SHAPECOLOR:
      case STYLE_OPACITY:
      case STYLE_SHADOW:
      case STYLE_SHADOWOFFSETX:
      case STYLE_SHADOWOFFSETY:
      case STYLE_SHADOWBLUR:
        __anyChangeHandler(object, key, value);
        break;
      case TEXT_FONTFAMILY:
        object.set({ fontFamily: value });
        break;
      case TEXT_FONTJUSTIFY: 
        object.set({ textAlign: value.value });
        break;
      case TEXT_FONTSIZE:
        object.set({ fontSize: parseInt(value) });
        break;
      case TEXT_FONTSTYLE:
        let obj = {
          fontStyle: "",
          fontWeight: "",
          underline: false
        };
        switch (value) {
          case 'Regular':
            obj.fontWeight = "";
            break;
          case 'Bold':
            obj.fontWeight = "bold";
            break;
          case 'Bold italic':
            obj.fontWeight = "bold";
            obj.fontStyle = "italic";
            break;
          case 'Bold underline':
            obj.fontWeight = "bold";
            obj.underline = true;
            break;
          case 'Italic':
            obj.fontStyle = "italic";
            break;
          case 'Underline':
            obj.underline = true;
            break;
        }
        object.set(obj);
        break;
    }
  };


  const __textSelectHandler = (selected) => {
    const {
      angule,
      fill,
      fontFamily,
      fontSize,
      fontStyle,
      fontWeight,
      height,
      nbskProperties,
      left,
      opacity,
      rx,
      ry,
      scaleX,
      scaleY,
      stroke,
      strokeWidth,
      textAlign,
      top,
      underline,
      width
    } = selected?.[0];

    let style = "Regular";
    if (fontWeight === "bold") {
      style = "Bold";
      if (fontStyle === "italic") style = "Bold Italic";
      if (underline) style = "Bold";
    }

    if (fontStyle === "italic") style = "Italic";
    if (underline) style = "Underline";

    setObjectProps({
      [SIZE_POS_HEIGHT]: parseInt(height * scaleY)?.toFixed(0) ?? 1,
      [SIZE_POS_WIDTH]: parseInt(width * scaleX)?.toFixed(0) ?? 1,
      [SIZE_POS_POSX]: typeof left === "number" ? left?.toFixed(0) : left,
      [SIZE_POS_POSY]: typeof top === "number" ? top?.toFixed(0) : top,
      [SIZE_POS_ROTATE]: angule ?? 0,
      [SIZE_POS_BORDERR1]: rx ?? 0,
      [SIZE_POS_BORDERR2]: ry ?? 0,
      [TEXT_FONTFAMILY]: fontFamily,
      [TEXT_FONTJUSTIFY]: { label: t(`nbsk:editor.text.align.${textAlign ?? "center"}`), value: textAlign ?? "center"},
      [TEXT_FONTSIZE]: fontSize ?? 10,
      [TEXT_FONTSTYLE]: style,
      [STYLE_AUTOCOLOR]: !!nbskProperties?.colorSchema?.object?.format,
      [STYLE_SHAPECOLOR]: !nbskProperties?.colorSchema?.object?.format ? fill : "",
      [STYLE_OPACITY]: opacity ? parseFloat(opacity) : 1,
      [STYLE_BORDER]: !!stroke,
      [STYLE_BORDERCOLOR]: stroke,
      [STYLE_BORDERWIDTH]: strokeWidth
    });
  };

  const __selectionAssing = (selected) => {
    if (selected?.length > 1) return;
    switch (selected?.[0]?.type) {
      case 'line': 
        return __lineSelectHandler(selected);
      case 'circle':
        return __circleSelectHandler(selected);
      case 'image':
        return __imageSelectHandler(selected);
      case 'rect':
        return __rectSelectHandler(selected);
      case 'text':
      case 'textbox':
        return __textSelectHandler(selected);
      default:
        break;
    }
  };

  const addCircle = (props = {}) => {
    let rect = new fabric.Circle({
      radius: 80,
      fill: '#ffffff',
      left: 80,
      nbskProperties: {},
      top: 80,
      ...props
    });
    canvas.add(rect);
    canvas.renderAll();
  };

  const addImage = (type, props = null, cnvs) => {
    if (!canvas) canvas = cnvs;
    if (!props) {
      props = type;
      type = null;
    }
    let url;
    let callback;
    if (props?.callback) {
      callback = props.callback;
      delete props.callback;
    }

    switch (type) {
      case 'landscape':
        url = lanscapeImg;
        break;
      case 'isomLandscapeImg':
        url = isomLandscapeImg;
        break;
      case 'portrait':
        url = portraitImg;
        break;
      case 'isomPortraitImg':
        url = isomPortraitImg;
        break;
      case 'square':
        url = squareImg;
        break;
      case 'isomSquareImg':
        url = isomSquareImg;
        break;
      
      default:
        url = type;
        break;
    }
    new fabric.Image.fromURL(url, (img) => {
      img.set({ ...props ?? {} })
      canvas.add(img);
      if (callback) callback(img);
      canvas.renderAll();
    });
  };

  const addLine = (props = {}) => {
    let line = new fabric.Line([100, 100, 450, 100], {
      stroke: "#000000",
      strokeWidth: 5,
      strokeUniform: true,
      ...props
    });
    canvas.add(line);
    canvas.renderAll();
  };

  const addRect = (props = {}) => {
    let circle = new fabric.Rect({
      fill: '#ffffff',
      left: 80,
      nbskProperties: {},
      top: 80,
      ...props
    });
    canvas.add(circle);
    canvas.renderAll();
  };

  const addText = (props = {}) => {
    let { text } = props;
    let textbox = new fabric.Textbox(text, 
      {
        fill: '#000000',
        nbskProperties: {},
        ...props
      }
    );
    canvas.add(textbox);
  };

  /**
   * 
   * @param {Array} objects 
   * @returns 
   */
  const bringToFront = (objects) => {
    if (!objects) objects = canvas.getActiveObjects();
    objects = Array.isArray(objects) ? objects : [objects];
    
    for (let o of objects) {
      canvas.bringToFront(o);
    }
  }

  /**
   * 
   * @param { Object } object 
   * @param { String } key 
   * @param { String} value 
   * @returns 
   */
  const changeHandler = (object, key, value) => {
    if (object?.length !== 1) return;
    if (!key) throw "NBSK:Editor not found key";
    
    setObjectProps((a) => ({
      ...a,
      [key]: value
    }));

    switch (object?.[0]?.type) {
      case 'line':
        __lineChangeHandler(object?.[0], key, value);
        break
      case 'rect':
        __rectChangeHandler(object?.[0], key, value);
        break
      case 'circle':
        __circleChangeHandler(object?.[0], key, value);
        break;
      case 'image':
        __imageChangeHandler(object?.[0], key, value);
        break;
      case 'text':
      case 'textbox':
        __textChangeHandler(object?.[0], key, value);
        break;
    }

    canvas.renderAll();
  };

  /**
   * 
   * @param { Object } objects 
   * @param { Array } fields 
   * @returns 
   */
  const isVisible = (objects, fields) => {
    let visible = false;

    if (!objects?.[0]?.type || !rulesObject?.[objects[0].type]) return false;

    fields = Array.isArray(fields) ? fields : [fields];

    for (let f of fields) {
      if (rulesObject?.[objects?.[0]?.type]?.includes(f)) {
        visible = true;
        break;
      }
    }
    
    return visible;
  };

  /**
   * 
   * @param { Object } e 
   * @param { Object } config 
   */
  const modificationHandler = async(e, { canvas }) => {
    if (!canvas) return;
    let selectAux = canvas.getActiveObjects();
    if (selectAux?.length === 1) {
      setSelection(canvas.getActiveObjects());
      __selectionAssing(selectAux);
    }
  };

  /**
   * 
   * @param { Object } e 
   */
  const selectionHandler = (e) => {
    // console.log("🚀 ~ file: NbskEditor.js:29 ~ selectionHandler ~ e:", e)
    let { selected } = e;
  
    setSelection(selected);
    __selectionAssing(selected);
  };

  /**
   * 
   * @param { Array } objects 
   */
  const removeObjects = (objects = null) => {
    if (!objects) objects = canvas.getActiveObjects();
    objects = Array.isArray(objects) ? objects : [objects];
    objects.forEach((obj) => {
      canvas.remove(obj)
    })
    canvas.discardActiveObject().renderAll()
  };

  /**
   * 
   * @param {*} objects 
   */
  const sendToBack = (objects, cnvs) => {
    if (!canvas) canvas = cnvs;
    if (!objects) objects = canvas.getActiveObjects();
    objects = Array.isArray(objects) ? objects : [objects];

    for (let o of objects) {
      canvas.sendToBack(o);
    }
  }

  const setNbskProps = (selection = null, nbskProperties = null) => {
    if (nbskProperties === null) {
      nbskProperties = selection;
      selection = canvas.getActiveObjects();
    }

    if (Array.isArray(selection)) {
      for (let i in selection) {
        let props = selection[i].nbskProperties;
        selection[i].set({
          nbskProperties: {
            ...props,
            ...nbskProperties
          }
        });
      }
    }
  };

  return {
    addImage,
    addCircle,
    addLine,
    addRect,
    addText,
    bringToFront,
    changeHandler,
    isVisible,
    modificationHandler,
    objectProps,
    selection,
    removeObjects,
    rulesObject,
    selectionHandler,
    sendToBack,
    setNbskProps,
    setSelection,
    setWasMoved,
    wasMoved
  };
};

export default useNbskEditor;
