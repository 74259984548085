const ageFormat = (date) => {
  if (!date) return date;
  let now = new Date();
  let dateEpoch = new Date(date).getTime();
  let diffDate = Math.ceil(now - dateEpoch);
  
  let days = Math.ceil(diffDate / (1000 * 60 * 60 * 24));
  
  return days;
};

export default ageFormat;
