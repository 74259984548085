import { newProperty } from "../schema.generator";

const schema = [
  { ...newProperty("name", "string", true, "Name") },
  { ...newProperty("width", "number", true, "Width") },
  { ...newProperty("height", "number", true, "Height") },
  { ...newProperty("resolution", "number", true, "Resolution") },
  { ...newProperty("maxSize", "number", false, "Max size") },
];

export default schema;
