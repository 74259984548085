import { Button, NubeskIcons } from "components/atoms";
import { useTranslation } from "react-i18next";
import { useTask } from "_hooks";
import i18n from "_config/lang";
import { 
  ITEM_DONE,
  ITEM_DECLINED,
  ITEM_INPROGRESS,
  ITEM_PENDING,
  ITEM_TOREVIEW
} from "_constants/task.constants";
import useAppSettings from "_hooks/useAppSettings";

const prefix = "o-task-item-status-manager";

const options = {
  [ITEM_PENDING]: [{
    label: "start",
    status: ITEM_INPROGRESS
  }],
  [ITEM_INPROGRESS]: [{
    label: "pending",
    status: ITEM_PENDING
  }, {
    label: "toReview",
    status: ITEM_TOREVIEW
  }],
  [ITEM_TOREVIEW]: [{
    label: "decline",
    status: ITEM_DECLINED
  }, {
    label: "approve",
    status: ITEM_DONE
  }],
  [ITEM_DONE]: [{
    label: "toReview",
    status: ITEM_TOREVIEW
  }],
  [ITEM_DECLINED]: [{
    label: "start",
    status: ITEM_INPROGRESS
  }]
};

const TaskItemsStatusManager = ({ productId, itemData, task }) => {

  const { t } = useTranslation();
  const { appColumnKeyProps } = useAppSettings();
  const { setProcessStatus } = useTask();

  return(
    <div className={prefix}>
      <div className={`${prefix}__task-key`}>
        <NubeskIcons icon="doc" />
        <span className="--key">{task.key}</span>

        <div className={`${prefix}__task-type`}>
          {task?.type && i18n.exists(`tasks:table.types.${task?.type.toUpperCase()}`) &&
            t(`tasks:table.types.${task.type.toUpperCase()}`, { key: appColumnKeyProps?.name })
          }
        </div>
      </div>
      
      <div className={`${prefix}__item-status`}>
        <div className={`${prefix}__status-circle --${itemData?.data?.status}`}></div>
        {itemData?.data?.status && t(`tasks:items.status.${itemData.data.status}`)}
      </div>
      
      <div className={`${prefix}__change-status`}>
        {options?.[itemData?.data?.status]?.map((btn, i) => (
          <Button
            className={`--${btn.label}`}
            content={t(`tasks:items.actions.${btn.label}`)}
            key={i}
            sm
            type="primary"
            onClick={() => setProcessStatus(task.key, productId, btn.status, "productDetails")}
          />
        ))}
      </div>
    </div>
  );
};

export default TaskItemsStatusManager;
