import PropTypes from "prop-types";
import classNames from "classnames";

const prefix = "m-flex-cell";

const FlexCell = ({ align, children, className, justify, style, ...props}) => {
  const classForComponent = classNames(prefix, {
    [`--align-${align}`]: align,
    [`--justify-${justify}`]: justify,
    [className]: className
  });

  return(
    <div 
      className={classForComponent}
      style={{
        ...style ?? {},
      }}
      {...props}
    >
      {children}
    </div>
  );
};

FlexCell.propTypes = {
  align: PropTypes.oneOf(["start", "center", "end", "stretch", "normal"]),
  grow: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  justify: PropTypes.oneOf(["start", "center", "end", "space-between", "space-around", "space-evenly"]),
  shrink: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  textTransform: PropTypes.string
};

FlexCell.defaultProps = {
  align: "center",
  basis: undefined,
  justify: "start"
};

export default FlexCell;
