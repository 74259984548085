import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, NubeskIcons } from "components/atoms";
import { FlexComponent } from "components/templates";
import { SearchPanel } from "components/templates";
import { openModal } from "_redux/ui/ui.actions";
import { selectTaskAll } from "_redux/task/task.selector";

const TaskToolbar = () => {
  const dispatch = useDispatch();
  let { t } = useTranslation();

  const { data: tasks } = useSelector(selectTaskAll);

  return (
    <>
      <FlexComponent>
        <Button
          leftIcon={<NubeskIcons icon="plus"/>}
          content={t("tasks:actions.newTask")}
          type={"primary"}
          onClick={() => dispatch(openModal({
            header: {
              icon: { type: "plus", size: 18 },
              text: t("modals:addToTask.title")
            },
            modalProps: {
              auto: false,
              type: "new"
            },
            typeModal: "addToTask"
          }))}
        />
      </FlexComponent>
      <SearchPanel 
        counterName={t("tasks:actions.counterName")}
        disabled={true}
        qty={tasks?.length}
        total={tasks?.length}
        placeholder={t("tasks:actions.searchBy")}
        withDates={false}
      />
    </>
  );
};

export default TaskToolbar;
