import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { selectSkuSelected } from "_redux/ui/ui.selector";

const useGridTimer = ({ delay } = {}) => {
  let timer = useRef(null);
  let cardRef = useRef(null);
  const selections  = useSelector(selectSkuSelected);
  const [active, setActive] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [lastRight, setLastRight] = useState(false);
  const [dimensions, setDimensions] = useState({});

  useEffect(() => {
    return () => clearTimeout(timer.current);
  }, []);

  const openCard = () => {
    setShowLoader(true);

    timer.current = setTimeout(() => {
      handleDimensions();
      setActive(true);
    }, delay || 250);
  };

  const handleDimensions = () => {
    const {
      current,
      current: {
        offsetLeft,
        offsetParent: { clientWidth },
      },
    } = cardRef;
    const parentDimensions = current.parentNode.getBoundingClientRect();
    const { top, right, bottom, left, width, height, x, y } =
      current.getBoundingClientRect();

    let fixedDimensions = {
      top: top - parentDimensions.top,
      left: left - parentDimensions.left,
      right: right ,
      width,
      height,
      bottom,
    };
    setDimensions(fixedDimensions);
    if (clientWidth - offsetLeft < 350) {
      return setLastRight(true);
    }
  };

  const onMouseEnter = () => {
    onMouseLeave();
    timer.current = setTimeout(
      () => {
        openCard();
      },
      Object.keys(selections.selected).length ? 700 : 450
    );
  };

  const onMouseLeave = () => {
    clearTimeout(timer.current);
    setShowLoader(false);
    return setActive(false);
  };

  const onContextMenu = (e) => {
    e.preventDefault();
    onMouseLeave();
    handleDimensions();
    setActive(!active);
  };

  const onWheel = () => {
    onMouseLeave();
  };

  return {
    active,
    showLoader,
    lastRight,
    dimensions,
    cardRef,
    actions: { onMouseEnter, onMouseLeave, onContextMenu, onWheel },
  };
};

export default useGridTimer;
