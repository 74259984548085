import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import className from "classnames";
import { Button, CircularProgress } from "components/atoms";
import { CountUpModal } from "components/molecules";
import { closeModal } from "_redux/ui/ui.actions";

const prefix = "o-bulk-counter";

const BulkActionsCounter = ({
  completed,
  legends,
  onCancel,
  onFinish,
  withError,
  total
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [percent, setPercent] = useState(0);
  const classForButtons = className(`${prefix}__buttons`, {
    "visible": (completed < total && onCancel !== null) || (completed === total) || (completed + withError === total)
  });

  const finish = () => {
    if (typeof onFinish === "function") onFinish();
    dispatch(closeModal());
  };
  
  useEffect(() => {
    if (total) {
      let calc = (completed + withError) / total;
      setPercent(Math.floor(calc * 100));
    }
  }, [completed, withError]);

  return (
    <>
      <div className={prefix}>
        <CircularProgress percent={percent} />
        <div className={`${prefix}__data`}>
          <span className={`${prefix}__data-title`}>
            {(completed + withError) < total
              ? legends["action"] ? legends["action"] : ""
              : legends["success"] || t("common:words.success")}
          </span>
          <div className={`${prefix}__data-item`}>
            <CountUpModal
              count={total}
              text={legends["total"] || ""}
              simple
            />
          </div>
          <div className={`${prefix}__data-item`}>
            <CountUpModal
              count={completed}
              text={legends["complete"] || t("common:words.complete")}
              simple
            />
          </div>
          <div className={`${prefix}__data-item`}>
            <CountUpModal
              count={withError}
              text={legends["error"] || t("common:words.error") }
              variant={"red"}
              simple
            />
          </div>
        </div>
      </div>

      <div className={classForButtons}>
        <Button
          disabled={!(completed < total && onCancel)}
          content={legends["cancel"] || t("common:form.cancel")}
          onClick={onCancel ? onCancel : () => {}}
          style={{visibility: (completed < total && onCancel) ? "visible" : "hidden"}}
          type="underline"
          sm
        />
        
        <Button
          content={legends["finish"] || t("common:words.finished")}
          disabled={(completed + withError) < total}
          onClick={finish}
          style={{visibility : ((completed + withError) === total) ? "visible" : "hidden"}}
          type="purple"
          sm
        />
      </div>
    </>
  );
};

BulkActionsCounter.propTypes = {
  completed: PropTypes.number,
  legends: PropTypes.object,
  onCancel: PropTypes.func,
  onFinish: PropTypes.func,
  total: PropTypes.number,
  withError: PropTypes.number
};

BulkActionsCounter.defaultProps = {
  completed: 0,
  legends: {},
  onCancel: null,
  onFinish: () => {},
  total: 0,
  withError: 0
};

export default BulkActionsCounter;
