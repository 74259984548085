import initialReaderState from "./reader.initialState";
import filterKeys from "./reducers/filterKeys";
import upload from "./reducers/upload";


const reducer = (state = initialReaderState, action) => {
  
  let reducers = {
    ...filterKeys,
    ...upload
  };

  let { type } = action;
  if (reducers[type] === undefined) return state;
  
  return reducers[type](state, action);
};

export default reducer;
