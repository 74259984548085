import { reduxActions as APP } from "_constants/app.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const layers = {
  [APP.LAYERS_STARTED]: (state) => ({
    ...state,
    layers: {
      ...state.layers,
      status: LOADING
    }
  }),
  
  [APP.LAYERS_SUCCESS]: (state, { payload }) => ({
    ...state,
    layers: {
      ...state.layers,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [APP.LAYERS_FAIL]: (state, { payload }) => ({
    ...state,
    layers: {
      ...state.layers,
      error: payload?.error ?? null,
      status: FAILURE
    }
  })
};

export default layers;
