import initialCatalogState from "_redux/catalog/catalog.initialState";
import { reduxActions as CATALOG } from "_constants/catalog.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const all = {
  [CATALOG.ALL_STARTED]: (state) => ({
    ...state,
    all: {
      ...state.all,
      status: LOADING,
    },
  }),

  [CATALOG.ALL_FAIL]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      error: payload?.error ?? null,
      status: FAILURE,
    },
  }),

  [CATALOG.ALL_SUCCESS]: (state, { payload }) => ({
    ...state,
    all: {
      ...state.all,
      data: payload?.data?.sort((a, b) => {
        let dateA = (new Date(a.createdAt)).getTime();
        let dateB = (new Date(b.createdAt)).getTime();
        if (dateA < dateB) return 1;
        if (dateA > dateB) return -1
        return 0;
      }) ?? null,
      error: null,
      status: SUCCESS,
    },
  }),
  
  [CATALOG.ALL_RESET]: (state, { payload }) => ({
    ...state,
    all: initialCatalogState.all,
  })
};

export default all;
