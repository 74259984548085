import { Select } from "components/molecules/Inputs";
import { FormControl } from "components/templates";
import { Accordion } from "components/organism";
import { FormInput } from "components/molecules/Form";
import { useAppSettings, useNbskEditor } from "_hooks";
import { TEXT_FONTFAMILY, TEXT_FONTJUSTIFY, TEXT_FONTSIZE, TEXT_FONTSTYLE } from "_constants/nbskEditor.constants";
import { useTranslation } from "react-i18next";

const TextSection = ({ canvas, selection, onChange, prefix, values }) => {
  const { appFonts } = useAppSettings();
  const { isVisible } = useNbskEditor(canvas);
  const { t } = useTranslation();

  return(
    <Accordion
      title={"Texto"}
      headerRight={null}
      isOpen={true}
      content={
      <div className={`${prefix}__text-props`}>
        {isVisible(selection, TEXT_FONTFAMILY) && !!values?.[TEXT_FONTFAMILY] &&
          <FormControl
            className="--font-family"
            content={
              <Select
                emptyLabel={" "}
                onChange={({target: { value }}) => onChange({ name: TEXT_FONTFAMILY, value })}
                options={appFonts?.map((f) => f.name) ?? []}
                sm
                value={values?.[TEXT_FONTFAMILY]}
              />
            }
            labelProps={{content: t("nbsk:editor.text.fontFamily.label"), size: "sm"}}
            orientation="vertical"
          />
        }

        {isVisible(selection, TEXT_FONTSIZE) && 
          <FormInput
            className="--font-size"
            label={t("nbsk:editor.text.size.label")}
            name={TEXT_FONTSIZE}
            onChange={({ e: { target: { value }}}) => {
              if (value.length > 2) return;
              if (!/\d/.test(value)) return;
              if (!value) value = 10;
              onChange({ 
                name: TEXT_FONTSIZE,
                value: parseInt(value) 
              }
            )}}
            orientation="vertical"
            size="sm"
            value={values?.[TEXT_FONTSIZE]}
          />
        }

        {isVisible(selection, TEXT_FONTSTYLE) && !!values?.[TEXT_FONTSTYLE] &&
          <FormControl
            className="--font-decoration"
            content={
              <Select
                emptyLabel={" "}
                onChange={({ target: { value }}) => onChange({ name: TEXT_FONTSTYLE, value })}
                options={["Regular", "Bold", "Italic"]}
                sm
                value={values?.[TEXT_FONTSTYLE]}
              />
            }
            labelProps={{content: t("nbsk:editor.text.style.label"), size: "sm"}}
            orientation="vertical"
          />
        }

        {isVisible(selection, TEXT_FONTJUSTIFY) && !!values?.[TEXT_FONTJUSTIFY] &&
          <FormControl
            className="--font-justify"
            content={
              <Select
                emptyLabel={" "}
                onChange={(value) => onChange({ name: TEXT_FONTJUSTIFY, value })}
                options={[{
                  label: t("nbsk:editor.text.align.left"),
                  value: "left"
                }, {
                  label: t("nbsk:editor.text.align.center"),
                  value: "center"
                },
                {
                  label: t("nbsk:editor.text.align.right"),
                  value: "right"
                }]}
                sm
                value={values?.[TEXT_FONTJUSTIFY]}
              />
            }
            labelProps={{content: t("nbsk:editor.text.align.label"), size: "sm"}}
            orientation="vertical"
          />
        }
      </div>
    } />
  );
};

export default TextSection;
