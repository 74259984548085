import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NubeskIcons } from "components/atoms";
import { Semaphore } from "components/molecules/index";

let interval;

const Processing = ({
  prefix
}) => {
  const initialized = useRef(false);
  const [stepCurrent, setStepCurrent] = useState(1);
  const { t } = useTranslation();

  const incrementStep = useCallback(() => {
    if (stepCurrent === 1) {
      interval = setInterval(() => {
        setStepCurrent((s) => {
          if (s === 4) {
            clearInterval(interval);
            return s;
          }
          return s + 1;
        });
      }, [2000]);
    }
  }, [stepCurrent, setStepCurrent]);

  useEffect(() => {
    if (!initialized?.current) {
      initialized.current = true;
      incrementStep();
    }
  }, [incrementStep])

  return (  
    <div className={`${prefix}__processing`}>
      <div className="--loading">
        <div>
          <NubeskIcons icon="spin" className="u-spin" size="5rem" />
        </div>
        <div>
          <p className="u-text-md">{t("module:syncfonia.updateCatalog.modal.fetchElements")}</p>
          <p className="u-text-sm">{t("module:syncfonia.updateCatalog.modal.thisMayTakeTime")}</p>
          <Semaphore current={stepCurrent} steps={4} />
        </div>
      </div>
    </div>
  );
};

export default Processing;
