import React, { useState } from "react";
import classNames from "classnames";

import { NubeskIcons } from "components/atoms";
import { FlexComponent } from "components/templates";
import { Button } from "components/atoms/index";

const Toast = ({ closeToast, toastProps, info, area, buttonProps }) => {
  const prefix = "m-toast";
  const [showInfo, setShowInfo] = useState(true);
  const classForComponent = classNames(prefix, {
    [`${prefix}--${toastProps.type}`]: toastProps.type,
  });
  return (
    <div className={classForComponent}>
      <NubeskIcons
        icon={
          toastProps.type === "success"
            ? "check"
            : toastProps.type === "info"
            ? "copy"
            : "circleT"
        }
        className={`${prefix}__icon`}
      />
      {!showInfo ? (
        <button
          className={`${prefix}__button`}
          onClick={() => setShowInfo(true)}
        >
          <NubeskIcons icon="down" />
        </button>
      ) : (
        <button className={`${prefix}__button`} onClick={closeToast}>
          <NubeskIcons icon="times" />
        </button>
      )}
      <FlexComponent isVertical>
        {showInfo && area ? (
          <span className={`${prefix}__title`}>{area}</span>
        ) : null}
        <div className={`${prefix}__content`}>
          <span
            className={`${prefix}__info${
              showInfo ? ` ${prefix}__info-show` : ""
            }`}
            dangerouslySetInnerHTML={{__html: info}}
          >
           
          </span>
          {buttonProps ? (
            <Button
              type={"toast"}
              content={"Undo"}
              sm
              {...buttonProps}
              onClick={() => {
                buttonProps?.onClick?.();
                closeToast();
              }}
            />
          ) : null}
        </div>
      </FlexComponent>
    </div>
  );
};

export default Toast;

//TODO toast error cuando se presente. Comprobar que funciona correctamente
