import initialLayerState from "../layer.initialState";
import { reduxActions as LAYER} from "_constants/layer.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const generate = {
  [LAYER.GENERATE_STARTED]: (state) => ({
    ...state,
    generate: {
      ...state.generate,
      status: LOADING
    }
  }),
  
  [LAYER.GENERATE_SUCCESS]: (state, { payload }) => ({
    ...state,
    generate: {
      ...state.generate,
      data: payload?.data ?? null,
      error: null,
      status: SUCCESS
    }
  }),
  
  [LAYER.GENERATE_FAIL]: (state, { payload }) => ({
    ...state,
    generate: {
      ...state.generate,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),

  [LAYER.GENERATE_RESET]: (state) => ({
    ...state,
    generate: initialLayerState.generate
  })
};

export default generate;
