import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { DropDown, FlexTable } from "components/templates";
import { FlexCell, NubeskIcons } from "components/atoms";
import { AvatarName } from "components/molecules";
import { useUsersPanel, useProfile } from "_hooks";
import { FAILURE, SUCCESS } from "_constants/redux.constants";
import { ACTIVE } from "_constants/user.constants";

const prefix = "v-users-panel";

const UsersPanel = () => {
  const { t } = useTranslation();
  const { fetchProfiles, profileList } = useProfile();
  const { 
    disableUser,
    enableUser,
    fetchUsers,
    resetPassword,
    sendWelcome,
    users,
    usersStatus
  } = useUsersPanel();

  useEffect(() => {
    fetchUsers();
    fetchProfiles();
  }, []);

  return (
    <div className={prefix}>
      <div className={`${prefix}__content`}>
        <FlexTable
          className={`${prefix}__table`}
          bodyClass={`${prefix}__table-body`}
          headerCol={9}
          rowCol={9}
          showEmpty={[SUCCESS, FAILURE].indexOf(usersStatus) !== -1}
          gridTemplateColumns="1fr minmax(300px, 1fr) 180px 120px 150px 50px"
          emptyData={t("tasks:table.emptyData")}
          headers={<>
            <FlexCell>{t("settings:usersPanel.table.headers.name")}</FlexCell>
            <FlexCell>{t("settings:usersPanel.table.headers.email")}</FlexCell>
            <FlexCell>{t("settings:usersPanel.table.headers.profile")}</FlexCell>
            <FlexCell>{t("settings:usersPanel.table.headers.status")}</FlexCell>
            <FlexCell>{t("settings:usersPanel.table.headers.lastConnection")}</FlexCell>
            <FlexCell></FlexCell>
          </>}
          rows={Array.isArray(users) && users?.length && users.map((u) => {
            return (
              <>
                <FlexCell>
                  <AvatarName
                    firstName={u.account.firstName}
                    lastName={u.account.lastName}
                  />
                </FlexCell>
                <FlexCell>
                  {u.email}
                </FlexCell>
                <FlexCell>
                  {profileList && profileList?.filter((p) => p._id === u.profileId)?.[0]?.name}
                </FlexCell>
                <FlexCell>
                  {t(`settings:usersPanel.status.${u.status}`)}
                </FlexCell>
                <FlexCell>
                  {u?.$lastLogin?.createdAt ? 
                    moment(u.$lastLogin.createdAt).format("YYYY-MM-DD hh:mm") 
                  : 
                    t("settings:usersPanel.table.never")
                  }
                </FlexCell>
                <FlexCell>
                  <DropDown
                    buttonProps={{
                      content: <NubeskIcons icon="dots" />,
                      type: "color"
                    }}
                    options={[{
                      content: t("settings:usersPanel.actions.resetPassword"),
                      onClick: () => resetPassword(u.email),
                      visible: !!u?.$lastLogin?.createdAt
                    }, {
                      content: t(`settings:usersPanel.actions.${u.status === ACTIVE ? "disableAccount" : "enableAccount"}`),
                      onClick: () => {
                        if (u.status === ACTIVE) {
                          disableUser(u._id);
                        } else {
                          enableUser(u._id);
                        }
                      }
                    }, {
                      content: t(`settings:usersPanel.actions.sendInvitation`),
                      onClick: () => sendWelcome(u._id),
                      visible: !u?.$lastLogin?.createdAt
                    }, {
                      disabled: true,
                      content: t("settings:usersPanel.actions.reviewPermissions"),
                      onClick: () => {}
                    }]}
                  />
                </FlexCell>
              </>
            );
          })}
        />
      </div>
    </div>
  )
};

export default UsersPanel;
