import { IDLE } from "_constants/redux.constants";

const initialStatisticsState = {
  products: {
    data: {
      complete: null,
      incomplete: null,
      broken: null
    },
    error: null,
    status: IDLE
  },

  tasks: {
    data: {
      copy: null,
      photos: null,
      rights: null
    },
    error: null,
    status: IDLE
  }
};

export default initialStatisticsState;
