export const reduxActions = {
  //MODAL
  OPEN_MODAL: "ui/open_modal",
  CLOSE_MODAL: "ui/close_modal",
  
  //TOOLBAR
  TOOLBAR_CHANGE: "ui/toolbar_change",
  TOOLBAR_PREVIOUS: "ui/toolbar_previous",
  TOOLBAR_SELECTION: "ui/toolbar_selection",
  
  //TOOLTIP
  TOGGLE_TOOLTIP: "ui/toggle_tooltip",
  
  //LEFT PANEL
  OPEN_PANEL_LEFT: "ui/open_panel_left",
  CLOSE_PANEL_LEFT: "ui/close_panel_left",

  //SELECTION
  ADD_SELECTION: "ui/add_selection",
  TOGGLE_SELECTION: "ui/toggle_selection",
  RESET_SELECTION: "ui/reset_selection",
  
  //SHORTCUTS
  KEYBOARD_SHORTCUTS: "ui/keyboard_shortcuts",
  CHANGE_DETAILS_PAGE: "ui/change_details_page",

  //IMAGES
  CARROUSEL: "ui/carrousel",
  CURRENT_ADAPTATION: "ui/details_current_adaptation",
  CURRENT_IMAGE: "ui/details_current_image",
  SET_IMAGES: "ui/set_images",

  //MANAGE IMAGES COMPONENT
  MANAGE_IMAGES_OPEN: "ui/manage_images_open",
  MANAGE_IMAGES_CLOSE: "ui/manage_images_close",
  MANAGE_IMAGES_SELECTED_IMAGE: "ui/manage_images_selected_image",
  MANAGE_IMAGES_IMAGES: "ui/manage_images_images",
  MANAGE_IMAGES_CHANNEL: "ui/manage_images_channel",
  MANAGE_IMAGES_DRAG: "ui/manage_images_drag",
  MANAGE_IMAGES_TOOLBAR: "ui/manage_images_toolbar",
  MANAGE_IMAGES_ORIGINAL_SORT: "ui/manage_images_original_sort",
  MANAGE_IMAGES_NEW_SORT: "ui/manage_images_new_sort",
  MANAGE_IMAGES_CHANGE_SORT: "ui/manage_images_change_sort",
  MANAGE_IMAGES_RESET: "ui/manage_images_reset",
  MANAGE_IMAGES_ADD_SELECTION: "ui/manage_images_selection",
  MANAGE_IMAGES_REMOVE_SELECTION: "ui/manage_images_remove_selection",
  MANAGE_IMAGES_DELETE: "ui/manage_images_delete",

  //MISC
  TOGGLE_FILTER: "ui/toggle_filter",
  PREVIOUS_PAGE: "ui/previous_page",
  TOGGLE_TOOLTIP: "ui/toggle_tooltip",

  //SMART EDITOR
  SMART_EDITOR_OPEN: "ui/smart_editor_open",
  SMART_EDITOR_CLOSE: "ui/smart_editor_close",

  TABS_HEIGHT: "ui/tabs_height",
  TOGGLE_REFRESH: "ui/toggle_refresh",

  //LOADER
  OPEN_LOADER: "ui/open_loader",
  CLOSE_LOADER: "ui/close_loader"
};
