import { Button, NubeskIcons } from 'components/atoms/index'
import React from 'react'

const prefix = "m-inputs-buttons"

const InputsApproveButton = ({onClick: {approve, discard}}) => {
  return (
    <div className={`${prefix}`}>
    <Button
      className={`${prefix}__button`}
      content={<NubeskIcons icon="close" />}
      type="gray"
      sqr
      onClick={discard}
    />
    <Button
      className={`${prefix}__button`}
      content={<NubeskIcons icon="outlineCheck" />}
      type="gray"
      sqr
      onClick={approve}
    />
  </div>
  )
}

export default InputsApproveButton
