import { reduxActions as UI } from "_constants/ui.constants";

const toolbar = {
  [UI.TOOLBAR_CHANGE]: (state, { payload }) => ({
    ...state,
    toolbar: {
      previous: state.toolbar.current,
      current: payload,
    },
  }),
  [UI.TOOLBAR_PREVIOUS]: (state) => ({
    ...state,
    toolbar: {
      previous: state.toolbar.current,
      current: state.toolbar.previous,
    },
  }),
  [UI.TOOLBAR_SELECTION]: (state) => ({
    ...state,
    toolbar: {
      previous: state.toolbar.current,
      current: `${state.toolbar.current}Selection`,
    }
  })
};

export default toolbar;
