export const reduxActions = {
  ALL_FAIL: "layout/all_fail",
  ALL_RESET: "layout/all_reset",
  ALL_STARTED: "layout/all_started",
  ALL_SUCCESS: "layout/all_success",
  
  ONE_FAIL: "layout/one_fail",
  ONE_RESET: "layout/one_reset",
  ONE_STARTED: "layout/one_started",
  ONE_SUCCESS: "layout/one_success",

  POST_FAIL: "layout/post_fail",
  POST_RESET: "layout/post_reset",
  POST_STARTED: "layout/post_started",
  POST_SUCCESS: "layout/post_success",
};

export const ACTIVE = "active";

export const GLOBAL = "$global";
