// For each args passed will be taken as expresion OR
// if pass to same array arg, will be taken as expresion AND all elements of array. 

const canDoIt = function (...args) {
  let output = [];

  if (!args?.length) return false;
  
  for (let a in args) {
    let localArray = args[a];

    if (!Array.isArray(localArray)) localArray = [localArray];
    output[a] = true;

    for (let permission of localArray) {
      if (this.permissions.indexOf(permission) === -1) {
        output[a] = false;
        break;
      }
    }
  }
  
  return output.indexOf(true) !== -1;
};

export default canDoIt;
