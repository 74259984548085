import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { Button, CircleStatus } from "components/atoms";
import { SimpleScroller } from "components/organism";

import { useAppSettings, useCopyData, usePrevious } from "_hooks";
import { selectProductDetails } from "_redux/product/product.selector";
import { selectUser } from "_redux/user/user.selectors";

import { APPROVED, TOREVIEW } from "_constants/product.constants";
import { $$FOLDER } from "_constants/permission.constants";
import FieldSelector from "./FieldSelector";
import { Badge } from "components/atoms/index";

const prefix = "o-details-copy";

const DetailsCopy = () => { 
  const { key } = useParams();

  const { appPropertyColumns } = useAppSettings();
  const {
    approveCopy,
    changeCopyStatus,
    dataReset, 
    dataStructure,
    discardChanges,
    changeHandler,
    fullObject: formValues,
    progress,
    saveCopy
  } = useCopyData();
  const prevKey = usePrevious(key);

  const product = useSelector(selectProductDetails);
  const user = useSelector(selectUser);

  const { t } = useTranslation();

  const [layout] = useState("global");
  const [isEdition, setIsEdition] = useState(false);
  
  const classForComponent = classNames(prefix);
  
  useEffect(() => {
    dataStructure(product, appPropertyColumns);
  }, [appPropertyColumns, product?.data]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (prevKey & prevKey !== key) {
      setIsEdition(false);
      dataReset();
    }
  }, [key]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={classForComponent}>
      <div className={`${prefix}__header`}>
        <Badge
          content={<div className="--layer">
            <div>Atributos globales</div>
            <div>
              <CircleStatus status={product?.process?.reviewCopy} /> {progress}%
            </div>
          </div>}
          variant="border-purple"
        />
      </div>

      <div className={`${prefix}__actions`}>
        <div>
          {product && <Button
            content={t("products:actions.generateContent")}
            type="gray"
            onClick={() => setIsEdition(false)}
            sm
          />}
        </div>
        <div>
          {isEdition && <>
            <Button
              content={t("products:actions.discardChanges")}
              onClick={() => {
                discardChanges();
                setIsEdition(false);
              }}
              sm
              type="gray"
            />
            <Button
              content={t("products:actions.saveChanges")}
              onClick={() => {
                setIsEdition(false);
                if (product?.process?.reviewCopy === APPROVED) {
                  changeCopyStatus(product._id, TOREVIEW);
                }
                saveCopy(product?._id, formValues);
              }}
              sm
              type="primary"
            />
          </>}
          {user.canDoIt($$FOLDER.EDIT) && product && 
            product?.process?.reviewCopy && !isEdition &&
            <Button
              content={t("common:form.edit")}
              type="primary"
              onClick={() => setIsEdition(true)}
              sm
            />
          }
          {user.canDoIt($$FOLDER.APPROVE_INFO) && product && 
            product?.process?.reviewCopy !== APPROVED && !isEdition &&
            <Button
              content={t("products:actions.approve")}
              onClick={() => approveCopy(product?._id)}
              sm
              type="success"
            />
          }
        </div>
      </div>    

      <div className={`${prefix}__content`}>
        <SimpleScroller
          disableTracksWidthCompensation 
          permanentTrackY={true}
          removeTrackYWhenNotUsed={false}
        >
          <div className={`${prefix}__content-inputs`}>
            {appPropertyColumns.filter(({ type }) => type === layout)
              .map(({ _id, key, name, class: { datatype }, flag, status, type }) => 
                <FieldSelector
                  disabled={!isEdition}
                  key={key}
                  {...{ _id,
                    datatype,
                    flag,
                    keyValue: key,
                    name,
                    onChange: ({ value }) => changeHandler({ value, name: key }),
                    options: formValues?.[key]?.array,
                    readOnly: formValues?.[key]?.readOnly || false,
                    status,
                    type,
                    value: formValues?.[key]?.value
                  }}
                />
              )
            }
          </div>
        </SimpleScroller>
      </div>
    </div>
  );
};

export default DetailsCopy;
