import { reduxActions as CATALOG } from "_constants/catalog.constants";
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const toTrash = {
  [CATALOG.TO_TRASH_STARTED]: (state) => ({
    ...state,
    put: {
      ...state.put,
      status: LOADING
    }
  }),
  [CATALOG.TO_TRASH_FAIL]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
  [CATALOG.TO_TRASH_SUCCESS]: (state, { payload }) => ({
    ...state,
    put: {
      ...state.put,
      data: payload?.data ?? null,
      status: SUCCESS
    },
    all: {
      ...state.all,
      data: [
        ...state.all.data?.filter(
          (row) => row._id !== payload?.data?.[0]?._id
        )
      ]
    }
  })
};

export default toTrash;
