import { reduxActions as CATALOG } from "_constants/catalog.constants";

const addResource = {
  [CATALOG.ADD_RESOURCE]: (state, { payload }) => {
    let newArray = state.one?.data?.resourcesId ?? [];
    let added = false;
    for (let r in newArray) {
      if (
        newArray[r].process.adaptationId === payload?.process.adaptationId &&
        newArray[r]._id !==  payload?._id
      ) {
        added = true;
        newArray = [
          payload, 
          ...state.one?.data?.resourcesId?.filter(
          (r) => r.process.adaptationId !== payload?.process.adaptationId) 
        ?? []];
        break;
      }

      if (newArray[r]._id === payload?._id) {
        added = true;
        newArray[r] = payload;
        break;
      }
    }
    if (!added) {
      newArray = [
        payload, 
        ...state.one?.data?.resourcesId ?? []];
    }
    return ({
      ...state,
      one: {
        ...state.one,
        data: {
          ...state.one.data,
          resourcesId: newArray
        }
      }
    })
}
};

export default addResource;
