import { createSelector } from "../../../node_modules/reselect/lib/index";

const selectProfileStore = (store) => store.profile;

export const selectProfileAll = createSelector(
  selectProfileStore,
  (profile) => profile.all
);

export default selectProfileStore;
