import initialUserState from '../user.initialState';
import { reduxActions as USER } from '_constants/user.constants';
import { FAILURE, LOADING, SUCCESS } from "_constants/redux.constants";

const restore = {
  [USER.RESTORE_STARTED]: (state) => ({
    ...state,
    restore: {
      ...state.restore,
      status: LOADING
    }
  }),
    
  [USER.RESTORE_FAIL]: (state, { payload }) => ({
    ...state,
    restore: {
      ...state.restore,
      error: payload?.error ?? null,
      status: FAILURE
    }
  }),
    
  [USER.RESTORE_SUCCESS]: (state, { payload }) => ({
    ...state,
    restore: {
      ...state.restore,
      data: payload?.data ?? null,
      status: SUCCESS
    }
  }),

  [USER.RESTORE_RESET]: (state) => ({
    ...state,
    restore: initialUserState.restore
  })
};

export default restore;
