import { reduxActions as APP } from "_constants/app.constants";

const configGot = {
  [APP.CONFIG_GOT]: (state, { payload }) => ({   
    ...state,
    configGot: payload
  })
};

export default configGot;
