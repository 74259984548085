import { reduxActions as UI } from "_constants/ui.constants";

const leftPanel = {
  [UI.OPEN_PANEL_LEFT]: (state, { payload }) => ({
    ...state,
    leftPanel: {
      isOpen:
        payload.isOpen || state.leftPanel.typePanel !== payload.typePanel
          ? true
          : !state.leftPanel.isOpen,
      panelProps: { ...payload.panelProps },
      typePanel: payload.typePanel,
    },
  }),
  [UI.CLOSE_PANEL_LEFT]: (state) => ({
    ...state,
    leftPanel: {
      isOpen: false,
      panelProps: {},
      typePanel: null,
    },
  }),
};

export default leftPanel;
