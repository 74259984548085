import { useDispatch } from "react-redux";
import { find } from "_redux/task/task.actions";
import { reduxActions as TASK } from "_constants/task.constants";

const useTasks = () => {
  const dispatch = useDispatch();

  /**
   * 
   * @param {*} params 
   * @param {*} extras 
   * @returns 
   */
  const fetch = (params, extras = {}) => dispatch(find({
    f: params?.f || [
      "_id", "data", "key", "name", "process", "status", "userId", "share", "foldersId"
    ],
    a: {
      extended: {
        f: {
          userId: ["account"],
          share: ["account"]
        }
      },
      sort: params?.sort ?? {
        by: {
          "createdAt": -1
        }
      }
    },
    w: {
      ...params?.w ?? {}
    }
  }, extras));

  /**
   * 
   * @param {*} params 
   * @returns 
   */
  const getTaskList = (params) => dispatch(find({
    a: {
      sort: {
        by: {
          createdAt: -1
        }
      }
    },
    f: ["_id", "name"],
    w: {
      ...params?.w ?? {},
    }
  }, [
    TASK.LIST_STARTED,
    TASK.LIST_SUCCESS,
    TASK.LIST_FAIL
  ]));
  
  return {
    fetch,
    getTaskList
  };
};

export default useTasks;
